import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { ErrorsObject, parseError } from 'utilities/errors/errorUtils';
import { CashFlowListItem } from '../categoriesViewModel';

export class DeleteTypeViewModel {
	private _refetch: () => CancellablePromise<void>;

	private _loading: boolean = false;
	private _error?: string = undefined;
	private _errors?: ErrorsObject = undefined;

	private _open: boolean = false;
	private _type: CashFlowListItem | null = null;

	constructor(refetch: () => CancellablePromise<void>) {
		makeAutoObservable(this);

		this._refetch = refetch;
	}

	public isLoading = () => this._loading;
	public getError = () => this._error;
	public getErrors = () =>
		this._errors ? Object.values(this._errors).flatMap((x) => x) : undefined;

	public isOpen = () => {
		return this._open;
	};
	public openModal = (type: CashFlowListItem) => {
		this._open = true;
		this._type = type;
	};
	public closeModal = () => {
		this._open = false;
		this._loading = false;
		this._error = undefined;
		this._errors = undefined;
		this._type = null;
	};

	public canDelete = () => {
		return (
			this._type !== null &&
			this._type.transactionCount === 0 &&
			this._type.transactionRuleCount === 0
		);
	};

	public delete = flow(function* (this: DeleteTypeViewModel) {
		try {
			if (this._type === null) return;

			this._loading = true;
			this._error = undefined;
			this._errors = undefined;

			var response: AxiosResponse<
				ApiResponse<string>
			> = yield customerApi.delete<ApiResponse<string>>(
				`/categories/${this._type?.classId}/types/${this._type?.id}`
			);

			if (response.data.error) this._error = response.data.error;
			else if (response.data.errors) this._errors = response.data.errors;
			else {
				this.closeModal();
				this._refetch();
			}
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}
