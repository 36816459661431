import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { USER_PREFERENCES_FIELD_OPTIONS } from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { CreateRelationshipDrawer } from '../components/createRelationshipDrawer/createRelationshipDrawer';
import { DeleteRelationshipModal } from '../components/deleteRelationshipModal';
import { EditRelationshipDrawer } from '../components/editRelationshipDrawer/editRelationshipDrawer';
import { RelationshipsLayout } from '../components/relationshipsLayout';
import { IRelationshipDefinitionData } from '../relationshipApiTypes';
import { LegalEntityPowerOfAttorneyRelationship } from './entityRelationshipsListModel';
import { useEntityRelationshipViewModel } from './entityRelationshipsViewModel';

export const EntityRelationships: FC = observer(() => {
	const viewModel = useEntityRelationshipViewModel();
	const history = useHistory();

	const navigateToTab = useCallback(
		(definition: IRelationshipDefinitionData | null) => {
			if (definition && definition.displayName === 'Power of Attorney') {
				history.push(
					history.location.pathname + `?tab=${definition.displayName}`,
				);
			} else if (definition && definition.category.displayName) {
				history.push(
					history.location.pathname + `?tab=${definition.category.displayName}`,
				);
			}
		},
		[history],
	);

	return (
		<RelationshipsLayout
			createRelationshipButton={
				<CreateRelationshipDrawer
					viewModel={viewModel.getCreateViewModel()!}
					readOnly={viewModel.loading()}
					navigateToTab={navigateToTab}
				/>
			}
			tabDetails={[
				{
					tab: 'Accounts',
					component: (
						<UserPreferencesDataGrid
							stonlyId="accounts"
							tableKey="entityRelationshipsAccounts"
							columns={viewModel.getAccountColumns()}
							rows={viewModel.getAccountRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Account Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							sx={{ height: '100%' }}
						/>
					),
				},
				{
					tab: 'Ownership',
					component: (
						<Box sx={{ paddingBottom: '1.25rem' }}>
							<UserPreferencesDataGrid
								stonlyId="ownership"
								tableKey="entityRelationshipsOwnership"
								columns={viewModel.getParentColumns()}
								rows={viewModel.getParentRows()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Parent Relationships"
								showToolbar
								showCustomViewButton
								initialState={{
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
								sx={{
									height: '40vh',
								}}
							/>
							<UserPreferencesDataGrid
								stonlyId="children"
								tableKey="entityRelationshipsChildren"
								columns={viewModel.getChildrenColumns()}
								rows={viewModel.getChildrenRows()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Child Relationships"
								showToolbar
								showCustomViewButton
								initialState={{
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
								sx={{
									marginTop: '1rem',
									height: '40vh',
								}}
							/>
						</Box>
					),
				},
				{
					tab: 'Staff',
					component: (
						<UserPreferencesDataGrid
							stonlyId="staff"
							tableKey="entityRelationshipsStaff"
							columns={viewModel.getStaffColumns()}
							rows={viewModel.getStaffRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Staff Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							sx={{ height: '100%' }}
						/>
					),
				},
				{
					tab: 'Power of Attorney',
					component: (
						<UserPreferencesDataGrid<LegalEntityPowerOfAttorneyRelationship>
							stonlyId="legal-entity-power-of-attorney-relationships"
							tableKey="legal-entity-power-of-attorney-relationships"
							columns={viewModel.getPowerOfAttorneyColumns()}
							rows={viewModel.getPowerOfAttorneyRelationships()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Power of Attorney Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
			]}
		>
			<EditRelationshipDrawer
				viewModel={viewModel.editRelationshipDrawerViewModel}
			/>
			<DeleteRelationshipModal
				viewModel={viewModel.deleteRelationshipViewModel}
			/>
		</RelationshipsLayout>
	);
});
