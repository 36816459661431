import { SaveAlt } from '@mui/icons-material';
import {
	FormControl,
	FormGroup,
	Grid,
	InputLabel,
	Menu,
	MenuItem,
	Select,
} from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { T4SubmitButton } from 'shared/components/buttons';
import { stonlyData } from 'stonly/functions';
import { DownloadType, useDiagramDownload } from '../_hooks/useDiagramDownload';

export type ExportButtonProps = {
	stonlyId: string;
	defaultFileName: string;
	diagram?: go.Diagram;
};

export const ExportButton: FC<ExportButtonProps> = observer(
	({ stonlyId, defaultFileName, diagram }) => {
		const { download } = useDiagramDownload(defaultFileName);

		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
		const [exportFileType, setExportFileType] = useState<DownloadType>('SVG');

		return (
			<>
				<T4Button
					id="exportBtn"
					variant="outlined"
					sx={{ background: 'white' }}
					startIcon={<SaveAlt />}
					onClick={(event) => setAnchorEl(event.currentTarget)}
					{...stonlyData({
						id: `${stonlyId}-export-button`,
					})}
				>
					Export
				</T4Button>
				<Menu
					id="exportMenu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={() => setAnchorEl(null)}
				>
					<Grid
						container
						direction="row"
						sx={{ padding: '1rem' }}
						{...stonlyData({
							id: `${stonlyId}-export-modal-container`,
						})}
					>
						<Grid container item xs>
							<FormControl fullWidth sx={{ paddingBottom: '10px' }}>
								<FormGroup>
									<FormControl sx={{ marginTop: '10px' }}>
										<InputLabel
											id="export-file-format-label"
											className="options-menu-item"
										>
											File Type
										</InputLabel>
										<Select
											labelId="export-file-format-label"
											id="export-file-format-select"
											label="File Type"
											value={exportFileType}
											onChange={(event) => {
												const selection = event.target.value;

												if (
													typeof selection === 'string' &&
													(selection === 'PNG' ||
														selection === 'PDF' ||
														selection === 'SVG')
												) {
													setExportFileType(selection);
												}
											}}
											{...stonlyData({
												id: `${stonlyId}-export-modal-file-type-select`,
											})}
										>
											<MenuItem value="PNG">
												<small>PNG</small>
											</MenuItem>
											<MenuItem value="PDF">
												<small>PDF</small>
											</MenuItem>
											<MenuItem value="SVG">
												<small>SVG</small>
											</MenuItem>
										</Select>
									</FormControl>
								</FormGroup>
							</FormControl>
							<T4SubmitButton
								sx={{ marginLeft: 'auto' }}
								onClick={() => {
									if (diagram) {
										download(diagram, exportFileType);
									}
								}}
								label="Export"
								{...stonlyData({
									id: `${stonlyId}-export-modal-export-button`,
								})}
							/>
						</Grid>
					</Grid>
				</Menu>
			</>
		);
	},
);
