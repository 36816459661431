import { makeAutoObservable } from 'mobx';
import { Moment } from 'moment';
import {
	EntityType,
	EntityTypeId,
	getEntityType,
	requiredFieldsByEntityTypeId,
} from '../../entity4Constants';
import { FieldDataContract } from '../../shared/fieldSets/fieldTypes';
import { IEntityApiData, IFieldUpdate } from '../entitiesApiTypes';
import { EntityRepository } from '../entityRepository';
import { getFieldSetSortingAlgorithm } from '../factories/fieldSetSortingFactory';
import { EntityBase } from './entityBase';
import { EntityCollection } from './entityCollection';
import { EntityField } from './fields/entityField';
import { EntityFieldBase } from './fields/field';
import moment from 'moment';

export class Entity implements EntityBase {
	public isRemovingCollectionItem: boolean = false;
	public loading: boolean = false;
	public loadingSuccess: boolean = false;
	public loadingError: string = '';

	private _entityType: EntityType;
	private _data: IEntityApiData;
	private _fields: EntityField[];
	private _collections: EntityCollection[];

	private _entityRepository: EntityRepository;

	constructor(
		data: IEntityApiData,
		entityRepository?: EntityRepository,
		onUpdate?: () => void,
	) {
		makeAutoObservable(this);

		this._data = data;
		this._entityType = getEntityType(data.entityTypeId)!;

		this._entityRepository =
			entityRepository ??
			new EntityRepository(
				this._entityType,
				data.id,
				getEntityType(data.parentEntityTypeId),
				data.parentEntityId,
			);

		this._fields = data.fields.map(
			(x) =>
				new EntityField(x, this, undefined, this._entityRepository, onUpdate),
		);
		this._collections = data.collections.map(
			(x) => new EntityCollection(this, x, this._entityRepository),
		);
	}

	public get data() {
		return this._data;
	}

	public get id(): string {
		return this._data.id;
	}

	public get entityType(): EntityType {
		return this._entityType;
	}

	public get entity(): Entity {
		return this;
	}

	public get isApproved(): boolean {
		let requiredFields = requiredFieldsByEntityTypeId.get(
			this._data.entityTypeId as EntityTypeId,
		);

		for (let field of requiredFields || []) {
			if (!this.getField(field)?.approvedValue) {
				return false;
			}
		}
		return true;
	}

	public get hasPendingValue(): boolean {
		const pendingField = this._fields.find((x) => x.isPendingApproval);

		return Boolean(pendingField);
	}

	public get hasRejectedValue(): boolean {
		const rejectedField = this._fields.find((x) => x.isRejected);

		return Boolean(rejectedField);
	}

	public get latestApprovedDate(): Moment | undefined {
		var updates = this._fields
			.map((x) => x.dateApproved)
			.filter((x) => x !== undefined)
			.sort((a, b) => {
				return a!.isAfter(b) ? -1 : 1;
			});
		return updates && updates.length ? updates[0] : undefined;
	}

	public get latestPendingApprovalDate(): Moment | undefined {
		var updates = this._fields
			.map((x) => x.dateSubmitted)
			.filter((x) => x !== undefined)
			.sort((a, b) => {
				return a!.isAfter(b) ? -1 : 1;
			});
		return updates && updates.length ? updates[0] : undefined;
	}

	public get latestUpdatedDate(): Date | undefined {
		var updates = this._data.fields
			.map((x) => x.updatedDate)
			.filter((x) => x !== undefined)
			.sort((a, b) => {
				return moment(a)!.isAfter(moment(b)) ? -1 : 1;
			});
		return updates && updates.length ? new Date(updates[0] || '') : undefined;
	}

	public getField(fieldIdentifier: string): EntityField | undefined {
		return this._fields.find((x) => x.identifier === fieldIdentifier);
	}

	public addField(field: EntityField): void {
		this._fields.push(field);
	}

	public async v2AddField(
		fieldIdentifier: string,
		value: any,
		phoneNumberNumber?: string | null,
		extension?: string | null,
	) {
		if (phoneNumberNumber !== undefined && extension !== undefined) {
			const field = await this._entityRepository.createPhoneNumberField(
				fieldIdentifier,
				value,
				phoneNumberNumber ?? null,
				extension ?? null,
			);
			this.addField(new EntityField(field, this, true, this._entityRepository));
		} else {
			const field = await this._entityRepository.updateField({
				fieldIdentifier: fieldIdentifier,
				value: value,
			});
			this.addField(new EntityField(field, this, true, this._entityRepository));
		}
	}

	public getCollectionField(
		contract: FieldDataContract,
	): EntityFieldBase | undefined {
		var fieldSets = this.getCollectionEntries(contract.fieldSetIdentifier);
		if (fieldSets) {
			var sortingAlgorithm = getFieldSetSortingAlgorithm(
				contract.fieldSetIdentifier,
			);

			return sortingAlgorithm
				.sort(fieldSets)[0]
				?.getField(contract.fieldIdentifier);
		} else {
			return this.getField(contract.fieldIdentifier);
		}
	}

	public getCollectionEntries(
		collectionIdentifier: string,
	): EntityCollection[] {
		return this._collections.filter(
			(x) => x.identifier === collectionIdentifier,
		);
	}

	public addCollection(collection: EntityCollection) {
		this._collections.push(collection);
	}

	public removeCollection(collection: EntityCollection) {
		this._collections = this._collections.filter((i) => i.id !== collection.id);
	}

	public createCollection(
		collectionIdentifier: string,
		fieldUpdates?: IFieldUpdate[],
		parentCollectionId?: string,
	): Promise<void> {
		this.loading = true;
		this.loadingError = '';
		this.loadingSuccess = false;
		return this._entityRepository
			.addCollection(
				this,
				collectionIdentifier,
				fieldUpdates ?? [],
				parentCollectionId,
			)
			.then((collection) => this.addCollection(collection))
			.catch((error) => (this.loadingError = error))
			.finally(() => (this.loading = false));
	}

	public deleteCollection(entityCollection: EntityCollection) {
		this.isRemovingCollectionItem = true;
		this._entityRepository
			.deleteCollectionField(entityCollection.id)
			.then(() => {
				this.removeCollection(entityCollection);
			})
			.finally(() => {
				this.isRemovingCollectionItem = false;
			});
	}
}
