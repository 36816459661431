import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorsObject } from 'utilities/errors/errorUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { GroupRepository } from '../../groupRepository';

interface UseAddGroupButtonProps {
	isLoading: boolean;
	name: string | undefined;
	description: string | undefined;
	setName: (value: string) => void;
	setDescription: (value: string) => void;
	getFieldErrors: (key: 'name' | 'description') => string[];
	resetForm: () => void;
	submit: () => Promise<string | undefined>;
}

export const useAddGroupButton = (): UseAddGroupButtonProps => {
	const { enqueueSnackbar } = useSnackbar();
	const groupsRepository = useMemo(() => new GroupRepository(), []);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorsObject>({});
	const [name, setName] = useState<string | undefined>(undefined);
	const [description, setDescription] = useState<string | undefined>(undefined);

	const resetForm = useCallback(() => {
		setIsLoading(false);
		setErrors({});
		setName(undefined);
		setDescription(undefined);
	}, []);

	const getFieldErrors = useCallback(
		(key: 'name' | 'description'): string[] => {
			return errors[key] ?? [];
		},
		[errors],
	);

	const submit = useCallback(async () => {
		try {
			if (isStringUndefinedOrNullOrWhitespace(name)) return;

			setIsLoading(true);
			setErrors({});

			const response = await groupsRepository.createGroup({
				name: name!.trim(),
				description: !isStringUndefinedOrNullOrWhitespace(description)
					? description!.trim()
					: null,
			});

			if (response.success) return response.value;
			else {
				if (response.errors !== null) setErrors(response.errors);
				else throw new Error();
			}
		} catch (error: any) {
			enqueueSnackbar(
				'An unexpected error occured when creating the security group. Please try again.',
				{
					variant: 'error',
				},
			);
		} finally {
			setIsLoading(false);
		}
	}, [name, description, enqueueSnackbar, groupsRepository]);

	useEffect(() => {
		return () => resetForm();
	}, [resetForm]);

	return {
		isLoading,
		name,
		description,
		setName,
		setDescription,
		getFieldErrors,
		resetForm,
		submit,
	};
};
