import { Chip, ChipProps, SxProps, Theme, styled } from '@mui/material';
import React from 'react';
import { brandColors } from 'shared/theme/brandColors';

interface IT4ChipProps {
	label: string;
	chipColor?: 'blue' | 'gold' | 'red' | 'denim';
	sx?: SxProps<Theme>;
}

interface StyledChipProps extends ChipProps {
	chipcolor?: 'blue' | 'gold' | 'red' | 'denim';
}
const StyledChip = styled(Chip)<StyledChipProps>(({ theme, chipcolor }) => ({
	color: theme.palette.secondary.contrastText,
	...(chipcolor === 'blue' && {
		backgroundColor: theme.palette.secondary.main,
	}),
	...(chipcolor === 'gold' && {
		backgroundColor: theme.common?.maize?.main,
	}),
	...(chipcolor === 'red' && {
		backgroundColor: theme.palette.error.main,
	}),
	...(chipcolor === 'denim' && {
		backgroundColor: brandColors.denim[100],
		color: brandColors.denim[900],
	}),
}));

const T4Chip: React.FC<IT4ChipProps> = ({ label, chipColor = 'blue', sx }) => {
	return (
		<StyledChip label={label} size="small" chipcolor={chipColor} sx={sx} />
	);
};

export default T4Chip;
