export const REGISTRATION_STATUS_OPTION_LIST_ID =
	'AF802BB4-30D5-45C6-8E9B-6163E79AEB2F';

export const REGISTRATION_FIELD_DESCRIPTIONS = {
	homeRegistration:
		'Yes\\No value to indicate if the jurisdictional registration is for the primary state\\province (home jurisdiction).  Generally most entities have a single primary (home) registration.',
	regNumber:
		"The Registration Number (aka Company Number) assigned by the Registration Authority (RA).  Jurisdiction RA's may have different names\\descriptions for the Registration Number (i.e. State of Washington)",
	assumedName:
		'A customer defined trade or DBA Name which may be different than the Anglicized Trading Name (Doing Business As) found in the Entity definition.',
	status:
		'Status of the registration. Registrations can have several statuses over the duration of the registration however can only have one distinct status at a time.',
	country: 'The Country that the Registration is effective with.',
	state: 'The State that the Registration is effective with.',
	county:
		'The registration county is the county or other subregion where the registration is effective, if applicable.',
	city: 'The registration city is the city, town, or similar municipality where the registration is effective, if applicable.',
	legalForm:
		'The type of legal entity that has been formed and registered with the Registration Authority (RA).  The legal form definition in subsequent jurisdictions may be different than the legal form definition in the home jurisdiction (i.e. "Foreign Corporation" in US states beyond the home state).',
	authority:
		'The jurisdictional Registration Authority (RA) that the Entity registered with (if applicable).',
	regDate:
		'Registration Date that was assigned by the Registration Authority.  Registration Date is the same as Incorporation Date for the Home Jurisdiction registration.',
	expDate:
		'The Expiration Date of the registration that was provided by the Registration Authority.',
	lastFiledDate:
		'The date that the Entity last filed the annual\\bi-annual update with the Registration Authority.',
	dissolutionDate:
		'The date that the registration was dissolved/cancelled by the Registration Authority.',
};
