import { makeAutoObservable } from "mobx";
import { ValueStatus } from "../../../shared/fieldSets/fieldViews/fieldViewTypes";
import { Address } from "./addressModel";

export const postalCodeRegex = /(^[A-Z0-9]{4,5}(?:[\s]?[-\s]?[\s]?\d{4})?$)/;
export const postalCodeErrorString = 'A Postal Code must contain 4 to 10 uppercase characters and/or digits.';

export class AddressFormModel {
    public state: string | null;
	public buildingNumber: string | null;
	public streetName: string | null;
	public poBox: string | null;
	public address1: string | null;
	public address2: string | null;
	public city: string | null;
	public postalCode: string | null;

    private _fieldStatus: Map<keyof AddressFormModel, ValueStatus>;

    constructor(address: Address) {
        makeAutoObservable(this);
        this.state = address.state;
        this.buildingNumber = address.buildingNumber;
        this.streetName = address.streetName;
        this.poBox = address.poBox;
        this.address1 = address.address1;
        this.address2 = address.address2;
        this.city = address.city;
        this.postalCode = address.postalCode;

        this._fieldStatus = new Map();
    }

    public getFieldError = (key: keyof AddressFormModel): string => {
        switch (key) {
            case 'postalCode':
                return this.getPostalCodeError();
            default:
                return '';
        }
    }
    
    public getField = (key: keyof AddressFormModel): string => {
        return this[key] as string;
    }

    public setValue(key: keyof AddressFormModel, value: string | null) {
        (this)[key] = value !== null ? (value.trim() ? value as Address[keyof Address] : null as any) : null as any;
    }

	public isWaiting<T extends keyof AddressFormModel>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Waiting;
	}

	public isSaved<T extends keyof AddressFormModel>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Saved;
	}

    public isErrored<T extends keyof AddressFormModel>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Errored;
	}

	public setIsWaiting<T extends keyof AddressFormModel>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Waiting);
	}

	public setIsSaved<T extends keyof AddressFormModel>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Saved);
	}

    public setIsErrored<T extends keyof AddressFormModel>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Errored);
	}

    public getPostalCodeError = (): string => {
        if (!this.postalCode?.match(postalCodeRegex)) {
            return postalCodeErrorString;
        }
        else {
            return '';
        }
    }
}