import { T4DataResponse, T4Response } from 'modules/clients/types';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { T4LayoutContext } from './contexts/t4LayoutContext';
import { observer } from 'mobx-react-lite';

export interface T4LayoutProviderProps {
	children: ReactNode;
}

export const T4LayoutProvider: FC<T4LayoutProviderProps> = observer(({ children }) => {
	const [organizationCode, setOrganizationCode] = useState<string>('');
	const [notificationsCount, setNotificationsCount] = useState<number>(0);
	const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(true);

	const { customerApiClient } = useClients();

	useEffect(() => {
		customerApiClient.api.layout.getLayoutDetails().then(
			(
				response: T4Response<
					T4DataResponse<{
						organizationCode: string;
						notificationsCount: number;
					}>
				>,
			) => {
				setOrganizationCode(response?.data?.data?.organizationCode ?? '');
				setNotificationsCount(response?.data?.data?.notificationsCount ?? 0);
			},
		);
	}, [customerApiClient.api.layout]);

	return (
		<T4LayoutContext.Provider
			value={{
				organizationCode: organizationCode,
				notificationsCount: notificationsCount,
				setNotificationsCount: setNotificationsCount,
				sideMenuOpen: sideMenuOpen,
				setSideMenuOpen: setSideMenuOpen,
			}}
		>
			{children}
		</T4LayoutContext.Provider>
	);
});
