/* eslint-disable mobx/missing-observer */
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, CircularProgress, InputAdornment } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { upsertNote } from '../services';

interface AccountBalanceNoteProps {
	balanceId: string;
	noteContent: string;
	refetch?: () => void;
}

const AccountBalanceNote: React.FC<AccountBalanceNoteProps> = ({
	balanceId,
	noteContent,
	refetch,
}) => {
	const [note, setNote] = useState<string>(noteContent);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const characterLimit = 2048;

	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const handleChange = (value: string) => {
		if (value.length <= characterLimit) {
			setNote(value);
		}
	};

	const {
		mutate: updateNoteMutation,
		isLoading,
		isError,
		isSuccess,
	} = useMutation((newNote: string) => upsertNote(balanceId, newNote), {
		onSuccess: () => {
			queryClient.invalidateQueries(['transactions']);
			enqueueSnackbar('Note saved', { variant: 'success' });
		},
		onError: () => {
			enqueueSnackbar('Failed to save note', { variant: 'error' });
		},
		onSettled: () => {
			queryClient.invalidateQueries(['transaction', balanceId]);
			setIsDirty(false);
			if (refetch) {
				refetch();
			}
		},
	});

	const handleSave = async () => {
		if (!isDirty) return;
		updateNoteMutation(note);
	};

	useEffect(() => {
		setNote(noteContent);
	}, [noteContent]);

	useEffect(() => {
		if (note !== noteContent) {
			setIsDirty(true);
		} else {
			setIsDirty(false);
		}
	}, [note]);

	return (
		<Box>
			<T4TextFieldV2
				sx={{ mt: 2, mb: 2 }}
				multiline
				minRows={5}
				maxRows={5}
				label="Notes"
				value={note}
				onChange={handleChange}
				onBlur={handleSave}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							{isLoading ? (
								<CircularProgress size={20} />
							) : isSuccess ? (
								<CheckCircleOutlineIcon color="success" />
							) : isError ? (
								<ErrorOutlineIcon color="error" />
							) : null}
						</InputAdornment>
					),
				}}
				helperText={`${note?.length ?? 0}/${characterLimit}`}
			/>
		</Box>
	);
};

export default AccountBalanceNote;
