import { format } from 'date-fns';

export const isReactElement = (item: any): boolean => {
	return (
		item &&
		typeof item === 'object' &&
		item.$$typeof === Symbol.for('react.element')
	);
};

export const shouldFilterElement = (
	element: React.ReactElement | string,
): boolean => {
	if (typeof element === 'string') return false;
	if (!element.props) return false;

	const { e4AccountId, e4AccountName } = element.props;
	return !e4AccountId || !e4AccountName;
};

export const formatDateSimpleUpperCase = (
	dateString: string | null | undefined,
): string => {
	if (!dateString) return '';
	const date = new Date(dateString);
	return format(date, 'dd-MMM-yyyy').toUpperCase();
};
