import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { T4AutocompleteAsync } from 'shared/components/autocompletes/t4AutocompleteAsync';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { ObjectPathParams } from '../../../../../shared/constants/paths';
import { EntityFieldBase } from '../../../entities/objects/fields/field';
import { InputAdornmentWithModal } from '../../components/molecules/inputAdornmentWithModal';
import CommonReferenceRepository from '../../repositories/referenceRepository';
import { Field } from '../fieldTypes';
import { FieldError } from './common/fieldError';
import { FieldViewFieldLayout } from './common/fieldLayout';
import { FieldViewProps, FieldViews } from './fieldViewTypes';

const getError = (
	parentField?: EntityFieldBase,
	parentFieldDefinition?: Field,
	fieldData?: EntityFieldBase,
	fieldDefinition?: Field,
) => {
	if (!parentField) {
		return;
	}

	const childValue = fieldData?.initialReferenceValue;
	const parentValue = parentField?.initialReferenceValue;

	if (
		parentValue != null &&
		childValue != null &&
		parentValue.identifier !== childValue.parentIdentifier
	) {
		const childName = fieldDefinition?.name;
		const parentName = parentFieldDefinition?.name;
		return `${childName} does not match selected ${parentName}. Please update.`;
	}

	return;
};

export const FieldViewReference: FC<FieldViewProps> = observer(
	({
		fieldDefinition,
		parentField,
		parentFieldDefinition,
		fieldData,
		customError,
		...props
	}) => {
		const { viewType } = useProfileView();
		const { objectId } = useParams<ObjectPathParams>();

		const [value, setValue] = useState<ReferenceDataValue | null>(
			fieldData?.initialReferenceValue
				? fieldData?.initialReferenceValue
				: null,
		);

		const isReadOnly = useMemo(() => viewType !== FieldViews.edit, [viewType]);

		const onSelect = async (selected: ReferenceDataValue | null) => {
			if (selected?.value !== fieldData?.initialReferenceValue?.value) {
				if (fieldData) {
					await fieldData.save(JSON.stringify(selected));
					setValue(selected);
				} else {
					props.createField(JSON.stringify(selected));
					setValue(selected);
				}
			}
		};

		const saveValue = () => {
			if (fieldData) {
				fieldData.save(JSON.stringify(value));
			} else {
				props.createField(JSON.stringify(value));
			}
		};

		const parentFieldValue = parentField?.initialReferenceValue?.value;

		return (
			<FieldViewFieldLayout
				entityId={objectId}
				fieldDefinition={fieldDefinition}
				fieldData={fieldData}
				approvedValueToDisplay={fieldData?.approvedReferenceValue?.displayName}
				value={value?.displayName}
				{...props.fieldLayoutProps}
			>
				<T4AutocompleteAsync<ReferenceDataValue, false, boolean, false>
					id={fieldDefinition.identifier}
					cacheKey={[
						fieldDefinition.identifier,
						fieldDefinition.referenceCollection,
						parentFieldValue,
					]}
					label={fieldDefinition.name}
					readOnly={isReadOnly}
					onSelect={onSelect}
					value={value}
					optionsResolver={async () => {
						return await CommonReferenceRepository.getCollection(
							fieldDefinition.referenceCollection!,
							parentFieldValue,
						);
					}}
					isOptionEqualToValue={(reference, value) =>
						reference.value === value?.value
					}
					getOptionLabel={(reference) => reference.displayName}
					startAdornment={
						<InputAdornmentWithModal
							title={fieldDefinition.name}
							description={fieldDefinition.description}
							adornmentType={fieldData?.loadingState}
							iconButtonProps={{
								...(viewType === FieldViews.default
									? {
											sx: {
												marginLeft: '-5px',
											},
									  }
									: {}),
							}}
						/>
					}
					required={fieldDefinition.isCreationRequirement}
					disableClearable={fieldDefinition.isCreationRequirement}
					textFieldProps={{
						variant: viewType === FieldViews.default ? 'standard' : 'outlined',
					}}
					sx={{
						...(viewType === FieldViews.default
							? {
									'& .MuiInputBase-root': {
										color: 'transparent',
										borderColor: 'transparent',
										borderBottomColor: 'transparent',
										'&:before, :after, :hover': {
											borderBottomColor: 'transparent !important',
										},
										padding: '0rem !important',
									},
							  }
							: {}),
					}}
				/>

				<FieldError
					error={fieldData?.loadingErrorMessage}
					customError={
						customError ||
						getError(
							parentField,
							parentFieldDefinition,
							fieldData,
							fieldDefinition,
						)
					}
					onTryAgainClick={saveValue}
				/>
			</FieldViewFieldLayout>
		);
	},
);
