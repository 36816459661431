import { Box, Collapse, Grid, Typography, useTheme } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { FC } from 'react';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { GeneralRelationshipFields } from '../components/generalRelationshipsFields';
import { EditAccountRelationshipDrawerViewModel } from './editAccountRelationshipDrawerViewModel';

interface IEditDrawerProps {
	viewModel: EditAccountRelationshipDrawerViewModel;
}

const stonlyIds = {
	saveButton: 'edit-account-relationship-drawer-save-button',
	cancelButton: 'edit-account-relationship-drawer-cancel-button',
};

export const EditAccountRelationshipDrawer: FC<IEditDrawerProps> = observer(
	({ viewModel }) => {
		const theme = useTheme();

		return (
			<T4DrawerBase
				open={viewModel.isOpen()}
				onClose={() => viewModel.closeDrawer()}
				title="Edit Relationship"
				loading={viewModel.isUpdating()}
				actions={[
					<DrawerCancelButton
						stonlyId={stonlyIds.cancelButton}
						onCancel={() => viewModel.closeDrawer()}
					/>,
					<DrawerSubmitButton
						stonlyId={stonlyIds.saveButton}
						label="Save"
						onSubmit={() => viewModel.updateRelationship()}
						disabled={viewModel.isSubmitDisabled()}
					/>,
				]}
			>
				<Grid container>
					<Grid item xs={12} sx={{ marginBottom: '16px' }}>
						<T4TextFieldV2
							label="Relationship Type"
							disabled={true}
							value={viewModel.getTypeDisplayName()}
						/>
					</Grid>

					<Grid item xs={12} sx={{ marginBottom: '16px' }}>
						<T4TextFieldV2
							label="Relationship"
							disabled={true}
							value={viewModel.getRelationshipDisplayName()}
						/>
					</Grid>

					<Grid item xs={12} sx={{ marginBottom: '16px' }}>
						<T4TextFieldV2
							label={viewModel.getRelatedEntityLabel()}
							disabled={true}
							value={viewModel.getRelatedEntityName()}
						/>
					</Grid>

					<Grid item xs={12} sx={{ marginTop: '12px', marginBottom: '12px' }}>
						<Grid
							item
							xs={12}
							sx={{
								padding: '16px',
								backgroundColor: theme.palette.lightBackground?.main,
								borderRadius: '2px',
							}}
						>
							<Typography variant="body1">
								<Box component="span" sx={{ fontWeight: 'bold' }}>
									{viewModel.getEntityName()}
								</Box>{' '}
								{viewModel.getDirectionDescription()}{' '}
								<Box component="span" sx={{ fontWeight: 'bold' }}>
									{viewModel.getRelatedEntityName()}
								</Box>
								.
							</Typography>
						</Grid>
					</Grid>

					<Collapse
						in={Boolean(viewModel.isAccountToAccountRelationship())}
						sx={{ width: '100%' }}
					>
						<Grid
							container
							item
							columnSpacing={1}
							sx={{ marginBottom: '16px' }}
						>
							<Grid item xs={6}>
								<T4Autocomplete<string, false, true, false>
									label="Funding Direction"
									options={viewModel.getFundingDirectionList()}
									value={viewModel.getFundingDirection()}
									onSelect={(value: string | null) =>
										viewModel.setFundingDirection(value)
									}
									readOnly={viewModel.isUpdating()}
									disableClearable
									required
								/>
							</Grid>

							<Grid item xs={6}>
								<T4Autocomplete<string, false, false, false>
									label="Cash Flow Movement"
									options={viewModel.getCashFlowMovementList()}
									value={viewModel.getCashFlowMovement()}
									onSelect={(value: string | null) =>
										viewModel.setCashFlowMovement(value)
									}
									readOnly={viewModel.isUpdating()}
								/>
							</Grid>
						</Grid>

						<Grid item xs={12} sx={{ marginBottom: '16px' }}>
							<T4Autocomplete<string, false, false, false>
								label="Funding Frequency"
								options={viewModel.getFundingFrequencyList()}
								value={viewModel.getFundingFrequency()}
								onSelect={(value: string | null) =>
									viewModel.setFundingFrequency(value)
								}
								readOnly={viewModel.isUpdating()}
							/>
						</Grid>
					</Collapse>

					<GeneralRelationshipFields
						effectiveFrom={viewModel.getEffectiveFrom()}
						effectiveFromOnChange={(value: Moment | null) =>
							viewModel.setEffectiveFrom(value)
						}
						effectiveTo={viewModel.getEffectiveTo()}
						effectiveToOnChange={(value: Moment | null) =>
							viewModel.setEffectiveTo(value)
						}
						notes={viewModel.getNotes()}
						notesOnChange={(value: string | null) => viewModel.setNotes(value)}
						inputDisabled={viewModel.isUpdating()}
					/>
				</Grid>

				<Collapse
					in={
						Boolean(viewModel.getUpdateError()) ||
						Boolean(viewModel.getUpdateErrors().length > 0)
					}
				>
					<Box sx={{ marginY: '8px' }}>
						<T4AlertStack
							error={viewModel.getUpdateError()}
							errors={viewModel.getUpdateErrors()}
						/>
					</Box>
				</Collapse>
			</T4DrawerBase>
		);
	},
);
