import { AxiosInstance } from 'axios';
import { Read, T4ApiResponse, Write } from 'modules/clients/types';
import { User } from '..';

// #region Models

export type ApprovalRule = {
	id: string;
	name: string;
	entityId: string | null;
	countryCode: string | null;
	currencyCode: string;
	requiresUniqueApprovers: boolean;
	canApproveOwnPayments: boolean;
	hasSequentialApprovers: boolean;
	createdOn: string;
	createdBy: User | null;
	approvalTiers: ApprovalTier[];
};

export type ApprovalTier = {
	id: string;
	amount: number;
	isUnlimitedAmount: boolean;
	isAutoApprove: boolean;
	approvalLevels: ApprovalLevel[];
};

export type ApprovalLevel = {
	id: string;
	level: number;
	users: User[] | null;
};

// #region Requests

export type AddApprovalTierRequest = {
	amount: number;
	isAutoApprove: boolean;
	isUnlimitedAmount: boolean;
	approvalLevels: {
		level: number;
		users: string[];
	}[];
};

export type SetTierAmountRequest = {
	amount: number;
	isUnlimitedAmount: boolean;
};

export type SetApprovalUsersRequest = {
	users: string[];
};

export type AddApprovalLevelRequest = {
	users: string[];
};

// #endregion
// #endregion

export type ApprovalRuleEndpoints = {
	getAll: Read<T4ApiResponse<ApprovalRule[]>>;
	get: Read<T4ApiResponse<ApprovalRule>, string>;
	createApprovalTier: Write<
		T4ApiResponse<ApprovalTier>,
		{ id: string; data: AddApprovalTierRequest }
	>;
	setTierAmount: Write<
		T4ApiResponse<ApprovalRule>,
		{ ruleId: string; tierId: string; data: SetTierAmountRequest }
	>;
	setTierAutoApprove: Write<
		T4ApiResponse<ApprovalRule>,
		{ ruleId: string; tierId: string; isAutoApprove: boolean }
	>;
	deleteTier: Write<T4ApiResponse<string>, { ruleId: string; tierId: string }>;
	setApprovalUsers: Write<
		T4ApiResponse<string>,
		{
			ruleId: string;
			tierId: string;
			levelId: string;
			data: SetApprovalUsersRequest;
		}
	>;
	addApprovallevel: Write<
		T4ApiResponse<ApprovalRule>,
		{ ruleId: string; tierId: string; data: AddApprovalLevelRequest }
	>;
	deleteApprovalLevel: Write<
		T4ApiResponse<ApprovalRule>,
		{ ruleId: string; tierId: string; levelId: string }
	>;
	getApproverUsers: Read<T4ApiResponse<User[]>>;
};

export function approvalRules(
	axiosInstance: AxiosInstance,
): ApprovalRuleEndpoints {
	return {
		getAll: async (_, config = {}) => {
			return await axiosInstance.get('payments4/payments/approvalrules', {
				...config,
			});
		},
		get: async (id, config = {}) => {
			return await axiosInstance.get(`payments4/payments/approvalrules/${id}`, {
				...config,
			});
		},
		createApprovalTier: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.post(
				`payments4/payments/approvalrules/${id}/tiers`,
				data,
				{
					...config,
				},
			);
		},
		setTierAmount: async ({ ruleId, tierId, data }, config: any = {}) => {
			return await axiosInstance.put(
				`payments4/payments/approvalrules/${ruleId}/tiers/${tierId}/setAmount`,
				data,
				{
					...config,
				},
			);
		},
		setTierAutoApprove: async (
			{ ruleId, tierId, isAutoApprove },
			config: any = {},
		) => {
			return await axiosInstance.put(
				`payments4/payments/approvalrules/${ruleId}/tiers/${tierId}/setAutoApprove`,
				{ isAutoApprove },
				{
					...config,
				},
			);
		},
		deleteTier: async ({ ruleId, tierId }, config: any = {}) => {
			return await axiosInstance.delete(
				`payments4/payments/approvalrules/${ruleId}/tiers/${tierId}`,
				{
					...config,
				},
			);
		},
		setApprovalUsers: async (
			{ ruleId, tierId, levelId, data },
			config: any = {},
		) => {
			return await axiosInstance.put(
				`payments4/payments/approvalrules/${ruleId}/tiers/${tierId}/approvals/${levelId}/users`,
				data,
				{
					...config,
				},
			);
		},
		addApprovallevel: async ({ ruleId, tierId, data }, config: any = {}) => {
			return await axiosInstance.post(
				`payments4/payments/approvalrules/${ruleId}/tiers/${tierId}/approvals`,
				data,
				{
					...config,
				},
			);
		},
		deleteApprovalLevel: async (
			{ ruleId, tierId, levelId },
			config: any = {},
		) => {
			return await axiosInstance.delete(
				`payments4/payments/approvalrules/${ruleId}/tiers/${tierId}/approvals/${levelId}`,
				{
					...config,
				},
			);
		},
		getApproverUsers: async (config: any = {}) => {
			return await axiosInstance.get(
				'payments4/payments/approvalrules/approverUsers',
				{
					...config,
				},
			);
		},
	};
}
