import { DataGridProProps, gridClasses } from '@mui/x-data-grid-pro';
import { Cash4Account } from 'models/apiModels';
import React, { FC } from 'react';
import { CollapseIcon, ExpandIcon, ignoredColumns } from '../utilities';
import DetailPanelContent from './DetailContentPanel';
import LinkDialog from './LinkDialog';
import { T4DataGrid } from 'shared/components/dataGrid/dataGrid';

interface IgnoredListProps {
	accounts: Cash4Account[];
	disableVirtualization?: boolean;
	stonlyIds: { [key: string]: string };
}

export const IgnoredList: FC<IgnoredListProps> = ({
	accounts,
	disableVirtualization = false,
	stonlyIds,
}) => {
	const [open, setOpen] = React.useState(false);
	const [choice, setChoice] = React.useState<Cash4Account>();

	const handleOpen = (account: Cash4Account) => {
		setChoice(account);
		setOpen(true);
	};

	const handleClose = () => {
		setChoice(undefined);
		setOpen(false);
	};

	const getDetailPanelContent = React.useCallback<
		NonNullable<DataGridProProps['getDetailPanelContent']>
	>(({ row }) => <DetailPanelContent row={row} isIgnored={true} />, []);

	const getDetailPanelHeight = React.useCallback(() => 320, []);

	return (
		<>
			<T4DataGrid
				columns={ignoredColumns(handleOpen)}
				rows={accounts}
				getDetailPanelHeight={getDetailPanelHeight}
				getDetailPanelContent={getDetailPanelContent}
				slots={{
					detailPanelExpandIcon: (params) => <ExpandIcon />,
					detailPanelCollapseIcon: (params) => <CollapseIcon />,
				}}
				disableRowSelectionOnClick
				sx={{
					[`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
						outline: 'transparent',
					},
					[`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
						{
							outline: 'none',
						},
				}}
				getRowId={(row) => row.id}
				disableVirtualization={disableVirtualization}
				hideFooter={false}
				stonlyId={stonlyIds.ignoredGrid}
			/>
			{choice && (
				<LinkDialog open={open} handleClose={handleClose} account={choice} />
			)}
		</>
	);
};
