export const capitalizeString = (value: string) => {
	if (value) return value.charAt(0).toUpperCase() + value.slice(1);

	return '';
};

export const isStringUndefinedOrNullOrWhitespace = (
	value: string | null | undefined,
) => {
	return value === undefined || value == null || value.trim() === '';
};

export const cleanString = (value: string | undefined | null) => {
	value = value?.trim();
	if (value === '' || value === null) {
		value = undefined;
	}

	return value;
};

export const getCorrectArticle = (value: string) => {
	if (isStringUndefinedOrNullOrWhitespace(value)) return '';
	const firstChar = value.charAt(0).toLowerCase();
	if (
		firstChar === 'a' ||
		firstChar === 'e' ||
		firstChar === 'i' ||
		firstChar === 'o' ||
		firstChar === 'u'
	)
		return 'an';
	else return 'a';
};

export const convertToOrdinalNumber = (value: number) => {
	const finalDigit = value % 10;
	const finalTwoDigits = value % 100;

	if (finalDigit === 1 && finalTwoDigits !== 11) return `${value}st`;
	if (finalDigit === 2 && finalTwoDigits !== 12) return `${value}nd`;
	if (finalDigit === 3 && finalTwoDigits !== 13) return `${value}rd`;
	return `${value}th`;
};
