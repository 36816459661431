import { Divider, MenuItem } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EntityTypeId, getEntityType } from 'features/entity4/entity4Constants';
import { flow, makeAutoObservable } from 'mobx';
import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { ObjectPathParams, paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { getOptionsMenuColDef } from 'shared/utilities/dataGrid/dataGridUtils';
import { T4Link } from '../../../../shared/components/t4Link';
import { DeleteRelationshipViewModel } from '../components/deleteRelationshipViewModel';
import {
	AccountRelationshipsListModel,
	GeneralAccountRelationship,
} from './accountRelationshipsListModel';
import { CreateAccountRelationshipDrawerViewModel } from './createAccountRelationshipDrawerViewModel';
import {
	EditAccountRelationshipDrawerViewModel,
	rowToRelationshipData,
} from './editAccountRelationshipDrawerViewModel';

export type AllowedTab =
	| 'Signatories'
	| 'Holder and Owners'
	| 'Account to Account';

export class AccountRelationshipsViewModel {
	private readonly _entityId: string;
	private _isAuthor: boolean;

	private listDataModel: AccountRelationshipsListModel;

	public _createRelationshipViewModel: CreateAccountRelationshipDrawerViewModel;
	public readonly editAccountRelationshipDrawerViewModel: EditAccountRelationshipDrawerViewModel;
	public readonly deleteRelationshipViewModel: DeleteRelationshipViewModel;

	constructor(entityId: string, isAuthor: boolean) {
		makeAutoObservable(this);

		this._entityId = entityId;
		this._isAuthor = isAuthor;

		this.listDataModel = new AccountRelationshipsListModel(entityId);
		this._createRelationshipViewModel =
			new CreateAccountRelationshipDrawerViewModel(this._entityId, () =>
				this.load(),
			);
		this.editAccountRelationshipDrawerViewModel =
			new EditAccountRelationshipDrawerViewModel(this._entityId, () =>
				this.load(),
			);
		this.deleteRelationshipViewModel = new DeleteRelationshipViewModel(
			EntityTypeId.Account,
			this._entityId,
			() => this.load(),
		);
	}

	public getCreateViewModel = () => this._createRelationshipViewModel;

	public loading = () => this.listDataModel.loading;
	public error = () => this.listDataModel.error;
	public getEntityId = () => this._entityId;
	public isAuthor = () => this._isAuthor;
	public setIsAuthor = (isAuthor: boolean) => (this._isAuthor = isAuthor);

	// tab functions
	public getTabList = (): AllowedTab[] => [
		'Signatories',
		'Holder and Owners',
		'Account to Account',
	];

	public getEntityName = () => this.listDataModel.data?.entityName;

	// signatory tables
	public getSignatoryColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'name',
				headerName: 'Name',
				description: 'Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'title',
				headerName: 'Title',
				description: 'Title',
				flex: 1,
			},
			{
				field: 'email',
				headerName: 'Email',
				description: 'Email',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getSignatoryRows = () => this.listDataModel.data?.signatories ?? [];

	// holder functions
	public getHolderColumns = () => {
		const cols: GridColDef[] = [
			{
				field: 'counterpartyName',
				headerName: 'Counterparty Name',
				description: 'Counterparty Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getHolderRows = () => this.listDataModel.data?.holders ?? [];

	// account owner functions
	public getOwnerColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'ownerName',
				headerName: 'Owner Name',
				description: 'Owner Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getOwnerRows = () => this.listDataModel.data?.owners ?? [];

	public getAccountToAccountColumns = () => {
		const cols: GridColDef[] = [
			{
				field: 'fundingDirection',
				headerName: 'Funding Direction',
				description: 'Funding Direction',
			},
			{
				field: 'accountName',
				headerName: 'Account Name',
				description: 'Account Name',
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'accountStatus',
				headerName: 'Account Status',
				description: 'Account Status',
			},
			{
				field: 'counterpartyName',
				headerName: 'Counterparty Name',
				description: 'Counterparty Name',
			},
			{
				field: 'relationshipTypeName',
				headerName: 'Relationship Type',
				description: 'Relationship Type',
			},
			{
				field: 'cashFlowMovement',
				headerName: 'Cash Flow Movement',
				description: 'Cash Flow Movement',
			},
			{
				field: 'fundingFrequency',
				headerName: 'Funding Frequency',
				description: 'Funding Frequency',
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getSourceAccountRows = () =>
		this.listDataModel.data?.sourceAccounts ?? [];
	public getTargetAccountRows = () =>
		this.listDataModel.data?.targetAccounts ?? [];

	private addOptionsColumn = (cols: GridColDef[]) => {
		if (this._isAuthor) {
			cols.unshift({
				...getOptionsMenuColDef(
					(
						params: GridRenderCellParams<GeneralAccountRelationship, string>,
					) => {
						const relationship = rowToRelationshipData(params.row);

						return (
							<OverflowMenu
								id={`relationship-overflow-menu-${relationship.id}`}
							>
								<MenuItem
									onClick={() =>
										this.editAccountRelationshipDrawerViewModel.openDrawer(
											relationship,
										)
									}
								>
									Edit Relationship
								</MenuItem>
								<Divider />
								<DeleteMenuItem
									onClick={() =>
										this.deleteRelationshipViewModel.openModal(relationship.id)
									}
								/>
							</OverflowMenu>
						);
					},
				),
			});
		}

		return cols;
	};

	// relationships load function
	public load = flow(function* (this: AccountRelationshipsViewModel) {
		yield this.listDataModel.load();
		this._createRelationshipViewModel.initialize(
			this.getEntityName(),
			this.listDataModel.flatData,
			this.listDataModel.definitions,
			this.listDataModel.fundingDirections,
			this.listDataModel.cashFlowMovements,
			this.listDataModel.fundingFrequencies,
		);
		this.editAccountRelationshipDrawerViewModel.initialize(
			this.getEntityName(),
			this.listDataModel.fundingDirections,
			this.listDataModel.cashFlowMovements,
			this.listDataModel.fundingFrequencies,
		);
	});
}

export const useAccountRelationshipsViewModel = () => {
	const { objectId } = useParams<ObjectPathParams>();
	const { isAuthor } = useUser();
	const [viewModel] = useState(
		new AccountRelationshipsViewModel(objectId, isAuthor),
	);

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	useEffect(() => {
		viewModel.setIsAuthor(isAuthor);
	}, [isAuthor, viewModel]);

	return viewModel;
};
