import { Grid, IconButton } from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { useFormContext } from '../providers/FormProvider';
import RuleMatchCount from './RuleMatchCount';
import { isValidName } from '../validation';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useEffect, useState } from 'react';
import ModalBase from '../../../../../shared/components/modalBase';
import { useQuery } from '@tanstack/react-query';
import { fetchRules } from '../../services';

export interface RuleNameProps {
	setPriorityToLast: boolean;
}

const RuleName: React.FC<RuleNameProps> = ({ setPriorityToLast = false }) => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const {
		priority,
		selectPriority,
		name,
		selectName,
		selectIsManualCategorization,
	} = useFormContext();

	const { data: rules } = useQuery(['rules'], fetchRules);

	useEffect(() => {
		if (setPriorityToLast) {
			selectPriority((rules?.length as number) + 1);
		}

		selectIsManualCategorization(false);
	}, [rules]);

	return (
		<Grid container item xs={12}>
			<Grid item xs={12} mb={2}>
				<RuleMatchCount />
			</Grid>
			<Grid item xs={2} pr={1}>
				<T4TextFieldV2
					data-testid="rule-priority"
					label="Priority"
					value={priority.field}
					onChange={selectPriority}
					required
					inputProps={{
						type: 'number',
						min: 1,
						max: (rules?.length as number) + 1,
					}}
					startAdornment={
						<IconButton
							onClick={() => {
								setIsModalOpen(true);
							}}
						>
							<InfoOutlinedIcon />
						</IconButton>
					}
				/>
				<ModalBase
					title="Priority"
					open={isModalOpen}
					onClose={() => {
						setIsModalOpen(false);
					}}
				>
					Rules with the same or lower priority than the new value will move
					down one place in the list.
				</ModalBase>
			</Grid>
			<Grid item xs={10}>
				<T4TextFieldV2
					data-testid="rule-name"
					label="Rule Name"
					value={name.field}
					onChange={selectName}
					required
					error={!isValidName(name.field, name.dirty).valid}
					helperText={isValidName(name.field, name.dirty).helperText}
				/>
			</Grid>
		</Grid>
	);
};

export default RuleName;
