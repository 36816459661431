import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { FieldApprovalConfiguration } from './fieldApprovalConfiguration/fieldApprovalConfiguration';

export const ApprovalsPage: FC = observer(() => {
	const { isAdmin } = useUser();

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.approvalsPage}
					title="Approvals"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
			hasAccess={isAdmin}
		>
			<FieldApprovalConfiguration />
		</T4View>
	);
});
