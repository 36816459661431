import { DateRange } from '@mui/x-date-pickers-pro';
import { makeAutoObservable } from 'mobx';
import { Moment } from 'moment';
import AuditModel from './auditModel';

class AuditViewModel {
	private model: AuditModel;

	constructor() {
		makeAutoObservable(this);
		this.model = new AuditModel();
	}

	public load = async () => {
		await this.model.load();
	};

	public getLoading = () => this.model.getLoading();
	public getError = () => this.model.getError();

	public getAudits = () => [...this.model.getAudits()];

	public getDateRange = (): DateRange<Moment | null> => [
		this.model.getStartDate(),
		this.model.getEndDate(),
	];
	public updateDateRange = (range: DateRange<Moment>) => {
		const [start, end] = range;

		this.model.setStartDate(start);
		this.model.setEndDate(end);
	};

	public onDateSelect = () => {
		this.model.load();
	};
}

export default AuditViewModel;
