import { customerApi } from 'shared/providers/customerApi';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { ApiResponse } from '../../../../../../utilities/api';
import { EntityConfig, EntityType } from '../../../../entity4Constants';
import { TaxIdData } from './taxId';
import { TaxIdListItemData } from './taxIds';

const taxIdStatusesOptionListId = '3D6D5576-FA38-4F73-9AB6-39FB47601C83';
const taxIdPurposesOptionListId = 'C5A4AC62-1B65-4EF9-950C-00CFFE8F0CE0';

export class TaxIdRepository {
	private _entityType: string;

	constructor(entityType: EntityType) {
		this._entityType = EntityConfig.get(entityType)!.controllerRoute;
	}

	public async getTaxId(entityId: string, taxIdId: string) {
		const response = await customerApi.get<ApiResponse<TaxIdData>>(
			`${this._entityType}/${entityId}/taxids/${taxIdId}`,
			{
				params: {
					entityId: entityId,
					taxIdId: taxIdId,
				},
			},
		);

		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async getTaxIds(entityId: string) {
		const response = await customerApi.get<ApiResponse<TaxIdListItemData[]>>(
			`${this._entityType}/${entityId}/taxids`,
		);

		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async createTaxId(
		entityId: string,
		countryReferenceData: string | null,
		stateProvinceReferenceData: string | null,
	) {
		const response = await customerApi.post<ApiResponse<string>>(
			`${this._entityType}/${entityId}/taxids`,
			{
				entityId: entityId,
				countryReferenceData: countryReferenceData,
				stateReferenceData: stateProvinceReferenceData,
			},
		);

		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async updateTaxId(
		entityId: string,
		taxIdId: string,
		purposes: string[],
		isPrimaryFederalIncomeTaxId: boolean | null,
		countryReferenceData: string,
		taxIdNumber: string | null,
		issuedDate: Date | null,
		taxIdTypeReferenceData: string | null,
		stateReferenceData: string | null,
		countyParish: string | null,
		city: string | null,
		taxIdStatusOptionId: string | null,
		issuingAuthorityReferenceData: string | null,
	) {
		const response = await customerApi.patch<ApiResponse<string>>(
			`${this._entityType}/${entityId}/taxids/${taxIdId}`,
			{
				purposes: purposes,
				isPrimaryFederalIncomeTaxId: isPrimaryFederalIncomeTaxId,
				countryReferenceData: countryReferenceData,
				taxIdNumber: taxIdNumber,
				issuedDate: issuedDate,
				taxIdTypeReferenceData: taxIdTypeReferenceData,
				stateReferenceData: stateReferenceData,
				countyParish: countyParish,
				city: city,
				taxIdStatusOptionId: taxIdStatusOptionId,
				issuingAuthorityReferenceData: issuingAuthorityReferenceData,
			},
		);

		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async makePrimaryFederalIncomeTaxId(
		entityId: string,
		taxIdId: string,
	) {
		const response = await customerApi.post<ApiResponse<string>>(
			`${this._entityType}/${entityId}/taxids/${taxIdId}/makeprimaryfederalincometaxid`,
		);

		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async deleteTaxId(entityId: string, taxIdId: string) {
		const response = await customerApi.delete<ApiResponse<string>>(
			`${this._entityType}/${entityId}/taxids/${taxIdId}`,
		);

		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async getTaxIdPurposes() {
		const response = await customerApi.get<ApiResponse<Option[]>>(
			`/fields/optionlist/${taxIdPurposesOptionListId}`,
		);

		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async getCountries() {
		const response = await customerApi.get<ReferenceDataValue[]>(
			`/common/collections/Countries`,
		);

		if (response.status === 200) return response.data;

		// eslint-disable-next-line no-throw-literal
		throw 'An error occured.';
	}

	public async getTaxIdTypes(
		countryCode: string | null,
	): Promise<ReferenceDataValue[]> {
		const response = await customerApi.get<ReferenceDataValue[]>(
			'/common/collections/taxidtypes',
			{ params: { countryCode: countryCode } },
		);

		if (response.status === 200) return response.data;

		// eslint-disable-next-line no-throw-literal
		throw 'An error occured.';
	}

	public async getStateProvinces(
		countryCode: string | null,
	): Promise<ReferenceDataValue[]> {
		const response = await customerApi.get<ReferenceDataValue[]>(
			'/common/collections/CountrySubdivisions',
			{ params: { parentIdentifier: countryCode } },
		);

		if (response.status === 200) return response.data;

		// eslint-disable-next-line no-throw-literal
		throw 'An error occured.';
	}

	public async getTaxIdStatuses() {
		const response = await customerApi.get<ApiResponse<Option[]>>(
			`/fields/optionlist/${taxIdStatusesOptionListId}`,
		);

		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async getTaxIdIssuingAuthorities(
		countryCode: string | null,
		stateProvinceCode: string | null,
	): Promise<ReferenceDataValue[]> {
		const response = await customerApi.get<ReferenceDataValue[]>(
			'/common/collections/taxidissuingauthorities',
			{
				params: {
					countryCode: countryCode,
					stateProvinceCode: stateProvinceCode,
				},
			},
		);

		if (response.status === 200) return response.data;

		// eslint-disable-next-line no-throw-literal
		throw 'An error occured.';
	}
}
