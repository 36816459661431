import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Tooltip } from '@mui/material';
import React, { useState } from 'react';

interface CopyContentProps {
	label?: string;
	value: string;
}

export const CopyContent: React.FC<CopyContentProps> = ({
	label = 'Copy Text',
	value,
}) => {
	const [tooltipTitle, setTooltipTitle] = useState('Copy to Clipboard');

	const copyToClipboard = async (text: string) => {
		try {
			await navigator.clipboard.writeText(text);
			setTooltipTitle('Copied!');
			setTimeout(() => setTooltipTitle('Copy to Clipboard'), 2000);
		} catch (err) {
			setTooltipTitle('Failed to copy!');
			setTimeout(() => setTooltipTitle('Copy to Clipboard'), 2000);
		}
	};

	return (
		<Tooltip title={tooltipTitle} enterDelay={300} leaveDelay={200}>
			<Button
				size="small"
				startIcon={<ContentCopyIcon />}
				onClick={() => copyToClipboard(value)}
			>
				{label}
			</Button>
		</Tooltip>
	);
};
