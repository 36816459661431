/* eslint-disable mobx/missing-observer */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Tooltip, useTheme } from '@mui/material';
import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid-pro';
import { Cash4Account } from 'models/apiModels';
import { brandColors } from 'shared/theme/brandColors';
import { formatDateSimpleUpperCase } from 'shared/utilities/dateUtilities';
import { stonlyIds } from '..';
import ActionButton from '../components/ActionButton';
import E4Logo from './E4_logo';

const e4Columns: GridColDef[] = [
	{
		field: 'displayName',
		headerName: 'Account Display Name',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.linkedE4Account?.displayName,
	},
	{
		field: 'naturalAccountNumber',
		headerName: 'Natural Account Number',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.linkedE4Account?.naturalAccountNumber,
	},
	{
		field: 'priorDayStatementsAccountNumber',
		headerName: 'Prior Day Statements Account Number',
		type: 'string',
		flex: 1,
		valueGetter: (params) =>
			params.row.linkedE4Account?.priorDayStatementAccountNumber,
	},
];

const c4Columns: GridColDef[] = [
	{
		field: 'accountIdentifier',
		headerName: 'Account Identifier',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.accountIdentifier,
	},
	{
		field: 'bankName',
		headerName: 'Bank Name',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.bankName,
	},
	{
		field: 'lastStatementDate',
		headerName: 'Last Statement Date',
		type: 'string',
		flex: 1,
		valueGetter: (params) => {
			const postDate = params.row.latestSyncEvent?.postDate;
			return postDate ? formatDateSimpleUpperCase(postDate) : '';
		},
	},
];

const columns: GridColDef[] = [
	{
		field: 'actions',
		headerName: '',
		width: 30,
		renderCell: (params) => {
			return <ActionButton row={params.row} />;
		},
		resizable: false,
		sortable: false,
		disableColumnMenu: true,
		filterable: false,
	},
	{
		field: 'e4Logo',
		headerName: '',
		width: 40,
		renderCell: (params) => {
			const isLinked = params.row.isLinked;
			return (
				<Tooltip title={isLinked ? 'Linked' : 'Not linked'}>
					<span
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<E4Logo
							fillColor={isLinked ? brandColors.equityGreen['500'] : '#b2b3b3'}
							size={32}
						/>
					</span>
				</Tooltip>
			);
		},
		resizable: false,
		sortable: false,
		disableColumnMenu: true,
		filterable: false,
	},
	{
		field: 'active',
		headerName: '',
		width: 40,
		renderCell: (params) => {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const theme = useTheme();
			const status = params.row.latestSyncEvent?.status;
			let color;
			switch (status) {
				case 'Completed':
					color = brandColors.equityGreen['500'];
					break;
				case 'Errored':
					color = brandColors.errorRed.A100;
					break;
				case 'Processing':
				case 'Queued':
				case 'Staged':
				case 'Triggered':
					color = theme.palette.warning.main;
					break;
				default:
					color = '#b2b3b3';
					break;
			}
			return (
				<Tooltip title={status}>
					<CircleIcon
						sx={{
							color: color,
							fontSize: '16px',
						}}
					/>
				</Tooltip>
			);
		},
		resizable: false,
		sortable: false,
		disableColumnMenu: true,
		filterable: false,
	},
	...e4Columns,
	...c4Columns,
];

const ignoredColumns = (
	handleButtonClick: (account: Cash4Account) => void,
): GridColDef[] => [
	{
		field: 'LinkAccount',
		headerName: '',
		flex: 1,
		renderCell: (params) => {
			return (
				<Button
					variant="text"
					size="small"
					onClick={() => handleButtonClick(params.row as Cash4Account)}
					data-stonlyid={stonlyIds.linkAccountInlineGridButton}
				>
					Link Account
				</Button>
			);
		},
		filterable: false,
	},
	{
		field: 'IgnoredDateTime',
		headerName: 'Ignored Date/Time',
		type: 't4DateTime',
		flex: 1,
	},
	{
		field: 'IgnoredBy',
		headerName: 'Ignored By',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.ignoredByEmail,
	},
	{
		field: 'AccountIdentifier',
		headerName: 'Account Identifier',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.accountIdentifier,
	},
	{
		field: 'BankName',
		headerName: 'Bank Name',
		type: 'string',
		flex: 1,
		valueGetter: (params) => params.row.bankName,
	},
	{
		field: 'postDate',
		headerName: 'Last Statement Date',
		type: 't4Date',
		flex: 1,
	},
];

const columnGroupingModel: GridColumnGroupingModel = [
	{
		groupId: 'Entity4 Data',
		children: e4Columns,
	},
	{
		groupId: 'Cash4 Data',
		children: c4Columns,
	},
];

export { columnGroupingModel, columns, ignoredColumns };

export const ExpandIcon = () => (
	<ChevronRightIcon data-stonlyid={stonlyIds.expandButton} />
);
export const CollapseIcon = () => (
	<ExpandMoreIcon data-stonlyid={stonlyIds.collapseButton} />
);

export const columnsAccountEventsHistory: GridColDef[] = [
	{
		headerName: 'Imported Date',
		field: 'importedDateTime',
		width: 200,
		type: 't4DateTime',
	},
	{
		headerName: 'Statement Date',
		field: 'statementDate',
		flex: 1,
		type: 't4Date',
	},
	{
		headerName: 'Bank Code',
		field: 'bankCode',
		flex: 1,
	},
	{
		headerName: 'Connector Name',
		field: 'connectorName',
		flex: 1,
	},
	{
		headerName: 'Display Name',
		field: 'displayName',
		flex: 1,
	},
	{
		headerName: 'Status',
		field: 'status',
		flex: 1,
	},
	{
		headerName: 'Error Message',
		field: 'errorMessage',
		flex: 1,
	},
	{
		headerName: 'Count of Balance Details',
		field: 'balanceDetailsCount',
		type: 'number',
		flex: 1,
	},
	{
		headerName: 'Count of Transaction Details',
		field: 'transactionDetailsCount',
		type: 'number',
		flex: 1,
	},
];
