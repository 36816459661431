import { Link as MUILink, LinkProps as MUILinkProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export type T4LinkProps = {
	disableTextDecoration?: boolean;
	children: ReactNode;
} & MUILinkProps &
	LinkProps;

export const T4Link: FC<T4LinkProps> = ({
	disableTextDecoration = false,
	component: _,
	...rest
}) => (
	<MUILink
		component={Link}
		{...rest}
		sx={{
			...rest?.sx,
			...(disableTextDecoration && {
				textDecoration: 'none',
			}),
		}}
	/>
);
