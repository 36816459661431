import { Box, Divider, Grid } from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { FormModal } from '../../../../../shared/components/formModal';
import { EntityCollection } from '../../../entities/objects/entityCollection';
import { PhoneListView } from '../../../fields/components/phoneListView';
import { PhoneList } from '../../../fields/objects/phoneList';
import { CreateFieldSetButton } from '../createFieldSetButton';
import { FieldSetViewProps } from '../fieldSetResolver';
import { FieldViews } from '../fieldViews/fieldViewTypes';

const fieldPrefix = 't4_field_phone_';

export const PhoneFieldSetViewTestable: FC<
	FieldSetViewProps & { phoneList: PhoneList }
> = observer(({ entity, fieldSetDefinition, isReadOnly, phoneList }) => {
	const { viewType } = useProfileView();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [phoneNumberToDelete, setPhoneNumberToDelete] =
		useState<EntityCollection | null>(null);

	const openDeleteModal = (collection: EntityCollection) => {
		setDeleteModalOpen(true);
		setPhoneNumberToDelete(collection);
	};

	const closeDeleteModal = () => {
		setPhoneNumberToDelete(null);
		setDeleteModalOpen(false);
	};

	const collectionEntries = entity.getCollectionEntries('t4_fieldset_phone');

	const phoneTypeDefinition = fieldSetDefinition.getFieldDefinition(
		`${fieldPrefix}type`,
	);
	const countryCodeDefinition = fieldSetDefinition.getFieldDefinition(
		`${fieldPrefix}countrycode`,
	);
	const extensionDefinition = fieldSetDefinition.getFieldDefinition(
		`${fieldPrefix}extension`,
	);
	const phoneNumberDefinition = fieldSetDefinition.getFieldDefinition(
		`${fieldPrefix}number`,
	);

	return (
		<Box>
			<Grid container>
				{viewType === FieldViews.edit && (
					<Grid item>
						<CreateFieldSetButton
							fieldSetDefinition={fieldSetDefinition}
							entity={entity}
						/>
					</Grid>
				)}
				{phoneList.items.map((collection, index) => {
					return (
						<Grid container item key={`phone-list-container-${collection.id}`}>
							<PhoneListView
								phoneList={phoneList}
								collection={collection}
								fieldPrefix={fieldPrefix}
								phoneTypeDefinition={phoneTypeDefinition!}
								countryCodeDefinition={countryCodeDefinition!}
								extensionDefinition={extensionDefinition!}
								phoneNumberDefinition={phoneNumberDefinition!}
								key={`${entity.id}-${collection!.id}-${
									phoneTypeDefinition!.identifier
								}`}
								openDeleteModal={openDeleteModal}
								isReadOnly={isReadOnly}
							/>
							{index < collectionEntries.length - 1 ? (
								<Grid item xs={12} paddingY={4}>
									<Divider />
								</Grid>
							) : null}
						</Grid>
					);
				})}
				{deleteModalOpen ? (
					<FormModal
						open={deleteModalOpen}
						onClose={closeDeleteModal}
						title="Delete Phone Number"
						onSubmit={async () => {
							await phoneList.deleteItem(phoneNumberToDelete!);
							closeDeleteModal();
						}}
						description={`Are you sure you want to delete this phone number?`}
						error={''}
						loading={false}
						submitButtonLabel="Delete"
						loadingMessage="Deleting..."
					/>
				) : null}
			</Grid>
		</Box>
	);
});

export const PhoneFieldSetView: FC<FieldSetViewProps> = observer(
	({ entity, fieldSetDefinition, ...props }) => {
		if (entity.loading) return null;

		return (
			<PhoneFieldSetViewTestable
				entity={entity}
				fieldSetDefinition={fieldSetDefinition}
				phoneList={new PhoneList(entity, fieldSetDefinition)}
				{...props}
			/>
		);
	},
);
