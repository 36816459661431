import { useContext } from 'react';
import { useUser } from 'shared/hooks/useUser';
import {
	AdminViewModeContext,
	AdminViewModes,
} from '../providers/contexts/adminViewModeContext';

export type UseAdminViewModeProps = {
	viewMode: AdminViewModes;
	setViewMode: (adminViewMode?: AdminViewModes | null) => void;
};

export function useAdminViewMode(): UseAdminViewModeProps {
	const { isAdmin } = useUser();
	const { viewMode, setViewMode } = useContext(AdminViewModeContext);

	return {
		viewMode: viewMode,
		setViewMode: (viewMode) => {
			if (isAdmin && viewMode === AdminViewModes.edit) {
				setViewMode(viewMode);
			} else {
				setViewMode(AdminViewModes.default);
			}
		},
	};
}
