import { flow, makeAutoObservable } from 'mobx';
import { parseError } from 'utilities/errors/errorUtils';
import { FrontendGroupRepository, GroupEntityDto } from '../groupRepository';

export class UngroupedEntitiesListModel {
	private _frontendGroupRepository: FrontendGroupRepository;
	private _loading: boolean;
	private _error: string;

	private _ungroupedEntities: GroupEntityDto[];

	constructor(frontendGroupRepository: FrontendGroupRepository) {
		makeAutoObservable(this);
		this._frontendGroupRepository = frontendGroupRepository;

		this._loading = false;
		this._error = '';
		this._ungroupedEntities = [];
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get ungroupedEntities() {
		return this._ungroupedEntities;
	}

	public load = flow(function* (this: UngroupedEntitiesListModel) {
		try {
			this._loading = true;
			this._error = '';

			const response =
				yield this._frontendGroupRepository.getUngroupedEntities();
			this._ungroupedEntities = response.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}
