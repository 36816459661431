import CommonRegistrationRepository, {
	RegistrationRepository,
} from './registrationRepository';
import { Registration } from './models/registration';
import { IRegistrationDto } from './registrationTypes';
import { CacheMap } from './registrationUtils';
import { ListModel } from './models/listModel';
import { StoreOperation } from '../../../utilities/storeOperation';

export class RegistrationStore {
	private _regRepo: RegistrationRepository;

	private _regById: CacheMap<Registration['id'], Registration>;
	private _regsByEntityId: CacheMap<
		Registration['entityId'],
		ListModel<Registration>
	>;

	public expiring: StoreOperation<undefined, IRegistrationDto[]>;

	constructor(registrationRepository: RegistrationRepository) {
		this._regRepo = registrationRepository;

		this.expiring = new StoreOperation<undefined, IRegistrationDto[]>(
			this._regRepo.getExpiringRegistrations
		);

		this._regById = new CacheMap(async (regId) => {
			const dto = await this._regRepo.getByRegistrationId(regId);
			const model = Registration.make(dto);
			return model;
		});

		// This is a little funky because we're updating the other one in here
		// Probably a smell that this thing is falling apart
		// Not as reusable as it first appeared - early optimization, etc.
		this._regsByEntityId = new CacheMap(async (entityId) => {
			const dtos = await this._regRepo.getByEntityId(entityId);
			const regs = await Promise.all(
				dtos.map(async (dto) => {
					const id = dto.registrationId;
					let reg: Registration;
					// Check whether we already have in the other map.
					if (this._regById.has(id)) {
						// If so, update in-place.
						reg = await this._regById.retrieve(id);
						reg.sync(dto);
					} else {
						// Otherwise, make a new one and put it there.
						reg = Registration.make(dto);
						this._regById.replaceSync(id, reg);
					}
					return reg;
				})
			);
			const regList = ListModel.make(
				'Registration',
				regs,
				(regToMatch: Registration) => (arrReg: Registration) => {
					return regToMatch.id === arrReg.id;
				}
			);
			return regList;
		});
	}

	public async getRegById(regId: string): Promise<Registration> {
		return await this._regById.retrieve(regId);
	}

	public async getListByEntityId(
		entityId: string
	): Promise<ListModel<Registration>> {
		return await this._regsByEntityId.retrieve(entityId);
	}

	public async create(
		entityId: string,
		partial: Partial<Omit<IRegistrationDto, 'registrationId'>> = {}
	) {
		// It is weird to have to get the entity ID list
		const list = await this.getListByEntityId(entityId);
		const dto = await this._regRepo.create({
			...partial,
			entityId,
		});
		const newItem = Registration.make(dto);
		// It is also weird to have to do this manually
		this._regById.replaceSync(dto.registrationId, newItem);
		list.add(newItem);
		return newItem;
	}

	public async update<T extends keyof IRegistrationDto>(
		item: Registration,
		key: T,
		value: IRegistrationDto[T]
	): Promise<Registration> {
		const {
			// Exclude to prevent overwriting
			approvedBy,
			approvedDate,
			entityId,
			registrationId,
			updatedBy,
			updatedDate,

			...safeDto
		} = item.asDto();

		const modifiedDto = {
			...safeDto,
			[key]: value,

			// Include with highest precedence
			entityId,
			registrationId,
		} as IRegistrationDto;
		const updatedDto = await this._regRepo.update(modifiedDto);
		item.sync(updatedDto);

		// Force refresh list when home registration changed
		if (key === 'isHomeRegistration' && value) {
			await this._regsByEntityId.retrieve(entityId, true);
		}

		return item;
	}

	public async remove(item: Registration): Promise<void> {
		const list = await this.getListByEntityId(item.entityId);
		list.remove(item);
		await this._regRepo.delete(item.asDto());
	}
}

const CommonRegistrationStore = new RegistrationStore(
	CommonRegistrationRepository
);
export default CommonRegistrationStore;
