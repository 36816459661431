import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';
import { Write } from '../../transactions';

// #region Models

export type LegalEntityGroupCollection = {
	id: string;
	name: string;
	description?: string;
	isDefault: boolean;
	updatedBy: string;
	updatedDate: string;
	createdBy: string;
	createdDate: string;
	legalEntityGroups: LegalEntityGroup[];
};

export type LegalEntityGroup = {
	id: string;
	name: string;
	description?: string;
	updatedBy: string;
	updatedDate: string;
	createdBy: string;
	createdDate: string;
	legalEntities: LegalEntity[];
};

export type LegalEntity = {
	id: string;
	code: string;
	displayName: string;
};

// #endregion

// #region Requests

export type LegalEntityGroupCollectionRequest = {
	name: string;
	description: string | null;
	legalEntityGroupIds: string[];
};

export type CreateLegalEntityGroupRequest = {
	name: string;
	description: string | null;
	collectionId: string;
	legalEntityIds: string[];
};

export type UpdateLegalEntityGroupRequest = {
	name: string;
	description: string | null;
	collectionId: string;
	legalEntityIdsToAdd: string[];
	legalEntityIdsToRemove: string[];
};

// #endregion

export type LegalEntityGroupEndpoints = {
	getAllCollections: Read<T4DataResponse<LegalEntityGroupCollection[]>>;
	createCollection: Write<
		T4DataResponse<string>,
		LegalEntityGroupCollectionRequest
	>;
	updateCollection: Write<
		T4DataResponse<string>,
		{ id: string; data: LegalEntityGroupCollectionRequest }
	>;
	deleteCollection: Write<T4DataResponse<string>, string>;
	createGroup: Write<T4DataResponse<string>, CreateLegalEntityGroupRequest>;
	updateGroup: Write<
		T4DataResponse<string>,
		{ id: string; data: UpdateLegalEntityGroupRequest }
	>;
	deleteGroup: Write<T4DataResponse<string>, string>;
};

export function legalEntityGroups(
	axiosInstance: AxiosInstance,
): LegalEntityGroupEndpoints {
	return {
		getAllCollections: async (
			_,
			config = {},
		): Promise<T4Response<T4DataResponse<LegalEntityGroupCollection[]>>> => {
			return await axiosInstance.get(
				'api/entity4/legalentitygroups/collections',
				{
					...config,
				},
			);
		},
		createCollection: async (data, config = {}) => {
			return await axiosInstance.post(
				'api/entity4/legalentitygroups/collections',
				data,
				{
					...config,
				},
			);
		},
		updateCollection: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.put(
				`api/entity4/legalentitygroups/collections/${id}`,
				data,
				{
					...config,
				},
			);
		},
		deleteCollection: async (id, config = {}) => {
			return await axiosInstance.delete(
				`api/entity4/legalentitygroups/collections/${id}`,
				{
					...config,
				},
			);
		},
		createGroup: async (data, config = {}) => {
			return await axiosInstance.post('api/entity4/legalentitygroups', data, {
				...config,
			});
		},
		updateGroup: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.put(
				`api/entity4/legalentitygroups/${id}`,
				data,
				{
					...config,
				},
			);
		},
		deleteGroup: async (id, config = {}) => {
			return await axiosInstance.delete(`api/entity4/legalentitygroups/${id}`, {
				...config,
			});
		},
	};
}
