import { Grid, Typography } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { CashFlowType } from '../categoriesViewModel';
import { EditSubtypeViewModel } from './editSubtypeViewModel';

export interface EditSubtypeModalProps {
	viewModel: EditSubtypeViewModel;
}
export const EditSubtypeModal: FC<EditSubtypeModalProps> = observer(
	({ viewModel }) => (
		<FormModal
			open={viewModel.isOpen()}
			onClose={() => viewModel.close()}
			submitButtonLabel="Save Changes"
			onSubmit={() => viewModel.save()}
			submitDisabled={viewModel.isSubmitDisabled()}
			title="Edit Cash Flow Subtype"
			description="Modify the name and corresponding code for this cash flow subtype."
			error={viewModel.getError() ?? ''}
			loading={viewModel.isLoading()}
		>
			<Grid container rowSpacing={1}>
				<Grid item xs={12}>
					<T4Autocomplete
						label="Cash Flow Class (CFC)"
						options={viewModel.getClasses()}
						getOptionLabel={(_class) => _class.name}
						value={viewModel.getClass() ?? null}
						required
						readOnly
					/>
				</Grid>
				<Grid item xs={12}>
					<T4Autocomplete<CashFlowType>
						data-testid="cash-flow-type"
						label="Cash Flow Type (CFT)"
						options={viewModel.getTypes()}
						getOptionLabel={(option) => option.name}
						value={viewModel.getType() ?? null}
						required
						readOnly
					/>
				</Grid>
				<Grid item xs={12}>
					<T4TextFieldV2
						label="Cash Flow Subtype (CFST)"
						value={viewModel.getName() ?? ''}
						onChange={(value) => viewModel.setName(value)}
						required
					/>
					<Typography variant="caption" paddingLeft={1}>
						Provide a name for the cash flow subtype.
					</Typography>
					<T4AlertStack errors={viewModel.getNameErrors()} />
				</Grid>
				<Grid item xs={12}>
					<T4TextFieldV2
						label="Cash Flow Subtype Code"
						value={viewModel.getCode() ?? ''}
						onChange={(value) => viewModel.setCode(value)}
						required
					/>
					<Typography variant="caption" paddingLeft={1}>
						Provide a four-letter code for the cash flow subtype.
					</Typography>
					<T4AlertStack errors={viewModel.getCodeErrors()} />
				</Grid>
			</Grid>
		</FormModal>
	),
);
