import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { FeatureFlag } from 'shared/constants/featureFlagConstants';

export type UseT4FeatureFlagsProps = {
	cash4Enabled: boolean;
	orgChartV2Enabled: boolean;
	t4DarkModeEnabled: boolean;
	payments4Module: boolean;
	entity4StaffPii: boolean;
	entity4AccountMapV2Enabled: boolean;
	sigmaStudioEnabled: boolean;
	investmentTransactionsEnabled: boolean;
	projectedTransactionsEnabled: boolean;
};

export function useT4FeatureFlags(): UseT4FeatureFlagsProps {
	const flags = useFlags();

	const {
		cash4CategorizationMvp,
		orgChartV2,
		t4DarkMode,
		payments4Module,
		entity4StaffPii,
		entity4AccountMapV2,
		sigmaStudio,
		investmentTransactions,
		projectedTransactions,
	} = FeatureFlag;

	const t4FeatureFlags = useMemo(
		() => ({
			cash4Enabled: flags[cash4CategorizationMvp],
			orgChartV2Enabled: flags[orgChartV2],
			t4DarkModeEnabled: flags[t4DarkMode],
			payments4Module: flags[payments4Module],
			entity4StaffPii: flags[entity4StaffPii],
			entity4AccountMapV2Enabled: flags[entity4AccountMapV2],
			sigmaStudioEnabled: flags[sigmaStudio],
			investmentTransactionsEnabled: flags[investmentTransactions],
			projectedTransactionsEnabled: flags[projectedTransactions],
		}),
		[
			flags,
			cash4CategorizationMvp,
			orgChartV2,
			t4DarkMode,
			payments4Module,
			entity4StaffPii,
			entity4AccountMapV2,
			sigmaStudio,
			investmentTransactions,
			projectedTransactions,
		],
	);

	return t4FeatureFlags;
}
