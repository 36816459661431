import {
	Box,
	Grid,
	Link as MUILink,
	Skeleton,
	Typography,
} from '@mui/material';
import {
	GridCellParams,
	GridColDef,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { GroupRoute } from 'features/administration/pages/groupProfile';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { stonlyData } from 'stonly/functions';
import {
	USER_PREFERENCES_FIELD_OPTIONS,
	getOptionsMenuColDef,
} from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { GroupDto } from '../groupRepository';
import { UngroupedEntitiesListRoute } from '../ungroupedEntitiesList/ungroupedEntitiesList';
import { useGroupNavigation } from '../useGroupNavigation';
import { AddGroupButton } from './addGroupButton';
import { GroupOptionsMenu } from './groupOptionsMenu';
import { useGroupList } from './hooks/useGroupList';

export const GroupsRoute: FC = observer(() => {
	const { isAdmin } = useUser();

	if (!isAdmin) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
				buttonHref={ACCESS_DENIED_MESSAGING.BUTTON_HREF}
				buttonText={ACCESS_DENIED_MESSAGING.BUTTON_TEXT}
			/>
		);
	}

	return (
		<Switch>
			<Route exact path={paths.administration.groups.href}>
				<GroupListPage />
			</Route>
			<Route exact path={paths.administration.groups.ungroupedEntities.href}>
				<UngroupedEntitiesListRoute />
			</Route>
			<Route path={paths.administration.groups.group.href.concat(validIdRegex)}>
				<GroupRoute />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
});

export const stonlyIds = {
	ungroupedEntitiesListLink: 'ungrouped-entities-list-link',
	groupList: 'group-list',
};

export const dataTestIds = {
	ungroupedEntitiesError: 'ungrouped-entities-error-alert',
	ungroupedEntitiesLoading: 'ungrouped-entities-loading',
	noUngroupedEntities: 'no-ungrouped-entities',
	ungroupedEntitiesCount: 'ungrouped-entities-count',
	ungroupedEntitiesListLink: 'ungrouped-entities-list-link',
	groupList: 'group-list',
};

export const GroupListPage: FC = observer(() => {
	const { isLoading, error, groups, ungroupedEntitiesCount, load } =
		useGroupList();
	const groupNavigation = useGroupNavigation();

	useEffect(() => {
		load();
	}, [load]);

	const columns = useMemo(
		(): GridColDef[] => [
			getOptionsMenuColDef<GroupDto>((params) => (
				<GroupOptionsMenu groupId={params.row.id} />
			)),
			{
				field: 'name',
				headerName: 'Name',
				flex: 7,
				minWidth: 150,
			},
			{
				field: 'description',
				headerName: 'Description',
				flex: 10,
				minWidth: 200,
			},
			{
				...getDateColumnDefinition(),
				field: 'updatedDate',
				headerName: 'Last Updated',
				flex: 5,
				minWidth: 100,
			},
			{
				field: 'entities',
				headerName: 'Entities',
				headerAlign: 'left',
				flex: 5,
				minWidth: 100,
				type: 'number',
				align: 'left',
				valueGetter: (params: GridValueGetterParams<GroupDto>) =>
					params.row.entityCount,
			},
			{
				field: 'members',
				headerName: 'Members',
				headerAlign: 'left',
				flex: 5,
				minWidth: 100,
				type: 'number',
				align: 'left',
				valueGetter: (params: GridValueGetterParams<GroupDto>) =>
					params.row.memberCount,
			},
			{
				...getDateColumnDefinition(),
				field: 'createdDate',
				headerName: 'Created Date',
				flex: 5,
				minWidth: 100,
			},
		],
		[],
	);

	const ungroupedEntitiesBanner = useMemo(() => {
		if (isLoading) {
			return (
				<Skeleton
					data-testid={dataTestIds.ungroupedEntitiesLoading}
					variant="rectangular"
				/>
			);
		}

		const getUngroupedEntitiesCount = () => {
			if (ungroupedEntitiesCount === 0) {
				return (
					<Typography data-testid={dataTestIds.noUngroupedEntities}>
						No ungrouped entities.
					</Typography>
				);
			}

			if (ungroupedEntitiesCount === 1) {
				return (
					<Typography data-testid={dataTestIds.noUngroupedEntities}>
						{'1 ungrouped entity. '}
						<MUILink
							component={Link}
							{...stonlyData({ id: stonlyIds.ungroupedEntitiesListLink })}
							data-testid={dataTestIds.ungroupedEntitiesListLink}
							to={paths.administration.groups.ungroupedEntities.href}
						>
							View List
						</MUILink>
					</Typography>
				);
			}

			return (
				<Typography data-testid={dataTestIds.ungroupedEntitiesCount}>
					{ungroupedEntitiesCount} ungrouped entities.{' '}
					<MUILink
						component={Link}
						{...stonlyData({ id: stonlyIds.ungroupedEntitiesListLink })}
						data-testid={dataTestIds.ungroupedEntitiesListLink}
						to={paths.administration.groups.ungroupedEntities.href}
					>
						View List
					</MUILink>
				</Typography>
			);
		};

		return <T4Alert severity="info">{getUngroupedEntitiesCount()}</T4Alert>;
	}, [isLoading, ungroupedEntitiesCount]);

	const onCellClick = useCallback(
		(params: GridCellParams<GroupDto, any, any>) => {
			if (params.field === USER_PREFERENCES_FIELD_OPTIONS) return;

			groupNavigation(params.row.id);
		},
		[groupNavigation],
	);

	return (
		<T4View
			loading={isLoading}
			header={
				<PageHeader
					id={pageHeaderStonlyIds.groupList}
					title="Security Groups"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
		>
			<Grid container sx={{ rowGap: 1.5 }}>
				<Grid
					container
					item
					xs={12}
					sx={{
						justifyContent: 'space-between',
						alignItems: 'end',
					}}
				>
					<Grid item>{ungroupedEntitiesBanner}</Grid>
					<Grid item>
						<AddGroupButton />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ height: '64vh' }}>
						<UserPreferencesDataGrid<GroupDto>
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							data-testid={dataTestIds.groupList}
							stonlyId={stonlyIds.groupList}
							tableKey="groupList"
							columns={columns}
							rows={groups}
							loading={isLoading}
							errorMessage={error}
							onCellClick={onCellClick}
						/>
					</Box>
				</Grid>
			</Grid>
		</T4View>
	);
});
