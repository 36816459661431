import {
	GridColumnOrderChangeParams,
	GridColumnVisibilityModel,
	GridDensity,
	GridFilterItem,
	GridFilterModel,
	GridLogicOperator,
	GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { GridColumnResizeParams } from '@mui/x-data-grid/models/params/gridColumnResizeParams';
import {
	TableColumnPreference,
	TableColumnPreferenceReq,
	TableCustomViewPreferences,
	TableCustomViewPreferencesReq,
	TableFilterPreferences,
	TableFilterPreferencesReq,
	TablePreferencesReq,
} from 'modules/clients/customer-api/src/userPreference';
import {
	MutableRefObject,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { ApiResponse } from 'utilities/api';
import { T4DataGridProps } from '../../components/dataGrid/dataGrid';
import {
	USER_PREFERENCES_UNHIDEABLE_FIELDS,
	sortColumnsBasedOnUserPreferences,
	updateColumnWidths,
} from '../../utilities/dataGrid/dataGridUtils';
import processColumnDefinitions from '../../utilities/dataGrid/processColumnDefinitions';
import { useUserTablePreference } from '../useUserTablePreference';

export type UseDataGridStateProps<TData extends GridValidRowModel> = Pick<
	T4DataGridProps<TData>,
	| 'density'
	| 'onDensityChange'
	| 'columnVisibilityModel'
	| 'onColumnVisibilityModelChange'
	| 'onColumnWidthChange'
	| 'onFilterModelChange'
	| 'filterModel'
	| 'columns'
	| 'rows'
	| 'onColumnOrderChange'
	| 'selectedCustomView'
	| 'customViewButton'
> & {
	isLoading: boolean;
	customViews: TableCustomViewPreferences[] | undefined;
	onCustomViewSelect: (customViewId: string) => Promise<void>;
	onCustomViewCreate: (
		customViewName: string,
	) => Promise<ApiResponse<TableCustomViewPreferences>>;
	onCustomViewDelete: (customViewId: string) => Promise<void>;
};

type InitialState = {
	columnVisibilityModel?: GridColumnVisibilityModel;
	filterModel?: GridFilterModel;
	density?: GridDensity;
};

export const useUserPreferencesDataGridState = <
	TData extends GridValidRowModel,
>(
	gridApiRef: MutableRefObject<GridApiPro>,
	tableCode: string,
	initialColumns: GridColDef[],
	rows: readonly TData[],
	calculateColumnWidths: boolean = false,
	sortColumns: boolean = true,
	initialState?: InitialState,
): UseDataGridStateProps<TData> => {
	const {
		isInitializing,
		tablePreferences,
		createUserCustomViewPreference,
		deleteUserCustomViewPreference,
		updateTablePreferences,
	} = useUserTablePreference(tableCode);

	const [hasInitalized, setHasInitalized] = useState<boolean>(false);
	const [columnVisibilityModel, setColumnVisibilityModel] =
		useState<GridColumnVisibilityModel>({});
	const [filterModel, setFilterModel] = useState<GridFilterModel>();

	const selectedCustomView = useMemo(
		() =>
			tablePreferences?.customViews?.find(
				(x) => x.id === tablePreferences?.selectedCustomViewId,
			),
		[tablePreferences?.customViews, tablePreferences?.selectedCustomViewId],
	);

	//#region Utility Functions

	const makeColumnVisibilityModel = useCallback<
		(
			columnPreferences?: TableColumnPreference[],
			initialState?: GridColumnVisibilityModel,
		) => GridColumnVisibilityModel
	>((columnPreferences, initialState) => {
		let columnVisibilityModel: GridColumnVisibilityModel = initialState || {};
		columnPreferences?.forEach((columnPreference) => {
			if (
				USER_PREFERENCES_UNHIDEABLE_FIELDS.includes(columnPreference.columnId)
			) {
				columnPreference.hide = false;
			} else {
				columnVisibilityModel[columnPreference.columnId] =
					!columnPreference.hide;
			}
		});

		return columnVisibilityModel;
	}, []);

	const makeFilterModel = useCallback<
		(
			filterPreferences?: TableFilterPreferences,
			initialState?: GridFilterModel,
		) => GridFilterModel | undefined
	>((filterPreferences, initialState) => {
		const filterModel: GridFilterModel | undefined = initialState || {
			items: [],
		};
		if (filterPreferences) {
			if (filterPreferences.logicOperator) {
				const logicOperator: GridLogicOperator | undefined =
					filterPreferences.logicOperator === GridLogicOperator.And
						? GridLogicOperator.And
						: filterPreferences.logicOperator === GridLogicOperator.Or
						? GridLogicOperator.Or
						: undefined;

				filterModel.logicOperator = logicOperator;
			}

			if ((filterPreferences.items?.length ?? 0) > 0) {
				const items: GridFilterItem[] =
					filterPreferences.items?.map((item) => {
						let filterItem: GridFilterItem = {
							id: item.id,
							field: item.field,
							operator: item.operator,
							value: item.value,
						};

						if (
							item &&
							item.value &&
							item.operator?.toLowerCase() === 'isanyof'
						) {
							const splitValue = item.value.split(',');
							filterItem.value = splitValue;
						}

						return filterItem;
					}) ?? [];

				filterModel.items = items;
			}
		}

		return filterModel;
	}, []);

	const getTableFilterPreferencesReq = useCallback<
		(filterModel?: GridFilterModel) => TableFilterPreferencesReq | undefined
	>(
		(filterModel) => {
			const currentFilter =
				filterModel || gridApiRef?.current?.state?.filter?.filterModel;
			if (currentFilter) {
				return {
					logicOperator: currentFilter?.logicOperator,
					items: currentFilter?.items
						.filter((item) => item.value !== null && item.value !== undefined)
						.map((item) => ({
							field: item.field,
							operator: item.operator,
							value:
								typeof item.value === 'object'
									? item.value?.join(',')
									: item.value,
						})),
				};
			}
		},
		[gridApiRef],
	);

	const getTableColumnPreferenceReqs = useCallback<
		() => TableColumnPreferenceReq[]
	>(() => {
		const visibleColumns = gridApiRef?.current?.getVisibleColumns?.() ?? [];

		const currentColumnPreferences: TableColumnPreferenceReq[] =
			gridApiRef?.current?.getAllColumns?.().map((column) => {
				const isVisible =
					visibleColumns.find((x) => x.field === column.field) !== undefined;
				const width = gridApiRef?.current?.getColumn(column.field)
					.computedWidth;
				const columnIndex = gridApiRef?.current?.getColumnIndex(
					column.field,
					false,
				);

				return {
					columnId: column.field,
					hide: !isVisible,
					sortOrder: columnIndex + 5,
					width: width,
				};
			});

		return currentColumnPreferences;
	}, [gridApiRef]);

	const getTablePreferencesReq = useCallback<
		(filterModel?: GridFilterModel) => TablePreferencesReq
	>(
		(filterModel) => {
			return {
				selectedCustomViewId: selectedCustomView?.id,
				tableFilter: getTableFilterPreferencesReq(filterModel),
				columnPreferences: getTableColumnPreferenceReqs(),
			};
		},
		[
			getTableColumnPreferenceReqs,
			getTableFilterPreferencesReq,
			selectedCustomView?.id,
		],
	);

	const loadPreferences = useCallback(
		(
			filterPreferences?: TableFilterPreferences,
			columnPreferences?: TableColumnPreference[],
			initialState?: {
				filterModel?: GridFilterModel;
				columnVisibilityModel?: GridColumnVisibilityModel;
			},
		) => {
			const filterModel = makeFilterModel(
				filterPreferences,
				initialState?.filterModel,
			);
			setFilterModel(filterModel);

			const initialColumnVisibilityModel = makeColumnVisibilityModel(
				columnPreferences,
				initialState?.columnVisibilityModel,
			);
			setColumnVisibilityModel(initialColumnVisibilityModel);
		},
		[makeColumnVisibilityModel, makeFilterModel],
	);

	//#endregion

	const mergedColumnDefinitions = useMemo(() => {
		if (initialColumns.length > 0 && rows.length > 0 && !isInitializing) {
			let mergedColumnDefinitions = updateColumnWidths(
				tablePreferences?.columnPreferences || [],
				initialColumns,
				selectedCustomView,
			);

			// if table should have calculated column widths, process those that need calculation
			if (calculateColumnWidths) {
				mergedColumnDefinitions = processColumnDefinitions(
					mergedColumnDefinitions,
					columnVisibilityModel,
					rows,
				);
			}

			// If columns need to be sorted from user preferences.
			if (sortColumns) {
				mergedColumnDefinitions = sortColumnsBasedOnUserPreferences(
					tablePreferences?.columnPreferences,
					mergedColumnDefinitions,
					selectedCustomView,
				);
			}

			return mergedColumnDefinitions;
		}

		return [];
	}, [
		initialColumns,
		isInitializing,
		tablePreferences?.columnPreferences,
		columnVisibilityModel,
		calculateColumnWidths,
		sortColumns,
		rows,
		selectedCustomView,
	]);

	const onColumnVisibilityModelChange = useCallback<
		(model: GridColumnVisibilityModel) => Promise<void>
	>(
		async (model) => {
			setColumnVisibilityModel(model);
			const modelKeys = Object.keys(model);

			const tablePreferencesReq = getTablePreferencesReq();
			tablePreferencesReq.columnPreferences =
				tablePreferencesReq.columnPreferences?.map((column) => {
					if (modelKeys.includes(column.columnId)) {
						column.hide = !model[column.columnId];
					}

					return column;
				});

			if (selectedCustomView) {
				tablePreferencesReq.selectedCustomViewId = undefined;
			}

			updateTablePreferences(tablePreferencesReq);
		},
		[getTablePreferencesReq, selectedCustomView, updateTablePreferences],
	);

	const onColumnWidthChange = useCallback<
		(params: GridColumnResizeParams) => Promise<void>
	>(
		async (params) => {
			const tablePreferencesReq = getTablePreferencesReq();

			tablePreferencesReq.columnPreferences =
				tablePreferencesReq.columnPreferences?.map((col) => {
					if (params.colDef.field === col.columnId) {
						col.width = params.width;
					}

					return col;
				});

			if (selectedCustomView) {
				tablePreferencesReq.selectedCustomViewId = undefined;
			}

			updateTablePreferences(tablePreferencesReq);
		},
		[getTablePreferencesReq, selectedCustomView, updateTablePreferences],
	);

	const onColumnOrderChange = useCallback<
		(params: GridColumnOrderChangeParams) => Promise<void>
	>(
		async (_) => {
			const tablePreferencesReq = getTablePreferencesReq();

			if (selectedCustomView) {
				tablePreferencesReq.selectedCustomViewId = undefined;
			}

			updateTablePreferences(tablePreferencesReq);
		},
		[getTablePreferencesReq, selectedCustomView, updateTablePreferences],
	);

	const onFilterModelChange = useCallback<
		(model: GridFilterModel) => Promise<void>
	>(
		async (model) => {
			setFilterModel(model);

			const tablePreferencesReq = getTablePreferencesReq(model);

			if (selectedCustomView) {
				tablePreferencesReq.selectedCustomViewId = undefined;
			}

			updateTablePreferences(tablePreferencesReq);
		},
		[getTablePreferencesReq, selectedCustomView, updateTablePreferences],
	);

	const onCustomViewCreate = useCallback(
		async (name: string): Promise<ApiResponse<TableCustomViewPreferences>> => {
			const tablePreferencesReq = getTablePreferencesReq();

			const tableCustomViewPreferencesReq: TableCustomViewPreferencesReq = {
				name: name,
				filter: tablePreferencesReq.tableFilter,
				columns: tablePreferencesReq.columnPreferences,
			};

			let response = await createUserCustomViewPreference(
				tableCustomViewPreferencesReq,
			);
			if (response.success) {
				tablePreferencesReq.selectedCustomViewId = response.value.id;
				updateTablePreferences(tablePreferencesReq);
			}

			return response;
		},
		[
			createUserCustomViewPreference,
			getTablePreferencesReq,
			updateTablePreferences,
		],
	);

	const onCustomViewDelete = useCallback<
		(customViewId: string) => Promise<void>
	>(
		async (customViewId) => {
			const tablePreferencesReq = getTablePreferencesReq();

			if (selectedCustomView?.id === customViewId) {
				tablePreferencesReq.selectedCustomViewId = undefined;
				updateTablePreferences(tablePreferencesReq);
			}

			await deleteUserCustomViewPreference(customViewId);
		},
		[
			deleteUserCustomViewPreference,
			getTablePreferencesReq,
			selectedCustomView?.id,
			updateTablePreferences,
		],
	);

	const onCustomViewSelect = useCallback(
		async (customViewId: string) => {
			let customView = tablePreferences?.customViews?.find(
				(x) => x.id === customViewId,
			);
			if (customView !== undefined) {
				const tablePreferencesReq = getTablePreferencesReq();
				tablePreferencesReq.selectedCustomViewId = customViewId;
				updateTablePreferences(tablePreferencesReq);
			}
		},
		[
			tablePreferences?.customViews,
			getTablePreferencesReq,
			updateTablePreferences,
		],
	);

	// on custom view select
	useEffect(() => {
		if (selectedCustomView) {
			loadPreferences(selectedCustomView.filter, selectedCustomView.columns);
		}
	}, [loadPreferences, selectedCustomView]);

	// on initialized
	useEffect(() => {
		if (!isInitializing && !hasInitalized) {
			if (tablePreferences) {
				if (tablePreferences?.selectedCustomViewId) {
					const customView = tablePreferences?.customViews?.find(
						(x) => x.id === tablePreferences?.selectedCustomViewId,
					);
					if (customView) {
						loadPreferences(customView.filter, customView.columns);
					}
				} else {
					loadPreferences(
						tablePreferences.tableFilter,
						tablePreferences.columnPreferences,
						{
							filterModel: initialState?.filterModel,
							columnVisibilityModel: initialState?.columnVisibilityModel,
						},
					);
				}
			} else {
				loadPreferences(undefined, undefined, {
					filterModel: initialState?.filterModel,
					columnVisibilityModel: initialState?.columnVisibilityModel,
				});
			}

			setHasInitalized(true);
		}
	}, [
		hasInitalized,
		initialState?.columnVisibilityModel,
		initialState?.filterModel,
		isInitializing,
		loadPreferences,
		tablePreferences,
	]);

	// on table code change
	useEffect(() => {
		return () => {
			setColumnVisibilityModel({});
			setFilterModel(undefined);
			setHasInitalized(false);
		};
	}, [tableCode]);

	// on unmount
	useEffect(() => {
		return () => {
			setColumnVisibilityModel({});
			setFilterModel(undefined);
			setHasInitalized(false);
		};
	}, []);

	return {
		columnVisibilityModel,
		onColumnVisibilityModelChange,
		onColumnWidthChange,
		onFilterModelChange,
		onColumnOrderChange,
		onCustomViewCreate,
		onCustomViewDelete,
		onCustomViewSelect,
		selectedCustomView,
		customViews: tablePreferences?.customViews,
		filterModel,
		columns: mergedColumnDefinitions,
		rows,
		isLoading: isInitializing,
	};
};
