import { flow, makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { jsonToReferenceData } from 'shared/utilities/referenceDataUtilities';
import { T4FieldAdornmentProps } from '../../../../shared/components/molecules/t4FieldAdornment';
import { TaxIdData } from './taxId';
import { TaxIdRepository } from './taxIdRepository';

const convertCountryStateProvinceReferenceData = (
	referenceData: string | null,
) => {
	const data = referenceData
		? (JSON.parse(referenceData) as ReferenceDataValue)
		: null;
	return data
		? {
				key: data.value,
				name: data.displayName,
		  }
		: null;
};

const getReferenceData = (
	referenceData: ReferenceDataValue | undefined,
): string | null => {
	return referenceData ? JSON.stringify(referenceData) : null;
};

export class TaxIdForm {
	public purposeAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public isPrimaryFederalIncomeTaxIdAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public countryAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public taxIdNumberAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public issuedDateAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public taxIdTypeAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public stateProvinceAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public countyParishAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public cityAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] = 'info';
	public taxIdStatusAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';
	public issuingAuthorityAdornmentState: T4FieldAdornmentProps['t4AdornmentType'] =
		'info';

	public selectedPurposes: Option[] = [];
	public isPrimaryFederalIncomeTaxId: boolean | null = null;
	public country: ReferenceDataValue | null = null;
	public taxIdNumber: string | null = null;
	public issuedDate: Moment | null = null;
	public taxIdType: ReferenceDataValue | null = null;
	public stateProvince: ReferenceDataValue | null = null;
	public countyParish: string | null = null;
	public city: string | null = null;
	public taxIdStatus: Option | null = null;
	public issuingAuthority: ReferenceDataValue | null = null;

	public isLoadingPurposes: boolean = false;
	public isLoadingCountries: boolean = false;
	public isLoadingTaxIdTypes: boolean = false;
	public isLoadingStateProvince: boolean = false;
	public isLoadingStatuses: boolean = false;
	public isLoadingIssuingAuthorities: boolean = false;

	public hasLoadedPurposes: boolean = false;
	public hasLoadedCountries: boolean = false;
	public hasLoadedTaxIdTypes: boolean = false;
	public hasLoadedStateProvinces: boolean = false;
	public hasLoadedTaxIdStatuses: boolean = false;
	public hasLoadedIssuingAuthorities: boolean = false;

	public purposes: Option[] = [];
	public countries: ReferenceDataValue[] = [];
	public taxIdTypes: ReferenceDataValue[] = [];
	public stateProvinces: ReferenceDataValue[] = [];
	public taxIdStatuses: Option[] = [];
	public issuingAuthorities: ReferenceDataValue[] = [];

	private data?: TaxIdData;

	private taxIdRepository: TaxIdRepository;

	constructor(taxIdRepository: TaxIdRepository) {
		makeAutoObservable(this);

		this.taxIdRepository = taxIdRepository;
	}

	public setData(taxIdData?: TaxIdData) {
		if (taxIdData) {
			this.selectedPurposes = taxIdData.purposes.map((purpose) => ({
				id: purpose.taxIdPurposeOptionId,
				code: purpose.taxIdPurposeOptionId,
				displayName: purpose.purposeName,
			}));
			this.isPrimaryFederalIncomeTaxId = taxIdData.isPrimaryFederalIncomeTaxId;
			this.country = jsonToReferenceData(taxIdData.countryReferenceData);
			this.taxIdNumber = taxIdData.taxIdNumber;
			this.issuedDate = taxIdData.issuedDate
				? moment(taxIdData.issuedDate)
				: null;
			this.taxIdType = jsonToReferenceData(taxIdData.taxIdTypeReferenceData);
			this.stateProvince = jsonToReferenceData(taxIdData.stateReferenceData);
			this.countyParish = taxIdData.countyParish;
			this.city = taxIdData.city;
			this.taxIdStatus = taxIdData.status
				? {
						id: taxIdData.status.taxIdStatusOptionId,
						code: taxIdData.status.taxIdStatusOptionId,
						displayName: taxIdData.status.status,
				  }
				: null;
			this.issuingAuthority = jsonToReferenceData(
				taxIdData.issuingAuthorityReferenceData,
			);

			this.data = taxIdData;
		}
	}

	public updateData(taxIdData: TaxIdData | undefined) {
		this.data = taxIdData;
	}

	public get issuingAuthorityUrl() {
		const data = jsonToReferenceData(this.data?.issuingAuthorityReferenceData);
		return data?.value ?? null;
	}

	public fetchPurposes = flow(function* (this: TaxIdForm) {
		this.purposes = yield this.taxIdRepository.getTaxIdPurposes();
		if (!this.hasLoadedPurposes) this.hasLoadedPurposes = true;
	});

	public fetchCountries = flow(function* (this: TaxIdForm) {
		this.countries = yield this.taxIdRepository.getCountries();
		const index = this.countries.findIndex(
			(country) => country.identifier === 'US',
		);
		const unitedStates = this.countries.splice(index, 1);
		this.countries = [...unitedStates, ...this.countries];
		if (!this.hasLoadedCountries) this.hasLoadedCountries = true;
	});

	public fetchTaxIdTypes = flow(function* (this: TaxIdForm) {
		this.taxIdTypes = yield this.taxIdRepository.getTaxIdTypes(
			this.country?.value ?? null,
		);
		if (!this.hasLoadedTaxIdTypes) this.hasLoadedTaxIdTypes = true;
	});

	public fetchStateProvinces = flow(function* (this: TaxIdForm) {
		this.stateProvinces = yield this.taxIdRepository.getStateProvinces(
			this.country?.value ?? null,
		);
		if (!this.hasLoadedStateProvinces) this.hasLoadedStateProvinces = true;
	});

	public fetchTaxIdStatuses = flow(function* (this: TaxIdForm) {
		this.taxIdStatuses = yield this.taxIdRepository.getTaxIdStatuses();
		if (!this.hasLoadedTaxIdStatuses) this.hasLoadedTaxIdStatuses = true;
	});

	public fetchIssuingAuthorities = flow(function* (this: TaxIdForm) {
		this.issuingAuthorities =
			yield this.taxIdRepository.getTaxIdIssuingAuthorities(
				this.country?.value ?? null,
				this.stateProvince?.value ?? null,
			);
		if (!this.hasLoadedIssuingAuthorities)
			this.hasLoadedIssuingAuthorities = true;
	});

	public updateTaxId = flow(function* (
		this: TaxIdForm,
		entityId: string,
		taxIdId: string,
	) {
		if (this.data) {
			yield this.taxIdRepository.updateTaxId(
				entityId,
				taxIdId,
				this.selectedPurposes.map((purpose) => purpose.id!),
				this.isPrimaryFederalIncomeTaxId,
				this.getCountryReferenceData(),
				this.taxIdNumber,
				this.issuedDate?.toDate() ?? null,
				this.getTaxIdTypeReferenceData(),
				this.getStateProvienceReferenceData(),
				this.countyParish,
				this.city,
				this.taxIdStatus?.id ?? null,
				this.getIssuingAuthorityReferenceData(),
			);
		}
	});

	private getCountryReferenceData(): string {
		const data = convertCountryStateProvinceReferenceData(
			this.data?.countryReferenceData ?? null,
		);
		if (data?.key === this.country?.value) {
			return this.data?.countryReferenceData!;
		} else {
			return getReferenceData(
				this.countries.find((country) => country.value === this.country?.value),
			)!;
		}
	}

	private getTaxIdTypeReferenceData(): string | null {
		const data = jsonToReferenceData(this.data?.taxIdTypeReferenceData ?? null);
		if (data?.value === this.taxIdType?.value) {
			return this.data?.taxIdTypeReferenceData ?? null;
		} else {
			return getReferenceData(
				this.taxIdTypes.find(
					(taxIdType) => taxIdType.identifier === this.taxIdType?.value,
				),
			);
		}
	}

	private getStateProvienceReferenceData(): string | null {
		const data = jsonToReferenceData(this.data?.stateReferenceData);
		if (data?.value === this.stateProvince?.value) {
			return this.data?.stateReferenceData ?? null;
		} else {
			return getReferenceData(
				this.stateProvinces.find(
					(stateProvince) => stateProvince.value === this.stateProvince?.value,
				),
			);
		}
	}

	private getIssuingAuthorityReferenceData(): string | null {
		const data = jsonToReferenceData(this.data?.issuingAuthorityReferenceData);
		if (data?.value === this.issuingAuthority?.value) {
			return this.data?.issuingAuthorityReferenceData ?? null;
		} else {
			return getReferenceData(
				this.issuingAuthorities.find(
					(issuingAuthority) =>
						issuingAuthority.identifier === this.issuingAuthority?.identifier,
				),
			);
		}
	}
}
