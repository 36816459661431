import { useQuery } from '@tanstack/react-query';
import { ApprovalConfiguration } from 'features/administration/approvals/fieldApprovalConfiguration/contractObjects';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { ApplicationConfigurationContext } from './contexts/applicationConfigurationContext';

export const T4ApplicationConfigurationProvider: FC = observer(
	({ children }) => {
		const { customerApiClient } = useClients();

		const [approvalConfiguration, setApprovalConfiguration] =
			useState<ApprovalConfiguration>({} as ApprovalConfiguration);

		const getApprovalConfig = async () => {
			var response =
				await customerApiClient.api.configuration.fieldApprovals.get();
			if (response.data.success) {
				setApprovalConfiguration(response.data.value);
				return response.data.value;
			} else {
				return {} as ApprovalConfiguration;
			}
		};

		const getSupportedTimezones = async () => {
			var response =
				await customerApiClient.api.configuration.getSupportedTimezones();
			if (response.data.success) {
				return response.data.value;
			} else {
				return [];
			}
		};

		const approvalConfigurationQuery = useQuery({
			queryKey: ['approvalConfiguration'],
			queryFn: getApprovalConfig,
			enabled: !!customerApiClient,
			refetchOnWindowFocus: false,
		});

		const supportedTimezonesQuery = useQuery({
			queryKey: ['supporedTimezones'],
			queryFn: getSupportedTimezones,
			enabled: !!customerApiClient,
			refetchOnWindowFocus: false,
		});

		return (
			<ApplicationConfigurationContext.Provider
				value={{
					loading:
						approvalConfigurationQuery.isLoading ||
						supportedTimezonesQuery.isLoading,
					approvalConfiguration,
					setApprovalConfiguration: (config) =>
						setApprovalConfiguration(config),
					supportedTimezones: supportedTimezonesQuery.data ?? [],
				}}
			>
				{children}
			</ApplicationConfigurationContext.Provider>
		);
	},
);
