import { Typography } from '@mui/material';
import DOMPurify from 'dompurify';

interface IFieldDescriptionProps {
	description: string;
}

export const FieldDescription: React.FC<IFieldDescriptionProps> = (props) => (
	<Typography
		data-testid="field-description"
		component="div"
		dangerouslySetInnerHTML={{
			__html: DOMPurify.sanitize(props.description),
		}}
		sx={{
			description: {
				'& p:first-of-type': {
					marginTop: 0,
				},
			},
		}}
	></Typography>
);
