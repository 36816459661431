import { AxiosInstance } from 'axios';
import { T4DataResponse, Read } from 'modules/clients/types';
import { Write } from '../../transactions';

//#region Models

export type DataImportDashboard = {
	templates: Template[];
};

export type Template = {
	id: string;
	name: string;
	description: string;
	objectType: string;
	fileName: string;
	links: Link[];
};

export type Link = {
	href: string;
	rel: string;
	type: string;
};

export type DataImportStatus =
	| 'Submitted'
	| 'Processing'
	| 'Complete'
	| 'Failure';

export type DataImport = {
	id: string;
	fileName: string;
	fileType: string;
	objectType: string;
	status: DataImportStatus;
	createdBy: string;
	createdOn: string;
	details: DataImportDetails[];
};

export type DataImportDetails = {
	objectIndex: number;
	objectId: string | null;
	errors: { [key: string]: string[] };
};

//#endregion

//#region Requests

export type GetDataImportDashboardResponse =
	T4DataResponse<DataImportDashboard>;

//#endregion

export type DataImportEndpoints = {
	dashboard: Read<GetDataImportDashboardResponse>;
	downloadTemplate: Read<BlobPart, string>;
	getAll: Read<T4DataResponse<DataImport[]>>;
	downloadOriginalFile: Read<BlobPart, string>;
	downloadErrorFile: Read<BlobPart, string>;
	uploadDataImport: Write<T4DataResponse<DataImport>, FormData>;
};

export function dataImports(axiosInstance: AxiosInstance): DataImportEndpoints {
	return {
		dashboard: async (_, config = {}) =>
			await axiosInstance.get('api/entity4/dataimports/dashboard', {
				...config,
			}),
		downloadTemplate: async (id, config = {}) =>
			await axiosInstance.get(
				`api/entity4/dataimports/templates/${id}/download`,
				{
					...config,
					responseType: 'blob',
				},
			),
		getAll: async (_, config = {}) =>
			await axiosInstance.get('api/entity4/dataimports', { ...config }),
		downloadOriginalFile: async (id, config = {}) =>
			await axiosInstance.get(
				`api/entity4/dataimports/${id}/download/original`,
				{
					...config,
					responseType: 'blob',
				},
			),
		// TODO: WRITE ENDPOINT FOR DOWNLOAD ERROR
		downloadErrorFile: async (id, config = {}) =>
			await axiosInstance.get(`api/entity4/dataimports/${id}/download/error`, {
				...config,
				responseType: 'blob',
			}),
		uploadDataImport: async (data, config = {}) =>
			await axiosInstance.post('api/entity4/dataimports', data, {
				...config,
				headers: {
					...config.headers,
					'content-type': 'multipart/form-data',
				},
			}),
	};
}
