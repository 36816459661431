import { makeAutoObservable } from 'mobx';
import { Account, AccountMap } from 'modules/clients/customer-api/src/client';

export class AccountMapModel {
	public accountViewLinks: go.ObjectData[] = [];
	public accountViewNodes: go.ObjectData[] = [];

	public entityViewNodes: go.ObjectData[] = [];
	public entityViewLinks: go.ObjectData[] = [];

	public modelData!: go.ObjectData;

	constructor(
		accountMap: AccountMap | undefined,
		isFlagVisible: boolean,
		isStandaloneAccountsVisible: boolean,
		isSubaccountVisible: boolean,
		isClosedAccountVisible: boolean,
		isAccountTypeVisible: boolean,
		isEntityRegionVisible: boolean,
		isAccountPurposeVisible: boolean,
		isAccountStatusVisible: boolean,
		getNodeColor: (data: any) => string,
	) {
		makeAutoObservable(this);

		if (accountMap) {
			this.modelData = { canRelink: false };

			const isAccountVisible = (account: Account) => {
				let isVisible = true;

				if (account.isSubaccount && !isSubaccountVisible) {
					isVisible = false;
				}

				if (account.accountStatus === 'Closed' && !isClosedAccountVisible) {
					isVisible = false;
				}

				return isVisible;
			};

			//#region Prepare entity view

			// nodes
			const entityViewNodes: go.ObjectData[] = [];

			const treeNodes = accountMap.treeNodes ?? {};
			Object.entries<Account[]>(treeNodes).forEach(([id, accounts]) => {
				entityViewNodes.push({
					key: id,
					category: 'entityOrg',
					entityId: id,
					entityCountry: accounts[0]?.entityCountry,
					entityErpCode: accounts[0]?.entityErpCode,
					entityName: accounts[0]?.entityName,
					isFlagVisible: isFlagVisible,
					countChildren: accounts.length,
				});

				accounts.forEach((account) => {
					entityViewNodes.push({
						...account,
						key: account.entityId,
						currentColor: getNodeColor(account),
						isAccountTypeVisible: isAccountTypeVisible,
						isVisible: isAccountVisible(account),
						parentTreeNodeId: account.parentTreeNodeId
							? account.parentTreeNodeId
							: id,
					});
				});
			});

			// standalone accounts
			const standaloneAccounts = accountMap.nonTreeNodes ?? [];
			if (standaloneAccounts.length > 0) {
				entityViewNodes.push({
					key: 'Standalone',
					text: 'Standalone Accounts',
					isGroup: true,
					visible: isStandaloneAccountsVisible,
				});
				standaloneAccounts.forEach((account) => {
					entityViewNodes.push({
						...account,
						key: account.entityId,
						currentColor: getNodeColor(account),
						group: 'Standalone',
						isVisible: isAccountVisible(account),
					});
				});
			}

			// links
			const entityViewLinks: go.ObjectData[] = [];

			entityViewNodes.forEach((node) => {
				if (node.parentTreeNodeId) {
					entityViewLinks.push({
						key: node.entityId + '-' + node.parentTreeNodeId,
						to: node.entityId,
						from: node.parentTreeNodeId,
						isSubaccount: node.isSubaccount,
						category:
							(node.cashFlowMovement ?? 'Manual') +
							(node.fundingDirection?.replace('-', ' ')?.replace(' ', '') ??
								''),
					});
				}
			});

			const rootToRootRelationships = accountMap.rootToRootRelationships ?? [];
			rootToRootRelationships.forEach((relationship) => {
				entityViewLinks.push({
					key:
						relationship.secondaryEntityId + '-' + relationship.primaryEntityId,
					to: relationship.secondaryEntityId,
					from: relationship.primaryEntityId,
					cashFlowMovement: relationship.cashFlowMovement,
					category:
						'RootToRoot' +
						(relationship.cashFlowMovement ?? 'Manual') +
						(relationship.fundingDirection
							?.replace('-', ' ')
							?.replace(' ', '') ?? ''),
				});
			});

			this.entityViewLinks = entityViewLinks;

			// legend
			const legendNodes = this.getLegendNodes(
				entityViewNodes,
				entityViewLinks,
				isAccountTypeVisible,
				isEntityRegionVisible,
				isAccountPurposeVisible,
				isAccountStatusVisible,
			);
			entityViewNodes.push(...legendNodes);

			this.entityViewNodes = entityViewNodes;

			//#endregion

			//#region Prepare account view

			// nodes
			const accountViewNodes: go.ObjectData[] = [];
			(accountMap.accountViewTreeNodes ?? []).forEach((account) => {
				accountViewNodes.push({
					...account,
					key: account.entityId,
					currentColor: getNodeColor(account),
					isVisible: isAccountVisible(account),
					isAccountTypeVisible: isAccountTypeVisible,
					isEntityRegionVisible: isEntityRegionVisible,
					isAccountPurposeVisible: isAccountPurposeVisible,
					isAccountStatusVisible: isAccountStatusVisible,
				});
			});

			const accountViewAuxiliaryNodes =
				accountMap.accountViewAuxiliaryNodes ?? [];
			if (accountViewAuxiliaryNodes.length > 0) {
				accountViewNodes.push({
					key: 'Standalone',
					text: 'Standalone Accounts',
					isGroup: true,
				});

				accountViewAuxiliaryNodes.forEach((account) => {
					accountViewNodes.push({
						...account,
						key: account.entityId,
						group: 'Standalone',
						currentColor: getNodeColor(account),
						isVisible: isAccountVisible(account),
						isAccountTypeVisible: isAccountTypeVisible,
						isEntityRegionVisible: isEntityRegionVisible,
						isAccountPurposeVisible: isAccountPurposeVisible,
						isAccountStatusVisible: isAccountStatusVisible,
					});
				});
			}

			// links
			const accountViewLinks: go.ObjectData[] = [];
			(accountMap.accountViewRelationships ?? []).forEach((relationships) => {
				relationships.forEach((relationship) => {
					if (relationship.parentEntityId) {
						accountViewLinks.push({
							key: relationship.entityId + '-' + relationship.parentEntityId,
							to: relationship.entityId,
							from: relationship.parentEntityId,
						});
					}
				});
			});

			this.accountViewLinks = accountViewLinks;

			// legend
			const accountViewLegendNodes = this.getLegendNodes(
				accountViewNodes,
				accountViewLinks,
				isAccountTypeVisible,
				isEntityRegionVisible,
				isAccountPurposeVisible,
				isAccountStatusVisible,
			);
			accountViewNodes.push(...accountViewLegendNodes);

			this.accountViewNodes = accountViewNodes;

			//#endregion
		}
	}

	private getLegendNodes(
		nodes: go.ObjectData[],
		links: go.ObjectData[],
		isAccountTypeVisible: boolean,
		isEntityRegionVisible: boolean,
		isAccountPurposeVisible: boolean,
		isAccountStatusVisible: boolean,
	) {
		const entityRegions = nodes.map((node) => node.entityRegion);
		const distinctEntityRegions = [...new Set(entityRegions)].filter(Boolean);
		const accountTypes = nodes.map((node) => node.accountType).sort();
		const distinctAccountTypes = [...new Set(accountTypes)].filter(Boolean);
		const accountStatuses = nodes.map((node) => node.accountStatus);
		const distinctAccountStatuses = [...new Set(accountStatuses)].filter(
			Boolean,
		);
		const accountPurposes = nodes.map((node) => node.purpose);
		const distinctAccountPurposes = [...new Set(accountPurposes)].filter(
			Boolean,
		);

		return [
			{
				key: 'Legend',
				isGroup: true,
				category: 'Legend',
			},
			{
				key: 'LegendNode',
				group: 'Legend',
				category: 'LegendNode',
				accountTypes: distinctAccountTypes,
				entityRegions: distinctEntityRegions,
				hasForeignAccount: nodes.some((node) => node.isForeignAccount),
				hasManualLink: links.some((link) => link.category?.includes('Manual')),
				hasAutomaticLink: links.some(
					(link) => link.category?.includes('Automatic'),
				),
				hasOneWayLink: links.some((link) => link.category?.includes('OneWay')),
				hasTwoWayLink: links.some((link) => link.category?.includes('TwoWay')),
				hasSubaccount: nodes.some((node) => node.isSubaccount),
				hideConnectors: links.length === 0, //this is done in the account view.
				accountStatuses: distinctAccountStatuses,
				accountPurposes: distinctAccountPurposes,
				isAccountTypeVisible: isAccountTypeVisible,
				isEntityRegionVisible: isEntityRegionVisible,
				isAccountStatusVisible: isAccountStatusVisible,
				isAccountPurposeVisible: isAccountPurposeVisible,
			},
		];
	}
}
