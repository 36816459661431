import { EntityType } from '../entity4Constants';
import { Document } from './models/document';

export const documentTypeOptionListSourceId =
	'8b0e5955-5744-491a-a019-1c7be1284ef4';
export const browserContentTypes = [
	'image/jpeg',
	'image/tiff',
	'image/bmp',
	'image/png',
	'image/svg',
	'image/gif',
	'application/pdf',
];

// TODO: Setup download endpoint
// https://stackoverflow.com/questions/20508788/do-i-need-content-type-application-octet-stream-for-file-download/20509354#20509354
export const displayDocument = async (
	document: Document,
	objectType: EntityType,
	objectId: string
) => {
	const link = globalThis.document.createElement('a');
	link.setAttribute('target', '_blank');

	if (document.externalUrl) {
		link.href = document.externalUrl;
	} else {
		const contents = await document.download(objectType, objectId);
		const file = new Blob([contents], {
			type: document.contentType,
		});

		link.href = URL.createObjectURL(file);
		link.setAttribute('type', document.contentType);
		if (browserContentTypes.indexOf(document.contentType) === -1) {
			link.setAttribute('download', document.name);
		}
	}

	globalThis.document.body.appendChild(link);
	link.click();
	globalThis.document.body.removeChild(link);
};

// TODO: Setup download endpoint
// https://stackoverflow.com/questions/20508788/do-i-need-content-type-application-octet-stream-for-file-download/20509354#20509354
export const downloadBlob = (name: string, type: string, contents: any) => {
	const file = new Blob([contents], { type: type });

	const link = document.createElement('a');
	link.href = URL.createObjectURL(file);
	link.setAttribute('target', '_blank');
	link.setAttribute('type', type);
	if (browserContentTypes.indexOf(type) === -1) {
		link.setAttribute('download', name);
	}

	document.body.appendChild(link);
	link.click();
	globalThis.document.body.removeChild(link);
};
