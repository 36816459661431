import { EntityType } from 'features/entity4/entity4Constants';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'shared/constants/paths';

export type UseRegistrationNavigationProps = (
	entityId: string,
	registration: string,
	view?: FieldViews,
) => void;
export const useRegistrationNavigation = (): UseRegistrationNavigationProps => {
	const history = useHistory();
	const { setViewType } = useProfileView();

	return (entityId: string, registrationId: string, view?: FieldViews) => {
		if (entityId && registrationId) {
			history.push(
				generatePath(
					paths.entity4.objects.object.registrations.registration.href,
					{
						objectType: EntityType.Entity,
						objectId: entityId,
						registrationId: registrationId,
					},
				),
			);
			setViewType(view ?? FieldViews.default);
		}
	};
};
