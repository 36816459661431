import {
	AccountIntegration,
	UpdateAccountIntegrationsResponse,
} from 'modules/clients/customer-api/src/cash4/accountIntegrations';
import { ConnectionIndex } from 'modules/clients/customer-api/src/cash4/connections';
import { useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';

export type UseUpdateAccountIntegrationProps = {
	loading: boolean;
	error: string;
	isDirty: boolean;
	isSubmitDisabled: () => boolean;
	clear: () => void;

	initialize: (
		connection: ConnectionIndex,
		accountIntegration: AccountIntegration | null,
	) => void;
	getAccountIdentifier: () => string;
	setAccountIdentifier: (value: string | null) => void;
	updateAccountIntegration: () => Promise<boolean>;
};

export const useUpdateAccountIntegration =
	(): UseUpdateAccountIntegrationProps => {
		const { customerApiClient } = useClients();

		const [connection, setConnection] = useState<ConnectionIndex | null>(null);
		const [accountIntegration, setAccountIntegration] =
			useState<AccountIntegration | null>(null);

		const [loading, setLoading] = useState<boolean>(false);
		const [error, setError] = useState('');
		const [isDirty, setIsDirty] = useState<boolean>(false);
		const [accountIdentifier, setAccountIdentifier] = useState<string | null>(
			null,
		);

		function initialize(
			connection: ConnectionIndex,
			accountIntegration: AccountIntegration | null,
		) {
			clear();
			setConnection(connection);
			setAccountIntegration(accountIntegration);
			setAccountIdentifier(accountIntegration?.accountIdentifier ?? null);
		}

		function clear() {
			setConnection(null);
			setAccountIntegration(null);
			setAccountIdentifier(null);
			setLoading(false);
			setError('');
			setIsDirty(false);
		}

		function isSubmitDisabled() {
			return (
				!isDirty ||
				loading ||
				isStringUndefinedOrNullOrWhitespace(accountIdentifier) ||
				connection === null ||
				accountIntegration === null
			);
		}

		function getAccountIdentifier() {
			return accountIdentifier ?? '';
		}
		function updateAccountIdentifier(value: string | null) {
			setAccountIdentifier(value);
			if (value !== accountIntegration?.accountIdentifier) setIsDirty(true);
			else setIsDirty(false);
		}

		async function updateAccountIntegration() {
			if (connection === null || accountIntegration === null) return false;
			try {
				setLoading(true);
				setError('');
				const response: UpdateAccountIntegrationsResponse =
					await customerApiClient.cash4.accountIntegrations.update({
						connectionId: connection!.id,
						accountIntegrationId: accountIntegration!.id,
						accountIdentifier: accountIdentifier,
					});
				if (response.data.success) return true;

				setError('Unable to update account integration');
			} catch (error: any) {
				setError('Unable to update account integration');
			} finally {
				setLoading(false);
			}

			return false;
		}

		return {
			loading,
			error,
			isDirty,
			isSubmitDisabled,
			clear,
			initialize,
			getAccountIdentifier,
			setAccountIdentifier: updateAccountIdentifier,
			updateAccountIntegration,
		};
	};
