import { Box, Container } from '@mui/material';
import { ReactNode } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { T4View as T4View_V2 } from 'shared/components/t4View';

interface T4ViewProps {
	header?: React.ReactElement;
	disableGutters?: true;
	children?: ReactNode;
}

/** @deprecated Please use @see {@link T4View_V2} */
export const T4View: React.FC<T4ViewProps> = ({
	header,
	disableGutters = false,
	children,
}) => (
	<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
		{header}
		<Container
			maxWidth={disableGutters ? false : 'lg'}
			sx={{ flex: 1 }}
			disableGutters={disableGutters}
		>
			{children}
		</Container>
	</Box>
);
