import React, { ReactNode } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { baseTheme } from '../theme/baseTheme';
import { lightTheme } from '../theme/lightTheme';
import { darkTheme } from '../theme/darkTheme';
import { useDarkMode } from './contexts/darkModeContext';
import { deepmerge } from '@mui/utils';

type T4ThemeProviderProps = {
	children: ReactNode;
};

export const T4ThemeProvider: React.FC<T4ThemeProviderProps> = ({
	children,
}) => {
	const { darkMode } = useDarkMode();

	const mode = darkMode ? 'dark' : 'light';

	const userSelectedTheme = darkMode ? darkTheme : lightTheme;

	const theme = createTheme(deepmerge(baseTheme(mode), userSelectedTheme));

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};
