import { observer } from 'mobx-react-lite';
import { FC } from 'react';
export interface ICustomNoRowsOverlayProps {
	icon?: JSX.Element;
	heading?: JSX.Element;
	body?: JSX.Element;
}

export const CustomNoRowsOverlay: FC<ICustomNoRowsOverlayProps> = observer(
	({ icon, heading, body }) => {
		return (
			<div
				style={{
					textAlign: 'center',
					width: '350px',
					margin: 'auto',
					paddingTop: '5%',
				}}
			>
				{icon ?? ''}
				{heading ?? ''}
				{body ?? ''}
			</div>
		);
	},
);
