import { useState } from 'react';
import { useUser } from 'shared/hooks/useUser';

export enum DocumentViewModes {
	default,
	edit,
}

export interface UseDocumentViewProps {
	viewMode: DocumentViewModes;
	setViewMode: (viewMode?: DocumentViewModes) => void;
}
export const useDocumentView = (): UseDocumentViewProps => {
	const { isAuthor } = useUser();
	const [documentView, setDocumentView] = useState<DocumentViewModes>(
		DocumentViewModes.default
	);

	return {
		viewMode: documentView,
		setViewMode: (view?: DocumentViewModes) => {
			if (view === DocumentViewModes.edit && isAuthor) {
				setDocumentView(view);
			} else if (view !== DocumentViewModes.edit) {
				setDocumentView(view ?? DocumentViewModes.default);
			}
		},
	};
};
