import { Add } from '@mui/icons-material';
import { Button, ButtonProps, styled, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { stonlyData } from 'stonly/functions';
import { AddAspectModal } from './addAspectModal';
import { useProfileView } from './providers/entityProfileContextProvider';

export const stonlyIds = {
	addButton: 'add-aspect-button',
};
export const testIds = {
	addButton: 'add-button',
};

const AddButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: 'white',
	textTransform: 'none',
	minWidth: 72,
	minHeight: 40,
	fontWeight: theme.typography.fontWeightRegular,
	'&.MuiButton-root.Mui-disabled': {
		color: 'rgba(255, 255, 255, 0.75)',
	},
}));

export const AddAspectButton: React.FC = observer(() => {
	const [open, setOpen] = useState<boolean>(false);
	const { aspectManager } = useProfileView();

	const disabled = aspectManager.availableAspects.length === 0;

	return (
		<>
			<Tooltip
				title="There are no available aspects"
				placement="bottom"
				disableFocusListener
				disableHoverListener={!disabled}
			>
				<span>
					<AddButton
						data-testid={testIds.addButton}
						{...stonlyData({ id: stonlyIds.addButton })}
						onClick={() => setOpen(true)}
						startIcon={<Add />}
						color="inherit"
						disabled={disabled}
					>
						Aspect
					</AddButton>
				</span>
			</Tooltip>
			<AddAspectModal open={open} onClose={() => setOpen(false)} />
		</>
	);
});
