import { AxiosInstance } from 'axios';
import { Read } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { ApiEndpoints, api } from './api';
import { ReferenceDataEndpoints, referenceData } from './api/referenceData';
import { Cash4Endpoints, cash4 } from './cash4';
import { Entity4Endpoints, entity4 } from './entity4';
import { SigmaEmbedEndpoints, sigmaEmbed } from './sigmaEmbed';
import { TransactionEndpoints, transactions } from './transactions';
import { UserPreferenceEndpoints, userPreference } from './userPreference';

export type T4CustomResponse<
	TResult,
	TInput = {},
	TInputValidationErrors = {},
> = {
	success: boolean;
	failure: boolean;
	error: string | null;
	errors:
		| {
				[key in keyof (TInput & TInputValidationErrors)]?: string[];
		  }
		| null;
	value: TResult;
};

//#region Read Models

export type Entity = {
	entityId: string;
	parentEntityId?: string;
	entityType: string;
	entityTypeId: number;
	createdBy: string;
	createdDateUtc: string;
	entityCode: string;
	status?: string;
	displayNameShort?: string;
	displayName?: string;
	entityStatus?: string;
	anglicizedLegalName?: string;
	shortName?: string;
	entityRegion?: string;
	functionalCurrencyCode?: string;
	erpCode?: string;
	erpPlatform?: string;
	website?: string;
	incorporationDate?: string;
	sicCategory?: string;
	sicCode?: string;
	acquiredCompany?: string;
	acquisitionDate?: string;
	dunsNumber?: string;
	refinitivPermanentIdentifierPermID?: string;
	tpinNumber?: string;
	leiIdentifier?: string;
	leiIssuer?: string;
	country?: string;
	stateProvince?: string;
	location?: string;
	dissolutionDate?: string;
	taxIdNumber?: string;
	taxIdCountry?: string;
	formOfOrganization?: string;

	parentTreeNodeId?: string;
	alternateParentNodes: string[];
	secondaryNodes: [string, string][];
	key: string;
	currentColor: string;
	group: string;
};

export type Account = {
	entityId: string;
	parentEntityId?: string;
	entityType?: string;
	entityTypeId?: number;
	createdBy?: string;
	createdDateUtc?: string;
	status?: string;
	displayNameShort?: string;
	displayName?: string;
	accountCode?: string;
	accountStatus?: string;
	shortName?: string;
	openDate?: string;
	closeDate?: string;
	accountCurrencyCode?: string;
	accountType?: string;
	purpose?: string;
	naturalAccountNumber?: string;
	generalLedgerAccountNumber?: string;
	counterparty?: string;
	counterpartyBranch?: string;
	entityName?: string;
	entityRegion?: string;
	entityErpCode?: string;
	entityCountry?: string;
	country?: string;
	stateProvince?: string;
	location?: string;
	parentTreeNodeId?: string;
	treeNodeId?: string;
	fundingDirection?: string;
	cashFlowMovement?: string;
	fundingFrequency?: string;
	paymentDirection?: string;
	accountEntityId?: string;
	isForeignAccount?: boolean;
	isSubaccount?: boolean;

	isEntityOrg?: boolean; // todo: this is not populated by the server
	//for gojs support
	key?: string;
	text?: string;
	color?: string;
	category?: string;
	countChildren?: number;
	group?: string;
	currentColor?: string;
	isGroup?: boolean;
};

export type Relationship = {
	relationshipId: string;
	relationshipDefinitionId: string;
	relationshipTypeId: string;
	relationshipType: string;
	relationshipCategory: string;
	relationshipSubcategory: string;
	primaryEntityId: string;
	primaryEntityType: string;
	primaryDisplayNameShort: string;
	primaryDisplayName: string;
	primaryDescriptor: string;
	secondaryEntityId: string;
	secondaryEntityType: string;
	secondaryDisplayNameShort: string;
	secondaryDisplayName: string;
	secondaryDescriptor: string;
	effectiveFrom?: string;
	effectiveTo?: string;
	fundingDirection: string;
	cashFlowMovement: string;
};

export type AccountViewRelationship = {
	parentEntityId?: string;
	entityId: string;
	depth: number;
	isAdded: boolean;
};

export type AccountMap = {
	treeNodes: {};
	nonTreeNodes: Account[];
	rootToRootRelationships: Relationship[];
	accountViewTreeNodes: Account[];
	accountViewAuxiliaryNodes: Account[];
	accountViewRelationships: [AccountViewRelationship[]];
};

export type OrgChart = {
	treeNodes: {};
	auxiliaryNodes: Entity[];
	secondaryConnections: Relationship[];
};

//#endregion

//#region Input Models

export type GetOrgChartInput = {
	asOfDate: Date;
};

//#endregion

//#region Response Models

export type GetAccountMapResponse = ApiResponse<AccountMap>;

export type GetOrgChartResponse = ApiResponse<OrgChart>;

//#endregion

export type T4CustomerClient = {
	api: ApiEndpoints;
	entity4: Entity4Endpoints;
	cash4: Cash4Endpoints;
	transactions: TransactionEndpoints;
	referenceData: ReferenceDataEndpoints;
	userPreference: UserPreferenceEndpoints;
	sigmaEmbed: SigmaEmbedEndpoints;

	getAccountMap: Read<GetAccountMapResponse>;
	getOrgChart: Read<GetOrgChartResponse, GetOrgChartInput>;
};

export function getCustomerApiClient(
	axiosInstance: AxiosInstance,
): T4CustomerClient {
	return {
		api: api(axiosInstance),
		entity4: entity4(axiosInstance),
		cash4: cash4(axiosInstance),
		transactions: transactions(axiosInstance),
		referenceData: referenceData(axiosInstance),
		userPreference: userPreference(axiosInstance),
		sigmaEmbed: sigmaEmbed(axiosInstance),

		getAccountMap: async (_, config) =>
			await axiosInstance.get<GetAccountMapResponse>('/accountmap/accounts', {
				...config,
			}),
		getOrgChart: async (input, config) =>
			await axiosInstance.get<GetOrgChartResponse>('/orgchart/v2/entities', {
				...config,
				params: {
					asOfDate: input.asOfDate,
				},
			}),
	};
}
