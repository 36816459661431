/* eslint-disable mobx/missing-observer */
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { stonlyData } from 'stonly/functions';
import { T4CopyButtonWrapper } from './T4CopyButtonWrapper';

interface T4TopBarProps {
	title: string;
	copyText: string | null;
	initialTooltipText?: string;
	stonlyPrefix?: string;
	copyUrl?: string;
	disableLink?: boolean;
}

const T4TopBar: React.FC<T4TopBarProps> = ({
	title,
	copyText,
	initialTooltipText,
	stonlyPrefix,
	copyUrl,
	disableLink,
}) => {
	const fullUrl = copyUrl ?? window.location.href;
	const stonlyIdShare = stonlyPrefix
		? stonlyData({
				id: `${stonlyPrefix}-share-button`,
		  })
		: {};

	const stonlyIdCopy = stonlyPrefix
		? stonlyData({
				id: `${stonlyPrefix}-copy-button`,
		  })
		: {};

	return (
		<Stack
			spacing={2}
			direction={'row'}
			sx={{
				alignItems: 'center',
				justifyContent: 'space-between',
				pb: 1,
			}}
		>
			<Typography variant="h6" sx={{ fontWeight: 600 }}>
				{title}
			</Typography>
			<Box>
				{!disableLink && (
					<T4CopyButtonWrapper
						copyText={fullUrl}
						placement="bottom"
						initialTooltipText="Copy Link"
					>
						<ShareButton {...stonlyIdShare} />
					</T4CopyButtonWrapper>
				)}
				{copyText && (
					<T4CopyButtonWrapper
						copyText={copyText}
						placement="bottom"
						initialTooltipText={initialTooltipText}
					>
						<IconButton>
							<ContentCopyIcon {...stonlyIdCopy} />
						</IconButton>
					</T4CopyButtonWrapper>
				)}
			</Box>
		</Stack>
	);
};

export default T4TopBar;

const ShareButton = React.forwardRef<
	HTMLButtonElement,
	React.ComponentPropsWithoutRef<typeof IconButton>
>((props, ref) => (
	<IconButton ref={ref} {...props}>
		{props.children || <ShareIcon />}
	</IconButton>
));
