import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
	Badge,
	Button,
	Fade,
	IconButton,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tooltip,
	Typography,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { TableCustomViewPreferences } from 'modules/clients/customer-api/src/userPreference';
import { useCallback, useMemo, useState } from 'react';
import { brandColors } from 'shared/theme/brandColors';
import { stonlyData } from 'stonly/functions';
import { ApiResponse } from 'utilities/api';
import { FormModal } from '../formModal';

interface ICustomViewProps {
	onCustomViewDelete: (customViewId: string) => void;
	onCustomViewSelect: (customViewId: string) => void;
	onCustomViewCreate: (
		customViewName: string,
	) => Promise<ApiResponse<TableCustomViewPreferences>>;
	customViews: TableCustomViewPreferences[] | undefined;
	selectedCustomView: TableCustomViewPreferences | undefined;
}

export const CustomView: React.FC<ICustomViewProps> = observer(
	({
		onCustomViewDelete,
		onCustomViewSelect,
		onCustomViewCreate,
		customViews,
		selectedCustomView,
	}) => {
		const [isMenuOpen, setIsMenuOpen] = useState(false);
		const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
		const [newViewLabel, setNewViewLabel] = useState<string>('');
		const [isAddingView, setIsAddingView] = useState(false);
		const [createError, setCreateError] = useState<string>();
		const [customViewToDelete, setCustomViewToDelete] =
			useState<TableCustomViewPreferences>();
		const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
		const [saving, setSaving] = useState<boolean>(false);

		const handleSubmitForm = useCallback(async () => {
			setSaving(true);
			setCreateError(undefined);
			try {
				var response = await onCustomViewCreate(newViewLabel);

				if (response.success) {
					setNewViewLabel('');
					setIsAddingView(false);
				} else {
					setCreateError(
						response?.errors?.name[0] || 'An unexpected error occurred.',
					);
				}
			} catch (error) {
				setCreateError('An unexpected error occurred.');
			}
			setSaving(false);
		}, [newViewLabel, onCustomViewCreate]);

		const handleOpenPopper = useCallback(
			(event: React.MouseEvent<HTMLButtonElement>) => {
				setAnchorEl(event.currentTarget);
				setIsMenuOpen(true);
			},
			[],
		);

		const handleClosePopper = useCallback(() => {
			setIsMenuOpen(false);
		}, []);

		const isSelectedCustomView = useMemo(
			() =>
				selectedCustomView !== undefined && selectedCustomView != null
					? true
					: false,
			[selectedCustomView],
		);

		const tooltipTitle = useMemo(
			() =>
				isSelectedCustomView
					? 'Viewing: ' + selectedCustomView?.name
					: 'No saved view is applied',
			[isSelectedCustomView, selectedCustomView?.name],
		);

		const isNewViewLabelValid = useMemo(() => {
			if (newViewLabel ? String.length === 0 : true) {
				return false;
			}

			return customViews?.every((view) => view.name !== newViewLabel) ?? true;
		}, [customViews, newViewLabel]);

		const popperId = useMemo(
			() => (isMenuOpen && Boolean(anchorEl) ? 'transition-popper' : undefined),
			[anchorEl, isMenuOpen],
		);

		const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
			if (event.key === 'Tab') {
				event.preventDefault();
				setIsMenuOpen(false);
			} else if (event.key === 'Escape') {
				setIsMenuOpen(false);
			}
		}, []);

		return (
			<>
				<ClickAwayListener onClickAway={handleClosePopper}>
					<div>
						<Tooltip title={tooltipTitle}>
							<Button
								startIcon={
									<Badge
										color="primary"
										overlap="circular"
										variant="dot"
										invisible={selectedCustomView === undefined}
									>
										<VisibilityIcon />
									</Badge>
								}
								type="button"
								size="small"
								id="custom-view-button"
								aria-controls={isMenuOpen ? 'custom-view-menu' : undefined}
								aria-expanded={isMenuOpen ? 'true' : undefined}
								aria-haspopup="true"
								onClick={handleOpenPopper}
								{...stonlyData({
									id: 'table-custom-view-button',
								})}
							>
								Saved view ({customViews?.length ?? 0})
							</Button>
						</Tooltip>
						<Popper
							id={popperId}
							open={isMenuOpen}
							anchorEl={anchorEl}
							role={undefined}
							transition
							placement="bottom-start"
							sx={{ zIndex: 'modal' }}
						>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Paper
										sx={{
											maxWidth: '300px',
											maxHeight: '300px',
											overflowY: 'auto',
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										<Button
											startIcon={<AddIcon />}
											size="small"
											onClick={() => {
												setIsAddingView(true);
												setIsMenuOpen(false);
											}}
											sx={{
												fontSize: 14,
												borderBottom: 1,
												borderRadius: 0,
												borderColor: brandColors.grey[50],
											}}
										>
											Save current view
										</Button>
										<MenuList
											autoFocusItem={isMenuOpen}
											id="custom-view-menu"
											aria-labelledby="custom-view-button"
											onKeyDown={handleListKeyDown}
											sx={{
												display: 'flex',
												flexDirection: 'column',
												paddingTop: 0,
											}}
										>
											{customViews?.map((customView) => (
												<MenuItem
													key={customView.id}
													onClick={() => {
														setIsMenuOpen(false);
														onCustomViewSelect(customView.id);
													}}
													selected={selectedCustomView?.id === customView.id}
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
														'&:active': {
															backgroundColor: brandColors.equityGreen[50],
														},
													}}
												>
													<Typography
														sx={{
															flexGrow: 1,
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															fontSize: 14,
														}}
													>
														{customView.name}
													</Typography>
													<IconButton
														edge="end"
														aria-label="delete"
														size="small"
														onClick={() => {
															setIsMenuOpen(false);
															setCustomViewToDelete(customView);
															setDeleteModalOpen(true);
														}}
													>
														<DeleteOutlineIcon
															sx={{
																color: brandColors.errorRed[500],
															}}
														/>
													</IconButton>
												</MenuItem>
											))}
										</MenuList>
									</Paper>
								</Fade>
							)}
						</Popper>
					</div>
				</ClickAwayListener>
				<FormModal
					loading={false}
					onClose={() => {
						setIsAddingView(false);
						setNewViewLabel('');
					}}
					open={isAddingView}
					title="Create Saved View"
					onSubmit={handleSubmitForm}
					error={createError}
					submitButtonLabel="Create"
					submitDisabled={!isNewViewLabelValid || saving}
					fullWidth
					maxWidth="xs"
				>
					<T4TextFieldV2
						autoFocus
						value={newViewLabel}
						label="Saved view name"
						onChange={(value) => setNewViewLabel(value)}
						inputProps={{ maxLength: 200 }}
					/>
				</FormModal>
				<FormModal
					open={deleteModalOpen}
					title="Delete saved view"
					description={`Are you sure you want to delete the saved view '${
						customViewToDelete?.name || ''
					}'?`}
					onSubmit={() => {
						if (customViewToDelete) {
							onCustomViewDelete(customViewToDelete.id);
						}
						setDeleteModalOpen(false);
					}}
					loading={false}
					onClose={() => {
						setDeleteModalOpen(false);
					}}
					submitButtonColor="error"
					submitButtonLabel="Delete"
				/>
			</>
		);
	},
);
