import { ListItemText, MenuItem, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { ReportedItem } from 'modules/clients/customer-api/src/api/cash4';
import { FC, useMemo } from 'react';
import { T4Toolbar } from 'shared/components/dataGrid/t4Toolbar';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { OverflowMenu } from 'shared/components/overflowMenu';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { formatCurrency } from 'utilities/currencyUtils';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';

export type ReportedItemsGridProps = {
	stonlyId: string;
	tableKey: string;
	loading?: boolean;
	reportedItems: ReportedItem[];
	selectedReportedIds?: string[];
	setSelectedReportedIds?: (selectedReportedIds: string[]) => void;
};

export const ReportedItemsGrid: FC<ReportedItemsGridProps> = observer(
	({
		stonlyId,
		tableKey,
		loading = false,
		reportedItems,
		selectedReportedIds,
		setSelectedReportedIds,
	}) => {
		const { setSelectedTransaction } = useReconciliationsContext();
		const useCheckboxSelection = useMemo(
			() => selectedReportedIds !== undefined,
			[selectedReportedIds],
		);

		const onRowSelectionModelChangeHandler = useMemo(() => {
			if (useCheckboxSelection && setSelectedReportedIds) {
				return (projectedIds: GridRowSelectionModel) => {
					setSelectedReportedIds(projectedIds.map((x) => x.toString()));
				};
			}
		}, [setSelectedReportedIds, useCheckboxSelection]);

		const columns = useMemo<GridColDef<ReportedItem>[]>(() => {
			const columns: GridColDef<ReportedItem>[] = [
				{
					...getDateColumnDefinition(),
					field: 'date',
					headerName: 'Date',
				},
				{
					field: '_e4AccountName',
					headerName: 'Account name',
					valueGetter: (params) => params.row.e4Account?.name,
				},
				{
					field: '_e4AccountNumber',
					headerName: 'Account number',
					valueGetter: (params) => params.row.e4Account?.number,
				},
				{
					field: '_c4AccountNumber',
					headerName: 'C4 Account number',
					valueGetter: (params) => params.row.c4Account.number,
				},
				{
					field: 'baiCode',
					headerName: 'Bank Transaction Code',
				},
				{
					field: 'bankReference',
					headerName: 'Bank Reference',
				},
				{
					field: 'customerReference',
					headerName: 'Customer Reference',
				},
				{
					field: 'checkNumber',
					headerName: 'Check Number',
				},
				{
					field: 'detail',
					headerName: 'Transaction Details',
				},
				{
					field: 'note',
					headerName: 'Notes',
				},
				{
					field: 'fiTransactionId',
					headerName: 'Bank Transaction Id',
				},
				{
					field: '_ruleName',
					headerName: 'Rule Name',
					valueGetter: (params) =>
						params.row.categorization
							? params.row.categorization.isManual
								? 'Manual Categorization'
								: params.row.categorization.name
							: 'No Rule Matched',
				},
				{
					field: '_cfc',
					headerName: 'CFC',
					description: 'Cash flow class',
					width: DataGridColumnWidths.threeChar,
					valueGetter: (params) => params.row?.categorization?.class?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.class?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_cft',
					headerName: 'CFT',
					description: 'Cash flow type',
					width: DataGridColumnWidths.threeChar,
					valueGetter: (params) => params.row?.categorization?.type?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.type?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_cfst',
					headerName: 'CFST',
					description: 'Cash flow subtype',
					width: DataGridColumnWidths.fourChar,
					valueGetter: (params) => params.row?.categorization?.subtype?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.subtype?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_glCode',
					headerName: 'GL code',
					valueGetter: (params) => params.row?.categorization?.glCode?.code,
				},
				{
					...getCurrencyColumnDefinition(),
					field: 'amount',
					headerName: 'Amount',
					renderCell: (params) =>
						formatCurrency(params.value, {
							currency: params.row.currencyCode,
						}),
				},
				{
					field: 'currencyCode',
					headerName: 'Currency',
				},
			];

			if (!useCheckboxSelection) {
				columns.unshift({
					...getOptionsMenuColDef((params) => {
						return (
							<OverflowMenu id={'c4-create-reconciliation-projected-item'}>
								<MenuItem onClick={() => setSelectedTransaction(params.row)}>
									<ListItemText>View</ListItemText>
								</MenuItem>
							</OverflowMenu>
						);
					}),
				});
			}

			return columns;
		}, [setSelectedTransaction, useCheckboxSelection]);

		return (
			<UserPreferencesDataGrid<ReportedItem>
				stonlyId={stonlyId}
				tableKey={tableKey}
				loading={loading}
				rows={reportedItems}
				hideFooter={false}
				showToolbar={true}
				showCustomViewButton
				pagination
				autoPageSize
				checkboxSelection={useCheckboxSelection}
				disableRowSelectionOnClick={useCheckboxSelection}
				rowSelectionModel={selectedReportedIds}
				onRowSelectionModelChange={onRowSelectionModelChangeHandler}
				initialState={{
					sorting: {
						sortModel: [{ field: 'date', sort: 'asc' }],
					},
				}}
				pinnedColumns={{
					left: [USER_PREFERENCES_FIELD_OPTIONS],
				}}
				slots={{
					toolbar: T4Toolbar,
				}}
				slotProps={{
					toolbar: {
						showQuickFilter: true,
						hideExport: true,
						quickFilterProps: {
							variant: 'outlined',
							size: 'small',
						},
					},
				}}
				columns={columns}
			/>
		);
	},
);
