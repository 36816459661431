import { Add } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Collapse,
	Grid,
	Typography,
} from '@mui/material';
import { useQuery } from 'features/entity4/shared/hooks/userQuery';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { stonlyData } from 'stonly/functions';
import { ObjectPathParams } from '../../../../shared/constants/paths';
import { AddAspectModal } from '../../entityProfile/addAspectModal';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import CommonFieldStore from '../../shared/fieldSets/fieldStore';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import EntityFieldRenderer from '../components/entityFieldRenderer';
import { EntityViewModel } from './models/entityViewModel';

const localStorageAspectInformationVariable: string = 'isAspectInformationOpen';
export const stonlyIds = {
	addAspectButton: 'information-add-aspect-button',
	addAspectDetails: 'information-add-aspect-details',
};
export const testIds = {
	addAspectButton: 'add-aspect-button',
	addAspectDetails: 'add-aspect-details',
};

export const ObjectInformationPage: FC = observer(() => {
	const { objectType, objectId } = useParams<ObjectPathParams>();
	const { isLoading, viewType, setViewType, aspectManager } = useProfileView();
	const query = useQuery();
	const { updateLastAutoSave } = useHeader();
	const errorHandler = useErrorHandler();

	const [viewModel] = useState(
		new EntityViewModel(objectId, objectType, undefined, updateLastAutoSave),
	);
	const [addAspectModal, setAddAspectModal] = useState(false);
	const [isAspectInformationOpen, setIsAspectInformationOpen] = useState(() => {
		const isAspectInformationOpen = localStorage.getItem(
			localStorageAspectInformationVariable,
		);
		if (isAspectInformationOpen === null) {
			return true;
		} else {
			return isAspectInformationOpen === 'true';
		}
	});

	const showAspectDetails = useMemo<boolean>(
		() =>
			Boolean(
				viewType === FieldViews.edit &&
					aspectManager.availableAspects.length > 0,
			),
		[aspectManager.availableAspects.length, viewType],
	);

	useEffect(() => {
		viewModel
			.load()
			.catch((error) => errorHandler(error))
			.finally(() => {
				if (viewModel.latestUpdatedDate) {
					updateLastAutoSave(viewModel.latestUpdatedDate);
				}
			});

		// please leave this so that it will only ever fire correctly
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewModel, errorHandler]);

	useEffect(() => {
		if (query.get('view') === null) setViewType(FieldViews.default);

		// please leave this so that it will only ever fire once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Grid container sx={{ height: '100%', padding: 0 }}>
			{showAspectDetails && (
				<Grid item xs={12} sx={{ margin: '1rem' }}>
					<Collapse in={showAspectDetails}>
						<Accordion
							expanded={isAspectInformationOpen}
							onChange={() => {
								setIsAspectInformationOpen(isAspectInformationOpen);
								localStorage.setItem(
									localStorageAspectInformationVariable,
									isAspectInformationOpen.toString(),
								);
							}}
						>
							<AccordionSummary
								data-testid={testIds.addAspectDetails}
								{...stonlyData({ id: stonlyIds.addAspectDetails })}
							>
								<Typography variant="h6">
									Need to track something else?
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container rowSpacing={2}>
									<Grid item>
										<Typography>
											Some records may require additional information that is
											not available in the standard record. These details can be
											added with prebuilt tabs called Aspects. Aspects allow you
											to track information specific to this record that may not
											apply to other items.
										</Typography>
									</Grid>
									<Grid item>
										<Button
											data-testid={testIds.addAspectButton}
											{...stonlyData({ id: stonlyIds.addAspectButton })}
											startIcon={<Add />}
											variant="contained"
											size="small"
											color="secondary"
											onClick={() => setAddAspectModal(true)}
										>
											Add Aspect
										</Button>
									</Grid>
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Collapse>
					<AddAspectModal
						open={addAspectModal}
						onClose={() => setAddAspectModal(false)}
					/>
				</Grid>
			)}

			<Grid item xs={12}>
				<EntityFieldRenderer
					loading={
						viewModel.loading || isLoading || aspectManager.loadingAspects
					}
					entity={viewModel.entity}
					sortedFields={CommonFieldStore.fields}
					disableV1Rendering={!viewModel.allowsV1Rendering}
				/>
			</Grid>
		</Grid>
	);
});
