import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { SigmaEmbed } from './components/sigmaEmbed';
import { SigmaProvider } from './providers/sigmaProvider';

export const SigmaPage: FC = observer(() => (
	<SigmaProvider>
		<SigmaEmbed />
	</SigmaProvider>
));
