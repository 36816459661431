import { AxiosInstance } from 'axios';
import { Cash4Endpoints, cash4 } from './cash4';

export type FrontendEndpoints = {
	cash4: Cash4Endpoints;
};

export function frontend(axiosInstance: AxiosInstance): FrontendEndpoints {
	return {
		cash4: cash4(axiosInstance),
	};
}
