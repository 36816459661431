import { KeyboardArrowRight, MoreVert } from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { SigmaButton } from 'features/shared/sigma/components/sigmaButton';
import { SigmaMenuItem } from 'features/shared/sigma/components/sigmaMenuItem';
import { useSigma } from 'features/shared/sigma/providers/sigmaProvider';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useState } from 'react';
import { DeleteFolderButton } from './deleteFolderButton';
import { DeleteReportButton } from './deleteReportButton';
import { EditFolderDetailsButton } from './editFolderDetailsButton';
import { EditReportDetailsButton } from './editReportDetailsButton';
import { RecentlyDeleted } from './recentlyDeletedReports';
import { ShareReportButton } from './shareReportButton';

type Dialog =
	| 'openRecentlyDeletedReport'
	| 'openDeleteReport'
	| 'openDeleteFolder'
	| 'openShareReport'
	| 'openEditReport'
	| 'openEditFolder';

export const DashboardOptions: FC = observer(() => {
	const { folder, workbook, recentlyDeletedWorkbooks } = useSigma();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [subDeleteMenuAnchorEl, setSubDeleteMenuAnchorEl] =
		useState<null | HTMLElement>(null);
	const [subEditMenuAnchorEl, setSubEditMenuAnchorEl] =
		useState<null | HTMLElement>(null);
	const [dialogState, setDialogState] = useState({
		openRecentlyDeletedReport: false,
		openDeleteReport: false,
		openDeleteFolder: false,
		openShareReport: false,
		openEditReport: false,
		openEditFolder: false,
	});

	const openDialog = useCallback(
		(dialog: Dialog) => {
			setDialogState({ ...dialogState, [dialog]: true });
			setAnchorEl(null);
			setSubEditMenuAnchorEl(null);
			setSubDeleteMenuAnchorEl(null);
		},
		[dialogState],
	);

	const closeDialog = useCallback(
		(dialog: Dialog) => {
			setDialogState({ ...dialogState, [dialog]: false });
		},
		[dialogState],
	);

	return (
		<>
			<SigmaButton
				startIcon={<MoreVert />}
				onClick={(event) => setAnchorEl(event.currentTarget)}
			/>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<MenuItem
					onMouseOver={(event) => setSubEditMenuAnchorEl(event.currentTarget)}
				>
					Edit
					<ListItemIcon sx={{ justifyContent: 'right' }}>
						<KeyboardArrowRight />
					</ListItemIcon>
				</MenuItem>
				<Menu
					anchorEl={subEditMenuAnchorEl}
					open={Boolean(subEditMenuAnchorEl)}
					onClose={() => setSubEditMenuAnchorEl(null)}
					anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
					{folder?.name && (
						<SigmaMenuItem
							name="folder"
							menuText={`Edit ${folder?.name} Folder`}
							disabled={
								folder?.permission !== 'edit' || folder?.name === 'My Reports'
							}
							onClick={() => openDialog('openEditFolder')}
						/>
					)}
					{workbook?.name && (
						<SigmaMenuItem
							name="report"
							menuText={`Edit ${workbook?.name}`}
							disabled={workbook?.permission !== 'edit'}
							onClick={() => openDialog('openEditReport')}
						/>
					)}
				</Menu>

				<MenuItem
					disabled={
						!(
							(workbook?.permission === 'edit' ||
								workbook?.permission === 'create' ||
								workbook?.permission === 'contribute') &&
							workbook !== undefined
						)
					}
					onClick={() => openDialog('openShareReport')}
				>
					Share
				</MenuItem>
				<MenuItem
					onMouseOver={(event) => setSubDeleteMenuAnchorEl(event.currentTarget)}
				>
					Delete
					<ListItemIcon sx={{ justifyContent: 'right' }}>
						<KeyboardArrowRight />
					</ListItemIcon>
				</MenuItem>
				<Menu
					anchorEl={subDeleteMenuAnchorEl}
					open={Boolean(subDeleteMenuAnchorEl)}
					onClose={() => setSubDeleteMenuAnchorEl(null)}
					anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
					{folder?.name && (
						<SigmaMenuItem
							name={'folder'}
							menuText={`Delete ${folder?.name} Folder`}
							disabled={
								folder?.permission !== 'edit' || folder?.name === 'My Reports'
							}
							onClick={() => openDialog('openDeleteFolder')}
						/>
					)}
					{workbook?.name && (
						<SigmaMenuItem
							name="report"
							menuText={`Delete ${workbook?.name}`}
							disabled={workbook?.permission !== 'edit'}
							onClick={() => openDialog('openDeleteReport')}
						/>
					)}
					<MenuItem
						onClick={() => openDialog('openRecentlyDeletedReport')}
						disabled={(recentlyDeletedWorkbooks?.length ?? 0) === 0}
					>
						Recently Deleted
					</MenuItem>
				</Menu>
			</Menu>
			<RecentlyDeleted
				isOpen={dialogState.openRecentlyDeletedReport}
				close={() => closeDialog('openRecentlyDeletedReport')}
			/>
			<DeleteReportButton
				open={dialogState.openDeleteReport}
				close={() => closeDialog('openDeleteReport')}
			/>
			<DeleteFolderButton
				open={dialogState.openDeleteFolder}
				close={() => closeDialog('openDeleteFolder')}
			/>
			<ShareReportButton
				open={dialogState.openShareReport}
				close={() => closeDialog('openShareReport')}
			/>
			<EditReportDetailsButton
				open={dialogState.openEditReport}
				close={() => closeDialog('openEditReport')}
			/>
			<EditFolderDetailsButton
				open={dialogState.openEditFolder}
				close={() => closeDialog('openEditFolder')}
			/>
		</>
	);
});
