import { GppBad } from '@mui/icons-material';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const Cash4DisabledPage: FC = () => {
	return (
		<Container
			sx={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
			}}
		>
			<Grid
				container
				rowSpacing={2}
				sx={{
					flexDirection: 'column',
					flexWrap: 'nowrap',
					alignItems: 'center',
				}}
			>
				<Grid item xs={12}>
					<Box component="img" src="" />
					<GppBad sx={{ height: '200px', width: '200px' }} />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h1">Module Disabled</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle1" align="center">
						You do not have access to the Cash4 Module.
					</Typography>
				</Grid>
				<Grid
					container
					item
					columnSpacing={2}
					xs={12}
					sx={{
						flexDirection: 'row',
						flexWrap: 'nowrap',
						justifyContent: 'center',
					}}
				>
					<Grid item>
						<Button variant="outlined" component={Link} to="/">
							See Solutions
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Cash4DisabledPage;
