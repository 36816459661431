import { Chip, ChipProps, styled, Theme } from '@mui/material';
import { FC } from 'react';

type T4ChipColor =
	| 'blue'
	| 'gold'
	| 'red'
	| 'lightblue'
	| 'lightred'
	| 'lightgreen';
export interface T4ChipProps
	extends Pick<
		ChipProps,
		'id' | 'label' | 'color' | 'icon' | 'sx' | 'onClick'
	> {
	t4ChipColor?: T4ChipColor;
}
export const T4Chip: FC<T4ChipProps> = ({
	id,
	t4ChipColor,
	children,
	...props
}) => (
	<StyledChip
		id={`t4-chip${id ? `-${id}` : ''}`}
		size="small"
		t4chipcolor={t4ChipColor}
		{...props}
	/>
);

const backgroundColor = (theme: Theme) => ({
	blue: theme.palette.secondary.main,
	gold: theme.common?.maize?.main,
	red: theme.palette.error.main,
	lightblue: theme.common?.cornflower?.main + '40',
	lightgreen: theme.palette.primary.main + '40',
	lightred: theme.palette.error.main + '20',
});
const color = (theme: Theme) => ({
	blue: theme.palette.secondary.contrastText,
	gold: theme.palette.secondary.contrastText,
	red: theme.palette.secondary.contrastText,
	lightblue: theme.common?.charcoal?.main,
	lightgreen: theme.common?.charcoal?.main,
	lightred: theme.common?.charcoal?.main,
});
interface StyledChipProps extends ChipProps {
	t4chipcolor?: T4ChipColor;
}
const StyledChip = styled(Chip)<StyledChipProps>(({ theme, t4chipcolor }) => ({
	...(t4chipcolor && {
		color: color(theme)[t4chipcolor],
		backgroundColor: backgroundColor(theme)[t4chipcolor],
		fontWeight: ['lightblue', 'lightgreen', 'lightred'].includes(t4chipcolor)
			? 'bold'
			: 'normal',
	}),
	'& .MuiChip-label': {
		paddingTop: '1px',
	},
}));
