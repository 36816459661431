import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Typography } from '@mui/material';
import T4Drawer from 'features/cash4/shared/components/T4SideDrawer/T4DrawerShell';
import { ProjectedItemDrawer } from 'features/cash4/transactions/components/ProjectedItems/projectedItemDrawer';
import { TransactionDrawer } from 'features/cash4/transactions/components/TransactionDrawer/TransactionDrawer';
import { EditRuleProvider } from 'features/cash4/transactions/providers/useEditRule';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';
import { ModifyReconciliationView } from '../_views/modifyReconciliationView';
import { ReconciliationView } from '../_views/reconciliationView';
import { useCreateReconciliation } from '../services';
import { C4CancelDialog } from './c4CancelDialog';
import { C4Drawer, C4DrawerProps } from './c4Drawer';

export enum ReconciliationMode {
	Create,
	View,
	Edit,
}

export enum ReconciliationTab {
	Selection,
	Detail,
}

export type ReconciliationDrawerProps = {};

export const ReconciliationDrawer: FC<ReconciliationDrawerProps> = observer(
	() => {
		const {
			open,
			mode,
			tab,
			reconciliation,
			selectedProjectedItem,
			projectedItemDrawerOpen,
			selectedTransaction,
			setSelectedTransaction,
			setProjectedItemDrawerOpen,
			setSelectedProjectedItem,
			setTab,
			updateReconciliation,
			onDrawerClose,
			notes,
		} = useReconciliationsContext();

		const [cancelOpen, setCancelOpen] = useState(false);
		const [selectedProjectedIds, setSelectedProjectedIds] = useState<string[]>(
			[],
		);
		const [selectedReportedIds, setSelectedReportedIds] = useState<string[]>(
			[],
		);

		const isSubmitDisabled = useMemo(
			() =>
				(selectedProjectedIds?.length ?? 0) <= 0 ||
				(selectedReportedIds?.length ?? 0) <= 0,
			[selectedProjectedIds?.length, selectedReportedIds?.length],
		);

		const onCancelHandler = useCallback(() => {
			setCancelOpen(true);
		}, []);

		const onCloseHandler = useCallback(() => {
			setCancelOpen(false);
			onDrawerClose();
		}, [onDrawerClose]);

		const onClickAwayHandler = useCallback(() => {
			if (mode !== ReconciliationMode.View) {
				onCancelHandler();
			} else {
				onCloseHandler();
			}
		}, [mode, onCancelHandler, onCloseHandler]);

		const { mutate: createReconciliation } =
			useCreateReconciliation(onDrawerClose);

		const title = useMemo(() => {
			let text = 'Reconciliation';
			switch (mode) {
				case ReconciliationMode.Create:
					text = 'Create reconciliation';
					break;

				case ReconciliationMode.Edit:
					text = 'Edit reconciliation';
					break;
			}

			return <Typography variant="h3">{text}</Typography>;
		}, [mode]);

		const actions = useMemo<C4DrawerProps['actions']>(() => {
			let actions: C4DrawerProps['actions'] = {};

			const cancelButton = (
				<T4Button color="secondary" onClick={onCancelHandler}>
					Cancel
				</T4Button>
			);

			switch (mode) {
				case ReconciliationMode.View:
					actions = {
						end: [cancelButton],
					};
					break;

				default:
					actions = {
						start:
							tab === ReconciliationTab.Detail
								? [
										<T4Button
											color="secondary"
											variant="outlined"
											startIcon={<ChevronLeft />}
											onClick={() => setTab(ReconciliationTab.Selection)}
										>
											Select Records
										</T4Button>,
								  ]
								: undefined,
						end:
							tab === ReconciliationTab.Selection
								? [
										cancelButton,
										<T4Button
											variant="contained"
											endIcon={<ChevronRight />}
											onClick={() => setTab(ReconciliationTab.Detail)}
											disabled={
												selectedProjectedIds.length === 0 ||
												selectedReportedIds.length === 0
											}
										>
											{`${
												mode === ReconciliationMode.Create ? 'Add' : 'Edit'
											} Details`}
										</T4Button>,
								  ]
								: [
										cancelButton,
										<T4Button
											variant="contained"
											disabled={isSubmitDisabled}
											onClick={() => {
												if (mode === ReconciliationMode.Create) {
													createReconciliation({
														projectedTransactions: selectedProjectedIds,
														reportedTransactions: selectedReportedIds,
														note: notes,
													});
												} else {
													updateReconciliation(
														selectedProjectedIds,
														selectedReportedIds,
													);
												}
											}}
										>
											{mode === ReconciliationMode.Create ? 'Create' : 'Save'}
										</T4Button>,
								  ],
					};
					break;
			}

			return actions;
		}, [
			createReconciliation,
			isSubmitDisabled,
			mode,
			notes,
			onCancelHandler,
			selectedProjectedIds,
			selectedReportedIds,
			setTab,
			tab,
			updateReconciliation,
		]);

		const cancellationModalDetails = useMemo(() => {
			let details = {
				title: 'Cancel reconciliation creation?',
				description:
					'Canceling reconciliation creation will discard any data you have entered, and a reconciliation will not be created.',
			};

			switch (mode) {
				case ReconciliationMode.Edit:
					details.title = 'Discard edits?';
					details.description =
						'Discarding will remove all changes made to the reconciliation.';
					break;
			}

			return details;
		}, [mode]);

		useEffect(() => {
			if (reconciliation) {
				setSelectedProjectedIds(
					reconciliation.projectedItems.map((item) => item.id),
				);
				setSelectedReportedIds(
					reconciliation.reportedItems.map((item) => item.id),
				);
			}
		}, [reconciliation]);

		useEffect(() => {
			if (!open) {
				setSelectedProjectedIds([]);
				setSelectedReportedIds([]);
			}
		}, [open]);

		return (
			<C4Drawer
				open={open}
				title={title}
				actions={actions}
				onClickAway={onClickAwayHandler}
				sx={{
					'& .MuiDrawer-paper': {
						width: '80%',
					},
				}}
			>
				<TabContext value={mode.toString()}>
					<TabPanel
						value={ReconciliationMode.View.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						<ReconciliationView />
					</TabPanel>
					<TabPanel
						value={ReconciliationMode.Create.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						<ModifyReconciliationView
							selectedProjectedIds={selectedProjectedIds}
							setSelectedProjectedIds={setSelectedProjectedIds}
							selectedReportedIds={selectedReportedIds}
							setSelectedReportedIds={setSelectedReportedIds}
						/>
					</TabPanel>
					<TabPanel
						value={ReconciliationMode.Edit.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						<ModifyReconciliationView
							selectedProjectedIds={selectedProjectedIds}
							setSelectedProjectedIds={setSelectedProjectedIds}
							selectedReportedIds={selectedReportedIds}
							setSelectedReportedIds={setSelectedReportedIds}
						/>
					</TabPanel>
				</TabContext>
				<C4CancelDialog
					open={cancelOpen}
					title={cancellationModalDetails.title}
					description={cancellationModalDetails.description}
					onCancel={() => setCancelOpen(false)}
					onConfirm={onCloseHandler}
					color={mode === ReconciliationMode.Create ? 'error' : 'warning'}
				/>
				<ProjectedItemDrawer
					isOpen={projectedItemDrawerOpen}
					projectedItem={selectedProjectedItem}
					onClose={() => {
						setProjectedItemDrawerOpen(false);
						setSelectedProjectedItem(undefined);
					}}
				/>
				<EditRuleProvider>
					<T4Drawer
						open={Boolean(selectedTransaction)}
						onClose={() => {
							setSelectedTransaction(undefined);
						}}
						stonlyPrefix="transaction-details"
					>
						<TransactionDrawer
							transaction={{
								id: selectedTransaction?.id ?? '',
								date: selectedTransaction?.date ?? '',
								c4AccountNumber: selectedTransaction?.c4Account?.number ?? '',
								e4AccountName: selectedTransaction?.e4Account?.name ?? '',
								e4AccountNumber: selectedTransaction?.e4Account?.number ?? '',
								e4AccountId: selectedTransaction?.e4Account?.id ?? '',
								transactionCode: selectedTransaction?.baiCode ?? '',
								bankReference: selectedTransaction?.bankReference ?? '',
								customerReference: selectedTransaction?.customerReference ?? '',
								checkNumber: selectedTransaction?.checkNumber ?? '',
								detail: selectedTransaction?.detail ?? '',
								cfc: selectedTransaction?.categorization?.class.code ?? '',
								cfcName: selectedTransaction?.categorization?.class.name ?? '',
								cft: selectedTransaction?.categorization?.type.code ?? '',
								cftName: selectedTransaction?.categorization?.type.name ?? '',
								cfst: selectedTransaction?.categorization?.subtype?.code ?? '',
								cfstName:
									selectedTransaction?.categorization?.subtype?.name ?? '',
								glNumber:
									selectedTransaction?.categorization?.glCode?.code ?? '',
								number: {
									value: selectedTransaction?.amount ?? 0,
									reportingValue: 0, // todo: query for single transaction instead
									reportingCurrencyRate: 1, // todo: query for single transaction instead
									reportingCurrencyEffectiveDate: '', // todo: query for single transaction instead
								},
								noteContent: selectedTransaction?.note ?? '',
								currency: selectedTransaction?.currencyCode ?? '',
								bankName: selectedTransaction?.c4Account.bank?.name ?? '',
								bankCode: selectedTransaction?.c4Account.bank?.code ?? '',
								importedDate: selectedTransaction?.createdOn ?? '',
								transactionRuleId:
									selectedTransaction?.categorization?.id ?? '',
								transactionRuleName:
									selectedTransaction?.categorization?.name ?? '',
								fiTransactionId: selectedTransaction?.fiTransactionId ?? '',
								reconciliationStatus:
									selectedTransaction?.reconciliationStatus ?? '',
								reconciliationRecordId: '', // todo: query for single transaction instead
							}}
							disableLink
						/>
					</T4Drawer>
				</EditRuleProvider>
			</C4Drawer>
		);
	},
);
