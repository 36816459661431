import { observer } from 'mobx-react-lite';
import React, { createContext, FC, useContext, useState } from 'react';

//#region Context

type EditRuleContextProps = {
	isEditing: boolean;
	isManualCategorization: boolean;
	setIsEditing: (value: boolean) => void;
	setIsManualCategorization: (value: boolean) => void;
};

const EditRuleContext = createContext<EditRuleContextProps>({
	isEditing: false,
	isManualCategorization: false,
	setIsEditing: () => {},
	setIsManualCategorization: () => {},
});

//#endregion

//#region Provider

export type EditRuleProviderProps = {
	children: React.ReactNode;
};

export const EditRuleProvider: FC<EditRuleProviderProps> = observer(
	({ children }) => {
		const [isEditing, setIsEditing] = useState(false);
		const [isManualCategorization, setIsManualCategorization] = useState(false);

		return (
			<EditRuleContext.Provider
				value={{
					isEditing,
					isManualCategorization,
					setIsEditing,
					setIsManualCategorization,
				}}
			>
				{children}
			</EditRuleContext.Provider>
		);
	},
);

//#endregion

//#region Hook

export type UseEditRuleProps = EditRuleContextProps;

export function useEditRule(): UseEditRuleProps {
	return useContext(EditRuleContext);
}

//#endregion
