import {
	ApprovalLevel,
	ApprovalRule,
} from 'modules/clients/apiGateway/payments4/approvalRules';
import { T4DataResponse2, T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';

interface UseEditPaymentTierDrawerProps {
	isHandleSetAmountLoading: boolean;
	handleSetAmountErrors: string[];
	handleSetAmount: (
		ruleId: string,
		tierId: string,
		amount: number,
		isUnlimitedAmount: boolean,
	) => Promise<{ amount: number; isUnlimitedAmount: boolean } | undefined>;

	isHandleSetIsAutoApproveLoading: boolean;
	handleSetIsAutoApproveErrors: string[];
	handleSetIsAutoApprove: (
		ruleId: string,
		tierId: string,
		isAutoApprove: boolean,
	) => Promise<boolean | undefined>;

	isHandleAddLevelLoading: boolean;
	handleAddLevelErrors: string[];
	handleAddLevel: (
		ruleId: string,
		tierId: string,
		users: string[],
	) => Promise<ApprovalLevel | undefined>;

	isHandleUpdateLevelUsersLoading: boolean;
	handleUpdateLevelUsers: (
		ruleId: string,
		tierId: string,
		levelId: string,
		users: string[],
	) => Promise<string[] | undefined>;

	isHandleRemoveLevelLoading: boolean;
	handleRemoveLevel: (
		ruleId: string,
		tierId: string,
		levelId: string,
	) => Promise<string | undefined>;
}

export const useEditPaymentTierFunctions =
	(): UseEditPaymentTierDrawerProps => {
		const { applicationApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();

		// #region Set Amount

		const [isHandleSetAmountLoading, setIsHandleSetAmountLoading] =
			useState<boolean>(false);
		const [handleSetAmountErrors, setHandleSetAmountErrors] = useState<
			string[]
		>([]);
		const handleSetAmount = useCallback(
			async (
				ruleId: string,
				tierId: string,
				amount: number,
				isUnlimitedAmount: boolean,
			) => {
				try {
					setHandleSetAmountErrors([]);
					setIsHandleSetAmountLoading(true);
					const response =
						await applicationApiClient.payments4.approvalRules.setTierAmount({
							ruleId: ruleId,
							tierId: tierId,
							data: { amount, isUnlimitedAmount },
						});
					if (response.status === 200) {
						enqueueSnackbar('Successfully updated tier amount.', {
							variant: 'success',
						});
						return { amount, isUnlimitedAmount };
					} else if (response.status === 400 && response.data)
						setHandleSetAmountErrors(
							flattenProblemDetails(response.data as T4ProblemDetails),
						);
					else throw new Error();
				} catch {
					enqueueSnackbar('Unable to set amount. Please try again later.', {
						variant: 'error',
					});
				} finally {
					setIsHandleSetAmountLoading(false);
				}
			},
			[applicationApiClient, enqueueSnackbar],
		);

		// #endregion

		// #region Set IsAutoApprove

		const [
			isHandleSetIsAutoApproveLoading,
			setIsHandleSetIsAutoApproveLoading,
		] = useState<boolean>(false);
		const [handleSetIsAutoApproveErrors, setHandleSetIsAutoApproveErrors] =
			useState<string[]>([]);
		const handleSetIsAutoApprove = useCallback(
			async (ruleId: string, tierId: string, isAutoApprove: boolean) => {
				try {
					setIsHandleSetIsAutoApproveLoading(true);
					const response =
						await applicationApiClient.payments4.approvalRules.setTierAutoApprove(
							{ ruleId, tierId, isAutoApprove },
						);
					if (response.status === 200) {
						enqueueSnackbar(
							`Successfully updated tier to ${
								isAutoApprove ? 'no approvals required.' : 'require approvals.'
							}`,
							{
								variant: 'success',
							},
						);
						return isAutoApprove;
					} else if (response.status === 400 && response.data)
						setHandleSetIsAutoApproveErrors(
							flattenProblemDetails(response.data as T4ProblemDetails),
						);
					else throw new Error();
				} catch {
					enqueueSnackbar(
						'Unable to set auto approvals. Please try again later.',
						{
							variant: 'error',
						},
					);
				} finally {
					setIsHandleSetIsAutoApproveLoading(false);
				}
			},
			[applicationApiClient, enqueueSnackbar],
		);

		// #endregion

		// #region Add Approval Level

		const [isHandleAddLevelLoading, setIsHandleAddLevelLoading] =
			useState<boolean>(false);
		const [handleAddLevelErrors, setHandleAddLevelErrors] = useState<string[]>(
			[],
		);
		const handleAddLevel = useCallback(
			async (ruleId: string, tierId: string, users: string[]) => {
				try {
					setIsHandleAddLevelLoading(true);
					const response =
						await applicationApiClient.payments4.approvalRules.addApprovallevel(
							{ ruleId, tierId, data: { users } },
						);
					if (response.status === 200) {
						enqueueSnackbar('Successfully added approval level.', {
							variant: 'success',
						});
						const responseTier = (
							response.data as T4DataResponse2<ApprovalRule>
						).data.approvalTiers.find((tier) => tier.id === tierId);
						return responseTier?.approvalLevels.sort(
							(a, b) => a.level - b.level,
						)[responseTier.approvalLevels.length - 1];
					} else if (response.status === 400 && response.data) {
						setHandleAddLevelErrors(
							flattenProblemDetails(response.data as T4ProblemDetails),
						);
					} else throw new Error();
				} catch {
					enqueueSnackbar(
						'Unable to add approval level. Please try again later.',
						{
							variant: 'error',
						},
					);
				} finally {
					setIsHandleAddLevelLoading(false);
				}
			},
			[applicationApiClient, enqueueSnackbar],
		);

		// #endregion

		// #region Update Approval Level Users

		const [
			isHandleUpdateLevelUsersLoading,
			setIsHandleUpdateLevelUsersLoading,
		] = useState<boolean>(false);
		const handleUpdateLevelUsers = useCallback(
			async (
				ruleId: string,
				tierId: string,
				levelId: string,
				users: string[],
			) => {
				try {
					setIsHandleUpdateLevelUsersLoading(true);
					const response =
						await applicationApiClient.payments4.approvalRules.setApprovalUsers(
							{
								ruleId,
								tierId,
								levelId,
								data: { users },
							},
						);
					if (response.status === 200) {
						enqueueSnackbar('Successfully updated approval level users.', {
							variant: 'success',
						});
						return users;
					} else throw new Error();
				} catch {
					enqueueSnackbar(
						'Unable to update approval level users. Please try again later.',
						{
							variant: 'error',
						},
					);
				} finally {
					setIsHandleUpdateLevelUsersLoading(false);
				}
			},
			[applicationApiClient, enqueueSnackbar],
		);

		// #endregion

		// #region Remove Approval Level

		const [isHandleRemoveLevelLoading, setIsHandleRemoveLevelLoading] =
			useState<boolean>(false);
		const handleRemoveLevel = useCallback(
			async (ruleId: string, tierId: string, levelId: string) => {
				try {
					setIsHandleRemoveLevelLoading(true);
					const response =
						await applicationApiClient.payments4.approvalRules.deleteApprovalLevel(
							{ ruleId, tierId, levelId },
						);
					if (response.status === 200) {
						enqueueSnackbar('Successfully removed approval level.', {
							variant: 'success',
						});
						return levelId;
					} else throw new Error();
				} catch {
					enqueueSnackbar(
						'Unable to remove approval level. Please try again later.',
						{
							variant: 'error',
						},
					);
				} finally {
					setIsHandleRemoveLevelLoading(false);
				}
			},
			[applicationApiClient, enqueueSnackbar],
		);

		// #endregion

		return {
			isHandleSetAmountLoading,
			handleSetAmountErrors,
			handleSetAmount,

			isHandleSetIsAutoApproveLoading,
			handleSetIsAutoApproveErrors,
			handleSetIsAutoApprove,

			isHandleAddLevelLoading,
			handleAddLevelErrors,
			handleAddLevel,

			isHandleUpdateLevelUsersLoading,
			handleUpdateLevelUsers,

			isHandleRemoveLevelLoading,
			handleRemoveLevel,
		};
	};
