import { customerApi } from 'shared/providers/customerApi';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { IRegistrationAuthorityApiData } from '../../entities/entitiesApiTypes';

export class ReferenceRepository {
	public async getCollection(
		referenceCollectionName?: string,
		parentIdentifier?: string | null,
	): Promise<ReferenceDataValue[]> {
		const response = await customerApi.get<ReferenceDataValue[]>(
			`/common/collections/${referenceCollectionName}`,
			{
				params: {
					parentIdentifier,
				},
			},
		);
		if (response.status === 200) {
			return response.data;
		}

		throw new Error(
			`Reference collection not found: ${referenceCollectionName}`,
		);
	}

	public async getElfCodes(
		countryCode: string,
		subdivisionCode?: string | null,
	): Promise<ReferenceDataValue[]> {
		const referenceCollectionName = 'EntityLegalFormCodes';
		const response = await customerApi.get<ReferenceDataValue[]>(
			`/common/collections/${referenceCollectionName}`,
			{
				params: {
					countryCode,
					subdivisionCode,
				},
			},
		);
		if (response.status === 200) {
			return response.data;
		}

		throw new Error(
			`Reference collection not found: ${referenceCollectionName}`,
		);
	}

	public async getRegistrationAuthoritiesByCountryCode(
		parentIdentifier?: string,
	): Promise<ReferenceDataValue[]> {
		const referenceCollectionName = 'RegistrationAuthorities';
		const response = await customerApi.get<ReferenceDataValue[]>(
			`/common/collections/${referenceCollectionName}`,
			{
				params: {
					parentIdentifier,
				},
			},
		);
		if (response.status === 200) {
			return response.data;
		}

		throw new Error(
			`Reference collection not found: ${referenceCollectionName}`,
		);
	}

	public async getRegistrationAuthorityByIdentifier(
		identifier?: string,
	): Promise<IRegistrationAuthorityApiData> {
		const referenceCollectionName = 'RegistrationAuthorities';
		const response = await customerApi.get<IRegistrationAuthorityApiData>(
			`/common/collections/${referenceCollectionName}/${identifier}`,
		);
		if (response.status === 200) {
			return response.data;
		}

		throw new Error(
			`Reference collection not found: ${referenceCollectionName}`,
		);
	}
}

const CommonReferenceRepository = new ReferenceRepository();
export default CommonReferenceRepository;
