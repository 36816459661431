import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { EntityType } from 'features/entity4/entity4Constants';
import { History } from 'history';
import { flow, makeAutoObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { paths } from 'shared/constants/paths';
import { FrontendGroupRepository } from '../groupRepository';
import { UngroupedEntitiesListModel } from './ungroupedEntitiesListModel';

export class UngroupedEntitiesListViewModel {
	private _history: History;
	private listModel: UngroupedEntitiesListModel;

	constructor(
		frontendGroupsRepository: FrontendGroupRepository,
		history: History,
	) {
		makeAutoObservable(this);

		this._history = history;
		this.listModel = new UngroupedEntitiesListModel(frontendGroupsRepository);
	}

	public getLoading = () => {
		return this.listModel.loading;
	};

	public getError = () => {
		return this.listModel.error !== '' ? this.listModel.error : undefined;
	};

	public getColumns = (): GridColDef[] => {
		return [
			{
				field: 'name',
				headerName: 'Name',
				flex: 12,
				minWidth: 250,
			},
			{
				field: 'status',
				headerName: 'Status',
				flex: 10,
				minWidth: 200,
			},
			{
				field: 'region',
				headerName: 'Region',
				flex: 10,
				minWidth: 200,
			},
			{
				field: 'functionalCurrencyCode',
				headerName: 'Currency Code',
				flex: 10,
				minWidth: 200,
			},
		];
	};

	public getRows = () => {
		return this.listModel.ungroupedEntities.map((entity) => {
			return {
				id: entity.id,
				name: entity.name,
				status: entity.status,
				region: entity.region,
				functionalCurrencyCode: entity.functionalCurrencyCode,
			};
		});
	};

	public onCellClick = (params: GridCellParams) => {
		this._history.push(
			generatePath(paths.entity4.objects.object.information.href, {
				objectId: params.id as string,
				objectType: EntityType.Entity,
			}),
		);
	};

	public loadUngroupedEntities = flow(function* (
		this: UngroupedEntitiesListViewModel,
	) {
		yield this.listModel.load();
	});
}
