import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { DashboardPage } from 'shared/dashboardPage/dashboardPage';
import DashboardPageModel, {
	IWorkspaceEmbedDto,
} from 'shared/dashboardPage/dashboardPageModel';
import DashboardPageViewModel from 'shared/dashboardPage/dashboardPageViewModel';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';

interface IProps {}

const Cash4AnalyticsStudioModel = new DashboardPageModel(() =>
	customerApi
		.get<ApiResponse<IWorkspaceEmbedDto>>('/cash4/analyticsStudio')
		.then((response) => response.data.value),
);

const AnalyticsStudioPage: React.FC<IProps> = observer(() => {
	const [viewModel] = useState(
		new DashboardPageViewModel(Cash4AnalyticsStudioModel),
	);
	return (
		<>
			<PageHeader
				id={pageHeaderStonlyIds.analyticsStudioPage}
				title="Analytics Studio - Classic"
			/>
			<DashboardPage viewModel={viewModel} />
		</>
	);
});

export default AnalyticsStudioPage;
