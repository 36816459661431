import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ObjectPathParams } from 'shared/constants/paths';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { OptionList } from 'shared/types/referenceDataTypes';
import { useProfileView } from '../../../../entityProfile/providers/entityProfileContextProvider';
import { TaxIds } from '../models/taxIds';
import { TaxIdsView } from '../views/taxIdsView';

const optionIds = {
	taxIdStatuses: '3D6D5576-FA38-4F73-9AB6-39FB47601C83'.toLowerCase(),
	taxIdPurposes: 'C5A4AC62-1B65-4EF9-950C-00CFFE8F0CE0'.toLowerCase(),
};

export const ObjectTaxIdsPage: FC = observer(() => {
	const handleError = useErrorHandler();
	const { objectId, objectType } = useParams<ObjectPathParams>();
	const { fetch } = useReferenceDataFetcher();
	const { setViewType } = useProfileView();

	const [taxIds] = useState(new TaxIds(objectId, objectType));
	const [purposes, setPurposes] = useState<OptionList>();
	const [statuses, setStatuses] = useState<OptionList>();

	useEffect(() => {
		setViewType(FieldViews.default);
	}, [setViewType]);

	useEffect(() => {
		fetch({
			referenceListNames: [],
			optionListIds: [optionIds.taxIdPurposes, optionIds.taxIdStatuses],
		}).then((result) => {
			setPurposes(result?.optionLists[optionIds.taxIdPurposes]);
			setStatuses(result?.optionLists[optionIds.taxIdStatuses]);
		});
		taxIds.fetch().catch((error) => handleError(error));
	}, [fetch, handleError, taxIds]);

	return (
		<TaxIdsView
			taxIds={taxIds}
			entityType={objectType}
			purposes={purposes}
			statuses={statuses}
		/>
	);
});
