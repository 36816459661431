import { AxiosInstance } from 'axios';
import { EntityTypeId } from 'features/entity4/entity4Constants';
import { Read, T4ApiResponse, Write } from 'modules/clients/types';
import { User } from '..';

export enum PaymentStatusTypes {
	Default = 0,
	Submitted = 1,
	Approved = 2,
	Initiated = 3,
	Transmitted = 4,
	Pending = 5,
	Rejected = 6,
	Failed = 7,
	Completed = 8,
}

export enum PaymentPriorityTypes {
	Urgp = 1,
}

export enum PaymentTypes {
	Wire = 1,
	Ach = 2,
	BookTransfer = 3,
}

export type Payment = {
	id: string;
	paymentTemplateId: string | null;
	paymentTemplateName: string | null;
	currentStatus: string;
	paymentType: string;
	priorityType: string;
	endToEndId: string;
	bankTransactionId: string;
	initiator: PaymentParty;
	payee: PaymentParty;
	instructedAmount: PaymentAmount;
	referenceData: string | null;
	createdOn: string;
	valueDate: string;
	statusHistory: PaymentStatusHistory[];
	approvalState: PaymentApprovalState | null;
};

export type PaymentParty = {
	entityId: string | null;
	partyType: EntityTypeId | null;
	name: string;
	countryCode: string;
	accountId: string | null;
	accountNumber: string;
	accountName: string | null;
	bank: Bank;
};

export type Bank = {
	entityId: string | null;
	name: string;
	bankCode: string;
	countryCode: string;
	aba: string | null;
	bic: string | null;
};

export type PaymentAmount = {
	value: number | null;
	currencyCode: string | null;
};

export type PaymentStatusHistory = {
	paymentStatusType: string;
	reason: string | null;
	createdBy: User | null;
	createdOn: string;
};

export type PaymentApprovalState = {
	id: string;
	paymentId: string;
	approvalRuleId: string;
	approvalRuleVersion: number;
	approvalRuleTierId: string;
	currentApprovalLevelId: string;
	isAutoApprove: boolean;
	canApproveOwnPayments: boolean;
	hasSequentialApprovers: boolean;
	requiresUniqueApprovers: boolean;
	currentStatus: string | null;
	isCompleted: boolean;
	paymentCreatedBy: User | null;
	paymentApprovalLevelStates: PaymentApprovalLevelState[];
};

export type PaymentApprovalLevelState = {
	id: string;
	paymentApprovalStateId: string;
	paymentApprovalLevelId: string;
	level: number;
	status: string | null;
	approvedBy: User | null;
	approvedOn: string | null;
	rejectedBy: User | null;
	rejectedOn: string | null;
	rejectedReason: string | null;
	approvers: User[] | null;
};

export enum PaymentApprovalLevelStatusTypes {
	Pending = 1,
	Approved = 2,
	Rejected = 3,
}

// #region Requests

export type SubmitPaymentRequest = {
	paymentType: string;
	priorityType: string;
	initiator: PaymentParty;
	payee: PaymentParty;
	instructedAmount: PaymentAmount;
	referenceData: string | null;
	valueDate: string; // DateOnly
};

export type RejectPaymentRequest = {
	reason: string | null;
};

// #endregion

export type PaymentEndpoints = {
	get: Read<T4ApiResponse<Payment>, string>;
	getAll: Read<T4ApiResponse<Payment[]>>;
	submit: Write<T4ApiResponse<Payment>, SubmitPaymentRequest>;
	approve: Write<T4ApiResponse<Payment>, string>;
	reject: Write<
		T4ApiResponse<Payment>,
		{ id: string; data: RejectPaymentRequest }
	>;
};

export function payments(axiosInstance: AxiosInstance): PaymentEndpoints {
	return {
		get: async (id, config = {}) => {
			return await axiosInstance.get(`payments4/payments/${id}`, {
				...config,
			});
		},
		getAll: async (_, config = {}) => {
			return await axiosInstance.get('payments4/payments', {
				...config,
			});
		},
		submit: async (data, config = {}) => {
			return await axiosInstance.post('payments4/payments/submit', data, {
				...config,
			});
		},
		approve: async (id, config = {}) => {
			return await axiosInstance.put(`payments4/payments/${id}/approve`, {
				...config,
			});
		},
		reject: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.put(`payments4/payments/${id}/reject`, data, {
				...config,
			});
		},
	};
}
