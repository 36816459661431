import { TextFieldProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
	T4Autocomplete,
	T4AutocompleteProps,
} from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { useCallback, useEffect, useState } from 'react';

export interface T4AutocompleteAsyncProps<
	TOption,
	TMultiple extends boolean = false,
	TDisableClearable extends boolean = boolean,
	TFreeSolo extends boolean = false,
> extends Omit<
		T4AutocompleteProps<TOption, TMultiple, TDisableClearable, TFreeSolo>,
		'options' | 'open' | 'setOpen' | 'loading'
	> {
	cacheKey: (string | undefined)[];
	optionsResolver: () => Promise<TOption[]>;
	textFieldProps?: Partial<TextFieldProps>;
}

// eslint-disable-next-line mobx/missing-observer
export function T4AutocompleteAsync<
	TOption,
	TMultiple extends boolean = false,
	TDisableClearable extends boolean = boolean,
	TFreeSolo extends boolean = false,
>({
	cacheKey,
	optionsResolver,
	...props
}: T4AutocompleteAsyncProps<TOption, TMultiple, TDisableClearable, TFreeSolo>) {
	const [open, setOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [options, setOptions] = useState<TOption[]>([]);
	const [shouldLoad, setShouldLoad] = useState<boolean>(false);

	const getOptions = useCallback(async () => {
		setError(undefined);
		return await optionsResolver();
	}, [optionsResolver]);

	// query data will be fresh for 5 mins then will become stale
	const getOptionsQuery = useQuery({
		queryKey: cacheKey,
		queryFn: getOptions,
		enabled: shouldLoad,
		staleTime: 1000 * 60 * 5,
	});

	useEffect(() => {
		if (open && options.length === 0) setShouldLoad(true);
		else if (open && options.length !== 0 && getOptionsQuery.isStale) {
			setShouldLoad(true);
		} else setShouldLoad(false);
	}, [open, options, getOptionsQuery.isStale, cacheKey]);

	useEffect(() => {
		if (!getOptionsQuery.isFetching && getOptionsQuery.data) {
			setOptions(getOptionsQuery.data);
		}
	}, [getOptionsQuery.isFetching, getOptionsQuery.data]);

	useEffect(() => {
		if (getOptionsQuery.isError) {
			setError(
				`Could not retrieve values for ${
					props.label ? props.label : 'this field'
				}. Please try again later.`,
			);
			setOptions([]);
			setOpen(false);
		}
	}, [props.label, getOptionsQuery.isError]);

	return (
		<T4Autocomplete<TOption, TMultiple, TDisableClearable, TFreeSolo>
			{...props}
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			loading={getOptionsQuery.isFetching}
			options={options}
			error={error !== undefined}
			helperText={error}
		/>
	);
}
