import { useAuth0 } from '@auth0/auth0-react';

export const orgIdKey = 'org_id';

export function useOrgId() {
	const { user, isAuthenticated } = useAuth0();

	if (user && isAuthenticated && user[orgIdKey]) {
		return user[orgIdKey];
	}

	return undefined;
}
