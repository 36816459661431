import {
	DataLists,
	GetDataListsRequest,
} from 'modules/clients/customer-api/src/api/referenceData';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useClients } from './useClients';

export type ReferenceDataFetcherProps = {
	fetch: (request: GetDataListsRequest) => Promise<DataLists | undefined>;
};

export const useReferenceDataFetcher = (): ReferenceDataFetcherProps => {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const fetch = useCallback(
		async function (
			request: GetDataListsRequest,
		): Promise<DataLists | undefined> {
			try {
				const response = await customerApiClient.referenceData.getLists(
					request,
				);

				// if critical error back from response
				if (!response.data || !response.data.data) {
					enqueueSnackbar(
						'An error occurred when loading reference data. Try again later.',
						{
							variant: 'error',
						},
					);
					return;
				}

				// if it is a partial success where data exists but there are errors as well
				if (!response.data.success) {
					enqueueSnackbar(
						'Some reference data may not have loaded. You may experience issues with dropdown filtering and editing.',
						{
							variant: 'warning',
						},
					);
				}

				// return existing lists
				return response.data.data;
			} catch {
				enqueueSnackbar(
					'An error occurred when loading reference data. Try again later.',
					{
						variant: 'error',
					},
				);
			}
		},
		[customerApiClient.referenceData, enqueueSnackbar],
	);

	return {
		fetch,
	};
};
