import { observer } from 'mobx-react-lite';
import {
	AdornmentType,
	InputAdornmentWithModal,
} from '../../shared/components/molecules/inputAdornmentWithModal';

// All optional: only "true" matters.
interface IRegistrationFieldAdornmentProps {
	description?: string;
	isSaved?: boolean;
	isLoading?: boolean;
	hasError?: boolean;
}
export const RegistrationFieldAdornment: React.FC<IRegistrationFieldAdornmentProps> = observer(
	({ description, isSaved, isLoading }) => {
		let adornmentType: AdornmentType = 'info';
		if (isSaved) adornmentType = 'success';
		if (isLoading) adornmentType = 'loading';
		return (
			<InputAdornmentWithModal
				description={description}
				adornmentType={adornmentType}
			/>
		);
	}
);
