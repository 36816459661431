import { observer } from 'mobx-react-lite';
import { PowerOfAttorneyType } from 'modules/clients/customer-api/src/api/referenceData';
import { useSnackbar } from 'notistack';
import {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';

type PowerOfAttorneyContextProps = {
	powerOfAttorneys: PowerOfAttorneyType[];
	isInitializing: boolean;
	isLoading: boolean;
	refetch: () => Promise<void>;
};

const PowerOfAttorneyContext = createContext<PowerOfAttorneyContextProps>({
	powerOfAttorneys: [],
	isInitializing: true,
	isLoading: true,
	refetch: async () => {},
});

export type PowerOfAttorneyProviderProps = {
	children: ReactNode;
};

export const PowerOfAttorneyProvider: FC = observer(({ children }) => {
	const { customerApiClient } = useClients();
	const enqueueSnackbar = useSnackbar();

	const [isInitializing, setIsInitializing] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [powerOfAttorneys, setPowerOfAttorneys] = useState<
		PowerOfAttorneyType[]
	>([]);

	const refetch = useCallback(async () => {
		setIsLoading(true);

		let errors = [];
		try {
			const response =
				await customerApiClient.referenceData.getPowerOfAttorneys();

			if (response.data.error) {
				errors.push(response.data.error);
			}

			if (response.data.errors) {
				errors.push(...Object.values(response.data.errors));
			}

			if (response.data.data) {
				setPowerOfAttorneys(response.data.data);
			}
		} catch (error: any) {}

		setIsLoading(false);
		if (isInitializing === true) {
			setIsInitializing(false);
		}

		if (errors.length > 0) {
			enqueueSnackbar.enqueueSnackbar(errors.join(', '), { variant: 'error' });
		}
	}, [customerApiClient.referenceData, enqueueSnackbar, isInitializing]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return (
		<PowerOfAttorneyContext.Provider
			value={{
				powerOfAttorneys: powerOfAttorneys,
				isInitializing: isInitializing,
				isLoading: isLoading,
				refetch: refetch,
			}}
		>
			{children}
		</PowerOfAttorneyContext.Provider>
	);
});

export type UsePowerOfAttorneysProps = PowerOfAttorneyContextProps;

export function usePowerOfAttorneys(): UsePowerOfAttorneysProps {
	return useContext(PowerOfAttorneyContext);
}
