import { makeAutoObservable } from 'mobx';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';

interface IConfigurationDto {
	requireSecurityGroupApprovals: boolean;
}

interface IUpdateSecurityReviewDto extends IConfigurationDto {}

export const ApiEndpoints = {
	getConfig: '/api/v1.0/configuration',
	updateReviewRequirement: '/api/v1.0/configuration/securityGroups/approvals',
};

export const SecurityGroupConfigurationModelErrors = {
	FailedToLoad: 'Unable to load your configuration',
	FailedToUpdate: 'Unable to update your configuration',
};

class SecurityGroupConfigurationModel {
	private _requiresApprovals: boolean = true;
	private _error?: string;
	private _loading: boolean = false;

	private _updatingApprovalRequirement: boolean = false;
	public setUpdatingApprovalRequirement = (value: boolean) =>
		(this._updatingApprovalRequirement = value);
	public getUpdatingApprovalRequirement() {
		return this._updatingApprovalRequirement;
	}

	constructor() {
		makeAutoObservable(this);
	}

	load() {
		this.setLoading(true);

		return customerApi
			.get<ApiResponse<IConfigurationDto>>(ApiEndpoints.getConfig)
			.then((response) => {
				if (response.data?.success) {
					this.setRequiresApproval(
						response.data.value.requireSecurityGroupApprovals,
					);
				}

				this.setError(SecurityGroupConfigurationModelErrors.FailedToLoad);
			})
			.catch((x) => this.setError(x))
			.finally(() => this.setLoading(false));
	}

	public getError = () => this._error;
	public setError = (error?: string) => (this._error = error);

	public getLoading() {
		return this._loading;
	}
	public setLoading = (loading: boolean) => (this._loading = loading);

	public getRequiresApproval = () => this._requiresApprovals;
	public setRequiresApproval = (requiresApproval: boolean) => {
		this._requiresApprovals = requiresApproval;
	};

	public updateApprovalRequirement(requireApproval: boolean) {
		this.setLoading(true);
		this.setUpdatingApprovalRequirement(true);

		return customerApi
			.put<ApiResponse<IConfigurationDto>>(
				ApiEndpoints.updateReviewRequirement,
				{
					requireSecurityGroupApprovals: requireApproval,
				} as IUpdateSecurityReviewDto,
			)
			.then((response) => {
				if (response.data?.success) {
					this.setRequiresApproval(
						response.data.value.requireSecurityGroupApprovals,
					);
				}

				this.setError(SecurityGroupConfigurationModelErrors.FailedToUpdate);
			})
			.catch((x) => this.setError(x))
			.finally(() => {
				this.setLoading(false);
				this.setUpdatingApprovalRequirement(false);
			});
	}
}

export default SecurityGroupConfigurationModel;
