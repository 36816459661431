import { AxiosResponse } from 'axios';
import { IEntityIdentifierApiData } from 'features/entity4/entities/entitiesApiTypes';
import { getDateWarningMessage } from 'features/entity4/entities/utilities/dateUtilities';
import {
	EntityConfig,
	EntityTypeId,
	getEntityType,
	getEntityTypeKey,
} from 'features/entity4/entity4Constants';
import { makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { Moment } from 'moment';
import { customerApi } from 'shared/providers/customerApi';
import {
	areDatesEqual,
	convertDate,
	formatDate,
} from 'shared/utilities/dateUtilities';
import { ApiResponse } from 'utilities/api';
import { flattenErrors, parseError } from 'utilities/errors/errorUtils';
import {
	getCorrectArticle,
	isStringUndefinedOrNullOrWhitespace,
} from 'utilities/stringUtils';
import {
	IRelationshipDefinitionData,
	IRelationshipTypeData,
} from '../relationshipApiTypes';
import { filterPossibleEntityTypeConnections } from '../relationshipUtilities';
import {
	BaseRelationship,
	RelationshipDirection,
} from '../relationshipsObjectTypes';

interface ICreateAccountRelationshipForm extends ICreateRelationshipForm {
	fundingDirection: string | null;
	cashFlowMovement: string | null;
	fundingFrequency: string | null;
}

export interface ICreateRelationshipForm {
	relatedObjectEntityType: { id: EntityTypeId; name: string } | null;
	definition: IRelationshipDefinitionData | null;
	type: IRelationshipTypeData | null;
	relatedObject: IEntityIdentifierApiData | null;
	direction: RelationshipDirection;
	effectiveFrom: Moment | null;
	effectiveTo: Moment | null;
	notes: string | null;
}

const initialForm: ICreateAccountRelationshipForm = {
	relatedObjectEntityType: null,
	definition: null,
	type: null,
	relatedObject: null,
	direction: RelationshipDirection.primary,
	effectiveFrom: null,
	effectiveTo: null,
	notes: null,
	fundingDirection: null,
	cashFlowMovement: null,
	fundingFrequency: null,
};

export class CreateAccountRelationshipDrawerViewModel {
	public readonly entityType: EntityTypeId;
	public readonly entityId: string;
	public _entityName: string | undefined;

	private _drawerOpen: boolean = false;
	private _cancelConfirmationOpen: boolean = false;
	private _duplicateConfirmationOpen: boolean = false;
	private _relatedEntitiesLoading: boolean = false;
	private _relatedEntitiesError: string | undefined = undefined;

	private _createLoading: boolean = false;
	private _createError: string | undefined = undefined;
	private _createErrors: string[] = [];

	private _allRelationships: BaseRelationship[] = [];
	private _possibleEntityTypes: { id: EntityTypeId; name: string }[] = [];
	private _definitions: IRelationshipDefinitionData[] = [];
	private _relatedObjects: IEntityIdentifierApiData[] = [];
	private _fundingDirections: string[] = [];
	private _cashFlowMovements: string[] = [];
	private _fundingFrequencies: string[] = [];

	private _form: ICreateAccountRelationshipForm = initialForm;

	private _refetch: () => CancellablePromise<void>;

	constructor(entityId: string, refetch: () => CancellablePromise<void>) {
		makeAutoObservable(this);

		this.entityType = EntityTypeId.Account;
		this.entityId = entityId;
		this._refetch = () => refetch();
	}

	public isRelatedEntitiesLoading() {
		return this._relatedEntitiesLoading;
	}
	public getRelatedEntitiesError() {
		return this._relatedEntitiesError;
	}

	public isCreateLoading() {
		return this._createLoading;
	}
	public getCreateError() {
		return this._createError;
	}
	public getCreateErrors() {
		return this._createErrors;
	}

	public initialize(
		entityName: string | undefined,
		relationships: BaseRelationship[],
		definitions: IRelationshipDefinitionData[],
		fundingDirections: string[],
		cashflowMovements: string[],
		fundingFrequencies: string[],
	) {
		this._entityName = entityName;
		this._allRelationships = relationships;
		this._definitions = definitions;
		this._fundingDirections = fundingDirections;
		this._cashFlowMovements = cashflowMovements;
		this._fundingFrequencies = fundingFrequencies;
		this._possibleEntityTypes = filterPossibleEntityTypeConnections(
			this.entityType,
			definitions,
		);
	}

	public setEntityName(value: string | undefined) {
		this._entityName = value;
	}

	public isOpen() {
		return this._drawerOpen;
	}
	public async openDrawer() {
		this._drawerOpen = true;
	}
	public checkCloseDrawer() {
		if (JSON.stringify(this._form) !== JSON.stringify(initialForm))
			this._cancelConfirmationOpen = true;
		else this.closeDrawer();
	}
	public closeDrawer() {
		this._cancelConfirmationOpen = false;
		this._duplicateConfirmationOpen = false;
		this._drawerOpen = false;
		this.resetForm();
	}
	public resetForm() {
		this._form = initialForm;
		this._createError = undefined;
		this._relatedEntitiesError = undefined;
		this._createErrors = [];
	}

	public isCancelConfirmationOpen() {
		return this._cancelConfirmationOpen;
	}
	public closeCancelConfirmation() {
		this._cancelConfirmationOpen = false;
	}

	public isDuplicateConfirmationOpen() {
		return this._duplicateConfirmationOpen;
	}
	public openDuplicateConfirmation() {
		this._duplicateConfirmationOpen = true;
	}
	public closeDuplicateConfirmation() {
		this._duplicateConfirmationOpen = false;
	}

	public isSubmitDisabled() {
		return (
			this._relatedEntitiesLoading ||
			this._createLoading ||
			!this._form.relatedObject ||
			!this._form.definition ||
			!this._form.type ||
			!this.isAccountToAccountValid() ||
			!this.areDatesValid()
		);
	}

	// Possible entity types
	public getPossibleEntityTypes() {
		return this._possibleEntityTypes;
	}

	public getRelatedObjectEntityType() {
		return this._form.relatedObjectEntityType;
	}
	public async setRelatedObjectEntityType(
		value: {
			id: EntityTypeId;
			name: string;
		} | null,
	) {
		if (
			value !== null &&
			value?.id !== this._form.relatedObjectEntityType?.id
		) {
			this.setSelectedDefinition(null);
			this._form.relatedObject = null;
			this._form.relatedObjectEntityType = value;
			const definitions = this.getDefinitions();
			if (definitions.length === 1) this.setSelectedDefinition(definitions[0]);
			await this.loadRelatedEntities();
		} else if (value === null) {
			this.resetForm();
		}
	}

	// relationship definitions
	public getDefinitions() {
		if (this._form.relatedObjectEntityType === null) return [];

		const matchType = (type: IRelationshipTypeData) =>
			(type.primaryType === this._form.relatedObjectEntityType!.id &&
				type.secondaryType === this.entityType) ||
			(type.secondaryType === this._form.relatedObjectEntityType!.id &&
				type.primaryType === this.entityType);

		return this._definitions
			.filter((definition) => definition.types.find(matchType))
			.map((relationshipDefinition) => ({
				...relationshipDefinition,
				types: relationshipDefinition.types.filter(matchType),
			}))
			.sort((a, b) => {
				if (a.displayName < b.displayName) return -1;
				if (a.displayName > b.displayName) return 1;
				return 0;
			});
	}
	public getSelectedDefinition() {
		return this._form.definition;
	}
	public setSelectedDefinition(value: IRelationshipDefinitionData | null) {
		const initialType = value?.types[0] ?? null;
		this._form.definition = value;
		this._form.type = initialType;

		this._form.direction =
			initialType?.primaryType === this.entityType
				? RelationshipDirection.primary
				: RelationshipDirection.secondary;
	}

	// related object selection
	public getRelatedObjects() {
		return this._relatedObjects;
	}
	public getRelatedObject() {
		return this._form.relatedObject;
	}
	public setRelatedObject(value: IEntityIdentifierApiData | null) {
		this._form.relatedObject = value;
	}
	public getRelatedObjectsLabel() {
		return (
			getEntityTypeKey(
				getEntityType(this._form.relatedObjectEntityType?.id)!,
			) ?? ''
		);
	}

	// relationship directionality
	public getEntityName() {
		return this._entityName;
	}

	public getRelatedEntityName() {
		return this._form.relatedObject !== null
			? this._form.relatedObject.displayName
			: `(select ${getCorrectArticle(
					this.getRelatedObjectsLabel(),
			  )} ${this.getRelatedObjectsLabel()})`;
	}

	public getDirectionDescription() {
		if (!this._entityName) return undefined;

		const directionDescription =
			this._form.direction === RelationshipDirection.primary
				? this._form.definition?.primaryDescriptor.toLowerCase()
				: this._form.definition?.secondaryDescriptor.toLowerCase();

		if (!directionDescription) return '...';
		return `${directionDescription}`;
	}

	public canToggleRelationshipDirection() {
		return (
			this._form.type !== null &&
			this._form.type?.primaryType === this._form.type?.secondaryType
		);
	}
	public toggleRelationshipDirection() {
		this._form.direction =
			this._form.direction === RelationshipDirection.primary
				? RelationshipDirection.secondary
				: RelationshipDirection.primary;
	}

	// effective dates
	public getEffectiveFrom() {
		return this._form.effectiveFrom;
	}
	public setEffectiveFrom(value: Moment | null) {
		this._form.effectiveFrom = value;
	}
	public getEffectiveFromError() {
		return getDateWarningMessage(
			'Effective From',
			this._form.effectiveFrom,
			this._form.effectiveTo ?? undefined,
		);
	}

	public getEffectiveTo() {
		return this._form.effectiveTo;
	}
	public setEffectiveTo(value: Moment | null) {
		this._form.effectiveTo = value;
	}
	public getEffectiveToError() {
		return getDateWarningMessage('Effective To', this._form.effectiveTo);
	}

	public areDatesValid() {
		const isEffectiveFromValid =
			this._form.effectiveTo !== null
				? !Boolean(
						getDateWarningMessage(
							'EffectiveFrom',
							this._form.effectiveFrom,
							this._form.effectiveTo,
						),
				  )
				: !Boolean(
						getDateWarningMessage('EffectiveFrom', this._form.effectiveFrom),
				  );
		return (
			isEffectiveFromValid &&
			!Boolean(getDateWarningMessage('EffectiveTo', this._form.effectiveTo))
		);
	}

	public getNotes() {
		return this._form.notes;
	}
	public setNotes(value: string | null) {
		if (isStringUndefinedOrNullOrWhitespace(value)) this._form.notes = null
		else if (value && value.length <=2048) this._form.notes = value
	}

	public setFundingDirection(value: string | null) {
		this._form.fundingDirection = value;
	}
	public getFundingDirection() {
		return this._form.fundingDirection;
	}
	public setCashFlowMovement(value: string | null) {
		this._form.cashFlowMovement = value;
	}
	public getCashFlowMovement() {
		return this._form.cashFlowMovement;
	}
	public setFundingFrequency(value: string | null) {
		this._form.fundingFrequency = value;
	}
	public getFundingFrequency() {
		return this._form.fundingFrequency;
	}

	public getFundingDirectionList() {
		return this._fundingDirections;
	}
	public getCashFlowMovementList() {
		return this._cashFlowMovements;
	}
	public getFundingFrequencyList() {
		return this._fundingFrequencies ?? [];
	}

	// creates relationship using data set in this._form
	public async checkCreateRelationship() {
		if (
			this._allRelationships.find(
				(relationship) =>
					relationship.relationshipTypeId === this._form.type!.id &&
					relationship.entityId === this._form.relatedObject!.id &&
					((relationship.isPrimaryDirection === true &&
						this._form.direction === RelationshipDirection.primary) ||
						(relationship.isPrimaryDirection === false &&
							this._form.direction === RelationshipDirection.secondary)) &&
					areDatesEqual(
						convertDate(relationship.effectiveFrom),
						this._form.effectiveFrom,
					) &&
					areDatesEqual(
						convertDate(relationship.effectiveTo),
						this._form.effectiveTo,
					),
			)
		) {
			this.openDuplicateConfirmation();
		} else {
			return await this.createRelationship();
		}
	}

	public *createRelationship() {
		if (this.isSubmitDisabled()) return;

		const route = EntityConfig.get(
			getEntityType(this.entityType)!,
		)!.controllerRoute;

		const definition = this._form.definition;

		try {
			this._createLoading = true;
			this._createError = undefined;

			const response: AxiosResponse<ApiResponse<string>> =
				yield customerApi.post<ApiResponse<string>>(
					`${route}/${this.entityId}/relationships`,
					{
						relationshipTypeId: this._form.type!.id,
						primaryEntityId:
							this._form.direction === RelationshipDirection.primary
								? this.entityId!
								: this._form.relatedObject!.id,
						secondaryEntityId:
							this._form.direction === RelationshipDirection.primary
								? this._form.relatedObject!.id
								: this.entityId!,
						effectiveFrom: formatDate(this._form.effectiveFrom) || undefined,
						effectiveTo: formatDate(this._form.effectiveTo) || undefined,
						notes: this._form.notes,
						fundingDirection: this._form.fundingDirection,
						cashFlowMovement: this._form.cashFlowMovement,
						fundingFrequency: this._form.fundingFrequency,
					},
				);

			if (response.data.error) this._createError = response.data.error;
			else if (response.data.errors)
				this._createErrors = flattenErrors(response.data.errors);
			else {
				this.closeDrawer();
				yield this._refetch();
			}
		} catch (err: any) {
			this._createError = parseError(err);
		} finally {
			this._createLoading = false;
		}

		return definition;
	}

	// loads related entity identifiers
	public *loadRelatedEntities() {
		if (!this._form.relatedObjectEntityType) return;

		const route = EntityConfig.get(
			getEntityType(this._form.relatedObjectEntityType!.id)!,
		)?.controllerRoute;
		try {
			this._relatedEntitiesLoading = true;
			this._relatedEntitiesError = undefined;

			const response: AxiosResponse<ApiResponse<IEntityIdentifierApiData[]>> =
				yield customerApi.get<ApiResponse<IEntityIdentifierApiData[]>>(
					`${route}/identifiers`,
				);
			if (response.data.error) this._relatedEntitiesError = response.data.error;
			else
				this._relatedObjects = response.data.value.filter(
					(entity: IEntityIdentifierApiData) => entity.id !== this.entityId,
				);
		} catch (err: any) {
			this._relatedEntitiesError = parseError(err);
		} finally {
			this._relatedEntitiesLoading = false;
		}
	}

	// account to account specific functions
	public isAccountToAccountRelationship() {
		if (this._form.relatedObjectEntityType === null) return false;
		return this._form.relatedObjectEntityType.id === EntityTypeId.Account;
	}

	private isAccountToAccountValid() {
		if (this._form.type === null) return true;

		if (this.isAccountToAccountRelationship()) {
			return this._form.fundingDirection !== null;
		} else return true;
	}
}
