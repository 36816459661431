import { Grid } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { LegalEntityIdentifier } from 'modules/clients/customer-api/src/entity4/entities';
import { LegalEntityGroupCollection } from 'modules/clients/customer-api/src/entity4/legalEntityGroups';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { T4MultiSelectAutocomplete } from 'shared/components/autocompletes/t4MultiselectAutocomplete';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { useLegalEntityGroupDrawer } from '../hooks/useLegalEntityGroupDrawer';

type LegalEntityGroupDrawerProps = {
	isOpen: boolean;
	closeDrawer: () => void;
	id?: string;
};

const stonlyIds = {
	cancelButton: 'legalentitygroup-drawer-cancel-button',
	submitButton: 'legalentitygroup-drawer-submit-button',
};

export const LegalEntityGroupDrawer: FC<LegalEntityGroupDrawerProps> = ({
	isOpen,
	closeDrawer,
	id,
}) => {
	const {
		isLoading,
		isInitializing,
		isValidForm,
		legalEntityIdentifiers,
		legalEntityGroupCollections,
		name,
		nameError,
		description,
		descriptionError,
		collection,
		legalEntities,
		initialize,
		setName,
		setDescription,
		setCollection,
		setLegalEntities,
		resetForm,
		submit,
	} = useLegalEntityGroupDrawer(id);

	useEffect(() => {
		if (isOpen) {
			initialize();
		}
	}, [id, isOpen, initialize]);

	const close = useCallback(() => {
		closeDrawer();
		resetForm();
	}, [closeDrawer, resetForm]);

	const drawerText = useMemo(
		() =>
			!id
				? {
						title: 'Create entity group',
						submitButtonText: 'Create',
				  }
				: {
						title: 'Edit entity group',
						submitButtonText: 'Save',
				  },
		[id],
	);

	return (
		<T4DrawerBase
			open={isOpen}
			onClose={close}
			title={drawerText.title}
			initializing={isInitializing}
			loading={isLoading}
			disableNavigationCollapse={true}
			actions={[
				<DrawerCancelButton
					stonlyId={stonlyIds.cancelButton}
					onCancel={close}
				/>,
				<DrawerSubmitButton
					stonlyId={stonlyIds.submitButton}
					label={drawerText.submitButtonText}
					onSubmit={async () => {
						if (await submit()) {
							close();
						}
					}}
					disabled={!isValidForm || isLoading}
				/>,
			]}
		>
			<Grid container rowSpacing={2}>
				<Grid item xs={12}>
					<T4TextFieldV2
						label="Name"
						value={name ?? ''}
						onChange={setName}
						InputProps={{
							readOnly: isLoading,
						}}
						required
						error={nameError !== undefined}
						helperText={nameError}
					/>
				</Grid>

				<Grid item xs={12}>
					<T4TextFieldV2
						label="Description"
						value={description ?? ''}
						onChange={setDescription}
						InputProps={{
							readOnly: isLoading,
						}}
						multiline
						maxRows={3}
						minRows={3}
						error={descriptionError !== undefined}
						helperText={descriptionError}
					/>
				</Grid>

				<Grid item xs={12}>
					<T4Autocomplete<LegalEntityGroupCollection, false, true, false>
						label="Assign to Collection"
						options={legalEntityGroupCollections}
						value={collection ?? undefined}
						onSelect={setCollection}
						getOptionLabel={(collection) => collection.name}
						isOptionEqualToValue={(collection, value) =>
							collection.id === value.id
						}
						required
						disableClearable={true}
						readOnly={isLoading}
					/>
				</Grid>

				<Grid item xs={12}>
					<T4MultiSelectAutocomplete<LegalEntityIdentifier>
						label="Apply to Entities"
						options={legalEntityIdentifiers}
						value={legalEntities}
						onSelect={setLegalEntities}
						getOptionKey={(identifier) => identifier.id}
						getOptionLabel={(identifier) => identifier.displayName}
						isOptionEqualToValue={(identifier, value) =>
							identifier.id === value.id
						}
					/>
				</Grid>
			</Grid>
		</T4DrawerBase>
	);
};
