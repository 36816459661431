import { Button, ButtonProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type SigmaButtonProps = ButtonProps;

export const SigmaButton: FC<SigmaButtonProps> = observer(({ sx, ...rest }) => (
	<Button
		{...rest}
		sx={{
			...sx,
			background: 'white',
			verticalAlign: 'center',
			'&:hover': {
				...((sx as any)?.['&:hover'] ?? {}),
				background: 'white',
			},
		}}
	/>
));
