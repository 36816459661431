import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from '../../../../utilities/api';
import { EntityConfig, EntityType } from '../../entity4Constants';
import {
	IAssociatedRegistrationsDto,
	ICreateRegisteredAgentJurisdictionInput,
	IRegisteredAgentJurisdictionDto,
	IRegisteredAgentJurisdictionsListDto,
} from './jurisdictionApiTypes';

export const JurisdictionRepository = {
	// frontend controller requests
	getRegisteredAgentJurisdiction(
		entityId: string,
		entityType: EntityType,
		jurisdictionId: string
	): Promise<IRegisteredAgentJurisdictionDto> {
		const controllerRoute = EntityConfig.get(entityType)!.controllerRoute.slice(
			1
		);
		return customerApi
			.get<ApiResponse<IRegisteredAgentJurisdictionDto>>(
				`/frontend/${controllerRoute}/${entityId}/registeredagentjurisdictions/${jurisdictionId}`
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},

	getRegisteredAgentJurisdictionsList(
		entityId: string,
		entityType: EntityType
	): Promise<IRegisteredAgentJurisdictionsListDto[]> {
		const controllerRoute = EntityConfig.get(entityType)!.controllerRoute.slice(
			1
		);
		return customerApi
			.get<ApiResponse<IRegisteredAgentJurisdictionsListDto[]>>(
				`/frontend/${controllerRoute}/${entityId}/registeredagentjurisdictions`
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},

	getMatchingRegisteredAgentJurisdiction(
		entityId: string,
		entityType: EntityType,
		registrationId: string
	): Promise<IRegisteredAgentJurisdictionDto> {
		const controllerRoute = EntityConfig.get(entityType)!.controllerRoute.slice(
			1
		);
		return customerApi
			.get<ApiResponse<IRegisteredAgentJurisdictionDto>>(
				`/frontend/${controllerRoute}/${entityId}/registeredagentjurisdictions/matching`,
				{
					params: {
						registrationId,
					},
				}
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},

	getAssociatedRegistrations(
		entityId: string,
		entityType: EntityType,
		jurisdictionId: string
	): Promise<IAssociatedRegistrationsDto[]> {
		const controllerRoute = EntityConfig.get(entityType)!.controllerRoute.slice(
			1
		);
		return customerApi
			.get<ApiResponse<IAssociatedRegistrationsDto[]>>(
				`/frontend/${controllerRoute}/${entityId}/registeredagentjurisdictions/${jurisdictionId}/associatedregistrations`
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},

	// controller requests
	addRegisteredAgentJurisdiction(
		entityId: string,
		entityType: EntityType,
		input: ICreateRegisteredAgentJurisdictionInput
	): Promise<string> {
		const controllerRoute = EntityConfig.get(entityType)!.controllerRoute;
		return customerApi
			.post<ApiResponse<string>>(
				`${controllerRoute}/${entityId}/registeredagentjurisdictions`,
				input
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},

	updateRegisteredAgentJurisdiction(
		entityId: string,
		entityType: EntityType,
		input: IRegisteredAgentJurisdictionDto
	): Promise<string> {
		const controllerRoute = EntityConfig.get(entityType)!.controllerRoute;
		return customerApi
			.patch<ApiResponse<string>>(
				`${controllerRoute}/${entityId}/registeredagentjurisdictions/${input.id}`,
				input
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},

	deleteRegisteredAgentJurisdiction(
		entityId: string,
		entityType: EntityType,
		jurisdictionId: string
	): Promise<string> {
		const controllerRoute = EntityConfig.get(entityType)!.controllerRoute;
		return customerApi
			.delete<ApiResponse<string>>(
				`${controllerRoute}/${entityId}/registeredagentjurisdictions/${jurisdictionId}`
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},

	deleteRegisteredAgentDetailsAspect(
		entityId: string,
		entityType: EntityType
	): Promise<string> {
		const controllerRoute = EntityConfig.get(entityType)!.controllerRoute;
		return customerApi
			.delete<ApiResponse<string>>(
				`${controllerRoute}/${entityId}/registeredagentjurisdictions`
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},
};
