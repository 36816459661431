import { Box, CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ObjectPathParams } from 'shared/constants/paths';
import { EntityType } from '../entity4Constants';
import { useProfileView } from '../entityProfile/providers/entityProfileContextProvider';
import { FieldViews } from '../shared/fieldSets/fieldViews/fieldViewTypes';
import { AccountRelationships } from './accountRelationships/accountRelationships';
import { CounterpartyRelationships } from './counterpartyRelationships/counterpartyRelationships';
import { EntityRelationships } from './entityRelationships/entityRelationships';
import { PartnerRelationships } from './partnerRelationships/partnerRelationships';
import { StaffRelationships } from './staffRelationships/staffRelationships';

export const ObjectRelationshipsPage: FC = observer(() => {
	const { objectType } = useParams<ObjectPathParams>();
	const { setViewType } = useProfileView();

	useEffect(() => {
		setViewType(FieldViews.default);
	}, [setViewType]);

	switch (objectType) {
		case EntityType.Entity:
			return <EntityRelationships />;
		case EntityType.Partner:
			return <PartnerRelationships />;
		case EntityType.Account:
			return <AccountRelationships />;
		case EntityType.Staff:
			return <StaffRelationships />;
		case EntityType.Counterparty:
			return <CounterpartyRelationships />;
		default:
			return (
				<Box
					width="100%"
					height="100%"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Box>
			);
	}
});
