import { CSSProperties } from 'react';

/**
 * Adds opacity value to hex base color
 * @param color Base color
 * @param opacity Opacity as a percentage EX: 100, 50, 25
 * @returns #RRGGBBAA Color
 */
export const GetHexColorWithOpacity = (
	color: CSSProperties['color'] | undefined,
	opacity: number,
): CSSProperties['color'] => {
	if (color === undefined || opacity < 1 || opacity > 100) return color;

	let hexOpacity = Math.round((opacity / 100) * 255)
		.toString(16)
		.toUpperCase();
	if (hexOpacity.length === 1) hexOpacity = '0' + hexOpacity;

	return `${color}${hexOpacity}`;
};
