import { FC } from 'react';
import { T4Autocomplete } from '../../shared/components/atoms/t4Autocomplete';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';

import { observer } from 'mobx-react-lite';
import { Field, Option } from 'modules/clients/customer-api/src/api/common';

export type OptionFieldFormViewProps = {
	field: Field;
	value: string | undefined;
	setValue: (field: Field, value: Option | undefined) => void;
};

export const OptionFieldFormView: FC<OptionFieldFormViewProps> = observer(
	({ field, value, setValue }) => (
		<T4Autocomplete<Option, false, boolean, false>
			id={field.id}
			label={field.name}
			getOptionLabel={(option) => option.displayName}
			options={field.optionList?.options ?? []}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			value={field.optionList?.options?.find((x) => x.id === value) ?? null}
			onSelect={(option) => setValue(field, option ?? undefined)}
			disableClearable={field.isCreationRequirement}
			required={field.isCreationRequirement}
			startAdornment={
				<T4FieldAdornment
					id={field.id}
					t4AdornmentType={'info'}
					title={field.name}
					description={field.description}
				/>
			}
		/>
	),
);
