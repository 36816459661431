import { GridInputRowSelectionModel, GridRowId } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { AccountIntegration } from 'modules/clients/customer-api/src/cash4/accountIntegrations';
import { ConnectionIndex } from 'modules/clients/customer-api/src/cash4/connections';
import { AccountSummary } from 'modules/clients/customer-api/src/entity4/accounts';
import { useState } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseAssignAccountProps = {
	isLoading: boolean;
	error: string;

	initialize: (
		connection: ConnectionIndex | null,
		accountIntegration: AccountIntegration | null,
	) => void;

	isAccountsLoading: boolean;
	accountsError: string;

	availableAccounts: AccountSummary[];
	accountIdentifier: string | null;

	isDirty: boolean;
	isSubmitDisabled: boolean;
	assignedAccount: AccountSummary | null;
	setAssignedAccount: (gridSelectionModel: GridInputRowSelectionModel) => void;
	submit: () => Promise<void>;
	closeModal: () => void;
};

export const useAssignAccount = (
	onClose: () => void,
	fetchConnections: () => Promise<void>,
): UseAssignAccountProps => {
	const { customerApiClient } = useClients();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const [availableAccounts, setAvailableAccounts] = useState<AccountSummary[]>(
		[],
	);
	const [connection, setConnection] = useState<ConnectionIndex | null>(null);
	const [accountIntegration, setAccountIntegration] =
		useState<AccountIntegration | null>(null);

	const [assignedAccount, setAssignedAccount] = useState<AccountSummary | null>(
		null,
	);

	const initialize = (
		connection: ConnectionIndex | null,
		accountIntegration: AccountIntegration | null,
	) => {
		setConnection(connection);
		setAccountIntegration(accountIntegration);
	};

	const getAccountSummaries = async () => {
		const response =
			await customerApiClient.entity4.accounts.getAllAccountSummaries();
		if (response.data.success) {
			const existingAccounts = connection!.accountIntegrations.map(
				(x) => x.entity4Account?.id,
			);
			const availableAccounts = response.data.value.filter(
				(account) => !existingAccounts.includes(account.id),
			);
			setAvailableAccounts(availableAccounts);
			return availableAccounts;
		} else {
			throw Error('Unable to load accounts.');
		}
	};

	const getAccountSummariesQuery = useQuery({
		queryKey: ['accountSummaries'],
		queryFn: getAccountSummaries,
		enabled: !!customerApiClient && !!connection && !!accountIntegration,
		refetchOnWindowFocus: false,
	});

	const isSubmitDisabled = () => {
		return (
			isLoading ||
			getAccountSummariesQuery.isFetching ||
			!connection ||
			!accountIntegration ||
			!assignedAccount
		);
	};

	const selectAccount = (gridSelectionModel: GridInputRowSelectionModel) => {
		const selections = gridSelectionModel as GridRowId[];
		setAssignedAccount(
			availableAccounts.find(
				(x) => x.id === selections[selections.length - 1],
			) ?? null,
		);
	};

	const closeModal = () => {
		onClose();
		setConnection(null);
		setAccountIntegration(null);
		setAssignedAccount(null);
		setAvailableAccounts([]);
		setError('');
	};

	const submit = async () => {
		if (!connection || !accountIntegration || !assignedAccount) return;

		try {
			setIsLoading(true);
			setError('');

			const response =
				await customerApiClient.cash4.accountIntegrations.assignAccount({
					connectionId: connection.id,
					accountIntegrationId: accountIntegration.id,
					entity4AccountId: assignedAccount.id,
				});
			if (response.data.success) {
				closeModal();
				await fetchConnections();
			} else {
				setError('Unable to assign account.');
			}
		} catch (error) {
			setError('Unable to assign account.');
		} finally {
			setIsLoading(false);
		}
	};

	return {
		isLoading,
		error,
		initialize,

		isAccountsLoading: getAccountSummariesQuery.isFetching,
		accountsError: getAccountSummariesQuery.error as string,
		availableAccounts: availableAccounts,
		accountIdentifier: accountIntegration?.accountIdentifier ?? null,

		isDirty: !!assignedAccount,
		isSubmitDisabled: isSubmitDisabled(),
		assignedAccount,
		setAssignedAccount: selectAccount,
		submit,
		closeModal,
	};
};
