import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { AccountIntegration } from 'modules/clients/customer-api/src/cash4/accountIntegrations';
import { ConnectionIndex } from 'modules/clients/customer-api/src/cash4/connections';
import { FC, useEffect, useState } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useUpdateAccountIntegration } from '../hooks/useUpdateAccountIntegration';

export interface UpdateAccountIntegrationModalProps {
	open: boolean;
	close: () => void;
	connection: ConnectionIndex;
	accountIntegration: AccountIntegration | null;
	refetch: () => Promise<void>;
}

export const UpdateAccountIntegrationModal: FC<
	UpdateAccountIntegrationModalProps
> = ({ open, close, connection, accountIntegration, refetch }) => {
	const updateAccountIntegration = useUpdateAccountIntegration();
	const [isCancelModalOpen, setCancelModalOpen] = useState<boolean>(false);

	useEffect(() => {
		updateAccountIntegration.initialize(connection, accountIntegration);
	}, [connection, accountIntegration]);

	const closeModal = () => {
		if (updateAccountIntegration.isDirty) {
			setCancelModalOpen(true);
		} else {
			close();
			updateAccountIntegration.clear();
		}
	};

	const submit = async () => {
		if (await updateAccountIntegration.updateAccountIntegration()) {
			close();
			updateAccountIntegration.clear();
			await refetch();
		}
	};

	const cancel = () => {
		setCancelModalOpen(false);
		close();
		updateAccountIntegration.clear();
	};

	return (
		<>
			<FormModal
				open={open}
				onClose={() => closeModal()}
				onSubmit={async () => await submit()}
				submitButtonLabel="Save Changes"
				submitDisabled={updateAccountIntegration.isSubmitDisabled()}
				title="Update Account Integration"
				error={updateAccountIntegration.error}
				loading={updateAccountIntegration.loading}
				fullWidth
			>
				<T4TextFieldV2
					label="Account Identifier"
					value={updateAccountIntegration.getAccountIdentifier()}
					onChange={(value) => {
						updateAccountIntegration.setAccountIdentifier(value);
					}}
					placeholder="Account Identifier"
					required
				/>
			</FormModal>
			<FormModal
				open={isCancelModalOpen}
				onClose={() => setCancelModalOpen(false)}
				onSubmit={() => cancel()}
				cancelButtonLabel="Keep Editing"
				submitButtonLabel="Discard Edits"
				title="Discard Account Integration Edits?"
				description="Are you sure you want to discard your changes to this Account Integration? Your changes will not be saved."
				error={''}
				loading={false}
			/>
		</>
	);
};
