import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DomainIcon from '@mui/icons-material/Domain';
import GroupIcon from '@mui/icons-material/Group';
import KeyIcon from '@mui/icons-material/Key';
import { roles } from 'modules/auth0';
import { ReactNode } from 'react';
import { FieldDataContract } from './shared/fieldSets/fieldTypes';

//#region Enums

export enum CustomerApiRoute {
	Entity = '/Entity',
	Partner = '/Partner',
	Counterparty = '/Counterparty',
	Account = '/Account',
	Staff = '/Staff',
	Subaccount = '/subaccount',
}

export enum EntityTypeId {
	InternalEntity = 1,
	Partner = 2,
	Counterparty = 3,
	Staff = 4,
	Account = 5,
	SubAccounts = 6,
}

export enum EntityType {
	Entity = 'entities',
	Partner = 'partners',
	Counterparty = 'counterparties',
	Account = 'accounts',
	Staff = 'staff',
	Subaccount = 'subaccounts',
}

export enum EntityTypeName {
	Entity = 'Entity',
	Partner = 'Partner',
	Counterparty = 'Counterparty',
	Account = 'Account',
	Staff = 'Staff',
	Subaccount = 'Subaccount',
}

export enum SubObjectType {
	Registration = 'Registration',
	RegisteredAgentJurisdiction = 'RegisteredAgentJurisdiction',
	TaxId = 'TaxId',
	Subaccount = 'subaccounts',
}

export enum PageTypes {
	Index = 'Index',
	Edit = 'Edit',
	Approve = 'Approve',
}

export enum PageName {
	entities = 'Entities',
	partners = 'Partners',
	counterparties = 'Counterparties',
	accounts = 'Accounts',
	staff = 'Staff',
	subaccounts = 'Subaccounts',
	documents = 'Documents',
	relationships = 'Relationships',
	registrations = 'Registrations',
	taxids = 'Tax',
	registeredagentdetails = 'Registered Agent Details',
}

//#endregion

//#region Types

export type AspectId =
	| 'information'
	| 'relationships'
	| 'registrations'
	| 'registered-agent-details'
	| 'tax-ids'
	| 'documents'
	| 'subaccounts';

export type IEntityConfigDefinition = {
	createButtonConfig: ICreateObjectButtonConfig;
	sideMenuConfig: ISidebarMenu;
	approvePageTitle: string;
	editPageTitle: string;
	profilePageTitle: string;
	uniqueIDContract: FieldDataContract;
	controllerRoute: string;
};

type ICreateObjectButtonConfig = {
	entityName: string;
	modalTitle: string;
	description: string;
	tips?: JSX.Element;
};

type ISidebarMenu = {
	label: string;
	listPageTitle: string;
	listPageIcon: ReactNode;
	approvalListPageTitle: string;
	entityType: EntityType;
	roles: string[];
};

//#endregion

//#region Constants

export const entityFieldIdentifiers = {
	code: 't4_field_entity_code',
};

export const counterpartyFieldIdentifiers = {
	code: 't4_field_counterparty_code',
};

export const partnerFieldIdentifiers = {
	code: 't4_field_partner_code',
};

export const accountFieldIdentifiers = {
	code: 't4_field_account_code',
};

export const subaccountFieldIdentifiers = {
	code: 't4_field_account_code',
};

export const staffFieldIdentifiers = {
	code: 't4_field_staff_code',
};

export const singularToPluralEntity = {
	Entity: 'entities',
	Partner: 'partners',
	Counterparty: 'counterparties',
	Account: 'accounts',
	Staff: 'staff',
	Subaccount: 'subaccounts',
};

export const aspectUrlIds = {
	information: 'information',
	relationships: 'relationships',
	registrations: 'registrations',
	registeredAgentDetails: 'registered-agent-details',
	taxIds: 'tax-ids',
	documents: 'documents',
	subAccounts: 'subaccounts',
};

export const columnDefinitionNames = {
	hasPendingChanges: 'Has Pending Changes',
	hasRejections: 'Has Rejections',
};

//#endregion

//#region Maps

const entityTypeNameToEntityType = new Map<EntityTypeName, EntityType>([
	[EntityTypeName.Entity, EntityType.Entity],
	[EntityTypeName.Partner, EntityType.Partner],
	[EntityTypeName.Counterparty, EntityType.Counterparty],
	[EntityTypeName.Staff, EntityType.Staff],
	[EntityTypeName.Account, EntityType.Account],
	[EntityTypeName.Subaccount, EntityType.Subaccount],
]);

export const objectTypeToObjectTypeName = new Map<EntityType, EntityTypeName>([
	[EntityType.Entity, EntityTypeName.Entity],
	[EntityType.Partner, EntityTypeName.Partner],
	[EntityType.Counterparty, EntityTypeName.Counterparty],
	[EntityType.Staff, EntityTypeName.Staff],
	[EntityType.Account, EntityTypeName.Account],
	[EntityType.Subaccount, EntityTypeName.Subaccount],
]);

export const entityTypeIdToEntityType = new Map<EntityTypeId, EntityType>([
	[EntityTypeId.InternalEntity, EntityType.Entity],
	[EntityTypeId.Partner, EntityType.Partner],
	[EntityTypeId.Counterparty, EntityType.Counterparty],
	[EntityTypeId.Staff, EntityType.Staff],
	[EntityTypeId.Account, EntityType.Account],
	[EntityTypeId.SubAccounts, EntityType.Subaccount],
]);

export const entityTypeToEntityTypeId = new Map<EntityType, EntityTypeId>([
	[EntityType.Entity, EntityTypeId.InternalEntity],
	[EntityType.Partner, EntityTypeId.Partner],
	[EntityType.Counterparty, EntityTypeId.Counterparty],
	[EntityType.Staff, EntityTypeId.Staff],
	[EntityType.Account, EntityTypeId.Account],
	[EntityType.Subaccount, EntityTypeId.SubAccounts],
]);

export const requiredFieldsByEntityTypeId = new Map<EntityTypeId, string[]>([
	[EntityTypeId.InternalEntity, [entityFieldIdentifiers.code]],
	[EntityTypeId.Counterparty, [counterpartyFieldIdentifiers.code]],
	[EntityTypeId.Partner, [partnerFieldIdentifiers.code]],
	[EntityTypeId.Staff, [staffFieldIdentifiers.code]],
	[EntityTypeId.Account, [accountFieldIdentifiers.code]],
	[EntityTypeId.SubAccounts, [subaccountFieldIdentifiers.code]],
]);

export const uniqueCodeFieldsByEntityTypeId = new Map<
	EntityTypeId,
	FieldDataContract
>([
	[
		EntityTypeId.InternalEntity,
		new FieldDataContract(
			't4_fieldset_entity_code',
			entityFieldIdentifiers.code,
		),
	],
	[
		EntityTypeId.Counterparty,
		new FieldDataContract(
			't4_fieldset_counterparty_code',
			counterpartyFieldIdentifiers.code,
		),
	],
	[
		EntityTypeId.Account,
		new FieldDataContract(
			't4_fieldset_account_code',
			accountFieldIdentifiers.code,
		),
	],
	[
		EntityTypeId.Partner,
		new FieldDataContract(
			't4_fieldset_partner_code',
			partnerFieldIdentifiers.code,
		),
	],
	[
		EntityTypeId.Staff,
		new FieldDataContract('t4_fieldset_staff_code', staffFieldIdentifiers.code),
	],
]);

export const statusFieldByEntityTypeId = new Map<
	EntityTypeId,
	FieldDataContract
>([
	[
		EntityTypeId.InternalEntity,
		new FieldDataContract(
			't4_fieldset_entity_status',
			't4_field_entity_status',
		),
	],
	[
		EntityTypeId.Counterparty,
		new FieldDataContract(
			't4_fieldset_counterparty_status',
			't4_field_counterparty_status',
		),
	],
	[
		EntityTypeId.Account,
		new FieldDataContract(
			't4_fieldset_account_status',
			't4_field_account_status',
		),
	],
	[
		EntityTypeId.Partner,
		new FieldDataContract(
			't4_fieldset_partner_status',
			't4_field_partner_status',
		),
	],
	[
		EntityTypeId.Staff,
		new FieldDataContract('t4_fieldset_staff_status', 't4_field_staff_status'),
	],
]);

export const EntityConfig = new Map<EntityType, IEntityConfigDefinition>([
	[
		EntityType.Entity,
		{
			createButtonConfig: {
				entityName: 'Entity',
				modalTitle: 'Create entity',
				description:
					'A unique code that represents the internal legal entity. Generally the ERP Company Code, although can be different.',
			},
			sideMenuConfig: {
				label: 'Entities',
				listPageTitle: 'Entities',
				listPageIcon: <DomainIcon />,
				approvalListPageTitle: 'Entity Approvals',
				roles: [roles.Everyone],
				entityType: EntityType.Entity,
			},
			approvePageTitle: 'Review Entity',
			editPageTitle: 'Manage Entity',
			profilePageTitle: 'Entity',
			uniqueIDContract: new FieldDataContract(
				't4_fieldset_entity_code',
				entityFieldIdentifiers.code,
			),
			controllerRoute: CustomerApiRoute.Entity,
		},
	],
	[
		EntityType.Partner,
		{
			createButtonConfig: {
				entityName: 'Partner',
				modalTitle: 'Create partner',
				description:
					'A unique code that represents the External Legal Entity. Can be the ERP code or other unique user defined value.',
			},
			sideMenuConfig: {
				label: 'Partners',
				listPageTitle: 'Partners',
				listPageIcon: <Diversity3Icon />,
				approvalListPageTitle: 'Partner Approvals',
				roles: [roles.Everyone],
				entityType: EntityType.Partner,
			},
			approvePageTitle: 'Review Partner',
			editPageTitle: 'Manage Partner',
			profilePageTitle: 'Partner',
			uniqueIDContract: new FieldDataContract(
				't4_fieldset_partner_code',
				partnerFieldIdentifiers.code,
			),
			controllerRoute: CustomerApiRoute.Partner,
		},
	],
	[
		EntityType.Counterparty,
		{
			createButtonConfig: {
				entityName: 'Counterparty',
				modalTitle: 'Create counterparty',
				description: 'A unique code that represents the counterparty.',
				tips: (
					<>
						<p>
							Coding suggestion is <strong>AAAACC###</strong> where:
						</p>
						<ul>
							<li>
								<strong>AAAA</strong> = Four character standard counterparty
								(bank) code (i.e., CITI, HSBC, WELL, JPMC, etc.)
							</li>
							<li>
								<strong>CC</strong> = ISO Country Code of the counterparty
								location (bank & branch) (i.e., US)
							</li>
							<li>
								<strong>###</strong> = incrementing digit for the unique SWIFT &
								local clearing code combination (i.e., 001) of the bank & branch
							</li>
						</ul>
					</>
				),
			},
			sideMenuConfig: {
				label: 'Counterparties',
				listPageTitle: 'Counterparties',
				listPageIcon: <AccountBalanceIcon />,
				approvalListPageTitle: 'Counterparty Approvals',
				roles: [roles.Everyone],
				entityType: EntityType.Counterparty,
			},
			approvePageTitle: 'Review Counterparty',
			editPageTitle: 'Manage Counterparty',
			profilePageTitle: 'Counterparty',
			uniqueIDContract: new FieldDataContract(
				't4_fieldset_counterparty_code',
				counterpartyFieldIdentifiers.code,
			),
			controllerRoute: CustomerApiRoute.Counterparty,
		},
	],
	[
		EntityType.Account,
		{
			createButtonConfig: {
				entityName: 'Account',
				modalTitle: 'Create account',
				description: 'A unique code that represents the account.',
				tips: (
					<>
						<p>
							Coding suggestion is <b>OMSE01-NDEA-37572-SEK-Operating-Active</b>{' '}
							where:
						</p>
						<ul>
							<li>
								<b>AAAA:</b> Entity Code of the legal entity that owns the
								Account\Subaccount
							</li>
							<li>
								<b>BBBB:</b> Four character Ultimate Parent Counterparty code
								(i.e. CHAS, HSBC, CITI, etc.)
							</li>
							<li>
								<b>#####:</b> Last five digits or characters of the
								Account\Subaccount number (i.e., 12345)
							</li>
							<li>
								<b>CCC:</b> Three character Currency Code that the
								Account\Subaccount is denominated in (i.e., USD, EUR, GBP, JPY,
								etc.)
							</li>
							<li>
								<b>Purpose:</b> Purpose of the Account\Subaccount (i.e.,
								Concentration, Collections, Payroll, Payables, Custody, etc.)
							</li>
							<li>
								<b>Status:</b> Status of the Account\Subaccount (i.e., Active,
								Closed, Pending Open, Pending Close, etc.)
							</li>
						</ul>
					</>
				),
			},
			sideMenuConfig: {
				label: 'Accounts',
				listPageTitle: 'Accounts',
				listPageIcon: <KeyIcon />,
				approvalListPageTitle: 'Account Approvals',
				roles: [roles.AccountUser],
				entityType: EntityType.Account,
			},
			approvePageTitle: 'Review Account',
			editPageTitle: 'Manage Account',
			profilePageTitle: 'Account',
			uniqueIDContract: new FieldDataContract(
				't4_fieldset_account_code',
				accountFieldIdentifiers.code,
			),
			controllerRoute: CustomerApiRoute.Account,
		},
	],
	[
		EntityType.Staff,
		{
			createButtonConfig: {
				entityName: 'Staff',
				modalTitle: 'Create staff',
				description:
					'A unique value assigned to a Staff member. Generally this is is the email address without the @domain value.',
			},
			sideMenuConfig: {
				label: 'Staff',
				listPageTitle: 'Staff',
				listPageIcon: <GroupIcon />,
				approvalListPageTitle: 'Staff Approvals',
				roles: [roles.Everyone],
				entityType: EntityType.Staff,
			},
			approvePageTitle: 'Review Staff',
			editPageTitle: 'Manage Staff',
			profilePageTitle: 'Staff',
			uniqueIDContract: new FieldDataContract(
				't4_fieldset_staff_code',
				staffFieldIdentifiers.code,
			),
			controllerRoute: CustomerApiRoute.Staff,
		},
	],
	[
		EntityType.Subaccount,
		{
			createButtonConfig: {
				entityName: 'Subaccount',
				modalTitle: 'Create subaccount',
				description: '',
				tips: (
					<>
						<p>
							A unique code that represents the account. Coding suggestion is{' '}
							<b>OMSE01-NDEA-37572-SEK-Operating-Active</b> where:
						</p>
						<ul>
							<li>
								<b>AAAA:</b> Entity Code of the legal entity that owns the
								Account\Subaccount
							</li>
							<li>
								<b>BBBB:</b> Four character Ultimate Parent Counterparty code
								(i.e. CHAS, HSBC, CITI, etc.)
							</li>
							<li>
								<b>#####:</b> Last five digits or characters of the
								Account\Subaccount number (i.e., 12345)
							</li>
							<li>
								<b>CCC:</b> Three character Currency Code that the
								Account\Subaccount is denominated in (i.e., USD, EUR, GBP, JPY,
								etc.)
							</li>
							<li>
								<b>Purpose:</b> Purpose of the Account\Subaccount (i.e.,
								Concentration, Collections, Payroll, Payables, Custody, etc.)
							</li>
							<li>
								<b>Status:</b> Status of the Account\Subaccount (i.e., Active,
								Closed, Pending Open, Pending Close, etc.)
							</li>
						</ul>
					</>
				),
			},
			sideMenuConfig: {
				label: 'Subaccounts',
				listPageTitle: 'Subaccounts',
				listPageIcon: <DomainIcon />,
				approvalListPageTitle: 'Subaccount Approvals',
				roles: [roles.AccountUser],
				entityType: EntityType.Subaccount,
			},
			approvePageTitle: 'Review Subaccount',
			editPageTitle: 'Manage Subaccount',
			profilePageTitle: 'Subaccount',
			uniqueIDContract: new FieldDataContract(
				't4_fieldset_account_code',
				subaccountFieldIdentifiers.code,
			),
			controllerRoute: CustomerApiRoute.Subaccount,
		},
	],
]);

//#endregion

//#region Functions

export function getCodeFieldForObjectType(entityType: EntityType): string {
	switch (entityType) {
		case EntityType.Entity:
			return entityFieldIdentifiers.code;
		case EntityType.Partner:
			return partnerFieldIdentifiers.code;
		case EntityType.Counterparty:
			return counterpartyFieldIdentifiers.code;
		case EntityType.Account:
			return accountFieldIdentifiers.code;
		case EntityType.Staff:
			return staffFieldIdentifiers.code;
		case EntityType.Subaccount:
			return subaccountFieldIdentifiers.code;
	}
}

export function getEntityTypeFromEntityName(value: string): string {
	const index = Object.keys(EntityTypeName).indexOf(value);
	const entityTypeName = Object.values(EntityTypeName)[index];
	const entityType = entityTypeNameToEntityType.get(entityTypeName)!;
	return entityType.valueOf();
}

export function getEntityTypeKey(value: EntityType): string {
	const keyIndex = Object.values(EntityType).indexOf(value);
	return Object.keys(EntityType)[keyIndex];
}

export type GetPluralNameInput = keyof typeof EntityType;
export function getPluralName(entityType: GetPluralNameInput): string {
	return singularToPluralEntity[entityType];
}

export function getPageNameFromUrl(value: string): string {
	value = value.replaceAll('-', '');
	const index = Object.keys(PageName).indexOf(value as PageName);
	const entityTypeName = Object.values(PageName)[index];
	return entityTypeName;
}

export function getAllowedRoles(
	page: PageTypes,
	entityType: EntityType,
): string[] {
	const allPageRoles = {
		[PageTypes.Index]: [roles.Everyone],
		[PageTypes.Edit]: [roles.Author],
		[PageTypes.Approve]: [roles.Approver],
	};

	let pageRoles: string[] = allPageRoles[page];
	if (entityType === EntityType.Account) {
		pageRoles = [
			...pageRoles.filter((x) => x !== roles.Everyone),
			roles.AccountUser,
		];
	}

	return pageRoles;
}

export function getEntityType(
	entityTypeId?: EntityTypeId,
): EntityType | undefined {
	if (entityTypeId) {
		return entityTypeIdToEntityType.get(entityTypeId);
	}

	return entityTypeId;
}

export function getEntityTypeId(
	entityType?: EntityType,
): EntityTypeId | undefined {
	if (entityType) {
		return entityTypeToEntityTypeId.get(entityType);
	}

	return entityType;
}

export function getObjectName(objectType?: EntityType): string {
	let name = '';

	if (objectType) {
		name = objectTypeToObjectTypeName.get(objectType) ?? '';
	}

	return name;
}

//#endregion
