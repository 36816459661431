import { observer } from 'mobx-react-lite';
import {
	LegalEntity,
	VisualizationPreferencesReq,
} from 'modules/clients/customer-api/src/api/visualizations';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';
import {
	useUserVisualizationPreference,
	UseUserVisualizationPreferenceProps,
} from '../../_hooks/useUserVisualizationPreference';
import { AsOfDateKey } from '../models/orgChartTypes';
//#region Contants

const OrgChartVisualizationKey = 'orgChart';
const initialPreferences: VisualizationPreferencesReq = {
	nodes: [],
	options: [
		// Chart Options
		{
			optionId: 'displayAuxiliaryNodes',
			hide: false,
		},
		{
			optionId: 'displaySecondaryOwnership',
			hide: false,
		},
		{
			optionId: 'displayLegend',
			hide: false,
		},
		{
			optionId: 'displayCompact',
			hide: true,
		},
		{
			optionId: 'displayOverviewMap',
			hide: true,
		},

		// Card Options
		{
			optionId: 'cardOptionsColor',
			hide: false,
			value: 'entityRegion',
		},
		{
			optionId: 'displayFlag',
			hide: false,
		},

		// Information Options
		{
			optionId: 'entityStatus',
			hide: false,
		},
		{
			optionId: 'entityRegion',
			hide: false,
		},
		{
			optionId: 'functionalCurrencyCode',
			hide: false,
		},
		{
			optionId: 'erpCode',
			hide: false,
		},
		{
			optionId: 'erpPlatform',
			hide: false,
		},
		{
			optionId: 'formOfOrganization',
			hide: false,
		},
		{
			optionId: 'incorporatedDate',
			hide: false,
		},
		{
			optionId: 'dissolutionDate',
			hide: false,
		},
		{
			optionId: 'acquiredCompany',
			hide: false,
		},
		{
			optionId: 'acquisitionDate',
			hide: false,
		},
		{
			optionId: 'stateProvince',
			hide: false,
		},
		{
			optionId: 'registrationNumber',
			hide: false,
		},
		{
			optionId: 'taxIdNumber',
			hide: false,
		},
		{
			optionId: 'taxIdCountry',
			hide: false,
		},
		{
			optionId: 'leiIdentifier',
			hide: false,
		},
	],
};

//#endregion

//#region Context

type OrgChartContextProps = Pick<
	UseUserVisualizationPreferenceProps,
	| 'optionPreferences'
	| 'nodePreferences'
	| 'updatePreferences'
	| 'views'
	| 'createView'
	| 'selectView'
	| 'overwriteView'
	| 'deleteView'
> & {
	isLoading: boolean;
	error: string | undefined;
	legalEntities: LegalEntity[];
	asOfDate: Moment;
};

const OrgChartContext = createContext<OrgChartContextProps>({
	isLoading: true,
	error: undefined,
	legalEntities: [],
	asOfDate: moment(),
	optionPreferences: [],
	nodePreferences: [],
	views: [],
	updatePreferences: () => Promise.resolve(),
	createView: () => Promise.resolve(),
	selectView: () => Promise.resolve(),
	overwriteView: () => Promise.resolve(),
	deleteView: () => Promise.resolve(),
});

//#endregion

//#region Provider

export type OrgChartProviderProps = {
	children: ReactNode;
};

export const OrgChartProvider: FC = observer(({ children }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();
	const {
		isLoading: isInitalizing,
		optionPreferences,
		nodePreferences,
		views,
		updatePreferences,
		createView,
		selectView,
		overwriteView,
		deleteView,
	} = useUserVisualizationPreference(
		OrgChartVisualizationKey,
		initialPreferences,
	);

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState<string>();
	const [legalEntities, setLegalEntities] = useState<LegalEntity[]>([]);

	const asOfDate = useMemo(() => {
		const asOfDateValue = optionPreferences.find(
			(x) => x.optionId === AsOfDateKey,
		)?.value;

		return asOfDateValue ? moment(asOfDateValue) : moment();
	}, [optionPreferences]);

	const initialize = useCallback(async () => {
		try {
			setIsLoading(true);
			setError(undefined);

			const response = await customerApiClient.api.visualizations.orgChart();

			if (response && response.data && response.data.data) {
				setLegalEntities(response.data.data);
			} else {
				throw new Error('Cound not load legal entities.');
			}
		} catch {
			const errorMessage = 'Could not load Entity Org Chart.';
			setError(errorMessage);
			enqueueSnackbar(errorMessage, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	}, [customerApiClient, enqueueSnackbar]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	return (
		<OrgChartContext.Provider
			value={{
				isLoading: isLoading || isInitalizing,
				error: error,
				legalEntities: legalEntities,
				asOfDate: asOfDate,
				optionPreferences: optionPreferences,
				nodePreferences: nodePreferences,
				views: views,
				updatePreferences: updatePreferences,
				createView: createView,
				selectView: selectView,
				overwriteView: overwriteView,
				deleteView: deleteView,
			}}
		>
			{children}
		</OrgChartContext.Provider>
	);
});

//#endregion

//#region Hook

export type UseOrgChartProps = OrgChartContextProps;

export function useOrgChart(): UseOrgChartProps {
	return useContext(OrgChartContext);
}

//#endregion
