import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { stonlyData } from 'stonly/functions';
import { TaxId } from '../models/taxId';

export const stonlyIds = {
	cancelButton: 'cancel-button',
	setPrimaryButton: 'submit-button',
};

export const testIds = {
	cancelButton: 'cancel-button',
	setPrimaryButton: 'set-primary-button',
};

export type MakePrimaryTaxIdDialogProps = {
	open: boolean;
	onClose: () => void;
	taxId: TaxId;
};

export const MakePrimaryTaxIdDialog: FC<MakePrimaryTaxIdDialogProps> = observer(
	({ open, onClose, taxId }) => {
		return (
			<Dialog open={open} fullWidth>
				<DialogTitle>Set as primary federal income tax ID?</DialogTitle>
				<DialogContent>
					<Typography>
						{
							'Setting this tax ID as the primary federal income tax ID removes the primary designation of any previous primary tax ID on this entity.'
						}
					</Typography>
				</DialogContent>
				<DialogActions
					sx={{
						paddingX: '1.5rem',
						paddingBottom: '1.5rem',
					}}
				>
					<Grid
						container
						columnSpacing={2}
						sx={{
							justifyContent: 'flex-end',
						}}
					>
						<Grid item>
							<Button
								data-testid={testIds.cancelButton}
								{...stonlyData({ id: stonlyIds.cancelButton })}
								color="secondary"
								onClick={() => onClose()}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item>
							<Button
								data-testid={testIds.setPrimaryButton}
								{...stonlyData({ id: stonlyIds.setPrimaryButton })}
								variant="contained"
								onClick={() => {
									taxId.makePrimaryFederalIncomeTaxId().then(() => {
										taxId.updateIsPrimaryFederalIncomeTaxId(true);
										onClose();
									});
								}}
							>
								Set Primary
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		);
	},
);
