import { Box, Grid, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { AccountIntegration } from 'modules/clients/customer-api/src/cash4/accountIntegrations';
import { ConnectionIndex } from 'modules/clients/customer-api/src/cash4/connections';
import { FC, useEffect } from 'react';
import { ConfirmFormModal } from 'shared/components/confirmFormModal';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { useAssignAccount } from '../hooks/useAssignAccount';

export type AssignAccountModalProps = {
	isOpen: boolean;
	connection: ConnectionIndex | null;
	accountIntegration: AccountIntegration | null;
	onClose: () => void;
	refetch: () => Promise<void>;
};

export const AssignAccountModal: FC<AssignAccountModalProps> = observer(
	({ isOpen, connection, accountIntegration, onClose, refetch }) => {
		const theme = useTheme();
		const assignAccountState = useAssignAccount(onClose, refetch);
		const columns = [
			{
				field: 'displayName',
				headerName: 'Account Display Name',
				type: 'string',
				flex: 1,
			},
			{
				field: 'naturalAccountNumber',
				headerName: 'Natural Account Number',
				type: 'string',
				flex: 1,
			},
			{
				field: 'priorDayStatementsAccountNumber',
				headerName: 'Prior Day Statements Account Number',
				type: 'string',
				flex: 1,
			},
		];

		useEffect(() => {
			assignAccountState.initialize(connection, accountIntegration);
		}, [connection, accountIntegration]);

		return (
			<ConfirmFormModal
				loading={assignAccountState.isLoading}
				isOpen={isOpen}
				isDirty={assignAccountState.isDirty}
				disableSubmit={assignAccountState.isSubmitDisabled}
				error={assignAccountState.error}
				modalTitle={'Assign Account'}
				modalDescription={
					'To display the account name in the table, match the Entity4 Account with the associated Account Identifier.'
				}
				cancelModalTitle={'Discard Selection?'}
				cancelModalDescription={
					'Are you sure you want to discard your changes? Your changes will not be saved.'
				}
				onSubmit={assignAccountState.submit}
				onClose={assignAccountState.closeModal}
				submitButtonLabel="Save"
				maxWidth="md"
			>
				<Grid container rowSpacing={3}>
					<Grid item xs={12}>
						<Box
							sx={{
								backgroundColor: theme.palette.lightBackground?.main,
								padding: '8px',
							}}
						>
							<Typography variant="body1">
								<strong>Account Identifier:</strong>{' '}
								{assignAccountState.accountIdentifier}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<UserPreferencesDataGrid
							tableKey="assignAccountStateList"
							stonlyId="assign-account-state"
							columns={columns}
							rows={assignAccountState.availableAccounts}
							loading={assignAccountState.isAccountsLoading}
							calculateColumnWidths
							autoHeight
							emptyMessage={
								assignAccountState.isAccountsLoading
									? ''
									: 'There are no accounts to display.'
							}
							checkboxSelection
							rowSelectionModel={
								!!assignAccountState.assignedAccount
									? [assignAccountState.assignedAccount.id]
									: []
							}
							onRowSelectionModelChange={assignAccountState.setAssignedAccount}
							showToolbar
						/>
					</Grid>
				</Grid>
			</ConfirmFormModal>
		);
	},
);
