import { makeAutoObservable } from 'mobx';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { SingleGroupRequestApprovalModel } from './singleGroupRequestApprovalModel';

export class SingleGroupRequestApprovalViewModel {
	private _model: SingleGroupRequestApprovalModel | null;
	private _openModal: boolean;
	private _successfulSubmit: boolean;
	private _successSnackbarOpen: boolean;

	constructor() {
		makeAutoObservable(this);

		this._model = null;
		this._successfulSubmit = false;
		this._successSnackbarOpen = false;
		this._openModal = false;
	}

	public isModalOpen = () => {
		return this._openModal;
	};
	public openModal = async (model: SingleGroupRequestApprovalModel) => {
		this._model = model;
		this._openModal = true;
	};
	public closeModal = () => {
		this._openModal = false;
		this._model = null;
	};

	public getDescription = () => {
		return this._model!.getDescription();
	};

	public getError = () => {
		return this._model!.error;
	};
	public getLoading = () => {
		return this._model!.loading;
	};
	public getIsApproved = () => {
		return this._model!.isApproved;
	};
	public getRejectionComment = () => {
		return this._model!.rejectionComment;
	};
	public setIsApproved = (value: boolean) => {
		this._model!.isApproved = value;
		if (value === true) this.setRejectionComment('');
	};
	public shouldShowRejectionComment = () => {
		return this._model!.isApproved === false;
	};
	public setRejectionComment = (value: string) => {
		this._model!.rejectionComment = value;
	};

	public isSuccessfulSubmit = () => {
		return this._successfulSubmit;
	};
	public resetSuccessfulSubmit = () => {
		this._successfulSubmit = false;
	};
	public isSuccessSnackbarOpen = () => {
		return this._successSnackbarOpen;
	};
	public closeSuccessSnackbar = () => {
		this._successSnackbarOpen = false;
	};

	public canSubmit = () => {
		if (this._model!.isApproved === null) return false;

		if (
			this._model!.isApproved === false &&
			isStringUndefinedOrNullOrWhitespace(this._model!.rejectionComment)
		)
			return false;

		return true;
	};

	public manageRequestsSubmit = async () => {
		await this._model!.submit();
		if (isStringUndefinedOrNullOrWhitespace(this.getError())) {
			this.closeModal();
			this._successfulSubmit = true;
			this._successSnackbarOpen = true;
		}
	};
}
