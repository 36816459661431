import { AxiosInstance } from 'axios';
import {
	AccountIntegrationsEndpoints,
	accountIntegrations,
} from './accountIntegrations';
import { AccountsEndpoints, accounts } from './accounts';
import { ConfigurationsEndpoints, configurations } from './configurations';
import { ConnectionsEndpoints, connections } from './connections';
import { ConnectorsEndpoints, connectors } from './connectors';

export type Cash4Endpoints = {
	accountIntegrations: AccountIntegrationsEndpoints;
	configurations: ConfigurationsEndpoints;
	connectors: ConnectorsEndpoints;
	connections: ConnectionsEndpoints;
	accounts: AccountsEndpoints;
};

export function cash4(axiosInstance: AxiosInstance): Cash4Endpoints {
	return {
		accountIntegrations: accountIntegrations(axiosInstance),
		configurations: configurations(axiosInstance),
		connectors: connectors(axiosInstance),
		connections: connections(axiosInstance),
		accounts: accounts(axiosInstance),
	};
}
