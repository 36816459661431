import { observer } from 'mobx-react-lite';
import {
	CreateLegalEntityGroupRequest,
	LegalEntityGroupCollection,
	LegalEntityGroupCollectionRequest,
	UpdateLegalEntityGroupRequest,
} from 'modules/clients/customer-api/src/entity4/legalEntityGroups';
import { T4DataResponse } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

type LegalEntityGroupsContextProps = {
	isInitializing: boolean;
	isRefetching: boolean;
	legalEntityGroupIds: string[];
	setLegalEntityGroupIds: (legalEntityGroupIds: string[]) => void;
	legalEntityGroupCollections: LegalEntityGroupCollection[];
	refetch: () => Promise<boolean>;

	isLoading: boolean;
	createCollection: (
		request: LegalEntityGroupCollectionRequest,
	) => Promise<T4DataResponse<string | null>>;
	updateCollection: (
		id: string,
		request: LegalEntityGroupCollectionRequest,
	) => Promise<T4DataResponse<string>>;
	removeCollection: (id: string) => Promise<T4DataResponse<string>>;
	createGroup: (
		request: CreateLegalEntityGroupRequest,
	) => Promise<T4DataResponse<string | null>>;
	updateGroup: (
		id: string,
		request: UpdateLegalEntityGroupRequest,
	) => Promise<T4DataResponse<string>>;
	deleteGroup: (id: string) => Promise<T4DataResponse<string>>;
};

const LegalEntityGroupsContext = createContext<LegalEntityGroupsContextProps>({
	isInitializing: true,
	isRefetching: false,
	legalEntityGroupIds: [],
	setLegalEntityGroupIds: () => {},
	legalEntityGroupCollections: [],
	refetch: () => Promise.resolve(true),

	isLoading: false,
	createCollection: () => Promise.resolve({} as T4DataResponse<string | null>),
	updateCollection: () => Promise.resolve({} as T4DataResponse<string>),
	removeCollection: () => Promise.resolve({} as T4DataResponse<string>),
	createGroup: () => Promise.resolve({} as T4DataResponse<string | null>),
	updateGroup: () => Promise.resolve({} as T4DataResponse<string>),
	deleteGroup: () => Promise.resolve({} as T4DataResponse<string>),
});

export type UseLegalEntityGroupsProps = LegalEntityGroupsContextProps;

export function useLegalEntityGroups(): UseLegalEntityGroupsProps {
	return useContext(LegalEntityGroupsContext);
}

export type LegalEntityGroupsProviderProps = {
	children: ReactNode;
};

export const LegalEntityGroupsProvider: FC = observer(({ children }) => {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const { getItem, updateItem } = useLocalStorage();

	// #region Load & Store

	const [isInitializing, setIsInitializing] = useState<boolean>(true);
	const [isRefetching, setIsRefetching] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [legalEntityGroupIds, _setLegalEntityGroupIds] = useState(
		getItem('legalEntityGroupIds')?.split(',') ?? [],
	);
	const [legalEntityGroupCollections, setLegalEntityGroupCollections] =
		useState<LegalEntityGroupCollection[]>([]);

	const setLegalEntityGroupIds = useCallback(
		(legalEntityGroupIds: string[]) => {
			_setLegalEntityGroupIds(legalEntityGroupIds);
			updateItem('legalEntityGroupIds', legalEntityGroupIds.join(','));
		},
		[updateItem],
	);

	const initialize = useCallback(async () => {
		try {
			setIsInitializing(true);
			const response =
				await customerApiClient.entity4.legalEntityGroups.getAllCollections();
			setLegalEntityGroupCollections(response?.data?.data ?? []);
		} catch {
			enqueueSnackbar(
				'Unable to load legal entity groups. Please refresh the page to try again.',
				{
					variant: 'error',
				},
			);
		} finally {
			setIsInitializing(false);
		}
	}, [customerApiClient.entity4.legalEntityGroups, enqueueSnackbar]);

	const refetch = useCallback(async () => {
		try {
			setIsRefetching(true);
			const response =
				await customerApiClient.entity4.legalEntityGroups.getAllCollections();
			setLegalEntityGroupCollections(response?.data?.data ?? []);
			return true;
		} catch {
			return false;
		} finally {
			setIsRefetching(false);
		}
	}, [customerApiClient.entity4.legalEntityGroups]);

	useEffect(() => {
		(async () => {
			await initialize();
		})();
	}, [initialize]);

	// #endregion

	// #region Legal Entity Group Collection Actions

	const createCollection = useCallback(
		async function (
			request: LegalEntityGroupCollectionRequest,
		): Promise<T4DataResponse<string | null>> {
			const errorResponse = {
				success: false,
				data: null,
				errors: {
					create: [
						'An unexpected error occurred when creating the entity group collection. Please try again.',
					],
				},
			};

			try {
				setIsLoading(true);
				const response =
					await customerApiClient.entity4.legalEntityGroups.createCollection(
						request,
					);

				if (response.data) {
					if (response.status === 200) refetch();
					return response.data;
				} else return errorResponse;
			} catch (ex: any) {
				return errorResponse;
			} finally {
				setIsLoading(false);
			}
		},
		[customerApiClient.entity4.legalEntityGroups, refetch],
	);

	const updateCollection = useCallback(
		async (
			id: string,
			request: LegalEntityGroupCollectionRequest,
		): Promise<T4DataResponse<string>> => {
			const errorResponse = {
				success: false,
				data: '',
				errors: {
					create: [
						'An unexpected error occurred when updating the entity group collection. Please try again.',
					],
				},
			};

			try {
				setIsLoading(true);
				const response =
					await customerApiClient.entity4.legalEntityGroups.updateCollection({
						id,
						data: request,
					});

				if (response.data) {
					if (response.status === 200) refetch();
					return response.data;
				} else return errorResponse;
			} catch (ex: any) {
				return errorResponse;
			} finally {
				setIsLoading(false);
			}
		},
		[customerApiClient.entity4.legalEntityGroups, refetch],
	);

	const removeCollection = useCallback(
		async function (id: string): Promise<T4DataResponse<string>> {
			const errorResponse = {
				success: false,
				data: id,
				errors: {
					remove: [
						'An unexpected error occured when removing the entity group collection. Please try again.',
					],
				},
			};

			try {
				setIsLoading(true);
				const response =
					await customerApiClient.entity4.legalEntityGroups.deleteCollection(
						id,
					);

				if (response.data) {
					if (response.status === 200) refetch();
					return response.data;
				} else return errorResponse;
			} catch (ex: any) {
				return errorResponse;
			} finally {
				setIsLoading(false);
			}
		},
		[customerApiClient.entity4.legalEntityGroups, refetch],
	);

	// #endregion

	// #region Legal Entity Group Actions

	const createGroup = useCallback(
		async function (
			request: CreateLegalEntityGroupRequest,
		): Promise<T4DataResponse<string | null>> {
			const errorResponse = {
				success: false,
				data: null,
				errors: {
					create: [
						'An unexpected error occured when creating the entity group. Please try again.',
					],
				},
			};

			try {
				setIsLoading(true);
				const response =
					await customerApiClient.entity4.legalEntityGroups.createGroup(
						request,
					);

				if (response.data) {
					if (response.status === 200) refetch();
					return response.data;
				} else return errorResponse;
			} catch (ex: any) {
				return errorResponse;
			} finally {
				setIsLoading(false);
			}
		},
		[customerApiClient.entity4.legalEntityGroups, refetch],
	);

	const updateGroup = useCallback(
		async function (
			id: string,
			request: UpdateLegalEntityGroupRequest,
		): Promise<T4DataResponse<string>> {
			const errorResponse = {
				success: false,
				data: id,
				errors: {
					update: [
						'An unexpected error occured when updating the entity group. Please try again.',
					],
				},
			};

			try {
				setIsLoading(true);
				const response =
					await customerApiClient.entity4.legalEntityGroups.updateGroup({
						id,
						data: request,
					});

				if (response.data) {
					if (response.status === 200) refetch();
					return response.data;
				} else return errorResponse;
			} catch (ex: any) {
				return errorResponse;
			} finally {
				setIsLoading(false);
			}
		},
		[customerApiClient.entity4.legalEntityGroups, refetch],
	);

	const deleteGroup = useCallback(
		async (id: string): Promise<T4DataResponse<string>> => {
			const errorResponse = {
				success: false,
				data: id,
				errors: {
					delete: [
						'An unexpected error occured when deleting the entity group. Please try again.',
					],
				},
			};

			try {
				setIsLoading(true);
				const response =
					await customerApiClient.entity4.legalEntityGroups.deleteGroup(id);

				if (response.data) {
					if (response.status === 200) refetch();
					return response.data;
				} else return errorResponse;
			} catch (ex: any) {
				return errorResponse;
			} finally {
				setIsLoading(false);
			}
		},
		[customerApiClient.entity4.legalEntityGroups, refetch],
	);

	// #endregion

	return (
		<LegalEntityGroupsContext.Provider
			value={{
				isInitializing,
				isRefetching,
				legalEntityGroupCollections,
				legalEntityGroupIds,
				setLegalEntityGroupIds,
				refetch,

				isLoading,
				createCollection,
				updateCollection,
				removeCollection,
				createGroup,
				updateGroup,
				deleteGroup,
			}}
		>
			{children}
		</LegalEntityGroupsContext.Provider>
	);
});
