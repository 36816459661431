import {
	Box,
	CircularProgress,
	Collapse,
	Divider,
	Grid,
	Tab,
	Tabs,
	Typography,
	useTheme,
} from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { observer } from 'mobx-react-lite';
import {
	Aspect,
	Tab as TabData,
} from 'modules/clients/customer-api/src/api/common';
import React, { FC, RefObject, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ObjectPathParams } from 'shared/constants/paths';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { stonlyData } from '../../../../stonly/functions';
import { AddressTabName } from '../../fields/objects/addressList';
import { getFieldSetView } from '../../shared/fieldSets/fieldSetResolver';
import { ICategorizedFieldSets } from '../../shared/fieldSets/fieldTypes';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { T4Object } from '../object/models/t4Object';
import { Entity } from '../objects/entity';
import { ContactsView } from './contactsView';
import { LegalEntityGroupsSelector } from './legalEntityGroupsSelector';
import { TabView } from './tabView';

export type HeaderRef = {
	categoryName: string;
	subcategoryName: string;
	ref: RefObject<HTMLDivElement>;
};

export type EntityFieldRendererProps = {
	loading: boolean;
	entity?: Entity;
	object?: T4Object;
	sortedFields?: ICategorizedFieldSets[];
	disableV1Rendering?: boolean;
	aspectOverride?: Aspect;
};

const EntityFieldRenderer: FC<EntityFieldRendererProps> = observer(
	({ loading, entity, sortedFields, disableV1Rendering, aspectOverride }) => {
		const theme = useTheme();
		const { objectType } = useParams<ObjectPathParams>();
		const { viewType, object, objectAspects, refetchObject } = useProfileView();
		const { entity4StaffPii } = useT4FeatureFlags();

		const [currentTab, setCurrentTab] = useState<string | false>(false);

		const informationAspect = useMemo(
			() =>
				aspectOverride ||
				objectAspects.find((x) => x.aspect.name === 'Information')?.aspect,
			[aspectOverride, objectAspects],
		);

		const isReadOnly = useMemo(
			() => viewType === FieldViews.default,
			[viewType],
		);

		const refs = useMemo<HeaderRef[]>(
			() =>
				sortedFields?.flatMap((field) =>
					field.subCategories.flatMap((subcategory) => ({
						categoryName: field.category.name,
						subcategoryName: subcategory.subcategory.name,
						ref: React.createRef(),
					})),
				) ?? [],
			[sortedFields],
		);
		const v2Refs = useMemo<HeaderRef[]>(
			() =>
				informationAspect?.tabs.flatMap((tab) =>
					tab.fieldGroups
						.sort((a, b) => a.sortOrder - b.sortOrder)
						.flatMap((fieldGroup) => ({
							categoryName: tab.name,
							subcategoryName: fieldGroup.name,
							ref: React.createRef(),
						})),
				) ?? [],
			[informationAspect?.tabs],
		);

		useEffect(() => {
			let calculatedTab: string | undefined = undefined;

			if (
				informationAspect &&
				informationAspect.tabs &&
				informationAspect.tabs.filter((x) => x.name !== 'Contacts').length > 0
			) {
				calculatedTab = informationAspect?.tabs.sort(
					(a, b) => a.sortOrder - b.sortOrder,
				)[0].id;
			}

			if (!calculatedTab) {
				if (
					!disableV1Rendering &&
					sortedFields &&
					sortedFields.filter((x) => {
						return !informationAspect?.tabs
							.map((x) => x.name)
							.includes(x.category.name);
					}).length > 0
				) {
					calculatedTab = sortedFields[0].category.id;
				}
			}
			if (calculatedTab) {
				setCurrentTab(calculatedTab);
			}
		}, [
			disableV1Rendering,
			informationAspect,
			informationAspect?.tabs,
			objectAspects,
			sortedFields,
		]);

		const isAddressTab = (categoryGroup: ICategorizedFieldSets) =>
			categoryGroup.category.name === AddressTabName;

		const handleTabChange = (_: React.ChangeEvent<{}>, newValue: string) => {
			setCurrentTab(newValue);
		};

		const CustomV1Tab: FC<{
			currentTab: string | false;
			categoryGroup: ICategorizedFieldSets;
		}> = observer(({ currentTab, categoryGroup }) => (
			<Collapse in={currentTab === categoryGroup.category.id}>
				<Box sx={{ paddingX: '1.5rem', paddingY: '0.25rem' }}>
					{categoryGroup.subCategories.map((subcategoryGroup) => (
						<Typography
							key={`subcategory-${subcategoryGroup.subcategory.id}`}
							onClick={() => {
								const ref = refs.find(
									(x) =>
										x.categoryName === categoryGroup.category.name &&
										x.subcategoryName === subcategoryGroup.subcategory.name,
								)?.ref;

								if (ref && ref.current) {
									ref.current.scrollIntoView({
										behavior: 'smooth',
									});
								}
							}}
							sx={() => ({
								textWrap: 'wrap',
								paddingY: '0.5rem',
								cursor: 'pointer',
							})}
						>
							{subcategoryGroup.subcategory.name}
						</Typography>
					))}
				</Box>
			</Collapse>
		));

		const CustomV2Tab: FC<{
			currentTab: string | false;
			tab?: TabData;
		}> = observer(({ currentTab, tab }) => (
			<Collapse in={currentTab === tab?.id}>
				<Box sx={{ paddingX: '1.5rem', paddingY: '0.25rem' }}>
					{tab?.fieldGroups
						.filter(() => {
							if (entity4StaffPii) {
								return true;
							}

							if (tab.name === 'Personal') {
								return entity4StaffPii
									? true
									: ['Citizenship & Residency'].includes(tab.name);
							}

							return true;
						})
						.map((fieldGroup) => (
							<Typography
								key={`subcategory-${fieldGroup.name}`}
								onClick={() => {
									const ref = v2Refs.find(
										(x) =>
											x.categoryName === tab.name &&
											x.subcategoryName === fieldGroup.name,
									)?.ref;

									if (ref && ref.current) {
										ref.current.scrollIntoView({
											behavior: 'smooth',
										});
									}
								}}
								sx={() => ({
									textWrap: 'wrap',
									paddingY: '0.5rem',
									cursor: 'pointer',
								})}
							>
								{fieldGroup.name}
							</Typography>
						))}
				</Box>
			</Collapse>
		));

		if (loading) {
			return (
				<Box width="100%" my={5} py={2} display="flex" justifyContent="center">
					<CircularProgress />
				</Box>
			);
		}

		return (
			<Grid
				container
				sx={{
					height: '100%',
					flexWrap: 'nowrap',
				}}
			>
				<Grid
					item
					xs={1.5}
					sx={{
						paddingTop: '1.25rem',
					}}
				>
					<Tabs
						value={currentTab ?? false}
						onChange={handleTabChange}
						orientation="vertical"
						color="secondary"
						sx={{
							position: 'sticky',
							top: '1.25rem',
							justifySelf: 'flex-start',
						}}
					>
						{!disableV1Rendering &&
							sortedFields
								?.filter((x) => {
									if (objectType !== 'staff') {
										if (
											objectType === 'entities' ||
											objectType === 'partners'
										) {
											if (entity && x.category.name === 'Legal') {
												return false;
											}
										}

										return true;
									}

									return (
										!informationAspect?.tabs
											.map((x) => x.name)
											.includes(x.category.name) ?? true
									);
								})
								?.map((categoryGroup) => {
									const elements = [
										<Tab
											key={`category-tab-${categoryGroup.category.id}`}
											data-testid={`${categoryGroup.category.name.toLowerCase()}-tab`}
											{...stonlyData({
												id: `${categoryGroup.category.name.toLowerCase()}-tab`,
											})}
											label={categoryGroup.category.name}
											value={categoryGroup.category.id}
											color="secondary"
											sx={{
												alignItems: 'flex-start',
												opacity: 1,
												color: theme.palette.charcoal[500],
												textAlign: 'left',
												...(currentTab === categoryGroup.category.id
													? {
															backgroundColor: theme.palette.denim[50],
															color: theme.palette.denim[500],
													  }
													: {}),
											}}
										/>,
									];

									if (categoryGroup.subCategories.length > 1) {
										elements.push(
											<CustomV1Tab
												currentTab={currentTab}
												categoryGroup={categoryGroup}
											/>,
										);
									}

									return elements;
								})
								.map((x) => x)}

						{informationAspect?.tabs
							.slice()
							.filter((tab) => {
								if (objectType === 'staff') {
									if (entity4StaffPii) {
										return true;
									} else {
										return !['ID Documents', 'Addresses'].includes(tab.name);
									}
								}

								return tab.name !== 'Addresses';
							})
							.sort((a, b) => a.sortOrder - b.sortOrder)
							.map((tab) => {
								if (tab.name === 'Addresses') {
									let categoryGroup = sortedFields?.find(
										(x) => x.category.name === 'Addresses',
									)!;
									const elements = [
										<Tab
											key={`category-tab-${categoryGroup.category.id}`}
											data-testid={`${categoryGroup.category.name.toLowerCase()}-tab`}
											{...stonlyData({
												id: `${categoryGroup.category.name.toLowerCase()}-tab`,
											})}
											label={categoryGroup.category.name}
											value={categoryGroup.category.id}
											color="secondary"
											sx={{
												alignItems: 'flex-start',
												opacity: 1,
												color: theme.palette.charcoal[500],
												textAlign: 'left',
												...(currentTab === categoryGroup.category.id
													? {
															backgroundColor: theme.palette.denim[50],
															color: theme.palette.denim[500],
													  }
													: {}),
											}}
										/>,
									];

									if (categoryGroup.subCategories.length > 1) {
										elements.push(
											<CustomV1Tab
												currentTab={currentTab}
												categoryGroup={categoryGroup}
											/>,
										);
									}

									return elements;
								}
								const elements = [
									<Tab
										key={`tab-${tab.id}`}
										data-testid={`${tab.name.toLowerCase()}-tab`}
										{...stonlyData({
											id: `${tab.name.toLowerCase()}-tab`,
										})}
										label={tab.name}
										value={tab.id}
										color="secondary"
										sx={(theme) => ({
											alignItems: 'flex-start',
											opacity: 1,
											color: theme.palette.charcoal[500],
											textAlign: 'left',
											...(currentTab === tab.id
												? {
														backgroundColor: theme.palette.denim[50],
														color: theme.palette.denim[500],
												  }
												: {}),
										})}
									/>,
								];

								const tabData = informationAspect?.tabs.find(
									(x) => x.id === tab.id,
								);

								if (
									(tabData?.fieldGroups.filter(() => {
										if (entity4StaffPii) {
											return true;
										}

										if (objectType === 'staff' && tab.name === 'Personal') {
											return entity4StaffPii
												? true
												: ['Citizenship & Residency'].includes(tab.name);
										}

										return true;
									}).length ?? 0) > 1
								) {
									elements.push(
										<CustomV2Tab currentTab={currentTab} tab={tabData} />,
									);
								}

								return elements;
							})
							.map((x) => x)}
					</Tabs>
				</Grid>
				<Grid
					item
					xs={10.5}
					sx={{
						padding: '2rem',
						borderLeft: '1px solid',
						borderColor: theme.palette.charcoal[50],
					}}
				>
					{!disableV1Rendering &&
						entity &&
						sortedFields
							?.filter((x) => {
								if (x.category.name === 'Addresses') {
									return true;
								} else {
									return (
										!informationAspect?.tabs
											.map((x) => x.name)
											.includes(x.category.name) ?? true
									);
								}
							})
							?.map((categoryGroup) => (
								<Box
									role="tabpanel"
									key={`tabpanel-${categoryGroup.category.id}`}
									hidden={currentTab !== categoryGroup.category.id}
								>
									<Grid container sx={{ gap: 2 }}>
										{categoryGroup.subCategories.map(
											(subcategoryGroup, index) => (
												<Grid
													component="section"
													key={`subcategory-group-${subcategoryGroup.subcategory.id}-${index}`}
													container
													item
													xs={12}
													sx={{ gap: 2 }}
												>
													{!isAddressTab(categoryGroup) && (
														<Grid item xs={12}>
															<Typography
																ref={
																	refs.find(
																		(x) =>
																			x.categoryName ===
																				categoryGroup.category.name &&
																			x.subcategoryName ===
																				subcategoryGroup.subcategory.name,
																	)?.ref
																}
																variant="h3"
															>
																{subcategoryGroup.subcategory.name}
															</Typography>
														</Grid>
													)}
													{subcategoryGroup.fieldSets.map((fieldSet, index) => {
														const FieldSetView = getFieldSetView(fieldSet);

														return (
															<Grid
																key={`field-set-view-${fieldSet.id}-${index}-grid`}
																item
																xs={12}
															>
																<FieldSetView
																	key={`field-set-view-${fieldSet.id}-${index}`}
																	fieldSetDefinition={fieldSet}
																	entity={entity}
																	isReadOnly={isReadOnly}
																/>
															</Grid>
														);
													})}
													{objectType === 'entities' &&
														subcategoryGroup.subcategory.name === 'General' &&
														categoryGroup.category.name === 'Details' && (
															<Grid item xs={12}>
																<LegalEntityGroupsSelector
																	isReadOnly={isReadOnly}
																/>
															</Grid>
														)}
													{categoryGroup.subCategories.length - 1 !== index && (
														<Grid item xs={12} sx={{ paddingBottom: '0.5rem' }}>
															{viewType === FieldViews.default && <Divider />}
														</Grid>
													)}
												</Grid>
											),
										)}
									</Grid>
								</Box>
							))}
					{object &&
						informationAspect?.tabs.map((tab, index) => {
							const tabId = `t4-tab-view-${tab.id}`;
							const key = `tav-view-${tab.id}-${index}`;
							return tab.name === 'Contacts' ? (
								<ContactsView
									key={key}
									tabId={tabId}
									object={object}
									tab={tab}
									hidden={currentTab !== tab.id}
									refetchObject={refetchObject}
								/>
							) : (
								<TabView
									key={key}
									tabId={tabId}
									object={object}
									tab={tab}
									hidden={currentTab !== tab.id}
									v2Refs={v2Refs}
								/>
							);
						})}
				</Grid>
			</Grid>
		);
	},
);

export default EntityFieldRenderer;
