import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { stonlyData } from 'stonly/functions';
import { paths } from '../../../../../../shared/constants/paths';
import { useProfileView } from '../../../../entityProfile/providers/entityProfileContextProvider';
import { T4Autocomplete } from '../../../../shared/components/atoms/t4Autocomplete';
import { FieldViews } from '../../../../shared/fieldSets/fieldViews/fieldViewTypes';
import { NewTaxIdForm } from '../models/newTaxIdForm';

export const stonlyIds = {
	closeButton: 'cancel-button',
	createButton: 'submit-button',
};

export const testIds = {
	closeButton: 'close-button',
	createButton: 'create-button',
};

export type CreateTaxIdDialogProps = {
	open: boolean;
	onClose: () => void;
	newTaxIdForm: NewTaxIdForm;
};

export const CreateTaxIdDialog: FC<CreateTaxIdDialogProps> = observer(
	({ open, onClose, newTaxIdForm }) => {
		const history = useHistory();
		const { setViewType } = useProfileView();

		return (
			<Dialog open={open} fullWidth>
				<DialogTitle>Create Tax ID</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item xs={12}>
							<T4Autocomplete<ReferenceDataValue, false, false, false>
								label="Issuing Country"
								value={newTaxIdForm.country}
								options={newTaxIdForm.countries}
								onSelect={(value) => newTaxIdForm.onCountryChange(value)}
								onFocus={() => newTaxIdForm.onCountryFocus()}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) =>
									option.value === value.value
								}
								loading={newTaxIdForm.loadingCountries}
								required
							/>
						</Grid>
						<Grid item xs={12}>
							<T4Autocomplete<ReferenceDataValue, false, false, false>
								label="Issuing State / Province"
								value={newTaxIdForm.stateProvince}
								options={newTaxIdForm.stateProvinces}
								onSelect={(value) => newTaxIdForm.onStateProvinceChange(value)}
								onFocus={() => newTaxIdForm.onStateProviceFocus()}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) =>
									option.value === value.value
								}
								loading={newTaxIdForm.loadingStateProvinces}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
					sx={{
						paddingX: '1.5rem',
						paddingBottom: '1.5rem',
					}}
				>
					<Grid
						container
						columnSpacing={2}
						sx={{
							justifyContent: 'flex-end',
						}}
					>
						<Grid item>
							<Button
								data-testid={testIds.closeButton}
								{...stonlyData({ id: stonlyIds.closeButton })}
								onClick={() => onClose()}
								color="secondary"
							>
								Close
							</Button>
						</Grid>
						<Grid item>
							<Button
								data-testid={testIds.createButton}
								{...stonlyData({ id: stonlyIds.createButton })}
								variant="contained"
								disabled={
									newTaxIdForm.country === null || newTaxIdForm.isCreatingTaxId
								}
								onClick={() => {
									newTaxIdForm.createTaxId().then((data) => {
										history.push(
											generatePath(
												paths.entity4.objects.object.taxIds.taxId.href,
												{
													objectType: newTaxIdForm.entityType,
													objectId: newTaxIdForm.entityId,
													taxIdId: data,
												},
											),
										);
									});
									setViewType(FieldViews.edit);
								}}
							>
								Create
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		);
	},
);
