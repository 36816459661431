import { AxiosInstance } from 'axios';
import { T4Response, Read, Write } from 'modules/clients/types';

export type Counterparty = {
	code: string;
	name: string;
};

export const MaxFileSizeInMB = 10;

export type FileContentType = string;

export type FileErrorMessage = string;

export type CsvUploadedFile = {
	id: string;
	bankCode: string;
	contentType: string;
	name: string;
	fileType: string;
	status: string;
	uploadedBy: string;
	uploadedDate: string;
	errorMessage: string | null;
};

export type BAI2UploadedFile = {
	id: string;
	bankCode: string;
	name: string;
	fileType: string;
	status: string;
	uploadedBy: string;
	uploadedDate: string;
	errorMessage: string | null;
};

export type UploadFilesInput = FormData;

export type UploadFilesResult = {
	success?: any;
	failure?: boolean;
	error?: string;
	successfulFileUploads: BAI2UploadedFile[] | CsvUploadedFile[];
	unsuccessfulFileUploads: string[];
};
export type UploadFilesResponse = T4Response<
	UploadFilesResult,
	UploadFilesInput
>;

export type DataImportsResult = {
	counterparties: Counterparty[];
	uploadedFiles: BAI2UploadedFile[] | CsvUploadedFile[];
};
export type DataImportsResponse = T4Response<DataImportsResult>;

export type CsvColumnsResult = string[];

export type CsvColumnsResponse = T4Response<CsvColumnsResult>;

export type DataImportsEndpoints = {
	dataImports: Read<DataImportsResult>;
	uploadBai2Files: Write<UploadFilesResult, UploadFilesInput>;
	uploadCsvFiles: Write<UploadFilesResult, UploadFilesInput>;
	getCsvColumnsTransactions: Read<CsvColumnsResult>;
	getCsvColumnsBalances: Read<CsvColumnsResult>;
};

export function dataImports(
	axiosInstance: AxiosInstance,
): DataImportsEndpoints {
	return {
		dataImports: async (_, config = {}): Promise<DataImportsResponse> => {
			return await axiosInstance.get('api/v1.0/frontend/cash4/dataImports', {
				...config,
			});
		},
		uploadBai2Files: async (
			data,
			config = {},
		): Promise<UploadFilesResponse> => {
			return await axiosInstance.post(
				'api/v1.0/frontend/cash4/dataImports/documents/bai2',
				data,
				{ ...config },
			);
		},
		uploadCsvFiles: async (data, config = {}): Promise<UploadFilesResponse> => {
			return await axiosInstance.post('api/v1.0/csvImports', data, {
				...config,
			});
		},
		getCsvColumnsTransactions: async (
			_,
			config = {},
		): Promise<CsvColumnsResponse> => {
			return await axiosInstance.get(
				'api/v1.0/csvImports/columns/transactions',
				{ ...config },
			);
		},
		getCsvColumnsBalances: async (
			_,
			config = {},
		): Promise<CsvColumnsResponse> => {
			return await axiosInstance.get('api/v1.0/csvImports/columns/balances', {
				...config,
			});
		},
	};
}
