import { Visibility } from '@mui/icons-material';
import {
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { ForwardedRef, forwardRef, useMemo } from 'react';

export type SensitiveFieldProps = {
	id: string;
	label?: string;
	description?: string;
	variant?: 'standard' | 'outlined';
	hideAdornments?: boolean;
	disableMargin?: boolean;
	onShowField?: () => void;
};

const SensitiveFieldInner =
	// eslint-disable-next-line mobx/missing-observer
	(
		{
			id,
			label,
			description,
			variant = 'standard',
			hideAdornments,
			disableMargin,
			onShowField = () => {},
			...rest
		}: SensitiveFieldProps,
		_ref?: ForwardedRef<HTMLFormElement>,
	) => {
		const SensitiveInputAdornment = useMemo(
			() => (
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle sensitive field visibility"
						onClick={() => onShowField()}
					>
						<Visibility />
					</IconButton>
				</InputAdornment>
			),
			[onShowField],
		);

		const InputComponent = useMemo(
			() =>
				variant === 'standard' ? (
					<Input
						id={`${variant}-sensitive-field-${id}`}
						type={'password'}
						autoComplete="off"
						value={'*****'}
						fullWidth
						disableUnderline
						readOnly
						startAdornment={
							hideAdornments ? undefined : (
								<T4FieldAdornment
									id={id}
									title={label}
									description={description}
									t4AdornmentType="info"
									iconButtonProps={{
										sx: {
											marginLeft: disableMargin ? '0px' : '-5px',
										},
									}}
								/>
							)
						}
						endAdornment={hideAdornments ? undefined : SensitiveInputAdornment}
						inputProps={{
							style: {
								padding: '8.5px 16px',
								paddingLeft: '0px',
							},
						}}
					/>
				) : (
					<OutlinedInput
						id={`${variant}-sensitive-field-${id}`}
						type={'password'}
						value={'*****'}
						fullWidth
						readOnly
						notched
						label={label}
						endAdornment={hideAdornments ? undefined : SensitiveInputAdornment}
						inputProps={{
							style: {
								padding: '8.5px 16px',
							},
						}}
					/>
				),
			[
				SensitiveInputAdornment,
				description,
				disableMargin,
				hideAdornments,
				id,
				label,
				variant,
			],
		);

		return (
			<FormControl
				{...rest}
				fullWidth
				variant={variant}
				sx={{
					margin: 0,
					marginTop: disableMargin ? undefined : '8px',
				}}
				component={'form'}
			>
				{(label?.length ?? 0) > 0 && (
					<InputLabel
						htmlFor={`${variant}-sensitive-field-${id}`}
						variant="standard"
						focused={false}
						shrink
					>
						{label}
					</InputLabel>
				)}
				{InputComponent}
			</FormControl>
		);
	};

export const SensitiveField = forwardRef(SensitiveFieldInner);
