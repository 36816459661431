export const formatFileSize = (bytes: string | number | undefined): string => {
	if (typeof bytes === 'string') {
		bytes = parseInt(bytes);
	}
	if (bytes) {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		const i = Math.min(
			parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10),
			sizes.length - 1,
		);
		return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)} ${sizes[i]}`;
	}
	return 'n/a';
};

export const downloadFile = (
	file: File,
	contentType: string,
	fileName: string,
) => {
	const link = document.createElement('a');
	link.href = URL.createObjectURL(file);
	link.setAttribute('target', '_blank');
	link.setAttribute('type', contentType);
	link.setAttribute('download', fileName);

	document.body.appendChild(link);
	link.click();
	globalThis.document.body.removeChild(link);
};
