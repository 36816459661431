import { AxiosResponse } from 'axios';
import { makeAutoObservable, flow } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { Errors } from 'utilities/errors/errorUtils';
import { CashFlowClass, CashFlowListItem } from '../categoriesViewModel';

export type AddTypeErrors = Errors<{
	code: [];
	name: [];
}>;

export class AddTypeViewModel {
	private _refetch: () => CancellablePromise<void>;

	private _open: boolean = false;
	private _loading: boolean = false;
	private _error?: string;
	private _errors: AddTypeErrors = { code: [], name: [] };
	private _classes: CashFlowClass[] = [];
	private _class?: CashFlowClass;
	private _name?: string;
	private _code?: string;

	constructor(refetch: () => CancellablePromise<void>) {
		makeAutoObservable(this);

		this._refetch = () => refetch();
	}

	public isOpen = (): boolean => this._open;
	public isLoading = (): boolean => this._loading;
	public isSubmitDisabled = () => !this._name || !this._code || !this._class;
	public getError = () => this._error;
	public getCodeErrors = () => this._errors.code;
	public getNameErrors = () => this._errors.name;
	public getClasses = () => this._classes.slice();
	public getClass = () => this._class;
	public getName = () => this._name;
	public getCode = () => this._code;

	public setName = (value: string) => (this._name = !value ? undefined : value);
	public setCode = (value: string) =>
		(this._code = !value ? undefined : value.toUpperCase());
	public setClass = (value: CashFlowClass | null) =>
		(this._class = value ?? undefined);

	public open = (row: CashFlowListItem, classes?: CashFlowClass[]) => {
		this._classes = classes?.slice() ?? [];
		this._class = classes?.find((cls) => cls.id === row.classId);
		this._open = true;
	};

	public close = () => {
		this._open = false;
		this._loading = false;
		this._error = undefined;
		this._errors = { code: [], name: [] };
		this._classes = [];
		this._class = undefined;
		this._name = undefined;
		this._code = undefined;
	};

	public save = flow(function* (this: AddTypeViewModel) {
		try {
			this._loading = true;
			this._error = undefined;
			this._errors = { code: [], name: [] };

			const response: AxiosResponse<
				ApiResponse<CashFlowListItem[]>
			> = yield customerApi.post<ApiResponse<CashFlowListItem[]>>(
				`/categories/${this._class?.id}/types`,
				{
					name: this._name?.trim(),
					code: this._code?.trim(),
				}
			);

			if (response.data.errors) {
				this._errors = (response.data.errors as unknown) as AddTypeErrors;
			} else if (response.data.error) {
				this._error = response.data.error;
			} else {
				this.close();
				this._refetch();
			}
		} catch (error) {
			this._error = (error as Error).message;
		} finally {
			this._loading = false;
		}
	});
}
