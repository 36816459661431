import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';

export type LayoutDetails = {
	organizationCode: string;
	notificationsCount: number;
};
export type LayoutDetailsResponse = T4Response<T4DataResponse<LayoutDetails>>;

export type LayoutEndpoints = {
	getLayoutDetails: Read<T4DataResponse<LayoutDetails>>;
};

export function layout(axiosInstance: AxiosInstance): LayoutEndpoints {
	return {
		getLayoutDetails: async (_, config): Promise<LayoutDetailsResponse> =>
			await axiosInstance.get('api/layout', {
				...config,
			}),
	};
}
