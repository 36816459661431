import { flow, makeAutoObservable } from 'mobx';
import {
	FieldChange,
	NotificationsResult,
} from 'modules/clients/customer-api/src/api/frontend';
import { T4CustomerClient } from 'modules/clients/customer-api/src/client';
import { T4Response } from 'modules/clients/types';
import { parseError } from 'utilities/errors/errorUtils';

export interface T4Notification extends FieldChange {
	id: string;
}

export default class NotificationListViewModel {
	private _error?: string;
	private _loading = true;
	private _results?: NotificationsResult;

	private _pendingApprovalRepository: T4CustomerClient;

	constructor(pendingApprovalRepository: T4CustomerClient) {
		makeAutoObservable(this);

		this._pendingApprovalRepository = pendingApprovalRepository;
	}

	public load = flow(function* (this: NotificationListViewModel) {
		this._loading = true;
		this._error = undefined;

		try {
			const response: T4Response<NotificationsResult> =
				yield this._pendingApprovalRepository.api.frontend.notifications();
			this._results = response.data;
			if (response.data === undefined) {
				this._error = '404';
			}
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});

	public isLoading(): boolean {
		return this._loading;
	}

	public getError(): string | undefined {
		return this._error;
	}

	public getNotificationsCount() {
		const pendingApprovalsCount = this._results?.pendingApprovals.length ?? 0;
		const rejectionsCount = this._results?.rejections.length ?? 0;
		return pendingApprovalsCount + rejectionsCount;
	}

	public getPendingApprovals(): T4Notification[] {
		return (
			this._results?.pendingApprovals.slice().map((value) => ({
				id: value.objectId + value.fieldName,
				objectId: value.objectId,
				objectType: value.objectType,
				parentObjectId: value.parentObjectId,
				parentObjectType: value.parentObjectType,
				objectName: value.objectName,
				fieldName: value.fieldName,
				dateChanged: value.dateChanged,
				changedBy: value.changedBy,
			})) ?? []
		);
	}

	public getRejections(): T4Notification[] {
		return (
			this._results?.rejections.slice().map((value) => ({
				id: value.objectId + value.fieldName,
				objectId: value.objectId,
				objectType: value.objectType,
				parentObjectId: value.parentObjectId,
				parentObjectType: value.parentObjectType,
				objectName: value.objectName,
				fieldName: value.fieldName,
				dateChanged: value.dateChanged,
				changedBy: value.changedBy,
				rejectedBy: value.rejectedBy,
			})) ?? []
		);
	}
}
