//#region Models

import { AxiosInstance } from 'axios';
import { ObjectType, Read, T4DataResponse } from 'modules/clients/types';

export type FieldType =
	| 'Text'
	| 'Boolean'
	| 'Date'
	| 'Option'
	| 'PhoneNumber'
	| 'LongText'
	| 'Address';

export type ObjectAspect = {
	objectType: ObjectType;
	isDefault: boolean;
	aspect: Aspect;
};

export type Aspect = {
	id: string;
	name: string;
	urlId: string;
	description: string;
	allowMultipleEnteries: boolean;
	creationDescription?: string;
	canBeAdded: boolean;
	tabs: Tab[];
};

export type Tab = {
	id: string;
	name: string;
	sortOrder: number;
	fieldGroups: FieldGroup[];
};

export type FieldGroup = {
	id: string;
	identifier: string;
	name: string;
	singularName: string;
	isCollection: boolean;
	sortOrder: number;
	childFieldGroups: FieldGroup[];
	fields: Field[];
};

export type Field = {
	id: string;
	identifier: string;
	name: string;
	fieldType: FieldType;
	description?: string;
	parentFieldIdentifier?: string;
	requiresApproval: boolean;
	isCreationRequirement: boolean;
	requiresUniqueValue: boolean;
	isPII: boolean;
	isRecommendedCreationField: boolean;
	isDefaultApprovalListColumn: string;
	isDefaultListColumn: boolean;
	optionListId?: string;
	referenceCollectionName?: string;
	validationCriterionId?: string;
	optionList?: OptionList;
	validationCriterion?: ValidationCriterion;
	sortOrder: number;
};

export type ValidationCriterion = {
	validationRegex: string;
	errorDescription: string;
};

export type OptionList = {
	id: string;
	name: string;
	options: Option[];
};

export type Option = {
	id: string;
	displayName: string;
	code?: string;
	detail?: string;
};

//#endregion

//#region Responses

export type ObjectAspectInput = {
	objectType?: ObjectType;
};

//#endregion

export type CommonEndpoints = {
	objectAspects: Read<T4DataResponse<ObjectAspect[]>, ObjectAspectInput>;
};

export function common(axiosInstance: AxiosInstance): CommonEndpoints {
	return {
		objectAspects: async (input?: ObjectAspectInput, config = {}) =>
			axiosInstance.get('api/common/objectaspects', {
				...config,
				params: input,
			}),
	};
}
