import { ReferenceDataValue } from 'shared/types/referenceDataTypes';

/**
 * Converts reference data json string to ReferenceDataValue object or returns null
 */
export const jsonToReferenceData = (
	maybeJson: string | null | undefined,
): ReferenceDataValue | null => {
	if (!maybeJson) return null;

	try {
		return JSON.parse(maybeJson);
	} catch {
		return {
			value: maybeJson,
			displayName: maybeJson,
			identifier: maybeJson,
		};
	}
};

/**
 * JSON.stringifies reference data value object or returns null
 */
export const referenceDataToJson = (
	value: ReferenceDataValue | null,
): string | null => {
	return value ? JSON.stringify(value) : null;
};

/**
 * Extracts value from reference json string
 */
export const extractValueFromRefJson = (
	maybeRefJson: string | null | undefined,
): string | null => {
	const refValue = jsonToReferenceData(maybeRefJson);
	if (refValue === null) return null;

	return refValue.value;
};

/**
 * Extracts display name from reference json string (REMOVES IDENTIFIER IF IDENTIFIER EXISTS IN DISPLAY NAME)
 * EX: "Identifier - DisplayName" => "DisplayName"
 */
export const extractDisplayNameFromRefJson = (
	maybeRefJson: string | null | undefined,
): string | null => {
	const refValue = jsonToReferenceData(maybeRefJson);
	if (refValue === null) return null;

	const name = refValue.displayName;
	const formattedName = removeIdentifierFromReferenceDisplayName(refValue);
	if (formattedName) return formattedName;

	return name;
};

/**
 * Formats reference data display name from "Identifier - DisplayName" => "DisplayName"
 * Some reference display names are prefixed with the "Identifier - " which is not always wanted
 */
export const removeIdentifierFromReferenceDisplayName = (
	dto: ReferenceDataValue,
): string => {
	const [matches] = dto.displayName.matchAll(/^[^-]+ - (.+)$/g);
	if (matches && matches.length === 2) return matches[1];

	return dto.displayName;
};
