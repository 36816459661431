import { debounce } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { T4MultiSelectAutocomplete } from 'shared/components/autocompletes/t4MultiselectAutocomplete';
import { stonlyData } from 'stonly/functions';
import { T4FieldAdornment } from './molecules/t4FieldAdornment';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';

type LegalEntityGroupOption = {
	id: string;
	collectionName: string;
	name: string;
};

export type LegalEntityGroupsSelectorProps = {
	loading: boolean;
};

export const LegalEntityGroupsFilter: FC<LegalEntityGroupsSelectorProps> =
	observer(({ loading }) => {
		const {
			isInitializing,
			legalEntityGroupCollections,
			legalEntityGroupIds,
			setLegalEntityGroupIds,
		} = useLegalEntityGroups();

		const legalEntityGroups = useMemo<LegalEntityGroupOption[]>(
			() => [
				...legalEntityGroupCollections.flatMap((collection) =>
					collection.legalEntityGroups.map((x) => ({
						id: x.id,
						collectionName: collection.name,
						name: x.name,
					})),
				),
			],
			[legalEntityGroupCollections],
		);
		const [selectedLegalEntityGroups, setSelectedLegalEntityGroups] = useState<
			LegalEntityGroupOption[]
		>([]);

		const onChangeDebounce = useMemo(
			() =>
				debounce((values: LegalEntityGroupOption[]) => {
					if (
						values.length !== legalEntityGroupIds.length ||
						values.some((x) => !legalEntityGroupIds.includes(x.id))
					) {
						setLegalEntityGroupIds(values.map((x) => x.id));
					}
				}, 2000),
			[legalEntityGroupIds, setLegalEntityGroupIds],
		);

		useEffect(() => {
			setSelectedLegalEntityGroups(
				legalEntityGroups.filter((x) => legalEntityGroupIds.includes(x.id)),
			);
		}, [legalEntityGroupIds, legalEntityGroups]);

		return (
			<T4MultiSelectAutocomplete<LegalEntityGroupOption>
				{...stonlyData({
					id: 'legal-entity-groups-filter',
				})}
				disablePortal
				loading={loading || isInitializing}
				disabled={loading || isInitializing}
				label="Filter by Entity Group"
				options={legalEntityGroups}
				value={selectedLegalEntityGroups}
				groupBy={(x) => x.collectionName}
				getOptionKey={(option) => option.id}
				getOptionLabel={(x) => x.name}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(_, values) => {
					setSelectedLegalEntityGroups(values);
					onChangeDebounce(values);
				}}
				listHelperText="Entity groups are managed by administrators through Global Settings within Administration."
				placeholder={
					selectedLegalEntityGroups.length === 0 ? 'Select entity groups' : ''
				}
				startAdornment={
					<T4FieldAdornment
						title="Filter by entity group"
						description="Selecting an entity group filters records based on relationships"
						t4AdornmentType="info"
					/>
				}
				sx={{
					minWidth: '20rem',
				}}
				disableListWrap
			/>
		);
	});
