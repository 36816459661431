/* eslint-disable mobx/missing-observer */
import LinkOffIcon from '@mui/icons-material/LinkOff';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from '@mui/material';
import { Cash4Account } from 'models/apiModels';
import React from 'react';
import { brandColors } from 'shared/theme/brandColors';
import LinkDialog, { BlueBox } from './LinkDialog';
import { useClients } from 'shared/hooks/useClients';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { AccountEventsHistoryList } from './AccountEventsHistoryList';

interface IDetailPanelContentProps {
	row: Cash4Account;
	isIgnored?: boolean;
	stonlyIds?: { [key: string]: string };
}

const DetailPanelContent: React.FC<IDetailPanelContentProps> = ({
	row,
	isIgnored = false,
	stonlyIds,
}) => {
	const [openLink, setOpenLink] = React.useState(false);
	const [openIgnore, setOpenIgnore] = React.useState(false);
	const [isRefetching, setIsRefetching] = React.useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const queryClient = useQueryClient();
	const { customerApiClient } = useClients();

	const handleRefetch = () => {
		return queryClient.refetchQueries(['accounts']);
	};

	const handleIgnore = () => {
		const id = row.id;
		mutate(id);
	};
	const handleOpenLink = () => {
		setOpenLink(true);
	};
	const handleCloseLink = () => {
		setOpenLink(false);
	};

	const handleOpenIgnore = () => {
		setOpenIgnore(true);
	};

	const handleCloseIgnore = () => {
		setOpenIgnore(false);
	};

	const ignoreAccountMutation = async (id: string) => {
		return customerApiClient.cash4.accounts.ignoreAccount(id);
	};

	const { mutate, isLoading: isMutating } = useMutation(ignoreAccountMutation, {
		onSuccess: () => {
			setIsRefetching(true);
			handleRefetch()
				.then(() => {
					enqueueSnackbar('Successfully Ignored Account', {
						variant: 'success',
					});
				})
				.catch((error) => {
					enqueueSnackbar('Error refetching accounts.', {
						variant: 'error',
					});
				})
				.finally(() => {
					setIsRefetching(false);
					handleCloseIgnore();
				});
		},
		onError: (err) => {
			enqueueSnackbar('Failed to ignore.');
			handleCloseIgnore();
		},
	});

	const isLoading = isMutating || isRefetching;

	return (
		<Stack sx={{ height: '100%', boxSizing: 'border-box' }} direction="column">
			{!row.isLinked && !isIgnored && (
				<Box
					sx={{
						padding: 2,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: 2,
						border: `1px dashed ${brandColors.grey[100]}`,
						margin: 1,
						mb: 0,
					}}
				>
					<LinkOffIcon sx={{ fontSize: 36 }} />
					<Typography variant="h6">Missing Entity4 account link</Typography>
					<Stack direction="row" spacing={2} sx={{ pt: 2 }}>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleOpenIgnore}
							data-stonlyid={stonlyIds?.openIgnoreAccountModal}
						>
							Ignore Account
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleOpenLink}
							disableElevation
							data-stonlyid={stonlyIds?.openLinkAccountModal}
						>
							Link Account
						</Button>
					</Stack>
				</Box>
			)}
			<AccountEventsHistoryList account={row} />
			<LinkDialog open={openLink} handleClose={handleCloseLink} account={row} />
			<IgnoreDialog
				open={openIgnore}
				handleClose={handleCloseIgnore}
				account={row}
				handleIgnore={handleIgnore}
				isLoading={isLoading}
				stonlyIds={stonlyIds}
			/>
		</Stack>
	);
};

export default DetailPanelContent;

interface IgnoreDialogProps {
	open: boolean;
	handleClose: () => void;
	account: Cash4Account;
	handleIgnore: () => void;
	isLoading: boolean;
	stonlyIds?: { [key: string]: string };
}

const IgnoreDialog: React.FC<IgnoreDialogProps> = ({
	open,
	handleClose,
	account,
	handleIgnore,
	isLoading,
	stonlyIds,
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			data-stonlyid={stonlyIds?.ignoreAccountModal}
		>
			<DialogTitle>Ignore Cash4 Account</DialogTitle>
			<DialogContent>
				<Typography variant="body1">
					<p>
						Ignoring this account means that it will no longer display in the
						Cash4 module, for past and future data imports.
					</p>
					<p>
						Please confirm that you want to ignore this account now and moving
						forward.
					</p>
					<p>
						This action can be undone by creating an account link from the
						'Ignored Accounts' page.
					</p>
				</Typography>
				<BlueBox account={account} />
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="primary"
					disabled={isLoading}
					onClick={handleClose}
					data-stonlyid={stonlyIds?.cancelButtonIgnoreDialog}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					disabled={isLoading}
					onClick={handleIgnore}
					disableElevation
					data-stonlyid={stonlyIds?.ignoreAccountButton}
				>
					Ignore Account
					{isLoading && (
						<CircularProgress
							size={24}
							sx={{
								color: 'primary',
								position: 'absolute',
								top: '50%',
								left: '50%',
								marginTop: '-12px',
								marginLeft: '-12px',
							}}
						/>
					)}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
