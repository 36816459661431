import { Alert, Box, Collapse, Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../../../shared/hooks/useUser';
import { EntityRepository } from '../../../entities/entityRepository';
import { getFieldSetSortingAlgorithm } from '../../../entities/factories/fieldSetSortingFactory';
import { EntityCollection } from '../../../entities/objects/entityCollection';
import { EntityType } from '../../../entity4Constants';
import { useProfileView } from '../../../entityProfile/providers/entityProfileContextProvider';
import { CreateFieldSetButton } from '../createFieldSetButton';
import { FieldSetViewProps } from '../fieldSetResolver';
import { getFieldView } from '../fieldViewResolver';
import { FieldDescription } from '../fieldViews/common/fieldDescription';
import { FieldViews } from '../fieldViews/fieldViewTypes';

export const CollectionFieldSetView: FC<FieldSetViewProps> = observer(
	({ fieldSetDefinition, entity }) => {
		const { entityType, entityId } = useParams<{
			entityType: EntityType;
			entityId: string;
		}>();
		const { viewType } = useProfileView();
		const { isAuthor } = useUser();
		const [entityRepository] = useState(
			new EntityRepository(entityType, entityId),
		);
		function getFields(
			collection: EntityCollection,
			entityRepository: EntityRepository,
		) {
			const fieldSetKey = entity.id + collection.id;
			return {
				key: fieldSetKey,
				elements: fieldSetDefinition.fields.map((field) => {
					const View = getFieldView(field);
					const fieldData = collection.getField(field.id);
					const key =
						entity.id + collection?.id + fieldSetDefinition.name + field.name;
					return (
						<View
							key={key}
							fieldDefinition={field}
							fieldLayoutProps={{
								excludeDivider: true,
								descriptionSpacing: fieldSetDefinition.isFromToCollection
									? 0
									: 0.75,
							}}
							fieldData={fieldData}
							createField={(value?: string) => {
								entityRepository
									.v2CreateCollectionField(
										collection.id,
										field.identifier,
										value ?? null,
									)
									.then((data) => {
										collection.addCollectionField(data);
									});
							}}
						/>
					);
				}),
			};
		}

		let sortingAlgorithm = getFieldSetSortingAlgorithm(fieldSetDefinition.name);
		const fieldSets = sortingAlgorithm.sort(
			entity.getCollectionEntries(fieldSetDefinition.name),
		);
		let entries = fieldSets.map((x) => getFields(x, entityRepository));

		var fieldSetError = '';

		const editable = isAuthor && viewType === FieldViews.edit;

		return (
			<>
				<FieldDescription description={fieldSetDefinition.description} />
				<Box paddingY={4}>
					<Divider />
				</Box>

				{editable ? (
					<>
						<CreateFieldSetButton
							fieldSetDefinition={fieldSetDefinition}
							entity={entity}
						/>
						<Box paddingY={4}>
							<Divider />
						</Box>
					</>
				) : null}

				<Collapse in={Boolean(fieldSetError)}>
					<Box paddingBottom={2}>
						<Alert color={'error'}>{fieldSetError}</Alert>
					</Box>
				</Collapse>

				{entries.map((entry, index) => (
					<Box
						key={`$container-${entry.key}`}
						paddingBottom={index >= entries.length - 1 ? 2 : 0}
					>
						{entry.elements}
						{index < entries.length - 1 ? (
							<Box paddingTop={2} paddingBottom={3}>
								<Divider />
							</Box>
						) : null}
					</Box>
				))}
			</>
		);
	},
);
