import NotFoundImage from '../../images/404-error-v1.jpg';
import AccessDeniedImage from '../../images/access-denied.jpg';
import ErrorLogo from '../../images/orgchart_error.jpg';
import { paths } from './paths';

export const RETURN_TO_HOME = 'Return to Home';
const RETURN_TO_E4_DASHBOARD = 'Return to Entity4 Dashboard';

export const ACCESS_DENIED_MESSAGING = {
	HEADING: 'Access Denied',
	BODY: 'You do not have the correct permissions to view this page. Please contact your administrator for assistance.',
	BUTTON_TEXT: RETURN_TO_HOME,
	BUTTON_HREF: paths.root.href,
	IMAGE: AccessDeniedImage,
};

export const COMING_SOON_MESSAGING = {
	HEADING: 'Coming Soon',
	BODY: 'Our developers are currently hard at work making progress on this feature! Please contact your administrator if you have any questions.',
	BUTTON_TEXT: RETURN_TO_HOME,
	BUTTON_HREF: paths.root.href,
	IMAGE: AccessDeniedImage,
};

export const NOT_FOUND_MESSAGING = {
	HEADING: '404 Not Found',
	BODY: 'There was an error finding the URL. Please check the URL and try again. If you continue to see this error, contact our Customer Success team at support@treasury4.com.',
	BUTTON_TEXT: RETURN_TO_E4_DASHBOARD,
	BUTTON_HREF: paths.entity4.href,
	IMAGE: NotFoundImage,
};

export const ACCOUNT_MAP_ERROR_MESSAGING = {
	HEADING: 'Unable to Load Account Map',
	BODY: 'There was an error loading the account map. Please refresh the page to try again. If you continue to see this error, contact our Customer Success team at support@treasury4.com.',
	BUTTON_TEXT: RETURN_TO_E4_DASHBOARD,
	BUTTON_HREF: paths.entity4.href,
	IMAGE: ErrorLogo,
};

export const ORG_CHART_ERROR_MESSAGING = {
	HEADING: 'Unable to Load Org Chart',
	BODY: 'There was an error loading the org chart. Please refresh the page to try again. If you continue to see this error, contact our Customer Success team at support@treasury4.com.',
	BUTTON_TEXT: RETURN_TO_E4_DASHBOARD,
	BUTTON_HREF: paths.entity4.href,
	IMAGE: ErrorLogo,
};
