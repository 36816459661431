import { AxiosInstance } from 'axios';
import { Read, T4ApiResponse } from 'modules/clients/types';

export type FinancialInstitution = {
	financialInstitutionId: string;
	displayName: string | null;
	bankCode: string | null;
	clientTransportCertificateName: string | null;
	clientSigningCertificateName: string | null;
	isWirePaymentsEnabled: boolean;
};

export type FinancialInstitutionEndpoints = {
	getAll: Read<T4ApiResponse<FinancialInstitution[]>>;
};

export function financialInstitutions(
	axiosInstance: AxiosInstance,
): FinancialInstitutionEndpoints {
	return {
		getAll: async (_, config = {}) => {
			return await axiosInstance.get('financialinstitutions', { ...config });
		},
	};
}
