import { OptionDto } from 'features/entity4/shared/fieldSets/FieldRepository';
import { flow, makeAutoObservable } from 'mobx';
import { Moment } from 'moment';
import { convertDate, formatDate } from 'shared/utilities/dateUtilities';
import { cleanString } from 'utilities/stringUtils';
import {
	DocumentDto,
	GlobalDocumentsRepository,
} from '../shared/globalDocumentsRepository';

export class Document {
	private _data: DocumentDto;
	private _form: DocumentDto;

	private _repository: GlobalDocumentsRepository;

	constructor(
		document: DocumentDto,
		documentRepository: GlobalDocumentsRepository,
	) {
		makeAutoObservable(this);

		this._data = document;
		this._form = document;

		this._repository = documentRepository;
	}

	public getData(): DocumentDto {
		return { ...this._data };
	}

	public getName(): string | undefined {
		return this._form.name;
	}

	public getType(): OptionDto | undefined {
		return this._form.type;
	}

	public getDescription(): string | undefined {
		return this._form.description;
	}

	public getStatus(): string | undefined {
		return this._form.status;
	}

	public getSignedDate(): Moment | null {
		return convertDate(this._form.signedDate);
	}

	public getExpirationDate(): Moment | null {
		return convertDate(this._form.expirationDate);
	}

	public getEffectiveFromDate(): Moment | null {
		return convertDate(this._form.effectiveFrom);
	}

	public getEffectiveToDate(): Moment | null {
		return convertDate(this._form.effectiveTo);
	}

	public getExternalUrl(): string | undefined {
		return this._form.externalUrl;
	}

	public getUploadedBy(): string {
		return this._form.uploadedBy;
	}

	public getUploadedDate(): Moment {
		return convertDate(this._form.uploadedDate)!;
	}

	public getFileSize(): string {
		return this._form.fileSize;
	}

	public getFileType(): string {
		return this._form.fileType;
	}

	public getUpdatedDate(): Date {
		return this._data.updatedDate;
	}

	public setName(name: string | null | undefined): void {
		this._form.name = name ?? undefined;
	}

	public setType(type: OptionDto | null | undefined): void {
		this._form.type = type ?? undefined;
	}

	public setDescription(description: string | null | undefined): void {
		this._form.description = description ?? undefined;
	}

	public setStatus(status: string | null | undefined): void {
		this._form.status = status ?? undefined;
	}

	public setSignedDate(signedDate: Moment | null | undefined): void {
		this._form.signedDate = formatDate(signedDate);
	}

	public setExpirationDate(expirationDate: Moment | null | undefined): void {
		this._form.expirationDate = formatDate(expirationDate);
	}

	public setEffectiveFrom(effectiveFrom: Moment | null | undefined): void {
		this._form.effectiveFrom = formatDate(effectiveFrom);
	}

	public setEffectiveTo(effectiveTo: Moment | null | undefined): void {
		this._form.effectiveTo = formatDate(effectiveTo);
	}

	public setExternalUrl(externalUrl: string | null | undefined): void {
		this._form.externalUrl = externalUrl ?? undefined;
	}

	public update = flow(function* (this: Document) {
		const updatedDocument = yield this._repository.updateDocument(
			this._data.id,
			{
				name: cleanString(this._form.name)?.trim(),
				type: this._form.type,
				description: cleanString(this._form.description),
				status: cleanString(this._form.status),
				signedDate: this._form.signedDate,
				expirationDate: this._form.expirationDate,
				effectiveFrom: this._form.effectiveFrom,
				effectiveTo: this._form.effectiveTo,
				externalUrl: cleanString(this._form.externalUrl),
			},
		);

		this._data = updatedDocument;
		this._form = updatedDocument;
	});
}
