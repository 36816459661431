import { makeAutoObservable, runInAction } from 'mobx';

export class StoreOperation<TInput, TData> {
	public loading: boolean = false;
	public error?: string;
	public data?: TData;
	private operation: (input: TInput) => Promise<TData>;
	constructor(operation: (input: TInput) => Promise<TData>) {
		makeAutoObservable(this);
		this.operation = operation;
	}

	public async execute(input: TInput) {
		this.loading = true;
		this.data = undefined;

		try {
			const result = await this.operation(input);
			runInAction(() => {
				this.data = result;
			});
			return result;
		} catch (e) {
			runInAction(() => {
				this.error = e as string;
			});

			// rethrow error for downstream catching
			throw e;
		} finally {
			runInAction(async () => {
				this.loading = false;
			});
		}
	}
}
