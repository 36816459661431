import { AxiosInstance } from 'axios';
import { Read, Write } from 'modules/clients/types';

//#region Read Api Models

export type SigmaWorkbook = {
	id: string;
	folderId: string;
	urlId: string;
	name: string;
	description: string;
	permission: string;
	updatedAt: string;
};

export type SigmaFolder = {
	id: string;
	name: string;
	permission: string;
};

export type SigmaFileEntry = {
	id: string;
	urlId: string;
	name: string;
	description: string;
	type: string;
	parentId: string;
	parentUrlId: string;
	permission: string;
	path: string;
	badge: string;
	createdBy: string;
	updatedBy: string;
	createdAt: string;
	updatedAt: string;
	isArchived: boolean;
	embeds: SigmaEmbedEntry[];
};

export type SigmaEmbedEntry = {
	embedId: string;
	embedUrl: string;
	public: boolean;
};

//#endregion

//#region Write Api Models

export type SigmaWorkbookReq = {
	folderId: string;
	name: string;
	description: string;
	folderName: string;
};

export type GetSigmaEmbedReq = {
	workbookId: string;
	workbookUrlId: string;
	embedMode: string;
};

export type SigmaFolderReq = {
	name: string;
};

export type DeleteSigmaFileReq = {
	id: string;
};

export type ShareSigmaWorkbookReq = {
	id: string;
	copy: boolean;
	permissions: string;
	name: string;
	folder: string;
};

export type EditSigmaFileReq = {
	fileId: string;
	name: string;
	description: string;
	parentId: string;
};

export type EditSigmaFolderReq = {
	fileId: string;
	name: string;
};

export type MoveSigmaWorkbookReq = {
	fileId: string;
	folderId: string;
};

export type SigmaUserReq = {
	userId: string;
};

//#endregion

export type SigmaEmbedEndpoints = {
	loadAllThingsSigma: Read<{
		memberId: string;
		sigmaWorkbooks: SigmaWorkbook[];
		solutionFolders: SigmaFolder[];
	}>;
	workbookEmbeds: Write<SigmaWorkbook[], SigmaUserReq>;
	createReport: Write<SigmaWorkbook, SigmaWorkbookReq>;
	sigmaUrl: Write<string, GetSigmaEmbedReq>;
	getFolders: Write<SigmaFolder[], SigmaUserReq>;
	createFolder: Write<SigmaFolder, SigmaFolderReq>;
	deleteFile: Write<boolean, DeleteSigmaFileReq>;
	shareFile: Write<SigmaWorkbook, ShareSigmaWorkbookReq>;
	editFile: Write<SigmaFileEntry, EditSigmaFileReq>;
	recentlyDeleted: Write<string, SigmaUserReq>;
	editFolder: Write<SigmaFileEntry, EditSigmaFolderReq>;
	moveWorkbook: Write<SigmaWorkbook, MoveSigmaWorkbookReq>;
	getUser: Read<string>;
};

export function sigmaEmbed(axiosInstance: AxiosInstance): SigmaEmbedEndpoints {
	return {
		loadAllThingsSigma: async () =>
			await axiosInstance.get<{
				memberId: string;
				sigmaWorkbooks: SigmaWorkbook[];
				solutionFolders: SigmaFolder[];
			}>('/sigmaembed/loadallthingssigma'),
		getUser: async () =>
			await axiosInstance.get<string>('/sigmaembed/sigmaUser'),
		workbookEmbeds: async (req) =>
			await axiosInstance.post<SigmaWorkbook[]>(
				'/sigmaembed/workbookembeds',
				req,
			),
		createReport: async (req) =>
			await axiosInstance.post<SigmaWorkbook>('/sigmaembed/createreport', req),
		sigmaUrl: async (req) =>
			await axiosInstance.post<string>('/sigmaembed/sigmaurl', req),
		getFolders: async (req) =>
			await axiosInstance.post<SigmaFolder[]>('/sigmaembed/getfolders', req),
		createFolder: async (req) =>
			await axiosInstance.post<SigmaFolder>('/sigmaembed/createfolder', req),
		deleteFile: async (req) =>
			await axiosInstance.post<boolean>('/sigmaembed/deletefile', req),
		shareFile: async (req) =>
			await axiosInstance.post<SigmaWorkbook>('/sigmaembed/sharefile', req),
		editFile: async (req) =>
			await axiosInstance.post<SigmaFileEntry>('/sigmaembed/editfile', req),
		recentlyDeleted: async (req) =>
			await axiosInstance.post<string>('/sigmaembed/recentlydeleted', req),
		editFolder: async (req) =>
			await axiosInstance.post<SigmaFileEntry>('/sigmaembed/editfolder', req),
		moveWorkbook: async (req) =>
			await axiosInstance.post<SigmaWorkbook>('/sigmaembed/moveworkbook', req),
	};
}
