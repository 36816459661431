import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
	GridColDef,
	GridRowClassNameParams,
	GridRowModel,
} from '@mui/x-data-grid-pro';
import { columnDefinitionNames } from 'features/entity4/entity4Constants';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import {
	FrontendGroupRepository,
	GroupMemberDto,
	GroupRepository,
	GroupRequestDto,
} from '../groupRepository';
import { SingleGroupMemberRequestApprovalModel } from '../shared/singleGroupRequestApprovalModel';
import { SingleGroupRequestApprovalViewModel } from '../shared/singleGroupRequestApprovalViewModel';
import {
	getIconApprovalColor,
	getTooltipText,
	isModifier,
} from '../utilities/approvalUtils';
import { GroupMembersListModel } from './groupMembersListModel';
import { ManageGroupMembersViewModel } from './manageGroupMembersButtonViewModel';

export const MemberPendingCSSClass = 'member-pending-request';

export class GroupMembersListViewModel {
	private _groupId: string;
	private _groupRepository: GroupRepository;
	private _userId: string;

	private listModel: GroupMembersListModel;

	public readonly manageMembersViewModel: ManageGroupMembersViewModel;
	public readonly manageRequestViewModel: SingleGroupRequestApprovalViewModel;

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
		groupRepository: GroupRepository,
		userId: string,
	) {
		this._groupId = groupId;
		this._groupRepository = groupRepository;
		this._userId = userId;
		this.listModel = new GroupMembersListModel(
			groupId,
			frontendGroupRepository,
		);

		this.manageMembersViewModel = new ManageGroupMembersViewModel(
			groupId,
			frontendGroupRepository,
			groupRepository,
		);
		this.manageRequestViewModel = new SingleGroupRequestApprovalViewModel();
	}

	public isLoading() {
		return this.listModel.loading;
	}

	public getMemberListLoading = () => {
		return this.listModel.loading;
	};

	public getMemberListError = () => {
		return isStringUndefinedOrNullOrWhitespace(this.listModel.error)
			? undefined
			: this.listModel.error;
	};

	public getMemberListColumns = (): GridColDef[] => {
		return [
			{
				field: columnDefinitionNames.hasPendingChanges,
				headerName: '',
				width: 50,
				sortable: true,
				hideSortIcons: false,
				filterable: false,
				disableExport: true,
				align: 'center',

				renderCell: this.pendingChangesRenderCell,
				valueGetter: (params: any) => params.row.request,
			},
			{
				field: 'email',
				headerName: 'Email',
				flex: 10,
				minWidth: 200,
			},
			{
				...getDateColumnDefinition(),
				field: 'lastLogin',
				headerName: 'Last Login',
				flex: 10,
				minWidth: 200,
			},
		];
	};

	public isRowPending = (params: GridRowClassNameParams<any>) => {
		return params.row.request ? MemberPendingCSSClass : '';
	};

	public pendingChangesRenderCell = (params: any) => {
		if (!params.row.request) {
			return '';
		}

		const isRequester = isModifier(this._userId, params.row.request);
		const tooltipText = getTooltipText(isRequester, params.row.request);
		const iconApprovalColor = getIconApprovalColor(
			isRequester,
			params.row.request,
		);

		return (
			<Tooltip title={tooltipText}>
				<IconButton
					onClick={() => {
						// Don't allow the modifier to open the modal to approve/reject their own changes.
						if (isRequester) {
							return;
						}

						this.manageRequestViewModel.openModal(
							new SingleGroupMemberRequestApprovalModel(
								this._groupId,
								params.row as GroupMemberDto,
								this._groupRepository,
							),
						);
					}}
				>
					{params.row.request.type === 'Addition' ? (
						<AddCircle
							sx={{ color: iconApprovalColor }}
							aria-label={tooltipText}
						/>
					) : (
						<RemoveCircle
							color="error"
							sx={{ color: iconApprovalColor }}
							aria-label={tooltipText}
						/>
					)}
				</IconButton>
			</Tooltip>
		);
	};

	public getMemberListRows = (): GridRowModel[] => {
		if (this.manageMembersViewModel.isSuccessfulSubmit()) {
			this.manageMembersViewModel.resetSuccessfulSubmit();
			this.loadMembers();
		}

		if (this.manageRequestViewModel.isSuccessfulSubmit()) {
			this.manageRequestViewModel.resetSuccessfulSubmit();
			this.loadMembers();
		}

		return this.listModel.groupMembers.map((member) => {
			return {
				id: member.id,
				email: member.email,
				lastLogin: member.lastLogin,
				request: member.request,
			};
		});
	};

	public loadMembers = async () => {
		await this.listModel.load();
	};

	private isTheOriginalCreator = (request: GroupRequestDto): boolean => {
		return request.createdBy === this._userId;
	};
}
