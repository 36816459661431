import { Divider, MenuItem } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { useUser } from 'shared/hooks/useUser';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useRegistrationNavigation } from '../../hooks/useRegistrationNavigation';
import { DeleteRegistrationViewModel } from '../models/deleteRegistrationViewModel';
import { RegistrationListItem } from '../../frontendRegistrationRepository';

export const testIds = {
	overflowMenuButton: 'overflow-menu',
	viewDetails: 'view-details',
	editDetails: 'edit-details',
	divider: 'divider',
	delete: 'delete',
};

export interface RegistrationOptionsCellProps
	extends GridRenderCellParams<RegistrationListItem> {
	deleteRegistrationViewModel: DeleteRegistrationViewModel;
}
export const RegistrationOptionsCell: FC<RegistrationOptionsCellProps> =
	observer(({ row, deleteRegistrationViewModel }) => {
		const { isAuthor } = useUser();
		const navigate = useRegistrationNavigation();

		return (
			<OverflowMenu
				id={`registration-overflow-menu-${row.id}`}
				iconButtonProps={{
					'data-testid': testIds.overflowMenuButton,
				}}
			>
				<MenuItem
					data-testid={testIds.viewDetails}
					onClick={() => {
						navigate(
							deleteRegistrationViewModel.entityId,
							row.id,
							FieldViews.default,
						);
					}}
				>
					View Details
				</MenuItem>
				{isAuthor && (
					<MenuItem
						data-testid={testIds.editDetails}
						onClick={() => {
							navigate(
								deleteRegistrationViewModel.entityId,
								row.id,
								FieldViews.edit,
							);
						}}
					>
						Edit Details
					</MenuItem>
				)}
				{isAuthor && <Divider data-testid={testIds.divider} />}
				{isAuthor && (
					<DeleteMenuItem
						data-testid={testIds.delete}
						onClick={() => deleteRegistrationViewModel.openModal(row.id)}
					/>
				)}
			</OverflowMenu>
		);
	});
