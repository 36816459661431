export enum FieldDataType {
	text,
	boolean,
	date,
	options,
	phoneNumber,
	longText,
}
export interface IFieldSetData {
	id: string;
	identifier: string;
	description: string;
	displayName: string;
	category: IFieldSetCategory;
	subcategory: IFieldSetSubcategory;
	isInternalEntityField: boolean;
	isCounterpartyField: boolean;
	isStaffField: boolean;
	isPartnerField: boolean;
	isAccountField: boolean;
	sortOrder: number;
	fields: IFieldData[];
	allowMany: boolean;
	isFromToCollection: boolean;
	isDefaultListColumn: boolean;
	isDefaultApprovalListColumn: boolean;
}

export class FieldSet {
	private readonly fieldSetData: IFieldSetData;
	public fields: Field[];
	constructor(fieldSetData: IFieldSetData) {
		this.fieldSetData = fieldSetData;
		this.fields = fieldSetData.fields
			.map((x) => new Field(x))
			.sort((a, b) => a.sortOrder - b.sortOrder);
	}

	get id(): string {
		return this.fieldSetData.id;
	}

	get identifier(): string {
		return this.fieldSetData.identifier;
	}

	get isFromToCollection(): boolean {
		return this.fieldSetData.isFromToCollection;
	}

	get isCollectionField(): boolean {
		return this.fieldSetData.isFromToCollection || this.fieldSetData.allowMany;
	}

	get name(): string {
		return this.fieldSetData.identifier;
	}

	get creationFields(): Field[] {
		return this.fields.filter((x) => x.isCreationRequirement);
	}

	get displayName(): string {
		return this.fieldSetData.displayName;
	}

	get description(): string {
		return this.fieldSetData.description;
	}

	get sortOrder(): number {
		return this.fieldSetData.sortOrder;
	}

	get allowMany(): boolean {
		return this.fieldSetData.allowMany;
	}

	get isDefaultListColumn(): boolean {
		return this.fieldSetData.isDefaultListColumn;
	}

	get isDefaultApprovalListColumn(): boolean {
		return this.fieldSetData.isDefaultApprovalListColumn;
	}

	public getFieldDefinition(fieldIdentifier: string) {
		return this.fields.find((x) => x.id === fieldIdentifier);
	}
}

export interface IFieldSetCategory {
	id: string;
	name: string;
	sortOrder: number;
}

export interface IFieldSetSubcategory extends IFieldSetCategory {}

export interface IFieldData {
	id: string;
	identifier: string;
	parentFieldIdentifier?: string;
	metadata: IFieldMetadata;
	sortOrder: number;
}

export class Field {
	private readonly fieldData: IFieldData;

	constructor(fieldData: IFieldData) {
		this.fieldData = fieldData;
	}

	get fieldType(): FieldDataType {
		return this.fieldData.metadata.fieldType;
	}

	get identifier(): string {
		return this.fieldData.identifier;
	}

	get parentFieldIdentifier(): string | undefined {
		return this.fieldData.parentFieldIdentifier;
	}

	get description(): string {
		return this.fieldData.metadata.description;
	}

	get name(): string {
		return this.fieldData.metadata.displayName;
	}

	get metadata(): IFieldMetadata {
		return this.fieldData.metadata;
	}

	get isCreationRequirement(): boolean {
		return this.fieldData.metadata.isCreationRequirement;
	}

	get id(): string {
		return this.fieldData.identifier;
	}

	get requiresApproval(): boolean {
		return this.fieldData.metadata.requiresApproval;
	}

	get referenceCollection(): string | undefined {
		return this.fieldData.metadata.referenceCollection;
	}

	get optionListId(): string | undefined {
		return this.fieldData.metadata.optionListId;
	}

	get hasPredefinedValues(): boolean {
		return Boolean(this.fieldData.metadata.referenceCollection);
	}

	get sortOrder(): number {
		return this.fieldData.sortOrder;
	}

	get validationCriterion(): IValidationCriterion | undefined {
		return this.fieldData.metadata.validationCriterion;
	}
}

export interface IFieldMetadata {
	id: string;
	fieldType: FieldDataType;
	isRequired: boolean;
	description: string;
	isCreationRequirement: boolean;
	displayName: string;
	requiresApproval: boolean;
	referenceCollection?: string;
	optionListId?: string;
	validationCriterion?: IValidationCriterion;
}

export interface IValidationCriterion {
	validationRegex: string;
	errorDescription: string;
}

export interface ICategorizedFieldSets {
	category: IFieldSetCategory;
	subCategories: {
		subcategory: IFieldSetSubcategory;
		fieldSets: FieldSet[];
	}[];
}

export interface IListFieldSet {
	headerName: string;
	isListColumn: boolean;
	isApprovalListColumn: boolean;
	isCollectionField: boolean;
	hasReferenceData: boolean;
	contract: FieldDataContract;
	fieldType: FieldDataType;
	isCreationRequirement: boolean;
	requiresApproval: boolean;

	referenceCollectionName?: string;
	optionListId?: string;
	showPending?: boolean;
	parentFieldIdentifier?: string;
}

export class FieldDataContract {
	public readonly fieldSetIdentifier: string;
	public readonly fieldIdentifier: string;

	constructor(fieldSetIdentifier: string, fieldIdentifier: string) {
		this.fieldSetIdentifier = fieldSetIdentifier;
		this.fieldIdentifier = fieldIdentifier;
	}
}
