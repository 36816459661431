import { FormControlLabel, Radio, RadioProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type VisualizationRadioProps = RadioProps & {
	label?: string;
	value?: unknown;
};

export const VisualizationRadio: FC<VisualizationRadioProps> = observer(
	({ children, label, value, ...rest }) => (
		<FormControlLabel
			key={rest?.key}
			value={value}
			control={
				<Radio
					{...rest}
					sx={{
						...rest?.sx,
						padding: '0.25rem',
					}}
				/>
			}
			label={
				label ? (
					<Typography sx={{ fontSize: '0.875rem' }}>{label}</Typography>
				) : undefined
			}
			sx={{
				margin: 0,
			}}
		/>
	),
);
