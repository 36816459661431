import { Grid, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { usePartiesQuery } from 'features/cash4/_queries/usePartiesQuery';
import { useT4Query } from 'features/cash4/_utilities/useT4Query';
import { useDataContext } from 'features/cash4/rules/createRuleModal/providers/DataProvider';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4DateField } from 'features/entity4/shared/components/atoms/t4DateField';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import {
	CashFlowDetail,
	GeneralLedgerDetail,
	Party,
	PartyAccount,
	ProjectedItem,
	ProjectedItemReq,
} from 'modules/clients/customer-api/src/api/cash4';
import { T4DataResponse } from 'modules/clients/types';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { DrawerCancelationModal } from 'shared/components/drawer/drawerCancelationModal';
import { T4NumberInput } from 'shared/components/t4NumberInput';
import { useClients } from 'shared/hooks/useClients';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { stonlyData } from 'stonly/functions';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';

const stonlyIds = {
	submitButton: 'create-projected-item-submit-button',
	cancelButton: 'create-projected-item-cancel-button',
	detailsSection: 'create-projected-item-details-section',
	initiatorSection: 'create-projected-item-initiator-section',
	payeeSection: 'create-projected-item-payee-section',
	categorizationSection: 'create-projected-item-categorization-section',
};

export type ProjectedItemForm = {
	initiatorType?: string;
	initiatorObject?: Party;
	initiatorAccount?: PartyAccount;
	payeeType?: string;
	payeeObject?: Party;
	payeeAccount?: PartyAccount;
	label?: string;
	currencyCode?: string;
	amount?: number;
	expectedValueDate?: Date;
	checkNumber?: string;
	description?: string;
	class?: CashFlowDetail;
	type?: CashFlowDetail;
	subtype?: CashFlowDetail;
	glDetail?: GeneralLedgerDetail;
	glCode?: string;
};

export type ProjectedItemDrawerProps = {
	isOpen: boolean;
	projectedItem?: ProjectedItem;
	onClose: () => void;
	onSubmit?: () => void;
};

export const ProjectedItemDrawer: FC<ProjectedItemDrawerProps> = observer(
	({ isOpen, projectedItem, onClose, onSubmit: onDrawerSubmit }) => {
		const { customerApiClient } = useClients();
		const { fetch } = useReferenceDataFetcher();
		const { enqueueSnackbar } = useSnackbar();
		const {
			categories,
			types,
			subtypes,
			glCodes,
			isLoadingGlCodes,
			refetchGlCodes,
		} = useDataContext();

		//#region Queries

		const { loading: loadingParties, data: parties } = usePartiesQuery();

		const getCurrencyCodes = useCallback(async () => {
			const response = await fetch({
				referenceListNames: ['FunctionalCurrency'],
			});

			return (
				response?.referenceLists['FunctionalCurrency'].map((x) => x.value) ?? []
			);
		}, [fetch]);
		const { loading: loadingCurrencyCodes, data: currencyCodes } =
			useT4Query(getCurrencyCodes);

		//#endregion

		//#region State

		const { handleSubmit, control, reset, formState, watch, setValue } =
			useForm<ProjectedItemForm>({
				defaultValues: {
					currencyCode: 'USD',
				},
			});

		const initiatorType = watch('initiatorType');
		const initiatorObject = watch('initiatorObject');
		const initiatorAccount = watch('initiatorAccount');
		const payeeType = watch('payeeType');
		const payeeObject = watch('payeeObject');
		const payeeAccount = watch('payeeAccount');
		const amount = watch('amount');
		const currencyCode = watch('currencyCode');
		const expectedValueDate = watch('expectedValueDate');
		const classValue = watch('class');
		const typeValue = watch('type');
		const subtypeValue = watch('subtype');
		const glDetail = watch('glDetail');
		const glCode = watch('glCode');

		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [isCancellationModalOpen, setIsCancellationModalOpen] =
			useState<boolean>(false);

		const cashFlowClasses = useMemo<CashFlowDetail[]>(() => {
			return categories.map((x) => ({
				id: x.id,
				code: x.code,
				name: x.name,
			}));
		}, [categories]);

		const cashFlowTypes = useMemo<CashFlowDetail[]>(() => {
			if (classValue) {
				return types(classValue.id)?.map((x) => ({
					id: x.id,
					code: x.code,
					name: x.name,
				}));
			}

			return [];
		}, [classValue, types]);

		const cashFlowSubtypes = useMemo(() => {
			if (typeValue) {
				return subtypes(typeValue.id)?.map((x) => ({
					id: x.id,
					code: x.code,
					name: x.name,
				}));
			}

			return [];
		}, [typeValue, subtypes]);

		const mappedGlCodes = useMemo<GeneralLedgerDetail[]>(() => {
			return glCodes.map((x) => ({
				id: x.id!,
				code: x.code!,
			}));
		}, [glCodes]);

		const canSave = useMemo(
			() =>
				initiatorType &&
				payeeType &&
				(initiatorType === 'Entity' || payeeType === 'Entity') &&
				(initiatorAccount === undefined ||
					initiatorAccount?.id !== payeeAccount?.id) &&
				(initiatorType !== 'Entity' ||
					(initiatorObject !== undefined && initiatorAccount !== undefined)) &&
				(payeeType !== 'Entity' ||
					(payeeObject !== undefined && payeeAccount !== undefined)) &&
				amount !== undefined &&
				currencyCode !== undefined &&
				expectedValueDate !== undefined &&
				moment(expectedValueDate).isValid() &&
				((classValue !== undefined && typeValue !== undefined) ||
					(classValue === undefined && typeValue === undefined)),
			[
				amount,
				classValue,
				currencyCode,
				expectedValueDate,
				initiatorAccount,
				initiatorObject,
				initiatorType,
				payeeAccount,
				payeeObject,
				payeeType,
				typeValue,
			],
		);

		const handleDrawerClose = useCallback(() => {
			onClose();
			setIsLoading(false);
			reset({
				currencyCode: 'USD',
			});
		}, [onClose, reset]);

		const onSubmit = useCallback(
			async (data: ProjectedItemForm) => {
				if (
					canSave &&
					data.amount !== undefined &&
					data.currencyCode &&
					data.expectedValueDate
				) {
					try {
						setIsLoading(true);

						const reqeustData: ProjectedItemReq = {
							amount: data.amount ?? 0,
							currencyCode: data.currencyCode!,
							expectedValueDate: data.expectedValueDate
								.toISOString()
								.split('T')?.[0],
							label: data.label,
							description: data.description,
							checkNumber: data.checkNumber,
							categorization:
								data.class && data.type
									? {
											classId: data.class?.id,
											typeId: data.type?.id,
											subtypeId: data.subtype?.id,
											glCode: data.glDetail
												? data.glDetail
												: {
														id: undefined,
														code: data.glCode,
												  },
									  }
									: undefined,
							initiator: {
								partyType: data.initiatorType ?? '',
								entityId: data.initiatorObject?.id,
								accountId: data.initiatorAccount?.id,
							},
							payee: {
								partyType: data.payeeType ?? '',
								entityId: data.payeeObject?.id,
								accountId: data.payeeAccount?.id,
							},
						};

						let response:
							| AxiosResponse<T4DataResponse<string>, ProjectedItemReq>
							| undefined;
						if (projectedItem) {
							response = await customerApiClient.api.cash4.updateProjected(
								projectedItem?.id,
								reqeustData,
							);
						} else {
							response = await customerApiClient.api.cash4.createProjected(
								reqeustData,
							);
						}

						if (response.data.success !== undefined && !response.data.success) {
							throw new Error('Failed response.');
						}

						handleDrawerClose();
						onDrawerSubmit?.();
						enqueueSnackbar('Projected Item created successfully!', {
							variant: 'success',
						});
					} catch (error) {
						enqueueSnackbar(
							'An unexpected error occurred and we were unable to create projected item. Please try again later.',
							{
								variant: 'error',
							},
						);
					} finally {
						refetchGlCodes();
						setIsLoading(false);
					}
				}
			},
			[
				canSave,
				projectedItem,
				handleDrawerClose,
				onDrawerSubmit,
				enqueueSnackbar,
				customerApiClient.api.cash4,
				refetchGlCodes,
			],
		);

		// #endregion

		useEffect(() => {
			if (isOpen) {
				if (projectedItem && !loadingParties && parties) {
					setValue('amount', projectedItem.amount);
					setValue('currencyCode', projectedItem.currencyCode);
					setValue('expectedValueDate', new Date(projectedItem.date));
					setValue('label', projectedItem.label);
					setValue('description', projectedItem.description);
					setValue('checkNumber', projectedItem.checkNumber);
					setValue('initiatorType', projectedItem.initiator.type);
					setValue(
						'initiatorObject',
						parties?.find((x) => x.id === projectedItem.initiator.object?.id),
					);
					setValue(
						'initiatorAccount',
						parties
							?.find((x) => x.id === projectedItem.initiator.object?.id)
							?.accounts?.find(
								(x) => x.id === projectedItem.initiator.account?.id,
							),
					);
					setValue('payeeType', projectedItem.payee.type);
					setValue(
						'payeeObject',
						parties?.find((x) => x.id === projectedItem.payee.object?.id),
					);
					setValue(
						'payeeAccount',
						parties
							?.find((x) => x.id === projectedItem.payee.object?.id)
							?.accounts?.find((x) => x.id === projectedItem.payee.account?.id),
					);

					const foundCashFlowClass = categories.find(
						(x) => x.id === projectedItem.categorization?.class?.id,
					);
					const foundCashFlowType = foundCashFlowClass?.types?.find(
						(x) => x.id === projectedItem.categorization?.type?.id,
					);
					const foundCashFlowSubtype = foundCashFlowType?.subtypes?.find(
						(x) => x.id === projectedItem.categorization?.subtype?.id,
					);
					setValue(
						'class',
						foundCashFlowClass
							? {
									id: foundCashFlowClass.id,
									code: foundCashFlowClass.code,
									name: foundCashFlowClass.name,
							  }
							: undefined,
					);
					setValue(
						'type',
						foundCashFlowType
							? {
									id: foundCashFlowType.id,
									code: foundCashFlowType.code,
									name: foundCashFlowType.name,
							  }
							: undefined,
					);
					setValue(
						'subtype',
						foundCashFlowSubtype
							? {
									id: foundCashFlowSubtype.id,
									code: foundCashFlowSubtype.code,
									name: foundCashFlowSubtype.name,
							  }
							: undefined,
					);
					setValue(
						'glDetail',
						mappedGlCodes.find(
							(x) => x.id === projectedItem.categorization?.glCode?.id,
						),
					);
				}
			} else {
				setValue('amount', undefined);
				setValue('currencyCode', 'USD');
				setValue('expectedValueDate', undefined);
				setValue('label', undefined);
				setValue('description', undefined);
				setValue('checkNumber', undefined);
				setValue('initiatorType', undefined);
				setValue('initiatorObject', undefined);
				setValue('initiatorAccount', undefined);
				setValue('payeeType', undefined);
				setValue('payeeObject', undefined);
				setValue('payeeAccount', undefined);
				setValue('class', undefined);
				setValue('type', undefined);
				setValue('subtype', undefined);
				setValue('glDetail', undefined);
				setValue('glCode', undefined);
			}
		}, [
			categories,
			glCodes,
			isOpen,
			loadingParties,
			mappedGlCodes,
			parties,
			projectedItem,
			reset,
			setValue,
			subtypes,
			types,
		]);

		return (
			<T4DrawerBase
				open={isOpen}
				initializing={loadingParties || loadingCurrencyCodes}
				loading={isLoading}
				title={`${projectedItem ? 'Edit' : 'Create'} projected item`}
				onClose={() => {
					if (formState.isDirty) {
						setIsCancellationModalOpen(true);
					} else {
						handleDrawerClose();
					}
				}}
				actions={[
					<DrawerCancelButton
						stonlyId={stonlyIds.cancelButton}
						onCancel={() => {
							if (formState.isDirty) {
								setIsCancellationModalOpen(true);
							} else {
								handleDrawerClose();
							}
						}}
					/>,
					<DrawerSubmitButton
						stonlyId={stonlyIds.submitButton}
						label={projectedItem ? 'Save' : 'Create'}
						onSubmit={handleSubmit(onSubmit)}
						disabled={!canSave || isLoading}
					/>,
				]}
			>
				<Grid container item xs={12} spacing={1}>
					<Grid
						container
						item
						xs={12}
						spacing={1}
						{...stonlyData({ id: stonlyIds.initiatorSection })}
					>
						<Grid item xs={12}>
							<Typography variant="h4">Initiator</Typography>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="initiatorType"
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4Autocomplete<string>
										id="initiatorType"
										label="Initiator type"
										options={['Entity', 'Partner', 'Counterparty'].filter(
											(x) => {
												if (payeeType !== undefined && payeeType !== 'Entity') {
													return x === 'Entity';
												}

												return true;
											},
										)}
										value={initiatorType ?? null}
										onChange={(_, newValue) => {
											if (value !== newValue) {
												onChange(newValue ?? undefined);
												setValue('initiatorObject', undefined);
												setValue('initiatorAccount', undefined);
											}
										}}
										error={!!error}
										helperText={error && error.message}
										required
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="initiatorObject"
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4Autocomplete<Party>
										id="initiator"
										loading={loadingParties}
										label="Initiator name"
										options={
											parties
												?.filter(
													(x) =>
														x.type === initiatorType &&
														(x.type !== 'Entity' ||
															(x.accounts?.length ?? 0) > 0),
												)
												?.sort((a, b) =>
													(a.name ?? '').localeCompare(b.name ?? ''),
												) ?? []
										}
										value={initiatorObject ?? null}
										onChange={(_, newValue) => {
											onChange(newValue ?? undefined);
											if (value?.id !== newValue?.id) {
												setValue('initiatorAccount', undefined);
											}
										}}
										isOptionEqualToValue={(option, value) =>
											option?.id === value?.id
										}
										getOptionLabel={(option) => option.name}
										error={!!error}
										helperText={error && error.message}
										required={initiatorType === 'Entity'}
										readOnly={!initiatorType}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="initiatorAccount"
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4Autocomplete<PartyAccount>
										id="initiator-account"
										loading={loadingParties}
										label="Initiator account"
										options={
											initiatorObject?.accounts?.filter(
												(x) => x.id !== payeeAccount?.id,
											) ?? []
										}
										value={initiatorAccount ?? null}
										onChange={(_, newValue) => {
											onChange(newValue);
											if (value?.id !== newValue?.id) {
												setValue(
													'currencyCode',
													newValue?.currencyCode ?? 'USD',
												);
											}
										}}
										isOptionEqualToValue={(option, value) =>
											option?.id === value?.id
										}
										getOptionLabel={(option) => option.name}
										error={!!error}
										helperText={error && error.message}
										required={initiatorType === 'Entity'}
										readOnly={!initiatorObject}
									/>
								)}
							/>
						</Grid>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							{...stonlyData({ id: stonlyIds.payeeSection })}
						>
							<Grid item xs={12}>
								<Typography variant="h4">Payee</Typography>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="payeeType"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4Autocomplete<string>
											id="payeeType"
											label="Payee type"
											options={['Entity', 'Partner', 'Counterparty'].filter(
												(x) => {
													if (
														initiatorType !== undefined &&
														initiatorType !== 'Entity'
													) {
														return x === 'Entity';
													}

													return true;
												},
											)}
											value={payeeType ?? null}
											onChange={(_, newValue) => {
												if (value !== newValue) {
													onChange(newValue ?? undefined);
													setValue('payeeObject', undefined);
													setValue('payeeAccount', undefined);
												}
											}}
											error={!!error}
											helperText={error && error.message}
											required
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="payeeObject"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4Autocomplete<Party>
											id="payee"
											loading={loadingParties}
											label="Payee name"
											options={
												parties
													?.filter(
														(x) =>
															x.type === payeeType &&
															(x.type !== 'Entity' ||
																(x.accounts?.length ?? 0) > 0),
													)
													?.sort((a, b) =>
														(a.name ?? '').localeCompare(b.name ?? ''),
													) ?? []
											}
											value={payeeObject ?? null}
											onChange={(_, newValue) => {
												onChange(newValue);
												if (value?.id !== newValue?.id) {
													setValue('payeeAccount', undefined);
												}
											}}
											isOptionEqualToValue={(option, value) =>
												option?.id === value?.id
											}
											getOptionLabel={(option) => option.name}
											error={!!error}
											helperText={error && error.message}
											required={payeeType === 'Entity'}
											readOnly={!payeeType}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="payeeAccount"
									control={control}
									render={({ field: { onChange }, fieldState: { error } }) => (
										<T4Autocomplete<PartyAccount>
											id="payee-account"
											loading={loadingParties}
											label="Payee account"
											options={
												payeeObject?.accounts?.filter(
													(x) => x.id !== initiatorAccount?.id,
												) ?? []
											}
											value={payeeAccount ?? null}
											onChange={(_, newValue) => {
												onChange(newValue);
											}}
											isOptionEqualToValue={(option, value) =>
												option?.id === value?.id
											}
											getOptionLabel={(option) => option.name}
											error={!!error}
											helperText={error && error.message}
											required={payeeType === 'Entity'}
											readOnly={!payeeObject}
										/>
									)}
								/>
							</Grid>
						</Grid>

						<Grid
							container
							item
							xs={12}
							spacing={1}
							{...stonlyData({ id: stonlyIds.detailsSection })}
						>
							<Grid item xs={12}>
								<Typography variant="h4">Details</Typography>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="label"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4TextFieldV2
											id="projected-item-label"
											label="Label"
											value={value ?? ''}
											onChange={(value: string) => {
												if (isStringUndefinedOrNullOrWhitespace(value)) {
													onChange(undefined);
												} else {
													onChange(value);
												}
											}}
											error={!!error}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Controller
									name="amount"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4NumberInput
											id="projected-item-amount"
											label="Amount"
											value={value ?? ''}
											onChange={(event) =>
												onChange(
													event.target.value?.length > 0
														? event.target.value.replaceAll(',', '')
														: undefined,
												)
											}
											decimalScale={2}
											error={!!error}
											required
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6}>
								<Controller
									name="currencyCode"
									control={control}
									render={({ field: { onChange }, fieldState: { error } }) => (
										<T4Autocomplete<string, false, true>
											id="currency-code"
											loading={loadingCurrencyCodes}
											label="Currency code"
											options={currencyCodes ?? []}
											value={currencyCode}
											onChange={(_, value) => onChange(value)}
											error={!!error}
											helperText={error && error.message}
											disableClearable
											required
											readOnly={
												initiatorAccount === null || payeeAccount === null
											}
											disabled={
												initiatorAccount === null || payeeAccount === null
											}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="expectedValueDate"
									control={control}
									render={({ field: { onChange, value } }) => (
										<T4DateField
											id="projected-item-expected-value-date"
											label="Expected value date"
											value={value ? moment(value) : null}
											onChange={(value) => onChange(value?.toDate())}
											required
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="checkNumber"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4TextFieldV2
											id="check-number"
											label="Check number"
											value={value ?? ''}
											onChange={(value) => {
												if (isStringUndefinedOrNullOrWhitespace(value))
													onChange(null);
												else onChange(value);
											}}
											error={!!error}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="description"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4TextFieldV2
											id="notes"
											label="Notes"
											value={value ?? ''}
											onChange={(value: string) => {
												if (isStringUndefinedOrNullOrWhitespace(value)) {
													onChange(undefined);
												} else {
													onChange(value);
												}
											}}
											minRows={4}
											maxRows={4}
											multiline
											error={!!error}
											helperText={`${value?.length ?? 0}/2048${
												error?.message ? ' ' + error.message : ''
											}`}
											inputProps={{
												maxLength: 2048,
											}}
										/>
									)}
								/>
							</Grid>
						</Grid>
						<Grid
							container
							item
							xs={12}
							spacing={1}
							{...stonlyData({ id: stonlyIds.categorizationSection })}
						>
							<Grid item xs={12}>
								<Typography variant="h4">Categorization</Typography>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="class"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4Autocomplete<CashFlowDetail>
											id="cash-flow-class"
											label="Cash flow class (CFC)"
											options={cashFlowClasses}
											value={classValue ?? null}
											onChange={(_, newValue) => {
												if (value?.id !== newValue?.id) {
													onChange(newValue ?? undefined);
													setValue('type', undefined);
													setValue('subtype', undefined);
												}

												if (newValue === null || newValue === undefined) {
													setValue('glDetail', undefined);
													setValue('glCode', undefined);
												}
											}}
											isOptionEqualToValue={(option, value) =>
												option.id === value.id
											}
											getOptionLabel={(option) => option.name}
											error={!!error}
											helperText={error && error.message}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="type"
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4Autocomplete<CashFlowDetail>
											id="cash-flow-type"
											label="Cash flow type (CFT)"
											options={cashFlowTypes}
											value={typeValue ?? null}
											onChange={(_, newValue) => {
												if (newValue?.id !== value?.id) {
													onChange(newValue ?? undefined);
													setValue('subtype', undefined);
												}

												if (newValue === null || newValue === undefined) {
													setValue('glDetail', undefined);
													setValue('glCode', undefined);
												}
											}}
											isOptionEqualToValue={(option, value) =>
												option.id === value.id
											}
											getOptionLabel={(option) => option.name}
											error={!!error}
											helperText={error && error.message}
											readOnly={!classValue}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="subtype"
									control={control}
									render={({ field: { onChange }, fieldState: { error } }) => (
										<T4Autocomplete<CashFlowDetail>
											id="cash-flow-subtype"
											label="Cash flow subtype (CFST)"
											options={cashFlowSubtypes}
											value={subtypeValue ?? null}
											onChange={(_, newValue) =>
												onChange(newValue ?? undefined)
											}
											isOptionEqualToValue={(option, value) =>
												option.id === value.id
											}
											getOptionLabel={(option) => option.name}
											error={!!error}
											helperText={error && error.message}
											readOnly={!typeValue}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<Controller
									name="glCode"
									control={control}
									render={({ field: { onChange } }) => (
										<T4Autocomplete<GeneralLedgerDetail, false, false, true>
											loading={isLoadingGlCodes}
											label="GL code"
											options={mappedGlCodes}
											isOptionEqualToValue={(option, value) =>
												option?.id === value?.id
											}
											getOptionLabel={(option) =>
												typeof option === 'string' ? option : option.code!
											}
											value={glDetail ?? glCode ?? null}
											inputValue={glCode ?? ''}
											onInputChange={(_, value) => {
												const foundGlCode = mappedGlCodes?.find(
													(x) => x.code === value,
												);
												if (foundGlCode) {
													onChange(foundGlCode.code ?? undefined);
													setValue('glDetail', foundGlCode ?? undefined);
												} else {
													onChange(value);
													setValue('glDetail', undefined);
												}
											}}
											onChange={(_, value) => {
												if (typeof value !== 'string') {
													onChange(undefined);
													setValue('glDetail', value ?? undefined);
												}
											}}
											readOnly={!typeValue}
											freeSolo
											autoSelect
											autoHighlight={false}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<DrawerCancelationModal
					isOpen={isCancellationModalOpen}
					resourceType="projected item"
					variant={projectedItem ? 'edit' : 'create'}
					onClose={() => setIsCancellationModalOpen(false)}
					onSubmit={() => handleDrawerClose()}
				/>
			</T4DrawerBase>
		);
	},
);
