import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { CannotDisplay } from '../../../shared/components/cannotDisplay';
import { ACCESS_DENIED_MESSAGING } from '../../../shared/constants/cannotDisplayMessaging';
import ConnectedSecurityGroupConfiguration from './securityGroups/securityGroupConfiguration';

export const ConfigurationPage: FC = observer(() => {
	const { isAdmin } = useUser();
	if (!isAdmin) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.configurationPage}
					title="Configurations"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
		>
			<ConnectedSecurityGroupConfiguration />
		</T4View>
	);
});
