import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	Grid,
} from '@mui/material';
import { FC, ReactNode } from 'react';

export interface CustomModalProps extends Partial<DialogProps> {
	title: string;
	open: boolean;
	actions: ReactNode[];
	children?: ReactNode;
}
export const CustomModal: FC<CustomModalProps> = ({
	title,
	open,
	children,
	actions,
	...props
}) => {
	return (
		<Dialog {...props} fullWidth open={open}>
			<DialogTitle>{title}</DialogTitle>
			{children && (
				<DialogContent>
					<Box
						sx={{
							paddingTop: '0.25rem',
						}}
					>
						{children}
					</Box>
				</DialogContent>
			)}
			<DialogActions
				sx={{
					paddingX: '1.5rem',
					paddingBottom: '1.5rem',
				}}
			>
				<Grid
					container
					columnSpacing={2}
					sx={{
						justifyContent: 'flex-end',
					}}
				>
					{actions.map((action, index) => (
						<Grid item key={`action-item-${index}`}>
							{action}
						</Grid>
					))}
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
