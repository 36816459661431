import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LoginError } from 'features/entity4/shared/loginError';
import { FC, ReactNode } from 'react';
import LoadingScreen from 'shared/components/loading-screen';
import { useApplication } from 'shared/hooks/useApplication';
import { T4StonlyWidgetProvider } from 'shared/providers/t4StonlyWidgetProvider';

export type ApplicationGateProps = {
	children: ReactNode;
};

export const ApplicationGate: FC<ApplicationGateProps> = ({ children }) => {
	const { isLoading, isAuthenticated, error } = useApplication();

	if (isLoading) {
		return <LoadingScreen />;
	}

	if (error || !isAuthenticated) {
		return <LoginError />;
	}

	return (
		<LocalizationProvider dateAdapter={DateAdapter}>
			<T4StonlyWidgetProvider />
			{children}
		</LocalizationProvider>
	);
};
