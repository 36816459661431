import { Collapse, Grid, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { ObjectType } from 'modules/clients/customer-api/src/api/objects';
import { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import { useApplicationConfiguration } from 'shared/hooks/useApplicationConfigurations';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { T4Alert } from '../../shared/components/atoms/t4Alert';
import { T4Chip, T4ChipProps } from '../../shared/components/atoms/t4Chip';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { T4Object } from '../object/models/t4Object';
import { SensitiveFieldProvider } from '../object/providers/sensitiveFieldProvider';
import {
	getObjectInputType,
	isPendingApproval,
	isRejected,
} from '../object/utilities';
import { EntityFieldApprovalButtons } from './entityFieldApprovalButtons';
import { RejectFieldModal } from './rejectFieldModal';
import { SensitiveField } from './sensitiveField';

export type EntityFieldProps = {
	object: T4Object;
	field: Field;
	approvalField: ReactElement;
	pendingField: ReactElement;
};

export const EntityFieldView: FC<EntityFieldProps> = observer(
	({ object, field, approvalField, pendingField, ...props }) => {
		const { viewType } = useProfileView();
		const { id: userId, isStaffUser } = useUser();
		const { approvalConfiguration } = useApplicationConfiguration();
		const { customerApiClient } = useClients();

		const [isRejectFieldModalOpen, setIsRejectFieldModalOpen] =
			useState<boolean>(false);
		const [showField, setShowField] = useState<boolean>(
			!field.isPII || !isStaffUser,
		);
		const [hasRequestedSensitiveFieldData, setHasRequestedSensitiveFieldData] =
			useState<boolean>(false);
		const objectField = useMemo(
			() => object?.fields?.find((x) => x.identifier === field.identifier),
			[
				field.identifier,
				object?.fields,
				// please leave this so we can calculate the correct values
				// eslint-disable-next-line react-hooks/exhaustive-deps
				object?.fields?.find((x) => x.identifier === field.identifier),

				// eslint-disable-next-line react-hooks/exhaustive-deps
				object?.fields?.find((x) => x.identifier === field.identifier)
					?.pendingValue,

				// eslint-disable-next-line react-hooks/exhaustive-deps
				object?.fields?.find((x) => x.identifier === field.identifier)
					?.approvedValue,
			],
		);
		const showApprovalUi = useMemo<boolean>(
			() =>
				field.requiresApproval &&
				viewType !== FieldViews.default &&
				approvalConfiguration.isActive,
			[approvalConfiguration.isActive, field.requiresApproval, viewType],
		);
		const showReviewButtons = useMemo<boolean>(
			() =>
				objectField !== undefined &&
				field.requiresApproval &&
				isPendingApproval(objectField, field) &&
				viewType === FieldViews.review,

			// please leave this here so we can calculate the correct values
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[
				objectField,
				field,
				viewType,
				objectField?.submittedDate,
				objectField?.rejectedDate,
				objectField?.rejectedComment,
			],
		);
		const rejectFieldModalTitle = useMemo(
			() => `Rejecting ${objectField?.pendingValue} as ${field.name}`,
			[objectField?.pendingValue, field.name],
		);
		const approvalChipText = useMemo<string>(() => {
			if (objectField && isRejected(objectField, field)) {
				return 'Rejected Approval';
			}
			if (objectField && isPendingApproval(objectField, field)) {
				return 'Pending Approval';
			}

			return 'Approval Field';

			// Please leave this so we can calucate correct values
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			objectField,
			field,
			objectField?.pendingValue,
			objectField?.rejectedDate,
			objectField?.rejectedComment,
		]);
		const appovalChipColor = useMemo<T4ChipProps['t4ChipColor']>(() => {
			if (objectField && isRejected(objectField, field)) {
				return 'red';
			}
			if (objectField && isPendingApproval(objectField, field)) {
				return 'gold';
			}

			return 'blue';

			// Please leave this so we can calucate correct values
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			objectField,
			field,
			objectField?.pendingValue,
			objectField?.submittedDate,
			objectField?.rejectedDate,
			objectField?.rejectedComment,
		]);

		const approveField = useCallback(async () => {
			if (object && objectField) {
				const isSubaccount = object.objectType === ObjectType.subaccount;
				const objectInput = isSubaccount
					? {
							objectType: getObjectInputType(object.parentObjectType!),
							objectId: object.parentObjectId!,
							subaccountId: object.id,
					  }
					: {
							objectType: getObjectInputType(object.objectType),
							objectId: object.id,
							subaccountId: undefined!,
					  };

				try {
					const approve = isSubaccount
						? customerApiClient.api.objects.subaccounts.fields.approve
						: customerApiClient.api.objects.fields.approve;

					const response = await approve({
						...objectInput,
						fieldId: objectField.id,
					});

					if (response?.data?.value) {
						const fieldIndex = object.fields.findIndex(
							(x) => x.id === objectField.id,
						);
						if (fieldIndex >= 0) {
							object.fields[fieldIndex].update(response.data.value);
						}
					}
				} catch {}
			}
		}, [
			object,
			objectField,
			customerApiClient.api.objects.subaccounts.fields.approve,
			customerApiClient.api.objects.fields.approve,
		]);

		const rejectField = useCallback(
			async (comment: string) => {
				if (object && objectField && (comment?.length ?? 0) > 0) {
					const isSubaccount = object.objectType === ObjectType.subaccount;
					const objectInput = isSubaccount
						? {
								objectType: getObjectInputType(object.parentObjectType!),
								objectId: object.parentObjectId!,
								subaccountId: object.id,
						  }
						: {
								objectType: getObjectInputType(object.objectType),
								objectId: object.id,
								subaccountId: undefined!,
						  };

					try {
						const reject = isSubaccount
							? customerApiClient.api.objects.subaccounts.fields.reject
							: customerApiClient.api.objects.fields.reject;
						const response = await reject({
							...objectInput,
							fieldId: objectField.id,
							comment: comment,
						});

						if (response?.data?.value) {
							const fieldIndex = object.fields.findIndex(
								(x) => x.id === objectField.id,
							);
							if (fieldIndex >= 0) {
								object.fields[fieldIndex].update(response.data.value);
							}
						}
					} catch {}
				}
			},
			[
				object,
				objectField,
				customerApiClient.api.objects.subaccounts.fields.reject,
				customerApiClient.api.objects.fields.reject,
			],
		);

		const fieldComponent = useMemo(
			() => (
				<Stack spacing={1}>
					{approvalField}
					{showApprovalUi && pendingField}
					{showReviewButtons && (
						<EntityFieldApprovalButtons
							disabled={objectField?.updatedBy === userId}
							onApprove={() => approveField()}
							onReject={() => setIsRejectFieldModalOpen(true)}
						/>
					)}
				</Stack>
			),
			[
				approvalField,
				approveField,
				objectField?.updatedBy,
				pendingField,
				showApprovalUi,
				showReviewButtons,
				userId,
			],
		);

		return (
			<SensitiveFieldProvider
				isSensitive={field.isPII && isStaffUser}
				hasRequestedSensitiveFieldData={hasRequestedSensitiveFieldData}
				showField={showField}
				setShowField={setShowField}
				setHasRequestedSensitiveFieldData={setHasRequestedSensitiveFieldData}
			>
				<Grid {...props} container sx={{ gap: 1 }}>
					{showApprovalUi && showField && (
						<Grid item xs={12}>
							<Collapse in={showApprovalUi}>
								<Grid container rowSpacing={2}>
									<Grid item xs={12}>
										<T4Chip
											t4ChipColor={appovalChipColor}
											label={approvalChipText}
										/>
									</Grid>
									{(objectField?.rejectedComment?.length ?? 0) > 0 && (
										<Grid item xs={12}>
											<T4Alert title="Rejected" severity="error">
												{objectField?.rejectedComment}
											</T4Alert>
										</Grid>
									)}
								</Grid>
							</Collapse>
						</Grid>
					)}
					<Grid item xs={12}>
						{!showField ? (
							<SensitiveField
								id={field.id}
								label={field.name}
								description={field.description}
								variant={
									viewType === FieldViews.default ? 'standard' : 'outlined'
								}
								onShowField={() => setShowField(!showField)}
							/>
						) : (
							fieldComponent
						)}
					</Grid>
					<RejectFieldModal
						title={rejectFieldModalTitle}
						open={isRejectFieldModalOpen}
						onClose={() => setIsRejectFieldModalOpen(false)}
						onReject={(comment: string) => {
							rejectField(comment).finally(() => {
								setIsRejectFieldModalOpen(false);
							});
						}}
					/>
				</Grid>
			</SensitiveFieldProvider>
		);
	},
);
