import { Button, Grid, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { T4RejectButton } from '../../shared/styleComponents/t4Components';

interface IEntityFieldApprovalButtonsProps {
	onApprove: () => void;
	onReject: () => void;
	disabled: boolean;
}

export const EntityFieldApprovalButtons: React.FC<IEntityFieldApprovalButtonsProps> = observer(
	({ onApprove, onReject, disabled }) => {
		return (
			<Grid container item sm={12} alignItems="center">
				<Grid container item sm={12} spacing={2}>
					<Tooltip
						title={disabled ? 'You cannot approve your own changes' : undefined}
					>
						<Grid item sm={3}>
							<Button
								fullWidth
								variant="contained"
								size="small"
								color="primary"
								onClick={() => onApprove()}
								data-testid="approve-button"
								disabled={disabled}
							>
								Approve
							</Button>
						</Grid>
					</Tooltip>
					<Tooltip
						title={disabled ? 'You cannot reject your own changes' : undefined}
					>
						<Grid item sm={3}>
							<T4RejectButton
								fullWidth
								variant="contained"
								size="small"
								color="primary"
								onClick={() => onReject()}
								data-testid="reject-button"
								disabled={disabled}
							>
								Reject
							</T4RejectButton>
						</Grid>
					</Tooltip>
				</Grid>
			</Grid>
		);
	}
);
