export const dataTestIds = {
	createRuleButton: 'create-rule-button',
	addConditionButton: 'add-condition',
	removeConditionButton: 'remove-condition-button',
};

export const stonlyIds = {
	createRuleButton: 'create-rule-button',
	addConditionButton: 'add-condition',
	removeConditionButton: 'remove-condition-button',
};
