import { ConnectionIndex } from 'modules/clients/customer-api/src/cash4/connections';
import { Connector } from 'modules/clients/customer-api/src/cash4/connectors';
import { useState } from 'react';
import { isValid } from 'shared/components/formModal';
import { useClients } from 'shared/hooks/useClients';
import { FormConnectionParameter } from '../components/connectionForm';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { flattenErrors } from 'utilities/errors/errorUtils';

export type UseEditConnectionProps = {
	loading: boolean;
	error: string | undefined;
	errors: string[];
	disableSubmit: boolean;
	isDirty: boolean;
	connectors: Connector[];
	connector: Connector | undefined;
	connectionName: string;
	connectionParameters: FormConnectionParameter[];
	open: (connectionIndex: ConnectionIndex | undefined) => Promise<void>;
	close: () => void;
	update: () => Promise<void>;
	setConnectionName: (name: string | undefined) => void;
	setConnectionParameter: (key: string, value: string | undefined) => void;
};

export function useEditConnectionProps(
	onClose: () => void,
): UseEditConnectionProps {
	const { customerApiClient } = useClients();

	const [loading, _setLoading] = useState(false);
	const [error, _setError] = useState<string | undefined>();
	const [errors, _setErrors] = useState<string[]>([]);

	const [connectionIndex, _setConnectionIndex] = useState<
		ConnectionIndex | undefined
	>();
	const [connectionName, _setConnectionName] = useState<string>('');
	const [parameters, _setParameters] = useState<FormConnectionParameter[]>([]);
	const [initialParameters, _setInitialParameters] = useState<
		FormConnectionParameter[]
	>([]);

	async function update(): Promise<void> {
		if (_isFormValid() && connectionIndex) {
			_setLoading(true);
			_setError(undefined);
			_setErrors([]);

			try {
				const response =
					await customerApiClient.cash4.connections.updateConnection({
						connectionId: connectionIndex.id,
						name: connectionName,
						parameters: Object.assign(
							{},
							...parameters
								.slice()
								.map((x) => ({ [x.key]: x.value?.trim() ?? null })),
						),
					});

				if (response.data.success) {
					close();
				} else if (response.status === 500) {
					throw new Error();
				} else if (response.data.error) {
					_setError(response.data.error);
				} else if (
					response.data.errors &&
					Object.entries(response.data.errors).length > 0
				) {
					_setErrors(flattenErrors(response.data.errors));
				} else {
					throw new Error();
				}
			} catch (error: any) {
				_setError('Unable to update connection.');
			} finally {
				_setLoading(false);
			}
		}
	}

	async function open(
		connectionIndex: ConnectionIndex | undefined,
	): Promise<void> {
		if (connectionIndex) {
			_setConnectionIndex(connectionIndex ?? undefined);
			_setConnectionName(connectionIndex.name);

			if (connectionIndex.credentialVerification === undefined) {
				_setError('An unexpected error occurred.');
			} else if (connectionIndex.credentialVerification.success === false) {
				_setError(connectionIndex.credentialVerification.errorMessage);
			}

			_setLoading(true);
			_setError(undefined);
			_setErrors([]);

			try {
				const response =
					await customerApiClient.cash4.connections.getConnection({
						connectionId: connectionIndex.id,
					});

				if (response.data.success) {
					const connectorParameters = response.data.data.connector.parameters;
					const params: FormConnectionParameter[] = connectorParameters.map(
						(x) => ({
							id: x.id,
							key: x.key,
							value:
								response.data.data.parameters.find((y) => y.key === x.key)
									?.value ?? null,
							isValueOptional: x.isValueOptional,
						}),
					);
					_setParameters(params);
					_setInitialParameters(params);
				} else if (response.status === 500) {
					throw new Error();
				} else if (response.data.error) {
					_setError(response.data.error);
				} else if (
					response.data.errors &&
					Object.entries(response.data.errors).length > 0
				) {
					_setErrors(flattenErrors(response.data.errors));
				} else {
					throw new Error();
				}
			} catch (error: any) {
				_setError('Unable to retrive connection.');
			} finally {
				_setLoading(false);
			}
		}
	}

	function close(): void {
		onClose();
		_setLoading(false);
		_setError(undefined);
		_setErrors([]);
		_setConnectionIndex(undefined);
		_setConnectionName('');
		_setParameters([]);
		_setInitialParameters([]);
	}

	function setConnectionName(name: string | undefined): void {
		_setConnectionName(name ?? '');
	}

	function setConnectionParameter(
		key: string,
		value: string | undefined,
	): void {
		const index = parameters.findIndex((x) => x.key === key);
		if (index !== -1) {
			const newParameters = parameters.slice();
			newParameters[index].value = !isStringUndefinedOrNullOrWhitespace(value)
				? value!
				: null;
			_setParameters(newParameters);
		}
	}

	function isDirty(): boolean {
		if (
			connectionIndex === undefined ||
			parameters.length !== initialParameters.length
		) {
			return false;
		}
		return (
			connectionName !== connectionIndex.name ||
			parameters.some((x, i) => x.value !== initialParameters[i].value)
		);
	}

	//#region Private Method(s)

	function _isFormValid(): boolean {
		return (
			isValid(connectionName) &&
			parameters
				.filter((x) => !x.isValueOptional)
				.every((x) => isValid(x.value))
		);
	}

	function _isSubmitDisabled(): boolean {
		return loading || _isFormValid() === false;
	}

	//#endregion

	return {
		loading,
		error,
		errors,
		disableSubmit: _isSubmitDisabled(),
		connectors: connectionIndex ? [connectionIndex.connector] : [],
		connector: connectionIndex?.connector,
		connectionName: connectionName,
		connectionParameters: parameters,
		isDirty: isDirty(),
		open,
		close,
		update,
		setConnectionName,
		setConnectionParameter,
	};
}
