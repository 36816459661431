export const isObjectNullOrUndefinedOrEmpty = (
	object: null | undefined | Object,
) => {
	return (
		object === undefined || object === null || Object.keys(object).length === 0
	);
};

/**
 * Function which checks equality of primitive type arrays and simple (one-level) objects
 * @param arr1 First array for comparison
 * @param arr2 Second array for comparison
 * @returns boolean value representing if both arrays are equal
 */
export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
	if (arr1.length !== arr2.length) return false;
	if (arr1.length && typeof arr1[0] !== typeof arr2[0]) return false;

	if (arr1.length && typeof arr1[0] === 'object')
		return arr1.every((value1) =>
			arr2.some((value2) =>
				Object.keys(value1).every((key) => value1[key] === value2[key]),
			),
		);
	else return arr1.every((value) => arr2.includes(value));
};

/**
 * Trims all string properties in object + nested objects + nested arrays
 *
 * NOTE: does not handle multidimensional arrays
 * @param object Object that needs trimming
 * @returns Object with trimmed string values
 */
export const trimStringsInObject = (object: Object): Object => {
	if (object) {
		try {
			const typedObject = { ...object } as { [key: string]: any };
			Object.keys(object).forEach((key) => {
				if (typeof typedObject[key] === 'string')
					typedObject[key] = (typedObject[key] as string).trim();
				else if (Array.isArray(typedObject[key])) {
					typedObject[key] = (typedObject[key] as any[]).map((x) => {
						if (typeof x === 'object') return trimStringsInObject(x);
						else if (typeof x === 'string') return x.trim();
						else return x;
					});
				} else if (typeof typedObject[key] === 'object')
					typedObject[key] = trimStringsInObject(typedObject[key]);
			});

			return typedObject;
		} catch {
			return object;
		}
	}

	return object;
};
