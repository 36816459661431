import { getPageNameFromUrl } from 'features/entity4/entity4Constants';
import { IBreadcrumb } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export type HeaderContextType = {
	lastAutoSave: Date | undefined;
	updateLastAutoSave: (autoSaveDate?: Date) => void;
	breadcrumbs: IBreadcrumb[];
	updateObjectName: (name: string) => void;
	updateSubObjectName: (name: string) => void;
};

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export const useHeader = (): HeaderContextType => {
	const context = useContext(HeaderContext);
	if (!context) {
		throw new Error('useHeader must be used within a HeaderContext');
	}

	return context;
};

export type HeaderContextProviderProps = {
	children: ReactNode;
};

export const HeaderContextProvider: FC<HeaderContextProviderProps> = observer(
	({ children }) => {
		const [lastAutoSave, setLastAutoSave] = useState<Date>();
		const [objectName, setObjectName] = useState<string>();
		const [subObjectName, setSubObjectName] = useState<string>();
		let location = useLocation();

		const updateLastAutoSave = useCallback((autoSaveDate?: Date) => {
			if (!autoSaveDate) {
				autoSaveDate = new Date();
			}
			setLastAutoSave(autoSaveDate);
		}, []);

		const updateObjectName = useCallback((name: string) => {
			setObjectName(name);
		}, []);

		const updateSubObjectName = useCallback((name: string) => {
			setSubObjectName(name);
		}, []);

		const breadcrumbs = useMemo((): IBreadcrumb[] => {
			const urlPieces = location.pathname.substring(1).split('/');
			const crumbs: IBreadcrumb[] = [];
			if (urlPieces[0] === 'entity4' && urlPieces.length > 1) {
				urlPieces.forEach((value: string, index: number) => {
					if (index === 1) {
						crumbs.push({
							label: getPageNameFromUrl(value),
							href: '/entity4/' + value,
						});
					} else if (index === 2 && objectName) {
						crumbs.push({
							label: objectName,
							href: '/' + urlPieces.slice(0, 3).join('/') + '/information',
						});
					} else if (
						index === 3 &&
						value !== 'information' &&
						value !== 'legal'
					) {
						crumbs.push({
							label: getPageNameFromUrl(value),
							href: '/' + urlPieces.slice(0, 4).join('/'),
						});
					} else if (index === 4 && subObjectName) {
						crumbs.push({
							label: subObjectName,
							href: location.pathname,
						});
					}
				});
				if (crumbs.length) {
					crumbs[crumbs.length - 1].href = '';
				}
			}
			return crumbs;
		}, [objectName, subObjectName, location.pathname]);

		return (
			<HeaderContext.Provider
				value={{
					lastAutoSave,
					updateLastAutoSave,
					breadcrumbs,
					updateObjectName,
					updateSubObjectName,
				}}
			>
				{children}
			</HeaderContext.Provider>
		);
	},
);
