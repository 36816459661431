import { Button, Grid } from '@mui/material';
import { CustomModal } from 'features/entity4/entities/components/customModal';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { stonlyData } from 'stonly/functions';
import { FieldViews } from '../../../shared/fieldSets/fieldViews/fieldViewTypes';
import { useRegistrationNavigation } from '../../hooks/useRegistrationNavigation';
import { REGISTRATION_FIELD_DESCRIPTIONS as fieldDescriptions } from '../../registrationConstants';
import { CreateRegistrationViewModel } from '../models/createRegistrationViewModel';

export const stonlyIds = {
	createButton: 'submit-button',
	cancelButton: 'cancel-button',
};

export const testIds = {
	createModal: 'create-modal',
	createButton: 'create-button',
	cancelButton: 'cancel-button',
};

export interface CreateRegistrationModalProps {
	viewModel: CreateRegistrationViewModel;
}
export const CreateRegistrationModal: FC<CreateRegistrationModalProps> =
	observer(({ viewModel }) => {
		const navigate = useRegistrationNavigation();

		return (
			<CustomModal
				data-testid={testIds.createModal}
				title="Create Registration"
				open={viewModel.isOpen}
				actions={[
					<Button
						data-testid={testIds.createButton}
						{...stonlyData({ id: stonlyIds.createButton })}
						variant="contained"
						color="primary"
						disabled={viewModel.isCreateButtonDisabled()}
						onClick={() => {
							viewModel.create().then((registrationId) => {
								if (registrationId) {
									navigate(viewModel.entityId, registrationId, FieldViews.edit);
								}
							});
						}}
					>
						Create
					</Button>,
					<Button
						data-testid={testIds.cancelButton}
						{...stonlyData({ id: stonlyIds.cancelButton })}
						color="secondary"
						onClick={() => viewModel.closeModal()}
					>
						Cancel
					</Button>,
				]}
			>
				<Grid item xs={12} sm={12}>
					<T4Autocomplete<ReferenceDataValue, false, true, false>
						label="Country"
						value={viewModel.location.countryOption ?? undefined}
						options={viewModel.location.countryOptions}
						isOptionEqualToValue={(option, value) =>
							option.value === value.value
						}
						getOptionLabel={(option) => option.displayName}
						onSelect={async (country) => {
							viewModel.location.changeCountry(country);
						}}
						onFocus={() => {
							viewModel.location.loadCountries();
						}}
						loading={viewModel.location.loadingCountries}
						startAdornment={
							<T4FieldAdornment
								description={fieldDescriptions.country}
								t4AdornmentType={'info'}
							/>
						}
						required
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<T4Autocomplete<ReferenceDataValue, false, true, false>
						label="State/Province"
						value={viewModel.location.provinceOption ?? undefined}
						options={viewModel.location.provinceOptions}
						isOptionEqualToValue={(option, value) =>
							option.value === value.value
						}
						getOptionLabel={(option) => option.displayName}
						onSelect={async (province) => {
							viewModel.location.changeProvince(province);
						}}
						loading={viewModel.location.loadingProvinces}
						startAdornment={
							<T4FieldAdornment
								description={fieldDescriptions.state}
								t4AdornmentType={'info'}
							/>
						}
					/>

					<T4AlertStack error={viewModel.location.provinceError} />
				</Grid>
				<T4AlertStack error={viewModel.error} />
			</CustomModal>
		);
	});
