/* eslint-disable mobx/missing-observer */
import { Box, Grid, Typography, createFilterOptions } from '@mui/material';
import {
	CashFlowSubtype,
	CashFlowType,
} from 'features/cash4/categories/categoriesViewModel';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import React from 'react';
import { CashFlowCategory, GLCode } from '../../models';
import { useDataContext } from '../providers/DataProvider';
import { useFormContext } from '../providers/FormProvider';
import { isValidClass, isValidType } from '../validation';

interface CategoriesProps {}

const Categories: React.FC<CategoriesProps> = () => {
	const {
		categories: classes,
		types,
		subtypes,
		isLoadingCategories,
		glCodes,
	} = useDataContext();

	const {
		cashFlowClass,
		selectClass,
		cashFlowType,
		selectType,
		cashFlowSubtype,
		selectSubtype,
		glCode,
		onInputChangeGlCode,
		onChangeGlCode,
	} = useFormContext();

	const handleChangeClass = (
		event: React.ChangeEvent<{}>,
		value: CashFlowCategory | null,
		reason: string,
	) => {
		if (reason === 'selectOption' || reason === 'clear') {
			selectType(null);
			selectSubtype(null);
		}
		selectClass(value);
	};

	const handleChangeType = (
		event: React.ChangeEvent<{}>,
		value: CashFlowType | null,
		reason: string,
	) => {
		if (reason === 'selectOption' || reason === 'clear') {
			selectSubtype(null);
		}
		selectType(value);
	};

	const handleInputChangeGlCode = (
		event: React.SyntheticEvent,
		value: string,
	) => {
		onInputChangeGlCode(event, value, glCodes);
	};

	const handleChangeGlCode = (
		event: React.SyntheticEvent,
		value: GLCode | string | null,
	) => {
		onChangeGlCode(event, value, glCodes);
	};

	return (
		<Grid container item xs={12}>
			<Grid item xs={12} padding={1}>
				<Box sx={{ bgcolor: '#e4e4e5', p: 1, pl: 2 }}>
					<Typography variant="subtitle2">
						Assign transactions in this rule to the following categories
					</Typography>
				</Box>
			</Grid>
			<Grid container item xs={12} spacing={2} paddingTop={1}>
				<Grid item xs={4}>
					<T4Autocomplete
						data-testid="cash-flow-class"
						label="Cash Flow Class (CFC)"
						options={classes}
						getOptionLabel={(option) =>
							option.name.concat(' (', option.code, ')')
						}
						loading={isLoadingCategories()}
						isOptionEqualToValue={(option, value) => option.code === value.code}
						value={cashFlowClass.field}
						onChange={handleChangeClass}
						required
						error={
							!isValidClass(cashFlowClass?.field?.id, cashFlowClass.dirty).valid
						}
						helperText={
							isValidClass(cashFlowClass?.field?.id, cashFlowClass.dirty)
								.helperText
						}
					/>
				</Grid>
				<Grid item xs={4}>
					<T4Autocomplete<CashFlowType>
						data-testid="cash-flow-type"
						label="Cash Flow Type (CFT)"
						options={
							cashFlowClass?.field?.id ? types(cashFlowClass.field.id) : []
						}
						getOptionLabel={(option) =>
							option.name.concat(' (', option.code, ')')
						}
						loading={isLoadingCategories()}
						isOptionEqualToValue={(option, value) => option.code === value.code}
						value={cashFlowType.field}
						onChange={handleChangeType}
						readOnly={cashFlowClass?.field?.id ? false : true}
						required
						error={
							!isValidType(cashFlowType?.field?.id, cashFlowType.dirty).valid
						}
						helperText={
							isValidType(cashFlowType?.field?.id, cashFlowType.dirty)
								.helperText
						}
					/>
				</Grid>
				<Grid item xs={4}>
					<T4Autocomplete<CashFlowSubtype>
						data-testid="cash-flow-subtype"
						label="Cash Flow Subtype (CFST)"
						options={
							cashFlowType?.field?.id ? subtypes(cashFlowType.field.id) : []
						}
						getOptionLabel={(option) =>
							option.name.concat(' (', option.code, ')')
						}
						loading={isLoadingCategories()}
						isOptionEqualToValue={(option, value) => option.code === value.code}
						value={cashFlowSubtype}
						onSelect={(value) => selectSubtype(value)}
						readOnly={cashFlowType.field?.id ? false : true}
					/>
				</Grid>
				<Grid item xs={4}>
					<T4Autocomplete<GLCode, false, false, true>
						data-testid="gl-code"
						label="GL Code"
						options={glCodes}
						getOptionLabel={(option) =>
							typeof option === 'string' ? option : option.code!
						}
						value={glCode}
						onInputChange={handleInputChangeGlCode}
						onChange={handleChangeGlCode}
						renderOption={(props, option) => (
							<li {...props}>
								{option.addCodeTitle ? option.addCodeTitle : option.code}
							</li>
						)}
						filterOptions={(options, params) => {
							const filter = createFilterOptions<GLCode>();
							const filtered = filter(options, params);

							const { inputValue } = params;
							const isExisting = options.some(
								(option) => inputValue === option.code,
							);
							if (inputValue !== '' && !isExisting) {
								filtered.push({
									addCodeTitle: `Add "${inputValue}"`,
									id: null,
									code: inputValue,
								});
							}

							return filtered;
						}}
						freeSolo
						autoHighlight={false}
						autoSelect
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Categories;
