import { Box, CircularProgress } from '@mui/material';
import { GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useUserPreferencesDataGridState } from 'shared/hooks/dataGrid/useUserPreferencesDataGridState';
import { CustomView } from './customView';
import { T4DataGrid, T4DataGridProps } from './dataGrid';

export type T4DataGridWrapperProps<TData extends GridValidRowModel> =
	T4DataGridProps<TData> & {
		tableKey: string;
		calculateColumnWidths?: boolean;
		sortColumns?: boolean;
		showCustomViewButton?: boolean;
	};

// eslint-disable-next-line mobx/missing-observer
export function UserPreferencesDataGrid<TData extends GridValidRowModel>({
	loading,
	showCustomViewButton = false,
	...rest
}: T4DataGridWrapperProps<TData>) {
	const _gridApiRef = useGridApiRef();
	const gridApiRef = useMemo(
		() => rest.apiRef || _gridApiRef,
		[_gridApiRef, rest.apiRef],
	);
	const {
		columnVisibilityModel,
		onColumnVisibilityModelChange,
		onColumnWidthChange,
		onFilterModelChange,
		onColumnOrderChange,
		onCustomViewCreate,
		onCustomViewDelete,
		onCustomViewSelect,
		selectedCustomView,
		customViews,
		filterModel,
		columns,
		rows,
		isLoading,
	} = useUserPreferencesDataGridState<TData>(
		gridApiRef,
		rest.tableKey,
		rest.columns,
		rest.rows,
		rest.calculateColumnWidths,
		rest.sortColumns,
		{
			columnVisibilityModel: rest.columnVisibilityModel,
			filterModel: rest.filterModel,
			density: rest.density,
		},
	);

	if (loading || isLoading) {
		return (
			<Box
				sx={{
					height: 'inherit',
					width: 'inherit',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<T4DataGrid<TData>
			{...rest}
			apiRef={gridApiRef}
			columns={columns}
			rows={rows}
			columnVisibilityModel={columnVisibilityModel}
			onColumnVisibilityModelChange={onColumnVisibilityModelChange}
			onColumnWidthChange={onColumnWidthChange}
			filterModel={filterModel}
			onFilterModelChange={onFilterModelChange}
			onColumnOrderChange={onColumnOrderChange}
			customViewButton={
				showCustomViewButton ? (
					<CustomView
						onCustomViewDelete={onCustomViewDelete}
						onCustomViewSelect={onCustomViewSelect}
						onCustomViewCreate={onCustomViewCreate}
						customViews={customViews}
						selectedCustomView={selectedCustomView}
					/>
				) : null
			}
		/>
	);
}
