import { GridValidRowModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ObjectsPathParams } from 'shared/constants/paths';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import {
	Option,
	OptionList,
	ReferenceDataValue,
} from 'shared/types/referenceDataTypes';
import { convertDate } from 'shared/utilities/dateUtilities';
import { jsonToReferenceData } from 'shared/utilities/referenceDataUtilities';
import { IFieldUpdate } from '../entities/entitiesApiTypes';
import { EntityRepository } from '../entities/entityRepository';
import {
	EntityType,
	entityTypeToEntityTypeId,
	getCodeFieldForObjectType,
} from '../entity4Constants';
import { FieldDataType } from '../shared/fieldSets/fieldTypes';
import FrontendRepository, {
	IListColumnDto,
} from '../shared/repositories/frontendRepository';

export type DeleteResult = {
	errors?: { [key: string]: string[] };
};

export type UseListPageProps = {
	objectType: EntityType;
	parentObjectId?: string;
	parentObjectType?: EntityType;

	loading: boolean;
	error: string | undefined;

	load: () => Promise<void>;
	updateField: (
		entityId: string,
		fieldIdentifier: string,
		value: any,
	) => Promise<void>;

	getReferenceList: (referenceListName: string) => ReferenceDataValue[];
	getOptionList: (optionListId: string) => Option[];

	initialColumns: IListColumnDto[];
	initialRows: GridValidRowModel[];

	creationFields: IListColumnDto[];
};

export const useListPage = (): UseListPageProps => {
	const { objectId, objectType } = useParams<
		ObjectsPathParams & {
			objectId?: string;
		}
	>();
	const location = useLocation();
	const { fetch } = useReferenceDataFetcher();

	const {
		isLoading,
		isRefetching,
		legalEntityGroupIds,
		legalEntityGroupCollections,
	} = useLegalEntityGroups();

	//#region State

	const subObjectType = useMemo(
		() =>
			location.pathname.includes('subaccounts')
				? EntityType.Subaccount
				: undefined,
		[location.pathname],
	);

	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | undefined>(undefined);
	const [initialColumns, setInitialColumns] = useState<IListColumnDto[]>([]);
	const [initialRows, setInitialRows] = useState<GridValidRowModel[]>([]);
	const [referenceLists, setReferenceLists] = useState<{
		[key: string]: ReferenceDataValue[];
	}>({});
	const [optionLists, setOptionLists] = useState<{ [key: string]: OptionList }>(
		{},
	);
	const entityRepository = useMemo(() => {
		let repository = new EntityRepository(objectType, objectId);

		if (subObjectType !== undefined) {
			repository = new EntityRepository(
				subObjectType,
				undefined,
				objectType,
				objectId,
			);
		}

		return repository;
	}, [objectId, objectType, subObjectType]);
	const creationFields = useMemo(
		() => initialColumns.filter((x) => x.isRecommendedCreationField),
		[initialColumns],
	);

	//#endregion

	//#region Lifecycles

	const load = useCallback(async () => {
		try {
			setLoading(true);
			setError(undefined);

			const listPageDto = await FrontendRepository.getListPage(
				subObjectType ?? objectType,
				objectId,
				legalEntityGroupIds,
			);

			// load referene data
			const referenceListNames = [
				...new Set(
					listPageDto.columns
						.filter((x) => x.referenceCollectionName !== undefined)
						.map((x) => x.referenceCollectionName!),
				),
			];
			const optionListIds = [
				...new Set(
					listPageDto.columns
						.filter((x) => x.optionListId !== undefined)
						.map((x) => x.optionListId!),
				),
			];

			const response = await fetch({
				objectTypeId: entityTypeToEntityTypeId.get(
					subObjectType ?? objectType,
				)!,
				referenceListNames,
				optionListIds,
			});

			if (response) {
				setReferenceLists(response.referenceLists);
				setOptionLists(response.optionLists);
			}

			// initialize data
			setInitialColumns(listPageDto.columns);
			setInitialRows(
				listPageDto.rows.map((row) => ({
					...row.fields.reduce((total: GridValidRowModel, current) => {
						const definition = listPageDto.columns.find(
							(x) => x.identifier === current.identifier,
						);

						const isCodeField =
							definition?.identifier ===
							getCodeFieldForObjectType(subObjectType ?? objectType);

						if (isCodeField) {
							total[current.identifier] =
								current.approvedStringValue ?? current.pendingStringValue;
						} else if (definition) {
							switch (definition.fieldType) {
								case FieldDataType.text:
								case FieldDataType.longText:
									total[current.identifier] = current.approvedStringValue;
									break;
								case FieldDataType.date:
									total[current.identifier] = convertDate(
										current.approvedDateValue,
									);
									break;
								case FieldDataType.boolean:
									total[current.identifier] = current.approvedBooleanValue;
									break;
								case FieldDataType.options:
									total[`${current.identifier}_data`] = current.approvedOptionId
										? ({
												id: current.approvedOptionId,
												displayName: current.displayValue,
												code: '',
										  } as Option)
										: undefined;
									total[current.identifier] = current.displayValue;
									break;
								default:
									total[current.identifier] = current.displayValue;
									break;
							}

							if (definition.referenceCollectionName) {
								const refValue = jsonToReferenceData(
									current.approvedStringValue,
								);
								total[`${current.identifier}_data`] = refValue ?? undefined;
								total[current.identifier] = refValue?.displayName;
							}
						} else {
							total[current.identifier] = current.displayValue;
						}

						return total;
					}, {} as GridValidRowModel),
					id: row.id,
					hasChanges: row.hasChanges,
					hasRejections: row.hasRejections,
					tags: row.tags,
					taxIds: row.taxIds,
					legalEntityGroups: legalEntityGroupCollections.flatMap((y) =>
						y.legalEntityGroups.filter((z) =>
							z.legalEntities.some((a) => a.id === row.id),
						),
					),
				})),
			);
		} catch (error: any) {
			setError(error);
		} finally {
			setLoading(false);
		}
	}, [
		subObjectType,
		objectType,
		objectId,
		legalEntityGroupIds,
		fetch,
		legalEntityGroupCollections,
	]);

	useEffect(() => {
		load();

		return () => {
			setLoading(false);
			setError(undefined);
			setInitialColumns([]);
			setInitialRows([]);
		};
	}, [load]);

	//#endregion

	//#region Methods

	const updateField = useCallback(
		async (entityId: string, fieldIdentifier: string, value: any) => {
			await entityRepository.updateField({
				fieldIdentifier,
				value,
				entityId,
			} as IFieldUpdate);
		},
		[entityRepository],
	);

	const getReferenceList = useCallback(
		function (referenceListName: string) {
			return referenceLists[referenceListName] ?? [];
		},
		[referenceLists],
	);

	const getOptionList = useCallback(
		function (optionListId: string) {
			return optionLists[optionListId]?.options ?? [];
		},
		[optionLists],
	);

	//#endregion

	return {
		objectType: subObjectType ?? objectType,
		parentObjectId: objectId,
		parentObjectType: subObjectType ? objectType : undefined,

		loading: loading || isLoading || isRefetching,
		error,

		load,
		updateField,

		getReferenceList,
		getOptionList,

		initialColumns,
		initialRows,

		creationFields,
	};
};
