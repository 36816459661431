import { Moment } from 'moment';
import {
	T4DateField,
	T4DateFieldProps,
} from '../shared/components/atoms/t4DateField';

export type DateFieldProps<TDateValue extends Moment> = Pick<
	T4DateFieldProps<TDateValue>,
	'id' | 'label' | 'required' | 'value'
> & {
	onChange: (value: TDateValue | null) => void;
};

// eslint-disable-next-line mobx/missing-observer
export function DateField<TDateValue extends Moment>({
	id,
	label,
	required,
	value,
	onChange,
}: DateFieldProps<TDateValue>) {
	// todo: add adornment
	return (
		<T4DateField<TDateValue>
			id={id}
			label={label}
			value={value ?? null}
			required={required}
			onChange={(value) => onChange(value)}
		/>
	);
}
