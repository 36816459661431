import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
} from 'react';

export type DarkModeContextType = {
	darkMode: boolean;
	toggleDarkMode: () => void;
};

const DarkModeContext = createContext<DarkModeContextType | undefined>(
	undefined,
);

export const useDarkMode = (): DarkModeContextType => {
	const context = useContext(DarkModeContext);
	if (!context) {
		throw new Error('useDarkMode must be used within a DarkModeContext');
	}
	return context;
};

export const DarkModeContextProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [darkMode, setDarkMode] = useState<boolean>(false);

	useEffect(() => {
		const storedTheme = localStorage.getItem('darkMode');
		setDarkMode(storedTheme === 'true');
	}, []);

	const toggleDarkMode = () => {
		const newMode = !darkMode;
		setDarkMode(newMode);
		localStorage.setItem('darkMode', String(newMode));
	};

	return (
		<DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>
			{children}
		</DarkModeContext.Provider>
	);
};
