import { FC, ReactNode, useState } from 'react';
import {
	ConfigurationsContext,
	ConfigurationsContextProps,
} from './contexts/configurationsContext';

export interface T4ConfigProviderProps {
	children: ReactNode;
}
export const T4ConfigurationsProvider: FC<T4ConfigProviderProps> = ({
	children,
}) => {
	const [context] = useState<ConfigurationsContextProps>({
		auth0Client: `${process.env.REACT_APP_AUTH0_CLIENT}`,
		auth0Token: `${process.env.REACT_APP_AUTH0_TOKEN_AUDIENCE}`,
		auth0Domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
		auth0Audience: `${process.env.REACT_APP_AUTH0_TOKEN_AUDIENCE}`,
		featureFlagKey: `${process.env.REACT_APP_FEATURE_FLAG_KEY}`,
		customerApiUrl: `${process.env.REACT_APP_CUSTOMER_API_BASE}`,
		apiGateway: {
			baseUrl: `${process.env.REACT_APP_API_GATEWAY_URL}`,
			apiSubscriptionKey: `${process.env.REACT_APP_API_GATEWAY_SUBSCRIPTION_KEY}`,
		},
		appInsights: {
			key: `${process.env.REACT_APP_APPINSIGHT_KEY || ''}`,
			connectionString: `${process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING || ''}`,
			correlationDomains: [
				`${process.env.REACT_APP_API_GATEWAY_URL?.replace(/^(https?:\/\/)/, '') || ''}`,
				`${process.env.REACT_APP_CUSTOMER_API_BASE?.replace(/^(https?:\/\/)/, '') || ''}`
			]
		},
		buildNumber: `${process.env.REACT_APP_BUILD_NUMBER}`,
	});

	return (
		<ConfigurationsContext.Provider value={context}>
			{children}
		</ConfigurationsContext.Provider>
	);
};
