import DownloadIcon from '@mui/icons-material/Download';
import {
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Paper,
	Popover,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useClients } from 'shared/hooks/useClients';

interface DownloadTemplatesPopoverProps {}

const DownloadTemplatesPopover: React.FC<
	DownloadTemplatesPopoverProps
> = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [balanceChecked, setBalanceChecked] = useState(false);
	const [transactionChecked, setTransactionChecked] = useState(false);
	const { customerApiClient } = useClients();

	const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
		handleStateClear();
	};

	const handleBalanceImportChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setBalanceChecked(event.target.checked);
	};

	const handleTransactionImportChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setTransactionChecked(event.target.checked);
	};

	const handleStateClear = () => {
		setBalanceChecked(false);
		setTransactionChecked(false);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const downloadCsvFile = (filename: string, csvContent: string) => {
		const blob = new Blob([csvContent], { type: 'text/csv' });
		const url = URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.href = url;
		anchor.download = filename;
		anchor.click();
		URL.revokeObjectURL(url);
	};

	const handleDownload = async () => {
		const selectedEndpoints = [];
		if (balanceChecked) {
			selectedEndpoints.push('balances');
		}
		if (transactionChecked) {
			selectedEndpoints.push('transactions');
		}

		// Prepare the CSV content based on the selected endpoints
		let balancesCsvContent = '';
		let transactionsCsvContent = '';
		if (balanceChecked) {
			try {
				// Make the API call to get balances columns
				const response =
					await customerApiClient.api.v1.frontend.cash4.dataImports.getCsvColumnsBalances();
				if (response?.status === 200) {
					const columnNames = response.data;
					balancesCsvContent = `${columnNames.join(',')}\n`;
				}
			} catch (error) {
				enqueueSnackbar('Error fetching csv data', { variant: 'error' });
			}
		}

		if (transactionChecked) {
			try {
				// Make the API call to get transactions columns
				const response =
					await customerApiClient.api.v1.frontend.cash4.dataImports.getCsvColumnsTransactions();
				const columnNames = response.data;
				transactionsCsvContent = `${columnNames.join(',')}\n`;
			} catch (error) {
				enqueueSnackbar('Error fetching csv data', { variant: 'error' });
			}
		}

		// Create and download the files based on the selected checkboxes
		if (balanceChecked) {
			downloadCsvFile('balances.csv', balancesCsvContent);
		}

		if (transactionChecked) {
			downloadCsvFile('transactions.csv', transactionsCsvContent);
		}
		handlePopoverClose();
	};

	return (
		<>
			<Button
				variant="outlined"
				startIcon={<DownloadIcon />}
				onClick={handlePopoverOpen}
			>
				Download Templates
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Paper sx={{ p: 2 }}>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									checked={balanceChecked}
									onChange={handleBalanceImportChange}
								/>
							}
							label="Balance Import Template"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={transactionChecked}
									onChange={handleTransactionImportChange}
								/>
							}
							label="Transaction Import Template"
						/>
					</FormGroup>
					<Button
						variant="contained"
						color="primary"
						onClick={handleDownload}
						disabled={!(balanceChecked || transactionChecked)}
					>
						Download
					</Button>
				</Paper>
			</Popover>
		</>
	);
};

export default DownloadTemplatesPopover;
