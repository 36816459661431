export class CacheMap<TId, TResult> {
	private _map: Map<TId, Promise<TResult>> = new Map();
	private _repoMethod: (id: TId) => Promise<TResult>;

	constructor(repoMethod: (id: TId) => Promise<TResult>) {
		this._repoMethod = repoMethod;
	}

	public async retrieve(id: TId, force = false): Promise<TResult> {
		if (!force && this.has(id)) {
			// Should never be undefined, because it .has()
			return this._map.get(id)!;
		}

		let resultPromise: Promise<TResult>;
		resultPromise = this._repoMethod(id).catch((error) => {
			throw error;
		});
		this._map.set(id, resultPromise);
		return resultPromise;
	}

	public has(id: TId) {
		return this._map.has(id);
	}

	public replaceSync(id: TId, value: TResult) {
		this._map.set(id, Promise.resolve(value));
	}

	public async remove(id: TId) {
		this._map.delete(id);
	}
}
