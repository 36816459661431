import { ISortingAlgorithm } from '../../../../utilities/sortingAlgorithm';
import { EntityCollection } from '../objects/entityCollection';

class DefaultSortingAlgorithm implements ISortingAlgorithm<EntityCollection> {
	sort(input: EntityCollection[]): EntityCollection[] {
		return input;
	}
}

var algorithmMap = new Map<string, ISortingAlgorithm<EntityCollection>>();

export function getFieldSetSortingAlgorithm(
	fieldSetName: string
): ISortingAlgorithm<EntityCollection> {
	return algorithmMap.get(fieldSetName) || new DefaultSortingAlgorithm();
}
