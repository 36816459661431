import { FC } from 'react';
import { Entity } from '../../entities/objects/entity';
import { SingleFieldSetView } from '../fieldSets/fieldSetViews/singleFieldSetView';
import { AddressFieldSetView } from './fieldSetViews/addressFieldSetView';
import { CollectionFieldSetView } from './fieldSetViews/collectionFieldSetView';
import { PhoneFieldSetView } from './fieldSetViews/phoneFieldSetView';
import { FieldSet } from './fieldTypes';

export type FieldSetViewProps = {
	fieldSetDefinition: FieldSet;
	entity: Entity;
	isReadOnly?: boolean;
};

const customViews = new Map<string, FC<FieldSetViewProps>>([
	['t4_fieldset_address', AddressFieldSetView],
	['t4_fieldset_phone', PhoneFieldSetView],
]);

export const getFieldSetView = (fieldSet: FieldSet): FC<FieldSetViewProps> => {
	let CustomView = customViews.get(fieldSet.name);
	if (CustomView) {
		return CustomView;
	}

	if (fieldSet.allowMany || fieldSet.isFromToCollection) {
		return CollectionFieldSetView;
	}

	return SingleFieldSetView;
};
