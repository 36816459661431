import { ObjectTaxIdPage } from 'features/entity4/entities/object/taxIds/pages/objectTaxIdPage';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { ObjectTaxIdsPage } from './pages/objectTaxIdsPage';

export const ObjectTaxIdsRoutes: FC = observer(() => (
	<Switch>
		<Route exact path={paths.entity4.objects.object.taxIds.href}>
			<ObjectTaxIdsPage />
		</Route>
		<Route
			exact
			path={paths.entity4.objects.object.taxIds.taxId.href.concat(validIdRegex)}
		>
			<ObjectTaxIdPage />
		</Route>

		<Route>
			<CannotDisplay
				headingText={NOT_FOUND_MESSAGING.HEADING}
				bodyText={NOT_FOUND_MESSAGING.BODY}
				imageSrc={NOT_FOUND_MESSAGING.IMAGE}
				buttonText={RETURN_TO_HOME}
				buttonHref={paths.root.href}
			/>
		</Route>
	</Switch>
));
