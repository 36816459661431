import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';
import { getEntityTypeRoute } from '../../../../../../features/entity4/entities/entityRepository';
import { EntityType } from '../../../../../../features/entity4/entity4Constants';
import { T4CustomResponse } from '../../client';
import { Write } from '../../transactions';

// #region Models

export type Tag = {
	tagId: string;
	name: string;
	lastUsedOn: string;
};

export type CreateTagObject = {
	name: string;
};

// #endregion

// #region Requests

export type AddTagRequest = {
	data: CreateTagObject;
	entityType: EntityType;
	entityId: string;
	parentEntityType?: EntityType;
	parentEntityId?: string;
};

export type RemoveTagRequest = {
	entityType: EntityType;
	entityId: string;
	tagId: string;
	parentEntityType?: EntityType;
	parentEntityId?: string;
};

// #endregion

// #region Responses

export type AddTagResponse = T4Response<
	T4CustomResponse<string>,
	AddTagRequest
>;
export type RemoveTagResponse = T4Response<
	T4CustomResponse<string>,
	RemoveTagRequest
>;

// #endregion

export type TagEndpoints = {
	add: Write<T4CustomResponse<string>, AddTagRequest>;
	remove: Write<T4CustomResponse<string>, RemoveTagRequest>;
	getAll: Read<T4DataResponse<Tag[]>>;
};

export function tags(axiosInstance: AxiosInstance): TagEndpoints {
	return {
		add: async (
			{ data, entityType, entityId, parentEntityType, parentEntityId },
			config: any = {},
		): Promise<AddTagResponse> =>
			await axiosInstance.post(
				parentEntityType && parentEntityId
					? `/${getEntityTypeRoute(
							parentEntityType,
					  )}/${parentEntityId}/${getEntityTypeRoute(
							entityType,
					  )}/${entityId}/tags`
					: `${getEntityTypeRoute(entityType)}/${entityId}/tags`,
				data,
				{
					...config,
				},
			),
		remove: async ({
			entityType,
			entityId,
			tagId,
			parentEntityType,
			parentEntityId,
		}): Promise<RemoveTagResponse> =>
			await axiosInstance.delete(
				parentEntityType && parentEntityId
					? `/${getEntityTypeRoute(
							parentEntityType,
					  )}/${parentEntityId}/${getEntityTypeRoute(
							entityType,
					  )}/${entityId}/tags/${tagId}`
					: `${getEntityTypeRoute(entityType)}/${entityId}/tags/${tagId}`,
			),
		getAll: async (config: any = {}) =>
			await axiosInstance.get('/tags', {
				...config,
			}),
	};
}
