import { AxiosInstance } from 'axios';
import { LegalEntityEndpoints, legalEntities } from './legalentities';
import { PartnerEndpoints, partners } from './partners';
import { CounterpartyEndpoints, counterparties } from './counterparties';

// #region Models

export type E4ObjectBase = {
	id: string;
	objectType: string;
	isApproved: boolean;
	code: string;
	displayName: string;
};

export type Address = {
	type: string;
	countryCode: string | null;
};

// #endregion

export type Entity4Endpoints = {
	legalEntities: LegalEntityEndpoints;
	partners: PartnerEndpoints;
	counterparties: CounterpartyEndpoints;
};

export function entity4(axiosInstance: AxiosInstance): Entity4Endpoints {
	return {
		legalEntities: legalEntities(axiosInstance),
		partners: partners(axiosInstance),
		counterparties: counterparties(axiosInstance),
	};
}
