import { CancelRounded, CheckBox } from '@mui/icons-material';
import {
	Alert,
	Box,
	Button,
	Snackbar,
	Typography,
	useTheme,
} from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { SingleGroupRequestApprovalViewModel } from './singleGroupRequestApprovalViewModel';

interface ISingleGroupRequestApprovalModal {
	viewModel: SingleGroupRequestApprovalViewModel;
}

export const stonlyIds = {
	manageGroupMemberReqeustButton: 'manage-group-members-reqeuest-button',
};

export const SingleGroupRequestApprovalModal: FC<ISingleGroupRequestApprovalModal> =
	observer(({ viewModel }) => {
		const theme = useTheme();

		if (!viewModel.isModalOpen())
			return (
				<Snackbar
					open={viewModel.isSuccessSnackbarOpen()}
					autoHideDuration={6000}
					onClose={viewModel.closeSuccessSnackbar}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert
						onClose={viewModel.closeSuccessSnackbar}
						severity="success"
						sx={{ width: '100%' }}
					>
						Successfully submitted review.
					</Alert>
				</Snackbar>
			);

		return (
			<FormModal
				open={viewModel.isModalOpen()}
				onClose={viewModel.closeModal}
				onSubmit={async () => await viewModel.manageRequestsSubmit()}
				title="Pending Approval"
				error={viewModel.getError()}
				loading={viewModel.getLoading()}
				fullWidth
				maxWidth="sm"
				submitButtonLabel="Submit"
				loadingMessage="Loading..."
				submitDisabled={!viewModel.canSubmit()}
			>
				<Box
					display="flex"
					flexDirection="column"
					sx={{ color: theme.palette.background.paper }}
				>
					<Typography>{viewModel.getDescription()}</Typography>
					<Box display="flex" paddingY={1}>
						<Button
							onClick={() => viewModel.setIsApproved(true)}
							startIcon={
								<CheckBox
									color={
										viewModel.getIsApproved() === true ? 'inherit' : 'primary'
									}
								/>
							}
							color="primary"
							variant={
								viewModel.getIsApproved() === true ? 'contained' : 'outlined'
							}
						>
							Approve
						</Button>
						<Box paddingX={2} />
						<Button
							onClick={() => viewModel.setIsApproved(false)}
							startIcon={
								<CancelRounded
									color={
										viewModel.getIsApproved() === false ? 'inherit' : 'error'
									}
								/>
							}
							color="error"
							variant={
								viewModel.getIsApproved() === false ? 'contained' : 'outlined'
							}
						>
							Reject
						</Button>
					</Box>
					{viewModel.shouldShowRejectionComment() ? (
						<T4TextFieldV2
							id="rejection-comment"
							label="Rejection Comment"
							required
							onChange={viewModel.setRejectionComment}
						/>
					) : null}
				</Box>
			</FormModal>
		);
	});
