import { AxiosInstance } from 'axios';
import { FieldApprovalEndpoints, fieldApprovals } from './fieldApprovals';
import { T4CustomResponse } from '../../client';
import { Read } from 'modules/clients/types';

// #region Endpoints

export type ConfigurationEndpoints = {
	fieldApprovals: FieldApprovalEndpoints;
	getSupportedTimezones: Read<T4CustomResponse<string[]>>;
};

// #endregion

export function configuration(
	axiosInstance: AxiosInstance,
): ConfigurationEndpoints {
	return {
		fieldApprovals: fieldApprovals(axiosInstance),
		getSupportedTimezones: async (_, config = {}) =>
			axiosInstance.get('/api/v1.0/configuration/timezones', {
				...config,
			}),
	};
}
