import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';

export type T4ListResponse<T> = {
	count: number;
	page: number;
	pages: number;
	items: T[];
};

export type RequestConfig<TData> = Omit<AxiosRequestConfig<TData>, 'headers'>;

type Response<TReturn, TData> = AxiosResponse<ApiResponse<TReturn>, TData>;

export class RepositoryBase {
	protected async get<TReturn = unknown>(
		url: string,
		config?: RequestConfig<any>
	): Promise<ApiResponse<TReturn>> {
		try {
			var response = await customerApi.get<
				TReturn,
				Response<TReturn, any>,
				any
			>(url, {
				...config,
			});

			return response.data;
		} catch (error) {
			throw error;
		}
	}

	protected async getFile(url: string): Promise<BlobPart> {
		try {
			var response = await customerApi.get<BlobPart>(url, {
				responseType: 'blob',
			});

			return response.data;
		} catch (error) {
			throw error;
		}
	}

	protected async post<TReturn = unknown, TData = any>(
		url: string,
		data?: TData,
		config?: RequestConfig<TData>
	): Promise<ApiResponse<TReturn>> {
		try {
			var response = await customerApi.post<
				TReturn,
				Response<TReturn, TData>,
				TData
			>(url, data, {
				...config,
			});

			return response.data;
		} catch (error) {
			throw error;
		}
	}

	protected async put<TReturn = unknown, TData = any>(
		url: string,
		data?: TData,
		config?: RequestConfig<TData>
	): Promise<ApiResponse<TReturn>> {
		try {
			var response = await customerApi.put<
				TReturn,
				Response<TReturn, TData>,
				TData
			>(url, data, {
				...config,
			});

			return response.data;
		} catch (error) {
			throw error;
		}
	}

	protected async patch<TReturn = unknown, TData = any>(
		url: string,
		data?: TData,
		config?: RequestConfig<TData>
	): Promise<ApiResponse<TReturn>> {
		try {
			var response = await customerApi.patch<
				TReturn,
				Response<TReturn, TData>,
				TData
			>(url, data, {
				...config,
			});

			return response.data;
		} catch (error) {
			throw error;
		}
	}

	protected async delete<TReturn = unknown>(
		url: string,
		config?: RequestConfig<any>
	): Promise<ApiResponse<TReturn>> {
		try {
			var response = await customerApi.delete<
				TReturn,
				Response<TReturn, any>,
				any
			>(url, {
				...config,
			});

			return response.data;
		} catch (error) {
			throw error;
		}
	}
}
