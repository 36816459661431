import { Add } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Button, ButtonProps, SvgIconProps } from '@mui/material';
import { FC } from 'react';

export interface T4DestructiveButtonProps
	extends Omit<
		LoadingButtonProps,
		'color' | 'variant' | 'loadingPosition' | 'loadingIndicator'
	> {
	label: string;
}
export const T4DestructiveButton: FC<T4DestructiveButtonProps> = ({
	label,
	...props
}) => (
	<LoadingButton {...props} color="error" variant="contained">
		{label}
	</LoadingButton>
);

export interface T4SecondaryButtonProps
	extends Omit<ButtonProps, 'color' | 'variant'> {
	label: string;
}
export const T4SecondaryButton: FC<T4SecondaryButtonProps> = ({
	label,
	...props
}) => (
	<Button {...props} color="secondary">
		{label}
	</Button>
);

// Primary
export interface T4SubmitButtonProps
	extends Omit<
		LoadingButtonProps,
		'color' | 'variant' | 'loadingPosition' | 'loadingIndicator'
	> {
	label: string;
}
export const T4SubmitButton: FC<T4SubmitButtonProps> = ({
	label,
	...props
}) => (
	<LoadingButton {...props} variant="contained">
		{label}
	</LoadingButton>
);

export interface T4AddButtonProps
	extends Omit<
		LoadingButtonProps,
		| 'variant'
		| 'color'
		| 'startIcon'
		| 'size'
		| 'loadingPosition'
		| 'loadingIndicator'
	> {
	label: string;
	props?: {
		iconProps?: Partial<SvgIconProps>;
	};
}
export const T4AddButton: FC<T4AddButtonProps> = ({ label, ...props }) => (
	<LoadingButton
		{...props}
		variant="outlined"
		color="primary"
		startIcon={<Add {...(props.props?.iconProps ?? {})} />}
	>
		{label}
	</LoadingButton>
);
