import { Add, Delete } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { FormModal } from '../../../../shared/components/formModal';
import { useUser } from '../../../../shared/hooks/useUser';
import { EntityCollection } from '../../entities/objects/entityCollection';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { AddressView } from '../../shared/fieldSets/customViews/addressView';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { AddressList } from '../objects/addressList';

export type AddressListViewProps = {
	addressList: AddressList;
};

export const AddressListView: FC<AddressListViewProps> = observer(
	({ addressList }) => {
		const { viewType } = useProfileView();
		const { isAuthor } = useUser();
		const [deleteModalOpen, setDeleteModalOpen] = useState(false);
		const [addressToDelete, setAddressToDelete] =
			useState<EntityCollection | null>(null);

		const isEditable = isAuthor && viewType === FieldViews.edit;

		const openDeleteModal = (address: EntityCollection) => {
			setDeleteModalOpen(true);
			setAddressToDelete(address);
		};

		const closeDeleteModal = () => {
			setAddressToDelete(null);
			setDeleteModalOpen(false);
		};

		return (
			<Grid container spacing={2} justifyContent="space-between">
				<Grid item>
					<Typography variant="h3">{addressList.displayName}es</Typography>
				</Grid>
				<Grid item>
					{isEditable ? (
						<Button
							startIcon={
								addressList.loading ? <CircularProgress size={20} /> : <Add />
							}
							onClick={addressList.createItem}
							variant="outlined"
							size="small"
							color="primary"
						>
							Add {addressList.addressType.displayName}
						</Button>
					) : null}
				</Grid>
				<Grid item xs={12}>
					{addressList.items
						.slice()
						.reverse()
						.map((address) => (
							<div
								key={address.id}
								style={{ display: 'flex', flexDirection: 'column' }}
							>
								<AddressView
									isFromTo={addressList.isFromTo}
									addressType={addressList.addressType.code}
									addressCollection={address}
									collectionDefinition={addressList.collectionDefinition}
									fieldViewType={viewType}
								/>
								{isEditable ? (
									<Button
										style={{ marginTop: '10px', marginLeft: 'auto' }}
										onClick={() => openDeleteModal(address)}
										startIcon={<Delete />}
										size="small"
										color="secondary"
										variant="outlined"
									>
										Delete
									</Button>
								) : null}
								<Box marginY={3}>
									<Divider />
								</Box>
							</div>
						))}
				</Grid>

				{deleteModalOpen ? (
					<FormModal
						open={deleteModalOpen}
						onClose={closeDeleteModal}
						title="Delete Address"
						onSubmit={() => {
							return addressList
								.deleteItem(addressToDelete!)
								.finally(() => closeDeleteModal());
						}}
						description={'Are you sure you want to delete this address?'}
						error={addressList.error ?? ''}
						loading={addressList.loading}
						submitButtonLabel="Delete"
						loadingMessage="Deleting..."
					/>
				) : null}
			</Grid>
		);
	},
);
