import { Box, Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { stonlyData } from 'stonly/functions';
import DashboardPageViewModel from './dashboardPageViewModel';

interface IProps {
	viewModel: DashboardPageViewModel;
}

export const DashboardPage: React.FC<IProps> = observer(({ viewModel }) => {
	useEffect(() => viewModel.init(), []);
	const reports = viewModel.getReportList();
	const selectedReport = viewModel.getSelectedReport();

	return (
		<Box>
			<Tabs
				{...stonlyData({
					id: `reporting-tabs`,
				})}
				value={selectedReport?.reportId}
				onChange={viewModel.setReport}
				variant="scrollable"
				scrollButtons="auto"
			>
				{reports.map((x) => (
					<Tab key={x.id} label={x.name} value={x.id} />
				))}
			</Tabs>
			<Box
				{...stonlyData({
					id: `reporting-visualization`,
				})}
			>
				{viewModel.renderDashboard()}
			</Box>
		</Box>
	);
});
