import { Edit } from '@mui/icons-material';
import { Alert, Box, Button, Snackbar } from '@mui/material';
import { GridPaginationModel } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { FormModal } from 'shared/components/formModal';
import { stonlyData } from 'stonly/functions';
import { ManageGroupEntitiesViewModel } from './manageGroupEntitiesButtonViewModel';

interface IManageGroupEntitiesButtonProps {
	viewModel: ManageGroupEntitiesViewModel;
}

export const dataTestIds = {
	manageGroupEntitiesButton: 'manage-group-entities-button',
};

export const stonlyIds = {
	manageGroupEntitiesButton: 'manage-group-entities-button',
};

export const ManageGroupEntitiesButton: FC<IManageGroupEntitiesButtonProps> =
	observer(({ viewModel }) => {
		const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
			{
				page: 0,
				pageSize: 25,
			},
		);

		return (
			<>
				<Button
					{...stonlyData({ id: stonlyIds.manageGroupEntitiesButton })}
					data-testid={dataTestIds.manageGroupEntitiesButton}
					startIcon={<Edit />}
					type="button"
					variant="outlined"
					color="primary"
					onClick={viewModel.openModal}
				>
					Manage
				</Button>

				<FormModal
					open={viewModel.isModalOpen()}
					onClose={viewModel.closeModal}
					onSubmit={async () => await viewModel.manageEntitiesSubmit()}
					title="Manage Entities"
					description="Add and remove entities available to this group by checking or unchecking the boxes."
					error={viewModel.getManageEntitiesError()}
					loading={viewModel.getManageEntitiesLoading()}
					fullWidth
					maxWidth="md"
					submitButtonLabel="Save Changes"
					loadingMessage="Loading..."
					submitDisabled={!viewModel.canSubmitUpdates()}
				>
					<Box sx={{ height: '55vh' }}>
						<UserPreferencesDataGrid
							tableKey="manageGroupEntitiesButton"
							stonlyId="manage-group-entities"
							columns={viewModel.getAvailableEntitiesListColumns()}
							rows={viewModel.getAvailableEntitiesListRows()}
							loading={viewModel.getAvailableEntitiesLoading()}
							errorMessage={viewModel.getAvailableEntitiesError()}
							checkboxSelection
							rowSelectionModel={viewModel.getSelectionModel()}
							onRowSelectionModelChange={viewModel.onSelectionChange}
							paginationModel={paginationModel}
							onPaginationModelChange={(model) => setPaginationModel(model)}
							pageSizeOptions={[25]}
							hideFooter={false}
							isRowSelectable={viewModel.isRowSelectable}
							showToolbar
							pagination
						/>
					</Box>
				</FormModal>

				<Snackbar
					open={viewModel.isSuccessSnackbarOpen()}
					autoHideDuration={6000}
					onClose={viewModel.closeSuccessSnackbar}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert
						onClose={viewModel.closeSuccessSnackbar}
						severity="success"
						sx={{ width: '100%' }}
					>
						Successfully updated group entities.
					</Alert>
				</Snackbar>
			</>
		);
	});
