import { makeAutoObservable } from 'mobx';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import {
	extractValueFromRefJson,
	jsonToReferenceData,
} from 'shared/utilities/referenceDataUtilities';
import CommonReferenceStore, { ReferenceStore } from '../referenceStore';

const mismatchMessage = (childField: string) =>
	`The selected Country does not match the selected ${childField}.`;

export class LocationModel {
	private _error?: string;
	public provinceError?: string;

	private _loadingCountries = false;
	private _loadingProvinces = false;

	private _countries: ReferenceDataValue[] = [];
	private _provinces: ReferenceDataValue[] = [];

	private _referenceStore: ReferenceStore;

	private _country?: string;
	private _province?: string;

	constructor(referenceStore: ReferenceStore = CommonReferenceStore) {
		makeAutoObservable(this);
		this._referenceStore = referenceStore;
	}

	public get loadingCountries() {
		return this._loadingCountries;
	}

	public get loadingProvinces() {
		return this._loadingProvinces;
	}

	public get currentCountry(): string | undefined {
		return this._country;
	}

	public get currentProvince(): string | undefined {
		return this._province;
	}

	public get countryOption(): ReferenceDataValue | null {
		return jsonToReferenceData(this._country);
	}

	public get provinceOption(): ReferenceDataValue | null {
		return jsonToReferenceData(this._province);
	}

	public get countryOptions() {
		return this._countries;
	}

	public get provinceOptions() {
		return this._provinces;
	}

	async loadCountries() {
		this._loadingCountries = true;
		try {
			const countries = await this._referenceStore.getCountries();
			this._countries = countries;
		} catch (error) {
			this._error = error as string;
		} finally {
			this._loadingCountries = false;
		}
	}

	async loadProvinces(countryCode?: string | null) {
		this._loadingProvinces = true;
		try {
			if (!countryCode) {
				countryCode = extractValueFromRefJson(this._country);
			}
			const provinces = countryCode
				? await this._referenceStore.getStateByCountry(countryCode)
				: [];
			this._provinces = provinces;
		} catch (error) {
			this._error = error as string;
		} finally {
			this._loadingProvinces = false;
		}
	}

	public async changeCountry(country: ReferenceDataValue | null) {
		this._country = country ? JSON.stringify(country) : undefined;
		await this.loadProvinces(country?.identifier);
		this.validateCountryProvinceMatch();
	}

	public async changeProvince(province: ReferenceDataValue | null) {
		this._province = province ? JSON.stringify(province) : undefined;
		this.validateCountryProvinceMatch();
	}

	public initializeValues = (country: string, province?: string) => {
		this._country = country;
		this._province = province;
		this.validateCountryProvinceMatch();
	};

	public validateCountryProvinceMatch(): void {
		if (this._province) {
			const state = jsonToReferenceData(this._province);
			const country = jsonToReferenceData(this._country);
			if (state && state.parentIdentifier !== country?.identifier) {
				this.provinceError = mismatchMessage('State/Province');
				return;
			}
		}
		this.provinceError = undefined;
	}

	public clearSelections() {
		this._country = undefined;
		this._province = undefined;
	}
}
