import { CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import {
	CircularProgress,
	IconButton,
	IconButtonProps,
	InputAdornment,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { FieldDescription } from '../../fieldSets/fieldViews/common/fieldDescription';
import { T4Modal, T4ModalProps } from '../atoms/t4Modal';

export type AdornmentType = 'loading' | 'success' | 'info';
export type InputAdornmentWithModalProps = {
	id?: string;
	adornmentType?: AdornmentType;
	title?: T4ModalProps['title'];
	description?: string;
	iconButtonProps?: Partial<IconButtonProps>;
};
export const InputAdornmentWithModal: FC<InputAdornmentWithModalProps> =
	observer(
		({ id, adornmentType, title, description, iconButtonProps = {} }) => {
			const [isOpen, setIsOpen] = useState(false);
			const partialId = id ? `-${id}` : '';

			return (
				<InputAdornment
					id={`input-adornment${partialId}`}
					data-testid="input-adornment"
					position="start"
				>
					{description || adornmentType !== 'info' ? (
						<IconButton
							{...iconButtonProps}
							id={`input-adornment-icon-button${partialId}`}
							data-testid="input-adornment-icon-button"
							onClick={() => description && setIsOpen(true)}
							size="small"
						>
							{adornmentType === 'loading' ? (
								<CircularProgress size={20} />
							) : adornmentType === 'success' ? (
								<CheckCircleOutline color="primary" />
							) : (
								<InfoOutlined />
							)}
						</IconButton>
					) : null}
					{description ? (
						<T4Modal
							id={`input-adornment${partialId}`}
							title={title}
							onClose={() => setIsOpen(false)}
							open={isOpen}
						>
							<FieldDescription description={description} />
						</T4Modal>
					) : null}
				</InputAdornment>
			);
		},
	);
