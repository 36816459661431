import { ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionProps,
	AccordionSummary,
} from '@mui/material';
import { FC } from 'react';

export interface T4AccordianProps
	extends Pick<AccordionProps, 'sx' | 'onChange'> {
	ariaControls: string;
	accordianId: string;
	summary: any;
	details: any;
}

export const T4Accordian: FC<T4AccordianProps> = ({
	ariaControls,
	accordianId,
	summary,
	details,
	...props
}) => {
	return (
		<Accordion {...props}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls={ariaControls}
				id={accordianId}
				data-testid="t4-accordian-summary"
			>
				{summary}
			</AccordionSummary>
			<AccordionDetails data-testid="t4-accordian-details" sx={{ paddingY: 0 }}>
				{details}
			</AccordionDetails>
		</Accordion>
	);
};
