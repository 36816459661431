import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response, Write } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';

//#region Models

export type LegalEntity = T4DataResponse<{
	id: string;
	code: string;
}>;

export type LegalEntityIdentifier = {
	id: string;
	displayName: string;
};

//#endregion

//#region Responses

export type DeleteLegalEntityData = {
	id: string;
};
export type DeleteLegalEntityResponse = T4Response<
	ApiResponse<LegalEntity>,
	DeleteLegalEntityData
>;

export type LegalEntityGroupsData = {
	legalEntityId: string;
	legalEntityGroupIds: string[];
};
export type LegalEntityGroupsReq = {
	legalEntityGroupIds: string[];
};
export type AddLegalEntityGroupsResponse = T4Response<
	ApiResponse<string | undefined>,
	LegalEntityGroupsReq
>;
export type RemoveLegalEntityGroupsResponse = T4Response<
	ApiResponse<string | undefined>,
	LegalEntityGroupsReq
>;

//#endregion

export type EntityEndpoints = {
	delete: Write<ApiResponse<LegalEntity>, DeleteLegalEntityData>;
	getIdentifiers: Read<ApiResponse<LegalEntityIdentifier[]>>;
	addLegalEntityGroups: Write<
		ApiResponse<string | undefined>,
		LegalEntityGroupsData,
		LegalEntityGroupsReq
	>;
	removeLegalEntityGroups: Write<
		ApiResponse<string | undefined>,
		LegalEntityGroupsData,
		LegalEntityGroupsReq
	>;
};

export function entities(axiosInstance: AxiosInstance): EntityEndpoints {
	return {
		delete: async (data, config = {}): Promise<DeleteLegalEntityResponse> =>
			await axiosInstance.delete(`entity/${data.id}`, {
				...config,
			}),
		getIdentifiers: async (
			_ = undefined,
			config = {},
		): Promise<T4Response<ApiResponse<LegalEntityIdentifier[]>>> =>
			await axiosInstance.get(`entity/identifiers`, {
				...config,
			}),
		addLegalEntityGroups: async (
			data,
			config = {},
		): Promise<AddLegalEntityGroupsResponse> =>
			await axiosInstance.post(
				`entity/${data.legalEntityId}/legalentitygroups`,
				{
					legalEntityGroupIds: data.legalEntityGroupIds,
				},
				{ ...config },
			),
		removeLegalEntityGroups: async (
			data,
			config = {},
		): Promise<AddLegalEntityGroupsResponse> =>
			await axiosInstance.delete(
				`entity/${data.legalEntityId}/legalentitygroups`,
				{
					...config,
					data: {
						legalEntityGroupIds: data.legalEntityGroupIds,
					},
				},
			),
	};
}
