import {
	AppInsightsContext,
	ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useConfigurations } from 'shared/hooks/useConfigurations';

export interface T4ApplicationInsightsProviderProps {
	children: ReactNode;
}
export const T4ApplicationInsightsProvider: FC<
	T4ApplicationInsightsProviderProps
> = ({ children }) => {
	const { appInsights } = useConfigurations();
	const reactPlugin = new ReactPlugin();
	const history = useHistory();

	if (appInsights.key || appInsights.connectionString) {
		// https://learn.microsoft.com/azure/azure-monitor/app/javascript-sdk-configuration?WT.mc_id=DT-MVP-5003472
		const applicationInsights = new ApplicationInsights({
			config: {
				instrumentationKey: appInsights.key,
				// TODO: We should look to migration the AI connection strings
				// https://learn.microsoft.com/azure/azure-monitor/app/migrate-from-instrumentation-keys-to-connection-strings?WT.mc_id=DT-MVP-5003472
				connectionString: appInsights.connectionString,
				enableCorsCorrelation: true,
				correlationHeaderDomains: appInsights.correlationDomains,
				extensions: [reactPlugin as any],
				extensionConfig: {
					[reactPlugin.identifier]: { history: history },
				},
			},
		});
		applicationInsights.loadAppInsights();
	}

	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			{children}
		</AppInsightsContext.Provider>
	);
};
