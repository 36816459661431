import { flow, makeAutoObservable } from 'mobx';
import SecurityGroupConfigurationModel from './securityGroupConfigurationModel';

class SecurityGroupConfigurationViewModel {
	private _model: SecurityGroupConfigurationModel;
	private _modalOpen: boolean;

	constructor() {
		makeAutoObservable(this);
		this._model = new SecurityGroupConfigurationModel();
		this._modalOpen = false;
	}

	get loading() {
		const loading = this._model.getLoading();
		const updating = this._model.getUpdatingApprovalRequirement();
		return loading || updating;
	}

	get updatingApprovalRequirement() {
		return this._model.getUpdatingApprovalRequirement();
	}

	get error() {
		return this._model.getError();
	}

	get requiresReview() {
		return this._model.getRequiresApproval();
	}

	public isOpen() {
		return this._modalOpen;
	}

	public load = flow(function* (this: SecurityGroupConfigurationViewModel) {
		yield this._model.load();
	});

	openModal = () => {
		this._modalOpen = true;
	};

	closeModal = () => {
		this._modalOpen = false;
	};

	submitModal = async () => {
		await this._model.updateApprovalRequirement(false);
		this.closeModal();
	};

	handleRequireReviewsToggle = (
		_: React.ChangeEvent<HTMLInputElement>,
		checked: boolean,
	) => {
		if (checked === false) {
			this.openModal();
		} else {
			this._model.updateApprovalRequirement(checked);
		}
	};
}

export default SecurityGroupConfigurationViewModel;
