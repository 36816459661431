import { Box, Collapse, Grid, Typography, useTheme } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { PowerOfAttorneyType } from 'modules/clients/customer-api/src/api/referenceData';
import { Moment } from 'moment';
import { FC } from 'react';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { GeneralRelationshipFields } from '../generalRelationshipsFields';
import { EditRelationshipDrawerViewModel } from './editRelationshipDrawerViewModel';

interface IEditRelationshipDrawerProps {
	viewModel: EditRelationshipDrawerViewModel;
}

export const stonlyIds = {
	addButton: 'relationship-add-button',
	createButton: 'submit-button',
	cancelButton: 'cancel-button',
};

export const testIds = {
	addButton: 'add-button',
	createButton: 'create-button',
	cancelButton: 'cancel-button',
};

export const EditRelationshipDrawer: FC<IEditRelationshipDrawerProps> =
	observer(({ viewModel }) => {
		const theme = useTheme();

		return (
			<T4DrawerBase
				open={viewModel.isOpen()}
				onClose={() => viewModel.closeDrawer()}
				title="Edit Relationship"
				loading={viewModel.isUpdating()}
				actions={[
					<DrawerCancelButton
						stonlyId={stonlyIds.cancelButton}
						onCancel={() => viewModel.closeDrawer()}
					/>,
					<DrawerSubmitButton
						stonlyId={stonlyIds.createButton}
						label="Save"
						onSubmit={() => viewModel.updateRelationship()}
						disabled={viewModel.isSubmitDisabled()}
					/>,
				]}
			>
				<Grid container>
					<Grid item xs={12} sx={{ marginBottom: '16px' }}>
						<T4TextFieldV2
							label="Relationship Type"
							disabled={true}
							value={viewModel.getTypeDisplayName()}
						/>
					</Grid>

					<Grid item xs={12} sx={{ marginBottom: '16px' }}>
						<T4TextFieldV2
							label="Relationship"
							disabled={true}
							value={viewModel.getRelationshipDisplayName()}
						/>
					</Grid>

					{viewModel.getRelationshipDisplayName() === 'Power of Attorney' && (
						<Grid item xs={12} sx={{ marginY: '16px' }}>
							<T4Autocomplete<PowerOfAttorneyType>
								label={'Power of Attorney Type'}
								options={viewModel.getPowerOfAttorneys()}
								getOptionLabel={(entity) => entity?.description ?? ''}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								value={viewModel.getSelectedPowerOfAttorney()}
								onChange={(_, value) => viewModel.setPowerOfAttorney(value)}
								readOnly={viewModel.isUpdating()}
								placeholder={`Select Power of Attorney Type`}
								required
							/>
						</Grid>
					)}

					<Grid item xs={12} sx={{ marginBottom: '16px' }}>
						<T4TextFieldV2
							label={viewModel.getRelatedEntityLabel()}
							disabled={true}
							value={viewModel.getRelatedEntityName()}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginTop: '12px', marginBottom: '12px' }}>
						<Grid
							item
							xs={12}
							sx={{
								padding: '16px',
								backgroundColor: theme.palette.lightBackground?.main,
								borderRadius: '2px',
							}}
						>
							<Typography variant="body1">
								<Box component="span" sx={{ fontWeight: 'bold' }}>
									{viewModel.getEntityName()}
								</Box>{' '}
								{viewModel.getDirectionDescription()}{' '}
								<Box component="span" sx={{ fontWeight: 'bold' }}>
									{viewModel.getRelatedEntityName()}
								</Box>
								.
							</Typography>
						</Grid>
					</Grid>

					<GeneralRelationshipFields
						effectiveFrom={viewModel.getEffectiveFrom()}
						effectiveFromOnChange={(value: Moment | null) =>
							viewModel.setEffectiveFrom(value)
						}
						effectiveTo={viewModel.getEffectiveTo()}
						effectiveToOnChange={(value: Moment | null) =>
							viewModel.setEffectiveTo(value)
						}
						notes={viewModel.getNotes()}
						notesOnChange={(value: string | null) => viewModel.setNotes(value)}
						inputDisabled={viewModel.isUpdating()}
					/>
				</Grid>

				<Collapse
					in={
						Boolean(viewModel.getUpdateError()) ||
						Boolean(viewModel.getUpdateErrors().length > 0)
					}
				>
					<Box sx={{ marginY: '8px' }}>
						<T4AlertStack
							error={viewModel.getUpdateError()}
							errors={viewModel.getUpdateErrors()}
						/>
					</Box>
				</Collapse>
			</T4DrawerBase>
		);
	});
