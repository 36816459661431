import { Button, Typography } from '@mui/material';
import { CustomModal } from 'features/entity4/entities/components/customModal';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { DeleteRelationshipViewModel } from './deleteRelationshipViewModel';

export const testIds = {
	modal: 'modal',
	cancelButton: 'cancel-button',
	deleteButton: 'delete-button',
};

export interface DeleteRelationshipModalProps {
	viewModel: DeleteRelationshipViewModel;
}
export const DeleteRelationshipModal: FC<DeleteRelationshipModalProps> = observer(
	({ viewModel }) => (
		<CustomModal
			data-testid={testIds.modal}
			title={'Confirm Delete'}
			open={viewModel.isOpen}
			actions={[
				<Button
					data-testid={testIds.cancelButton}
					color="secondary"
					onClick={() => viewModel.closeModal()}
				>
					Cancel
				</Button>,
				<Button
					data-testid={testIds.deleteButton}
					color="error"
					variant="contained"
					onClick={() =>
						viewModel.delete().then(() => {
							viewModel.closeModal();
						})
					}
				>
					Delete
				</Button>,
			]}
		>
			<Typography>
				Are you sure you want to delete this relationship?
			</Typography>
		</CustomModal>
	)
);
