import { AxiosInstance } from 'axios';
import { Read, Write } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';

//#region Read Models

export type TablePreferences = {
	id: string;
	selectedCustomViewId?: string;
	tableFilter?: TableFilterPreferences;
	columnPreferences?: TableColumnPreference[];
	customViews?: TableCustomViewPreferences[];
};

export type TableFilterPreferences = {
	id: string;
	logicOperator?: string;
	items?: TableFilterItem[];
};

export type TableFilterItem = {
	id: string;
	field: string;
	operator: string;
	value: string;
};

export type TableColumnPreference = {
	id: string;
	columnId: string;
	hide: boolean;
	width: number;
	sortOrder?: number;
};

export type TableCustomViewPreferences = {
	id: string;
	name: string;
	columns?: TableColumnPreference[];
	filter?: TableFilterPreferences;
};

export type VisualizationPreferences = {
	optionPreferences: VisualizationOptionPreference[];
};

export type VisualizationOptionPreference = {
	optionId: string;
	hide: boolean;
	value?: string;
};

//#endregion

//#region Write Models

export type TablePreferencesReq = {
	selectedCustomViewId?: string | null;
	tableFilter?: TableFilterPreferencesReq;
	columnPreferences?: TableColumnPreferenceReq[];
};

export type TableFilterPreferencesReq = {
	logicOperator?: string;
	items?: TableFilterItemReq[];
};

export type TableFilterItemReq = {
	field: string;
	operator: string;
	value: string;
};

export type TableColumnPreferenceReq = {
	columnId: string;
	hide: boolean;
	width: number;
	sortOrder?: number;
};

export type TableCustomViewPreferencesReq = {
	name: string;
	columns?: TableColumnPreferenceReq[];
	filter?: TableFilterPreferencesReq;
};

//#endregion

//#region Inputs

export type GetTablePreferencesInput = {
	userId: string;
	tableCode: string;
};

export type UpdateTablePreferencesInput = GetTablePreferencesInput & {
	tablePreferences: TablePreferencesReq;
};

export type UpdateTablePreferenceColumnsInput = GetTablePreferencesInput & {
	columnPreferenceUpdates: TableColumnPreferenceReq[];
};

export type UpdateTableFilterPreferencesInput = GetTablePreferencesInput & {
	filterPreferenceUpdates: TableFilterPreferencesReq;
};

export type CreateTableCustomViewInput = GetTablePreferencesInput & {
	createCustomView: TableCustomViewPreferencesReq;
};

export type DeleteTableCustomViewInput = GetTablePreferencesInput & {
	customViewId: string;
};

export type GetVisualizationPreferencesInput = {
	userId: string;
	visualizationCode: string;
};

export type UpdateVisualizationPreferencesInput =
	GetVisualizationPreferencesInput & {
		visualizationPreferences: VisualizationPreferences;
	};

export type UpdateVisualizationPreferenceOptionsInput =
	GetVisualizationPreferencesInput & {
		visualizationOptionPreferences: VisualizationOptionPreference[];
	};

//#endregion

//#region Responses

export type GetTablePreferencesResponse = ApiResponse<TablePreferences>;

export type UpdateTablePreferencesResponse = ApiResponse<TablePreferences>;

export type UpdateTablePreferenceColumnsResponse = ApiResponse<
	TableColumnPreference[]
>;
export type UpdateTableFilterPreferencesResponse =
	ApiResponse<TableFilterPreferences>;

export type UpdateTableSelectedCustomViewResponse = ApiResponse<undefined>;

export type CreateTableCustomViewResponse =
	ApiResponse<TableCustomViewPreferences>;

export type DeleteTableCustomViewResponse = ApiResponse<string>;

export type GetVisualizationPreferencesResponse =
	ApiResponse<VisualizationPreferences>;

export type UpdateVisualizationPreferencesResponse =
	ApiResponse<VisualizationPreferences>;

export type UpdateVisualizationPreferenceOptionsResponse = ApiResponse<
	VisualizationOptionPreference[]
>;

//#endregion

export type UserPreferenceEndpoints = {
	getTablePreferences: Read<
		GetTablePreferencesResponse,
		GetTablePreferencesInput
	>;
	updateTablePreferences: Write<
		UpdateTablePreferencesResponse,
		UpdateTablePreferencesInput,
		TableFilterPreferencesReq
	>;
	updateTablePreferenceColumns: Write<
		UpdateTablePreferenceColumnsResponse,
		UpdateTablePreferenceColumnsInput,
		TableColumnPreferenceReq[]
	>;
	udpateTableFilterPreferences: Write<
		UpdateTableFilterPreferencesResponse,
		UpdateTableFilterPreferencesInput,
		TableFilterPreferencesReq
	>;
	createTableCustomView: Write<
		CreateTableCustomViewResponse,
		CreateTableCustomViewInput,
		TableCustomViewPreferencesReq
	>;
	deleteTableCustomView: Write<
		DeleteTableCustomViewResponse,
		DeleteTableCustomViewInput
	>;
	getVisualizationPreferences: Read<
		GetVisualizationPreferencesResponse,
		GetVisualizationPreferencesInput
	>;
	updateVisualizationPreferences: Write<
		UpdateVisualizationPreferencesResponse,
		UpdateVisualizationPreferencesInput,
		VisualizationPreferences
	>;
	updateVisualizationPreferenceOptions: Write<
		UpdateVisualizationPreferenceOptionsResponse,
		UpdateVisualizationPreferenceOptionsInput,
		VisualizationOptionPreference[]
	>;
};

export function userPreference(
	axiosInstance: AxiosInstance,
): UserPreferenceEndpoints {
	return {
		getTablePreferences: async (input, config = {}) =>
			await axiosInstance.get<GetTablePreferencesResponse>(
				`/userpreference/${input.userId}/table/${input.tableCode}`,
				{ ...config },
			),
		updateTablePreferences: async (input, config = {}) =>
			await axiosInstance.put<UpdateTablePreferencesResponse>(
				`/userpreference/${input.userId}/table/${input.tableCode}`,
				input.tablePreferences,
				{ ...config },
			),
		updateTablePreferenceColumns: async (input, config = {}) =>
			await axiosInstance.put<UpdateTablePreferenceColumnsResponse>(
				`/userpreference/${input.userId}/table/${input.tableCode}/columns`,
				input.columnPreferenceUpdates,
				{ ...config },
			),
		udpateTableFilterPreferences: async (input, config = {}) =>
			await axiosInstance.put<UpdateTableFilterPreferencesResponse>(
				`/userpreference/${input.userId}/table/${input.tableCode}/filters`,
				input.filterPreferenceUpdates,
				{ ...config },
			),
		createTableCustomView: async (input, config = {}) =>
			axiosInstance.post<CreateTableCustomViewResponse>(
				`/userpreference/${input.userId}/table/${input.tableCode}/customview`,
				input.createCustomView,
				{ ...config },
			),
		deleteTableCustomView: async (input, config = {}) =>
			await axiosInstance.delete<DeleteTableCustomViewResponse>(
				`/userpreference/${input.userId}/table/${input.tableCode}/customview/${input.customViewId}`,
				{ ...config },
			),
		getVisualizationPreferences: async (input, config = {}) =>
			await axiosInstance.get<GetVisualizationPreferencesResponse>(
				`/userpreference/${input.userId}/visualization/${input.visualizationCode}`,
				{ ...config },
			),
		updateVisualizationPreferences: async (input, config = {}) =>
			await axiosInstance.put<UpdateVisualizationPreferencesResponse>(
				`/userpreference/${input.userId}/visualization/${input.visualizationCode}`,
				input.visualizationPreferences,
				{ ...config },
			),
		updateVisualizationPreferenceOptions: async (input, config = {}) =>
			await axiosInstance.put<UpdateVisualizationPreferenceOptionsResponse>(
				`/userpreference/${input.userId}/visualization/${input.visualizationCode}/options`,
				input.visualizationOptionPreferences,
				{ ...config },
			),
	};
}
