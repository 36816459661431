import { ColorResult, TwitterPicker } from '@hello-pangea/color-picker';
import { Tune } from '@mui/icons-material';
import { TabContext, TabList } from '@mui/lab';
import {
	FormControl,
	FormGroup,
	Grid,
	Menu,
	RadioGroup,
	Tab,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { ExportButton } from 'features/entity4/visualizations/_components/exportButton';
import { SearchTextField } from 'features/entity4/visualizations/_components/searchTextField';
import go from 'gojs';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { stonlyData } from 'stonly/functions';
import { accountMapPrefix } from 'stonly/pagePrefixes';
import { VisualizationCheckbox } from '../../_components/visualizationCheckbox';
import { VisualizationHelpModal } from '../../_components/visualizationHelpModal';
import { VisualizationOptionLabel } from '../../_components/visualizationOptionLabel';
import { VisualizationRadio } from '../../_components/visualizationRadio';
import { VisualizationTabPanel } from '../../_components/visualizationTabPannel';
import { VisualizationView } from '../../_components/visualizationView';
import { useAccountMap } from '../_providers/accountMapProvider';
import {
	AccountDetailOptionsMap,
	AllAccountDetailOptions,
	AllChartOptions,
	AllCounterpartyDetailOptions,
	AllEntityDetailOptions,
	CardOptionUserDefinedColorKey,
	CardOptionsDisplayKey,
	ChartOptionsMap,
	CounterpartyDetailOptionsMap,
	DisplayAccountTypeKey,
	DisplayClosedAccountsKey,
	DisplayFlagKey,
	EntityDetailOptionsMap,
} from '../models/accountMapTypes';

type DisplayOptionTabs =
	| 'chart-options'
	| 'card-options'
	| 'information-options';

export type AccountMapHeaderComponentProps = {
	diagram: go.Diagram | undefined;
};

export const AccountMapHeaderComponent: FC<AccountMapHeaderComponentProps> =
	observer(({ diagram }) => {
		const {
			visualizationPreferences,
			accountView,
			toggleAccountView,
			updateVisualizationPreferences,
		} = useAccountMap();

		const [tab, setTab] = useState<DisplayOptionTabs>('chart-options');
		const [searchValue, setSearchValue] = useState<string>('');
		const [colorPaletteOpen, setColorPaletteOpen] = useState(false);
		const [anchorOptionsEl, setAnchorOptionsEl] = useState<HTMLElement>();

		const openOptions = useMemo(
			() => Boolean(anchorOptionsEl),
			[anchorOptionsEl],
		);

		const handleOptionsClose = useCallback(() => {
			setAnchorOptionsEl(undefined);
		}, []);

		const handleOptionPreferencesChange = useCallback(
			async (optionChanged: string, isEnabled: boolean) => {
				const nextOptionPreferences =
					visualizationPreferences?.options?.filter(
						(x) => x.optionId !== optionChanged,
					) ?? [];
				const optionPreferences = [
					...nextOptionPreferences,
					{
						id: optionChanged,
						optionId: optionChanged,
						hide: !isEnabled,
					},
				];

				await updateVisualizationPreferences({ options: optionPreferences });
			},
			[updateVisualizationPreferences, visualizationPreferences],
		);

		const isOptionChecked = useCallback(
			(optionKey: string) => {
				return !visualizationPreferences?.options?.find(
					(op) => op.optionId === optionKey,
				)?.hide;
			},
			[visualizationPreferences?.options],
		);

		const handleColorByPreferenceChange = useCallback(
			async (colorByValue: string) => {
				const optionPreferences = [
					...(visualizationPreferences?.options?.filter(
						(x) => x.optionId !== CardOptionsDisplayKey,
					) ?? []),
					{
						id: CardOptionsDisplayKey,
						optionId: CardOptionsDisplayKey,
						hide: false,
						value: colorByValue,
					},
				];

				await updateVisualizationPreferences({ options: optionPreferences });
			},
			[updateVisualizationPreferences, visualizationPreferences],
		);

		const handleUserChosenColorChange = useCallback(
			async (color: ColorResult) => {
				const optionPreferences = [
					...(visualizationPreferences?.options?.filter(
						(x) =>
							x.optionId !== CardOptionUserDefinedColorKey &&
							x.optionId !== CardOptionsDisplayKey,
					) ?? []),
					{
						id: CardOptionUserDefinedColorKey,
						optionId: CardOptionUserDefinedColorKey,
						hide: false,
						value: color?.hex,
					},
					{
						id: CardOptionsDisplayKey,
						optionId: CardOptionsDisplayKey,
						hide: false,
						value: 'singleColor',
					},
				];

				await updateVisualizationPreferences({ options: optionPreferences });
			},
			[updateVisualizationPreferences, visualizationPreferences],
		);

		const onSearchType = useCallback(
			(searchValue: string) => {
				const transactionName = 'search-highlight';
				if (diagram) {
					diagram.startTransaction(transactionName);

					if (searchValue) {
						const input = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
						const regex = new RegExp(input, 'i');
						const nodes = diagram.findNodesByExample(
							{ entityType: regex },
							{ status: regex },
							{ displayNameShort: regex },
							{ displayName: regex },
							{ accountCode: regex },
							{ accountStatus: regex },
							{ shortName: regex },
							{ openDate: regex },
							{ closeDate: regex },
							{ accountCurrencyCode: regex },
							{ accountType: regex },
							{ purpose: regex },
							{ naturalAccountNumber: regex },
							{ generalLedgerAccountNumber: regex },
							{ counterparty: regex },
							{ counterpartyBranch: regex },
							{ entityName: regex },
							{ entityRegion: regex },
							{ entityErpCode: regex },
							{ entityCountry: regex },
							{ stateProvince: regex },
							{ location: regex },
							{ fundingDirection: regex },
							{ cashFlowMovement: regex },
							{ paymentDirection: regex },
						);

						diagram.highlightCollection(nodes);
					} else {
						diagram.clearHighlighteds();
					}

					diagram.commitTransaction(transactionName);
				}
			},
			[diagram],
		);

		useEffect(() => {
			onSearchType(searchValue);
		}, [diagram, onSearchType, searchValue]);

		return (
			<VisualizationView
				stonlyId={accountMapPrefix}
				title={accountView === 'account' ? 'Account Map' : 'Entity Map'}
				headerElements={[
					<ToggleButtonGroup
						color="primary"
						value={accountView}
						exclusive
						onChange={() => toggleAccountView()}
						aria-label="Platform"
						style={{ alignItems: 'center' }}
					>
						<ToggleButton
							value="account"
							{...stonlyData({
								id: `${accountMapPrefix}-account-view-button`,
							})}
						>
							Account View
						</ToggleButton>
						<ToggleButton
							value="entity"
							{...stonlyData({
								id: `${accountMapPrefix}-entity-view-button`,
							})}
						>
							Entity View
						</ToggleButton>
					</ToggleButtonGroup>,
					<Grid
						container
						item
						flexWrap="wrap"
						xs="auto"
						sx={{ gap: 2, alignItems: 'center' }}
					>
						<Grid item xs="auto">
							<SearchTextField
								value={searchValue}
								onChange={(event) => {
									const newSearchValue = event.target.value ?? '';

									setSearchValue(newSearchValue);
									onSearchType(newSearchValue);
								}}
							/>
						</Grid>
						<Grid item xs="auto">
							<T4Button
								id="displayOptionsBtn"
								variant="outlined"
								sx={{
									background: 'white',
									height: '40px',
								}}
								startIcon={<Tune />}
								onClick={(event) => setAnchorOptionsEl(event.currentTarget)}
								{...stonlyData({
									id: `${accountMapPrefix}-display-options-button`,
								})}
							>
								Display Options
							</T4Button>
							<Menu
								id="optionsMenu"
								anchorEl={anchorOptionsEl}
								open={openOptions}
								onClose={handleOptionsClose}
								sx={{
									'& .MuiMenu-list': {
										padding: 0,
									},
								}}
							>
								<Grid
									container
									flexWrap="nowrap"
									sx={{
										padding: '1rem',
										paddingY: '1.5rem',
										width: '800px',
									}}
									{...stonlyData({
										id: `${accountMapPrefix}-display-modal-container`,
									})}
								>
									<TabContext value={tab}>
										<Grid item xs="auto">
											<TabList
												orientation="vertical"
												variant="scrollable"
												onChange={(_, selectedTab) => setTab(selectedTab)}
												sx={{ borderRight: 1, borderColor: 'divider' }}
											>
												<Tab
													sx={{ alignItems: 'start' }}
													label="Chart Options"
													value="chart-options"
													{...stonlyData({
														id: `${accountMapPrefix}-display-modal-chart-options`,
													})}
												/>
												<Tab
													sx={{ alignItems: 'start' }}
													label="Card Options"
													value="card-options"
													{...stonlyData({
														id: `${accountMapPrefix}-display-modal-card-options`,
													})}
												/>
												<Tab
													sx={{ alignItems: 'start' }}
													label="Information Options"
													value="information-options"
													{...stonlyData({
														id: `${accountMapPrefix}-display-modal-information-options`,
													})}
												/>
											</TabList>
										</Grid>
										<Grid item xs={true}>
											<VisualizationTabPanel value={'chart-options'}>
												<FormControl>
													<FormGroup>
														{AllChartOptions.map((option) => {
															return (
																<div key={option}>
																	<span
																		style={{
																			float: 'right',
																			marginTop: 13,
																			display:
																				option === DisplayClosedAccountsKey
																					? 'block'
																					: 'none',
																		}}
																	>
																		<T4FieldAdornment
																			title="Closed Accounts"
																			t4AdornmentType="info"
																			description="Closed Accounts with active associated accounts will still display until all associated accounts are also in a closed status."
																		/>
																	</span>
																	<VisualizationCheckbox
																		key={option}
																		label={ChartOptionsMap.get(option)}
																		checked={isOptionChecked(option)}
																		onChange={(_, checked) =>
																			handleOptionPreferencesChange(
																				option,
																				checked,
																			)
																		}
																	/>
																</div>
															);
														})}
													</FormGroup>
												</FormControl>
											</VisualizationTabPanel>
											<VisualizationTabPanel value={'card-options'}>
												<FormControl>
													<VisualizationOptionLabel>
														Color
													</VisualizationOptionLabel>
													<RadioGroup
														name="card-options-group"
														value={
															visualizationPreferences?.options?.find(
																(op) => op.optionId === CardOptionsDisplayKey,
															)?.value ?? 'accountPurpose'
														}
														onChange={(_, value) =>
															handleColorByPreferenceChange(value)
														}
													>
														<Grid container sx={{ gap: 1 }}>
															<Grid item xs="auto">
																<VisualizationRadio
																	label={'Single Color'}
																	value={'singleColor'}
																/>
															</Grid>
															<Grid
																container
																item
																xs="auto"
																sx={{ alignItems: 'center' }}
															>
																<div
																	style={{
																		alignSelf: 'center',
																		background:
																			visualizationPreferences?.options?.find(
																				(op) =>
																					op.optionId ===
																					CardOptionUserDefinedColorKey,
																			)?.value ?? '#E0DFDF',
																		height: 20,
																		width: 20,
																		borderRadius: 6,
																		border: '1.5px #414042 solid',
																	}}
																	onClick={() => setColorPaletteOpen(true)}
																/>
															</Grid>
														</Grid>
														{colorPaletteOpen ? (
															<div
																style={{
																	position: 'absolute',
																	zIndex: '2',
																	marginTop: 33,
																	marginLeft: 33,
																}}
															>
																<div
																	style={{
																		position: 'fixed',
																		top: '0px',
																		right: '0px',
																		bottom: '0px',
																		left: '0px',
																	}}
																	onClick={() => setColorPaletteOpen(false)}
																/>
																<TwitterPicker
																	color={
																		visualizationPreferences?.options?.find(
																			(op) =>
																				op.optionId ===
																				CardOptionUserDefinedColorKey,
																		)?.value ?? '#E0DFDF'
																	}
																	styles={{
																		card: {
																			boxShadow:
																				'0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
																		},
																		hash: { display: 'none' },
																		input: { display: 'none' },
																		swatch: { border: '1px solid black' },
																	}}
																	triangle="hide"
																	width={205}
																	onChangeComplete={handleUserChosenColorChange}
																	colors={[
																		'#F6A3A2',
																		'#FBC59C',
																		'#E3D69D',
																		'#FBFBB8',
																		'#E5FAC6',
																		'#A6C09A',
																		'#C9F7F4',
																		'#D1EBFD',
																		'#8AA4B8',
																		'#C5A8Ce',
																		'#D7ADD4',
																		'#E6B2BD',
																		'#FBCEE5',
																		'#E0DFDF',
																		'#FFFFFF',
																	]}
																/>
															</div>
														) : null}
														<VisualizationRadio
															label={'Color by Account Purpose'}
															value={'accountPurpose'}
														/>
														<VisualizationRadio
															label={'Color by Account Status'}
															value={'accountStatus'}
														/>
														<VisualizationRadio
															label={'Color by Entity Region'}
															value={'entityRegion'}
														/>
													</RadioGroup>
													<VisualizationOptionLabel>
														Symbols
													</VisualizationOptionLabel>
													<FormGroup>
														<VisualizationCheckbox
															label={'Account Type/Foreign Account'}
															checked={isOptionChecked(DisplayAccountTypeKey)}
															onChange={(_, checked) =>
																handleOptionPreferencesChange(
																	DisplayAccountTypeKey,
																	checked,
																)
															}
														/>
													</FormGroup>
													{accountView === 'entity' && (
														<>
															<VisualizationOptionLabel>
																Image
															</VisualizationOptionLabel>
															<FormGroup>
																<VisualizationCheckbox
																	label={'Country Flag'}
																	checked={isOptionChecked(DisplayFlagKey)}
																	onChange={(_, checked) =>
																		handleOptionPreferencesChange(
																			DisplayFlagKey,
																			checked,
																		)
																	}
																/>
															</FormGroup>
														</>
													)}
												</FormControl>
											</VisualizationTabPanel>
											<VisualizationTabPanel value={'information-options'}>
												<Grid container>
													<Grid item xs={6}>
														<Grid item xs={12}>
															<VisualizationOptionLabel>
																Account Details
															</VisualizationOptionLabel>
														</Grid>
														<Grid item xs={12}>
															{AllAccountDetailOptions.map((option) => (
																<Grid key={option} item xs={12}>
																	<VisualizationCheckbox
																		label={AccountDetailOptionsMap.get(option)}
																		checked={isOptionChecked(option)}
																		onChange={(_, checked) =>
																			handleOptionPreferencesChange(
																				option,
																				checked,
																			)
																		}
																	/>
																</Grid>
															))}
														</Grid>
													</Grid>
													<Grid container item xs={6} sx={{ gap: 2 }}>
														<Grid item xs={12}>
															<Grid item xs={12}>
																<VisualizationOptionLabel>
																	Counterparty Details
																</VisualizationOptionLabel>
															</Grid>
															<Grid item xs={12}>
																{AllCounterpartyDetailOptions.map((option) => (
																	<Grid key={option} item xs={12}>
																		<VisualizationCheckbox
																			label={CounterpartyDetailOptionsMap.get(
																				option,
																			)}
																			checked={isOptionChecked(option)}
																			onChange={(_, checked) =>
																				handleOptionPreferencesChange(
																					option,
																					checked,
																				)
																			}
																		/>
																	</Grid>
																))}
															</Grid>
														</Grid>
														<Grid item xs={12}>
															<Grid item xs={12}>
																<VisualizationOptionLabel>
																	Entity Details
																</VisualizationOptionLabel>
															</Grid>
															<Grid item xs={12}>
																{AllEntityDetailOptions.map((option) => (
																	<Grid key={option} item xs={12}>
																		<VisualizationCheckbox
																			label={EntityDetailOptionsMap.get(option)}
																			checked={isOptionChecked(option)}
																			onChange={(_, checked) =>
																				handleOptionPreferencesChange(
																					option,
																					checked,
																				)
																			}
																		/>
																	</Grid>
																))}
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</VisualizationTabPanel>
										</Grid>
									</TabContext>
								</Grid>
							</Menu>
						</Grid>
						<Grid item xs="auto">
							<ExportButton
								stonlyId={accountMapPrefix}
								defaultFileName="Entity4_AccountMap"
								diagram={diagram}
							/>
						</Grid>
						<Grid item xs="auto">
							<VisualizationHelpModal />
						</Grid>
					</Grid>,
				]}
			/>
		);
	});
