import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { makeAutoObservable } from 'mobx';
import { EntityType } from '../../../entity4Constants';
import { jsonToReferenceData } from 'shared/utilities/referenceDataUtilities';
import { ValueStatus } from '../../../shared/fieldSets/fieldViews/fieldViewTypes';
import { Address } from '../addresses/addressModel';
import { IRegisteredAgentJurisdictionDto } from '../jurisdictionApiTypes';
import { JurisdictionRepository } from '../jurisdictionRepository';

export const JURISDICTION_FIELD_DESCRIPTIONS = {
	country: 'The Country that the Jurisdiction is effective with.',
	state: 'The State/Province that the Jurisdiction is effective with.',
};

export class Jurisdiction {
	private _id: string;
	private _entityId: string;
	private _entityType: EntityType;
	private _country: string | null;
	private _state: string | null;
	private _streetAddress: Address;
	private _mailingAddress: Address;
	private _isMailingSameAsStreetAddress: boolean;
	private _updatedDate: Date;

	private _openMailingAddressModal: boolean;
	private _error: string;

	private _fieldStatus: Map<keyof Jurisdiction, ValueStatus>;

	constructor(dto: IRegisteredAgentJurisdictionDto, entityType: EntityType) {
		makeAutoObservable(this);
		this._id = dto.id;
		this._entityId = dto.entityId;
		this._entityType = entityType;
		this._country = dto.country;
		this._state = dto.state;
		this._streetAddress = dto.streetAddress
			? new Address(this, dto.streetAddress)
			: new Address(this);
		this._mailingAddress = dto.mailingAddress
			? new Address(this, dto.mailingAddress)
			: new Address(this);
		this._isMailingSameAsStreetAddress = dto.isMailingSameAsStreetAddress;
		this._updatedDate = dto.updatedDate;

		this._openMailingAddressModal = false;
		this._error = '';
		this._fieldStatus = new Map();
	}

	get id(): string {
		return this._id;
	}

	get entityId(): string {
		return this._entityId;
	}

	get entityType(): EntityType {
		return this._entityType;
	}

	get country(): string | null {
		return this._country;
	}

	get state(): string | null {
		return this._state;
	}

	get streetAddress(): Address {
		return this._streetAddress;
	}

	get mailingAddress(): Address {
		return this._mailingAddress;
	}

	get updatedDate(): Date {
		return this._updatedDate;
	}

	get isMailingSameAsStreetAddress(): boolean {
		return this._isMailingSameAsStreetAddress;
	}

	get openMailingAddressModal(): boolean {
		return this._openMailingAddressModal;
	}

	get error(): string {
		return this._error;
	}

	get countryOption(): ReferenceDataValue | null {
		return jsonToReferenceData(this.country);
	}

	get stateOption(): ReferenceDataValue | null {
		return jsonToReferenceData(this.state);
	}

	public resetFormError = () => {
		this._error = '';
	};

	public setCountry = async (country: string | null) => {
		this._country = country;
		this.setIsWaiting('country');
		await this.update()
			.then(() => {
				this.setIsSaved('country');
				this.resetFormError();
			})
			.catch((error) => {
				this._error = error as string;
				this.setIsErrored('country');
			});
	};

	public setState = async (state: string | null) => {
		this._state = state;
		this.streetAddress.updateState();
		this.mailingAddress.updateState();
		this.setIsWaiting('state');
		await this.update()
			.then(() => {
				this.setIsSaved('state');
				this.resetFormError();
			})
			.catch((error) => {
				this._error = error as string;
				this.setIsErrored('state');
			});
	};

	public setIsMailingSameAsStreetAddress = async (event: any) => {
		const { checked } = event.target;
		if (checked) {
			this._openMailingAddressModal = true;
		} else {
			this.setIsWaiting('isMailingSameAsStreetAddress');
			this._isMailingSameAsStreetAddress = checked;
			this._openMailingAddressModal = false;
			await this.update()
				.then(() => {
					this.setIsSaved('isMailingSameAsStreetAddress');
				})
				.catch((error) => {
					this._error = error as string;
					this.setIsErrored('isMailingSameAsStreetAddress');
				});
		}
	};

	public closeMailingAddressModal = () => {
		this._isMailingSameAsStreetAddress = false;
		this._openMailingAddressModal = false;
	};

	public clearMailingAddress = async () => {
		this._openMailingAddressModal = false;
		this.setIsWaiting('isMailingSameAsStreetAddress');
		this._isMailingSameAsStreetAddress = true;
		this._mailingAddress.clear();

		await this.update()
			.then(() => {
				this.setIsSaved('isMailingSameAsStreetAddress');
			})
			.catch((error) => {
				this._error = error as string;
				this.setIsErrored('isMailingSameAsStreetAddress');
			});
	};

	public update = async (): Promise<void> => {
		await JurisdictionRepository.updateRegisteredAgentJurisdiction(
			this._entityId,
			this._entityType,
			this.toDto(),
		);
	};

	public isDirty<T extends keyof Jurisdiction>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Dirty;
	}

	public isWaiting<T extends keyof Jurisdiction>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Waiting;
	}

	public isSaved<T extends keyof Jurisdiction>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Saved;
	}

	public isErrored<T extends keyof Jurisdiction>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Errored;
	}

	public setIsDirty<T extends keyof Jurisdiction>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Dirty);
	}

	public setIsWaiting<T extends keyof Jurisdiction>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Waiting);
	}

	public setIsSaved<T extends keyof Jurisdiction>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Saved);
	}

	public setIsErrored<T extends keyof Jurisdiction>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Errored);
	}

	public toDto = (): IRegisteredAgentJurisdictionDto => {
		return {
			id: this._id,
			entityId: this._entityId,
			country: this.country!,
			state: this._state,
			streetAddress: this._streetAddress.toDto(),
			mailingAddress: this._mailingAddress.toDto(),
			isMailingSameAsStreetAddress: this._isMailingSameAsStreetAddress,
			updatedDate: this._updatedDate,
		};
	};
}
