import axios from 'axios';
import { responseInterceptor } from 'shared/functions/responseInterceptor';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useClients } from 'shared/hooks/useClients';

/** @deprecated Please use {@link useClients} react hook. */
export const customerApi = axios.create({
	baseURL: `${process.env.REACT_APP_CUSTOMER_API_BASE}`,
	validateStatus: (_) => true,
});
customerApi.interceptors.response.use(responseInterceptor);
