import {
	FrontendGroupRepository,
	GroupDto,
} from 'features/administration/securityGroups/groupRepository';
import { flow, makeAutoObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { paths } from 'shared/constants/paths';
import { parseError } from 'utilities/errors/errorUtils';

export class GroupProfileModel {
	private _groupId: string;
	private _frontendGroupRepository: FrontendGroupRepository;
	private _group: GroupDto | null = null;

	private _loading: boolean;
	private _error: string;

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
	) {
		makeAutoObservable(this);
		this._groupId = groupId;
		this._frontendGroupRepository = frontendGroupRepository;

		this._loading = false;
		this._error = '';
	}

	public getName = () => {
		return this._group?.name;
	};

	public getLoading = () => {
		return this._loading;
	};

	public getError = () => {
		return this._error !== '' ? this._error : undefined;
	};

	public getAspects = () => {
		return [
			{
				label: 'Details',
				link: generatePath(paths.administration.groups.group.information.href, {
					groupId: this._groupId,
				}),
			},
			{
				label: 'Entities',
				link: generatePath(paths.administration.groups.group.entities.href, {
					groupId: this._groupId,
				}),
			},
			{
				label: 'Members',
				link: generatePath(paths.administration.groups.group.members.href, {
					groupId: this._groupId,
				}),
			},
		];
	};

	public load = flow(function* (this: GroupProfileModel) {
		try {
			this._loading = true;
			this._error = '';

			const response = yield this._frontendGroupRepository.getGroup(
				this._groupId,
			);
			this._group = response.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}
