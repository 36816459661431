import { ListItemText, MenuItem, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import T4Drawer from 'features/cash4/shared/components/T4SideDrawer/T4DrawerShell';
import { ProjectionDrawer } from 'features/cash4/transactions/components/ProjectionDrawer/ProjectionDrawer';
import { observer } from 'mobx-react-lite';
import { ProjectedItem } from 'modules/clients/customer-api/src/api/cash4';
import { FC, useCallback, useMemo, useState } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { OverflowMenu } from 'shared/components/overflowMenu';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { formatCurrency } from 'utilities/currencyUtils';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';

export type ProjectedItemsGridProps = {
	stonlyId: string;
	tableKey: string;
	loading?: boolean;
	projectedItems: ProjectedItem[];
	selectedProjectedIds?: string[];
	setSelectedProjectedIds?: (selectedProjectedIds: string[]) => void;
};

export const ProjectedItemsGrid: FC<ProjectedItemsGridProps> = observer(
	({
		stonlyId,
		tableKey,
		loading = false,
		selectedProjectedIds,
		setSelectedProjectedIds,
		projectedItems,
	}) => {
		const { setSelectedProjectedItem, setProjectedItemDrawerOpen } =
			useReconciliationsContext();

		const useCheckboxSelection = useMemo(
			() => selectedProjectedIds !== undefined,
			[selectedProjectedIds],
		);
		const [projection, setProjection] = useState<ProjectedItem>();
		const [openProjectionDrawer, setOpenProjectionDrawer] = useState(false);

		const onRowSelectionModelChangeHandler = useMemo(() => {
			if (useCheckboxSelection && setSelectedProjectedIds) {
				return (projectedIds: GridRowSelectionModel) => {
					setSelectedProjectedIds(projectedIds.map((x) => x.toString()));
				};
			}
		}, [setSelectedProjectedIds, useCheckboxSelection]);

		const handleOpenProjectionDrawer = useCallback(() => {
			setOpenProjectionDrawer(true);
		}, []);

		const handleCloseProjectionDrawer = useCallback(() => {
			setOpenProjectionDrawer(false);
		}, []);

		const handleProjectedViewClick = useCallback(
			(projection: ProjectedItem) => {
				setProjection(projection);
				handleOpenProjectionDrawer();
			},
			[handleOpenProjectionDrawer],
		);

		const columns = useMemo<GridColDef<ProjectedItem>[]>(() => {
			const columns: GridColDef<ProjectedItem>[] = [
				{
					field: 'label',
					headerName: 'Label',
				},
				{
					field: '_initiator_type',
					headerName: 'Initiator type',
					valueGetter: (params) => params.row.initiator.type,
				},
				{
					field: '_initiator_name',
					headerName: 'Initiator name',
					valueGetter: (params) => params.row.initiator.object?.name,
				},
				{
					field: '_initiator__account_name',
					headerName: 'Initiator account name',
					valueGetter: (params) => params.row.initiator.account?.name,
				},
				{
					field: '_initiator__account_number',
					headerName: 'Initiator account number',
					valueGetter: (params) => params.row.initiator.account?.number,
				},
				{
					...getCurrencyColumnDefinition(),
					field: 'amount',
					headerName: 'Amount',
					renderCell: (params) =>
						formatCurrency(params.row.amount, {
							currency: params.row.currencyCode,
						}),
				},
				{
					field: 'currencyCode',
					headerName: 'Currency',
				},
				{
					...getDateColumnDefinition(),
					field: 'date',
					headerName: 'Expected value date',
				},
				{
					field: '_payee_type',
					headerName: 'Payee type',
					valueGetter: (params) => params.row.payee.type,
				},
				{
					field: '_payee_name',
					headerName: 'Payee name',
					valueGetter: (params) => params.row.payee.object?.name,
				},
				{
					field: '_payee__account_name',
					headerName: 'Payee account name',
					valueGetter: (params) => params.row.payee.account?.name,
				},
				{
					field: '_payee__account_number',
					headerName: 'Payee account number',
					valueGetter: (params) => params.row.payee.account?.number,
				},
				{
					field: '_cfc',
					headerName: 'CFC',
					description: 'Cash flow class',
					width: DataGridColumnWidths.threeChar,
					valueGetter: (params) => params.row?.categorization?.class?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.class?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_cft',
					headerName: 'CFT',
					description: 'Cash flow type',
					width: DataGridColumnWidths.threeChar,
					valueGetter: (params) => params.row?.categorization?.type?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.type?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_cfst',
					headerName: 'CFST',
					description: 'Cash flow subtype',
					width: DataGridColumnWidths.fourChar,
					valueGetter: (params) => params.row?.categorization?.subtype?.code,
					renderCell: (params) => {
						return (
							<Tooltip title={params.row.categorization?.subtype?.name}>
								<Typography variant="body2" noWrap>
									{params.value}
								</Typography>
							</Tooltip>
						);
					},
				},
				{
					field: '_glCode',
					headerName: 'GL code',
					valueGetter: (params) => params.row?.categorization?.glCode?.code,
				},
			];

			if (!useCheckboxSelection) {
				columns.unshift({
					...getOptionsMenuColDef((params) => {
						return (
							<OverflowMenu id={'c4-create-reconciliation-projected-item'}>
								<MenuItem
									onClick={() => {
										handleProjectedViewClick(params.row);
									}}
								>
									<ListItemText>View</ListItemText>
								</MenuItem>
								<MenuItem
									onClick={() => {
										setSelectedProjectedItem(params.row);
										setProjectedItemDrawerOpen(true);
									}}
								>
									<ListItemText>Edit</ListItemText>
								</MenuItem>
							</OverflowMenu>
						);
					}),
				});
			}

			return columns;
		}, [
			handleProjectedViewClick,
			setProjectedItemDrawerOpen,
			setSelectedProjectedItem,
			useCheckboxSelection,
		]);

		return (
			<>
				<UserPreferencesDataGrid
					stonlyId={stonlyId}
					tableKey={tableKey}
					loading={loading}
					rows={projectedItems}
					hideFooter={false}
					showToolbar={true}
					showCustomViewButton
					pagination
					autoPageSize
					checkboxSelection={useCheckboxSelection}
					disableRowSelectionOnClick={useCheckboxSelection}
					rowSelectionModel={selectedProjectedIds}
					onRowSelectionModelChange={onRowSelectionModelChangeHandler}
					columns={columns}
					initialState={{
						sorting: {
							sortModel: [{ field: 'date', sort: 'asc' }],
						},
					}}
					pinnedColumns={{
						left: [USER_PREFERENCES_FIELD_OPTIONS],
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							hideExport: true,
							quickFilterProps: {
								variant: 'outlined',
								size: 'small',
							},
						},
					}}
				/>
				<T4Drawer
					open={openProjectionDrawer}
					onClose={handleCloseProjectionDrawer}
					stonlyPrefix="projected-transaction-details"
				>
					{projection && (
						<ProjectionDrawer projection={projection} loading={false} />
					)}
				</T4Drawer>
			</>
		);
	},
);
