import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { FC } from 'react';
import { T4Checkbox } from '../../shared/components/atoms/t4Checkbox';

export type BooleanFieldFormViewProps = {
	field: Field;
	value: boolean | undefined;
	setValue: (field: Field, value: boolean | undefined) => void;
};

export const BooleanFieldFormView: FC<BooleanFieldFormViewProps> = observer(
	({ field, value, setValue }) => {
		return (
			<T4Checkbox
				id={field.id}
				label={field.name}
				checked={value ?? false}
				onChange={(_, value) => setValue(field, value)}
				required={field.isCreationRequirement}
			/>
		);
	},
);
