import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { T4AutocompleteAsync } from 'shared/components/autocompletes/t4AutocompleteAsync';
import { Option } from 'shared/types/referenceDataTypes';
import { ObjectPathParams } from '../../../../../shared/constants/paths';
import { CommonCustomerFieldsRepository } from '../../../fields/customerFieldsRepository';
import { InputAdornmentWithModal } from '../../components/molecules/inputAdornmentWithModal';
import { FieldError } from './common/fieldError';
import { FieldViewFieldLayout } from './common/fieldLayout';
import { FieldViewProps, FieldViews } from './fieldViewTypes';

export const FieldViewOptions: FC<FieldViewProps> = observer(
	({ fieldDefinition, parentField, fieldData, customError, ...props }) => {
		const { viewType } = useProfileView();
		const { objectId } = useParams<ObjectPathParams>();

		const [value, setValue] = useState<Option | null>(
			fieldData?.initialValue
				? ({
						id: fieldData.initialValue,
						displayName: fieldData.displayValue!,
				  } as Option)
				: null,
		);

		const isReadOnly = useMemo(() => viewType !== FieldViews.edit, [viewType]);

		const onSelect = (selected: Option | null) => {
			if (selected?.id !== fieldData?.initialValue) {
				if (fieldData) {
					fieldData.save(selected?.id).then(() => setValue(selected));
				} else {
					props.createField(selected?.id);
				}
			}
		};

		const saveValue = () => {
			if (fieldData) {
				fieldData.save(value?.id);
			} else {
				props.createField(value?.id);
			}
		};

		return (
			<FieldViewFieldLayout
				entityId={objectId}
				fieldDefinition={fieldDefinition}
				fieldData={fieldData}
				approvedValueToDisplay={fieldData?.approvedReferenceValue?.displayName}
				value={fieldData?.approvedValue}
				{...props.fieldLayoutProps}
			>
				<T4AutocompleteAsync<Option, false, boolean, false>
					id={fieldDefinition.identifier}
					cacheKey={[fieldDefinition.identifier, fieldDefinition.optionListId]}
					label={fieldDefinition.name}
					onSelect={onSelect}
					value={value ?? undefined}
					optionsResolver={async () => {
						return await CommonCustomerFieldsRepository.getOptionsByOptionListSourceIdentifier(
							fieldDefinition.optionListId!,
						);
					}}
					readOnly={isReadOnly}
					isOptionEqualToValue={(option, value) => option.id === value?.id}
					getOptionLabel={(option) => option.displayName}
					startAdornment={
						<InputAdornmentWithModal
							title={fieldDefinition.name}
							description={fieldDefinition.description}
							adornmentType={fieldData?.loadingState}
							iconButtonProps={{
								...(viewType === FieldViews.default
									? {
											sx: {
												marginLeft: '-5px',
											},
									  }
									: {}),
							}}
						/>
					}
					required={fieldDefinition.isCreationRequirement}
					disableClearable={fieldDefinition.isCreationRequirement}
					textFieldProps={{
						variant: viewType === FieldViews.default ? 'standard' : 'outlined',
					}}
					sx={{
						...(viewType === FieldViews.default
							? {
									'& .MuiInputBase-root': {
										color: 'transparent',
										borderColor: 'transparent',
										borderBottomColor: 'transparent',
										'&:before, :after, :hover': {
											borderBottomColor: 'transparent !important',
										},
										padding: '0rem !important',
									},
							  }
							: {}),
					}}
				/>

				<FieldError
					error={fieldData?.loadingErrorMessage}
					customError={customError}
					onTryAgainClick={saveValue}
				/>
			</FieldViewFieldLayout>
		);
	},
);
