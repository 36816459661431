import { useCallback, useEffect, useState } from 'react';

export const useQueue = (): {
	isProcessing: boolean;
	tasks: (() => Promise<void> | void)[];
	addTask: (task: () => Promise<void> | void) => void;
	clear: () => void;
	pauseProcessing: () => void;
	resumeProcessing: () => void;
} => {
	const [shouldProcess, setShouldProcess] = useState<boolean>(true);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [tasks, setTasks] = useState<(() => Promise<void> | void)[]>([]);

	const addTask = useCallback((task: () => Promise<void> | void) => {
		setTasks((oldTasks) => [...oldTasks, task]);
	}, []);

	const clear = useCallback(() => {
		setTasks([]);
	}, []);

	useEffect(() => {
		if (!shouldProcess) return;
		if (tasks.length === 0) return;

		const task = tasks[0];
		setIsProcessing(true);
		setTasks((oldTasks) => oldTasks.slice(1));

		Promise.resolve(task()).finally(() => {
			setIsProcessing(false);
		});
	}, [shouldProcess, tasks]);

	return {
		isProcessing,
		tasks,
		addTask,
		clear,
		pauseProcessing: () => setShouldProcess(false),
		resumeProcessing: () => setShouldProcess(true),
	};
};
