import { ApprovalConfiguration } from 'features/administration/approvals/fieldApprovalConfiguration/contractObjects';
import { createContext } from 'react';

export type ApplicationConfigurationContextProps = {
	loading: boolean;
	approvalConfiguration: ApprovalConfiguration;
	setApprovalConfiguration: (
		approvalConfiguration: ApprovalConfiguration,
	) => void;
	supportedTimezones: string[];
};

export const ApplicationConfigurationContext =
	createContext<ApplicationConfigurationContextProps>(
		{} as ApplicationConfigurationContextProps,
	);
