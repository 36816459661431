import {
	LegalEntityGroup,
	LegalEntityGroupCollectionRequest,
} from 'modules/clients/customer-api/src/entity4/legalEntityGroups';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { ErrorsObject } from 'utilities/errors/errorUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';

export type UseLegalEntityGroupCollectionDrawerProps = {
	loading: boolean;
	legalEntityGroups: LegalEntityGroup[];
	name: string | null;
	nameError: string | undefined;
	description: string | null;
	descriptionError: string | undefined;
	selectedLegalEntityGroups: LegalEntityGroup[];
	setName: (name: string | null) => void;
	setDescription: (description: string | null) => void;
	setSelectedLegalEntityGroups: (legalEntityGroups: LegalEntityGroup[]) => void;
	resetForm: () => void;
	submit: () => Promise<boolean>;
};

export function useLegalEntityGroupCollectionDrawer(
	id?: string,
): UseLegalEntityGroupCollectionDrawerProps {
	const { enqueueSnackbar } = useSnackbar();
	const { legalEntityGroupCollections, createCollection, updateCollection } =
		useLegalEntityGroups();

	const _legalEntityGroupCollection = useMemo(
		() => legalEntityGroupCollections.find((x) => x.id === id),
		[id, legalEntityGroupCollections],
	);

	const legalEntityGroups = useMemo(
		() =>
			legalEntityGroupCollections
				.flatMap((x) => x.legalEntityGroups ?? [])
				.sort((a, b) => a.name.localeCompare(b.name)),
		[legalEntityGroupCollections],
	);

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState<ErrorsObject>({});

	const [name, setName] = useState<string | null>(null);
	const [description, setDescription] = useState<string | null>(null);
	const [selectedLegalEntityGroups, setSelectedLegalEntityGroups] = useState<
		LegalEntityGroup[]
	>([]);

	useEffect(() => {
		setName(_legalEntityGroupCollection?.name ?? null);
		setDescription(_legalEntityGroupCollection?.description ?? null);
		setSelectedLegalEntityGroups(
			_legalEntityGroupCollection?.legalEntityGroups ?? [],
		);
	}, [_legalEntityGroupCollection]);

	// #region Form Functions

	const setFormName = (value: string | null) => {
		setErrors((oldState) => {
			delete oldState['Name'];
			return oldState;
		});
		setName(value);
	};

	const setFormDescription = (value: string | null) => {
		setErrors((oldState) => {
			delete oldState['Description'];
			return oldState;
		});
		if (isStringUndefinedOrNullOrWhitespace(value)) setDescription(null);
		else setDescription(value);
	};

	// #endregion

	// #region Submit Actions

	const create = useCallback(async (): Promise<boolean> => {
		try {
			if (id !== undefined) return false;
			setErrors({});
			setLoading(true);

			const response = await createCollection({
				name: name!.trim(),
				description: description !== null ? description.trim() : null,
				legalEntityGroupIds: selectedLegalEntityGroups.map((x) => x.id),
			});
			if (!response.success) {
				setErrors(response.errors);
				return false;
			}

			enqueueSnackbar('Successfully created entity group collection.', {
				variant: 'success',
			});
			return true;
		} catch {
			enqueueSnackbar(
				'An unexpected error occured when creating the entity group collection. Please try again.',
				{
					variant: 'error',
				},
			);
			return false;
		} finally {
			setLoading(false);
		}
	}, [
		id,
		name,
		description,
		selectedLegalEntityGroups,
		createCollection,
		enqueueSnackbar,
	]);

	const update = useCallback(async (): Promise<boolean> => {
		try {
			if (!id) return false;
			setErrors({});
			setLoading(true);

			const request = {
				name: name!.trim(),
				description: description?.trim(),
				legalEntityGroupIds: selectedLegalEntityGroups.map((x) => x.id),
			} as LegalEntityGroupCollectionRequest;

			const response = await updateCollection(id, request);

			if (!response.success) {
				setErrors(response.errors);
				return false;
			}

			enqueueSnackbar('Successfully updated entity group collection.', {
				variant: 'success',
			});
			return true;
		} catch {
			enqueueSnackbar(
				'An unexpected error occured when updating the entity group collection. Please try again.',
				{
					variant: 'error',
				},
			);
			return false;
		} finally {
			setLoading(false);
		}
	}, [
		id,
		name,
		description,
		selectedLegalEntityGroups,
		updateCollection,
		enqueueSnackbar,
	]);

	// #endregion

	// #region State Cleanup Functions

	const resetForm = useCallback(() => {
		setLoading(false);
		setErrors({});
		setName(null);
		setDescription(null);
		setSelectedLegalEntityGroups([]);
	}, []);

	useEffect(() => {
		return () => {
			resetForm();
		};
	}, [resetForm]);

	// #endregion

	return {
		loading,
		legalEntityGroups,
		name,
		nameError: errors['Name']?.join(' '),
		description,
		descriptionError: errors['Description']?.join(' '),
		selectedLegalEntityGroups,
		setName: setFormName,
		setDescription: setFormDescription,
		setSelectedLegalEntityGroups,
		resetForm,
		submit: !id ? create : update,
	};
}
