import { AxiosInstance } from 'axios';
import { Write } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Field, ObjectInput } from '../..';

//#region Request Models

export type SubaccountInput = ObjectInput & {
	subaccountId: string;
};

export type ApproveObjectFieldInput = SubaccountInput & {
	fieldId: string;
};

export type RejectField = {
	comment: string;
};

export type RejectObjectFieldInput = SubaccountInput &
	RejectField & {
		fieldId: string;
	};

export type UpdateField = {
	fieldIdentifier: string;
	value: any;
};

export type UpdateFieldInput = SubaccountInput & UpdateField;

export type PhoneNumberCreateField = {
	identifier: string;
	countryCode: string | null;
	number: string | null;
	extension: string | null;
};

export type CreatePhoneNumberFieldInput = SubaccountInput &
	PhoneNumberCreateField;

export type PhoneNumberUpdateField = {
	countryCode: string | null;
	number: string | null;
	extension: string | null;
};

export type UpdatePhoneNumberFieldInput = SubaccountInput &
	PhoneNumberUpdateField & {
		fieldId: string;
	};

//#endregion

export type SubaccountFieldEndpoints = {
	update: Write<ApiResponse<Field>, UpdateFieldInput, UpdateField>;
	phoneNumberCreate: Write<
		ApiResponse<Field>,
		CreatePhoneNumberFieldInput,
		PhoneNumberCreateField
	>;
	phoneNumberUpdate: Write<
		ApiResponse<Field>,
		UpdatePhoneNumberFieldInput,
		PhoneNumberUpdateField
	>;
	approve: Write<ApiResponse<Field>, ApproveObjectFieldInput>;
	reject: Write<ApiResponse<Field>, RejectObjectFieldInput, RejectField>;
};

export function subaccountFields(
	axiosInstance: AxiosInstance,
): SubaccountFieldEndpoints {
	return {
		update: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}`,
				{
					fieldIdentifier: input.fieldIdentifier,
					value: input.value,
				},
				{
					...config,
				},
			),
		phoneNumberCreate: async (input, config = {}) =>
			await axiosInstance.post(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/fields/phonenumber`,
				{
					identifier: input.identifier,
					countryCode: input.countryCode ?? null,
					number: input.number ?? null,
					extension: input.extension ?? null,
				},
				{
					...config,
				},
			),
		phoneNumberUpdate: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/fields/${input!.fieldId}/phonenumber`,
				{
					countryCode: input.countryCode ?? null,
					number: input.number ?? null,
					extension: input.extension ?? null,
				},
				{
					...config,
				},
			),
		approve: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/fields/${input!.fieldId}/approve`,
				undefined,
				{
					...config,
				},
			),
		reject: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/fields/${input!.fieldId}/reject`,
				{
					comment: input.comment ?? null,
				},
				{
					...config,
				},
			),
	};
}
