import { Add, ArrowDropDown, Cached, FolderOpen } from '@mui/icons-material';
import { Alert, Breadcrumbs, Grid, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { SigmaWorkbook } from 'modules/clients/customer-api/src/sigmaEmbed';
import { FC, useCallback, useEffect, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { T4View } from 'shared/components/t4View';
import { NOT_FOUND_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { AddFolderButton } from 'shared/dashboardPage/addFolderButton';
import { AddReportButton } from 'shared/dashboardPage/addReportButton';
import { DashboardOptions } from 'shared/dashboardPage/dashboardOptions';
import { useSigma } from '../providers/sigmaProvider';
import { SigmaButton } from './sigmaButton';

export const SigmaEmbed: FC = observer(() => {
	const {
		initializing,
		sigmaUrl,
		workbooks,
		workbook,
		folders,
		folder,
		generateSigmaUrl,
		selectFolder,
		selectWorkbook,
	} = useSigma();

	const [filteredWorkbooks, setFilteredWorkbooks] = useState<SigmaWorkbook[]>(
		[],
	);

	useEffect(() => {
		setFilteredWorkbooks(workbooks);
	}, [workbooks]);

	const workbooksUpdated = useCallback(() => {
		if (folder) {
			const filtered = workbooks.filter((x) => x.folderId === folder.id);
			setFilteredWorkbooks(filtered);
		}
	}, [folder, workbooks]);

	useEffect(() => {
		workbooksUpdated();
	}, [folder, workbooksUpdated]);

	const [anchorElFolder, setAnchorElFolder] = useState<null | HTMLElement>(
		null,
	);
	const handleClickFolder = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElFolder(event.currentTarget);
	};
	const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(
		null,
	);
	const handleClickReport = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElReport(event.currentTarget);
	};
	const handleCloseFolder = () => {
		setAnchorElFolder(null);
	};
	const handleCloseReport = () => {
		setAnchorElReport(null);
	};

	const [openFolderAdd, setOpenFolderAdd] = useState(false);
	const [openReportAdd, setOpenReportAdd] = useState(false);
	const [showWarning, setShowWarning] = useState(true);

	const handleOpenFolderAdd = () => {
		setOpenFolderAdd(true);
		setAnchorElFolder(null);
	};
	const handleOpenReportAdd = () => {
		setAnchorElReport(null);
		setOpenReportAdd(true);
	};
	return (
		<T4View disablePadding={true} loading={initializing}>
			<AddFolderButton open={openFolderAdd} setOpen={setOpenFolderAdd} />
			<AddReportButton open={openReportAdd} setOpen={setOpenReportAdd} />
			<Grid container spacing={0.5} direction="column" sx={{ height: '100%' }}>
				{showWarning && (
					<Alert
						severity="info"
						onClose={() => {
							setShowWarning(false);
						}}
						sx={{ margin: 2, marginBottom: 0 }}
					>
						<b>Previewing Analytics Studio Beta. </b>This feature is currently
						under development, and unexpected changes may occur.
					</Alert>
				)}
				{folders.length > 0 && (
					<Grid item marginLeft={2}>
						<Grid container marginBottom={0} height={50}>
							<Grid item xs={7}>
								<Breadcrumbs aria-label="breadcrumb" separator="›">
									<SigmaButton
										onClick={handleClickFolder}
										style={{ cursor: 'pointer' }}
									>
										{folder?.name} <ArrowDropDown />
									</SigmaButton>

									{workbook && (
										<SigmaButton
											onClick={handleClickReport}
											style={{ cursor: 'pointer' }}
										>
											{workbook.name} <ArrowDropDown />{' '}
										</SigmaButton>
									)}
									{!workbook}
								</Breadcrumbs>
							</Grid>
							<Grid item container xs={5} justifyContent="flex-end">
								<SigmaButton
									startIcon={<Cached />}
									onClick={() => generateSigmaUrl()}
								/>
								<DashboardOptions />
							</Grid>
							<Menu
								anchorEl={anchorElFolder}
								open={Boolean(anchorElFolder)}
								onClose={handleCloseFolder}
							>
								{folders.map((f) => (
									<MenuItem
										key={f.id}
										selected={f.id === folder?.id}
										onClick={() => {
											selectFolder(f);
											handleCloseFolder();
										}}
									>
										{f.name}
									</MenuItem>
								))}
								<MenuItem>
									<SigmaButton
										variant="outlined"
										startIcon={<FolderOpen />}
										onClick={handleOpenFolderAdd}
									>
										Create Folder
									</SigmaButton>
								</MenuItem>
							</Menu>
							<Menu
								anchorEl={anchorElReport}
								open={Boolean(anchorElReport)}
								onClose={handleCloseReport}
							>
								{filteredWorkbooks.length > 0 ? (
									filteredWorkbooks.map((f) => (
										<MenuItem
											key={f.id}
											selected={f.id === workbook?.id}
											onClick={() => {
												selectWorkbook(f);
												handleCloseReport();
											}}
										>
											{f.name}
										</MenuItem>
									))
								) : (
									<MenuItem>No workbooks found</MenuItem>
								)}
								<MenuItem>
									<SigmaButton
										variant="outlined"
										startIcon={<Add />}
										onClick={handleOpenReportAdd}
									>
										Create
									</SigmaButton>
								</MenuItem>
							</Menu>

							{filteredWorkbooks.length === 0 && (
								<SigmaButton
									variant="outlined"
									startIcon={<Add />}
									onClick={handleOpenReportAdd}
								>
									Create
								</SigmaButton>
							)}
						</Grid>
					</Grid>
				)}

				<Grid item sx={{ flex: 2, display: 'flex' }}>
					{workbooks.filter((x) => x.folderId === folder?.id).length !== 0 ? (
						<iframe
							title="Report"
							width="100%"
							style={{ flex: 1, border: 'none' }}
							src={sigmaUrl}
						/>
					) : (
						<CannotDisplay
							headingText="Whoops! Nothing here yet..."
							bodyText="Let's change that"
							imageSrc={NOT_FOUND_MESSAGING.IMAGE}
						/>
					)}
				</Grid>
			</Grid>
		</T4View>
	);
});
