export const stonlyIds = {
	balancesTable: 'balances',
	balancesRowContextMenu: 'balances-row-context-menu',
	balancesRowContextMenuDelete: 'balances-row-context-menu-delete',
	balancesRowContextMenuView: 'balances-row-context-menu-view',
	balancesDeleteConfirmationModal: 'balances-delete-confirmation-modal',
	balancesDeleteConfirmationCancelButton: 'balances-delete-confirmation-cancel',
	balancesDeleteConfirmationDeleteButton: 'balances-delete-confirmation-delete',
	balancesDisplayCurrencyDropdown: 'balances-display-currency-dropdown',
};
