import { useAdminViewMode } from 'features/administration/_hooks/useAdminViewMode';
import { AdminViewModes } from 'features/administration/providers/contexts/adminViewModeContext';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'shared/constants/paths';

export type UseGroupNavigationProps = (
	groupId?: string,
	view?: AdminViewModes,
) => void;

export const useGroupNavigation = (): UseGroupNavigationProps => {
	const history = useHistory();
	const { setViewMode } = useAdminViewMode();

	return (groupId?: string, view?: AdminViewModes) => {
		if (groupId) {
			setViewMode(view);
			history.push(
				generatePath(paths.administration.groups.group.information.href, {
					groupId: groupId,
				}),
			);
		}
	};
};
