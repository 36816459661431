import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { FC } from 'react';
import {
	T4DatePicker,
	T4DatePickerProps,
} from 'shared/components/t4DatePicker';

export type AsOfDatePickerProps = T4DatePickerProps<Moment>;

export const AsOfDatePicker: FC<AsOfDatePickerProps> = observer(
	({ ...props }) => {
		return (
			<T4DatePicker
				disableFuture
				{...props}
				slotProps={{
					...props?.slotProps,
					field: {
						...props?.slotProps?.field,
						InputProps: {
							startAdornment: (
								<Typography
									noWrap
									sx={(theme) => ({
										paddingLeft: '8px',
										paddingRight: '20px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',

										color: theme.palette.primary.main,
									})}
								>
									{'As of'}
								</Typography>
							),
							...(props?.slotProps?.field as any)?.InputProps,
						},
					},
					textField: {
						size: 'small',
						...props?.slotProps?.textField,

						InputLabelProps: {
							shrink: true,
							...(props?.slotProps?.textField as any)?.InputLabelProps,
						},

						sx: (theme) => ({
							height: '40px',
							backgroundColor: 'white',
							'& input': {
								paddingLeft: '8px',
								borderLeft: '1px solid',
								borderColor: theme.palette.primary.main,
								textTransform: 'uppercase',
								...(props?.slotProps?.textField as any)?.sx?.['& input'],
							},
							'& .MuiFormControl-root-MuiTextField-root': {
								color: theme.palette.text.primary,
								...(props?.slotProps?.textField as any)?.sx?.[
									'& .MuiFormControl-root-MuiTextField-root'
								],
							},
							'& .MuiFormLabel-root.Mui-disabled': {
								color: theme.palette.text.primary,
								...(props?.slotProps?.textField as any)?.sx?.[
									'& .MuiFormLabel-root.Mui-disabled'
								],
							},
							'& .MuiOutlinedInput-notchedOutline': {
								borderColor: theme.palette.primary.main,
								borderWidth: '1px',
								...(props?.slotProps?.textField as any)?.sx?.[
									'& .MuiOutlinedInput-notchedOutline'
								],
							},
							'& .MuiOutlinedInput-root': {
								'&.Mui-focused fieldset': {
									borderColor: theme.palette.primary.main,
									borderWidth: '2px',
									...(props?.slotProps?.textField as any)?.sx?.[
										'& .MuiOutlinedInput-root'
									]?.['&.Mui-focused fieldset'],
								},
								'&:hover fieldset': {
									borderColor: theme.palette.primary.main,
									borderWidth: '2px',
									...(props?.slotProps?.textField as any)?.sx?.[
										'& .MuiOutlinedInput-root'
									]?.['&:hover fieldset'],
								},
								...(props?.slotProps?.textField as any)?.sx?.[
									'& .MuiOutlinedInput-root'
								],
							},
							...(props?.slotProps?.textField as any)?.sx,
						}),
					},
				}}
			/>
		);
	},
);
