import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EntityTypeId, getEntityType } from 'features/entity4/entity4Constants';
import { flow, makeAutoObservable } from 'mobx';
import { PowerOfAttorneyType } from 'modules/clients/customer-api/src/api/referenceData';
import { useEffect, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { ObjectPathParams, paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import {
	getDateColumnDefinition,
	getMultiSelectGridColDef,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { getOptionsMenuColDef } from 'shared/utilities/dataGrid/dataGridUtils';
import { T4Link } from '../../../../shared/components/t4Link';
import { CreateRelationshipDrawerViewModel } from '../components/createRelationshipDrawer/createRelationshipDrawerViewModel';
import { DeleteRelationshipViewModel } from '../components/deleteRelationshipViewModel';
import {
	EditRelationshipDrawerViewModel,
	rowToRelationshipData,
} from '../components/editRelationshipDrawer/editRelationshipDrawerViewModel';
import { RelationshipOptionsCell } from '../components/relationshipOptionCell';
import { usePowerOfAttorneys } from '../providers/powerOfAttorneysProvider';
import { getRelationshipColumns } from '../relationshipUtilities';
import { BaseRelationship } from '../relationshipsObjectTypes';
import {
	EntityRelationshipsListModel,
	LegalEntityPowerOfAttorneyRelationship,
} from './entityRelationshipsListModel';

export type AllowedTab =
	| 'Accounts'
	| 'Ownership'
	| 'Staff'
	| 'Power of Attorney';

export class EntityRelationshipsViewModel {
	private readonly _entityId: string;
	private _isAuthor: boolean;

	private listDataModel: EntityRelationshipsListModel;

	public _createRelationshipViewModel: CreateRelationshipDrawerViewModel;
	public readonly editRelationshipDrawerViewModel: EditRelationshipDrawerViewModel;
	public readonly deleteRelationshipViewModel: DeleteRelationshipViewModel;

	constructor(
		entityId: string,
		isAuthor: boolean,
		powerOfAttorneys: PowerOfAttorneyType[],
	) {
		makeAutoObservable(this);

		this._entityId = entityId;
		this._isAuthor = isAuthor;

		this.listDataModel = new EntityRelationshipsListModel(
			entityId,
			powerOfAttorneys,
		);
		this._createRelationshipViewModel = new CreateRelationshipDrawerViewModel(
			EntityTypeId.InternalEntity,
			this._entityId,
			() => this.load(),
			powerOfAttorneys,
		);
		this.editRelationshipDrawerViewModel = new EditRelationshipDrawerViewModel(
			EntityTypeId.InternalEntity,
			this._entityId,
			() => this.load(),
			powerOfAttorneys,
		);
		this.deleteRelationshipViewModel = new DeleteRelationshipViewModel(
			EntityTypeId.InternalEntity,
			this._entityId,
			() => this.load(),
		);
	}

	public getCreateViewModel = () => this._createRelationshipViewModel;

	public loading = () => this.listDataModel.loading;
	public error = () => this.listDataModel.error;
	public getEntityId = () => this._entityId;
	public isAuthor = () => this._isAuthor;
	public setIsAuthor = (isAuthor: boolean) => (this._isAuthor = isAuthor);

	// tab functions
	public getTabList = (): AllowedTab[] => [
		'Accounts',
		'Ownership',
		'Staff',
		'Power of Attorney',
	];

	public getEntityName = () => this.listDataModel.data?.entityName;

	// ownership tables
	public getParentColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'owner',
				headerName: 'Owner',
				description: 'Owner',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'ownershipLevel',
				headerName: 'Ownership Level',
				description: 'Ownership Level',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getParentRows = () => this.listDataModel.data?.parents ?? [];

	public getChildrenColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'childEntity',
				headerName: 'Child Entity',
				description: 'Child Entity',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'formOfOrganization',
				headerName: 'Form of Organization',
				description: 'Form of Organization',
				flex: 1,
			},
			{
				field: 'ownershipLevel',
				headerName: 'Ownership Level',
				description: 'Ownership Level',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getChildrenRows = () => this.listDataModel.data?.children ?? [];

	// account relationships
	public getAccountColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'accountName',
				headerName: 'Account Name',
				description: 'Account Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'accountStatus',
				headerName: 'Account Status',
				description: 'Account Status',
				flex: 1,
			},
			{
				field: 'counterpartyName',
				headerName: 'Counterparty Name',
				description: 'Counterparty Name',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getAccountRows = () => this.listDataModel.data?.accounts ?? [];

	// staff relationships
	public getStaffColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'staffName',
				headerName: 'Staff Name',
				description: 'Staff Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'title',
				headerName: 'Title',
				description: 'Title',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getStaffRows = () => this.listDataModel.data?.staff ?? [];

	public getPowerOfAttorneyColumns =
		(): GridColDef<LegalEntityPowerOfAttorneyRelationship>[] => {
			const columns: GridColDef<LegalEntityPowerOfAttorneyRelationship>[] = [
				{
					field: 'staffDisplayName',
					headerName: 'Staff Name',
					description: 'Staff Name',
					flex: 1,
					renderCell: (params: GridRenderCellParams) => {
						const path = generatePath(
							paths.entity4.objects.object.information.href,
							{
								objectType: getEntityType(params.row.entityTypeId)!,
								objectId: params.row.entityId,
							},
						);

						return (
							<T4Link to={path} color="secondary">
								{params.formattedValue}
							</T4Link>
						);
					},
				},
				{
					...getMultiSelectGridColDef<
						LegalEntityPowerOfAttorneyRelationship,
						string | undefined
					>(this.listDataModel.powerOfAttorneys.map((x) => x.description)),
					field: 'typeOfPowerOfAttorneyId',
					headerName: 'Power of Attorney',
					description: 'Power of Attorney',
					valueGetter: ({ value }) =>
						this.listDataModel.powerOfAttorneys.find((x) => x.id === value)
							?.description ?? '',
				},
				...getRelationshipColumns<LegalEntityPowerOfAttorneyRelationship>(),
			];

			if (this.isAuthor()) {
				columns.unshift(
					getOptionsMenuColDef<LegalEntityPowerOfAttorneyRelationship>(
						(params: GridRenderCellParams<BaseRelationship, string>) => (
							<RelationshipOptionsCell
								editRelationshipDrawerViewModel={
									this.editRelationshipDrawerViewModel
								}
								deleteRelationshipViewModel={this.deleteRelationshipViewModel}
								relationship={rowToRelationshipData(
									EntityTypeId.InternalEntity,
									params.row,
								)}
							/>
						),
					),
				);
			}

			return columns;
		};
	public getPowerOfAttorneyRelationships = () =>
		this.listDataModel.data?.powerOfAttorneys ?? [];

	private addOptionsColumn = (cols: GridColDef[]) => {
		if (this._isAuthor) {
			cols.unshift({
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<BaseRelationship, string>) => (
						<RelationshipOptionsCell
							editRelationshipDrawerViewModel={
								this.editRelationshipDrawerViewModel
							}
							deleteRelationshipViewModel={this.deleteRelationshipViewModel}
							relationship={rowToRelationshipData(
								EntityTypeId.InternalEntity,
								params.row,
							)}
						/>
					),
				),
			});
		}

		return cols;
	};
	// relationships load function
	public load = flow(function* (this: EntityRelationshipsViewModel) {
		yield this.listDataModel.load();
		this._createRelationshipViewModel.initialize(
			this.getEntityName(),
			this.listDataModel.flatData,
		);
		this.editRelationshipDrawerViewModel.setEntityName(this.getEntityName());
	});
}

export const useEntityRelationshipViewModel = () => {
	const { powerOfAttorneys } = usePowerOfAttorneys();
	const { objectId } = useParams<ObjectPathParams>();
	const { isAuthor } = useUser();
	const viewModel = useMemo(
		() =>
			new EntityRelationshipsViewModel(objectId, isAuthor, powerOfAttorneys),
		[objectId, isAuthor, powerOfAttorneys],
	);

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	useEffect(() => {
		viewModel.setIsAuthor(isAuthor);
	}, [isAuthor, viewModel]);

	return viewModel;
};
