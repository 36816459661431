import {
	AccountBalance,
	DescriptionOutlined,
	Diversity3,
	Domain,
	Group,
	Key,
} from '@mui/icons-material';
import { Grid, Paper, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
	Dashboard,
	DashboardCounts,
} from 'modules/clients/customer-api/src/entity4';
import { FC, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import {
	DashboardCard,
	DashboardCardProps,
} from 'shared/components/dashboardCard';
import { T4Link } from 'shared/components/t4Link';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { stonlyData } from 'stonly/functions';
import { EntityType } from '../../entity4Constants';
import { ExpiringRegistrationsList } from '../components/expiringRegistrationsList';
import { RecentlyUpdatedList } from '../components/recentlyUpdatedList';

export const testIds = {
	entitiesCountCard: 'entities-count-card',
	partnersCountCard: 'partners-count-card',
	counterpartiesCountCard: 'counterparties-count-card',
	accountsCountCard: 'accounts-cound-card',
	staffCountCard: 'staff-count-card',
	documentsCountCard: 'documents-count-card',

	expiringRegistrations: 'expiring-registrations',
	recentlyUpdated: 'recently-updated',
};

export const DashbaordPage: FC = observer(() => {
	const { customerApiClient } = useClients();
	const { fetch } = useReferenceDataFetcher();
	const [loading, setLoading] = useState(true);
	const [dashboard, setDashboard] = useState<Dashboard>({
		counts: {
			entities: 0,
			staff: 0,
			partners: 0,
			counterparties: 0,
			accounts: 0,
			documents: 0,
		},
		expiringRegistrations: [],
		recentlyUpdated: [],
	});
	const [countriesList, setCountriesList] = useState<string[]>([]);
	useEffect(() => {
		async function fetchDashboard() {
			try {
				setLoading(true);

				var result = await customerApiClient.entity4.dashboard();

				if (result.data.data) {
					if (result.data.error) {
					} else if (
						result.data.errors &&
						Object.entries(result.data.errors).length > 0
					) {
					} else {
						setDashboard(result.data.data);
					}
				} else {
					throw new Error('No data returned.');
				}
				const listResponse = await fetch({
					referenceListNames: ['Countries'],
					optionListIds: [],
				});
				if (listResponse) {
					const countries = listResponse.referenceLists['Countries'] ?? [];
					setCountriesList([...new Set(countries.map((x) => x.displayName))]);
				}
			} catch {
				/* noop */
			} finally {
				setLoading(false);
			}
		}

		fetchDashboard();
	}, [fetch, customerApiClient]);

	function getAttributes(key: keyof DashboardCounts): Pick<
		DashboardCardProps,
		'icon' | 'label' | 'dashboardCardProps'
	> & {
		link: string;
	} {
		switch (key) {
			case 'entities':
				return {
					label: 'Entities',
					icon: <Domain />,
					dashboardCardProps: {
						...stonlyData({ id: 'entities-count-card' }),
						...{ 'data-testid': testIds.entitiesCountCard },
					} as any,
					link: generatePath(paths.entity4.objects.href, {
						objectType: EntityType.Entity,
					}),
				};
			case 'staff':
				return {
					label: 'Staff',
					icon: <Group />,
					dashboardCardProps: {
						...stonlyData({ id: 'staff-count-card' }),
						...{ 'data-testid': testIds.staffCountCard },
					} as any,
					link: generatePath(paths.entity4.objects.href, {
						objectType: EntityType.Staff,
					}),
				};
			case 'partners':
				return {
					label: 'Partners',
					icon: <Diversity3 />,
					dashboardCardProps: {
						...stonlyData({ id: 'partners-count-card' }),
						...{ 'data-testid': testIds.partnersCountCard },
					} as any,
					link: generatePath(paths.entity4.objects.href, {
						objectType: EntityType.Partner,
					}),
				};
			case 'counterparties':
				return {
					label: 'Counterparties',
					icon: <AccountBalance />,
					dashboardCardProps: {
						...stonlyData({ id: 'counterparties-count-card' }),
						...{ 'data-testid': testIds.counterpartiesCountCard },
					} as any,
					link: generatePath(paths.entity4.objects.href, {
						objectType: EntityType.Counterparty,
					}),
				};
			case 'accounts':
				return {
					label: 'Accounts',
					icon: <Key />,
					dashboardCardProps: {
						...stonlyData({ id: 'accounts-count-card' }),
						...{ 'data-testid': testIds.accountsCountCard },
					} as any,
					link: generatePath(paths.entity4.objects.href, {
						objectType: EntityType.Account,
					}),
				};
			case 'documents':
				return {
					label: 'Documents',
					icon: <DescriptionOutlined />,
					dashboardCardProps: {
						...stonlyData({ id: 'documents-count-card' }),
						...{ 'data-testid': testIds.documentsCountCard },
					} as any,
					link: generatePath(paths.entity4.documents.href),
				};
			default:
				return { label: '', link: '' };
		}
	}

	return (
		<T4View loading={loading}>
			<Grid container spacing={3}>
				<Grid container item xs={12} spacing={1}>
					{Object.entries(
						dashboard?.counts ?? {
							accounts: 0,
							counterparties: 0,
							documents: 0,
							entities: 0,
							partners: 0,
							staff: 0,
						},
					).map(([key, value]) => {
						const { link, dashboardCardProps, ...rest } = getAttributes(
							key as keyof DashboardCounts,
						);

						return (
							<Grid key={key} item xs={12} md={4} lg={3} xl={2}>
								<T4Link to={link} disableTextDecoration>
									<DashboardCard
										{...rest}
										loading={false}
										value={value}
										dashboardCardProps={{
											...dashboardCardProps,
											sx: {
												'& .MuiCardHeader-avatar': {
													color: 'white',
												},
											},
										}}
									/>
								</T4Link>
							</Grid>
						);
					})}
				</Grid>
				<Grid container item xs={12} spacing={3}>
					{[
						[
							'Expiring Registrations',
							<ExpiringRegistrationsList
								{...{ 'data-testid': testIds.expiringRegistrations }}
								registrations={dashboard.expiringRegistrations}
								countries={countriesList}
							/>,
						],
						[
							'Recently Updated',
							<RecentlyUpdatedList
								{...{ 'data-testid': testIds.recentlyUpdated }}
								recentlyUpdated={dashboard.recentlyUpdated}
							/>,
						],
					].map(([title, component], index) => (
						<Grid key={`dashboard-item-${index}`} item xs={12} lg={6}>
							<Paper sx={{ padding: '1rem' }}>
								<Grid
									container
									sx={{
										rowGap: 2,
									}}
								>
									<Grid item xs={12}>
										<Typography variant="h5">{title}</Typography>
									</Grid>
									<Grid item xs={12}>
										{component}
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					))}
				</Grid>
			</Grid>
		</T4View>
	);
});
