import { useCallback, useMemo, useState } from 'react';
import { FrontendGroupRepository, GroupDto } from '../../groupRepository';
import { parseError } from 'utilities/errors/errorUtils';

interface UseGroupListProps {
	isLoading: boolean;
	error: string | undefined;
	groups: GroupDto[];
	ungroupedEntitiesCount: number;
	load: () => Promise<void>;
}

export const useGroupList = (): UseGroupListProps => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [groups, setGroups] = useState<GroupDto[]>([]);
	const [ungroupedEntitiesCount, setUngroupedEntitiesCount] =
		useState<number>(0);

	const groupsFrontendRepository = useMemo(
		() => new FrontendGroupRepository(),
		[],
	);

	const load = useCallback(async () => {
		try {
			setIsLoading(true);
			setError(undefined);

			var groupsResponse = await groupsFrontendRepository.getGroups();
			setGroups(groupsResponse.value);
			var ungroupedEntitiesResponse =
				await groupsFrontendRepository.getUngroupedEntitiesCount();
			setUngroupedEntitiesCount(ungroupedEntitiesResponse.value);
		} catch (error: any) {
			setError(parseError(error));
		} finally {
			setIsLoading(false);
		}
	}, [groupsFrontendRepository]);

	return {
		isLoading,
		error,
		groups,
		ungroupedEntitiesCount,
		load,
	};
};
