import { AxiosResponse } from 'axios';
import { RestrictedAccessError } from 'utilities/errors/restrictedAccessError';
import { UnexpectedError } from 'utilities/errors/unexpectedError';

export const responseInterceptor = (response: AxiosResponse<unknown, any>) => {
	if (response.status === 403) {
		throw RestrictedAccessError;
	} else if (response.status >= 500) {
		throw UnexpectedError;
	}

	return response;
};
