import { isStringUndefinedOrNullOrWhitespace } from './stringUtils';

export function formatCurrency(
	value: number | null | undefined,
	options?: Intl.NumberFormatOptions,
	locale?: string,
): string | null {
	if (value === undefined || value === null) {
		return null;
	}

	try {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currencySign: 'accounting',
			currencyDisplay: 'narrowSymbol',
			...options,
		}).format(value);
	} catch (error) {
		console.warn(
			`Invalid currency code: ${options?.currency}. Returning raw number.`,
		);
		return new Intl.NumberFormat(locale).format(value);
	}
}

/**
 * Gets currency symbol using en-US local (for now) and a currency code.
 * @param locale String representation of locale EX: en-US (English-UnitedStates)
 * @param currencyCode The 3 character currency code for the symbol you want
 * @returns currency symbol as a string.
 */
export const getCurrencySymbol = (
	locale?: string | null,
	currencyCode?: string | null,
): string | undefined => {
	if (
		isStringUndefinedOrNullOrWhitespace(locale) ||
		isStringUndefinedOrNullOrWhitespace(currencyCode)
	)
		return undefined;

	return (0)
		.toLocaleString(locale!, {
			style: 'currency',
			currency: currencyCode!,
			currencySign: 'accounting',
			currencyDisplay: 'narrowSymbol',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		})
		.replace(/\d/g, '')
		.trim();
};
