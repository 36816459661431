import { Divider, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { DeleteRelationshipViewModel } from './deleteRelationshipViewModel';
import {
	EditRelationshipDrawerViewModel,
	IEditRelationshipData,
} from './editRelationshipDrawer/editRelationshipDrawerViewModel';

export interface RelationshipOptionsCellProps {
	editRelationshipDrawerViewModel: EditRelationshipDrawerViewModel;
	deleteRelationshipViewModel: DeleteRelationshipViewModel;
	relationship: IEditRelationshipData;
}
export const RelationshipOptionsCell = observer(
	({
		editRelationshipDrawerViewModel,
		deleteRelationshipViewModel,
		relationship,
	}: RelationshipOptionsCellProps) => (
		<OverflowMenu id={`relationship-overflow-menu-${relationship.id}`}>
			<MenuItem
				onClick={() => editRelationshipDrawerViewModel.openDrawer(relationship)}
			>
				Edit Relationship
			</MenuItem>
			<Divider />
			<DeleteMenuItem
				onClick={() => deleteRelationshipViewModel.openModal(relationship.id)}
			/>
		</OverflowMenu>
	),
);
