import { Delete } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	DialogActions,
	Divider,
	Grid,
	MenuItem,
	Paper,
	Typography,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { getSingleSelectGridColDef } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	USER_PREFERENCES_FIELD_OPTIONS,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { DeleteMenuItem } from '../../../../../../shared/components/deleteMenuItem';
import ModalBase from '../../../../../../shared/components/modalBase';
import { OverflowMenu } from '../../../../../../shared/components/overflowMenu';
import { T4Link } from '../../../../../../shared/components/t4Link';
import {
	ObjectPathParams,
	paths,
} from '../../../../../../shared/constants/paths';
import { useUser } from '../../../../../../shared/hooks/useUser';
import { aspectUrlIds } from '../../../../entity4Constants';
import { AddRegisteredAgentJurisdictionButton } from '../../../../entityAspect/RegisteredAgentDetails/components/addRegisteredAgentJurisdictionButton';
import { AddJurisdictionViewModel } from '../../../../entityAspect/RegisteredAgentDetails/models/addJurisdictionViewModel';
import { Jurisdiction } from '../../../../entityAspect/RegisteredAgentDetails/models/jurisdictionModel';
import { RegisteredAgentJurisdictionListViewModel } from '../../../../entityAspect/RegisteredAgentDetails/models/registeredAgentJurisdictionListViewModel';
import { useProfileView } from '../../../../entityProfile/providers/entityProfileContextProvider';
import { FieldViews } from '../../../../shared/fieldSets/fieldViews/fieldViewTypes';

export const ObjectRegisteredAgentJurisdictionsPage: FC = observer(() => {
	const history = useHistory();
	const { isAuthor } = useUser();
	const { setViewType, aspectManager } = useProfileView();
	const { objectType, objectId } = useParams<ObjectPathParams>();
	const { fetch } = useReferenceDataFetcher();

	const viewModel = useMemo(
		() =>
			new RegisteredAgentJurisdictionListViewModel(
				objectId,
				objectType,
				aspectUrlIds.registeredAgentDetails,
				fetch,
			),
		[fetch, objectId, objectType],
	);

	const navToJurisdiction = useCallback(
		(
			setViewType: (update: FieldViews) => void,
			jurisdictionId: string,
			isEdit = false,
		) => {
			setViewType(isEdit ? FieldViews.edit : FieldViews.default);
			history.push(
				generatePath(
					paths.entity4.objects.object.registeredAgentDetails
						.registeredAgentDetail.href,
					{
						objectType: objectType,
						objectId: objectId,
						registeredAgentDetailId: jurisdictionId,
					},
				),
			);
		},
		[history, objectId, objectType],
	);

	const columns = useMemo(
		() => [
			getOptionsMenuColDef<Jurisdiction>(({ row }) => (
				<OverflowMenu id={`jurisdiction-overflow-menu-${row.id}`}>
					<MenuItem
						onClick={() => {
							navToJurisdiction(setViewType, row.id);
						}}
					>
						View Details
					</MenuItem>
					{isAuthor && (
						<MenuItem
							onClick={() => {
								navToJurisdiction(setViewType, row.id, true);
							}}
						>
							Edit Details
						</MenuItem>
					)}
					{isAuthor && <Divider />}
					{isAuthor && (
						<DeleteMenuItem
							onClick={() => {
								viewModel.openDeleteModal(row);
							}}
						/>
					)}
				</OverflowMenu>
			)),
			{
				field: 'location',
				headerName: 'Location',
				flex: 70,
				minWidth: 150,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.registeredAgentDetails
							.registeredAgentDetail.href,
						{
							objectType: objectType,
							objectId: objectId,
							registeredAgentDetailId: params.id,
						},
					);
					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
				...getSingleSelectGridColDef(viewModel.getLocations()),
			},
			{
				field: 'streetAddress',
				headerName: 'Street Address',
				flex: 50,
				minWidth: 150,
			},
			{
				field: 'mailingAddress',
				headerName: 'Mailing Address',
				flex: 50,
				minWidth: 150,
			},
		],
		[isAuthor, navToJurisdiction, objectId, objectType, setViewType, viewModel],
	);

	useEffect(() => {
		setViewType(FieldViews.default);
	}, [setViewType]);

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	if (viewModel.loading) {
		return (
			<Box width="100%" my={5} py={2} display="flex" justifyContent="center">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Grid container sx={{ gap: 2, paddingY: '1.5rem', paddingX: '2rem' }}>
			<Grid
				container
				item
				xs={12}
				sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
			>
				<Grid item xs="auto">
					<Typography variant="h3" display="flex" alignItems="center">
						Jurisdictions
					</Typography>
				</Grid>
				{isAuthor && (
					<Grid item xs="auto">
						<AddRegisteredAgentJurisdictionButton
							viewModel={
								new AddJurisdictionViewModel(
									objectId,
									objectType,
									aspectUrlIds.registeredAgentDetails,
									history,
								)
							}
						/>
					</Grid>
				)}
			</Grid>
			<Grid item xs={12}>
				<UserPreferencesDataGrid
					stonlyId="jurisdictions"
					tableKey="registeredAgentJurisdictionListPage"
					columns={columns}
					rows={viewModel.rows}
					loading={viewModel.loading}
					errorMessage={viewModel.error}
					emptyMessage="No Jurisdictions found"
					pagination
					hideFooter={false}
					showToolbar
					showCustomViewButton
					initialState={{
						sorting: {
							sortModel: [{ field: 'location', sort: 'asc' }],
						},
						pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
					}}
					sx={{ height: '65vh' }}
				/>
			</Grid>

			{isAuthor && (
				<Grid item xs={12}>
					<Paper
						sx={{
							background: '#F7F7F7',
							boxShadow: 'none',
						}}
					>
						<Grid
							container
							sx={{
								padding: 2,
								alignItems: 'center',
								justifyContent: 'space-between',
							}}
						>
							<Grid item xs="auto">
								<Typography>
									Deleting this aspect will remove all registered agent
									jurisdiction addresses from any linked registrations.
								</Typography>
							</Grid>
							<Grid item xs="auto">
								<Button
									variant="outlined"
									color="error"
									onClick={viewModel.openDeleteAspectModal}
									startIcon={<Delete />}
								>
									Delete
								</Button>
							</Grid>

							<ModalBase
								open={viewModel.deleteAspectModalOpen}
								onClose={viewModel.closeDeleteAspectModal}
								title={'Delete the Registered Agent Details aspect?'}
								actions={
									<DialogActions
										sx={{
											paddingX: '1.5rem',
											paddingBottom: '1.5rem',
										}}
									>
										<Button
											variant="contained"
											color="error"
											onClick={() => {
												viewModel.closeDeleteAspectModal();
												aspectManager.deleteAspect(
													aspectUrlIds.registeredAgentDetails,
												);
											}}
											disabled={aspectManager.loadingDeleteAspect}
										>
											Delete
										</Button>
										<Button
											color="secondary"
											onClick={viewModel.closeDeleteAspectModal}
										>
											Cancel
										</Button>
									</DialogActions>
								}
							>
								<Typography>
									Deleting this aspect will permanently remove all jurisdictions
									from the record, and delete registered agent addresses from
									any linked registrations. The Aspect tab will also be removed
									from the page.
								</Typography>
							</ModalBase>
						</Grid>
					</Paper>
				</Grid>
			)}

			<ModalBase
				open={viewModel.deleteModalOpen}
				onClose={viewModel.closeDeleteModal}
				title={'Delete Jurisdiction?'}
				actions={
					<DialogActions
						sx={{
							paddingX: '1.5rem',
							paddingBottom: '1.5rem',
						}}
					>
						<Grid display="flex" justifyContent="space-between" width="100%">
							{viewModel.deleteLoading ? (
								<Box display="flex" alignItems="center">
									<Box marginRight={2}>
										<Typography noWrap={true}>Deleting...</Typography>
									</Box>
									<CircularProgress size={25} />
								</Box>
							) : null}
							<Grid container spacing={1} justifyContent="flex-end">
								<Grid item>
									<Button
										color="secondary"
										onClick={viewModel.closeDeleteModal}
									>
										Cancel
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="contained"
										color="error"
										onClick={viewModel.deleteJurisdiction}
										disabled={viewModel.deleteLoading}
									>
										Delete
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</DialogActions>
				}
			>
				<Typography>
					Are you sure you want to delete this Jurisdiction? Doing so will
					remove the Registered Agent Addresses from any linked Registrations.
				</Typography>
			</ModalBase>
		</Grid>
	);
});
