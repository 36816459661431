import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { UseRemoveAccountIntegrationProps } from '../hooks/useRemoveAccountIntegration';

export type RemoveAccountIntegrationModalProps = {
	state: UseRemoveAccountIntegrationProps;
};

export const RemoveAccountIntegrationModal: FC<
	RemoveAccountIntegrationModalProps
> = ({ state: { isLoading, isOpen, error, cancel, confirm } }) => (
	<FormModal
		loading={isLoading}
		open={isOpen}
		title="Remove Account?"
		onSubmit={() => confirm()}
		onClose={() => cancel()}
		description="Are you sure you want to remove this account? This account will no longer be associated with the connection."
		submitButtonLabel="Remove"
		submitButtonColor="error"
		error={error}
	/>
);
