import { Container, Box, Typography } from '@mui/material';
import React from 'react';

export const loginErrorTestIds = {
	error: 'login-error-id',
};

export const LoginError: React.FC = ({ ...props }) => (
	<Box
		sx={{
			width: '100vw',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			// added to account for <body> padding
			position: 'fixed',
			top: 0,
			left: 0,
		}}
	>
		<Container maxWidth="xs" data-testid={loginErrorTestIds.error}>
			<Box marginBottom={1}>
				<Typography variant="h1" align="center">
					Unable to Log In
				</Typography>
			</Box>
			<Typography align="center">
				There was an issue logging you into your account.
			</Typography>
		</Container>
	</Box>
);
