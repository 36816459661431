import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import {
	ReconciliationCreateRecord,
	useReconciliationsContext,
} from '../_providers/reconciliationsProvider';

const createReconciliation = async (
	reconRecord: ReconciliationCreateRecord,
) => {
	try {
		const response = await customerApi.post<
			ApiResponse<ReconciliationCreateRecord>
		>('/api/v1.0/cash4/reconciliationRecords', reconRecord);
		if (response.data.error) {
			throw new Error(response.data.error);
		}
		return response;
	} catch (error) {
		throw error;
	}
};

export const useCreateReconciliation = (callbackFunction: () => void) => {
	const {
		reconciliationQueryContext: { refetch: refetchReconciliations },
		projectedItemsQueryContext: { refetch: refetchProjectedItems },
		reportedItemsQueryContext: { refetch: refetchReportedItems },
	} = useReconciliationsContext();

	const mutation = useMutation(
		(reconRecord: ReconciliationCreateRecord) =>
			createReconciliation(reconRecord),
		{
			onSuccess: () => {
				enqueueSnackbar('The reconciliation was sucessfully created!', {
					variant: 'success',
				});
				refetchReconciliations();
				refetchProjectedItems();
				refetchReportedItems();

				callbackFunction();
			},
			onError: (error) => {
				enqueueSnackbar(
					'An error occured and the reconciliation could not be created.',
					{
						variant: 'error',
					},
				);
			},
		},
	);

	return mutation;
};
