import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { PowerOfAttorneyType } from 'modules/clients/customer-api/src/api/referenceData';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import {
	flattenErrors,
	parseError,
	parseErrorsV2,
} from 'utilities/errors/errorUtils';
import {
	BaseRelationship,
	PowerOfAttorneyRelationship,
} from '../relationshipsObjectTypes';
import { T4DataResponse } from 'modules/clients/types';

export class StaffRelationshipsListModel {
	private readonly _entityId: string;
	private _loading: boolean = false;
	private _error: string | undefined = undefined;

	private _data: StaffRelationships | null = null;
	private _powerOfAttorneys: PowerOfAttorneyType[] = [];

	constructor(entityId: string) {
		makeAutoObservable(this);

		this._entityId = entityId;
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get data(): StaffRelationships | null {
		return this._data;
	}
	public get flatData(): BaseRelationship[] {
		let relationships: BaseRelationship[] =
			this._data?.accountSignatories ?? [];
		relationships = relationships.concat(this._data?.roles ?? []);
		relationships = relationships.concat(this.data?.ownership ?? []);

		return relationships;
	}
	public get powerOfAttorneys() {
		return this._powerOfAttorneys ?? [];
	}

	// relationships load function
	public load = flow(function* (this: StaffRelationshipsListModel) {
		this._loading = true;
		let errors = [];

		try {
			this._loading = true;
			const response: AxiosResponse<ApiResponse<StaffRelationships>> =
				yield customerApi.get<ApiResponse<StaffRelationships>>(
					`staff/${this._entityId}/relationships`,
				);

			if (response.data?.error) {
				let parsedError = parseError(response.data.error);

				if (parsedError) {
					errors.push(parsedError);
				}
			}

			this._data = response.data?.value ?? null;
		} catch (error: any) {
			const parsedError = parseErrorsV2(error);

			if (parsedError) {
				errors.push(parsedError);
			}
		}

		try {
			const powerOfAttorneysResponse: AxiosResponse<
				T4DataResponse<PowerOfAttorneyType[]>
			> = yield customerApi.get('referencedata/powerofattorneys');

			if (powerOfAttorneysResponse.data) {
				if (powerOfAttorneysResponse.data.error) {
					errors.push(powerOfAttorneysResponse.data.error);
				}

				if (powerOfAttorneysResponse.data.errors) {
					errors = errors.concat(
						flattenErrors(powerOfAttorneysResponse.data.errors),
					);
				}

				if (powerOfAttorneysResponse.data.data) {
					this._powerOfAttorneys = powerOfAttorneysResponse.data.data;
				}
			}
		} catch (error: any) {
			const parsedError = parseErrorsV2(error);

			if (parsedError) {
				errors.push(parsedError);
			}
		}

		this._error = errors.join(', ');
		this._loading = false;
	});
}

interface StaffRelationships {
	entityName: string;
	accountSignatories: StaffAccountRelationship[];
	roles: StaffRoleRelationship[];
	ownership: StaffOwnershipRelationship[];
	powerOfAttorneys: StaffPowerOfAttorneyRelationship[];
}

interface StaffAccountRelationship extends BaseRelationship {
	accountName: string | null;
	accountStatus: string | null;
	signatoryTitle: string | null;
	counterpartyName: string | null;
}

interface StaffRoleRelationship extends BaseRelationship {
	entityName: string | null;
	formOfOrganization: string | null;
	title: string | null;
}

interface StaffOwnershipRelationship extends BaseRelationship {
	entityName: string | null;
	formOfOrganization: string | null;
	ownershipLevel: string | null;
}

export type StaffPowerOfAttorneyRelationship = PowerOfAttorneyRelationship & {
	legalEntityDisplayName: string;
};
