import { flow, makeAutoObservable } from 'mobx';
import { FrontendRegistrationRepository } from '../../frontendRegistrationRepository';

export class DeleteRegistrationViewModel {
	private _entityId: string;
	private _frontendRegistrationRepository: FrontendRegistrationRepository;

	private _error?: string;
	private _deleting: boolean = false;
	private _isOpen: boolean = false;
	private _registrationId?: string;

	constructor(
		entityId: string,
		frontendRegistrationRepository: FrontendRegistrationRepository
	) {
		makeAutoObservable(this);

		this._entityId = entityId;
		this._frontendRegistrationRepository = frontendRegistrationRepository;
	}

	public get entityId(): string {
		return this._entityId;
	}

	public get isOpen(): boolean {
		return this._isOpen;
	}

	public get deleting(): boolean {
		return this._deleting;
	}

	public get error(): string | undefined {
		return this._error;
	}

	public openModal(registrationId: string): void {
		this._registrationId = registrationId;
		this._isOpen = true;
	}

	public closeModal(): void {
		this._registrationId = undefined;
		this._isOpen = false;
	}

	public delete = flow(function* (this: DeleteRegistrationViewModel) {
		try {
			this._error = undefined;
			this._deleting = true;

			if (this._registrationId) {
				yield this._frontendRegistrationRepository.deleteRegistration(
					this._entityId,
					this._registrationId
				);
			}
		} catch (error) {
			this._error = error as string;
		} finally {
			this._deleting = false;
		}
	});
}
