import { RepositoryBase } from 'models/repositoryBase';
import { ApiResponse } from 'utilities/api';

//#region Read Repository

export type GroupDto = {
	id: string;
	name: string;
	description: string | null;
	createdBy: string;
	createdDate: string;
	entityCount: number;
	memberCount: number;
	updatedBy: string;
	updatedDate: string;
};

export type GroupRequestDto = {
	id: string;
	type: 'Addition' | 'Removal';
	createdBy: string;
	reviewers: string[];
};

export type GroupMemberDto = {
	isInGroup: boolean;
	isApproved: boolean;
	id: string;
	email: string;
	lastLogin: string;
	request: GroupRequestDto | null;
};

export type AvailableGroupMemberDto = {
	isInGroup: boolean;
	hasOpenRequest: boolean;
	id: string;
	email: string;
	lastLogin: string;
};

export type GroupEntityDto = {
	isInGroup: boolean;
	isApproved: boolean;
	id: string;
	name: string;
	status: string;
	region: string;
	functionalCurrencyCode: string;
	request: GroupRequestDto | null;
};

export type AvailableGroupEntityDto = {
	isInGroup: boolean;
	hasOpenRequest: boolean;
	id: string;
	name: string;
	status: string;
	region: string;
	functionalCurrencyCode: string;
};

export class FrontendGroupRepository extends RepositoryBase {
	public version = '1.0';
	public baseUrl = `api/v${this.version}/frontend/groups`;

	public async getGroups(): Promise<ApiResponse<GroupDto[]>> {
		return await this.get<GroupDto[]>(this.baseUrl);
	}

	public async getGroup(groupId: string): Promise<ApiResponse<GroupDto>> {
		return await this.get<GroupDto>(`${this.baseUrl}/${groupId}`);
	}

	public async getGroupMembers(
		groupId: string,
	): Promise<ApiResponse<GroupMemberDto[]>> {
		return await this.get<GroupMemberDto[]>(
			`${this.baseUrl}/${groupId}/members`,
		);
	}

	public async getAvailableGroupMembers(
		groupId: string,
	): Promise<ApiResponse<AvailableGroupMemberDto[]>> {
		return await this.get<AvailableGroupMemberDto[]>(
			`${this.baseUrl}/${groupId}/availablemembers`,
		);
	}

	public async getGroupEntities(
		groupId: string,
	): Promise<ApiResponse<GroupEntityDto[]>> {
		return await this.get<GroupEntityDto[]>(
			`${this.baseUrl}/${groupId}/entities`,
		);
	}

	public async getAvailableGroupEntities(
		groupId: string,
	): Promise<ApiResponse<AvailableGroupEntityDto[]>> {
		return await this.get<AvailableGroupEntityDto[]>(
			`${this.baseUrl}/${groupId}/availableentities`,
		);
	}

	public async getUngroupedEntities(): Promise<ApiResponse<GroupEntityDto[]>> {
		return await this.get<GroupEntityDto[]>(
			`${this.baseUrl}/ungroupedentities`,
		);
	}

	public async getUngroupedEntitiesCount(): Promise<ApiResponse<number>> {
		return await this.get<number>(`${this.baseUrl}/ungroupedentities/count`);
	}
}

//#endregion

//#region Write Repository

export type CreateGroupData = {
	name: string;
	description: string | null;
};

export type UpdateGroupData = {
	name: string;
	description: string | null;
};

export type ManageGroupMembersData = {
	memberIds: string[];
};

export type ManageGroupApprovalsData = {
	reviews: {
		[key: string]: {
			isApproved: boolean;
			rejectionComment: string | null;
		};
	};
};

export type ManageGroupEntitiesData = {
	entityIds: string[];
};

export class GroupRepository extends RepositoryBase {
	public version = '1.0';
	public baseUrl = `api/v${this.version}/groups`;

	public async createGroup(
		request: CreateGroupData,
	): Promise<ApiResponse<string>> {
		return await this.post(this.baseUrl, request);
	}

	public async updateGroup(
		groupId: string,
		request: UpdateGroupData,
	): Promise<ApiResponse<string>> {
		return await this.patch(`${this.baseUrl}/${groupId}`, request);
	}

	public async updateGroupMembers(
		groupId: string,
		request: ManageGroupMembersData,
	): Promise<ApiResponse<string>> {
		return await this.patch(`${this.baseUrl}/${groupId}/members`, request);
	}

	public async manageGroupMemberApprovals(
		groupId: string,
		request: ManageGroupApprovalsData,
	): Promise<ApiResponse<string>> {
		return await this.patch(
			`${this.baseUrl}/${groupId}/members/approvals`,
			request,
		);
	}

	public async updateGroupEntities(
		groupId: string,
		request: ManageGroupEntitiesData,
	): Promise<ApiResponse<string>> {
		return await this.patch(`${this.baseUrl}/${groupId}/entities`, request);
	}

	public async manageGroupEntityApprovals(
		groupId: string,
		request: ManageGroupApprovalsData,
	): Promise<ApiResponse<string>> {
		return await this.patch(
			`${this.baseUrl}/${groupId}/entities/approvals`,
			request,
		);
	}
}

//#endregion
