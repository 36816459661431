import { makeObservable, observable, action } from 'mobx';
import { IFieldUpdate } from '../../entities/entitiesApiTypes';
import { EntityRepository } from '../../entities/entityRepository';
import { Entity } from '../../entities/objects/entity';
import { EntityCollection } from '../../entities/objects/entityCollection';
import { FieldSet } from '../../shared/fieldSets/fieldTypes';

export class CollectionList {
	public readonly entity: Entity;
	public readonly collectionDefinition: FieldSet;
	public items: EntityCollection[] = [];
	public loading: boolean = false;
	public error: string | undefined;

	private _entityRepository: EntityRepository;

	constructor(
		entity: Entity,
		collectionDefinition: FieldSet,
		entityRepository?: EntityRepository
	) {
		makeObservable(this, {
			entity: observable,
			collectionDefinition: observable,
			items: observable,
			loading: observable,
			error: observable,
			createItem: action,
			deleteItem: action,
		});
		this.entity = entity;
		this.collectionDefinition = collectionDefinition;
		// do we need to populate this.items, and if so, with what?

		this._entityRepository =
			entityRepository ?? new EntityRepository(entity.entityType, entity.id);
	}

	public addItemFromCollection = (collection: EntityCollection) => {
		this.items.push(collection);
	};

	public get fieldUpdatesForNewItem(): IFieldUpdate[] {
		return [];
	}

	public createItem = async (): Promise<void> => {
		this.loading = true;
		this.error = undefined;

		try {
			const collection = await this._entityRepository.addCollection(
				this.entity,
				this.collectionDefinition.name,
				this.fieldUpdatesForNewItem
			);

			this.addItemFromCollection(collection);
			this.entity.addCollection(collection);
		} catch (err) {
			this.error = err as string;
		} finally {
			this.loading = false;
		}
	};

	public deleteItem = async (itemToDelete: EntityCollection): Promise<any> => {
		this.loading = true;
		this.error = undefined;

		try {
			await this._entityRepository.deleteCollectionField(itemToDelete!.id);

			// We do not want to maintain this state in 2 places.
			// The entity is the more appropriate source of truth.
			// This will require some refactoring to create:
			// - Parent store(s) - entity may be one
			// - Set up view models (like this) that use parent store(s)
			this.items = this.items.filter((i) => i.id !== itemToDelete.id);
			this.entity.removeCollection(itemToDelete);
		} catch (err) {
			this.error = err as string;
		} finally {
			this.loading = false;
		}
	};
}
