import { Box } from '@mui/material';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { FC } from 'react';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { T4TextFieldV2 } from '../../shared/components/atoms/t4TextField';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { useFieldView } from '../object/hooks/useFieldView';
import { Collection } from '../object/models/collection';
import { T4Object } from '../object/models/t4Object';
import { ViewType } from '../object/utilities';
import { VisibilityOffIcon } from './visibilityOffIcon';
import { useHeader } from 'shared/providers/contexts/headerContext';

export type StringFieldProps = {
	object: T4Object;
	field: Field;
	fieldViewType: ViewType;
	collection: Collection | undefined;
	multiline?: boolean;
};

export const StringFieldView: FC<StringFieldProps> = observer(
	({ object, field, fieldViewType, collection, multiline }) => {
		const { viewType } = useProfileView();
		const { updateLastAutoSave } = useHeader();
		const {
			isReadOnly,
			hasPermission,
			showVisibilityIcon,
			error,
			warning,
			label,
			adornmentState,
			value,
			setValue,
			update,
		} = useFieldView<string>(
			object,
			field,
			fieldViewType,
			updateLastAutoSave,
			collection,
		);

		return (
			<Box key={`${fieldViewType}-field-string-view-wrapper-${field.id}`}>
				<T4TextFieldV2
					id={field.id}
					label={label}
					value={value ?? ''}
					onChange={(newValue) => {
						if (fieldViewType === 'pending') {
						}
						setValue(newValue ?? undefined);
					}}
					disabled={!hasPermission}
					onBlur={() => update(value)}
					variant={viewType === FieldViews.default ? 'standard' : 'outlined'}
					multiline={multiline}
					minRows={multiline ? 3 : undefined}
					maxRows={multiline ? 6 : undefined}
					helperText={
						viewType === FieldViews.edit
							? field.identifier === 't4_field_description'
								? `${value?.length ?? 0}/2048`
								: ''
							: ''
					}
					startAdornment={
						<T4FieldAdornment
							id={field.id}
							t4AdornmentType={adornmentState}
							hasPermission={hasPermission}
							title={field.name}
							description={field.description}
							iconButtonProps={{
								...(viewType === FieldViews.default
									? {
											sx: {
												marginLeft: '-5px',
											},
									  }
									: {}),
							}}
						/>
					}
					InputProps={{
						readOnly: isReadOnly,
						endAdornment: showVisibilityIcon ? (
							<VisibilityOffIcon />
						) : undefined,
						...(viewType === FieldViews.default
							? {
									disableUnderline: true,
							  }
							: {}),
						sx: {
							...(viewType === FieldViews.default
								? {
										padding: '0rem !important',
								  }
								: {}),
						},
					}}
					inputProps={{
						'data-testid': 't4-string-field-input',
					}}
				/>
				<T4AlertStack error={error} warning={warning} />
			</Box>
		);
	},
);
