import { Add, DashboardCustomize } from '@mui/icons-material';
import { Divider, Grid, MenuItem, useTheme } from '@mui/material';
import {
	GRID_TREE_DATA_GROUPING_FIELD,
	GridColDef,
} from '@mui/x-data-grid-pro';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import {
	LegalEntity,
	LegalEntityGroup,
} from 'modules/clients/customer-api/src/entity4/legalEntityGroups';
import moment, { Moment } from 'moment';
import { FC, useMemo, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { ACCESS_DENIED_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { getOptionsMenuColDef } from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { LegalEntityGroupActions } from './legalEntityGroupActions';
import { LegalEntityGroupCollectionActions } from './legalEntityGroupCollectionActions';

const stonlyIds = {
	createLegalEntityGroupButton: 'legal-entity-group-create-button',
	createLegalEntityGroupCollectionButton:
		'legal-entity-group-collection-create-button',
	legalEntityGroupCollectionOptions:
		'legal-entity-group-collection-options-menu',
	legalEntityGroupCollectionEdit: 'legal-entity-group-collection-edit',
	legalEntityGroupOptions: 'legal-entity-group-options-menu',
	legalEntityGroupEdit: 'legal-entity-group-edit',
};

export type LegalEntityGroupCollectionListItem = {
	id: string;
	path: string[];
	name: string;
	description?: string;
	isDefault?: boolean;
	entityCount: number;
	groups?: LegalEntityGroup[];
	legalEntities: LegalEntity[];
	createdBy: string;
	createdDate: Moment;
};

export const LegalEntityGroupsPage: FC = observer(() => {
	const theme = useTheme();
	const { isAdmin } = useUser();
	const {
		isInitializing,
		isLoading,
		isRefetching,
		legalEntityGroupCollections,
	} = useLegalEntityGroups();

	// #region Collection State

	const [isCollectionDrawerOpen, setIsCollectionDrawerOpen] =
		useState<boolean>(false);
	const [isDeleteCollectionModalOpen, setIsDeleteCollectionModalOpen] =
		useState<boolean>(false);
	const [selectedCollection, setSelectedCollection] = useState<
		LegalEntityGroupCollectionListItem | undefined
	>(undefined);

	// #endregion

	// #region Group State

	const [isGroupDrawerOpen, setIsGroupDrawerOpen] = useState<boolean>(false);
	const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] =
		useState<boolean>(false);
	const [selectedGroup, setSelectedGroup] = useState<
		LegalEntityGroupCollectionListItem | undefined
	>(undefined);

	// #endregion

	const rows: LegalEntityGroupCollectionListItem[] = useMemo(() => {
		return legalEntityGroupCollections.flatMap((collection) => {
			const collectionLegalEntities = collection.legalEntityGroups.flatMap(
				(x) => x.legalEntities ?? [],
			);
			return [
				{
					id: collection.id,
					path: [collection.name],
					name: collection.name,
					description: collection.description,
					isDefault: collection.isDefault,
					entityCount: collectionLegalEntities.length,
					groups: collection.legalEntityGroups,
					legalEntities: collectionLegalEntities,
					createdBy: collection.createdBy,
					createdDate: moment(collection.createdDate),
				},
				...collection.legalEntityGroups.map((x) => ({
					id: x.id,
					path: [collection.name, x.name],
					name: x.name,
					description: x.description,
					entityCount: x.legalEntities.length,
					legalEntities: x.legalEntities,
					createdBy: x.createdBy,
					createdDate: moment(x.createdDate),
				})),
			];
		});
	}, [legalEntityGroupCollections]);

	const columns: GridColDef<LegalEntityGroupCollectionListItem>[] =
		useMemo(() => {
			return [
				{
					...getOptionsMenuColDef<LegalEntityGroupCollectionListItem>(
						(params) => {
							if (params.row.path.length === 1 && !params.row.isDefault)
								return (
									<OverflowMenu
										id={`legalentitygroupcollection-options-menu-${params.row.id}`}
										iconButtonProps={{
											...stonlyData({
												id: stonlyIds.legalEntityGroupCollectionOptions,
											}),
										}}
									>
										<MenuItem
											onClick={() => {
												setSelectedCollection(params.row);
												setIsCollectionDrawerOpen(true);
											}}
											{...stonlyData({
												id: stonlyIds.legalEntityGroupCollectionEdit,
											})}
										>
											Edit
										</MenuItem>

										<Divider />
										<DeleteMenuItem
											onClick={() => {
												setSelectedCollection(params.row);
												setIsDeleteCollectionModalOpen(true);
											}}
											text="Remove"
										/>
									</OverflowMenu>
								);
							else if (params.row.path.length === 2)
								return (
									<OverflowMenu
										id={`legalentitygroup-options-menu-${params.row.id}`}
										iconButtonProps={{
											...stonlyData({
												id: stonlyIds.legalEntityGroupOptions,
											}),
										}}
									>
										<MenuItem
											onClick={() => {
												setSelectedGroup(params.row);
												setIsGroupDrawerOpen(true);
											}}
											{...stonlyData({
												id: stonlyIds.legalEntityGroupEdit,
											})}
										>
											Edit
										</MenuItem>

										<Divider />
										<DeleteMenuItem
											onClick={() => {
												setSelectedGroup(params.row);
												setIsDeleteGroupModalOpen(true);
											}}
										/>
									</OverflowMenu>
								);
							else return null;
						},
					),
				},
				{
					field: 'description',
					headerName: 'Description',
					flex: 1,
				},
				{
					field: 'entityCount',
					headerName: 'Entities',
					flex: 1,
				},
				{
					field: 'createdBy',
					headerName: 'Created By',
					flex: 1,
				},
				{
					...getDateColumnDefinition('DD-MMM-YYYY h:mm:ss a'),
					field: 'createdDate',
					headerName: 'Creation Date',
					flex: 1,
				},
			];
		}, []);

	const dataGrid = useMemo(
		() => (
			<UserPreferencesDataGrid<LegalEntityGroupCollectionListItem>
				tableKey="legal-entity-groups"
				stonlyId="legal-entity-groups"
				initialState={{
					sorting: {
						sortModel: [
							{
								field: GRID_TREE_DATA_GROUPING_FIELD,
								sort: 'asc',
							},
						],
					},
				}}
				columns={columns}
				rows={rows}
				getRowClassName={(row) =>
					legalEntityGroupCollections.some((x) => x.id === row.id)
						? 'collection-row'
						: ''
				}
				groupingColDef={{
					headerName: 'Name',
					flex: 1,
					disableColumnMenu: false,
					filterable: true,
					sortable: true,
					valueGetter: (params) => params.row.name,
				}}
				treeData
				getTreeDataPath={(row) => row.path}
				hideFooter={false}
				showToolbar
				showCustomViewButton
				loading={isRefetching}
			/>
		),
		[columns, rows, isRefetching, legalEntityGroupCollections],
	);

	if (!isAdmin) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}

	return (
		<T4View
			header={
				<PageHeader
					id="legal-entity-groups-header"
					title="Entity Groups"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
			loading={isInitializing}
		>
			<Grid
				container
				item
				sx={{
					flexDirection: 'column',
					height: '100%',
					gap: 2,
					flexWrap: 'nowrap',
				}}
			>
				<Grid
					container
					item
					xs="auto"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						gap: 2,
					}}
				>
					<Grid item>
						<T4Button
							{...stonlyData({
								id: stonlyIds.createLegalEntityGroupButton,
							})}
							variant="outlined"
							startIcon={<Add />}
							onClick={() => setIsGroupDrawerOpen(true)}
							disabled={isInitializing || isLoading}
						>
							Create Entity Group
						</T4Button>
					</Grid>
					<Grid item>
						<T4Button
							{...stonlyData({
								id: stonlyIds.createLegalEntityGroupCollectionButton,
							})}
							variant="outlined"
							startIcon={<DashboardCustomize />}
							onClick={() => setIsCollectionDrawerOpen(true)}
							disabled={isInitializing || isLoading}
						>
							Create Collection
						</T4Button>
					</Grid>
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						'& .collection-row': {
							backgroundColor: theme.palette.grey['50'],
						},
						paddingBottom: '16px',
					}}
				>
					{dataGrid}
				</Grid>
			</Grid>

			<LegalEntityGroupCollectionActions
				collection={selectedCollection}
				isDrawerOpen={isCollectionDrawerOpen}
				closeDrawer={() => {
					setIsCollectionDrawerOpen(false);
					setSelectedCollection(undefined);
				}}
				isDeleteModalOpen={isDeleteCollectionModalOpen}
				closeDeleteModal={() => {
					setIsDeleteCollectionModalOpen(false);
					setSelectedCollection(undefined);
				}}
			/>

			<LegalEntityGroupActions
				group={selectedGroup}
				isDrawerOpen={isGroupDrawerOpen}
				closeDrawer={() => {
					setIsGroupDrawerOpen(false);
					setSelectedGroup(undefined);
				}}
				isDeleteModalOpen={isDeleteGroupModalOpen}
				closeDeleteModal={() => {
					setIsDeleteGroupModalOpen(false);
					setSelectedGroup(undefined);
				}}
			/>
		</T4View>
	);
});
