import {
	GridInputRowSelectionModel,
	GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { Entity4Account } from 'models/apiModels';
import { FC } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { T4DataGridProps } from '../../../../../shared/components/dataGrid/dataGrid';

type AddAccountIdentifiersType<TData extends GridValidRowModel> = Pick<
	T4DataGridProps<TData>,
	'rows' | 'columns' | 'rowSelectionModel'
>;

type AddAccountIdentifiersProps = AddAccountIdentifiersType<any> & {
	loading?: boolean;
	onSelectionChangeCallBack: (
		gridSelectionModel: GridInputRowSelectionModel,
	) => void;
	getRowId: (row: Entity4Account) => string;
};

export const AccountSelectionDataGrid: FC<AddAccountIdentifiersProps> =
	observer(
		({
			rows,
			columns,
			rowSelectionModel,
			loading = false,
			onSelectionChangeCallBack,
			getRowId,
		}) => {
			return (
				<UserPreferencesDataGrid
					tableKey="connectSelectAccountList"
					stonlyId="connect-select-account"
					columns={columns}
					rows={rows}
					loading={loading}
					calculateColumnWidths
					autoHeight
					emptyMessage={loading ? '' : 'There are no accounts to display.'}
					checkboxSelection
					rowSelectionModel={rowSelectionModel}
					onRowSelectionModelChange={(model) => {
						onSelectionChangeCallBack(model);
					}}
					sx={{ width: 'auto' }}
					showToolbar
					showCustomViewButton
					getRowId={getRowId}
				/>
			);
		},
	);
