import { Delete } from '@mui/icons-material';
import { Button, Grid, useTheme } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { observer } from 'mobx-react-lite';
import { FC, FocusEvent, useMemo, useState } from 'react';
import { useUser } from '../../../../shared/hooks/useUser';
import { EntityCollection } from '../../entities/objects/entityCollection';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { Field } from '../../shared/fieldSets/fieldTypes';
import { EntityFieldAdornment } from '../../shared/fieldSets/fieldViews/common/fieldAdornments';
import { FieldError } from '../../shared/fieldSets/fieldViews/common/fieldError';
import { FieldViewOptions } from '../../shared/fieldSets/fieldViews/fieldViewOptions';
import { FieldViewString } from '../../shared/fieldSets/fieldViews/fieldViewString';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { PhoneList } from '../objects/phoneList';

export type MuiPhoneNumberDetails = {
	countryCode: string;
	dialCode: string;
	name: string;
};

const removeNonDigits = (mixedString: string): string => {
	return mixedString.replaceAll(/\D/g, '');
};

export const splitPhone = (formatted: string, countryCode: string) => {
	formatted = removeNonDigits(formatted);
	countryCode = removeNonDigits(countryCode);
	const [matches] = [
		...formatted.matchAll(new RegExp(`^\\D*${countryCode}\\s*(.*)$`, 'g')),
	];

	if (!matches || matches.length < 2) {
		return { countryCode: '', phoneNumber: '' };
	}

	return {
		countryCode: `+${countryCode}`,
		phoneNumber: removeNonDigits(matches[1]),
	};
};

export type PhoneListViewProps = {
	phoneList: PhoneList;
	phoneTypeDefinition: Field;
	countryCodeDefinition: Field;
	extensionDefinition: Field;
	phoneNumberDefinition: Field;
	collection: EntityCollection;
	fieldPrefix: string;
	openDeleteModal: (collection: EntityCollection) => void;
	isReadOnly?: boolean;
};

export const PhoneListView: FC<PhoneListViewProps> = observer(
	({
		phoneTypeDefinition,
		countryCodeDefinition,
		extensionDefinition,
		phoneNumberDefinition,
		collection,
		fieldPrefix,
		openDeleteModal,
		isReadOnly,
	}) => {
		const theme = useTheme();
		const { viewType } = useProfileView();
		const { isAuthor } = useUser();

		const [isPhoneDirty, setIsPhoneDirty] = useState(false);

		const [phoneTypeField, setPhoneTypeField] = useState(
			collection.getField(`${fieldPrefix}type`),
		);
		const [countryCodeField, setCountryCodeField] = useState(
			collection.getField(`${fieldPrefix}countrycode`),
		);
		const [phoneNumberField, setPhoneNumberField] = useState(
			collection.getField(`${fieldPrefix}number`),
		);
		const [extensionField, setExtensionField] = useState(
			collection.getField(`${fieldPrefix}extension`),
		);

		const [countryCodeValue, setCountryCode] = useState(
			countryCodeField?.approvedValue,
		);
		const [phoneNumberValue, setPhoneNumber] = useState(
			phoneNumberField?.approvedValue,
		);

		const editable = isAuthor && viewType === FieldViews.edit;

		const savePhoneNumber = async (
			e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>,
		) => {
			if (!isPhoneDirty) {
				return;
			}

			// This makes 2 HTTP requests. If there are more use cases for chunking
			// these up, it might be good to add to EntityCollection. The
			// EntityRepository does provide the ability to make multiple
			// fieldUpdates at a time, but this isn't implemented in
			// EntityCollection or CollectionField.

			await Promise.all([
				countryCodeField?.save(countryCodeValue) ??
					collection
						.v2AddField(
							countryCodeDefinition.identifier,
							countryCodeValue ?? null,
						)
						.then(() => {
							setCountryCodeField(
								collection.getField(`${fieldPrefix}countrycode`),
							);
						}),
				phoneNumberField?.save(phoneNumberValue) ??
					collection
						.v2AddField(
							phoneNumberDefinition.identifier,
							phoneNumberValue ?? null,
						)
						.then(() => {
							setPhoneNumberField(collection.getField(`${fieldPrefix}number`));
						}),
			]);
			setIsPhoneDirty(false);
		};

		const updatePhoneNumber = (formattedNumber: any, countryData?: any) => {
			const { countryCode, phoneNumber } = splitPhone(
				formattedNumber as string,
				countryData?.dialCode as string,
			);

			setCountryCode(countryCode);
			setPhoneNumber(phoneNumber);
			setIsPhoneDirty(true);
		};

		const countryCodeRegularExpression = countryCodeDefinition
			.validationCriterion?.validationRegex
			? new RegExp(countryCodeDefinition.validationCriterion.validationRegex)
			: undefined;
		const shouldDisplayCountryCodeWarning = () =>
			countryCodeField?.id !== undefined &&
			countryCodeField.approvedValue !== '' &&
			countryCodeValue !== undefined &&
			countryCodeValue !== '' &&
			countryCodeDefinition.validationCriterion !== null &&
			countryCodeRegularExpression !== undefined &&
			!countryCodeRegularExpression.test(countryCodeValue);

		const phoneNumberRegularExpression = phoneNumberDefinition
			.validationCriterion?.validationRegex
			? new RegExp(phoneNumberDefinition.validationCriterion.validationRegex)
			: undefined;
		const shouldDisplayPhoneNumberWarning = () =>
			phoneNumberField?.id !== undefined &&
			countryCodeField?.approvedValue !== '' &&
			phoneNumberValue !== undefined &&
			phoneNumberValue !== '' &&
			phoneNumberDefinition.validationCriterion !== null &&
			phoneNumberRegularExpression !== undefined &&
			!phoneNumberRegularExpression.test(phoneNumberValue);

		const _isReadOnly = useMemo(() => viewType !== FieldViews.edit, [viewType]);

		return (
			<Grid container>
				<Grid item xs={12}>
					<FieldViewOptions
						fieldData={phoneTypeField}
						fieldDefinition={phoneTypeDefinition}
						fieldLayoutProps={{ excludeDivider: true }}
						createField={(value?: string) => {
							collection
								.v2AddField?.(phoneTypeDefinition.identifier, value ?? null)
								.then(() => {
									setPhoneTypeField(collection.getField(`${fieldPrefix}type`));
								});
						}}
					/>
				</Grid>
				<Grid container item columnSpacing={2}>
					<Grid item xs={8}>
						<MuiPhoneNumber
							InputProps={{
								readOnly: isReadOnly || _isReadOnly,
								endAdornment: (
									<EntityFieldAdornment
										fieldDefinition={phoneNumberDefinition}
										field={phoneNumberField}
										onClick={
											() => {} /* no-op, doesn't have a description yet*/
										}
									/>
								),
							}}
							defaultCountry="us"
							onChange={updatePhoneNumber}
							onBlur={savePhoneNumber}
							variant={
								viewType === FieldViews.default ? 'standard' : 'outlined'
							}
							autoFormat
							disableAreaCodes
							value={`${countryCodeValue ?? ''}${phoneNumberValue ?? ''}`}
							fullWidth
							inputProps={{
								sx: {
									paddingX: '14px',
									paddingY: '8.5px',
									color: theme.typography.body1,
								},
							}}
							label="Phone Number"
							sx={{
								marginTop: '8.0px',
								svg: { height: '20px' },
								...(viewType === FieldViews.default
									? {
											'& .MuiInputBase-root': {
												color: 'transparent',
												borderColor: 'transparent',
												borderBottomColor: 'transparent',
												'&:before, :after, :hover': {
													borderBottomColor: 'transparent !important',
												},
												padding: '0rem !important',
											},
									  }
									: {}),
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<FieldViewString
							fieldData={extensionField}
							fieldDefinition={extensionDefinition}
							fieldLayoutProps={{ excludeDivider: true }}
							createField={(value?: string) => {
								collection
									.v2AddField?.(extensionDefinition.identifier, value ?? null)
									.then(() => {
										setExtensionField(
											collection.getField(`${fieldPrefix}extension`),
										);
									});
							}}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginLeft: '1rem' }}>
						<FieldError
							error={
								countryCodeField?.loadingErrorMessage ||
								phoneNumberField?.loadingErrorMessage
							}
							warning={
								shouldDisplayCountryCodeWarning()
									? countryCodeDefinition.validationCriterion?.errorDescription
									: shouldDisplayPhoneNumberWarning()
									? phoneNumberDefinition.validationCriterion?.errorDescription
									: undefined
							}
						/>
					</Grid>
				</Grid>
				{editable ? (
					<Button
						style={{ marginTop: '10px', marginLeft: 'auto' }}
						onClick={() => openDeleteModal(collection)}
						startIcon={<Delete />}
						size="small"
						color="error"
						variant="outlined"
					>
						Delete
					</Button>
				) : null}
			</Grid>
		);
	},
);
