import { useSigma } from 'features/shared/sigma/providers/sigmaProvider';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClients } from './useClients';

export type SharePermission = 'Edit' | 'Explore';

export type UseShareReportButtonProps = {
	isLoading: boolean;
	error: string | undefined;
	isSubmitDisabled: boolean;
	copy: boolean;
	name: string;
	permission: string;
	setCopy: (value: boolean) => void;
	setName: (value: string) => void;
	setPermission: (value: string) => void;
	onSubmit: (onSuccess: () => void) => Promise<void>;
	resetForm: () => void;
};

export function useShareReportButton(): UseShareReportButtonProps {
	const { customerApiClient } = useClients();
	const {
		folders,
		folder,
		workbooks,
		workbook,
		selectFolder,
		addWorkbook,
		updateWorkbook,
	} = useSigma();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>();
	const [permission, setPermission] = useState<string>('Explore');
	const [name, setName] = useState<string>('');
	const [copy, setCopy] = useState<boolean>(false);

	const isSubmitDisabled = useMemo(
		() => (copy && (name?.trim()?.length ?? 0) <= 0) || isLoading,
		[copy, isLoading, name],
	);

	const resetForm = useCallback(() => {
		setIsLoading(false);
		setPermission('Explore');
		setName('');
		setCopy(false);
		setError(undefined);
	}, []);

	const onSubmit = useCallback(
		async (onSuccess: () => void) => {
			if (workbook) {
				try {
					setIsLoading(true);

					const nameActual = copy
						? name?.trim() ?? workbook.name
						: name?.trim();
					const sharedFolder = folders.find(
						(x) => x.name === 'Internally Shared',
					);
					if (sharedFolder) {
						const isNameUnique = !workbooks.some(
							(x) => x.name === nameActual && x.folderId === sharedFolder.id,
						);
						if (isNameUnique) {
							const response = await customerApiClient.sigmaEmbed.shareFile({
								id: workbook.id,
								copy: copy,
								permissions: permission,
								name: nameActual,
								folder: sharedFolder.id,
							});

							if (copy) {
								addWorkbook(response.data);
							} else {
								updateWorkbook(response.data);
							}

							selectFolder(sharedFolder);
							onSuccess();
						} else {
							setError(
								`Report with name: ${nameActual} already exissts in the folder with name: ${folder?.name}.`,
							);
						}
					} else {
						setError(
							"Could not find shared folder with name: 'Internaly Shared'.",
						);
					}
				} catch {
					setError('Could not share report.');
				} finally {
					setIsLoading(false);
				}
			} else {
				setError('No report selected.');
			}
		},
		[
			addWorkbook,
			copy,
			customerApiClient.sigmaEmbed,
			folder?.name,
			folders,
			name,
			permission,
			selectFolder,
			updateWorkbook,
			workbook,
			workbooks,
		],
	);

	useEffect(() => {
		return () => resetForm();
	}, [resetForm]);

	return {
		isLoading: isLoading,
		error: error,
		isSubmitDisabled: isSubmitDisabled,
		copy: copy,
		name: name,
		permission: permission,
		setCopy: setCopy,
		setName: setName,
		setPermission: setPermission,
		onSubmit: onSubmit,
		resetForm: resetForm,
	};
}
