import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useDeleteReportButton } from 'shared/hooks/useDeleteReportButton';

export type DeleteReportButtonProps = {
	open: boolean;
	close: () => void;
};

export const DeleteReportButton: FC<DeleteReportButtonProps> = observer(
	({ open, close }) => {
		const { isLoading, onSubmit } = useDeleteReportButton();

		const handleClose = useCallback(() => {
			close();
		}, [close]);

		const handleSubmit = useCallback(() => {
			return onSubmit(handleClose);
		}, [handleClose, onSubmit]);

		return (
			<FormModal
				open={open}
				onClose={() => handleClose()}
				onSubmit={() => handleSubmit()}
				fullWidth
				title={'Delete Current Report?'}
				loading={isLoading}
				submitButtonLabel="Delete"
				loadingMessage="Deleting"
			/>
		);
	},
);
