import { Grid } from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useAddReportButton } from 'shared/hooks/useAddReportButton';

type AddReportButtonProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

export const AddReportButton: FC<AddReportButtonProps> = observer(
	({ open, setOpen }) => {
		const {
			isLoading,
			error,
			isSubmitDisabled,
			name,
			description,
			setName,
			setDescription,
			onSubmit,
		} = useAddReportButton({ open, setOpen });

		const handleClose = () => setOpen(false);

		return (
			<>
				<FormModal
					open={open}
					onClose={handleClose}
					onSubmit={() => onSubmit()}
					fullWidth
					title="Create Report"
					description="Create a new report"
					loading={isLoading}
					submitDisabled={isSubmitDisabled}
					submitButtonLabel="Create"
					loadingMessage="Creating"
				>
					<Grid container spacing={2} direction="column">
						<Grid item>
							<T4TextFieldV2
								id="report-name"
								label="Name"
								placeholder="New Report"
								value={name ?? ''}
								onChange={setName}
								required
							/>
						</Grid>
						<Grid item>
							<T4TextFieldV2
								id="report-description"
								label="Description"
								value={description ?? ''}
								onChange={setDescription}
								required
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<T4AlertStack error={error} />
					</Grid>
				</FormModal>
			</>
		);
	},
);
