import { Box, Grid } from '@mui/material';
import {
	MuiPhoneNumberDetails,
	splitPhone,
} from 'features/entity4/fields/components/phoneListView';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { PhoneNumber } from 'modules/clients/customer-api/src/api/objects';
import { FC } from 'react';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { T4PhoneNumber } from '../../shared/components/atoms/t4PhoneNumber';
import { T4TextFieldV2 } from '../../shared/components/atoms/t4TextField';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { useFieldView } from '../object/hooks/useFieldView';
import { Collection } from '../object/models/collection';
import { T4Object } from '../object/models/t4Object';
import { ViewType } from '../object/utilities';
import { VisibilityOffIcon } from './visibilityOffIcon';
import { useHeader } from 'shared/providers/contexts/headerContext';

export type PhoneNumberFieldViewProps = {
	object: T4Object;
	field: Field;
	fieldViewType: ViewType;
	collection: Collection | undefined;
};

export const PhoneNumberFieldView: FC<PhoneNumberFieldViewProps> = observer(
	({ object, field, fieldViewType, collection }) => {
		const { viewType } = useProfileView();
		const { updateLastAutoSave } = useHeader();
		const {
			isReadOnly,
			hasPermission,
			showVisibilityIcon,
			error,
			warning,
			label,
			adornmentState,
			value,
			setValue,
			update,
		} = useFieldView<PhoneNumber>(
			object,
			field,
			fieldViewType,
			updateLastAutoSave,
			collection,
		);

		return (
			<Box>
				<Grid
					key={`approved-field-phone-number-view-wrapper`}
					container
					item
					xs={12}
					direction="row"
					wrap="nowrap"
					columnGap={2}
				>
					<Grid item xs={12}>
						<T4PhoneNumber
							id={field.id}
							data-testid="t4-phone-number-field"
							label={label}
							variant={
								viewType === FieldViews.default ? 'standard' : 'outlined'
							}
							value={`${value?.countryCode ?? ''}${value?.number ?? ''}`}
							disabled={!hasPermission}
							onChange={
								((newValue: any, details: MuiPhoneNumberDetails) => {
									const { countryCode, phoneNumber } = splitPhone(
										newValue,
										details?.dialCode,
									);

									if (value) {
										setValue({
											...value,
											countryCode: countryCode ?? undefined,
											number: phoneNumber ?? undefined,
										});
									} else {
										setValue({
											phoneNumberId: undefined!,
											countryCode: countryCode ?? undefined,
											number: phoneNumber ?? undefined,
											extension: undefined,
										});
									}
								}) as any
							}
							onBlur={() => update(value ?? undefined)}
							InputProps={{
								readOnly: isReadOnly,
								endAdornment: (
									<>
										<T4FieldAdornment
											id={field.id}
											t4AdornmentType={adornmentState}
											hasPermission={hasPermission}
											title={field.name}
											description={field.description}
											iconButtonProps={{
												...(viewType === FieldViews.default
													? {
															sx: {
																marginLeft: '-5px',
															},
													  }
													: {}),
											}}
										/>
										{showVisibilityIcon && <VisibilityOffIcon />}
									</>
								),
								...(viewType === FieldViews.default
									? {
											disableUnderline: true,
									  }
									: {}),
							}}
							sx={{
								...(viewType === FieldViews.default
									? {
											'& .MuiInputBase-root': {
												padding: '0rem !important',
											},
									  }
									: {}),
							}}
						/>
					</Grid>
					<Grid item>
						<T4TextFieldV2
							id={field.id}
							data-test-id="t4-extension-field"
							label={'Extension'}
							value={value?.extension ?? ''}
							disabled={!hasPermission}
							onChange={(newValue) => {
								if (value) {
									setValue({
										...value,
										extension: newValue ?? undefined,
									});
								} else {
									setValue({
										phoneNumberId: undefined!,
										countryCode: undefined,
										number: undefined,
										extension: newValue ?? undefined,
									});
								}
							}}
							onBlur={() => update(value ?? undefined)}
							variant={
								viewType === FieldViews.default ? 'standard' : 'outlined'
							}
							InputProps={{
								readOnly: isReadOnly,
								sx: {
									...(viewType === FieldViews.default
										? {
												'&:before, :after, :hover': {
													borderBottomColor: 'transparent !important',
												},
												padding: '0rem !important',
										  }
										: {}),
								},
							}}
						/>
					</Grid>
				</Grid>
				<T4AlertStack error={error} warning={warning} />
			</Box>
		);
	},
);
