import { DialogActions, Grid, Typography } from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4Modal } from 'features/entity4/shared/components/atoms/t4Modal';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useState } from 'react';

export type DeletePhoneNumberModalProps = {
	open: boolean;
	title: string;
	onDelete: () => Promise<void>;
	onClose?: () => void;
};

export const DeletePhoneNumberModal: FC<DeletePhoneNumberModalProps> = observer(
	({ open, title, onDelete, onClose }) => {
		const [isDeleting, setIsDeleting] = useState<boolean>(false);
		const [error, setError] = useState<string>();

		const handleClose = useCallback(() => {
			if (!isDeleting) {
				onClose?.();
				setError(undefined);
			}
		}, [isDeleting, onClose]);

		const handleDelete = useCallback(async (): Promise<void> => {
			try {
				setIsDeleting(true);
				setError(undefined);

				await onDelete();
				handleClose();
			} catch {
				setError('Unable to delete collection.');
			} finally {
				setIsDeleting(false);
			}
		}, [handleClose, onDelete]);

		return (
			<T4Modal
				open={open}
				title="Delete Phone Number"
				fullWidth
				onClose={() => handleClose()}
				actions={
					<DialogActions
						sx={{
							padding: '1.5rem',
							paddingTop: '0.5rem',
						}}
					>
						<T4Button
							disabled={isDeleting}
							color="secondary"
							onClick={() => handleClose()}
						>
							Cancel
						</T4Button>
						<T4Button
							variant="contained"
							color="error"
							onClick={() => handleDelete()}
							disabled={isDeleting}
						>
							Delete
						</T4Button>
					</DialogActions>
				}
			>
				<Grid container sx={{ gap: 2 }}>
					{error && (
						<Grid item xs={12}>
							{error}
						</Grid>
					)}
					<Grid item xs={12}>
						<Typography>Are you sure you want to delete {title}?</Typography>
					</Grid>
				</Grid>
			</T4Modal>
		);
	},
);
