import { ThemeOptions } from '@mui/material';
import { brandColors } from './brandColors';

export const lightTheme: ThemeOptions = {
	typography: {
		allVariants: {
			color: brandColors.charcoal['500'],
		},
		h1: {
			color: '#fff',
		},
		banner: {
			color: '#fff',
		},
	},
	common: {
		text: brandColors.grey.legacy,
		maize: {
			main: brandColors.maize['500'],
			...brandColors.maize,
		},
		denim: {
			main: brandColors.denim['500'],
			...brandColors.denim,
		},
		charcoal: {
			main: brandColors.charcoal['500'],
			...brandColors.charcoal,
		},
		cornflower: {
			main: brandColors.cornflower['500'],
			...brandColors.cornflower,
		},
		equityGreen: {
			main: brandColors.equityGreen['500'],
			...brandColors.equityGreen,
		},
		jasper: {
			main: brandColors.jasper['500'],
			...brandColors.jasper,
		},
		debtRed: {
			main: brandColors.debtRed['500'],
			...brandColors.debtRed,
		},
	},
	cash4: {
		negativeCell: {
			background: brandColors.debtRed.A15,
			text: brandColors.debtRed['500'],
		},
	},
	palette: {
		primary: {
			main: brandColors.equityGreen['500'],
			dark: brandColors.equityGreen['500'],
			contrastText: '#fff',
		},
		secondary: {
			main: brandColors.denim['500'],
			contrastText: '#fff',
		},
		error: {
			light: brandColors.errorRed.A80,
			main: brandColors.errorRed.A100,
			contrastText: '#fff',
		},
		background: {
			default: '#fff',
		},
		lightBackground: {
			main: '#eeeeee',
		},
		text: {
			primary: brandColors.charcoal['500'],
			disabled: 'rgba(0, 0, 0, 0.38)',
		},
		header: {
			main: brandColors.charcoal['500'],
			contrastText: '#fff',
		},
		secondaryHeader: {
			main: brandColors.denim['500'],
			contrastText: '#fff',
		},
		charcoal: {
			...brandColors.charcoal,
		},
		equityGreen: {
			...brandColors.equityGreen,
		},
		denim: {
			...brandColors.denim,
		},
		maize: {
			...brandColors.maize,
		},
		cornflower: {
			...brandColors.cornflower,
		},
		grey: {
			...brandColors.grey,
		},
		debtRed: {
			...brandColors.debtRed,
		},
		jasper: {
			...brandColors.jasper,
		},
	},
	components: {
		MuiGrid: {
			styleOverrides: {
				root: {
					'& .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell': {
						backgroundColor: brandColors.equityGreen['50'],
					},
					'& .MuiDataGrid-row.Mui-selected.Mui-hovered .MuiDataGrid-cell': {
						backgroundColor: brandColors.equityGreen['50'],
					},
					'& .MuiDataGrid-row.Mui-hovered .MuiDataGrid-cell': {
						backgroundColor: brandColors.grey['50'],
					},
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				colorPrimary: {
					background: brandColors.charcoal['500'],
				},
				colorSecondary: {
					background: brandColors.denim['500'],
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'& .MuiTypography-root': {
						color: brandColors.charcoal,
					},
				},
			},
		},
		MuiListItemButton: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: '#eeeeee',
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					'&.MuiButton-text.MuiButton-textSecondary': {
						'&:hover': {
							backgroundColor: brandColors.denim.A10,
						},
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				colorPrimary: {
					background: brandColors.denim['100'],
					color: brandColors.denim['900'],
				},
				root: {
					'&.MuiButtonBase-root.MuiChip-root': {
						'&:hover': {
							background: brandColors.denim['100'],
							color: brandColors.denim['900'],
						},
						'&.MuiChip-deletable': {
							'&:hover': {
								background: brandColors.errorRed['50'],
								color: brandColors.errorRed['900'],

								'.MuiChip-deleteIcon': {
									color: brandColors.errorRed['900'],
								},
							},
							'.MuiChip-deleteIcon': {
								color: brandColors.denim['900'],
							},
						},
					},
				},
			},
		},
	},
};
