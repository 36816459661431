/* eslint-disable mobx/missing-observer */
import { Add, Approval, ExpandMore, Share } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Box,
	Divider,
	Grid,
	ListItemText,
	MenuItem,
	Typography,
	useTheme,
} from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
} from '@mui/x-data-grid-pro';
import { QueryObserverResult } from '@tanstack/react-query';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import {
	ApprovalLevel,
	ApprovalRule,
	ApprovalTier,
} from 'modules/clients/apiGateway/payments4/approvalRules';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { T4DataGrid } from 'shared/components/dataGrid/dataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { FormModal } from 'shared/components/formModal';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { useClients } from 'shared/hooks/useClients';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { convertDate, formatReadDate } from 'shared/utilities/dateUtilities';
import { AddApprovalTierDrawer } from './addApprovalTierDrawer';
import { EditApprovalTierDrawer } from './editApprovalTierDrawer';
import { useGetPaymentApprovalRule } from './hooks/useGetPaymentApprovalRule';

interface ApprovalRuleDefaultDrawerProps {
	ruleId: string | null;
	onClose: () => void;
	refetch: () => Promise<QueryObserverResult<ApprovalRule[], Error>>;
}

const stonlyIds = {
	approvalTierGrid: 'approval-rule-tier-grid',
	cancelButton: 'approval-rule-detail-drawer-cancel-button',
};

export const ApprovalRuleDetailDrawer: FC<ApprovalRuleDefaultDrawerProps> = ({
	onClose,
	ruleId,
	refetch: refetchRuleList,
}) => {
	// #region State

	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const { applicationApiClient } = useClients();

	const {
		isLoading,
		isFetching,
		data: rule,
		refetch,
		error,
	} = useGetPaymentApprovalRule(ruleId);
	useEffect(() => {
		if (!isLoading && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
			onClose();
		}
	}, [isLoading, error, enqueueSnackbar, onClose]);

	const [selectedTierId, setSelectedTierId] = useState<string | null>(null);
	const [isAddApprovalTierDrawerOpen, setIsAddApprovalTierDrawerOpen] =
		useState<boolean>(false);

	const selectedTier: ApprovalTier | null = useMemo(
		() => rule?.approvalTiers.find((t) => t.id === selectedTierId) ?? null,
		[rule, selectedTierId],
	);

	const [tierIdToDelete, setTierIdToDelete] = useState<string | null>(null);
	const [isDeleteTierLoading, setIsDeleteTierLoading] =
		useState<boolean>(false);
	const deleteTier = useCallback(
		async (ruleId: string, tierId: string) => {
			try {
				setIsDeleteTierLoading(true);
				const response =
					await applicationApiClient.payments4.approvalRules.deleteTier({
						ruleId,
						tierId,
					});
				if (response.status === 200) {
					enqueueSnackbar('Successfully deleted tier.', { variant: 'success' });
					refetch();
				} else throw new Error();
			} catch {
				enqueueSnackbar('Unable to delete tier. Please try again later.', {
					variant: 'error',
				});
			} finally {
				setIsDeleteTierLoading(false);
			}
		},
		[applicationApiClient, enqueueSnackbar, refetch],
	);

	// #endregion

	// #region Memoized Values

	const RulePropertiesAccordian = useMemo(
		() => (
			<Accordion
				sx={{
					height: 'fit-content !important',
					width: '100%',
					backgroundColor: theme.palette.cornflower[50],
					color: theme.palette.charcoal[500],
					borderRadius: '4px',
					marginTop: '0px !important',
				}}
			>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<Typography variant="h5" fontWeight={500}>
						Rule Properties
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container direction="column" columnSpacing={2} rowSpacing={1}>
						<Grid container item xs>
							<Grid item xs={4}>
								<Typography fontWeight={500}>Entities</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography sx={{ textAlign: 'right' }}>
									{rule?.entityId ?? 'Any'}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item xs>
							<Grid item xs={4}>
								<Typography fontWeight={500}>Countries</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography sx={{ textAlign: 'right' }}>
									{rule?.countryCode ?? 'Any'}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item xs>
							<Grid item xs={4}>
								<Typography fontWeight={500}>Currency</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography sx={{ textAlign: 'right' }}>
									{rule?.currencyCode}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item xs>
							<Grid item xs={10}>
								<Typography fontWeight={500}>Self-approve payments</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography sx={{ textAlign: 'right' }}>
									{rule?.canApproveOwnPayments ? 'Yes' : 'No'}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item xs>
							<Grid item xs={10}>
								<Typography fontWeight={500}>
									Allow sequential approvers
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography sx={{ textAlign: 'right' }}>
									{rule?.hasSequentialApprovers ? 'Yes' : 'No'}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item xs>
							<Grid item xs={10}>
								<Typography fontWeight={500}>
									Require unique approvers
								</Typography>
							</Grid>
							<Grid item xs={2}>
								<Typography sx={{ textAlign: 'right' }}>
									{rule?.requiresUniqueApprovers ? 'Yes' : 'No'}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item xs>
							<Grid item xs={6}>
								<Typography fontWeight={500}>Created by</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography sx={{ textAlign: 'right' }}>
									{rule?.createdBy?.email}
								</Typography>
							</Grid>
						</Grid>

						<Grid container item xs>
							<Grid item xs={6}>
								<Typography fontWeight={500}>Created date</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography sx={{ textAlign: 'right' }}>
									{formatReadDate(convertDate(rule?.createdOn))}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		),
		[rule, theme],
	);

	const TierComponent = useMemo(() => {
		if (rule?.approvalTiers.length === 0) {
			return (
				<Grid
					container
					item
					direction="column"
					xs={12}
					sx={{
						borderRadius: '4px',
						border: '1px solid',
						borderColor: theme.palette.charcoal[50],
						padding: '1rem',
						gap: 4,
					}}
				>
					<Grid
						item
						xs="auto"
						sx={{ display: 'flex', justifyContent: 'center' }}
					>
						<Avatar
							sx={{
								backgroundColor: theme.palette.cornflower[50],
								height: '80px',
								width: '80px',
							}}
						>
							<Approval
								sx={{
									color: theme.palette.cornflower[500],
									height: '40px',
									width: '40px',
								}}
							/>
						</Avatar>
					</Grid>
					<Grid item xs>
						<Typography
							variant="body1"
							fontWeight={500}
							sx={{ textAlign: 'center' }}
						>
							No approval tiers set for payments. Payments cannot be processed
							without assigned tiers and approvers.
						</Typography>
					</Grid>
					<Grid
						item
						xs="auto"
						sx={{ display: 'flex', justifyContent: 'center' }}
					>
						<T4Button onClick={() => setIsAddApprovalTierDrawerOpen(true)}>
							Add Approval Tier
						</T4Button>
					</Grid>
				</Grid>
			);
		} else {
			const columns: GridColDef[] = [
				{
					...getOptionsMenuColDef(
						(params: GridRenderCellParams<ApprovalTier>) => (
							<OverflowMenu id="approvaltier-options-menu">
								<MenuItem onClick={() => setSelectedTierId(params.row.id)}>
									<ListItemText>Edit</ListItemText>
								</MenuItem>
								<DeleteMenuItem
									onClick={() => setTierIdToDelete(params.row.id)}
								/>
							</OverflowMenu>
						),
					),
				},
				{
					field: 'amount',
					headerName: 'Approval Tier',
					description: 'Approval Tier',
					headerAlign: 'left',
					align: 'left',
					type: 'number',
					minWidth: DataGridColumnWidths.medium,
					flex: 1,
					valueFormatter: (params: GridValueFormatterParams<number>) => {
						const amount =
							params.value !== -1
								? params.value.toLocaleString('en-US')
								: 'Unlimited';

						return `Up to ${amount} ${rule?.currencyCode}`;
					},
					sortComparator: (v1, v2) => {
						if (v1 !== -1 && v2 !== -1) return v1 - v2;
						else if (v1 === -1 && v2 !== -1) return 1;
						else if (v2 === -1 && v1 !== -1) return -1;
						else return 0;
					},
				},
				{
					field: 'approvalLevels',
					headerName: 'Approval Levels',
					description: 'Approval Levels',
					type: 'number',
					minWidth: DataGridColumnWidths.medium,
					flex: 1,
					valueFormatter: (
						params: GridValueFormatterParams<ApprovalLevel[]>,
					) => {
						const tier = params.api.getRow(params.id!);
						if (tier.isAutoApprove) return 'Auto Approve';
						else return `${(params.value ?? []).length} levels`;
					},
				},
			];

			return (
				<Grid item xs={12}>
					<T4DataGrid<ApprovalTier>
						stonlyId={stonlyIds.approvalTierGrid}
						columns={columns}
						rows={rule?.approvalTiers ?? []}
						initialState={{
							sorting: { sortModel: [{ field: 'amount', sort: 'asc' }] },
						}}
					/>
				</Grid>
			);
		}
	}, [rule, theme]);

	const AddApprovalTierDrawerComponent = useMemo(
		() => (
			<AddApprovalTierDrawer
				isOpen={isAddApprovalTierDrawerOpen}
				onClose={() => {
					setIsAddApprovalTierDrawerOpen(false);
				}}
				rule={rule ?? null}
				ruleProperties={RulePropertiesAccordian}
				refetch={refetch}
			/>
		),
		[isAddApprovalTierDrawerOpen, rule, refetch, RulePropertiesAccordian],
	);

	const EditApprovalTierDrawerComponent = useMemo(
		() => (
			<EditApprovalTierDrawer
				isOpen={!!selectedTier}
				onClose={() => setSelectedTierId(null)}
				tier={selectedTier}
				rule={rule ?? null}
				ruleProperties={RulePropertiesAccordian}
				refetch={refetch}
			/>
		),
		[selectedTier, rule, refetch, RulePropertiesAccordian],
	);

	const DeleteTierModalComponent = useMemo(
		() => (
			<FormModal
				title="Delete payment tier?"
				description="All associated users will be removed from this tier and the tier will
					be deleted. This action cannot be undone."
				open={!!tierIdToDelete}
				onClose={() => setTierIdToDelete(null)}
				onSubmit={async () => {
					if (rule !== undefined && tierIdToDelete !== null) {
						await deleteTier(rule.id, tierIdToDelete);
						setTierIdToDelete(null);
					}
				}}
				loading={isDeleteTierLoading}
				submitButtonLabel="Delete"
				submitButtonColor="error"
				submitDisabled={isDeleteTierLoading}
			/>
		),
		[tierIdToDelete, rule, deleteTier, isDeleteTierLoading],
	);

	// #endregion

	return (
		<T4DrawerBase
			open={!!ruleId}
			onClose={() => {
				refetchRuleList();
				onClose();
			}}
			initializing={isLoading || isFetching}
			title="Rule Detail"
			actions={[
				<DrawerCancelButton
					stonlyId={stonlyIds.cancelButton}
					onCancel={() => {
						refetchRuleList();
						onClose();
					}}
					label="Close"
				/>,
			]}
			utilityActions={[
				<CopyToClipboardIconButton
					valueToCopy={window.location.href}
					initialTooltipText="Copy Link"
				>
					<Share />
				</CopyToClipboardIconButton>,
			]}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 3 }}>
				<Grid
					container
					item
					xs={12}
					sx={{
						backgroundColor: theme.palette.secondary.main,
						borderRadius: '4px',
						padding: '.75rem',
					}}
				>
					<Grid item xs={12} sx={{ textAlign: 'center' }}>
						<Typography
							variant="h1"
							sx={{
								color: theme.palette.secondary.contrastText,
							}}
						>
							{rule?.name}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Typography
								sx={{
									color: theme.palette.secondary.contrastText,
								}}
							>
								{`${rule?.entityId ?? 'Any Entity'}`}
							</Typography>
							<Divider
								flexItem
								orientation="vertical"
								color={theme.palette.secondary.contrastText}
								sx={{
									marginX: '.75rem',
								}}
							/>
							<Typography
								sx={{
									color: theme.palette.secondary.contrastText,
								}}
							>
								{`${rule?.countryCode ?? 'Any Country'}`}
							</Typography>
							<Divider
								flexItem
								orientation="vertical"
								color={theme.palette.secondary.contrastText}
								sx={{
									marginX: '.75rem',
								}}
							/>
							<Typography
								sx={{
									color: theme.palette.secondary.contrastText,
								}}
							>
								{`${rule?.currencyCode}`}
							</Typography>
						</Box>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					{RulePropertiesAccordian}
				</Grid>

				<Grid container item xs={12} sx={{ gap: 2 }}>
					<Grid
						container
						item
						xs={12}
						sx={{ justifyContent: 'space-between', alignItems: 'center' }}
					>
						<Grid item xs>
							<Typography variant="h5" fontWeight={500}>
								Approval Tiers
							</Typography>
						</Grid>
						<Grid item xs="auto">
							<T4Button
								variant="outlined"
								startIcon={<Add />}
								onClick={() => setIsAddApprovalTierDrawerOpen(true)}
							>
								Add Approval Tier
							</T4Button>
						</Grid>
					</Grid>

					{TierComponent}
				</Grid>
			</Grid>

			{AddApprovalTierDrawerComponent}
			{EditApprovalTierDrawerComponent}
			{DeleteTierModalComponent}
		</T4DrawerBase>
	);
};
