import { Add, Balance, SwapHoriz } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
	Button,
	Divider,
	Grid,
	Link,
	MenuItem,
	Tab,
	Tooltip,
	Typography,
} from '@mui/material';
import {
	GridColDef,
	GridRowClassNameParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import {
	QueryKey,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { LegalEntityGroupsFilter } from 'features/entity4/shared/components/legalEntityGroupsFilter';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { ProjectedItem } from 'modules/clients/customer-api/src/api/cash4';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import {
	ConfirmationDialog,
	IConfirmationDialogProps,
} from 'shared/components/confirmationDialog';
import { CustomNoRowsOverlay } from 'shared/components/dataGrid/customNoRowsOverlay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { brandColors } from 'shared/theme/brandColors';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { DataGridColumnWidths } from 'shared/utilities/dataGrid/dataGridUtils';
import { isValidDateRange } from 'shared/utilities/dateUtilities';
import { getQueryParam } from 'shared/utilities/navigationUtilities';
import { stonlyData } from 'stonly/functions';
import { formatCurrency } from 'utilities/currencyUtils';
import { ReconciliationDrawer } from '../reconciliations/_components/reconciliationDrawer';
import { useReconciliationsContext } from '../reconciliations/_providers/reconciliationsProvider';
import T4Drawer from '../shared/components/T4SideDrawer/T4DrawerShell';
import { ProjectedItemDrawer } from './components/ProjectedItems/projectedItemDrawer';
import { ProjectionDrawer } from './components/ProjectionDrawer/ProjectionDrawer';
import { TransactionDrawer } from './components/TransactionDrawer/TransactionDrawer';
import {
	CurrencyOption,
	TransactionListItem,
	TransactionValue,
} from './models';
import { EditRuleProvider } from './providers/useEditRule';
import { useTransactions } from './providers/useTransactions';
import { deleteProjectedTransactionApiCall, useTransaction } from './services';
import { stonlyIds as stonly } from './utilities/index';
export const dataTestIds = {
	categorizationInfoModal: 'categorization-info-modal',
	createProjectedItemButton: 'create-projected-item-button',
};

export const stonlyIds = {
	categorizationInfoModal: 'categorization-info-modal',

	transactionsDrawer: 'cash4-transactions-drawer',
	transactionsRowContextMenu: 'cash4-transactions-row-context-menu',
	transactionsRowContextMenuView: 'cash4-transactions-row-context-menu-view',
	transactionsRowContextMenuDelete:
		'cash4-transactions-row-context-menu-delete',
	transactionsDeleteConfirmationModal:
		'cash4-transactions-delete-confirmation-modal',
	transactionsDeleteConfirmationCancelButton:
		'cash4-transactions-delete-confirmation-cancel',
	transactionsDeleteConfirmationDeleteButton:
		'cash4-transactions-delete-confirmation-delete',

	// Projected Transactions
	projectedTransactionsGrid: 'cash4-projected-transactions-grid',
	projectedTransactionsDrawer: 'cash4-projected-transactions-drawer',
	projectedTransactionsRowContextMenu:
		'cash4-projected-transactions-row-context-menu',
	projectedTransactionsRowContextMenuView:
		'cash4-projected-transactions-row-context-menu-view',
	projectedTransactionsRowContextMenuDelete:
		'cash4-projected-transactions-row-context-menu-delete',
	projectedTransactionsDeleteConfirmationModal:
		'cash4-projected-transactions-delete-confirmation-modal',
	projectedTransactionsDeleteConfirmationCancelButton:
		'cash4-projected-transactions-delete-confirmation-cancel',
	projectedTransactionsDeleteConfirmationDeleteButton:
		'cash4-projected-transactions-delete-confirmation-delete',
	projectedTransactionsCreateButton:
		'cash4-transactions-create-projected-item-button',
};

export type TransactionPageTab = 'reported' | 'projected';

export const TransactionsPage: FC = observer(() => {
	const { projectedTransactionsEnabled } = useT4FeatureFlags();
	const user = useUser();
	const { legalEntityGroupIds } = useLegalEntityGroups();
	const history = useHistory();
	const queryClient = useQueryClient();
	const { transactionId, projectionId } = useParams<{
		transactionId: string;
		projectionId: string;
	}>();
	const {
		dateRange,
		handleDateRangeChange,
		defaultDateRange,
		fetchTransactions,
		currencyOption,
		configurations,
		handleCurrencyOptionChange,
		deleteCashTransactionApiCall,
	} = useTransactions();
	const { setTransactionStartDate, setTransactionEndDate } =
		useSessionStorage();
	const {
		projectedItemsQueryContext: {
			loading: projectedItemsLoading,
			data: projectedItems,
			refetch: refetchProjectedItems,
		},
		startReconciliationCreation,
	} = useReconciliationsContext();

	const queryKeyProjectedTransactions = 'projected-transactions';

	const [projectedItem, setProjectedItem] = useState<ProjectedItem>();
	const [transaction, setTransaction] = useState<TransactionListItem>();
	const [openTransactionDrawer, setOpenTransactionDrawer] =
		useState<boolean>(false);
	const [confirmationModalLoading, setConfirmationModalLoading] =
		useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedDialog, setSelectedDialog] =
		useState<IConfirmationDialogProps>({} as IConfirmationDialogProps);
	const [openProjectionDrawer, setOpenProjectionDrawer] = useState(false);
	const [dateRangeError, setDateRangeError] = useState<string>();

	useTransaction(transactionId, setTransaction, transaction);

	const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState<boolean>(false);

	const handleCloseTransactionDrawer = () => {
		setOpenTransactionDrawer(false);
		history.push(paths.cash4.transactions.href);
	};

	const handleCloseProjectionDrawer = useCallback(() => {
		setOpenProjectionDrawer(false);
	}, []);

	const handleOpenTransactionDrawer = () => {
		setOpenTransactionDrawer(true);
	};

	const handleOpenProjectionDrawer = () => {
		setOpenProjectionDrawer(true);
	};

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleTransactionViewClick = useCallback(
		(transaction: TransactionListItem) => {
			setTransaction(transaction);
			history.push(`${paths.cash4.transactions.href}/${transaction.id}`);
			handleOpenTransactionDrawer();
		},
		[history],
	);

	const handleProjectedViewClick = useCallback((projection: ProjectedItem) => {
		setProjectedItem(projection);
		handleOpenProjectionDrawer();
	}, []);

	const deleteTransaction = (data: { transactionId: string }) => {
		const { transactionId } = data;
		setConfirmationModalLoading(true);
		return deleteCashTransactionApiCall(transactionId);
	};

	const mutation = useMutation(deleteTransaction, {
		onSuccess: (data, variables) => {
			if (data) {
				handleCloseDialog();
				queryClient.invalidateQueries(['transactions']);
				enqueueSnackbar('Transaction deleted successfully.', {
					variant: 'success',
				});
			} else {
				enqueueSnackbar('Error deleting transaction.', {
					variant: 'error',
				});
			}
			setConfirmationModalLoading(false);
		},
	});

	const handleDeleteCashTransaction = useCallback(
		(transactionId: string) => {
			return mutation.mutate({ transactionId: transactionId });
		},
		[mutation],
	);

	const handleDeleteClick = useCallback(
		(transaction: TransactionListItem) => {
			setSelectedDialog({
				title: 'Delete transaction record',
				text: 'Are you sure you want to delete this transaction record? This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					handleDeleteCashTransaction(transaction.id);
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);
			handleOpenDialog();
		},
		[handleDeleteCashTransaction],
	);

	const deleteProjectedTransaction = (data: {
		ProjectedTransactionId: string;
	}) => {
		const { ProjectedTransactionId } = data;
		return deleteProjectedTransactionApiCall(ProjectedTransactionId);
	};

	const handleRefetch = (queryKey: QueryKey) => {
		queryClient.refetchQueries(queryKey);
	};

	const projectedTransactionMutation = useMutation(deleteProjectedTransaction, {
		onSuccess: () => {
			handleRefetch([queryKeyProjectedTransactions, dateRange]);
			enqueueSnackbar('The projected item was successfully deleted!', {
				variant: 'success',
			});
		},
		onError: () => {
			enqueueSnackbar(
				'An error occurred and the projected item could not be deleted.',
				{
					variant: 'error',
					autoHideDuration: 10000,
				},
			);
		},
	});

	const handleDeleteProjectedTransaction = useCallback(
		(projectedTransactionId: string) => {
			setConfirmationModalLoading(true);
			try {
				if (projectedTransactionId) {
					projectedTransactionMutation.mutate({
						ProjectedTransactionId: projectedTransactionId,
					});
				}
				handleCloseDialog();
			} catch {
			} finally {
				setConfirmationModalLoading(false);
			}
		},
		[projectedTransactionMutation],
	);

	const handleProjectedTransactionDeleteClick = useCallback(
		(row: ProjectedItem) => {
			setSelectedDialog({
				title: 'Delete projected item?',
				text: 'This projected item will be permanently deleted from the system. This action cannot be undone.',
				primaryButtonText: 'DELETE',
				onPrimaryButtonClick: () => {
					handleDeleteProjectedTransaction(row.id);
				},
				secondaryButtonText: 'CANCEL',
				onSecondaryButtonClick: handleCloseDialog,
			} as IConfirmationDialogProps);

			handleOpenDialog();
		},
		[handleDeleteProjectedTransaction],
	);

	const createProjectedItemButton = useMemo(
		() => (
			<Button
				startIcon={<Add />}
				type="button"
				variant="outlined"
				color="primary"
				data-testid={dataTestIds.createProjectedItemButton}
				{...stonlyData({
					id: stonlyIds.projectedTransactionsCreateButton,
				})}
				onClick={() => setIsCreateDrawerOpen(true)}
			>
				PROJECTED ITEM
			</Button>
		),
		[],
	);

	const CreateDrawer = useMemo(
		() => (
			<ProjectedItemDrawer
				isOpen={isCreateDrawerOpen}
				projectedItem={projectedItem}
				onClose={() => {
					setIsCreateDrawerOpen(false);
					setProjectedItem(undefined);
				}}
				onSubmit={() => {
					refetchProjectedItems();
				}}
			/>
		),
		[isCreateDrawerOpen, projectedItem, refetchProjectedItems],
	);

	useEffect(() => {
		if (isValidDateRange(dateRange)) {
			dateRange[0] && setTransactionStartDate(dateRange[0]);
			dateRange[1] && setTransactionEndDate(dateRange[1]);
		}
	}, [dateRange, setTransactionEndDate, setTransactionStartDate]);

	const { data, isLoading, error } = useQuery(
		['transactions', dateRange, legalEntityGroupIds],
		() => fetchTransactions(dateRange, legalEntityGroupIds),
		{
			enabled: !!isValidDateRange(dateRange),
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (transactionId) {
			handleOpenTransactionDrawer();
		}
	}, [transactionId]);

	useEffect(() => {
		if (projectionId) {
			handleOpenProjectionDrawer();
		}
	}, [projectionId]);

	const tabValue = useMemo(() => {
		return getQueryParam(history.location.search, 'tab') ?? 'reported';
	}, [history.location.search]);

	const columns = useMemo<GridColDef[]>(() => {
		const columns: GridColDef<TransactionListItem>[] = [
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<OverflowMenu
							iconButtonProps={{
								...stonlyData({
									id: stonlyIds.transactionsRowContextMenu,
								}),
							}}
							id={`transactionId-more-menu`}
						>
							<MenuItem
								{...stonlyData({
									id: stonlyIds.transactionsRowContextMenuView,
								})}
								onClick={() => {
									handleTransactionViewClick(params.row);
								}}
							>
								View
							</MenuItem>

							{user.cash4.isAuthor && <Divider /> && (
								<DeleteMenuItem
									{...stonlyData({
										id: stonlyIds.transactionsRowContextMenuDelete,
									})}
									onClick={() => {
										handleDeleteClick(params.row);
									}}
								/>
							)}
						</OverflowMenu>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				...getDateColumnDefinition(),
				field: 'date',
				headerName: 'Date',
			},
			{
				field: 'e4AccountName',
				headerName: 'Account Name',
				headerClassName: 'wrap-column-header',
			},
			{
				field: 'e4AccountNumber',
				headerName: 'Account Number',
			},
			{
				field: 'c4AccountNumber',
				headerName: 'C4 Account Number',
			},
			{
				field: 'transactionCode',
				headerName: 'Bank Transaction Code',
			},
			{
				field: 'bankReference',
				headerName: 'Bank Reference',
			},
			{
				field: 'customerReference',
				headerName: 'Customer Reference',
			},
			{
				field: 'checkNumber',
				headerName: 'Check Number',
			},
			{
				field: 'detail',
				headerName: 'Transaction Details',
			},
			{
				field: 'noteContent',
				headerName: 'Notes',
			},
			{
				field: 'fiTransactionId',
				headerName: 'Bank Transaction Id',
			},
			{
				field: 'transactionRuleName',
				headerName: 'Rule Name',
				valueGetter: (params) => {
					return params.row.id === params.row.transactionRuleName
						? 'Manual Categorization'
						: params.row.transactionRuleName || 'No Rule Matched';
				},
			},
			{
				field: 'cfc',
				headerName: 'CFC',
				description: 'Cash Flow Class',
				width: DataGridColumnWidths.threeChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cfcName}>
							<Typography variant="body2" noWrap>
								{value.row.cfc}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cfcName',
				headerName: 'CFC Description',
				description: 'Cash Flow Class',
			},
			{
				field: 'cft',
				headerName: 'CFT',
				description: 'Cash Flow Type',
				width: DataGridColumnWidths.threeChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cftName}>
							<Typography variant="body2" noWrap>
								{value.row.cft}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cftName',
				headerName: 'CFT Description',
				description: 'Cash Flow Type',
			},
			{
				field: 'cfst',
				headerName: 'CFST',
				description: 'Cash Flow Subtype',
				width: DataGridColumnWidths.fourChar,
				renderCell: (value) => {
					return (
						<Tooltip title={value.row.cfstName}>
							<Typography variant="body2" noWrap>
								{value.row.cfst}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'cfstName',
				headerName: 'CFST Description',
				description: 'Cash Flow Subtype',
			},
			{
				field: 'glNumber',
				headerName: 'GL Code',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'number',
				headerName: 'Amount',
				valueGetter: (
					params: GridValueGetterParams<TransactionListItem, TransactionValue>,
				) => {
					if (currencyOption.id === 'original') {
						return params.value?.value;
					} else {
						return params.value?.reportingValue;
					}
				},
				renderCell: (params) => {
					let currencyCode: string | undefined = params.row.currency;
					if (currencyOption.id === 'reporting') {
						currencyCode = configurations?.reportingCurrencyCode || 'USD';
					}
					return formatCurrency(params.value, {
						currency: currencyCode,
					});
				},
			},
			{
				field: 'currency',
				headerName: 'Currency',
				renderCell: (params) => {
					return currencyOption.id === 'original'
						? params.row.currency.toUpperCase()
						: configurations?.reportingCurrencyCode || 'USD';
				},
			},
		];

		if (projectedTransactionsEnabled) {
			columns.push({
				field: 'reconciliationStatus',
				headerName: 'Reconciliation Status',
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value === 'Unreconciled' || params.value === 'Posted'
									? 'The cleared transaction has no accociated records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									// todo: c4-reconciliations
									enqueueSnackbar('Not implemented yet.');
								}}
								sx={(theme) => ({
									textDecoration: 'underline',
									color: theme.palette.denim[500],
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: 'pointer',
								})}
							>
								{params.value === 'Unreconciled'
									? 'No reconciliation'
									: params.value === 'PartiallyReconciled'
									? 'Partially reconciled'
									: params.value === 'Posted'
									? 'No association'
									: params.value}
							</Typography>
						</Tooltip>
					);
				},
			});
		}

		columns.push(
			{
				field: 'bankName',
				headerName: 'Counterparty Name',
			},
			{
				field: 'bankCode',
				headerName: 'Counterparty Code',
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'importedDate',
				headerName: 'Imported Date',
			},
		);

		return columns;
	}, [
		configurations?.reportingCurrencyCode,
		currencyOption.id,
		handleDeleteClick,
		handleTransactionViewClick,
		projectedTransactionsEnabled,
		user.cash4.isAuthor,
	]);

	const projectedTransactionsColumns = useMemo<GridColDef<ProjectedItem>[]>(
		() => [
			{
				field: 'actions',
				headerName: '',
				width: 25,
				renderCell: (params) => {
					return (
						<OverflowMenu
							iconButtonProps={{
								...stonlyData({
									id: stonlyIds.transactionsRowContextMenu,
								}),
							}}
							id={`projectedTransactionId-more-menu`}
						>
							<MenuItem
								onClick={() => {
									handleProjectedViewClick(params.row);
								}}
							>
								View
							</MenuItem>
							{user.cash4.isAuthor && (
								<MenuItem
									onClick={() => {
										setProjectedItem(params.row);
										setIsCreateDrawerOpen(true);
									}}
								>
									Edit
								</MenuItem>
							)}

							{user.cash4.isAuthor && <Divider /> && (
								<DeleteMenuItem
									onClick={() =>
										handleProjectedTransactionDeleteClick(params.row)
									}
								/>
							)}
						</OverflowMenu>
					);
				},
				resizable: false,
				sortable: false,
				disableColumnMenu: true,
				filterable: false,
				disableExport: true,
				hideable: false,
				disableReorder: true,
			},
			{
				field: 'label',
				headerName: 'Label',
			},
			{
				field: 'reconciliationStatus',
				headerName: 'Reconciliation Status',
				renderCell: (params) => {
					return (
						<Tooltip
							title={
								params.value === 'Unreconciled'
									? 'Preform reconciliation to associate records.'
									: 'Has associated records.'
							}
						>
							<Typography
								onClick={() => {
									// todo: c4-reconciliations
									enqueueSnackbar('Not implemented yet.');
								}}
								sx={(theme) => ({
									textDecoration: 'underline',
									color: theme.palette.denim[500],
									lineHeight: 1.43,
									letterSpacing: '0.01071em',
									fontSize: '0.875rem',
									cursor: 'pointer',
								})}
							>
								{params.value === 'PartiallyReconciled'
									? 'Partially reconciled'
									: params.value}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: 'description',
				headerName: 'Description',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'amount',
				headerName: 'Amount',
				renderCell: (params) =>
					formatCurrency(params.row.amount, {
						currency: params.row.currencyCode,
					}),
			},
			{
				field: 'currencyCode',
				headerName: 'Currency code',
			},
			{
				...getDateColumnDefinition(),
				field: 'date',
				headerName: 'Expected value date',
			},
			{
				field: '_cfc',
				headerName: 'CFC',
				description: 'Cash flow class',
				width: DataGridColumnWidths.threeChar,
				valueGetter: (params) => params.row?.categorization?.class?.code,
				renderCell: (params) => {
					return (
						<Tooltip title={params.row.categorization?.class?.name}>
							<Typography variant="body2" noWrap>
								{params.value}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: '_cft',
				headerName: 'CFT',
				description: 'Cash flow type',
				width: DataGridColumnWidths.threeChar,
				valueGetter: (params) => params.row?.categorization?.type?.code,
				renderCell: (params) => {
					return (
						<Tooltip title={params.row.categorization?.type?.name}>
							<Typography variant="body2" noWrap>
								{params.value}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: '_cfst',
				headerName: 'CFST',
				description: 'Cash flow subtype',
				width: DataGridColumnWidths.fourChar,
				valueGetter: (params) => params.row?.categorization?.subtype?.code,
				renderCell: (params) => {
					return (
						<Tooltip title={params.row.categorization?.subtype?.name}>
							<Typography variant="body2" noWrap>
								{params.value}
							</Typography>
						</Tooltip>
					);
				},
			},
			{
				field: '_glCode',
				headerName: 'GL code',
				valueGetter: (params) => params.row?.categorization?.glCode?.code,
			},
			{
				field: '_initiator_type',
				headerName: 'Initiator type',
				valueGetter: (params) => params.row.initiator.type,
			},
			{
				field: '_initiator_name',
				headerName: 'Initiator name',
				valueGetter: (params) => params.row.initiator.object?.name,
			},
			{
				field: '_initiator__account_name',
				headerName: 'Initiator account name',
				valueGetter: (params) => params.row.initiator.account?.name,
			},
			{
				field: '_initiator__account_number',
				headerName: 'Initiator account number',
				valueGetter: (params) => params.row.initiator.account?.number,
			},
			{
				field: '_payee_type',
				headerName: 'Payee type',
				valueGetter: (params) => params.row.payee.type,
			},
			{
				field: '_payee_name',
				headerName: 'Payee name',
				valueGetter: (params) => params.row.payee.object?.name,
			},
			{
				field: '_payee__account_name',
				headerName: 'Payee account name',
				valueGetter: (params) => params.row.payee.account?.name,
			},
			{
				field: '_payee__account_number',
				headerName: 'Payee account number',
				valueGetter: (params) => params.row.payee.account?.number,
			},
		],
		[
			handleProjectedTransactionDeleteClick,
			handleProjectedViewClick,
			user.cash4.isAuthor,
		],
	);

	const customNoProjectedTransactionsOverlay = () => (
		<CustomNoRowsOverlay
			icon={
				<div
					style={{
						backgroundColor: brandColors.cornflower[50],
						borderRadius: '50%',
						width: '80px',
						height: '80px',
						display: 'flex',
						margin: 'auto',
					}}
				>
					<Balance
						sx={{
							fontSize: '50px',
							color: brandColors.denim[500],
							margin: 'auto',
						}}
					/>
				</div>
			}
			heading={<Typography variant="h6">No Projected Items</Typography>}
			body={
				<Typography variant="body1">
					Select{' '}
					<Link
						underline="always"
						color={brandColors.denim[500]}
						sx={{ cursor: 'pointer' }}
						onClick={() => setIsCreateDrawerOpen(true)}
					>
						Create Projection
					</Link>{' '}
					to add your data.
				</Typography>
			}
		/>
	);

	const reconcileButton = useMemo(
		() => (
			<T4Button
				variant="outlined"
				startIcon={<SwapHoriz />}
				onClick={() => {
					startReconciliationCreation();
				}}
			>
				Reconcile
			</T4Button>
		),
		[startReconciliationCreation],
	);

	const reportedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				tableKey="transactionsPage"
				columns={columns}
				stonlyId="transactions"
				rows={data || []}
				loading={isLoading}
				calculateColumnWidths
				columnVisibilityModel={{
					c4AccountNumber: false,
					bankName: false,
					bankCode: false,
					bankReference: false,
					checkNumber: false,
					glNumber: false,
					customerReference: false,
					transactionImportedDate: false,
					transactionRuleName: false,
					fiTransactionId: false,
					noteContent: false,
					cfcName: false,
					cftName: false,
					cfstName: false,
				}}
				getRowClassName={(
					params: GridRowClassNameParams<TransactionListItem>,
				) => (!params.row.cfc ? 'uncategorized-row' : '')}
				initialState={{
					sorting: {
						sortModel: [
							{ field: 'date', sort: 'desc' },
							{ field: 'e4AccountNumber', sort: 'asc' },
							{ field: 'transactionCode', sort: 'asc' },
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
			/>
		),
		[columns, data, isLoading],
	);

	const projectedGrid = useMemo(
		() => (
			<UserPreferencesDataGrid
				stonlyId={stonly.projectedTransactionsGrid}
				tableKey="projectedTransactionsPage"
				loading={projectedItemsLoading}
				columns={projectedTransactionsColumns}
				rows={projectedItems || []}
				calculateColumnWidths
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'date',
								sort: 'desc',
							},
						],
					},
				}}
				pinnedColumns={{ left: ['actions'] }}
				showToolbar
				showCustomViewButton
				noRowsOverlay={customNoProjectedTransactionsOverlay}
				pagination
				hideFooter={false}
				autoPageSize
			/>
		),
		[projectedItems, projectedItemsLoading, projectedTransactionsColumns],
	);

	useEffect(() => {
		if (!tabValue) {
			history.push(history.location.pathname + `?tab=${'reported'}`);
		}
	}, [history, tabValue]);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.transactionsPage}
					title="Transactions"
				/>
			}
		>
			<TabContext value={tabValue}>
				<Grid
					container
					sx={{
						gap: 2,
						flexDirection: 'column',
						flexWrap: 'nowrap',
						height: '100%',
						width: '100%',
					}}
				>
					<Grid container item xs="auto">
						<TabList
							onChange={(_, tab) => {
								const setTab = (tab: TransactionPageTab) => {
									history.push(history.location.pathname + `?tab=${tab}`);
								};

								if (tab === 'reported') {
									setTab(tab);
								} else if (tab === 'projected') {
									setTab(tab);
								} else {
									setTab('reported');
								}
							}}
						>
							<Tab label="Reported" value="reported" />
							{projectedTransactionsEnabled && (
								<Tab label="Projected" value="projected" />
							)}
						</TabList>
					</Grid>
					<Grid item xs={true}>
						<TabPanel value="reported" sx={{ height: '100%', padding: 0 }}>
							<Grid
								container
								sx={{
									gap: 2,
									height: '100%',
									flexDirection: 'column',
									flexWrap: 'nowrap',
								}}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{ justifyContent: 'space-between' }}
								>
									<Grid
										container
										item
										xs="auto"
										sx={{ gap: 2, alignItems: 'flex-end' }}
									>
										<Grid item xs="auto">
											<T4DateRangePicker
												defaultValue={dateRange}
												onAccept={([startDate, endDate]) => {
													if (
														startDate &&
														startDate.isValid() &&
														endDate &&
														endDate.isValid() &&
														startDate.isBefore(endDate)
													) {
														setDateRangeError(undefined);
														if (
															!startDate.isSame(dateRange[0]) ||
															!endDate.isSame(dateRange[1])
														) {
															handleDateRangeChange([startDate, endDate]);
														}
													}

													if (startDate && startDate.isSame(endDate, 'day')) {
														setDateRangeError(
															'Start and end date cannot be the same.',
														);
													}
												}}
												maxDate={moment().subtract(1, 'days')}
												disableFuture
												showShortcuts
												shortcutResetDefaults={defaultDateRange}
												sx={{ marginBottom: 0 }}
											/>
										</Grid>
										<Grid item xs="auto">
											<T4TextFieldV2
												label="Display Currency"
												select
												value={currencyOption.id}
												onChange={(event) => {
													handleCurrencyOptionChange(
														event as CurrencyOption['id'],
													);
												}}
											>
												{[
													{ id: 'original', value: 'Account Currency' },
													{
														id: 'reporting',
														value: `Reporting Currency (${
															configurations?.reportingCurrencyCode || 'USD'
														})`,
													},
												].map((x) => {
													return (
														<MenuItem key={x.id} value={x.id}>
															{x.value}
														</MenuItem>
													);
												})}
											</T4TextFieldV2>
										</Grid>
										<Grid item xs="auto">
											<LegalEntityGroupsFilter loading={isLoading} />
										</Grid>
									</Grid>
									{projectedTransactionsEnabled && (
										<Grid
											container
											item
											xs="auto"
											sx={{ gap: 2, alignItems: 'flex-end' }}
										>
											<Grid item xs="auto">
												{createProjectedItemButton}
											</Grid>
											<Grid item xs="auto">
												{reconcileButton}
											</Grid>
										</Grid>
									)}
								</Grid>
								{(error || dateRangeError) && (
									<Grid item xs="auto">
										<T4AlertStack error={(error as string) || dateRangeError} />
									</Grid>
								)}
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper
										sx={{
											height: '100%',
											'& .uncategorized-row': {
												backgroundColor: '#FFFDE3',
											},
											'& .MuiDataGrid-columnHeaders': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeader': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeadersInner': {
												height: '3rem !important',
												maxHeight: '3rem !important',
											},
											'& .MuiDataGrid-columnHeaderTitle': {
												height: '3rem !important',
												maxHeight: '3rem !important',
												display: 'flex',
												flexWrap: 'wrap',
												whiteSpace: 'break-spaces',
												lineHeight: '1.5rem',
												alignContent: 'center',
											},
										}}
									>
										{reportedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value="projected" sx={{ height: '100%', padding: 0 }}>
							<Grid
								container
								sx={{
									gap: 2,
									height: '100%',
									flexDirection: 'column',
									flexWrap: 'nowrap',
								}}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{
										gap: 2,
										justifyContent: 'flex-end',
										alignContent: 'flex-end',
									}}
								>
									<Grid item xs="auto">
										{createProjectedItemButton}
									</Grid>
									<Grid item xs="auto">
										{reconcileButton}
									</Grid>
								</Grid>
								<Grid item xs={true}>
									<ActuallyPrettyGoodDataGridWrapper>
										{projectedGrid}
									</ActuallyPrettyGoodDataGridWrapper>
								</Grid>
							</Grid>
						</TabPanel>
					</Grid>
				</Grid>
			</TabContext>

			<EditRuleProvider>
				<T4Drawer
					open={openTransactionDrawer}
					onClose={handleCloseTransactionDrawer}
					stonlyPrefix="transaction-details"
				>
					<TransactionDrawer transaction={transaction} />
				</T4Drawer>
			</EditRuleProvider>

			<T4Drawer
				open={openProjectionDrawer}
				onClose={handleCloseProjectionDrawer}
				stonlyPrefix="projected-transaction-details"
			>
				<ProjectionDrawer
					loading={projectedItemsLoading}
					projection={projectedItem}
				/>
			</T4Drawer>

			<ReconciliationDrawer />

			<ConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={selectedDialog.title || ''}
				text={selectedDialog.text}
				primaryButtonText={selectedDialog.primaryButtonText}
				secondaryButtonText={selectedDialog.secondaryButtonText}
				onPrimaryButtonClick={selectedDialog.onPrimaryButtonClick}
				onSecondaryButtonClick={selectedDialog.onSecondaryButtonClick}
				stonlyIds={{
					confirmationModal: stonlyIds.transactionsDeleteConfirmationModal,
					secondaryButton: stonlyIds.transactionsDeleteConfirmationCancelButton,
					primaryButton: stonlyIds.transactionsDeleteConfirmationDeleteButton,
				}}
				loading={confirmationModalLoading}
			/>
			{CreateDrawer}
		</T4View>
	);
});
