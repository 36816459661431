import {
	GridColDef,
	GridRowModel,
	GridRowParams,
	GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { flow, makeAutoObservable } from 'mobx';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { FrontendGroupRepository, GroupRepository } from '../groupRepository';
import { ManageGroupEntitiesModel } from './manageGroupEntitiesButtonModel';

export class ManageGroupEntitiesViewModel {
	private manageEntitiesModel: ManageGroupEntitiesModel;

	private _openModal: boolean;
	private _successfulSubmit: boolean;
	private _successSnackbarOpen: boolean;

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
		groupRepository: GroupRepository,
	) {
		makeAutoObservable(this);
		this.manageEntitiesModel = new ManageGroupEntitiesModel(
			groupId,
			frontendGroupRepository,
			groupRepository,
		);

		this._successfulSubmit = false;
		this._successSnackbarOpen = false;
		this._openModal = false;
	}

	// Manage Entities Modal Functions
	public isModalOpen = () => {
		return this._openModal;
	};
	public openModal = async () => {
		this._openModal = true;
		await this.loadAvailableEntities();
	};
	public closeModal = () => {
		this._openModal = false;
		this.manageEntitiesModel.clear();
	};
	public isRowSelectable = (params: GridRowParams) => {
		return !params.row.hasOpenRequest;
	};

	// Manage Entities Modal List Functions
	public getAvailableEntitiesError = () => {
		return isStringUndefinedOrNullOrWhitespace(
			this.manageEntitiesModel.availableEntitiesError,
		)
			? undefined
			: this.manageEntitiesModel.availableEntitiesError;
	};
	public getAvailableEntitiesLoading = () => {
		return this.manageEntitiesModel.availableEntitiesLoading;
	};

	public getAvailableEntitiesListColumns = (): GridColDef[] => {
		return [
			{
				field: 'name',
				headerName: 'Name',
				flex: 12,
				minWidth: 250,
			},
			{
				field: 'status',
				headerName: 'Status',
				flex: 10,
				minWidth: 200,
			},
			{
				field: 'region',
				headerName: 'Region',
				flex: 10,
				minWidth: 200,
			},
			{
				field: 'functionalCurrencyCode',
				headerName: 'Currency Code',
				flex: 10,
				minWidth: 200,
			},
		];
	};

	public getAvailableEntitiesListRows = (): GridRowModel[] => {
		return this.manageEntitiesModel.availableEntities.map((entity) => {
			return {
				id: entity.id,
				name: entity.name,
				status: entity.status,
				region: entity.region,
				functionalCurrencyCode: entity.functionalCurrencyCode,
				hasOpenRequest: entity.hasOpenRequest,
			};
		});
	};

	public loadAvailableEntities = flow(function* (
		this: ManageGroupEntitiesViewModel,
	) {
		yield this.manageEntitiesModel.loadAvailableEntities();
		if (!this.getAvailableEntitiesError()) {
			this.manageEntitiesModel.setSelectionModel();
		}
	});

	// Manage Entities Modal Update Functions
	public isSuccessfulSubmit = () => {
		return this._successfulSubmit;
	};
	public resetSuccessfulSubmit = () => {
		this._successfulSubmit = false;
	};
	public isSuccessSnackbarOpen = () => {
		return this._successSnackbarOpen;
	};
	public closeSuccessSnackbar = () => {
		this._successSnackbarOpen = false;
	};

	public getSelectionModel = (): GridRowSelectionModel => {
		return this.manageEntitiesModel.selectionModel;
	};
	public onSelectionChange = (selectionModel: GridRowSelectionModel) => {
		this.manageEntitiesModel.onSelectionModelChange(selectionModel);
	};

	public getManageEntitiesError = () => {
		return this.manageEntitiesModel.error;
	};
	public getManageEntitiesLoading = () => {
		return this.manageEntitiesModel.loading;
	};

	public canSubmitUpdates = () => {
		if (
			this.manageEntitiesModel.loading ||
			this.manageEntitiesModel.availableEntitiesLoading ||
			!isStringUndefinedOrNullOrWhitespace(
				this.manageEntitiesModel.availableEntitiesError,
			)
		) {
			return false;
		}
		return true;
	};

	public manageEntitiesSubmit = flow(function* (
		this: ManageGroupEntitiesViewModel,
	) {
		yield this.manageEntitiesModel.updateGroupEntities();
		if (isStringUndefinedOrNullOrWhitespace(this.manageEntitiesModel.error)) {
			this.closeModal();
			this._successfulSubmit = true;
			this._successSnackbarOpen = true;
		}
	});
}
