import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
	Autocomplete,
	Button,
	Chip,
	CircularProgress,
	ClickAwayListener,
	Divider,
	Grid,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Tag } from 'modules/clients/customer-api/src/entity4/tags';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTags } from 'shared/hooks/useTags';
import { sortDates } from 'shared/utilities/dateUtilities';
import { stonlyData } from 'stonly/functions';

export type TagsHeaderComponentProps = {
	isEditMode?: boolean;
	isAuthor?: boolean;
	entityObjectTags: Tag[];
	addTag: (tagName: string) => Promise<void>;
	removeTag: (tagId: string) => void;
};

const stonlyIds = {
	addTagButton: 'header-add-tag-button',
};

export const TagsHeaderComponent: FC<TagsHeaderComponentProps> = observer(
	({ isEditMode, isAuthor, entityObjectTags = [], addTag, removeTag }) => {
		const theme = useTheme();
		const { tags } = useTags();

		const tagOptions = useMemo(
			() => [
				...tags,
				{
					tagId: '',
					name: '',
					lastUsedOn: '',
				},
			],
			[tags],
		);
		const autocompleteRef = useRef<HTMLDivElement>();

		const [loading, setLoading] = useState<boolean>(false);
		const [isAbleToAddTags, setIsAbleToAddTags] = useState<boolean>(false);
		const [inputValue, setInputValue] = useState('');

		const reset = useCallback(() => {
			setIsAbleToAddTags(false);
			setInputValue('');
		}, []);

		const filterOptions = useCallback((options: Tag[], inputValue: string) => {
			const top5 = options
				.slice()
				.sort((a, b) => sortDates(b.lastUsedOn, a.lastUsedOn))
				.filter(
					(tag) =>
						tag.name.toLowerCase().includes(inputValue?.toLowerCase() ?? '') &&
						tag.tagId !== '',
				)
				.slice(0, 5);

			return [...top5, options.find((x) => x.tagId === '')!];
		}, []);

		useEffect(() => {
			if (!isEditMode) {
				reset();
			}
		}, [isEditMode, reset]);

		const handleCreateTagClick = async (tagText: string): Promise<void> => {
			if (addTag) {
				setLoading(true);
				await addTag(tagText);
				setLoading(false);

				setInputValue('');
				autocompleteRef.current?.focus();
			}
		};

		const deleteTag = async (tagId: string): Promise<void> => {
			removeTag(tagId);
		};

		return (
			<ClickAwayListener onClickAway={reset}>
				<Grid
					container
					spacing={1}
					sx={{
						marginBottom: '10px',
						maxHeight: '80px',
						overflow: 'auto',
						flexDirection: 'row',
						flexWrap: 'wrap',
						paddingX: '2rem',
					}}
				>
					{entityObjectTags.map((tag) => (
						<Grid item key={tag.tagId}>
							<Chip
								id={tag.tagId}
								label={tag.name}
								deleteIcon={isEditMode ? <CloseIcon /> : undefined}
								color="primary"
								onDelete={
									isEditMode
										? async () => {
												await deleteTag(tag.tagId);
										  }
										: undefined
								}
							/>
						</Grid>
					))}
					{isAuthor && isEditMode && (
						<Grid item>
							{!isAbleToAddTags ? (
								<Tooltip
									title={'Maximum tags allowed: 15'}
									placement="bottom-start"
									slotProps={{
										popper: {
											modifiers: [
												{
													name: 'offset',
													options: {
														offset: [15, -15],
													},
												},
											],
										},
									}}
								>
									<Button
										{...stonlyData({
											id: stonlyIds.addTagButton,
										})}
										startIcon={<Add style={{ fontSize: '30px' }} />}
										sx={{
											color: 'white',
											verticalAlign: 'center',
											textTransform: 'none',
											paddingTop: '0.15rem',
										}}
										onClick={() => {
											setIsAbleToAddTags(true);
										}}
									>
										Add tag
									</Button>
								</Tooltip>
							) : (
								<Autocomplete<Tag>
									ref={autocompleteRef}
									loading={loading}
									options={tagOptions}
									value={null}
									getOptionLabel={(option) => option.name ?? ''}
									getOptionDisabled={(option) =>
										(option.tagId === '' &&
											tags.some(
												(x) =>
													x.name.toLowerCase() ===
													inputValue?.trim()?.toLowerCase(),
											)) ||
										entityObjectTags.some(
											(x) =>
												x.name.toLowerCase() ===
												option.name?.trim()?.toLowerCase(),
										)
									}
									filterOptions={(options, params) =>
										filterOptions(options, params.inputValue)
									}
									disablePortal
									disableCloseOnSelect
									autoHighlight
									renderOption={(props, option, state) => {
										const key = option.tagId ?? 'empty';

										if (option.tagId === '') {
											return (
												<Grid key={key} container>
													{filterOptions(tagOptions, state.inputValue)
														.length !== 1 && (
														<Grid item xs={12}>
															<Divider />
														</Grid>
													)}

													<Grid container item xs={12}>
														{(state.inputValue?.length ?? 0) > 0 && (
															<Grid
																item
																xs={12}
																sx={{
																	flexWrap: 'wrap',
																	display: 'flex',
																}}
																component="li"
																{...props}
																style={{
																	padding: '0.25rem',
																	paddingLeft: '0.5rem',
																	paddingRight: '0.5rem',
																}}
															>
																<Typography
																	variant="body1"
																	sx={{
																		marginRight: '0.25rem',
																	}}
																>
																	Create new tag
																</Typography>
																<Chip
																	icon={<Add />}
																	label={inputValue}
																	color="primary"
																	sx={{ marginLeft: '0.25rem' }}
																/>
															</Grid>
														)}
														<Grid
															item
															xs={12}
															sx={{
																paddingTop: '0.25rem',
																paddingX: '0.5rem',
															}}
														>
															<Typography
																variant="caption"
																display="block"
																sx={{
																	fontStyle: 'italic',
																	color:
																		(inputValue?.length ?? 0) >= 25
																			? theme.palette.debtRed[500]
																			: theme.palette.grey[500],
																}}
															>
																25 character limit
															</Typography>
														</Grid>
													</Grid>
												</Grid>
											);
										}

										return (
											<li
												{...props}
												key={key}
												style={{
													padding: '0.25rem',
													paddingLeft: '0.5rem',
													paddingRight: '0.5rem',
												}}
											>
												<Chip label={option.name} color="primary" />
											</li>
										);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="standard"
											placeholder="Search or create a new tag"
											autoFocus
											inputProps={{ ...params.inputProps, maxLength: 25 }}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<>
														{loading && (
															<CircularProgress color="inherit" size={20} />
														)}
													</>
												),
												sx: {
													color: 'white',
													borderColor: 'white',
													borderBottomColor: 'white',
													'&:before, :after, :hover': {
														borderBottomColor: 'transparent !important',
													},
												},
											}}
										/>
									)}
									renderTags={() => <></>}
									inputValue={inputValue}
									onInputChange={(_, value) => setInputValue(value)}
									onChange={async (_, value) => {
										if (value) {
											if (value.tagId === '') {
												await handleCreateTagClick(inputValue);
											} else {
												await handleCreateTagClick(value.name);
											}
										}
									}}
									size="small"
									slotProps={{
										popupIndicator: {
											sx: {
												color: 'white',
											},
										},
										clearIndicator: {
											sx: {
												color: 'white',
											},
										},
									}}
									sx={{
										width: '18rem',
									}}
								/>
							)}
						</Grid>
					)}
				</Grid>
			</ClickAwayListener>
		);
	},
);
