import { AxiosInstance } from 'axios';
import { Write } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Field, ObjectInput } from '../../..';
import { CreatePhoneNumberCollectionField } from '../../../collections';
import {
	CreateCollectionField,
	UpdateCollectionField,
	UpdatePhoneNumberCollectionField,
} from '../../../collections/fields';
import { UpdatePhoneNumberFieldInput } from '../../fields';

//#region Request Models

export type CreateSubaccountCollectionFieldInput = ObjectInput &
	CreateCollectionField & {
		subaccountId: string;
		collectionId: string;
	};

export type UpdateCollectionFieldInput = ObjectInput &
	UpdateCollectionField & {
		subaccountId: string;
		collectionId: string;
	};

export type CreatePhoneNumberCollectionFieldInput = ObjectInput &
	CreatePhoneNumberCollectionField & {
		subaccountId: string;
		collectionId: string;
	};

export type UpdatePhoneNumberCollectionFieldInput =
	UpdatePhoneNumberFieldInput & {
		collectionId: string;
	};

//#endregion

export type SubaccountCollectionFieldEndpoints = {
	create: Write<
		ApiResponse<Field>,
		CreateSubaccountCollectionFieldInput,
		CreateCollectionField
	>;
	update: Write<
		ApiResponse<Field>,
		UpdateCollectionFieldInput,
		UpdateCollectionField
	>;
	createPhoneNumber: Write<
		ApiResponse<Field>,
		CreatePhoneNumberCollectionFieldInput,
		CreatePhoneNumberCollectionField
	>;
	updatePhoneNumber: Write<
		ApiResponse<Field>,
		UpdatePhoneNumberCollectionFieldInput,
		UpdatePhoneNumberCollectionField
	>;
};

export function subaccountCollectionFields(
	axiosInstance: AxiosInstance,
): SubaccountCollectionFieldEndpoints {
	return {
		create: async (input, config = {}) =>
			await axiosInstance.post(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/collections/${input!.collectionId}/fields`,
				{
					fieldIdentifier: input.fieldIdentifier,
					value: input.value,
				},
				{
					...config,
				},
			),
		update: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/collections/${input!.collectionId}`,
				{
					value: input?.value ?? null,
				},
				{
					...config,
				},
			),
		createPhoneNumber: async (input, config = {}) =>
			await axiosInstance.post(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/collections/${input!.collectionId}/fields/phonenumber`,
				{
					fieldIdentifier: input.fieldIdentifier,
					countryCode: input.countryCode ?? null,
					number: input.number ?? null,
					extension: input.extension ?? null,
				},
				{
					...config,
				},
			),
		updatePhoneNumber: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/collections/${input!.collectionId}/fields/${
					input!.fieldId
				}/phonenumber`,
				{
					countryCode: input.countryCode ?? null,
					number: input.number ?? null,
					extension: input.extension ?? null,
				},
				{
					...config,
				},
			),
	};
}
