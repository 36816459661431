import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import {
	GridCellParams,
	GridColDef,
	GridRowClassNameParams,
	GridRowModel,
} from '@mui/x-data-grid-pro';
import {
	EntityType,
	columnDefinitionNames,
} from 'features/entity4/entity4Constants';
import { History } from 'history';
import { generatePath } from 'react-router-dom';
import { paths } from 'shared/constants/paths';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import {
	FrontendGroupRepository,
	GroupEntityDto,
	GroupRepository,
} from '../groupRepository';
import { SingleGroupEntityRequestApprovalModel } from '../shared/singleGroupRequestApprovalModel';
import { SingleGroupRequestApprovalViewModel } from '../shared/singleGroupRequestApprovalViewModel';
import {
	getIconApprovalColor,
	getTooltipText,
	isModifier,
} from '../utilities/approvalUtils';
import { GroupEntitiesListModel } from './groupEntitiesListModel';
import { ManageGroupEntitiesViewModel } from './manageGroupEntitiesButtonViewModel';

export const EntityPendingCSSClass = 'entity-pending-request';

export class GroupEntitiesListViewModel {
	private _groupId: string;
	private _groupRepository: GroupRepository;
	private _history: History;
	private _userId: string;

	private listModel: GroupEntitiesListModel;

	public readonly manageGroupEntitiesViewModel: ManageGroupEntitiesViewModel;
	public readonly manageRequestViewModel: SingleGroupRequestApprovalViewModel;

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
		groupRepository: GroupRepository,
		history: History,
		userId: string,
	) {
		this._groupId = groupId;
		this._groupRepository = groupRepository;
		this._history = history;
		this._userId = userId;

		this.listModel = new GroupEntitiesListModel(
			groupId,
			frontendGroupRepository,
		);

		this.manageGroupEntitiesViewModel = new ManageGroupEntitiesViewModel(
			groupId,
			frontendGroupRepository,
			groupRepository,
		);

		this.manageRequestViewModel = new SingleGroupRequestApprovalViewModel();
	}

	public isLoading() {
		return this.listModel.loading;
	}

	public getEntityListLoading = () => {
		return this.listModel.loading;
	};

	public getEntityListError = () => {
		return isStringUndefinedOrNullOrWhitespace(this.listModel.error)
			? undefined
			: this.listModel.error;
	};

	public getEntityListColumns = (): GridColDef[] => {
		return [
			{
				field: columnDefinitionNames.hasPendingChanges,
				headerName: '',
				width: 50,
				sortable: true,
				hideSortIcons: false,
				filterable: false,
				disableExport: true,
				align: 'center',

				renderCell: this.pendingChangesRenderCell,
				valueGetter: (params: any) => params.row.request,
			},
			{
				field: 'name',
				headerName: 'Name',
				flex: 12,
				minWidth: 250,
			},
			{
				field: 'status',
				headerName: 'Status',
				flex: 10,
				minWidth: 200,
			},
			{
				field: 'region',
				headerName: 'Region',
				flex: 10,
				minWidth: 200,
			},
			{
				field: 'functionalCurrencyCode',
				headerName: 'Functional Currency Code',
				flex: 10,
				minWidth: 200,
			},
		];
	};

	public isRowPending = (params: GridRowClassNameParams<any>) => {
		return params.row.request ? EntityPendingCSSClass : '';
	};

	public pendingChangesRenderCell = (params: any) => {
		if (!params.row.request) {
			return '';
		}

		const isRequester = isModifier(this._userId, params.row.request);
		const tooltipText = getTooltipText(isRequester, params.row.request);
		const iconApprovalColor = getIconApprovalColor(
			isRequester,
			params.row.request,
		);

		return (
			<Tooltip title={tooltipText}>
				<IconButton
					onClick={() => {
						if (isRequester) {
							return;
						}

						this.manageRequestViewModel.openModal(
							new SingleGroupEntityRequestApprovalModel(
								this._groupId,
								params.row as GroupEntityDto,
								this._groupRepository,
							),
						);
					}}
				>
					{params.row.request.type === 'Addition' ? (
						<AddCircle
							sx={{ color: iconApprovalColor }}
							aria-label={tooltipText}
						/>
					) : (
						<RemoveCircle
							color="error"
							sx={{ color: iconApprovalColor }}
							aria-label={tooltipText}
						/>
					)}
				</IconButton>
			</Tooltip>
		);
	};

	public getEntityListRows = (): GridRowModel[] => {
		if (this.manageGroupEntitiesViewModel.isSuccessfulSubmit()) {
			this.manageGroupEntitiesViewModel.resetSuccessfulSubmit();
			this.loadEntities();
		}

		if (this.manageRequestViewModel.isSuccessfulSubmit()) {
			this.manageRequestViewModel.resetSuccessfulSubmit();
			this.loadEntities();
		}

		return this.listModel.groupEntities.map((entity) => {
			return {
				id: entity.id,
				name: entity.name,
				status: entity.status,
				region: entity.region,
				functionalCurrencyCode: entity.functionalCurrencyCode,
				request: entity.request,
			};
		});
	};

	public onCellClick = (params: GridCellParams) => {
		if (params.colDef.field === columnDefinitionNames.hasPendingChanges) return;

		this._history.push(
			generatePath(paths.entity4.objects.object.information.href, {
				objectType: EntityType.Entity,
				objectId: params.id as string,
			}),
		);
	};

	public loadEntities = async () => {
		await this.listModel.load();
	};
}
