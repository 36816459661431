/* eslint-disable mobx/missing-observer */
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Delete } from '@mui/icons-material';
import {
	Collapse,
	Divider,
	Grid,
	IconButton,
	Switch,
	Tooltip,
	Typography,
} from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { User } from 'modules/clients/apiGateway/payments4';
import {
	AddApprovalTierRequest,
	ApprovalRule,
} from 'modules/clients/apiGateway/payments4/approvalRules';
import { T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { T4MultiSelectAutocompleteVertical } from 'shared/components/autocompletes/t4MultiselectAutocompleteVertical';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { DrawerCancelationModal } from 'shared/components/drawer/drawerCancelationModal';
import { T4CurrencyInput } from 'shared/components/t4CurrencyInput';
import { useClients } from 'shared/hooks/useClients';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';
import { convertToOrdinalNumber } from 'utilities/stringUtils';
import {
	isAnotherTierAutoApprove,
	isAnotherTierUnlimitedAmount,
	isLowestTierAmount,
} from './utilities';
import { getAddApprovalTierFormValidator } from './validators';
import { QueryObserverResult } from '@tanstack/react-query';
import { useGetPaymentApproverUsers } from './hooks/useGetPaymentApproverUsers';

export interface AddApprovalTierDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	rule: ApprovalRule | null;
	ruleProperties: ReactNode;
	refetch: () => Promise<QueryObserverResult<ApprovalRule | undefined, Error>>;
}

const stonlyIds = {
	addTierButon: 'approval-tier-add-button',
	cancelButton: 'approval-tier-cancel-button',
};

export type AddApprovalTierForm = {
	amount: number | null;
	isUnlimitedAmount: boolean;
	isAutoApprove: boolean;
	approvalLevels: AddApprovalLevelForm[];
};
type AddApprovalLevelForm = {
	id: string;
	users: User[];
};

const defaultApprovalTierForm: AddApprovalTierForm = {
	amount: null,
	isUnlimitedAmount: false,
	isAutoApprove: false,
	approvalLevels: [],
};

export const AddApprovalTierDrawer: FC<AddApprovalTierDrawerProps> = ({
	isOpen,
	onClose,
	rule,
	ruleProperties,
	refetch,
}) => {
	// #region State

	const { enqueueSnackbar } = useSnackbar();
	const { applicationApiClient } = useClients();
	const {
		data: approverUsers,
		isLoading: isUsersLoading,
		isFetching: isUsersFetching,
	} = useGetPaymentApproverUsers();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const [isCancellationModalOpen, setIsCancellationModalOpen] =
		useState<boolean>(false);

	const {
		handleSubmit,
		control,
		reset,
		formState,
		watch,
		setValue,
		clearErrors,
	} = useForm<AddApprovalTierForm>({
		defaultValues: defaultApprovalTierForm,
		resolver: yupResolver(getAddApprovalTierFormValidator(rule)),
	});
	const {
		fields: currentLevels,
		remove,
		append,
	} = useFieldArray({ control, name: 'approvalLevels' });

	const amount = watch('amount');
	const isUnlimitedAmount = watch('isUnlimitedAmount');
	const isAutoApprove = watch('isAutoApprove');

	const resetDrawer = useCallback(() => {
		setIsLoading(false);
		reset();
		setErrors([]);
	}, [reset]);

	const closeDrawer = useCallback(() => {
		onClose();
		resetDrawer();
	}, [onClose, resetDrawer]);

	// #endregion

	// #region Submit Handler

	const onAddTier = useCallback(
		async (data: AddApprovalTierForm) => {
			try {
				if (rule === null) return;

				setErrors([]);
				setIsLoading(true);
				const requestData: AddApprovalTierRequest = {
					amount: data.isUnlimitedAmount ? -1 : data.amount!,
					isUnlimitedAmount: data.isUnlimitedAmount,
					isAutoApprove: data.isAutoApprove,
					approvalLevels: data.isAutoApprove
						? []
						: data.approvalLevels.map((level, index) => ({
								level: index,
								users: level.users.map((user) => user.userId),
						  })),
				};

				const response =
					await applicationApiClient.payments4.approvalRules.createApprovalTier(
						{ id: rule.id, data: requestData },
					);

				if (response.status === 201) {
					refetch();
					closeDrawer();
					enqueueSnackbar('Successfully added the approval tier.', {
						variant: 'success',
					});
				} else if (response.status === 400 && response.data) {
					setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
				} else throw new Error();
			} catch (error) {
				enqueueSnackbar(
					'An unexpected error occurred and we were unable to create the approval tier. Please try again later.',
					{
						variant: 'error',
					},
				);
			} finally {
				setIsLoading(false);
			}
		},
		[rule, applicationApiClient, closeDrawer, refetch, enqueueSnackbar],
	);

	// #endregion

	// #region Memoized Values

	const sortedUsers = useMemo(
		() =>
			approverUsers?.sort((a, b) => {
				if (a.email !== null && b.email !== null) {
					if (a.email < b.email) return -1;
					if (a.email > b.email) return 1;
					return 0;
				} else {
					if (a.userId < b.userId) return -1;
					if (a.userId > b.userId) return 1;
					return 0;
				}
			}) ?? [],
		[approverUsers],
	);

	const isCurrentAmountLowest = useCallback(
		(amount: number | null) =>
			amount !== null && rule ? isLowestTierAmount(amount, rule) : true,
		[rule],
	);

	const isOtherTierUnlimitedAmount = useMemo(
		() => (rule ? isAnotherTierUnlimitedAmount(rule) : false),
		[rule],
	);

	const isOtherTierAutoApprove = useMemo(
		() => (rule ? isAnotherTierAutoApprove(rule) : false),
		[rule],
	);

	const Actions = useMemo(
		() => [
			<DrawerCancelButton
				stonlyId={stonlyIds.cancelButton}
				onCancel={() => {
					if (formState.isDirty) setIsCancellationModalOpen(true);
					else closeDrawer();
				}}
			/>,
			<DrawerSubmitButton
				stonlyId={stonlyIds.addTierButon}
				label="Add Tier"
				disabled={!formState.isDirty || isLoading}
				onSubmit={handleSubmit(onAddTier)}
			/>,
		],
		[closeDrawer, isLoading, formState, handleSubmit, onAddTier],
	);

	const ApprovalLevelCollapse = useMemo(() => {
		return (
			<Collapse in={!isAutoApprove}>
				<Grid container item xs={12} sx={{ gap: 2 }}>
					{currentLevels.map((level, index) => (
						<Grid key={level.id} container item xs={12}>
							<Grid item xs={12}>
								<Typography
									variant="body1"
									fontWeight={500}
								>{`${convertToOrdinalNumber(
									index + 1,
								)} Approval Level`}</Typography>
							</Grid>
							<Grid
								container
								item
								xs={12}
								columnSpacing={1}
								sx={{ alignItems: 'center' }}
							>
								<Grid item xs={11}>
									<Controller
										name={`approvalLevels.${index}.users`}
										control={control}
										render={({ field: { onChange, value } }) => {
											return (
												<T4MultiSelectAutocompleteVertical<User>
													options={sortedUsers}
													value={value}
													onChange={(_, value) => {
														onChange(value);
													}}
													getOptionKey={(option) => option.userId}
													isOptionEqualToValue={(option, value) =>
														option.userId === value.userId
													}
													getOptionLabel={(option) =>
														option.email ?? option.userId
													}
													loading={isUsersLoading || isUsersFetching}
												/>
											);
										}}
									/>
								</Grid>
								<Grid item xs={1}>
									<IconButton
										onClick={() => remove(index)}
										sx={{ marginTop: '8px', height: 'fit-content' }}
									>
										<Delete aria-label="Remove Approval Level" />
									</IconButton>
								</Grid>
							</Grid>

							<Grid item xs={12} sx={{ marginTop: '1rem' }}>
								<Divider />
							</Grid>
						</Grid>
					))}
					<Grid item xs={12}>
						<Tooltip
							title={
								currentLevels.length >= 2
									? 'Only two levels can be created for each tier'
									: ''
							}
						>
							<span>
								<T4Button
									variant="outlined"
									startIcon={<Add />}
									onClick={() => {
										let tempLevelId = '';
										try {
											tempLevelId = crypto.randomUUID(); // if not supported by browswer, use backup below
										} catch {
											tempLevelId = Math.random().toString(36).substring(0, 10);
										}
										append({ id: tempLevelId, users: [] });
									}}
									disabled={currentLevels.length >= 2 || isAutoApprove}
								>
									Add Approval Level
								</T4Button>
							</span>
						</Tooltip>
					</Grid>
				</Grid>
			</Collapse>
		);
	}, [
		control,
		append,
		remove,
		isAutoApprove,
		currentLevels,
		sortedUsers,
		isUsersLoading,
		isUsersFetching,
	]);

	const CancellationModal = useMemo(
		() => (
			<DrawerCancelationModal
				isOpen={isCancellationModalOpen}
				onClose={() => setIsCancellationModalOpen(false)}
				onSubmit={closeDrawer}
				resourceType="approval tier"
				variant="create"
			/>
		),
		[isCancellationModalOpen, closeDrawer],
	);

	// #endregion

	return (
		<T4DrawerBase
			open={isOpen}
			onClose={closeDrawer}
			title={'Add Tier'}
			actions={Actions}
			loading={isLoading}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 3 }}>
				<Grid item xs={12}>
					{ruleProperties}
				</Grid>
				<Grid container item columnSpacing={2} xs={12}>
					<Grid item xs={6}>
						<Controller
							name="amount"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) =>
								isUnlimitedAmount ? (
									<T4TextFieldV2
										label={`"Up To" Limit ${rule?.currencyCode}`}
										value="Unlimited"
										disabled={true}
										required
									/>
								) : (
									<T4CurrencyInput
										label={`"Up To" Limit ${rule?.currencyCode}`}
										value={value}
										onValueChange={({ floatValue }) => {
											onChange(floatValue ?? null);
											if (!isCurrentAmountLowest(floatValue ?? null))
												setValue('isAutoApprove', false, { shouldDirty: true });
										}}
										decimalScale={0}
										allowNegative={false}
										locale="en-US"
										currencyCode={rule?.currencyCode ?? undefined}
										error={!!error}
										helperText={error && error.message}
										disabled={isUnlimitedAmount}
										required
									/>
								)
							}
						/>
					</Grid>
					<Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
						<Tooltip
							title={
								isOtherTierUnlimitedAmount
									? 'Only one tier can be set with an unlimited amount'
									: isAutoApprove
									? 'A tier with no approvals cannot have an unlimited amount'
									: ''
							}
						>
							<span>
								<Controller
									name="isUnlimitedAmount"
									control={control}
									render={({ field: { onChange, value } }) => (
										<>
											<Switch
												checked={value}
												onChange={(_, value) => {
													onChange(value);
													if (value === true) {
														clearErrors('amount');
														setValue('isAutoApprove', false);
													}
												}}
												disabled={isOtherTierUnlimitedAmount}
											/>
											<Typography variant="body1" sx={{ display: 'inline' }}>
												Unlimited amount
											</Typography>
										</>
									)}
								/>
							</span>
						</Tooltip>
					</Grid>
				</Grid>
				<Grid container item columnSpacing={2} xs={12}>
					<Grid item xs={6}>
						<Typography variant="h4">Approval Levels</Typography>
					</Grid>
					<Grid item xs={6} sx={{ justifyContent: 'flex-end' }}>
						<Tooltip
							title={
								isOtherTierAutoApprove
									? 'Only one tier can be set to have no approvals required'
									: isUnlimitedAmount
									? 'A tier with an unlimited amount must have levels'
									: !isCurrentAmountLowest(amount)
									? 'Only the tier with the lowest amount can be set to have no approvals required'
									: ''
							}
						>
							<span>
								<Controller
									name="isAutoApprove"
									control={control}
									render={({ field: { onChange, value } }) => (
										<>
											<Switch
												checked={value}
												onChange={onChange}
												disabled={
													isOtherTierAutoApprove ||
													!isCurrentAmountLowest(amount) ||
													isUnlimitedAmount
												}
											/>
											<Typography variant="body1" sx={{ display: 'inline' }}>
												No approvals required
											</Typography>
										</>
									)}
								/>
							</span>
						</Tooltip>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					{ApprovalLevelCollapse}
				</Grid>

				{Object.values(errors).length > 0 && (
					<Grid item xs={12}>
						<T4AlertStack errors={errors} />
					</Grid>
				)}
			</Grid>

			{CancellationModal}
		</T4DrawerBase>
	);
};
