import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { parseError } from 'utilities/errors/errorUtils';
import { BaseRelationship } from '../relationshipsObjectTypes';
import {
	IAccountRelationshipDefinitions,
	IRelationshipDefinitionData,
} from '../relationshipApiTypes';
import { EntityTypeId } from 'features/entity4/entity4Constants';
import { filterDefinitions } from '../relationshipUtilities';

export class AccountRelationshipsListModel {
	private readonly _entityId: string;
	private _loading: boolean = false;
	private _error: string | undefined = undefined;

	private _data: AccountRelationships | null = null;
	private _definitions: IRelationshipDefinitionData[] = [];
	private _fundingDirections: string[] = [];
	private _cashFlowMovements: string[] = [];
	private _fundingFrequencies: string[] = [];

	constructor(entityId: string) {
		makeAutoObservable(this);

		this._entityId = entityId;
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get data(): AccountRelationships | null {
		return this._data;
	}
	public get flatData(): BaseRelationship[] {
		let relationships: BaseRelationship[] = this._data?.signatories ?? [];
		relationships = relationships.concat(this._data?.holders ?? []);
		relationships = relationships.concat(this.data?.owners ?? []);
		relationships = relationships.concat(this._data?.sourceAccounts ?? []);
		relationships = relationships.concat(this._data?.targetAccounts ?? []);

		return relationships;
	}
	public get definitions(): IRelationshipDefinitionData[] {
		return this._definitions;
	}
	public get fundingDirections(): string[] {
		return this._fundingDirections;
	}
	public get cashFlowMovements(): string[] {
		return this._cashFlowMovements;
	}
	public get fundingFrequencies(): string[] {
		return this._fundingFrequencies;
	}

	// relationships load function
	public load = flow(function* (this: AccountRelationshipsListModel) {
		try {
			this._loading = true;
			const response: AxiosResponse<ApiResponse<AccountRelationships>> =
				yield customerApi.get<ApiResponse<AccountRelationships>>(
					`account/${this._entityId}/relationships`,
				);

			if (response.data.error) throw response.data.error;
			this._data = response.data.value;

			const definitionsResponse: AxiosResponse<
				ApiResponse<IAccountRelationshipDefinitions>
			> = yield customerApi.get<ApiResponse<IAccountRelationshipDefinitions>>(
				'/Relationship/Definitions/account',
			);
			if (definitionsResponse.data.error) throw response.data.error;
			else {
				this._definitions = filterDefinitions(
					EntityTypeId.Account,
					definitionsResponse.data.value.definitions,
				);
				this._fundingDirections =
					definitionsResponse.data.value.fundingDirections;
				this._cashFlowMovements =
					definitionsResponse.data.value.cashFlowMovements;
				this._fundingFrequencies =
					definitionsResponse.data.value.fundingFrequencies;
			}
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}

interface AccountRelationships {
	entityName: string;
	signatories: AccountSignatoryRelationship[];
	holders: AccountHolderRelationship[];
	owners: AccountOwnerRelationship[];
	sourceAccounts: AccountToAccountRelationship[];
	targetAccounts: AccountToAccountRelationship[];
}

interface AccountSignatoryRelationship extends BaseRelationship {
	name: string;
	title: string;
	email: string;
}

interface AccountHolderRelationship extends BaseRelationship {
	counterpartyName: string;
}

interface AccountOwnerRelationship extends BaseRelationship {
	ownerName: string;
}

interface AccountToAccountRelationship extends BaseRelationship {
	accountName: string | null;
	accountStatus: string | null;
	counterpartyName: string | null;
	fundingDirection: string | null;
	cashFlowMovement: string | null;
	fundingFrequency: string | null;
}

export interface GeneralAccountRelationship extends BaseRelationship {
	// signatory fields
	name: string;
	title: string;
	email: string;

	// owner fields
	ownerName: string;

	// account to account fields
	accountName: string | null;
	accountStatus: string | null;
	counterpartyName: string | null;
	fundingDirection: string | null;
	cashFlowMovement: string | null;
	fundingFrequency: string | null;
}
