import { AxiosResponse } from 'axios';
import { makeAutoObservable, flow } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { Errors } from 'utilities/errors/errorUtils';
import {
	CashFlowClass,
	CashFlowListItem,
	CashFlowSubtype,
	CashFlowType,
} from '../categoriesViewModel';

export type EditSubtypeErrors = Errors<{ code: string; name: string }>;

export class EditSubtypeViewModel {
	private _refetch: () => CancellablePromise<void>;

	private _loading: boolean = false;
	private _error?: string;
	private _errors: EditSubtypeErrors = { code: [], name: [] };
	private _open: boolean = false;
	private _classes: CashFlowClass[] = [];
	private _class?: CashFlowClass;
	private _type?: CashFlowType;
	private _subtype?: CashFlowSubtype;
	private _code?: string;
	private _name?: string;

	constructor(refetch: () => CancellablePromise<void>) {
		makeAutoObservable(this);

		this._refetch = () => refetch();
	}

	public isLoading = () => this._loading;
	public getError = () => this._error;
	public getCodeErrors = () => this._errors.code;
	public getNameErrors = () => this._errors.name;
	public isSubmitDisabled = () => !this._code || !this._name;
	public isOpen = () => this._open;
	public getClasses = () => this._classes.slice();
	public getClass = () => this._class;
	public getTypes = () => this._class?.types.slice() ?? [];
	public getType = () => this._type;
	public getCode = () => this._code;
	public getName = () => this._name;

	public setName = (value: string) => (this._name = !value ? undefined : value);
	public setCode = (value: string) =>
		(this._code = !value ? undefined : value.toUpperCase());

	public open = (row: CashFlowListItem, classes?: CashFlowClass[]) => {
		this._classes = classes ?? [];
		this._class = this._classes.find((_class) => _class.id === row.classId);
		this._type = this._class?.types.find((type) => type.id === row.typeId);
		this._subtype = this._type?.subtypes.find(
			(subtype) => subtype.id === row.subtypeId
		);
		this._code = this._subtype?.code;
		this._name = this._subtype?.name;
		this._open = true;
	};

	public close = () => {
		this._loading = false;
		this._error = undefined;
		this._errors = { code: [], name: [] };
		this._classes = [];
		this._class = undefined;
		this._type = undefined;
		this._subtype = undefined;
		this._code = undefined;
		this._name = undefined;
		this._open = false;
	};

	public save = flow(function* (this: EditSubtypeViewModel) {
		try {
			this._loading = true;
			this._error = undefined;
			this._errors = { code: [], name: [] };

			const response: AxiosResponse<
				ApiResponse<CashFlowClass[]>
			> = yield customerApi.post<AxiosResponse<ApiResponse<CashFlowClass[]>>>(
				`/categories/${this._class?.id}/types/${this._type?.id}/subtypes/${this._subtype?.id}`,
				{
					code: this._code?.trim(),
					name: this._name?.trim(),
				}
			);

			if (response.data.errors) {
				this._errors = (response.data.errors as unknown) as EditSubtypeErrors;
			} else if (response.data.error) {
				this._error = response.data.error;
			} else {
				this._refetch();
				this.close();
			}
		} catch (error) {
			this._error = (error as Error).message;
		} finally {
			this._loading = false;
		}
	});
}
