import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { ExpirationDateRegistrationCell } from 'features/entity4/components/expirationDateRegistrationCell';
import { EntityType } from 'features/entity4/entity4Constants';
import { observer } from 'mobx-react-lite';
import { ExpiringRegistration } from 'modules/clients/customer-api/src/entity4';
import { FC, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { T4Link } from 'shared/components/t4Link';
import { paths } from 'shared/constants/paths';
import {
	getDateColumnDefinition,
	getSingleSelectGridColDef,
} from 'shared/utilities/dataGrid/columnDefinitions';

export type ExpiringRegistrationListProps = {
	registrations: ExpiringRegistration[];
	countries: string[];
};

export const ExpiringRegistrationsList: FC<ExpiringRegistrationListProps> =
	observer(({ registrations, countries, ...rest }) => {
		const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
			{
				page: 0,
				pageSize: 10,
			},
		);

		return (
			<UserPreferencesDataGrid<ExpiringRegistration>
				{...rest}
				stonlyId="registration-renewal-list"
				tableKey="registrationRenewalList"
				emptyMessage="There are no registrations expiring in the next 30 days."
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'expirationDate',
								sort: 'asc',
							},
						],
					},
				}}
				rows={registrations}
				paginationModel={paginationModel}
				onPaginationModelChange={(model) => setPaginationModel(model)}
				pageSizeOptions={[10]}
				hideFooter={false}
				autoHeight
				pagination
				sx={{
					'& .MuiChip-filled': {
						height: '28px',
					},
					'& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
						outline: 'none',
					},
				}}
				columns={[
					{
						field: 'entityCode',
						headerName: 'Entity Code',
						flex: 1,
						minWidth: 120,
					},
					{
						field: 'registrationNumber',
						headerName: 'Registration Number',
						flex: 2,
						minWidth: 120,
						renderCell: ({ value, row }) => (
							<T4Link
								color="secondary"
								to={generatePath(
									paths.entity4.objects.object.registrations.registration.href,
									{
										objectType: EntityType.Entity,
										objectId: row.legalEntityId,
										registrationId: row.id,
									},
								)}
							>
								{value}
							</T4Link>
						),
					},
					{
						...getSingleSelectGridColDef(countries),
						field: 'registrationCountry',
						headerName: 'Country',
						flex: 1,
						minWidth: 120,
					},
					{
						...getDateColumnDefinition(),
						field: 'expirationDate',
						headerName: 'Expiration Date',
						flex: 1,
						minWidth: 150,
						renderCell: ExpirationDateRegistrationCell,
					},
				]}
			/>
		);
	});
