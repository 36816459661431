import { flow, makeAutoObservable } from 'mobx';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { EntityType } from '../../../../entity4Constants';
import { ReferenceRepository } from '../../../../shared/repositories/referenceRepository';
import { TaxIdRepository } from './taxIdRepository';

export class NewTaxIdForm {
	public entityId: string;
	public entityType: EntityType;

	private _hasLoadedCountries: boolean = false;
	private _hasLoadedStateProvinces: boolean = false;

	public loadingCountries: boolean = false;
	public loadingStateProvinces: boolean = false;

	private _countries: ReferenceDataValue[] = [];
	private _stateProvinces: ReferenceDataValue[] = [];

	public country: ReferenceDataValue | null = null;
	public stateProvince: ReferenceDataValue | null = null;

	public isCreatingTaxId: boolean = false;

	private referenceRepository: ReferenceRepository;
	private taxIdRepository: TaxIdRepository;

	constructor(
		entityId: string,
		entityType: EntityType,
		referenceRepository?: ReferenceRepository,
		taxIdRepository?: TaxIdRepository,
	) {
		makeAutoObservable(this);

		this.entityId = entityId;
		this.entityType = entityType;
		this.referenceRepository = referenceRepository ?? new ReferenceRepository();
		this.taxIdRepository = taxIdRepository ?? new TaxIdRepository(entityType);
	}

	private _fetchCountries = flow(function* (this: NewTaxIdForm) {
		this.loadingCountries = true;
		this._countries = yield this.referenceRepository.getCollection('Countries');
		const index = this._countries.findIndex(
			(reference) => reference.identifier === 'US',
		);
		const unitedStates = this._countries.splice(index, 1);
		this._countries = [...unitedStates, ...this._countries];
		this.loadingCountries = false;
		if (!this._hasLoadedCountries) this._hasLoadedCountries = true;
	});

	private _fetchStateProvinces = flow(function* (this: NewTaxIdForm) {
		if (this.country) {
			this.loadingStateProvinces = true;
			this._stateProvinces = yield this.referenceRepository.getCollection(
				'CountrySubdivisions',
				this.country.value,
			);
			if (
				this.stateProvince !== null &&
				!this._stateProvinces.some(
					(stateProvince) =>
						stateProvince.identifier === this.stateProvince?.value,
				)
			) {
				this.stateProvince = null;
			}
			this.loadingStateProvinces = false;
			if (!this._hasLoadedStateProvinces) this._hasLoadedStateProvinces = true;
		}
	});

	public onCountryFocus(): void {
		if (!this._hasLoadedCountries) this._fetchCountries();
	}

	public onStateProviceFocus(): void {
		if (this.country && !this._hasLoadedStateProvinces)
			this._fetchStateProvinces();
	}

	public onCountryChange(value: ReferenceDataValue | null) {
		if (value === null) {
			this._stateProvinces = [];
			this.stateProvince = null;
		}
		if (value !== this.country) {
			this.country = value;
			this._fetchStateProvinces();
		}
	}

	public onStateProvinceChange(value: ReferenceDataValue | null) {
		if (value !== this.stateProvince) {
			this.stateProvince = value;
		}
	}

	public get countries(): ReferenceDataValue[] {
		return this._countries;
	}

	public get stateProvinces(): ReferenceDataValue[] {
		return this._stateProvinces;
	}

	public async createTaxId() {
		this.isCreatingTaxId = true;
		let taxIdId: string = '';
		try {
			taxIdId = await this.taxIdRepository.createTaxId(
				this.entityId,
				this.getCountryReferenceData(),
				this.getStateProvinceReferenceData(),
			);
		} finally {
			this.isCreatingTaxId = false;
		}

		return taxIdId;
	}

	private getCountryReferenceData() {
		const referenceData =
			this._countries.find(
				(country) => country.value === this.country?.value,
			) ?? null;

		return referenceData ? JSON.stringify(referenceData) : null;
	}

	private getStateProvinceReferenceData() {
		const referenceData =
			this._stateProvinces.find(
				(stateProvince) => stateProvince.value === this.stateProvince?.value,
			) ?? null;

		return referenceData ? JSON.stringify(referenceData) : null;
	}
}
