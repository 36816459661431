import { Edit } from '@mui/icons-material';
import { useAdminViewMode } from 'features/administration/_hooks/useAdminViewMode';
import { AdminViewModes } from 'features/administration/providers/contexts/adminViewModeContext';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import { PageHeader, PageHeaderProps } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { GroupPathParams, paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { CannotDisplay } from '../../../shared/components/cannotDisplay';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from '../../../shared/constants/cannotDisplayMessaging';
import { GroupDetails } from '../securityGroups/groupDetails/groupDetails';
import { GroupEntitiesListRoute } from '../securityGroups/groupEntities/groupEntitiesList';
import { GroupMembersListRoute } from '../securityGroups/groupMembers/groupMembersList';
import { FrontendGroupRepository } from '../securityGroups/groupRepository';
import { GroupProfileModel } from './models/groupProfileModel';

export const testIds = {
	headerEditButton: 'header-edit-button',
};

export const stonlyIds = {
	headerEditButton: 'header-edit-button',
};

export const GroupRoute: FC = observer(() => {
	const { groupId } = useParams<GroupPathParams>();
	const frontendGroupRepository = useMemo(
		() => new FrontendGroupRepository(),
		[],
	);
	const [viewModel, setViewModel] = useState(
		new GroupProfileModel(groupId!, frontendGroupRepository),
	);

	useEffect(() => {
		setViewModel(new GroupProfileModel(groupId!, frontendGroupRepository));
	}, [groupId, frontendGroupRepository]);

	const { isAdmin } = useUser();
	if (!isAdmin) {
		return (
			<CannotDisplay
				headingText={ACCESS_DENIED_MESSAGING.HEADING}
				bodyText={ACCESS_DENIED_MESSAGING.BODY}
				imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
			/>
		);
	}

	return <GroupPage viewModel={viewModel} />;
});

export type GroupPageProps = {
	viewModel: GroupProfileModel;
};

export const GroupPage: FC<GroupPageProps> = observer(({ viewModel }) => {
	const { isAdmin } = useUser();
	const { viewMode, setViewMode } = useAdminViewMode();
	const location = useLocation();

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	const getActions = useCallback((): PageHeaderProps['actions'] => {
		let actions: PageHeaderProps['actions'] = [];

		if (isAdmin && location.pathname.endsWith('information')) {
			actions.push(
				{
					stonlyId: 'header-view-button',
					testId: 'header-view-button',
					label: 'View',
					action: () => setViewMode(AdminViewModes.default),
				},
				{
					stonlyId: stonlyIds.headerEditButton,
					testId: testIds.headerEditButton,
					startIcon: <Edit />,
					label: 'Edit',
					action: () => setViewMode(AdminViewModes.edit),
				},
			);
		}

		return actions;
	}, [isAdmin, location.pathname, setViewMode]);

	const isEditMode = useMemo(
		() => viewMode === AdminViewModes.edit,
		[viewMode],
	);

	useEffect(() => {
		if (!location.pathname.endsWith('information')) {
			setViewMode(AdminViewModes.default);
		}
	}, [location.pathname, setViewMode]);

	return (
		<T4View
			loading={viewModel.getLoading()}
			header={
				<PageHeader
					id={viewModel.getName() ?? 'groupprofile'}
					title={viewModel.getName()}
					isEditMode={isEditMode}
					showDoneButton={viewMode !== AdminViewModes.default}
					actions={getActions()}
					aspects={viewModel.getAspects()}
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
								{
									label: 'Security Groups',
									href: paths.administration.groups.href,
								},
							]}
						/>
					}
				/>
			}
		>
			<Switch>
				<Route
					path={paths.administration.groups.group.information.href}
					component={GroupDetails}
					exact
				/>
				<Route
					path={paths.administration.groups.group.members.href}
					component={GroupMembersListRoute}
					exact
				/>
				<Route
					path={paths.administration.groups.group.entities.href}
					component={GroupEntitiesListRoute}
					exact
				/>

				<Route>
					<CannotDisplay
						headingText={NOT_FOUND_MESSAGING.HEADING}
						bodyText={NOT_FOUND_MESSAGING.BODY}
						imageSrc={NOT_FOUND_MESSAGING.IMAGE}
						buttonText={RETURN_TO_HOME}
						buttonHref={paths.root.href}
					/>
				</Route>
			</Switch>
		</T4View>
	);
});
