import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { USER_PREFERENCES_FIELD_OPTIONS } from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { CreateRelationshipDrawer } from '../components/createRelationshipDrawer/createRelationshipDrawer';
import { DeleteRelationshipModal } from '../components/deleteRelationshipModal';
import { EditRelationshipDrawer } from '../components/editRelationshipDrawer/editRelationshipDrawer';
import { RelationshipsLayout } from '../components/relationshipsLayout';
import { IRelationshipDefinitionData } from '../relationshipApiTypes';
import { useCounterpartyRelationshipsViewModel } from './counterpartyRelationshipsViewModel';

export const CounterpartyRelationships: FC = observer(() => {
	const viewModel = useCounterpartyRelationshipsViewModel();
	const history = useHistory();

	const navigateToTab = useCallback(
		(definition: IRelationshipDefinitionData | null) => {
			if (definition) {
				let tab = viewModel.getTabList()[0];

				if (definition.displayName === 'Account Owner') {
					tab = 'Accounts Owned';
				} else if (definition.displayName === 'Account Holder') {
					tab = 'Accounts Held';
				}

				history.push(history.location.pathname + `?tab=${tab}`);
			}
		},
		[history, viewModel],
	);

	return (
		<RelationshipsLayout
			createRelationshipButton={
				<CreateRelationshipDrawer
					viewModel={viewModel.getCreateViewModel()!}
					readOnly={viewModel.loading()}
					navigateToTab={navigateToTab}
				/>
			}
			tabDetails={[
				{
					tab: 'Accounts Owned',
					component: (
						<UserPreferencesDataGrid
							stonlyId="accounts-owned"
							tableKey="counterpartyRelationshipsAccountsOwned"
							columns={viewModel.getAccountsOwnedColumns()}
							rows={viewModel.getAccountsOwnedRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Account Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
				{
					tab: 'Accounts Held',
					component: (
						<UserPreferencesDataGrid
							stonlyId="accounts-held"
							tableKey="counterpartyRelationshipsAccountsHeld"
							columns={viewModel.getAccountsHeldColumns()}
							rows={viewModel.getAccountsHeldRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Account Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
			]}
		>
			<EditRelationshipDrawer
				viewModel={viewModel.editRelationshipDrawerViewModel}
			/>
			<DeleteRelationshipModal
				viewModel={viewModel.deleteRelationshipViewModel}
			/>
		</RelationshipsLayout>
	);
});
