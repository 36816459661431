import { DateRange } from '@mui/x-date-pickers-pro';
import { useQuery } from '@tanstack/react-query';
import { CashFlowClass } from 'features/cash4/categories/categoriesViewModel';
import { GLCode, TransactionRuleRequest } from 'features/cash4/rules/models';
import { T4CustomerClient } from 'modules/clients/customer-api/src/client';
import { Transaction } from 'modules/clients/customer-api/src/transactions';
import { T4DataResponse } from 'modules/clients/types';
import { Moment } from 'moment';
import { useClients } from 'shared/hooks/useClients';
import { customerApi } from 'shared/providers/customerApi';
import {
	dateRangeToQueryParam,
	isValidDateRange,
} from 'shared/utilities/dateUtilities';
import { ApiResponse } from 'utilities/api';
import { TransactionListItem } from '../models';
import { getTransactionListItem } from '../utilities';

export const fetchCategoriesForRules = async () => {
	try {
		const classesResponse = await customerApi.get<ApiResponse<CashFlowClass[]>>(
			'/categories?includeTransactionCount=false',
		);
		if (classesResponse.data.error) throw classesResponse.data.error;
		return classesResponse.data.value;
	} catch (error) {
		throw error;
	}
};

export const fetchGlCodes = async () => {
	try {
		const glCodesResponse = await customerApi.get<T4DataResponse<GLCode[]>>(
			'api/v1/cash4/transactionRules/glcodes',
		);
		if (!glCodesResponse.data.success) throw glCodesResponse.data.errors;
		return glCodesResponse.data.data;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to fetch GL codes', error);
		throw error;
	}
};

export const saveManualCategorization = async (
	transactionRuleRequest: TransactionRuleRequest,
): Promise<void> => {
	try {
		// Save request
		await customerApi
			.post(`/api/v1/cash4/transactionRules`, transactionRuleRequest)
			.then((response) => {
				const data = response.data as ApiResponse<string>;
				if (data.success) {
					return data.value;
				} else if (data.error) {
					throw data.error;
				} else {
					throw new Error('Failed to categorize transaction');
				}
			});
	} catch (error) {
		throw error;
	}
};

export const deleteRule = async (ruleId: string) => {
	try {
		await customerApi.delete(`/api/v1/cash4/transactionRules/${ruleId}`);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to delete rule', error);
		throw error;
	}
};

const fetchTransaction = async (
	transactionId: string,
	customerApiClient: T4CustomerClient,
) => {
	if (transactionId) {
		const response = await customerApiClient.transactions.getTransaction({
			transactionId: transactionId,
		});
		const transactionListItem = getTransactionListItem(response.data.value);
		if (response.data.error) {
			throw new Error(response.data.error);
		} else if (response.data.errors) {
			const errors = Object.values(response.data.errors)
				.flatMap((errors) => errors)
				.join(', ');
			throw new Error(errors);
		}

		return transactionListItem;
	}

	return null;
};

export const useTransaction = (
	transactionId: string,
	setTransaction: (transaction: TransactionListItem | undefined) => void,
	transaction: TransactionListItem | undefined,
) => {
	const { customerApiClient } = useClients();

	return useQuery<TransactionListItem | null>(
		['transaction', transactionId],
		() => fetchTransaction(transactionId, customerApiClient),
		{
			enabled: !!transactionId || !!transaction,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				setTransaction(data ?? undefined);
			},
		},
	);
};
export const fetchTransactions = async (
	dateRange: DateRange<Moment> | null = null,
): Promise<TransactionListItem[]> => {
	if (dateRange != null && !isValidDateRange(dateRange))
		return Promise.resolve([]);

	let queryParams = new URLSearchParams();

	if (dateRange != null) {
		queryParams = dateRangeToQueryParam(queryParams, dateRange);
	}

	let response = await customerApi.get<ApiResponse<Transaction[]>>(
		transactionsPath,
		{ params: queryParams },
	);
	const transactions: Transaction[] = response.data.value;
	if (!!transactions) {
		const transactionListItems: TransactionListItem[] =
			transactions.map((transaction) => getTransactionListItem(transaction)) ??
			[];
		return transactionListItems;
	}
	return [];
};

const cash4Path = 'api/v1.0/cash4';
const projectedTransactionsPath = cash4Path + '/projectedTransactions';
const transactionsPath = '/transactions';

export const deleteProjectedTransactionApiCall = async (
	projectedTransactionId: string,
) => {
	const apiResponse = await customerApi.delete<T4DataResponse<string>>(
		`${projectedTransactionsPath}/${projectedTransactionId}`,
		{},
	);
	if (apiResponse.data.error) throw apiResponse.data.error;

	return apiResponse.data.data;
};
