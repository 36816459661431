/* eslint-disable mobx/missing-observer */
import { FC, useMemo } from 'react';
import { getCurrencySymbol } from 'utilities/currencyUtils';
import { T4NumberInput, T4NumberInputProps } from './t4NumberInput';

interface T4CurrencyInputProps extends T4NumberInputProps {
	locale?: string;
	currencyCode?: string;
}

export const T4CurrencyInput: FC<T4CurrencyInputProps> = ({
	value,
	locale = 'en-US',
	currencyCode = 'USD',
	decimalScale = 2,
	...rest
}) => {
	const currencySymbol = useMemo(
		() => getCurrencySymbol(locale, currencyCode),
		[locale, currencyCode],
	);

	const placeholderText = useMemo(() => {
		if (value === null || value === undefined)
			return `${currencySymbol}${Number(0).toFixed(decimalScale).toString()}`;
	}, [value, currencySymbol, decimalScale]);

	return (
		<T4NumberInput
			{...rest}
			value={value}
			decimalScale={decimalScale}
			prefix={currencySymbol}
			placeholder={placeholderText}
		/>
	);
};
