import { AxiosInstance } from 'axios';
import { PaymentEndpoints, payments } from './payments';
import { PaymentTemplateEndpoints, paymentTemplates } from './paymentTemplates';
import { ApprovalRuleEndpoints, approvalRules } from './approvalRules';

export type User = {
	userId: string;
	name: string | null;
	email: string | null;
};

export type Payments4Endpoints = {
	payments: PaymentEndpoints;
	paymentTemplates: PaymentTemplateEndpoints;
	approvalRules: ApprovalRuleEndpoints;
};

export function payments4(axiosInstance: AxiosInstance): Payments4Endpoints {
	return {
		payments: payments(axiosInstance),
		paymentTemplates: paymentTemplates(axiosInstance),
		approvalRules: approvalRules(axiosInstance),
	};
}
