import { customerApi } from 'shared/providers/customerApi';
import { T4DataResponse } from 'modules/clients/types';
import { Balance } from '../balancesViewModel';
import { useQuery } from '@tanstack/react-query';
import { BalanceListItem } from '../balancesModel';
import { mapBalanceData } from '../utilites/balanceProfile';

const balancesPath = 'api/v1.0/cash4/cash/balances';

const getBalance = async (balanceId: string): Promise<BalanceListItem> => {
	if (!balanceId) return Promise.reject('No balance ID provided');

	const response = await customerApi.get<T4DataResponse<BalanceListItem>>(
		`${balancesPath}/${balanceId}`,
	);

	if (response.data.error) {
		throw new Error(response.data.error);
	}

	return response.data.data;
};

interface UseBalanceProps {
	balanceId: string;
	setBalance: (balance: Balance | null) => void;
	balance: Balance | null;
}

export const useBalance = ({
	balanceId,
	setBalance,
	balance,
}: UseBalanceProps) => {
	const query = useQuery<BalanceListItem, Error>(
		['balance', balanceId],
		() => getBalance(balanceId),
		{
			enabled: !!balanceId && !balance,
			onSuccess: (data) => {
				const mappedDate = mapBalanceData(data);
				setBalance(mappedDate);
			},
			onError: (error) => {
				console.error('Error fetching balance:', error);
				setBalance(null);
			},
		},
	);

	return query;
};

export const upsertNote = async (balanceId: string, noteContent: string) => {
	try {
		const response = await customerApi.put<T4DataResponse<string>>(
			`${balancesPath}/${balanceId}/note`,
			{ content: noteContent },
		);

		if (response.data.error) {
			throw response.data.error;
		}

		return response.data.data;
	} catch (error) {
		throw error;
	}
};

export const deleteBalance = async (balanceId: string) => {
	try {
		const response = await customerApi.delete<T4DataResponse<string>>(
			`${balancesPath}/${balanceId}`,
		);

		if (response.data.error) {
			throw response.data.error;
		}

		return response.data.data;
	} catch (error) {
		throw error;
	}
};
