import { GridColDef } from '@mui/x-data-grid-pro';

export const DefaultColumnHeaderFont =
	"1.15rem 'Roboto','Helvetica','Arial',sans-serif";
export const DefaultColumnCellFont =
	"1.1rem 'Roboto','Helvetica','Arial',sans-serif";

const calculateColumnWidth = (
	columnDefinition: GridColDef,
	valueLengths: (number | null)[] = [],
	headerFont: string = DefaultColumnHeaderFont,
	font: string = DefaultColumnCellFont,
) => {
	// if canvas object doesn't already exist create
	// sharing canvas object is more performant than creating a new every time
	const canvas: HTMLCanvasElement =
		(document.getElementById('datagrid-column-canvas') as HTMLCanvasElement) ||
		(document.createElement('canvas') as HTMLCanvasElement);
	if (canvas.id === null || canvas.id === undefined)
		canvas.id = 'datagrid-column-canvas';
	const context = canvas.getContext('2d');

	// IF CONTEXT IS SOMEHOW NULL RETURN DEFAULT 150PX WIDTH
	if (context === null || context === undefined) return 150;

	// get width necessary for header
	context!.font = headerFont;
	const colHeaderWidth =
		context!.measureText(columnDefinition.headerName ?? '').width + 28;
	if (valueLengths.length === 0) return colHeaderWidth;

	// get average and max widths & figure out which to return
	context!.font = font;
	const sortedColumnLengths: number[] = sortColumnLengths(valueLengths).filter(
		(x) => x !== null,
	) as number[];
	const avgLength =
		sortedColumnLengths.reduce(
			(accumulator, currentValue) => accumulator + currentValue,
			0,
		) / sortedColumnLengths.length;
	const maxLength = sortedColumnLengths[sortedColumnLengths.length - 1];

	// edge case check where there are no values return column header width
	if (avgLength === 0 || maxLength === 0) return colHeaderWidth;

	const avgStringWidth = context!.measureText('n'.repeat(avgLength)).width;
	const maxStringWidth = context!.measureText('n'.repeat(maxLength)).width;

	// if all values are similar in size, return max width
	if (
		avgStringWidth >= maxStringWidth - 40 &&
		avgStringWidth <= maxStringWidth
	) {
		return colHeaderWidth < maxStringWidth ? maxStringWidth : colHeaderWidth;
	}

	// if values vary return average width or column header width
	return colHeaderWidth < avgStringWidth ? avgStringWidth : colHeaderWidth;
};
export default calculateColumnWidth;

export const sortColumnLengths = (valueLengths: (number | null)[]) => {
	return valueLengths.sort((a, b) => {
		if (a === null && b === null) return 0;
		if (a === null && b !== null) return -1;
		if (a !== null && b === null) return 1;
		return (a as number) - (b as number);
	});
};
