import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GroupPathParams } from 'shared/constants/paths';
import {
	FrontendGroupRepository,
	GroupDto,
	GroupRepository,
} from '../groupRepository';
import { ErrorsObject, parseError } from 'utilities/errors/errorUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { T4AdornmentType } from 'features/entity4/shared/components/molecules/t4FieldAdornment';

interface UseGroupDetailsProps {
	isLoading: boolean;
	loadingError: string | undefined;
	name: string | undefined;
	description: string | undefined;
	setName: (value: string) => void;
	setDescription: (value: string) => void;
	getFieldAdornment: (key: 'name' | 'description') => T4AdornmentType;
	getFieldErrors: (key: 'name' | 'description') => string[];
	resetFieldAdornments: () => void;
	load: () => Promise<void>;
	update: (key: 'name' | 'description') => Promise<void>;
}

export const useGroupDetails = (): UseGroupDetailsProps => {
	const { groupId } = useParams<GroupPathParams>();
	const groupsFrontendRepository = useMemo(
		() => new FrontendGroupRepository(),
		[],
	);
	const groupsRepository = useMemo(() => new GroupRepository(), []);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadingError, setLoadingError] = useState<string | undefined>(
		undefined,
	);
	const [group, setGroup] = useState<GroupDto | undefined>(undefined);
	const [form, setForm] = useState<
		{ name: string; description?: string } | undefined
	>(undefined);
	const [fieldAdornments, setFieldAdornments] = useState<{
		name: T4AdornmentType;
		description: T4AdornmentType;
	}>({ name: 'info', description: 'info' });
	const [fieldErrors, setFieldErrors] = useState<ErrorsObject>({});

	const load = useCallback(async () => {
		try {
			if (!groupId) return;
			setIsLoading(true);
			setLoadingError(undefined);

			const response = await groupsFrontendRepository.getGroup(groupId);
			if (response.success) {
				setGroup(response.value);
				setForm({
					name: response.value.name,
					description: response.value.description ?? undefined,
				});
			}
		} catch (error: any) {
			setLoadingError(parseError(error));
		} finally {
			setIsLoading(false);
		}
	}, [groupId, groupsFrontendRepository]);

	const setName = useCallback((value: string) => {
		setForm((oldState) => ({
			...oldState,
			name: value,
		}));
	}, []);

	const setDescription = useCallback((value: string) => {
		setForm((oldState) => ({
			...oldState,
			description: value,
		}));
	}, []);

	const getFieldAdornment = useCallback(
		(key: 'name' | 'description') => {
			return fieldAdornments[key];
		},
		[fieldAdornments],
	);

	const getFieldErrors = useCallback(
		(key: 'name' | 'description') => {
			return fieldErrors[key] ?? [];
		},
		[fieldErrors],
	);

	const update = useCallback(
		async (key: 'name' | 'description') => {
			if (groupId && group && form && group![key] !== form![key]) {
				try {
					setFieldAdornments((oldState) => ({
						...oldState,
						[key]: 'loading',
					}));
					setFieldErrors((oldState) => {
						delete oldState[key];
						return oldState;
					});

					const request = {
						name: form!.name,
						description: !isStringUndefinedOrNullOrWhitespace(form!.description)
							? form!.description!.trim()
							: null,
					};
					const response = await groupsRepository.updateGroup(groupId, request);
					if (response.success) {
						setFieldAdornments((oldState) => ({
							...oldState,
							[key]: 'success',
						}));
						setGroup((oldState) => ({
							...oldState!,
							name: request.name,
							description: request.description,
						}));
					} else {
						if (response.errors) setFieldErrors(response.errors);
						setFieldAdornments((oldState) => ({
							...oldState,
							[key]: 'info',
						}));
					}
				} catch {
				} finally {
				}
			}
		},
		[groupId, group, form, groupsRepository],
	);

	const resetFieldAdornments = useCallback(() => {
		setFieldAdornments({ name: 'info', description: 'info' });
	}, []);

	const resetForm = useCallback(() => {
		setIsLoading(false);
		setLoadingError(undefined);
		setGroup(undefined);
		setForm(undefined);
		resetFieldAdornments();
		setFieldErrors({});
	}, [resetFieldAdornments]);

	useEffect(() => {
		return () => resetForm();
	}, [resetForm]);

	return {
		isLoading,
		loadingError,
		name: form?.name,
		description: form?.description,
		setName,
		setDescription,
		getFieldAdornment,
		getFieldErrors,
		resetFieldAdornments,
		load,
		update,
	};
};
