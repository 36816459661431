import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { flow, makeAutoObservable } from 'mobx';
import { parseError } from 'utilities/errors/errorUtils';
import {
	AvailableGroupMemberDto,
	FrontendGroupRepository,
	GroupRepository,
} from '../groupRepository';

export class ManageGroupMembersModel {
	private _groupId: string;
	private _frontendGroupRepository: FrontendGroupRepository;
	private _groupRepository: GroupRepository;

	private _loading: boolean;
	private _error: string;
	private _availableMembersLoading: boolean;
	private _availableMembersError: string;

	private _availableMembers: AvailableGroupMemberDto[] = [];
	private _selectionModel: GridRowId[] = [];

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
		groupRepository: GroupRepository,
	) {
		makeAutoObservable(this);
		this._groupId = groupId;
		this._frontendGroupRepository = frontendGroupRepository;
		this._groupRepository = groupRepository;

		this._loading = false;
		this._availableMembersLoading = false;
		this._error = '';
		this._availableMembersError = '';
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get availableMembersLoading() {
		return this._availableMembersLoading;
	}
	public get availableMembersError() {
		return this._availableMembersError;
	}
	public get availableMembers() {
		return this._availableMembers;
	}

	public clear = () => {
		this._error = '';
		this._availableMembersError = '';
		this._selectionModel = [];
		this._availableMembers = [];
	};

	public get selectionModel() {
		return this._selectionModel;
	}
	public onSelectionModelChange = (selectionModel: GridRowSelectionModel) => {
		this._selectionModel = selectionModel;
	};
	public setSelectionModel = () => {
		this._selectionModel = this._availableMembers
			.filter((member) => member.isInGroup)
			.map((member) => member.id);
	};

	public updateGroupMembers = flow(function* (this: ManageGroupMembersModel) {
		try {
			this._loading = true;
			this._error = '';

			const response = yield this._groupRepository.updateGroupMembers(
				this._groupId,
				{
					memberIds: this._selectionModel as string[],
				},
			);

			return response.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});

	public loadAvailableMembers = flow(function* (this: ManageGroupMembersModel) {
		try {
			this._availableMembersLoading = true;
			this._availableMembersError = '';

			const response =
				yield this._frontendGroupRepository.getAvailableGroupMembers(
					this._groupId,
				);
			this._availableMembers = response.value;
		} catch (error: any) {
			this._availableMembersError = parseError(error);
		} finally {
			this._availableMembersLoading = false;
		}
	});
}
