/* eslint-disable mobx/missing-observer */
import { IconButton, SxProps, Theme, Tooltip } from '@mui/material';
import { FC, useState } from 'react';

export interface CopyToClipboardIconProps {
	// value to be copied to clipboard
	valueToCopy: string;

	// icon to use
	children: React.ReactElement;

	// icon button size
	size?: 'small' | 'medium' | 'large';

	// styles applied to the tooltip
	sx?: SxProps<Theme>;

	// determines the placement of the tooltip relative to the icon button
	tooltipPlacement?:
		| 'top'
		| 'bottom'
		| 'right'
		| 'left'
		| 'top-start'
		| 'top-end'
		| 'bottom-start'
		| 'bottom-end'
		| 'right-start'
		| 'right-end'
		| 'left-start'
		| 'left-end';

	// initial text diplayed in the icon button tooltip
	initialTooltipText?: string;
}

export const CopyToClipboardIconButton: FC<CopyToClipboardIconProps> = ({
	valueToCopy,
	children,
	size,
	sx,
	tooltipPlacement,
	initialTooltipText = 'Copy to Clipboard',
}) => {
	const [tooltipText, setTooltipText] = useState<string>(initialTooltipText);

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(valueToCopy ?? '');
			setTooltipText('Copied!');
		} catch (err) {
			setTooltipText('Failed to copy!');
		} finally {
			setTimeout(() => setTooltipText(initialTooltipText), 2000);
		}
	};

	return (
		<Tooltip
			onClose={() => setTooltipText(initialTooltipText)}
			title={tooltipText}
			placement={tooltipPlacement}
			sx={sx}
		>
			<IconButton size={size} onClick={copyToClipboard}>
				{children}
			</IconButton>
		</Tooltip>
	);
};
