import { FileDownloadOutlined } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	Typography,
	useTheme,
} from '@mui/material';
import { DataImport } from 'modules/clients/customer-api/src/entity4/dataImports';
import { FC } from 'react';
import { GetHexColorWithOpacity } from 'utilities/colorUtils';

export const DataImportDetailPanel: FC<{
	dataImport: DataImport;
	erroredRecordCount: number;
	successfulRecordCount: number;
	isOriginalDownloading: boolean;
	downloadOriginalFile?: () => void;
	isErrorDownloading: boolean;
	downloadErrorFile?: () => void;
}> = ({
	dataImport,
	erroredRecordCount,
	successfulRecordCount,
	isOriginalDownloading,
	downloadOriginalFile,
	isErrorDownloading,
	downloadErrorFile,
}) => {
	const theme = useTheme();

	const downloadOriginalFileButton = (
		<Button
			variant="outlined"
			startIcon={
				isOriginalDownloading ? (
					<CircularProgress size={20} />
				) : (
					<FileDownloadOutlined />
				)
			}
			onClick={downloadOriginalFile}
			disabled={isOriginalDownloading}
		>
			{'Download Original'}
		</Button>
	);

	const downloadErrorFileButton = (
		<Button
			variant="contained"
			startIcon={
				isErrorDownloading ? (
					<CircularProgress size={20} />
				) : (
					<FileDownloadOutlined />
				)
			}
			onClick={downloadErrorFile}
			disabled={isErrorDownloading}
		>
			{'Download Errors'}
		</Button>
	);

	const getBackgroundColor = () => {
		if (dataImport.status === 'Submitted') {
			return GetHexColorWithOpacity(theme.common?.denim?.[50], 25);
		} else if (dataImport.status === 'Processing') {
			return GetHexColorWithOpacity(theme.common?.maize?.[50], 50);
		} else if (dataImport.status === 'Complete' && erroredRecordCount === 0) {
			return GetHexColorWithOpacity(theme.common?.equityGreen?.[50], 50);
		} else if (dataImport.status === 'Failure') {
			return GetHexColorWithOpacity(theme.common?.debtRed?.[50], 25);
		} else if (erroredRecordCount > 0) {
			return GetHexColorWithOpacity(theme.common?.jasper?.[50], 50);
		}
	};

	return (
		<Box sx={{ padding: '0.25rem' }}>
			<Grid
				container
				sx={{
					backgroundColor: `${
						getBackgroundColor() ?? theme.palette.background.paper
					}`,
					flexDirection: 'column',
					alignItems: 'center',
					padding: '1rem',
					textAlign: 'center',
				}}
			>
				{dataImport.status === 'Submitted' && (
					<>
						<Grid item xs="auto" sx={{ marginBottom: '8px' }}>
							<Typography>The file has been submitted.</Typography>
							<Typography fontWeight={500}>
								Download the file to view submission.
							</Typography>
						</Grid>
						<Grid item xs="auto">
							{downloadOriginalFileButton}
						</Grid>
					</>
				)}

				{dataImport.status === 'Processing' && (
					<>
						<Grid item xs="auto" sx={{ marginBottom: '8px' }}>
							<Typography>The file is being processed.</Typography>
							<Typography fontWeight={500}>
								Download the file to view submission.
							</Typography>
						</Grid>
						<Grid item xs="auto">
							{downloadOriginalFileButton}
						</Grid>
					</>
				)}

				{dataImport.status === 'Complete' && erroredRecordCount === 0 && (
					<>
						<Grid item xs="auto" sx={{ marginBottom: '8px' }}>
							<Typography>{`${dataImport.details.length} of the ${dataImport.details.length} records were imported successfully.`}</Typography>
							<Typography fontWeight={500}>
								Download the file to view the records that were imported.
							</Typography>
						</Grid>
						<Grid item xs="auto">
							{downloadOriginalFileButton}
						</Grid>
					</>
				)}

				{dataImport.status === 'Failure' && (
					<>
						<Grid item xs="auto" sx={{ marginBottom: '8px' }}>
							<Typography>Records failed to import.</Typography>
							<Typography fontWeight={500}>
								{erroredRecordCount > 0
									? 'Download the error file to view detailed information.'
									: 'File could not be read.'}
							</Typography>
						</Grid>
						<Grid container item xs="auto" direction="row" spacing={2}>
							<Grid item xs="auto">
								{downloadOriginalFileButton}
							</Grid>
							{erroredRecordCount > 0 && (
								<Grid item xs="auto">
									{downloadErrorFileButton}
								</Grid>
							)}
						</Grid>
					</>
				)}

				{erroredRecordCount > 0 && dataImport.status !== 'Failure' && (
					<>
						<Grid item xs="auto" sx={{ marginBottom: '8px' }}>
							<Typography>{`${successfulRecordCount} of the ${dataImport.details.length} records were imported successfully.`}</Typography>
							<Typography fontWeight={500}>
								Download the error file to view the {erroredRecordCount} of{' '}
								{dataImport.details.length} records that failed to import.
							</Typography>
						</Grid>
						<Grid container item xs="auto" direction="row" spacing={2}>
							<Grid item xs="auto">
								{downloadOriginalFileButton}
							</Grid>
							<Grid item xs="auto">
								{downloadErrorFileButton}
							</Grid>
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	);
};
