import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClients } from './useClients';
import { useSigma } from 'features/shared/sigma/providers/sigmaProvider';

type UseAddReportButtonProps = {
	open: boolean;
	setOpen: (open: boolean) => void;
};

export function useAddReportButton({ open, setOpen }: UseAddReportButtonProps) {
	const { customerApiClient } = useClients();
	const { workbooks, folders, folder, addWorkbook } = useSigma();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>();
	const [name, setName] = useState<string>();
	const [description, setDescription] = useState<string>();

	const isSubmitDisabled = useMemo(
		() => name?.trim() === '' || name === undefined || isLoading,
		[isLoading, name],
	);

	const resetForm = useCallback((): void => {
		setIsLoading(false);
		setName(undefined);
		setDescription(undefined);
		setError(undefined);
	}, []);

	const onSubmit = useCallback(async () => {
		try {
			setIsLoading(true);
			setError(undefined);

			let folderActual = folder;
			let nameActual = name ?? 'New Report';
			if (
				folderActual?.permission !== 'create' &&
				folderActual?.permission !== 'edit'
			) {
				const myReports = folders.filter((x) => x.name === 'My Reports');
				if (myReports && myReports.length > 0) {
					folderActual = myReports[0];
				}
			}

			if (folderActual) {
				const isNameUnique = !workbooks.some(
					(x) => x.name === nameActual && x.folderId === folderActual?.id,
				);
				if (isNameUnique) {
					const response = await customerApiClient.sigmaEmbed.createReport({
						name: nameActual,
						description: description ?? '',
						folderName: folderActual.name,
						folderId: folderActual.id,
					});

					addWorkbook(response.data);
					setOpen(false);
					resetForm();
				} else {
					setError(
						`Report with name: ${nameActual} already exists in this folder.`,
					);
				}
			} else {
				setError('Unable to get folder to place the report.');
			}
		} catch {
			setError('Unable to create report.');
		} finally {
			setIsLoading(false);
		}
	}, [
		addWorkbook,
		customerApiClient.sigmaEmbed,
		description,
		folder,
		folders,
		name,
		resetForm,
		setOpen,
		workbooks,
	]);

	useEffect(() => {
		return () => resetForm();
	}, [resetForm]);

	return {
		isLoading,
		error,
		isSubmitDisabled,
		name,
		description,
		setName,
		setDescription,
		onSubmit,
	};
}
