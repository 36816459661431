import { OpenInNew } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	Icon,
	Typography,
} from '@mui/material';
import {
	SubObjectType,
	entityTypeIdToEntityType,
} from 'features/entity4/entity4Constants';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { T4Section } from 'shared/components/t4Section';
import { ObjectRegistrationPathParams } from 'shared/constants/paths';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { useUser } from 'shared/hooks/useUser';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { stonlyData } from 'stonly/functions';
import { capitalizeString } from 'utilities/stringUtils';
import { DocumentListView } from '../../../../documents/components/documentListView';
import DocumentListViewModel from '../../../../documents/models/documentListViewModel';
import { RegistrationDatePicker } from '../../../../registrations/components/registrationDatePicker';
import { RegistrationLinkedJurisdictionAddresses } from '../../../../registrations/components/registrationJurisdictionAddresses';
import { RegistrationTextInput } from '../../../../registrations/components/registrationTextInput';
import { REGISTRATION_FIELD_DESCRIPTIONS as fieldDescriptions } from '../../../../registrations/registrationConstants';
import { IRegisteredAgentDto } from '../../../../registrations/registrationTypes';
import { RegistrationViewModel } from '../../../../registrations/views/models/registrationViewModel';
import { T4Button } from '../../../../shared/components/atoms/t4Button';
import { T4Checkbox } from '../../../../shared/components/atoms/t4Checkbox';
import { InputAdornmentWithModal } from '../../../../shared/components/molecules/inputAdornmentWithModal';
import { T4AlertStack } from '../../../../shared/components/molecules/t4AlertStack';
import { CustomModal } from '../../../components/customModal';

export const stonlyIds = {
	setHomeRegistrationButton: 'submit-button',
	cancelButton: 'cancel-button',
};

export const testIds = {
	setHomeRegistrationButton: 'set-home-registration-button',
	cancelButton: 'cancel-button',
};

export const ObjectRegistrationPage: FC = observer(() => {
	const { viewType } = useProfileView();
	const { isAuthor } = useUser();
	const history = useHistory();
	const handleError = useErrorHandler();
	const { updateLastAutoSave, updateSubObjectName } = useHeader();
	const { objectType, objectId, registrationId } =
		useParams<ObjectRegistrationPathParams>();
	const { fetch } = useReferenceDataFetcher();

	const viewModel = useMemo(
		() =>
			new RegistrationViewModel(registrationId!, history, updateLastAutoSave),
		[registrationId, history, updateLastAutoSave],
	);
	const documentListViewModel = useMemo(
		() =>
			new DocumentListViewModel(
				objectType,
				objectId,
				history,
				fetch,
				registrationId,
				SubObjectType.Registration,
			),
		[objectType, objectId, history, fetch, registrationId],
	);

	useEffect(() => {
		updateSubObjectName('');
		viewModel
			.load()
			.catch((error) => {
				handleError(error);
			})
			.finally(() => {
				if (viewModel.registration?.registrationNumber) {
					updateSubObjectName(viewModel.registration?.registrationNumber);
				}
			});
	}, [handleError, updateSubObjectName, viewModel]);

	useEffect(() => {
		documentListViewModel.load();
	}, [documentListViewModel]);

	const isReadonly = viewType !== FieldViews.edit || !isAuthor;

	if (viewModel.loading || !viewModel.registration) {
		return (
			<Box
				width="100%"
				height="100%"
				display="flex"
				justifyContent="center"
				alignItems="center"
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<T4Section error={viewModel.error}>
			<Grid container sx={{ paddingY: '1.5rem' }}>
				<Grid item xs={12}>
					<Typography variant="h3">Registration Details</Typography>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						paddingY: '1.5rem',
					}}
				>
					<Divider />
				</Grid>
				<Grid container item rowSpacing={2} xs={12}>
					<Grid
						container
						item
						flexDirection="row"
						flexWrap="nowrap"
						columnSpacing={2}
						xs={12}
					>
						<Grid item sx={{ width: '100%' }}>
							<RegistrationTextInput
								registration={viewModel.registration}
								fieldKey="registrationNumber"
								label="Registration Number"
								isReadOnly={isReadonly}
								description={fieldDescriptions.regNumber}
							/>
						</Grid>
						<Grid
							item
							container
							flexDirection="row"
							flexWrap="nowrap"
							xs="auto"
							sx={{
								alignSelf: 'center',
							}}
						>
							<Grid item sx={{ alignSelf: 'center' }}>
								<InputAdornmentWithModal
									description={fieldDescriptions.homeRegistration}
									adornmentType={viewModel.adornmentType('isHomeRegistration')}
								/>
							</Grid>
							<Grid item>
								<T4Checkbox
									label="Primary"
									checked={viewModel.registration.isHomeRegistration}
									disabled={
										isReadonly ||
										viewModel.registration.isWaiting('isHomeRegistration')
									}
									onChange={(_event, checked) =>
										viewModel.onMakeHomeRegistrationClick(checked)
									}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<RegistrationTextInput
							registration={viewModel.registration}
							fieldKey="assumedName"
							label="Assumed Name"
							isReadOnly={isReadonly}
							description={fieldDescriptions.assumedName}
						/>
					</Grid>
					<Grid item xs={12}>
						<T4Autocomplete<Option, false, false, false>
							label="Registration Status"
							value={viewModel.currentStatus}
							options={viewModel.statusOptions}
							onSelect={async (status) => {
								viewModel.changeStatus(status);
							}}
							getOptionLabel={(option) => option.displayName}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							loading={viewModel.loadingStatuses}
							startAdornment={
								viewModel.adornmentType('statusId') ? (
									<T4FieldAdornment
										description={fieldDescriptions.status}
										t4AdornmentType={viewModel.adornmentType('statusId')}
									/>
								) : null
							}
							disabled={isReadonly}
							readOnly={isReadonly}
						/>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={12} sm={6}>
							<T4Autocomplete<ReferenceDataValue, false, false, false>
								label="Legal Form"
								value={viewModel.currentLegalForm}
								options={viewModel.legalFormOptions}
								onSelect={async (form) => {
									viewModel.changeLegalForm(form);
								}}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) =>
									option.value === value.value
								}
								startAdornment={
									viewModel.adornmentType('registrationLegalForm') ? (
										<T4FieldAdornment
											description={fieldDescriptions.legalForm}
											t4AdornmentType={viewModel.adornmentType(
												'registrationLegalForm',
											)}
										/>
									) : null
								}
								loading={viewModel.loadingForms}
								disabled={isReadonly}
								readOnly={
									isReadonly ||
									viewModel.currentCountry == null ||
									viewModel.registration?.isWaiting('registrationLegalForm')
								}
							/>
							<T4AlertStack error={viewModel.elfError} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<RegistrationDatePicker
								dateKey={'registrationDate'}
								registration={viewModel.registration}
								label="Registration Date"
								isReadOnly={isReadonly}
								description={fieldDescriptions.regDate}
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={12} sm={6}>
							<RegistrationDatePicker
								dateKey={'expirationDate'}
								registration={viewModel.registration}
								label="Expiration Date"
								isReadOnly={isReadonly}
								description={fieldDescriptions.expDate}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<RegistrationDatePicker
								dateKey={'lastFiledDate'}
								registration={viewModel.registration}
								label="Last Filed Date"
								isReadOnly={isReadonly}
								description={fieldDescriptions.lastFiledDate}
								maxDate={moment().endOf('day')}
							/>
						</Grid>
					</Grid>
					<Grid container item spacing={2}>
						<Grid item xs={12} sm={6}>
							<RegistrationDatePicker
								dateKey={'dissolutionDate'}
								registration={viewModel.registration}
								label="Dissolution Date"
								isReadOnly={isReadonly}
								description={fieldDescriptions.dissolutionDate}
							/>
						</Grid>
						<Grid />
					</Grid>

					<Typography variant="h4" sx={{ marginTop: '12px' }}>
						Location
					</Typography>
					<Grid item container spacing={2}>
						<Grid item xs={12} sm={6}>
							<T4Autocomplete<ReferenceDataValue, false, false, false>
								label="Country"
								value={viewModel.currentCountry}
								onSelect={async (country) => {
									viewModel.changeCountry(country);
								}}
								options={viewModel.countryOptions}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) =>
									option.value === value.value
								}
								loading={viewModel.loadingCountries}
								startAdornment={
									viewModel.adornmentType('registrationCountry') ? (
										<T4FieldAdornment
											description={fieldDescriptions.country}
											t4AdornmentType={viewModel.adornmentType(
												'registrationCountry',
											)}
										/>
									) : null
								}
								disabled={isReadonly}
								readOnly={isReadonly}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<T4Autocomplete<ReferenceDataValue, false, false, false>
								label="State/Province"
								value={viewModel.currentProvince}
								onSelect={async (province) => {
									viewModel.changeProvince(province);
								}}
								options={viewModel.provinceOptions}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) =>
									option.value === value.value
								}
								loading={viewModel.loadingProvinces}
								startAdornment={
									viewModel.adornmentType('registrationState') ? (
										<T4FieldAdornment
											description={fieldDescriptions.state}
											t4AdornmentType={viewModel.adornmentType(
												'registrationState',
											)}
										/>
									) : null
								}
								disabled={isReadonly}
								readOnly={
									isReadonly ||
									viewModel.registration?.isWaiting('registrationState')
								}
							/>

							<T4AlertStack error={viewModel.provinceError} />
						</Grid>
					</Grid>
					<Grid item container spacing={2} xs={12} flexWrap="nowrap">
						<Grid item xs={12}>
							<T4Autocomplete<ReferenceDataValue, false, false, false>
								label="Registration Authority"
								value={viewModel.currentAuthority}
								options={viewModel.authorityOptions}
								onSelect={async (authority) => {
									viewModel.changeAuthority(authority);
								}}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) =>
									option.value === value.value
								}
								loading={viewModel.loadingAuthorities}
								startAdornment={
									viewModel.adornmentType('registrationAuthority') ? (
										<T4FieldAdornment
											description={fieldDescriptions.authority}
											t4AdornmentType={viewModel.adornmentType(
												'registrationAuthority',
											)}
										/>
									) : null
								}
								disabled={isReadonly}
								readOnly={
									isReadonly ||
									(viewModel.currentCountry == null &&
										viewModel.currentAuthority == null) ||
									viewModel.registration.isWaiting('registrationAuthority')
								}
							/>

							<T4AlertStack error={viewModel.authorityError} />
						</Grid>
						<Grid item>
							<Button
								href={viewModel.authorityDetails?.url ?? ''}
								target="_blank"
								startIcon={
									<Icon>
										{viewModel.loadingAuthorityDetails ? (
											<CircularProgress size={20} />
										) : (
											<OpenInNew
												sx={{
													fontSize: '1.0em',
												}}
											/>
										)}
									</Icon>
								}
								variant="outlined"
								color="primary"
								sx={{
									marginTop: '8px',
									height: '40px',
								}}
								fullWidth
								disabled={
									viewModel.loadingAuthorityDetails ||
									!viewModel.authorityDetails?.url
								}
							>
								<Typography noWrap color="inherit" variant="button">
									Visit website
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={6}>
							<RegistrationTextInput
								registration={viewModel.registration}
								fieldKey="registrationCounty"
								label="County"
								isReadOnly={isReadonly}
								description={fieldDescriptions.county}
							/>
						</Grid>
						<Grid item xs={6}>
							<RegistrationTextInput
								registration={viewModel.registration}
								fieldKey="registrationCity"
								label="City"
								isReadOnly={isReadonly}
								description={fieldDescriptions.city}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Typography variant="h4" sx={{ marginTop: '12px' }}>
					Registered Agent
				</Typography>
				<Grid
					paddingTop={2}
					container
					item
					rowSpacing={2}
					sm={12}
					sx={{ alignItems: 'center', justifyItems: 'space-between' }}
				>
					<Grid item container spacing={2} flexWrap="nowrap">
						<Grid item xs={6} sm={8}>
							<T4Autocomplete<IRegisteredAgentDto, false, false, false>
								label="Registered Agent"
								value={viewModel.currentRegisteredAgent}
								options={viewModel.registeredAgentOptions}
								onSelect={async (form) => {
									await viewModel.changeRegisteredAgent(form);
								}}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								onFocus={async () => {
									if (!viewModel.hasRegisteredAgentOptions) {
										await viewModel.loadRegisteredAgents();
									}
								}}
								loading={viewModel.loadingRegisteredAgents}
								groupBy={(option) =>
									capitalizeString(
										entityTypeIdToEntityType.get(option.entityType)!,
									)!
								}
								startAdornment={
									viewModel.adornmentType('registeredAgent') ? (
										<T4FieldAdornment
											description={fieldDescriptions.authority}
											t4AdornmentType={viewModel.adornmentType(
												'registeredAgent',
											)}
										/>
									) : null
								}
								disabled={isReadonly}
							/>
						</Grid>
						<Grid item xs={6} sm={4}>
							<T4Button
								disabled={!viewModel.registration.registeredAgent}
								variant="outlined"
								fullWidth
								onClick={viewModel.navigateToRegisteredAgent}
								sx={{
									marginTop: '8px',
									height: '40px',
								}}
							>
								<Typography noWrap color="inherit" variant="button">
									View Registered Agent
								</Typography>
							</T4Button>
						</Grid>
					</Grid>
					<Grid item xs={12} sm={12} justifyContent="center">
						<T4AlertStack error={viewModel.registeredAgentError} />
						<T4AlertStack error={viewModel.matchingJurisdictionError} />
						<RegistrationLinkedJurisdictionAddresses
							jurisdiction={viewModel.matchingJurisdiction}
							loading={viewModel.loadingMatchingJurisdiction}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						paddingTop: '1.5rem',
					}}
				>
					<DocumentListView viewModel={documentListViewModel} />
				</Grid>
				<CustomModal
					title={'Set as Home Registration'}
					open={viewModel.isMakeHomeRegistrationModalOpen}
					actions={[
						<Button
							data-testid={testIds.cancelButton}
							{...stonlyData({ id: stonlyIds.cancelButton })}
							color="secondary"
							onClick={() => viewModel.closeMakeHomeRegistrationModal()}
						>
							Cancel
						</Button>,
						<Button
							data-testid={testIds.setHomeRegistrationButton}
							{...stonlyData({ id: stonlyIds.setHomeRegistrationButton })}
							variant="contained"
							onClick={() => viewModel.makeHomeRegistration()}
						>
							Set as Home Registration
						</Button>,
					]}
				>
					<Typography>
						Since there can be only one home registration per entity, this
						action clears the primary designation of any previous home
						registration on this entity.
					</Typography>
				</CustomModal>
			</Grid>
		</T4Section>
	);
});
