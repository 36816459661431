import { TestAccountIntegrationResponse } from 'modules/clients/customer-api/src/cash4/accountIntegrations';
import {
	ConnectionIndex,
	GetConnectionsResponse,
} from 'modules/clients/customer-api/src/cash4/connections';
import { useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { BankTab } from '../connectionsListPage';

export type BankProps = {
	bankCode: string;
	bankName: string;
	connections: ConnectionIndex[];
};

export type UseAccountConnectionsProps = {
	loading: boolean;
	error: string;
	isCreateModalOpen: boolean;
	isEditModalOpen: boolean;
	connectionIndex: ConnectionIndex | undefined;
	activeTab: string;
	bankTabs: BankTab[];
	banks: [string, BankProps][];
	tabChange: (tab: string) => void;
	onCreateModalOpen: () => void;
	onCreateModalClose: () => void;
	onEditModalOpen: (connectionIndex: ConnectionIndex) => void;
	onEditModalClose: () => void;
	load: () => Promise<void>;
	connect: (
		connectionId: string,
		accountIntegrationId: string,
	) => Promise<void>;
};

export const useConnectionsList = (): UseAccountConnectionsProps => {
	const { customerApiClient } = useClients();

	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState('');
	const [activeTab, setActiveTab] = useState<string>('');
	const [banks, setBanks] = useState<[string, BankProps][]>([]);
	const [bankTabs, setBankTabs] = useState<BankTab[]>([]);

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const [connectionIndex, setConnectionIndex] = useState<
		ConnectionIndex | undefined
	>();

	async function load() {
		try {
			setLoading(true);
			setError('');

			const response: GetConnectionsResponse =
				await customerApiClient.cash4.connections.getConnections();

			if (response.data.success) {
				let banks = new Map<string, BankProps>();
				response.data.data.forEach((connection) => {
					const bankCode = connection.connector.bankCode ?? 'bankless';
					const bankName = connection.connector.bankName ?? 'Bankless';

					const bank = banks.get(bankCode) ?? {
						bankCode: bankCode,
						bankName: bankName,
						connections: [],
					};
					bank.bankName = connection.connector.bankName;
					bank.connections.push(connection);
					banks.set(bankCode, bank);
				});
				banks = new Map(
					Array.from(banks.entries()).sort(
						(a: [string, BankProps], b: [string, BankProps]) => {
							if (a[1].bankName < b[1].bankName) {
								return -1;
							}
							if (a[1].bankName > b[1].bankName) {
								return 1;
							}
							return 0;
						},
					),
				);
				setBanks(Array.from(banks.entries()));

				setBankTabs(
					Array.from(banks.entries()).map(([_, bank]) => ({
						code: bank.bankCode,
						name: bank.bankName,
					})),
				);

				if (isStringUndefinedOrNullOrWhitespace(activeTab))
					setActiveTab(Array.from(banks.entries())[0][0] ?? 'WFBI');
			} else {
				throw new Error();
			}
		} catch {
			setError('Unable to Load List Table.');
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		load();
	}, []);

	return {
		loading,
		error,
		isCreateModalOpen,
		isEditModalOpen,
		connectionIndex,
		activeTab,
		banks,
		bankTabs,
		load,
		tabChange: (tab: string) => {
			setActiveTab(tab);
		},
		onCreateModalOpen: () => {
			setIsCreateModalOpen(true);
		},
		onCreateModalClose: () => {
			setIsCreateModalOpen(false);
		},
		onEditModalOpen: (connectionIndex: ConnectionIndex) => {
			setConnectionIndex(connectionIndex ?? undefined);
			setIsEditModalOpen(true);
		},
		onEditModalClose: () => {
			setIsEditModalOpen(false);
			setConnectionIndex(undefined);
		},
		connect: async (connectionId: string, accountIntegrationId: string) => {
			try {
				setError('');

				const response: TestAccountIntegrationResponse =
					await customerApiClient.cash4.accountIntegrations.connect({
						connectionId: connectionId,
						accountIntegrationId: accountIntegrationId,
					});
				if (response.data.success) {
					load();
				}
			} catch (error: any) {
				setError('Unable to Test Account Connection');
			}
		},
	};
};
