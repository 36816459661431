import { Box, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import {
	Tab as NewTab,
	OptionList,
} from '../../../../modules/clients/customer-api/src/api/common';
import { T4Object } from '../object/models/t4Object';
import { HeaderRef } from './entityFieldRenderer';
import {
	renderCollection,
	renderFieldGroup,
} from './functions/v2EntityFieldRenderer';

//#region Old Models

/** @deprecated */
export interface Field {
	id: string;
	identifier: string;
	name: string;
	description?: string;
	requiresApproval: boolean;
	isCreationRequirement: boolean;
	isRecommendedCreationField?: boolean;
	validationRegex?: string;
	validationMessage?: string;
	sortOrder: number;
}

/** @deprecated */
export interface StringField extends Field {}

/** @deprecated */
export interface LongTextField extends Field {}

/** @deprecated */
export interface BooleanField extends Field {}

/** @deprecated */
export interface DateField extends Field {}

/** @deprecated */
export interface OptionField extends Field {
	optionList?: OptionList;
}

/** @deprecated */
export interface ReferenceField extends Field {
	referenceCollectionName?: string;
	parentFieldIdentifier?: string;
}

/** @deprecated */
export interface PhoneNumberField extends Field {
	approvedPhoneNumber?: PhoneNumber;
	pendingPhoneNumber?: PhoneNumber;
}

/** @deprecated */
export interface PhoneNumber {
	countryCode?: string;
	number?: string;
	extension?: string;
}

//#endregion

export type TabViewProps = {
	tabId: string;
	object: T4Object;
	tab: NewTab;
	hidden: boolean;
	v2Refs: HeaderRef[];
};
export const TabView: FC<TabViewProps> = observer(
	({ tabId, object, tab, hidden, v2Refs }) => {
		const { entity4StaffPii } = useT4FeatureFlags();

		if (tab)
			return (
				<Box id={tabId} hidden={hidden}>
					<Grid container rowSpacing={2}>
						{[
							...tab.fieldGroups
								.filter((x) => x.isCollection === false)
								.map((fieldGroup, index, array) =>
									renderFieldGroup(
										object,
										tab,
										fieldGroup,
										v2Refs,
										entity4StaffPii,
										undefined,
										array.length - 1 === index,
									),
								),
							...tab.fieldGroups
								.filter((x) => x.isCollection === true)
								.map((collection) =>
									renderCollection(object, tab, collection, undefined, v2Refs),
								),
						]
							.filter((x) => x[1] !== null)
							.sort((a, b) => (a[0] as number) - (b[0] as number))
							.map((x) => (
								<Grid key={x[0]?.toString()} item xs={12}>
									{x[1]}
								</Grid>
							))}
					</Grid>
				</Box>
			);

		return null;
	},
);
