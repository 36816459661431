import { Close } from '@mui/icons-material';
import InputIcon from '@mui/icons-material/Input';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import {
	EntityType,
	objectTypeToObjectTypeName,
} from 'features/entity4/entity4Constants';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { observer } from 'mobx-react-lite';
import { SnackbarKey, closeSnackbar, useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { FileUploadBox } from 'shared/components/fileUploadBox/fileUploadBox';
import { FileUploadValidation } from 'shared/components/fileUploadBox/fileUploadValidation';
import { FormModal } from 'shared/components/formModal';
import { T4Link } from 'shared/components/t4Link';
import { paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { capitalizeString } from 'utilities/stringUtils';

export type CreateDataImportButtonProps = {
	disabled: boolean;
	initialDataType?: EntityType;
	navigateToDataImports?: boolean;
	reload?: () => Promise<void>;
};

export const CreateDataImportButton: FC<CreateDataImportButtonProps> = observer(
	({ disabled, initialDataType, navigateToDataImports = false, reload }) => {
		const theme = useTheme();
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();
		const [isOpen, setIsOpen] = useState<boolean>(false);
		const [loading, setLoading] = useState<boolean>(false);
		const [errors, setErrors] = useState<string[]>([]);
		const [dataType, setDataType] = useState<EntityType | null>(null);
		const [file, setFile] = useState<File>();

		const options: EntityType[] = [
			EntityType.Account,
			EntityType.Entity,
		].sort();

		useEffect(() => {
			if (initialDataType) setDataType(initialDataType);
		}, [initialDataType]);

		const clear = useCallback(() => {
			setErrors([]);
			setLoading(false);
			setDataType(initialDataType ?? null);
			setFile(undefined);
		}, [initialDataType]);

		const close = useCallback(() => {
			setIsOpen(false);
			clear();
		}, [clear]);

		const upload = useCallback(
			async (
				navigationAction:
					| ((snackbarId: SnackbarKey) => JSX.Element)
					| undefined,
				reload: (() => Promise<void>) | undefined,
			) => {
				if (dataType === null || file === undefined) return;

				try {
					setErrors([]);
					setLoading(true);

					const formData = new FormData();
					formData.append(
						'ObjectType',
						objectTypeToObjectTypeName.get(dataType)!,
					);
					formData.append('file', file);

					const response =
						await customerApiClient.entity4.dataImports.uploadDataImport(
							formData,
						);

					if (response.data.success) {
						enqueueSnackbar('Data has been submitted for import.', {
							variant: 'info',
							action: navigationAction,
						});
						close();
						if (reload) await reload();
					} else {
						if (
							Object.keys(response.data.errors).find((x) => x === 'FILE_ERROR')
						) {
							throw new Error('FILE_ERROR');
						} else setErrors(flattenErrors(response.data.errors));
					}
				} catch {
					setErrors([
						'There was an error uploading the file. Please try again later.',
					]);
				} finally {
					setLoading(false);
				}
			},
			[
				customerApiClient.entity4.dataImports,
				dataType,
				file,
				close,
				enqueueSnackbar,
			],
		);

		const navigationAction = navigateToDataImports
			? (snackbarId: SnackbarKey) => (
					<>
						<T4Link
							to={generatePath(paths.entity4.dataImports.href)}
							onClick={() => closeSnackbar(snackbarId)}
							color={theme.palette.primary.contrastText}
						>
							View
						</T4Link>
						<IconButton
							onClick={() => closeSnackbar(snackbarId)}
							sx={{ marginLeft: '8px' }}
						>
							<Close htmlColor={theme.palette.primary.contrastText} />
						</IconButton>
					</>
			  )
			: undefined;

		return (
			<>
				<Button
					variant="outlined"
					startIcon={<InputIcon />}
					onClick={() => setIsOpen(true)}
					disabled={disabled}
				>
					Import
				</Button>
				<FormModal
					open={isOpen}
					loading={loading}
					submitDisabled={loading || file === undefined || dataType === null}
					submitButtonLabel="Import"
					title="Import Data"
					errors={errors}
					onSubmit={async () => await upload(navigationAction, reload)}
					onClose={() => {
						setIsOpen(false);
						clear();
					}}
				>
					<Box sx={{ paddingBottom: '1rem' }}>
						<T4Autocomplete<EntityType>
							options={options}
							getOptionLabel={(option) => capitalizeString(option)}
							label="Data Type"
							required={true}
							value={dataType}
							onSelect={(type) => setDataType(type ?? null)}
						/>
					</Box>

					<FileUploadBox
						validation={new FileUploadValidation(['text/csv'], 2)}
						onClear={() => setFile(undefined)}
						getFile={() => file}
						getFileName={() => file?.name}
						setFile={setFile}
						fileSizeText=".CSV (maximum file size 2MB)"
					/>
				</FormModal>
			</>
		);
	},
);
