import { MenuItem, MenuItemProps, Tooltip, TooltipProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type SigmaMenuItemProps = MenuItemProps & {
	name: string | undefined;
	menuText: string;
	tooltipProps?: Partial<Omit<TooltipProps, 'title'>>;
};

export const SigmaMenuItem: FC<SigmaMenuItemProps> = observer(
	({ disabled, name, menuText, tooltipProps, ...rest }) => {
		if (name === undefined) return null;

		return (
			<Tooltip
				{...tooltipProps}
				title={!disabled ? '' : `You do not have edit access to this ${name}.`}
			>
				<MenuItem {...rest} disabled={disabled}>
					{menuText}
				</MenuItem>
			</Tooltip>
		);
	},
);
