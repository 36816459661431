import {
	CashFlowSubtype,
	CashFlowType,
} from 'features/cash4/categories/categoriesViewModel';
import React, {
	SetStateAction,
	createContext,
	useContext,
	useState,
} from 'react';
import {
	CashFlowCategory,
	GLCode,
	MatchCondition,
	TransactionRule,
	TransactionRuleListItem,
} from '../../models';
import { useDataContext } from './DataProvider';

interface FormContextProps {
	isManualCategorization: FormInput<boolean>;
	selectIsManualCategorization: (input: boolean) => void;
	priority: FormInput<number | null>;
	selectPriority: (input: number | null) => void;
	name: FormInput<string>;
	selectName: (input: string) => void;
	cashFlowClass: FormInput<CashFlowCategory | null>;
	selectClass: (option: CashFlowCategory | null) => void;
	cashFlowType: FormInput<CashFlowType | null>;
	selectType: (option: CashFlowType | null) => void;
	cashFlowSubtype: CashFlowSubtype | null;
	selectSubtype: (option: SetStateAction<CashFlowSubtype | null>) => void;
	glCode: GLCode | null | string;
	onInputChangeGlCode: (
		event: React.SyntheticEvent,
		value: string,
		glCodes: GLCode[],
	) => void;
	onChangeGlCode: (
		event: React.SyntheticEvent,
		value: GLCode | string | null,
		glCodes: GLCode[],
	) => void;
	matchConditions: FormInput<MatchCondition>[];
	selectConditionField: (index: number, field: string | null) => void;
	selectConditionOperator: (index: number, operator: string | null) => void;
	handleChangeConditionValue: (
		value: string,
		conditionIndex: number,
		valueIndex: number,
	) => void;
	clearConditionValues: (conditionIndex: number) => void;
	addMatchCondition: () => void;
	addMatchConditionOr: (index: number) => void;
	removeMatchConditionOr: (conditionIndex: number, valueIndex: number) => void;
	removeMatchCondition: (index: number) => void;
	disposeRule: () => void;
	initializeRule: (rule: TransactionRuleListItem) => void;
	getRule: () => TransactionRule;
}

const FormContext = createContext<FormContextProps | null>(null);

export const useFormContext = (): FormContextProps => {
	const context = useContext(FormContext);
	if (context === null) {
		throw new Error('useFormContext must be used within a FormProvider');
	}
	return context;
};

interface FormProviderProps {
	children: React.ReactNode;
}

export interface FormInput<TData> {
	field: TData;
	dirty: boolean;
}

export const FormProvider = ({ children }: FormProviderProps) => {
	const [isManualCategorization, setIsManualCategorization] = useState<
		FormInput<boolean>
	>({
		field: false,
		dirty: false,
	});
	const [priority, setPriority] = useState<FormInput<number | null>>({
		field: null,
		dirty: false,
	});
	const [name, setName] = useState<FormInput<string>>({
		field: '',
		dirty: false,
	});
	const [cashFlowClass, setCashFlowClass] = useState<
		FormInput<CashFlowCategory | null>
	>({
		field: null,
		dirty: false,
	});
	const [cashFlowType, setCashFlowType] = useState<
		FormInput<CashFlowType | null>
	>({
		field: null,
		dirty: false,
	});
	const [cashFlowSubtype, setSubtype] = useState<CashFlowSubtype | null>(null);
	const [glCode, setGlCode] = useState<GLCode | null | string>(null);
	const [matchConditions, setMatchConditions] = useState<
		FormInput<MatchCondition>[]
	>([{ field: { field: null, operator: null, values: [''] }, dirty: false }]);

	const {
		findCashFlowClass,
		findCashFlowType,
		findCashFlowSubtype,
		rule: fetchedRule,
	} = useDataContext();

	const selectIsManualCategorization = (input: boolean): void => {
		setIsManualCategorization({ field: input, dirty: true });
	};

	const selectPriority = (input: number | null): void => {
		setPriority({ field: input, dirty: true });
	};

	const selectClass = (option: CashFlowCategory | null) => {
		setCashFlowClass({ field: option, dirty: true });
	};

	const selectType = (option: CashFlowType | null) => {
		setCashFlowType({ field: option, dirty: true });
	};

	const selectSubtype = (option: SetStateAction<CashFlowSubtype | null>) => {
		setSubtype(option);
	};

	const selectName = (input: string) => {
		setName({ field: input, dirty: true });
	};

	// onInputChange
	const onInputChangeGlCode = (
		event: React.SyntheticEvent,
		value: string,
		glCodes: GLCode[],
	) => {
		if (event && event.type === 'change') {
			const matchingOption = glCodes.find((option) => option.code === value);
			if (matchingOption) {
				setGlCode(matchingOption);
			} else {
				setGlCode(value.trim() === '' ? null : { id: null, code: value });
			}
		}
	};

	// onChange
	const onChangeGlCode = (
		event: React.SyntheticEvent,
		value: GLCode | string | null,
		glCodes: GLCode[],
	) => {
		if (typeof value === 'string') {
			const matchingGLCode = glCodes.find(
				(glCode) => glCode.code === value,
			) ?? {
				id: null,
				code: value,
			};
			setGlCode(matchingGLCode);
		} else {
			setGlCode(value);
		}
	};

	const selectConditionField = (index: number, field: string | null) => {
		const newConditions = [...matchConditions];
		newConditions[index].field.field = field;
		newConditions[index].dirty = true;
		setMatchConditions(newConditions);
	};

	const selectConditionOperator = (index: number, operator: string | null) => {
		const newConditions = [...matchConditions];
		newConditions[index].field.operator = operator;
		newConditions[index].dirty = true;
		setMatchConditions(newConditions);
	};

	const handleChangeConditionValue = (
		conditionvalue: string,
		conditionIndex: number,
		valueIndex: number,
	) => {
		const newConditions = [...matchConditions];
		newConditions[conditionIndex].field.values[valueIndex] = conditionvalue;
		newConditions[conditionIndex].dirty = true;
		setMatchConditions(newConditions);
	};

	const clearConditionValues = (conditionIndex: number) => {
		const newConditions = [...matchConditions];
		newConditions[conditionIndex].field.values = newConditions[
			conditionIndex
		].field.values.map(() => '');
		setMatchConditions(newConditions);
	};

	const addMatchConditionOr = (index: number) => {
		const newConditions = [...matchConditions];
		matchConditions[index].field.values = [
			...matchConditions[index].field.values,
			'',
		];
		setMatchConditions(newConditions);
	};

	const removeMatchConditionOr = (
		conditionIndex: number,
		valueIndex: number,
	) => {
		const newConditions = [...matchConditions];
		newConditions[conditionIndex].field.values.splice(valueIndex, 1);
		setMatchConditions(newConditions);
	};

	const addMatchCondition = () => {
		const newConditions = [...matchConditions];
		const newCondition: MatchCondition = {
			field: null,
			operator: '',
			values: [''],
		};
		newConditions.push({ field: newCondition, dirty: false });
		setMatchConditions(newConditions);
	};

	const removeMatchCondition = (conditionIndex: number) => {
		const newConditions = [...matchConditions];
		newConditions.splice(conditionIndex, 1);
		setMatchConditions(newConditions);
	};

	const disposeRule = () => {
		setName({ field: '', dirty: false });
		setCashFlowClass({ field: null, dirty: false });
		setCashFlowType({ field: null, dirty: false });
		setSubtype(null);
		setGlCode(null);
		setMatchConditions([
			{ field: { field: null, operator: null, values: [''] }, dirty: false },
		]);
	};

	const initializeRule = (rule: TransactionRuleListItem) => {
		setIsManualCategorization({
			field: rule.isManualCategorization,
			dirty: true,
		});
		setPriority({ field: rule.priority, dirty: true });
		setName({ field: rule.name, dirty: true });
		setCashFlowClass({ field: findCashFlowClass(rule.cfc.id), dirty: true });
		setCashFlowType({
			field: findCashFlowType(rule.cfc.id, rule.cft.id),
			dirty: true,
		});
		if (rule.cfst?.id) {
			setSubtype(findCashFlowSubtype(rule.cft.id, rule.cfst?.id));
		}
		setGlCode(rule.glCode ?? null);
		//use JSON.Parse to deep copy the array.  This prevents mutation of the original array
		setMatchConditions(
			JSON.parse(
				JSON.stringify(
					rule.matchConditions.map((condition) => {
						return { field: condition, dirty: true };
					}),
				),
			),
		);
	};

	const getRule = (): TransactionRule => {
		const rule: TransactionRule = {
			...(fetchedRule?.id && { id: fetchedRule.id }),
			isManualCategorization: isManualCategorization.field,
			priority: priority.field!,
			transactionCount: fetchedRule?.menu.transactionCount!,
			name: name.field,
			cashFlowClass: cashFlowClass.field!,
			cashFlowType: cashFlowType.field!,
			cashFlowSubtype: cashFlowSubtype!,
			glCode: glCode as GLCode,
			matchConditions: matchConditions.map((condition) => condition.field),
		};

		return rule;
	};

	const value = {
		isManualCategorization,
		priority,
		name,
		cashFlowClass,
		selectClass,
		cashFlowType,
		selectType,
		cashFlowSubtype,
		selectSubtype,
		glCode,
		onInputChangeGlCode,
		onChangeGlCode,
		matchConditions,
		selectConditionField,
		selectConditionOperator,
		handleChangeConditionValue,
		clearConditionValues,
		addMatchConditionOr,
		removeMatchConditionOr,
		addMatchCondition,
		removeMatchCondition,
		disposeRule,
		initializeRule,
		selectIsManualCategorization,
		selectPriority,
		selectName,
		getRule,
	};

	return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
