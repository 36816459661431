import { useAuth0 } from '@auth0/auth0-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isObjectNullOrUndefinedOrEmpty } from 'utilities/objectUtils';
import { useApplicationConfiguration } from './useApplicationConfigurations';

export type UseApplicationProps = {
	isLoading: boolean;
	isAuthenticated: boolean;
	error: Error | undefined;
};

export function useApplication(): UseApplicationProps {
	const { loading: loadingApplicationConfiguration } =
		useApplicationConfiguration();
	const flags = useFlags();
	const authContext = useAuth0();

	const isLoading =
		isObjectNullOrUndefinedOrEmpty(flags) ||
		authContext.isLoading ||
		!authContext.user ||
		loadingApplicationConfiguration;

	return {
		isLoading,
		isAuthenticated: authContext.isAuthenticated,
		error: authContext.error,
	};
}
