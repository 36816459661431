import { AxiosInstance } from 'axios';
import { Read } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Object, ObjectInput } from '..';
import {
	SubaccountCollectionEndpoints,
	subaccountCollections,
} from './collections';
import { SubaccountFieldEndpoints, subaccountFields } from './fields';

//#region Request Models

export type SubaccountInput = ObjectInput & {
	subaccountId: string;
};

//#endregion

export type SubaccountEndpoints = {
	get: Read<ApiResponse<Object>, SubaccountInput>;
	fields: SubaccountFieldEndpoints;
	collections: SubaccountCollectionEndpoints;
};

export function subaccounts(axiosInstance: AxiosInstance): SubaccountEndpoints {
	return {
		get: async (input, config) =>
			await axiosInstance.get(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}`,
				{ ...config },
			),
		fields: subaccountFields(axiosInstance),
		collections: subaccountCollections(axiosInstance),
	};
}
