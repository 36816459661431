import { OpenInNew } from '@mui/icons-material';
import {
	Box,
	Button,
	Checkbox,
	Divider,
	Grid,
	Icon,
	Typography,
} from '@mui/material';
import { getDateWarningMessage } from 'features/entity4/entities/utilities/dateUtilities';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { T4Section } from 'shared/components/entity4/t4Section';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { ObjectTaxIdPathParams } from '../../../../../../shared/constants/paths';
import { DocumentListView } from '../../../../documents/components/documentListView';
import DocumentListViewModel from '../../../../documents/models/documentListViewModel';
import { SubObjectType } from '../../../../entity4Constants';
import { useProfileView } from '../../../../entityProfile/providers/entityProfileContextProvider';
import {
	T4Autocomplete,
	T4MultipleAutocomplete,
} from '../../../../shared/components/atoms/t4Autocomplete';
import { T4Checkbox } from '../../../../shared/components/atoms/t4Checkbox';
import { T4DateField } from '../../../../shared/components/atoms/t4DateField';
import { T4TextFieldV2 } from '../../../../shared/components/atoms/t4TextField';
import { T4AlertStack } from '../../../../shared/components/molecules/t4AlertStack';
import { T4FieldAdornment } from '../../../../shared/components/molecules/t4FieldAdornment';
import { FieldViews } from '../../../../shared/fieldSets/fieldViews/fieldViewTypes';
import { MakePrimaryTaxIdDialog } from '../components/makePrimaryTaxIdDialog';
import { TaxId } from '../models/taxId';

export type TaxIdViewProps = {
	taxId: TaxId;
};

export const TaxIdView: FC<TaxIdViewProps> = observer(({ taxId }) => {
	const { viewType } = useProfileView();
	const { objectId, objectType } = useParams<ObjectTaxIdPathParams>();
	const { fetch } = useReferenceDataFetcher();
	const history = useHistory();
	const documentListViewModel = useMemo(
		() =>
			new DocumentListViewModel(
				objectType,
				objectId!,
				history,
				fetch,
				taxId.taxIdId,
				SubObjectType.TaxId,
			),
		[objectType, objectId, history, fetch, taxId.taxIdId],
	);

	useEffect(() => {
		documentListViewModel.load();
	}, [documentListViewModel]);

	const readOnly = viewType !== FieldViews.edit;

	return (
		<T4Section>
			<Grid container rowSpacing={2} columnSpacing={2} paddingBottom={2}>
				<Grid item xs={12}>
					<Grid container>
						<Grid item>
							<Typography variant="h3">Tax ID Details</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid
					item
					container
					xs={12}
					flexDirection="row"
					flexWrap="nowrap"
					columnSpacing={2}
				>
					<Grid item sx={{ width: '100%' }}>
						<T4TextFieldV2
							label="Tax ID Number"
							value={taxId.taxIdNumber ?? ''}
							onChange={(value) => taxId.onTaxIdNumberChange(value)}
							onBlur={() => taxId.onTaxIdNumberBlur()}
							startAdornment={
								<T4FieldAdornment
									title="Tax ID Number"
									description="The tax identification number issued by the tax ID issuing authority for the primary country of residence."
									t4AdornmentType={taxId.taxIdNumberAdornmentState}
								/>
							}
							InputProps={{
								readOnly: readOnly,
							}}
						/>
					</Grid>
					<Grid
						item
						container
						flexDirection="row"
						flexWrap="nowrap"
						xs="auto"
						sx={{
							alignSelf: 'center',
						}}
					>
						<Grid item sx={{ alignSelf: 'center' }}>
							<T4FieldAdornment
								title="Primary Federal Income Tax ID"
								description="This indicates if the tax ID number is the primary federal income tax ID for a given legal entity."
								t4AdornmentType={
									taxId.isPrimaryFederalIncomeTaxIdAdornmentState
								}
							/>
						</Grid>
						<Grid item>
							<T4Checkbox
								label={'Primary Federal Income Tax ID'}
								checked={taxId.isPrimaryFederalIncomeTaxId}
								onChange={(_event, checked) => {
									taxId.onIsPrimaryFederalIncomeTaxIdClick(checked);
								}}
								disabled={readOnly}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<T4MultipleAutocomplete<Option>
						label="Purpose"
						value={taxId.selectedPurposes}
						options={taxId.purposes}
						onChange={(_, purposes) => taxId.onPurposeChange(purposes)}
						onFocus={() => taxId.onPurposeFocus()}
						onBlur={() => taxId.onPurposeClose()}
						getOptionLabel={(option) => option.displayName}
						isOptionEqualToValue={(a, b) => a.id === b.id}
						renderOption={(props, option) => (
							<li {...props}>
								<Checkbox checked={taxId.isSelectedPurpose(option)} />
								<Typography>{option.displayName}</Typography>
							</li>
						)}
						renderTags={(purposes) =>
							purposes
								.sort(
									(a, b) =>
										a.displayName?.localeCompare(b.displayName ?? '') ?? 0,
								)
								.map((purpose, index) => (
									<Typography
										key={`purpose-${purpose.displayName}-${purpose.id}`}
										sx={{
											marginRight: '0.25rem',
											paddingY: '8.5px',
										}}
									>{`${purpose.displayName}${
										index === purposes.length - 1 ? '' : ','
									}`}</Typography>
								))
						}
						loading={taxId.loadingSelectedPurposes}
						startAdornment={
							<T4FieldAdornment
								title="Purpose"
								description="The purpose of tax ID number issued by the taxation authority."
								t4AdornmentType={taxId.purposeAdornmentState}
							/>
						}
						readOnly={readOnly}
					/>
				</Grid>
				<Grid item xs={12}>
					<T4Autocomplete<ReferenceDataValue, false, boolean, false>
						label="Issuing Country"
						disableCloseOnSelect
						value={taxId.country}
						options={taxId.countries}
						onSelect={(value) => taxId.onCountrySelect(value)}
						onFocus={() => taxId.onCountryFocus()}
						getOptionLabel={(option) => option.displayName}
						isOptionEqualToValue={(a, b) => a.value === b.value}
						startAdornment={
							<T4FieldAdornment
								title="Issuing Country"
								description="The country of the tax ID issuing authority."
								t4AdornmentType={taxId.countryAdornmentState}
							/>
						}
						readOnly={readOnly}
						required
						disableClearable
					/>
				</Grid>
				<Grid item xs={12}>
					<T4DateField
						id="taxid-issueddate"
						label="Issued Date"
						value={taxId.issuedDate}
						onChange={(date) => taxId.onIssuedDateChange(date)}
						onAccept={(date) => taxId.onIssuedDateAccept(date)}
						onBlur={() => taxId.onIssuedDateBlur()}
						slotProps={{
							field: {
								InputProps: {
									startAdornment: (
										<T4FieldAdornment
											title="Issued Date"
											description="The date that the tax ID number was issued by the governmental taxation authority."
											t4AdornmentType={taxId.issuedDateAdornmentState}
										/>
									),
								},
							},
						}}
						readOnly={readOnly}
					/>
					<T4AlertStack
						warning={getDateWarningMessage('Issued Date', taxId.issuedDate)}
					/>
				</Grid>
				<Grid item xs={12}>
					<T4Autocomplete<Option, false, false, false>
						label="Tax ID Status"
						value={taxId.status}
						options={taxId.statuses}
						onSelect={(option) => taxId.onStatusSelect(option)}
						onFocus={() => taxId.onStatusFocus()}
						getOptionLabel={(option) => option.displayName}
						isOptionEqualToValue={(a, b) => a.id === b.id}
						startAdornment={
							<T4FieldAdornment
								title="Tax ID Status"
								description="Status of the tax ID number. Tax ID numbers can have several statuses over their duration, however, they can only have one status at a time."
								t4AdornmentType={taxId.taxIdStatusAdornmentState}
							/>
						}
						readOnly={readOnly}
					/>
				</Grid>
				<Grid item xs={12}>
					<T4Autocomplete<ReferenceDataValue, false, false, false>
						label="Tax ID Type"
						value={taxId.taxIdType}
						options={taxId.types}
						onSelect={(option) => taxId.onTaxIdTypeSelect(option)}
						onFocus={() => taxId.onTaxIdTypeFocus()}
						getOptionLabel={(option) => option.displayName}
						isOptionEqualToValue={(a, b) => a.value === b.value}
						startAdornment={
							<T4FieldAdornment
								title="Tax ID Type"
								description="The type of tax ID number issued by the issuing authority (governmental/jurisdictional). This generally applies to national tax ID numbers."
								t4AdornmentType={taxId.taxIdTypeAdornmentState}
							/>
						}
						readOnly={readOnly}
					/>
					<T4AlertStack error={taxId.taxIdTypeError} />
				</Grid>
				<Grid item xs={12}>
					<T4Autocomplete<ReferenceDataValue, false, false, false>
						label="Issuing State / Province"
						value={taxId.stateProvince}
						options={taxId.stateProvinces}
						onSelect={(option) => taxId.onStateProvinceSelect(option)}
						onFocus={() => taxId.onStateProvinceFoucs()}
						getOptionLabel={(option) => option.displayName}
						isOptionEqualToValue={(a, b) => a.value === b.value}
						startAdornment={
							<T4FieldAdornment
								title="Issuing State / Province"
								description="The state or province of the tax ID issuing authority."
								t4AdornmentType={taxId.stateProvinceAdornmentState}
							/>
						}
						readOnly={readOnly}
					/>
					<T4AlertStack error={taxId.stateProvinceError} />
				</Grid>
				<Grid item xs={12}>
					<T4TextFieldV2
						label="Issuing County"
						value={taxId.countyParish ?? ''}
						onChange={(value) => taxId.onCountyParishChange(value)}
						onBlur={() => taxId.onCountyParishBlur()}
						startAdornment={
							<T4FieldAdornment
								title="Issuing County"
								description="The county or other sub-jurisdiction if applicable of the tax ID issuing authority."
								t4AdornmentType={taxId.countyParishAdornmentState}
							/>
						}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<T4TextFieldV2
						label="Issuing City"
						value={taxId.city ?? ''}
						onChange={(value) => taxId.onCityChange(value)}
						onBlur={() => taxId.onCityBlur()}
						startAdornment={
							<T4FieldAdornment
								title="Issuing City"
								description="The city or municipality of the tax ID issuing authority."
								t4AdornmentType={taxId.cityAdornmentState}
							/>
						}
						InputProps={{
							readOnly: readOnly,
						}}
					/>
				</Grid>
				<Grid
					item
					container
					columnSpacing={2}
					flexDirection="row"
					flexWrap="nowrap"
				>
					<Grid item sx={{ width: '100%' }}>
						<T4Autocomplete<ReferenceDataValue, false, false, false>
							label="Issuing Authority"
							value={taxId.issuingAuthority}
							options={taxId.issuingAuthorities}
							onChange={(_, option) => taxId.onIssuingAuthoritySelect(option)}
							onFocus={() => taxId.onIssuingAuthorityFocus()}
							getOptionLabel={(option) => option.displayName}
							isOptionEqualToValue={(a, b) => a.value === b.value}
							startAdornment={
								<T4FieldAdornment
									title="Issuing Authority"
									description="The tax ID issuing authority. Generally, this value represents the governmental/jurisdictional authority that issued the tax ID number."
									t4AdornmentType={taxId.issuingAuthorityAdornmentState}
								/>
							}
							readOnly={readOnly}
						/>
						<T4AlertStack error={taxId.issuingAuthorityError} />
					</Grid>
					<Grid item>
						<Button
							href={taxId.issuingAuthorityUrl}
							target="_blank"
							disabled={taxId.issuingAuthorityUrl.length === 0}
							startIcon={
								<Icon>
									<OpenInNew
										sx={{
											fontSize: '1.0em',
										}}
									/>
								</Icon>
							}
							variant="outlined"
							sx={{
								marginTop: '8px',
								paddingY: '8px',
							}}
							fullWidth
						>
							<Typography
								noWrap
								color="inherit"
								sx={{
									fontWeight: 500,
									fontSize: '0.875rem',
								}}
							>
								Visit website
							</Typography>
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Box paddingY={2}>
				<DocumentListView viewModel={documentListViewModel} />
			</Box>

			<MakePrimaryTaxIdDialog
				open={taxId.setPrimaryFederalIncomeTaxIdModalOpen}
				onClose={() => {
					taxId.setPrimaryFederalIncomeTaxIdModalOpen = false;
				}}
				taxId={taxId}
			/>
		</T4Section>
	);
});
