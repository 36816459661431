import { makeAutoObservable } from 'mobx';
import {
	Object as ObjectData,
	ObjectType,
} from 'modules/clients/customer-api/src/api/objects';
import { Collection } from './collection';
import { ObjectField } from './objectField';

export class T4Object {
	public id: string;
	public objectType: ObjectType;
	public parentObjectId?: string;
	public parentObjectType?: ObjectType;
	public fields: ObjectField[] = [];
	public collections: Collection[] = [];

	constructor(objectData: ObjectData) {
		makeAutoObservable(this);

		if (!objectData) throw new Error('Object data is required.');

		this.id = objectData.id;
		this.objectType = objectData.entityTypeId;
		this.parentObjectId = objectData.parentEntityId ?? undefined;
		this.parentObjectType = objectData.parentEntityTypeId ?? undefined;
		this.fields = objectData.fields?.map((x) => new ObjectField(x)) ?? [];
		this.collections =
			objectData.collections?.map((x) => new Collection(x)) ?? [];
	}

	public getDisplayName(): string {
		return '';
	}
}
