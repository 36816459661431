import { History } from 'history';
import { flow, makeAutoObservable } from 'mobx';
import {
	DataLists,
	GetDataListsRequest,
} from 'modules/clients/customer-api/src/api/referenceData';
import { generatePath } from 'react-router-dom';
import { paths } from 'shared/constants/paths';
import { Option } from 'shared/types/referenceDataTypes';
import { convertDate } from 'shared/utilities/dateUtilities';
import { parseError } from 'utilities/errors/errorUtils';
import {
	EntityType,
	SubObjectType,
	entityTypeToEntityTypeId,
} from '../../entity4Constants';
import { DocumentListItem } from '../components/documentListView';
import { CommonDocumentStore } from '../documentStore';
import { Document } from '../models/document';
import { DeleteDocumentViewModel } from './deleteDocumentViewModel';
import { UploadDocumentViewModel } from './uploadDocumentViewModel';

export const DOCUMENTTYPE_LISTID = '8b0e5955-5744-491a-a019-1c7be1284ef4';

export default class DocumentListViewModel {
	private _error?: string = undefined;
	private _loading = true;

	private _objectType: EntityType;
	private _objectId: string;
	private _subobjectType?: SubObjectType;
	private _subobjectId?: string;
	private _history: History;

	private _documents: Document[] = [];
	private _documentTypes: Option[] = [];

	public deleteDocumentViewModel: DeleteDocumentViewModel;
	public uploadDocumentViewModel: UploadDocumentViewModel;

	private _fetchReferenceData: (
		request: GetDataListsRequest,
	) => Promise<DataLists | undefined>;

	constructor(
		objectType: EntityType,
		objectId: string,
		history: History,
		fetchReferenceData: (
			request: GetDataListsRequest,
		) => Promise<DataLists | undefined>,
		subObjectId?: string,
		subObjectType?: SubObjectType,
	) {
		makeAutoObservable(this);

		this._objectType = objectType;
		this._objectId = objectId;
		this._history = history;
		this._fetchReferenceData = fetchReferenceData;
		this._subobjectType = subObjectType;
		this._subobjectId = subObjectId;

		this.deleteDocumentViewModel = new DeleteDocumentViewModel(() =>
			this.load(),
		);
		this.uploadDocumentViewModel = new UploadDocumentViewModel(
			() => this.load(),
			this._history,
		);
	}

	public load = flow(function* (this: DocumentListViewModel) {
		try {
			this._loading = true;
			this._error = undefined;
			const params =
				this._subobjectType !== SubObjectType.Subaccount
					? {
							entityType: this._objectType,
							entityId: this._objectId,
							subObjectType: this._subobjectType,
							subObjectId: this._subobjectId,
					  }
					: {
							entityType: this._subobjectType,
							entityId: this._subobjectId,
							subObjectType: undefined,
							subObjectId: undefined,
					  };

			const documentListResult: Document[] =
				yield CommonDocumentStore.getDocumentsByEntityId(
					params.entityType as EntityType,
					params.entityId!,
					params.subObjectId,
					params.subObjectType,
				);

			const referenceResponse = yield this._fetchReferenceData({
				objectTypeId: entityTypeToEntityTypeId.get(this._objectType)!,
				referenceListNames: [],
				optionListIds: [DOCUMENTTYPE_LISTID],
			});
			if (referenceResponse) {
				this._documentTypes =
					(referenceResponse.optionLists[DOCUMENTTYPE_LISTID]
						.options as Option[]) ?? [];
			}

			this._documents = documentListResult ?? [];
		} catch (error) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});

	public navigateToDocument = (documentId: string) => {
		this._history.push(
			generatePath(paths.entity4.objects.object.documents.document.href, {
				objectType: this._objectType as string,
				objectId: this._objectId as string,
				documentId: documentId,
			}),
		);
	};

	public isLoading = (): boolean => this._loading;
	public getError = (): string | undefined => this._error;
	public getObjectType = (): EntityType => this._objectType;
	public getObjectId = (): string => this._objectId;
	public getSubobjectType = (): SubObjectType | undefined =>
		this._subobjectType;
	public getSubobjectId = (): string | undefined => this._subobjectId;

	public getTitle = (): string =>
		this._subobjectType === undefined ? 'Documents' : 'Associated Documents';
	public getDocuments = (): DocumentListItem[] =>
		this._documents.map((document) => ({
			menu: document,
			id: document.id,
			name: document.name,
			type: this._documentTypes.find(
				(type) => type.id === document.documentTypeId,
			)?.displayName,
			subObjectDisplayName: document.subObjectDisplayName,
			status: document.status,
			expirationDate: convertDate(document.expirationDate) ?? undefined,
			uploadedByName: document.uploadedByName,
			uploadedDate: convertDate(document.uploadedDate) ?? undefined,
		}));
	public get types(): string[] {
		return [...new Set(this._documentTypes.map((x) => x.displayName))];
	}
}
