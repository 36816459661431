import { Box, Button, Collapse, Grid, Tooltip } from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { useApplicationConfiguration } from 'shared/hooks/useApplicationConfigurations';
import { useUser } from 'shared/hooks/useUser';
import { EntityFieldBase } from '../../../../entities/objects/fields/field';
import { InputAdornmentWithModal } from '../../../components/molecules/inputAdornmentWithModal';
import { Field } from '../../fieldTypes';
import { FieldViews } from '../fieldViewTypes';
import { FieldError } from './fieldError';
import {
	FieldViewRejectButton,
	IFieldViewRejectButtonProps,
} from './fieldViewRejectButton';
import { FieldViewRejection } from './fieldViewRejection';
import { FieldViewStatusChip } from './fieldViewStatusChip';

export type FieldViewFieldLayoutProps = {
	entityId: string;
	fieldDefinition: Field;
	fieldData?: EntityFieldBase;
	approvedValueToDisplay?: string;
	value: any;
	approveFieldDisplay?: JSX.Element;
	rejectionModalProps?: Partial<IFieldViewRejectButtonProps>;
	excludeDivider?: boolean;
	descriptionSpacing?: number;
};

export const FieldViewFieldLayout: FC<FieldViewFieldLayoutProps> = observer(
	({
		entityId,
		fieldDefinition,
		fieldData,
		approvedValueToDisplay,
		value,
		approveFieldDisplay,
		...props
	}) => {
		const { viewType } = useProfileView();
		const { id: userId } = useUser();
		const { approvalConfiguration } = useApplicationConfiguration();

		const isDefaultView = useMemo(
			() =>
				fieldDefinition.requiresApproval &&
				!approveFieldDisplay &&
				viewType === FieldViews.default,
			[approveFieldDisplay, fieldDefinition.requiresApproval, viewType],
		);

		const regularExpression = fieldDefinition.validationCriterion
			?.validationRegex
			? new RegExp(fieldDefinition.validationCriterion.validationRegex)
			: undefined;

		const shouldDisplayWarning = () =>
			fieldData?.id &&
			fieldData.approvedValue &&
			fieldDefinition.validationCriterion &&
			regularExpression &&
			!regularExpression.test(fieldData.approvedValue);

		const disableApproveRejectButton = fieldData?.updatedBy() === userId;

		return (
			<Box>
				{approvalConfiguration.isActive && viewType !== FieldViews.default ? (
					<FieldViewStatusChip
						fieldDefinition={fieldDefinition}
						fieldData={fieldData}
					/>
				) : null}

				{approvalConfiguration.isActive && viewType !== FieldViews.default ? (
					<FieldViewRejection fieldData={fieldData} />
				) : null}

				<Grid container item sm={12} alignItems="flex-start" spacing={1}>
					<Grid container item sm={12} spacing={1}>
						{approvalConfiguration.isActive &&
						fieldDefinition.requiresApproval ? (
							<Grid container item xs={12}>
								{approveFieldDisplay ? (
									approveFieldDisplay
								) : viewType === FieldViews.default ||
								  approvalConfiguration.isActive ? (
									<Grid item xs={12}>
										<T4TextFieldV2
											variant={
												viewType === FieldViews.default
													? 'standard'
													: 'outlined'
											}
											label={`${
												viewType === FieldViews.default
													? fieldDefinition.name
													: 'Approved ' + fieldDefinition.name
											}`}
											InputLabelProps={{ shrink: true }}
											value={approvedValueToDisplay ?? ''}
											InputProps={{
												readOnly: true,
												...(isDefaultView
													? {
															startAdornment: (
																<InputAdornmentWithModal
																	title={fieldDefinition.name}
																	description={fieldDefinition.description}
																	iconButtonProps={{
																		...(viewType === FieldViews.default
																			? {
																					sx: {
																						marginLeft: '-5px',
																					},
																			  }
																			: {}),
																	}}
																/>
															),
															sx: {
																...(viewType === FieldViews.default
																	? {
																			color: 'transparent',
																			borderColor: 'transparent',
																			borderBottomColor: 'transparent',
																			'&:before, :after, :hover': {
																				borderBottomColor:
																					'transparent !important',
																			},
																			padding: '0rem !important',
																	  }
																	: {}),
															},
													  }
													: {}),
											}}
										/>
										<FieldError
											error={fieldData?.loadingErrorMessage}
											warning={
												shouldDisplayWarning()
													? fieldDefinition.validationCriterion
															?.errorDescription
													: undefined
											}
										/>
									</Grid>
								) : null}
							</Grid>
						) : null}
						<Grid item xs={12}>
							<Collapse
								in={
									(!approvalConfiguration.isActive &&
										fieldDefinition.requiresApproval) ||
									viewType !== FieldViews.default ||
									!fieldDefinition.requiresApproval
								}
							>
								{props.children}
							</Collapse>
						</Grid>

						{fieldData?.isPendingApproval && viewType === FieldViews.review && (
							<Grid container item sm={12} spacing={2} alignItems="center">
								<Grid container item sm={12} spacing={2}>
									<Tooltip
										title={
											disableApproveRejectButton
												? 'You cannot approve your own changes'
												: undefined
										}
									>
										<Grid item sm={3}>
											<Button
												fullWidth
												variant="contained"
												size="small"
												color="primary"
												onClick={() => fieldData?.approve()}
												disabled={disableApproveRejectButton}
											>
												Approve
											</Button>
										</Grid>
									</Tooltip>
									<Tooltip
										title={
											disableApproveRejectButton
												? 'You cannot reject your own changes'
												: undefined
										}
									>
										<Grid item sm={3}>
											<FieldViewRejectButton
												entityId={entityId}
												fieldDefinition={fieldDefinition}
												fieldData={fieldData}
												{...props.rejectionModalProps}
											/>
										</Grid>
									</Tooltip>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Box>
		);
	},
);
