import { FormLabel, FormLabelProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type VisualizationOptionLabelProps = FormLabelProps;

export const VisualizationOptionLabel: FC<VisualizationOptionLabelProps> =
	observer(({ children, ...rest }) => (
		<FormLabel
			{...rest}
			sx={{
				...rest?.sx,
				fontWeight: 'bold',
				fontSize: '0.875rem',
			}}
		>
			{children}
		</FormLabel>
	));
