import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { T4View } from 'shared/components/entity4/t4View';
import { ObjectTaxIdPathParams } from 'shared/constants/paths';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { TaxId } from '../models/taxId';
import { TaxIdView } from '../views/taxIdView';

export const ObjectTaxIdPage: FC = observer(() => {
	const handleError = useErrorHandler();
	const { objectType, objectId, taxIdId } = useParams<ObjectTaxIdPathParams>();
	const { updateLastAutoSave, updateSubObjectName } = useHeader();

	const [taxId] = useState(
		new TaxId(objectId, objectType, taxIdId, updateLastAutoSave),
	);

	useEffect(() => {
		updateSubObjectName('');
		taxId
			.load()
			.catch((error) => handleError(error))
			.finally(() => {
				if (taxId.taxIdNumber) {
					updateSubObjectName(taxId.taxIdNumber);
				}
			});
	}, [handleError, taxId, updateSubObjectName]);

	return (
		<T4View>
			<TaxIdView taxId={taxId} />
		</T4View>
	);
});
