import { GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { CashFlowClass } from 'features/cash4/categories/categoriesViewModel';
import { T4DataResponse, T4Response } from 'modules/clients/types';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import {
	GLCode,
	OperatorMatchOperations,
	RulePredicate,
	TransactionRule,
	TransactionRuleListItem,
} from '../models';
import {
	mapRuleToRuleListItem,
	mapRuleToRuleRequest,
	sortRules,
} from '../utilities';

export const fetchRules = async (): Promise<TransactionRuleListItem[]> => {
	try {
		const response: TransactionRuleListItem[] = await customerApi
			.get<T4Response<TransactionRule[]>>('/api/v1.0/cash4/transactionRules')
			.then((res) => res.data.data)
			.then((rules) => rules.map((value) => mapRuleToRuleListItem(value)))
			.then((mappedData) => sortRules(mappedData));

		return response as TransactionRuleListItem[];
	} catch (error) {
		if (axios.isAxiosError(error)) {
			const serverError = error as AxiosError<ApiResponse<any>>;

			if (serverError && serverError.response) {
				throw new Error(serverError.response.data.error);
			}
		}

		throw error;
	}
};

export const fetchRule = async (ruleId: string) => {
	try {
		const response = await customerApi
			.get<T4Response<TransactionRule>>(
				`/api/v1.0/cash4/transactionRules/${ruleId}`,
			)
			.then((res) => res.data.data)
			.then((rule) => mapRuleToRuleListItem(rule));
		return response as TransactionRuleListItem;
	} catch (error) {
		throw error;
	}
};

const createRule = async (rule: TransactionRule) => {
	try {
		const ruleRequest = mapRuleToRuleRequest(rule);
		const response = await customerApi.post<ApiResponse<TransactionRule>>(
			'/api/v1.0/cash4/transactionRules',
			ruleRequest,
		);
		if (response.data.error) {
			throw new Error(response.data.error);
		}
		return response;
	} catch (error) {
		throw error;
	}
};

export const useCreateRule = (callbackFunction: () => void) => {
	const queryClient = useQueryClient();
	const mutation = useMutation((rule: TransactionRule) => createRule(rule), {
		onSuccess: () => {
			queryClient.refetchQueries(['rules']);
			enqueueSnackbar({
				message: 'Rule created successfully',
				variant: 'success',
			});
			callbackFunction();
		},
		onError: (error) => {
			enqueueSnackbar({
				message: 'Rule creation failed',
				variant: 'error',
			});
		},
	});

	return mutation;
};

const updateRule = async (rule: TransactionRule) => {
	try {
		const ruleRequest = mapRuleToRuleRequest(rule);
		const response = await customerApi.put<ApiResponse<TransactionRule>>(
			`/api/v1.0/cash4/transactionRules/${rule.id}`,
			ruleRequest,
		);
		if (response.data.error) {
			throw new Error(response.data.error);
		}
		return response;
	} catch (error) {
		throw error;
	}
};

export const useUpdateRule = (callbackFunction: () => void) => {
	const queryClient = useQueryClient();
	const mutation = useMutation((rule: TransactionRule) => updateRule(rule), {
		onSuccess: () => {
			queryClient.refetchQueries(['rules']);
			enqueueSnackbar({
				message: 'Rule updated successfully',
				variant: 'success',
			});
			callbackFunction();
		},
		onError: (error) => {
			enqueueSnackbar({
				message: 'Rule update failed',
				variant: 'error',
			});
		},
	});

	return mutation;
};

const deleteRule = async (ruleId: string) => {
	try {
		const response = await customerApi.delete<ApiResponse<void>>(
			`/api/v1.0/cash4/transactionRules/${ruleId}`,
		);
		if (response.data.error) {
			throw new Error(response.data.error);
		}
		return response;
	} catch (error) {
		throw error;
	}
};

export const useDeleteRule = (id: string) => {
	const queryClient = useQueryClient();
	const mutation = useMutation(
		() => {
			if (!id) throw new Error('Rule ID is undefined');
			return deleteRule(id);
		},
		{
			onSuccess: () => {
				queryClient.refetchQueries(['rules']);
				enqueueSnackbar({
					message: 'Rule deleted successfully',
					variant: 'success',
				});
			},
			onError: (error) => {
				enqueueSnackbar({
					message: 'Rule deletion failed',
					variant: 'error',
				});
			},
		},
	);

	return mutation;
};

const runRules = async () => {
	try {
		const response = await customerApi.post<ApiResponse<void>>(
			'/api/v1.0/cash4/transactionRules/runRules',
		);
		if (response.data.error) {
			throw new Error(response.data.error);
		}
		return response;
	} catch (error) {
		throw error;
	}
};

export const useRunRules = () => {
	const mutation = useMutation(runRules, {
		onSuccess: () => {
			enqueueSnackbar({
				message: 'Success! Rules have started running',
				variant: 'success',
			});
		},
		onError: (error) => {
			enqueueSnackbar({
				message: 'Kickoff Run Rules Failed',
				variant: 'error',
			});
		},
	});

	return mutation;
};

export const useUpdateRulePriority = () => {
	const [isLoadingPriorities, setIsLoadingPriorities] = useState(false);
	const queryClient = useQueryClient();

	const updateRulePriority = async (params: GridRowOrderChangeParams) => {
		setIsLoadingPriorities(true);
		let rule = (params.row as TransactionRuleListItem).menu;
		const ruleId = rule.id;
		let ruleRequest = mapRuleToRuleRequest(rule);
		ruleRequest.priority = params.targetIndex + 1;

		try {
			if (rule.priority === ruleRequest.priority) {
				setIsLoadingPriorities(false);
				return;
			}

			const response = await customerApi.put<ApiResponse<TransactionRule[]>>(
				`/api/v1.0/cash4/transactionRules/${ruleId}`,
				ruleRequest,
			);

			if (response.data.error) throw new Error(response.data.error);

			enqueueSnackbar('Rule priority updated successfully', {
				variant: 'success',
			});
			queryClient.refetchQueries(['rules']);

			return response.data.value;
		} catch (error) {
			enqueueSnackbar('Failed to update rule priority', { variant: 'error' });
		} finally {
			setIsLoadingPriorities(false);
		}
	};

	return { updateRulePriority, isLoadingPriorities };
};

export const fetchCategoriesForRules = async () => {
	try {
		const classesResponse = await customerApi.get<ApiResponse<CashFlowClass[]>>(
			'/categories?includeTransactionCount=false',
		);
		if (classesResponse.data.error) throw classesResponse.data.error;
		return classesResponse.data.value;
	} catch (error) {
		throw error;
	}
};

export const fetchGlCodes = async () => {
	try {
		const glCodesResponse = await customerApi.get<T4DataResponse<GLCode[]>>(
			'/api/v1.0/cash4/transactionRules/glCodes',
		);
		if (glCodesResponse.data.error) throw glCodesResponse.data.error;
		return glCodesResponse.data.data;
	} catch (error) {
		throw error;
	}
};

export const fetchRulePredicates = async () => {
	try {
		const response = await customerApi.get<T4DataResponse<RulePredicate[]>>(
			'/api/v1.0/cash4/transactionRules/rulePredicates',
		);
		if (response.data.error) throw response.data.error;
		return response.data.data;
	} catch (error) {
		throw error;
	}
};

export const fetchOperatorMatchOperations = async () => {
	try {
		const response = await customerApi.get<
			T4DataResponse<OperatorMatchOperations[]>
		>('/api/v1.0/cash4/transactionRules/operatorMatchOperations');
		if (response.data.error) throw response.data.error;
		return response.data.data;
	} catch (error) {
		throw error;
	}
};
