import { Collapse, Box, Alert, AlertTitle } from '@mui/material';
import { EntityFieldBase } from '../../../../entities/objects/fields/field';

interface IFieldViewRejectionProps {
	fieldData?: EntityFieldBase;
}

export const FieldViewRejection: React.FC<IFieldViewRejectionProps> = ({
	fieldData,
}) => {
	return (
		<Collapse in={fieldData?.isRejected}>
			<Box marginBottom={2}>
				<Alert severity="error">
					<AlertTitle>Rejected</AlertTitle>
					{fieldData?.rejectionComment}
				</Alert>
			</Box>
		</Collapse>
	);
};
