import { downloadBlob } from 'features/entity4/documents/documentUtils';
import { T4AdornmentType } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { OptionDto } from 'features/entity4/shared/fieldSets/FieldRepository';
import { flow, makeAutoObservable } from 'mobx';
import { shouldSaveDate } from 'shared/utilities/dateUtilities';
import { Errors, parseErrors } from 'utilities/errors/errorUtils';
import { Document } from '../../models/document';
import {
	DocumentView,
	GlobalDocumentsRepository,
	UpdateDocumentParams,
} from '../../shared/globalDocumentsRepository';

export const NETWORK_ERROR = 'There was an error saving this field. Please contact our Customer Success team at support@treasury4.com.';

export class GlobalDocumentViewModel {
	private _errors: string[] = [];
	private _loading: boolean = true;
	private _documentTypes: OptionDto[] = [];
	private _title?: string;
	public document?: Document;

	private _id: string;
	private _repository: GlobalDocumentsRepository;
	private _updateLastAutoSave: (autoSaveDate?: Date) => void;

	public nameErrors: string[] = [];
	public externalUrlErrors: string[] = [];
	public typeErrors: string[] = [];
	public descriptionErrors: string[] = [];
	public statusErrors: string[] = [];
	public signedDateErrors: string[] = [];
	public expirationDateErrors: string[] = [];
	public effectiveFromErrors: string[] = [];
	public effectiveToErrors: string[] = [];

	public nameAdornmentState: T4AdornmentType = 'info';
	public typeAdornmentState: T4AdornmentType = 'info';
	public descriptionAdornmentState: T4AdornmentType = 'info';
	public statusAdornmentState: T4AdornmentType = 'info';
	public signedDateAdornmentState: T4AdornmentType = 'info';
	public expirationDateAdornmentState: T4AdornmentType = 'info';
	public effectiveFromAdornmentState: T4AdornmentType = 'info';
	public effectiveToAdornmentState: T4AdornmentType = 'info';

	constructor(
		id: string,
		repository: GlobalDocumentsRepository,
		updateLastAutoSave: (autoSaveDate?: Date) => void,
	) {
		makeAutoObservable(this);

		this._id = id;
		this._repository = repository;
		this._updateLastAutoSave = updateLastAutoSave;
	}

	public load = flow(function* (this: GlobalDocumentViewModel) {
		this._errors = [];
		this._loading = true;

		try {
			const { documentTypes, title, document }: DocumentView =
				yield this._repository.getDocumentView(this._id);

			this._documentTypes = documentTypes;
			this._title = title;
			this.document = new Document(document, this._repository);
		} catch (error) {
			const errorObject = parseErrors(error);
			this._errors = Object.keys(errorObject).flatMap(
				(key) => errorObject[key],
			);
		} finally {
			this._loading = false;
			this._updateLastAutoSave(this.document?.getUpdatedDate());
		}
	});

	public isLoading(): boolean {
		return this._loading;
	}

	public getErrors(): string[] {
		return this._errors;
	}

	public getDocumentTypes(): OptionDto[] {
		return this._documentTypes.slice();
	}

	public getTitle(): string | undefined {
		return this._title;
	}

	public updateName = flow(function* (this: GlobalDocumentViewModel) {
		if (this.document) {
			this.nameAdornmentState = 'info';
			this.nameErrors = [];

			if (this.document.getData().name !== this.document.getName()) {
				try {
					this.nameAdornmentState = 'loading';

					yield this.document
						.update()
						.finally(() => this._updateLastAutoSave());

					this.nameAdornmentState = 'success';
				} catch (errors: any) {
					this.nameAdornmentState = 'info';
					this.nameErrors = (errors as Errors<UpdateDocumentParams>)?.[
						'name'
					] ?? [NETWORK_ERROR];
				}
			}
		}
	});

	public updateExternalUrl = flow(function* (this: GlobalDocumentViewModel) {
		if (this.document) {
			this.externalUrlErrors = [];

			if (
				this.document.getData().externalUrl !== this.document.getExternalUrl()
			) {
				try {
					yield this.document
						.update()
						.finally(() => this._updateLastAutoSave());
				} catch (errors) {
					this.externalUrlErrors = (errors as Errors<UpdateDocumentParams>)?.[
						'externalUrl'
					] ?? [NETWORK_ERROR];
				}
			}
		}
	});

	public updateType = flow(function* (
		this: GlobalDocumentViewModel,
		type: OptionDto | null,
	) {
		if (this.document) {
			this.typeAdornmentState = 'info';
			this.typeErrors = [];
			this.document.setType(type);

			if (this.document.getData().type?.id !== this.document.getType()?.id) {
				try {
					this.typeAdornmentState = 'loading';

					yield this.document
						.update()
						.finally(() => this._updateLastAutoSave());

					this.typeAdornmentState = 'success';
				} catch (errors) {
					this.typeAdornmentState = 'info';
					this.typeErrors = (errors as Errors<UpdateDocumentParams>)?.[
						'type'
					] ?? [NETWORK_ERROR];
				}
			}
		}
	});

	public updateDescription = flow(function* (this: GlobalDocumentViewModel) {
		if (this.document) {
			this.descriptionAdornmentState = 'info';
			this.descriptionErrors = [];

			if (
				this.document.getData().description !== this.document.getDescription()
			) {
				try {
					this.descriptionAdornmentState = 'loading';

					yield this.document
						.update()
						.finally(() => this._updateLastAutoSave());

					this.descriptionAdornmentState = 'success';
				} catch (errors) {
					this.descriptionAdornmentState = 'info';
					this.descriptionErrors = (errors as Errors<UpdateDocumentParams>)?.[
						'description'
					] ?? [NETWORK_ERROR];
				}
			}
		}
	});

	public updateStatus = flow(function* (
		this: GlobalDocumentViewModel,
		status: string | null,
	) {
		if (this.document) {
			this.statusAdornmentState = 'info';
			this.statusErrors = [];
			this.document.setStatus(status);

			if (this.document.getData().status !== this.document.getStatus()) {
				try {
					this.statusAdornmentState = 'loading';

					yield this.document
						.update()
						.finally(() => this._updateLastAutoSave());

					this.statusAdornmentState = 'success';
				} catch (errors) {
					this.statusAdornmentState = 'info';
					this.statusErrors = (errors as Errors<UpdateDocumentParams>)?.[
						'status'
					] ?? [NETWORK_ERROR];
				}
			}
		}
	});

	public udpateSignedDate = flow(function* (this: GlobalDocumentViewModel) {
		if (this.document) {
			this.signedDateAdornmentState = 'info';
			this.signedDateErrors = [];

			if (
				shouldSaveDate(
					this.document.getData().signedDate,
					this.document.getSignedDate(),
				)
			) {
				try {
					this.signedDateAdornmentState = 'loading';

					yield this.document
						.update()
						.finally(() => this._updateLastAutoSave());

					this.signedDateAdornmentState = 'success';
				} catch (errors) {
					this.signedDateAdornmentState = 'info';
					this.signedDateErrors = (errors as Errors<UpdateDocumentParams>)?.[
						'signedDate'
					] ?? [NETWORK_ERROR];
				}
			} else {
				const signedDate = this.document.getSignedDate();
				if (signedDate && !signedDate.isValid()) {
					this.signedDateErrors.push('Date is in an invalid format.');
				}
			}
		}
	});

	public udpateExpirationDate = flow(function* (this: GlobalDocumentViewModel) {
		if (this.document) {
			this.expirationDateAdornmentState = 'info';
			this.expirationDateErrors = [];

			if (
				shouldSaveDate(
					this.document.getData().expirationDate,
					this.document.getExpirationDate(),
				)
			) {
				try {
					this.expirationDateAdornmentState = 'loading';

					yield this.document
						.update()
						.finally(() => this._updateLastAutoSave());

					this.expirationDateAdornmentState = 'success';
				} catch (errors) {
					this.expirationDateAdornmentState = 'info';
					this.expirationDateErrors = (
						errors as Errors<UpdateDocumentParams>
					)?.['expirationDate'] ?? [NETWORK_ERROR];
				}
			} else {
				const expirationDate = this.document.getExpirationDate();
				if (expirationDate && !expirationDate.isValid()) {
					this.expirationDateErrors.push('Date is in an invalid format.');
				}
			}
		}
	});

	public udpateEffectiveFrom = flow(function* (this: GlobalDocumentViewModel) {
		if (this.document) {
			this.effectiveFromAdornmentState = 'info';
			this.effectiveFromErrors = [];
			const effectiveFrom = this.document.getEffectiveFromDate();
			const effectiveTo = this.document.getEffectiveToDate();

			if (
				effectiveFrom === null ||
				effectiveTo === null ||
				effectiveFrom.isBefore(effectiveTo)
			) {
				if (
					shouldSaveDate(this.document.getData().effectiveFrom, effectiveFrom)
				) {
					try {
						this.effectiveFromAdornmentState = 'loading';

						yield this.document
							.update()
							.finally(() => this._updateLastAutoSave());

						this.effectiveFromAdornmentState = 'success';
					} catch (errors) {
						this.effectiveFromAdornmentState = 'info';
						this.effectiveFromErrors = (
							errors as Errors<UpdateDocumentParams>
						)?.['effectiveFrom'] ?? [NETWORK_ERROR];
					}
				}
			} else {
				if (effectiveFrom && !effectiveFrom.isValid()) {
					this.effectiveFromErrors.push('Date is in an invalid format.');
				}
				if (
					effectiveFrom &&
					effectiveTo &&
					effectiveFrom.isSameOrAfter(effectiveTo)
				) {
					this.effectiveFromErrors.push(
						"Please make sure 'Effective From' is before 'Effective To'.",
					);
				}
			}
		}
	});

	public udpateEffectiveTo = flow(function* (this: GlobalDocumentViewModel) {
		if (this.document) {
			this.effectiveToAdornmentState = 'info';
			this.effectiveToErrors = [];
			const effectiveFrom = this.document.getEffectiveFromDate();
			const effectiveTo = this.document.getEffectiveToDate();

			if (
				effectiveFrom === null ||
				effectiveTo === null ||
				effectiveTo.isAfter(effectiveFrom)
			) {
				if (shouldSaveDate(this.document.getData().effectiveTo, effectiveTo)) {
					try {
						this.effectiveToAdornmentState = 'loading';

						yield this.document
							.update()
							.finally(() => this._updateLastAutoSave());

						this.effectiveToAdornmentState = 'success';
					} catch (errors) {
						this.effectiveToAdornmentState = 'info';
						this.effectiveToErrors = (errors as Errors<UpdateDocumentParams>)?.[
							'effectiveTo'
						] ?? [NETWORK_ERROR];
					}
				}
			} else {
				if (effectiveTo && !effectiveTo.isValid()) {
					this.effectiveToErrors.push('Date is in an invalid format.');
				}
				if (
					effectiveFrom &&
					effectiveTo &&
					effectiveTo.isSameOrBefore(effectiveFrom)
				) {
					this.effectiveToErrors.push(
						"Please make sure 'Effective To' is after 'Effective From'.",
					);
				}
			}
		}
	});

	public onViewFileClick = flow(function* (this: GlobalDocumentViewModel) {
		const externalUrl = this.document?.getExternalUrl();
		if (externalUrl) {
			window.open(externalUrl);
		} else {
			const contents = yield this._repository.getFile(this._id);

			downloadBlob(
				this.document!.getName() ?? 'document',
				this.document!.getFileType(),
				contents,
			);
		}
	});
}
