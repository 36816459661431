import { Divider, Grid, Typography } from '@mui/material';
import { User } from 'modules/clients/apiGateway/payments4';
import {
	Payment,
	PaymentApprovalLevelStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import moment, { Moment } from 'moment';
import { FC, useMemo } from 'react';
import { dateTimeReadFormat, formatDate } from 'shared/utilities/dateUtilities';

export const PaymentHistoryTab: FC<{
	tabIndex: number;
	payment: Payment | undefined;
}> = ({ tabIndex, payment }) => {
	interface PaymentHistory {
		date: Moment | null;
		status: string | null;
		user: User | null;
		level?: number;
	}

	const history = useMemo(() => {
		if (payment === undefined) return [];
		const statusHistoryArr: PaymentHistory[] = payment?.statusHistory.map(
			(history) => ({
				date: moment(history.createdOn),
				status: history.paymentStatusType,
				user: history.createdBy,
			}),
		);

		const levelStateArr: PaymentHistory[] =
			payment?.approvalState?.paymentApprovalLevelStates
				.filter(
					(history) =>
						history.status !==
						PaymentApprovalLevelStatusTypes[
							PaymentApprovalLevelStatusTypes.Pending
						],
				)
				.map((history) => {
					const historyInfo =
						history.status ===
						PaymentApprovalLevelStatusTypes[
							PaymentApprovalLevelStatusTypes.Approved
						]
							? { date: history.approvedOn, user: history.approvedBy }
							: { date: history.rejectedOn, user: history.rejectedBy };

					return {
						date: moment(historyInfo.date),
						status: history.status,
						user: historyInfo.user,
						level: history.level,
					};
				}) ?? [];

		return statusHistoryArr.concat(levelStateArr).sort((a, b) => {
			if (a.date !== null && b.date !== null) return b.date.diff(a.date);
			if (a.date === null && b.date !== null) return -1;
			if (b.date === null && a.date !== null) return 1;
			return 0;
		});
	}, [payment]);

	return tabIndex === 2 ? (
		<div role="tabpanel">
			<Grid container direction="column" sx={{ gap: 2 }}>
				{history.map((value, index) => {
					return (
						<Grid
							key={index}
							item
							xs={12}
							sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
						>
							<Typography variant="body1">
								{formatDate(value.date, dateTimeReadFormat)?.toUpperCase()}
							</Typography>
							<Typography variant="body1" fontWeight={500}>
								{`${
									value.level !== undefined
										? 'Approval Level ' + value.level + ': '
										: ''
								}${value.status}`}
							</Typography>
							<Typography variant="body1">
								{value.user?.email ?? value.user?.userId}
							</Typography>

							{index !== history.length - 1 && (
								<Divider sx={{ paddingTop: '0.5rem' }} />
							)}
						</Grid>
					);
				})}
			</Grid>
		</div>
	) : null;
};
