import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { USER_PREFERENCES_FIELD_OPTIONS } from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { CreateRelationshipDrawer } from '../components/createRelationshipDrawer/createRelationshipDrawer';
import { DeleteRelationshipModal } from '../components/deleteRelationshipModal';
import { EditRelationshipDrawer } from '../components/editRelationshipDrawer/editRelationshipDrawer';
import { RelationshipsLayout } from '../components/relationshipsLayout';
import { usePartnerRelationshipsViewModel } from './partnerRelationshipsViewModel';

export const PartnerRelationships: FC = observer(() => {
	const viewModel = usePartnerRelationshipsViewModel();

	return (
		<RelationshipsLayout
			createRelationshipButton={
				<CreateRelationshipDrawer
					viewModel={viewModel.getCreateViewModel()!}
					readOnly={viewModel.loading()}
					navigateToTab={() => {}}
				/>
			}
			tabDetails={[
				{
					tab: 'Accounts Owned',
					component: (
						<UserPreferencesDataGrid
							stonlyId="partners"
							tableKey="partnerRelationships"
							columns={viewModel.getAccountColumns()}
							rows={viewModel.getAccountRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Account Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
			]}
		>
			<EditRelationshipDrawer
				viewModel={viewModel.editRelationshipDrawerViewModel}
			/>
			<DeleteRelationshipModal
				viewModel={viewModel.deleteRelationshipViewModel}
			/>
		</RelationshipsLayout>
	);
});
