import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import ModalBase from 'shared/components/modalBase';

export const VisualizationHelpModal: FC = observer(() => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	return (
		<>
			<IconButton
				onClick={() => {
					setIsModalOpen(true);
				}}
			>
				<HelpOutlineOutlinedIcon />
			</IconButton>
			<ModalBase
				title="Keyboard / Mouse commands"
				open={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
			>
				<p>
					<strong>Shift:</strong> Select node and ancestors
				</p>
				<p>
					<strong>Alt:</strong> Select node and direct parent(s)
				</p>
				<p>
					<strong>Shift+Alt:</strong> Select node and descendants
				</p>
				<p>
					<strong>Double-click:</strong> Restrict graph to selected node(s).
					Click Reset View to show all nodes.
				</p>
				<p>
					<strong>Ctrl-click:</strong> Select a node. Use with double-click to
					filter the graph to selected nodes. Example: Ctrl-click multiple
					nodes, then double-click the last node to filter the graph. You can
					also use Ctrl with a key combination to add to a predefined filter.
					For example Ctrl+Shift will select the node and its ancestors. You can
					then click on any other node to add to that filter.
				</p>
			</ModalBase>
		</>
	);
});
