import React from 'react';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { IconButton, Tooltip } from '@mui/material';
import { useDarkMode } from 'shared/providers/contexts/darkModeContext';

const DarkModeButton: React.FC = () => {
	const { darkMode, toggleDarkMode } = useDarkMode();

	const Icon = darkMode ? LightModeIcon : DarkModeIcon;
	const tooltipTitle = darkMode ? 'Turn on Light Mode' : 'Turn on Dark Mode';

	return (
		<Tooltip title={tooltipTitle} aria-label="Treasury4 Dark Mode">
			<IconButton size="small" color="primary" onClick={toggleDarkMode}>
				<Icon sx={{ fill: '#fff' }} />
			</IconButton>
		</Tooltip>
	);
};

export default DarkModeButton;
