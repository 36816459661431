import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { stonlyData } from 'stonly/functions';

/* eslint-disable mobx/missing-observer */
interface E4AccountLinkProps {
	e4AccountId?: string | null;
	e4AccountName?: string | null;
	stonlyPrefix?: string;
}

export const E4AccountLink = ({
	e4AccountId,
	e4AccountName,
	stonlyPrefix,
}: E4AccountLinkProps) => {
	const stonlyId = stonlyPrefix
		? stonlyData({
				id: `${stonlyPrefix}-link-to-e4-account`,
		  })
		: {};

	return (
		<Link
			component={RouterLink}
			to={`../../entity4/accounts/${e4AccountId}/information`}
			target="_blank"
			rel="noreferrer"
			sx={{
				color: 'white',
				textDecoration: 'underline',
			}}
			{...stonlyId}
		>
			{e4AccountName}
		</Link>
	);
};
