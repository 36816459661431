import { debounce } from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { T4MultiSelectAutocomplete } from 'shared/components/autocompletes/t4MultiselectAutocomplete';
import { ObjectPathParams } from 'shared/constants/paths';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { stonlyData } from '../../../../stonly/functions';
import { useLegalEntityGroupsSelector } from './hooks/uselegalEntityGroupsSelector';

type LegalEntityGroupOption = {
	id: string;
	collectionName: string;
	name: string;
};

export type LegalEntityGroupsSelectorProps = {
	isReadOnly?: boolean;
};

export const LegalEntityGroupsSelector: FC<LegalEntityGroupsSelectorProps> =
	observer(({ isReadOnly }) => {
		const { objectId } = useParams<ObjectPathParams>();
		const { viewType } = useProfileView();
		const { legalEntityGroupCollections } = useLegalEntityGroups();
		const {
			isLoading,
			selectedLegalEntityGroupIds,
			setSelectedLegalEntityGroupIds,
			onBlur,
		} = useLegalEntityGroupsSelector(objectId);

		const legalEntityGroups = useMemo<LegalEntityGroupOption[]>(
			() => [
				...legalEntityGroupCollections.flatMap((collection) =>
					collection.legalEntityGroups.map((x) => ({
						id: x.id,
						collectionName: collection.name,
						name: x.name,
					})),
				),
			],
			[legalEntityGroupCollections],
		);

		const onChangeDebounce = useMemo(() => debounce(onBlur, 2000), [onBlur]);

		return (
			<T4MultiSelectAutocomplete<LegalEntityGroupOption>
				{...stonlyData({ id: 'legal-entity-groups-selector' })}
				loading={isLoading}
				label="Entity Group"
				options={legalEntityGroups}
				value={legalEntityGroups.filter((x) =>
					selectedLegalEntityGroupIds.includes(x.id),
				)}
				groupBy={(x) => x.collectionName}
				getOptionKey={(option) => option.id}
				getOptionLabel={(x) => x.name}
				getOptionDisabled={(x) => x.id === ''}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				onChange={(_, values) => {
					const newSelectedLegalEntityGroupIds = values.map((x) => x.id);
					setSelectedLegalEntityGroupIds(newSelectedLegalEntityGroupIds);
					onChangeDebounce(newSelectedLegalEntityGroupIds);
				}}
				listHelperText="Entity groups are managed by administrators through Global Settings within Administration."
				placeholder={
					selectedLegalEntityGroupIds.length === 0 ? 'Select entity groups' : ''
				}
				startAdornment={
					<T4FieldAdornment
						title="Entity group"
						description="Assign a group to filter entities or other records based on relationships."
						t4AdornmentType="info"
					/>
				}
				sx={{
					margin: 0,
					minWidth: '20rem',
					...(viewType === FieldViews.default
						? {
								'& .MuiInputBase-root': {
									color: 'transparent',
									borderColor: 'transparent',
									borderBottomColor: 'transparent',
									'&:before, :after, :hover': {
										borderBottomColor: 'transparent !important',
									},
									padding: '0rem !important',
								},
						  }
						: {}),
				}}
				disableListWrap
				disabled={isReadOnly || isLoading}
				textFieldProps={{
					variant: viewType === FieldViews.default ? 'standard' : 'outlined',
				}}
			/>
		);
	});
