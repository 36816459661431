import { useQuery } from '@tanstack/react-query';
import { User } from 'modules/clients/apiGateway/payments4';
import { T4DataResponse2 } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { useClients } from 'shared/hooks/useClients';

export const useGetPaymentApproverUsers = () => {
	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const loadPaymentApproverUsers = useCallback(async () => {
		try {
			const response =
				await applicationApiClient.payments4.approvalRules.getApproverUsers();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<User[]>).data;
			else throw new Error();
		} catch {
			throw new Error(
				'Unable to load users with the payment approver role. Please try again later.',
			);
		}
	}, [applicationApiClient]);

	const queryResult = useQuery<User[], Error>(
		['payment-approver-users'],
		loadPaymentApproverUsers,
		{
			refetchOnWindowFocus: false,
		},
	);

	useEffect(() => {
		if (!queryResult.isLoading && queryResult.error?.message) {
			enqueueSnackbar(queryResult.error.message, {
				variant: 'error',
			});
		}
	}, [queryResult, enqueueSnackbar]);

	return queryResult;
};
