import { Button, Grid } from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { CustomModal } from './customModal';

export type RejectFieldModalProps = {
	title: string;
	open: boolean;
	onClose: () => void;
	onReject: (comment: string) => void;
};

export const RejectFieldModal: FC<RejectFieldModalProps> = observer(
	({ title, open, onClose, onReject }) => {
		const [comment, setComment] = useState<string>('');
		const isDisabled = useMemo(
			() => (comment?.length ?? 0) === 0,
			[comment?.length],
		);

		return (
			<CustomModal
				title={title}
				open={open}
				actions={[
					<Button color="secondary" onClick={() => onClose()}>
						Cancel
					</Button>,
					<Button
						color="error"
						variant="contained"
						disabled={isDisabled}
						onClick={() => onReject(comment)}
					>
						Reject
					</Button>,
				]}
			>
				<Grid container>
					<Grid item xs={12}>
						<T4TextFieldV2
							label="Reason for rejection"
							value={comment}
							onChange={(value) => setComment(value)}
							required
						/>
					</Grid>
				</Grid>
			</CustomModal>
		);
	},
);
