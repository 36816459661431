import { DeleteOutlined } from '@mui/icons-material';
import {
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuItemProps,
	useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';

interface DeleteMenuItemProps extends Omit<MenuItemProps, 'color'> {
	text?: string;
}

export const DeleteMenuItem: React.FC<DeleteMenuItemProps> = observer(
	({ text, ...rest }) => {
		const theme = useTheme();

		return (
			<MenuItem {...rest} color="error">
				<ListItemIcon sx={{ color: theme.palette.error.main }}>
					<DeleteOutlined />
				</ListItemIcon>
				<ListItemText
					sx={{ '& .MuiTypography-root': { color: theme.palette.error.main } }}
				>
					{text ?? 'Delete'}
				</ListItemText>
			</MenuItem>
		);
	},
);
