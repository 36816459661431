import { makeAutoObservable } from 'mobx';
import { Field, FieldType } from 'modules/clients/customer-api/src/api/objects';
import { PhoneNumber } from './phoneNumber';

export class ObjectField {
	public id: string;
	public identifier: string;
	public fieldType: FieldType;
	public updatedBy: string;
	public updatedDate: string;
	public approvedBy?: string;
	public approvedDate?: string;
	public rejectedBy?: string;
	public rejectedDate?: string;
	public rejectedComment?: string;
	public submittedDate?: string;
	public approvedValue?: any;
	public pendingValue?: any;
	public approvedOptionSourceId?: string;
	public pendingOptionSourceId?: string;
	public approvedPhoneNumber?: PhoneNumber;
	public pendingPhoneNumber?: PhoneNumber;

	constructor(fieldData: Field) {
		makeAutoObservable(this);

		if (!fieldData) {
			throw new Error('Field data is required.');
		}

		this.id = fieldData.id;
		this.identifier = fieldData.identifier;
		this.fieldType = fieldData.fieldType;
		this.updatedBy = fieldData.updatedBy;
		this.updatedDate = fieldData.updatedDate;
		this.approvedBy = fieldData.approvedBy ?? undefined;
		this.approvedDate = fieldData.approvedDate ?? undefined;
		this.rejectedBy = fieldData.rejectedBy ?? undefined;
		this.rejectedDate = fieldData.rejectedDate ?? undefined;
		this.rejectedComment = fieldData.rejectedComment ?? undefined;
		this.submittedDate = fieldData.submittedDate ?? undefined;
		this.approvedValue = fieldData.approvedValue ?? undefined;
		this.pendingValue = fieldData.pendingValue ?? undefined;
		this.approvedOptionSourceId = fieldData.approvedOptionSourceId ?? undefined;
		this.pendingOptionSourceId = fieldData.pendingOptionSourceId ?? undefined;
		this.approvedPhoneNumber = fieldData.approvedPhoneNumber
			? new PhoneNumber(fieldData.approvedPhoneNumber)
			: undefined;
		this.pendingPhoneNumber = fieldData.pendingPhoneNumber
			? new PhoneNumber(fieldData.pendingPhoneNumber)
			: undefined;
	}

	public update(fieldData: Field) {
		this.id = fieldData.id;
		this.identifier = fieldData.identifier;
		this.fieldType = fieldData.fieldType;
		this.updatedBy = fieldData.updatedBy;
		this.updatedDate = fieldData.updatedDate;
		this.approvedBy = fieldData.approvedBy ?? undefined;
		this.approvedDate = fieldData.approvedDate ?? undefined;
		this.rejectedBy = fieldData.rejectedBy ?? undefined;
		this.rejectedDate = fieldData.rejectedDate ?? undefined;
		this.rejectedComment = fieldData.rejectedComment ?? undefined;
		this.submittedDate = fieldData.submittedDate ?? undefined;
		this.approvedValue = fieldData.approvedValue ?? undefined;
		this.pendingValue = fieldData.pendingValue ?? undefined;
		this.approvedOptionSourceId = fieldData.approvedOptionSourceId ?? undefined;
		this.pendingOptionSourceId = fieldData.pendingOptionSourceId ?? undefined;
		if (this.approvedPhoneNumber && fieldData.approvedPhoneNumber) {
			this.approvedPhoneNumber.id = fieldData.approvedPhoneNumber.phoneNumberId;
			this.approvedPhoneNumber.countryCode =
				fieldData.approvedPhoneNumber.countryCode;
			this.approvedPhoneNumber.number = fieldData.approvedPhoneNumber.number;
		} else if (fieldData.approvedPhoneNumber) {
			this.approvedPhoneNumber = new PhoneNumber(fieldData.approvedPhoneNumber);
		}
		if (this.pendingPhoneNumber && fieldData.pendingPhoneNumber) {
			this.pendingPhoneNumber.id = fieldData.pendingPhoneNumber.phoneNumberId;
			this.pendingPhoneNumber.countryCode =
				fieldData.pendingPhoneNumber.countryCode;
			this.pendingPhoneNumber.number = fieldData.pendingPhoneNumber.number;
		} else if (fieldData.pendingPhoneNumber) {
			this.pendingPhoneNumber = new PhoneNumber(fieldData.pendingPhoneNumber);
		}
	}
}
