/* eslint-disable mobx/missing-observer */
import { SxProps, useTheme } from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { FC } from 'react';
import { stonlyData } from 'stonly/functions';

interface DrawerCancelButtonProps {
	onCancel: () => void;
	stonlyId?: string;
	label?: string;
	disabled?: boolean;
	sx?: SxProps;
}

export const DrawerCancelButton: FC<DrawerCancelButtonProps> = ({
	onCancel,
	stonlyId,
	label,
	disabled,
	sx,
}) => {
	const theme = useTheme();

	return (
		<T4Button
			{...stonlyData({ id: stonlyId ?? 'cancel-button' })}
			onClick={onCancel}
			disabled={disabled}
			sx={{ color: theme.palette.secondary.main, ...sx }}
		>
			{label ?? 'Cancel'}
		</T4Button>
	);
};

interface DrawerSubmitButtonProps {
	onSubmit: () => void;
	stonlyId?: string;
	label?: string;
	disabled?: boolean;
	sx?: SxProps;
}

export const DrawerSubmitButton: FC<DrawerSubmitButtonProps> = ({
	onSubmit,
	stonlyId,
	label,
	disabled,
	sx,
}) => {
	const theme = useTheme();

	return (
		<T4Button
			{...stonlyData({ id: stonlyId ?? 'submit-button' })}
			onClick={onSubmit}
			type="submit"
			variant="contained"
			disabled={disabled}
			sx={{
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				...sx,
			}}
		>
			{label ?? 'Submit'}
		</T4Button>
	);
};
