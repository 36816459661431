import { flow, makeAutoObservable } from 'mobx';
import { IFilter } from '../../../../utilities/filter';
import { AccountFieldFilter } from '../../entities/configs/accountConfig';
import { CounterpartyFieldFilter } from '../../entities/configs/counterpartyConfig';
import { InternalEntityFieldFilter } from '../../entities/configs/internalEntityConfig';
import { PartnerFieldFilter } from '../../entities/configs/partnerConfig';
import { StaffFieldFilter } from '../../entities/configs/staffConfig';
import { EntityType } from '../../entity4Constants';
import { FieldRepository } from './FieldRepository';
import {
	groupFieldsByCategory,
	groupFieldsByListColumnSettings,
	getDefinitions,
} from './fieldsSortFunctions';
import { ICategorizedFieldSets, IFieldSetData, Field } from './fieldTypes';

export class FieldStore {
	private _fieldRepository = new FieldRepository();

	private _hasLoadedFieldData: boolean = false;
	private _fieldData: IFieldSetData[] = [];

	public fieldsLoading: boolean = false;
	public fields: ICategorizedFieldSets[] = [];
	public fieldToDefinition: Map<string, Field> = new Map();
	public fieldToParentDefinition: Map<string, Field> = new Map();

	constructor() {
		makeAutoObservable(this);
	}

	protected fieldsData = flow(function* (this: FieldStore) {
		if (!this._hasLoadedFieldData) {
			this._fieldData = yield this._fieldRepository.getFields();
			this._hasLoadedFieldData = true;
		}

		return this._fieldData;
	});

	public getFields = flow(function* (
		this: FieldStore,
		filter?: IFilter<IFieldSetData>
	) {
		const fields = yield this.fieldsData();
		this.fields = groupFieldsByCategory(
			filter ? filter.filter(fields) : fields
		);

		const definitions = getDefinitions(filter ? filter.filter(fields) : fields);
		this.fieldToDefinition = definitions.toDefinition;
		this.fieldToParentDefinition = definitions.toParentDefinition;
	});

	public getListColumnFields = flow(function* (
		this: FieldStore,
		filter?: IFilter<IFieldSetData>
	) {
		const fields: IFieldSetData[] = yield this.fieldsData();
		return groupFieldsByListColumnSettings(
			filter ? filter.filter(fields) : fields
		);
	});
}

export const fieldFilterByEntityType = new Map<
	EntityType,
	IFilter<IFieldSetData>
>([
	[EntityType.Entity, new InternalEntityFieldFilter()],
	[EntityType.Counterparty, new CounterpartyFieldFilter()],
	[EntityType.Staff, new StaffFieldFilter()],
	[EntityType.Partner, new PartnerFieldFilter()],
	[EntityType.Account, new AccountFieldFilter()],
]);

const CommonFieldStore = new FieldStore();
export default CommonFieldStore;
