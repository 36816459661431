import { useSigma } from 'features/shared/sigma/providers/sigmaProvider';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useClients } from './useClients';

export type UseDeleteReportButtonProps = {
	isLoading: boolean;
	onSubmit: (onSuccess: () => void) => Promise<void>;
};

export function useDeleteReportButton(): UseDeleteReportButtonProps {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();
	const { workbook, recentlyDeletedFolder, deleteWorkbook } = useSigma();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const onSubmit = useCallback(
		async (onSuccess: () => void) => {
			if (workbook && recentlyDeletedFolder) {
				setIsLoading(true);

				try {
					const response = await customerApiClient.sigmaEmbed.moveWorkbook({
						fileId: workbook.id,
						folderId: recentlyDeletedFolder.id,
					});

					deleteWorkbook(response.data);
					onSuccess();
				} catch {
					enqueueSnackbar(
						`Unable to move report with name: ${workbook.name}.`,
						{
							variant: 'error',
						},
					);
				} finally {
					setIsLoading(false);
				}
			}
		},
		[
			customerApiClient.sigmaEmbed,
			enqueueSnackbar,
			recentlyDeletedFolder,
			deleteWorkbook,
			workbook,
		],
	);

	return {
		isLoading: isLoading,
		onSubmit: onSubmit,
	};
}
