import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Field, Option } from 'modules/clients/customer-api/src/api/common';
import { FC } from 'react';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { T4Autocomplete } from '../../shared/components/atoms/t4Autocomplete';
import { T4AlertStack } from '../../shared/components/molecules/t4AlertStack';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { useFieldView } from '../object/hooks/useFieldView';
import { Collection } from '../object/models/collection';
import { T4Object } from '../object/models/t4Object';
import { ViewType } from '../object/utilities';
import { useHeader } from 'shared/providers/contexts/headerContext';

export type OptionFieldViewProps = {
	object: T4Object;
	field: Field;
	fieldViewType: ViewType;
	collection: Collection | undefined;
};

export const OptionFieldView: FC<OptionFieldViewProps> = observer(
	({ object, field, fieldViewType, collection }) => {
		const { viewType } = useProfileView();
		const { updateLastAutoSave } = useHeader();
		const {
			hasPermission,
			isReadOnly,
			warning,
			error,
			label,
			adornmentState,
			value,
			update,
		} = useFieldView<Option>(
			object,
			field,
			fieldViewType,
			updateLastAutoSave,
			collection,
		);

		return (
			<Box
				id={`approved-field-view-wrapper-${field.id}`}
				key={`approved-field-view-wrapper-${field.id}`}
			>
				<T4Autocomplete<Option, false, boolean, false>
					id={field.id}
					label={label}
					options={field.optionList?.options ?? []}
					getOptionLabel={(option) => option.displayName}
					isOptionEqualToValue={(a, b) => a?.id === b?.id}
					value={value ?? null}
					onChange={(_, value) => update(value ?? undefined)}
					readOnly={isReadOnly}
					disabled={!hasPermission}
					textFieldProps={{
						variant: viewType === FieldViews.default ? 'standard' : 'outlined',
						InputProps: {
							sx: {
								...(viewType === FieldViews.default
									? {
											padding: '0rem !important',
									  }
									: {}),
								paddingRight:
									viewType === FieldViews.default
										? '0px !important'
										: '14px !important',
							},
							...(viewType === FieldViews.default
								? {
										disableUnderline: true,
								  }
								: {}),
						},
					}}
					startAdornment={
						<T4FieldAdornment
							id={field.id}
							t4AdornmentType={adornmentState}
							hasPermission={hasPermission}
							title={field.name}
							description={field.description}
							iconButtonProps={{
								...(viewType === FieldViews.default
									? {
											sx: {
												marginLeft: '-5px',
											},
									  }
									: {}),
							}}
						/>
					}
					required={field.isCreationRequirement && viewType === FieldViews.edit}
					disableClearable={field.isCreationRequirement}
				/>
				<T4AlertStack error={error} warning={warning} />
			</Box>
		);
	},
);
