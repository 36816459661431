import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Jurisdiction } from "../../entityAspect/RegisteredAgentDetails/models/jurisdictionModel";
import { T4AlertStack } from "../../shared/components/molecules/t4AlertStack";

interface IRegistrationLinkedJurisdictionAddressesProps {
    jurisdiction: Jurisdiction | null;
    loading: boolean;
}

export const RegistrationLinkedJurisdictionAddresses: React.FC<IRegistrationLinkedJurisdictionAddressesProps> = observer(
    ({ jurisdiction, loading }) => {
        
        if (loading) {
            return (
                <Box width="100%" my={5} py={2} display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )
        }
        
        if (!jurisdiction) return null;
        if (jurisdiction.streetAddress.isEmpty() && jurisdiction.mailingAddress.isEmpty()) {
            return (
                <Grid item xs={12} sm={12}>
                    <T4AlertStack info='No address information for the matching Jurisdiction' />
                </Grid>
            )
        }

        const missingStreet = jurisdiction.streetAddress.isEmpty() ? 'No Street Address for the matching Jurisdiction' : '';
        const missingMailing = jurisdiction.mailingAddress.isEmpty() && !jurisdiction.isMailingSameAsStreetAddress ? 'No Mailing Address for the matching Jurisdiction' : '';
        const streetAddress = missingStreet === ''
            ? (
                <>
                <Typography variant="body1">{`${jurisdiction.streetAddress.buildingNumber ?? ''} ${jurisdiction.streetAddress.streetName ?? ''}`}</Typography>
                <Typography variant="body1">{jurisdiction.streetAddress.address1}</Typography>
                <Typography variant="body1">{jurisdiction.streetAddress.address2}</Typography>
                <Typography variant="body1">{jurisdiction.streetAddress.location}</Typography>   
                </>
            )
            : <T4AlertStack info={missingStreet} />;

        const mailingAddress = jurisdiction.isMailingSameAsStreetAddress
            ? streetAddress
            : ( 
                missingMailing === ''
                ? (
                    <>
                    <Typography variant="body1">{`${jurisdiction.mailingAddress.buildingNumber ?? ''} ${jurisdiction.mailingAddress.streetName ?? ''}`}</Typography>
                    <Typography variant='body1'>{jurisdiction.mailingAddress.poBox}</Typography>
                    <Typography variant='body1'>{jurisdiction.mailingAddress.address1}</Typography>
                    <Typography variant="body1">{jurisdiction.mailingAddress.address2}</Typography>
                    <Typography variant='body1'>{jurisdiction.mailingAddress.location}</Typography>
                    </>
                )
                : <T4AlertStack info={missingMailing} />
            );

        return (
            <Grid
                item
                container
                columnSpacing={2}
                alignItems="top"
                sm={12}
                sx={{ justifyItems: 'space-between' }}
            >
                <Grid item xs={6} sm={6}>
                    <Paper>
                        <Box padding={2}>
                            <Typography variant='h6'>Street Address</Typography>
                            {streetAddress}
                        </Box>
                        
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <Paper>
                        <Box padding={2}>
                            <Typography variant='h6'>Mailing Address</Typography>
                            {mailingAddress}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        )
});