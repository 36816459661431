import { AxiosInstance } from 'axios';
import { AdministrationEndpoints, administration } from './administration';
import { Cash4Endpoints, cash4 } from './cash4';
import { CommonEndpoints, common } from './common';
import { ConfigurationEndpoints, configuration } from './configuration';
import { FrontendEndpoints, frontend } from './frontend';
import { LayoutEndpoints, layout } from './layout';
import { ObjectsEndpoints, objects } from './objects';
import { V1Endpoints, v1 } from './v1';
import { VisualizationEndpoints, visualizations } from './visualizations';

export type ApiEndpoints = {
	v1: V1Endpoints;
	layout: LayoutEndpoints;
	frontend: FrontendEndpoints;
	configuration: ConfigurationEndpoints;
	common: CommonEndpoints;
	objects: ObjectsEndpoints;
	visualizations: VisualizationEndpoints;
	administration: AdministrationEndpoints;
	cash4: Cash4Endpoints;
};

export function api(axiosInstance: AxiosInstance): ApiEndpoints {
	return {
		v1: v1(axiosInstance),
		layout: layout(axiosInstance),
		frontend: frontend(axiosInstance),
		configuration: configuration(axiosInstance),
		common: common(axiosInstance),
		objects: objects(axiosInstance),
		visualizations: visualizations(axiosInstance),
		administration: administration(axiosInstance),
		cash4: cash4(axiosInstance),
	};
}
