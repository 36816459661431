import { Field, Option } from 'modules/clients/customer-api/src/api/common';
import { ApprovalConfiguration } from 'modules/clients/customer-api/src/api/configuration/fieldApprovals';
import {
	ObjectType,
	ObjectsInput,
} from 'modules/clients/customer-api/src/api/objects';
import moment from 'moment';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { ObjectField } from './models/objectField';

export type ViewType = 'approved' | 'pending';

export function getObjectInputType(
	object: ObjectType,
): ObjectsInput['objectType'] {
	let objectTypeName: ObjectsInput['objectType'];
	switch (object) {
		case ObjectType.entity: {
			objectTypeName = 'entity';
			break;
		}
		case ObjectType.partner: {
			objectTypeName = 'partner';
			break;
		}
		case ObjectType.counterparty: {
			objectTypeName = 'counterparty';
			break;
		}
		case ObjectType.staff: {
			objectTypeName = 'staff';
			break;
		}
		case ObjectType.account: {
			objectTypeName = 'account';
			break;
		}
		case ObjectType.subaccount: {
			objectTypeName = 'subaccount';
			break;
		}
		default: {
			objectTypeName = 'entity';
			break;
		}
	}

	return objectTypeName;
}

export function getReferenceValue(value: any): ReferenceDataValue | undefined {
	if (value) {
		try {
			return JSON.parse(value) as any;
		} catch (error) {
			// TODO: why is this here?
			return {
				value: value,
				displayName: value,
				identifier: value,
			} as any;
		}
	} else {
		return undefined;
	}
}

export function getOption(
	field: Field,
	value: string | undefined,
): Option | undefined {
	if (value) {
		if (field.optionList) {
			return field.optionList.options.find((option) => option.id === value);
		}
	}

	return undefined;
}

export function getValue<TReturn>(
	objectField: ObjectField,
	field: Field,
	value: ViewType = 'approved',
): TReturn | undefined {
	if (!objectField) {
		throw new Error('Object field is required.');
	}
	if (!field) {
		throw new Error('Field is required.');
	}

	if (field.fieldType === 'Text' && !field.referenceCollectionName) {
		if (value === 'pending') {
			return (objectField.pendingValue ?? undefined) as any;
		} else {
			return (objectField.approvedValue ?? undefined) as any;
		}
	} else if (field.fieldType === 'Text' && field.referenceCollectionName) {
		if (value === 'pending') {
			return getReferenceValue(objectField.pendingValue) as any;
		} else {
			return getReferenceValue(objectField.approvedValue) as any;
		}
	} else if (field.fieldType === 'Boolean') {
		if (value === 'pending') {
			return (
				objectField.pendingValue ? Boolean(objectField.pendingValue) : undefined
			) as any;
		} else {
			return (
				objectField.approvedValue
					? Boolean(objectField.approvedValue)
					: undefined
			) as any;
		}
	} else if (field.fieldType === 'Date') {
		if (value === 'pending') {
			return (
				objectField.pendingValue ? moment(objectField.pendingValue) : undefined
			) as any;
		} else {
			return (
				objectField.approvedValue
					? moment(objectField.approvedValue)
					: undefined
			) as any;
		}
	} else if (field.fieldType === 'Option') {
		if (value === 'approved') {
			return getOption(field, objectField.approvedOptionSourceId) as any;
		} else {
			return getOption(field, objectField.pendingOptionSourceId) as any;
		}
	} else if (field.fieldType === 'LongText') {
		if (value === 'approved') {
			return (objectField.approvedValue ?? undefined) as any;
		} else {
			return (objectField.pendingValue ?? undefined) as any;
		}
	} else if (field.fieldType === 'PhoneNumber') {
		if (value === 'approved') {
			return (objectField.approvedPhoneNumber ?? undefined) as any;
		} else {
			return (objectField.pendingPhoneNumber ?? undefined) as any;
		}
	} else if (field.fieldType === 'Address') {
		throw new Error('Not supported.');
	}

	return undefined;
}

export function getInitalValue<TReturn>(
	objectField: ObjectField | undefined,
	field: Field,
	fieldViewType: ViewType,
	approvalConfiguration: ApprovalConfiguration,
): TReturn | undefined {
	if (objectField) {
		const approvedValue = getValue<TReturn>(objectField, field);
		if (field.requiresApproval && approvalConfiguration.isActive) {
			if (fieldViewType === 'approved') {
				return approvedValue;
			} else {
				const pendingValue = getValue<TReturn>(objectField, field, 'pending');
				if (
					pendingValue ||
					isSubmitted(objectField, field) ||
					isRejected(objectField, field)
				) {
					return pendingValue;
				} else {
					return approvedValue;
				}
			}
		} else {
			return approvedValue;
		}
	}
}

export function getValidationWarning(
	objectField: ObjectField,
	field: Field,
	value: ViewType = 'approved',
): string | undefined {
	if (
		objectField &&
		field &&
		field.validationCriterion &&
		field.validationCriterion.validationRegex &&
		field.fieldType === 'Text'
	) {
		const regex = new RegExp(field.validationCriterion.validationRegex);
		if (value === 'pending') {
			const pendingValue = getValue<string>(objectField, field, value);
			if (pendingValue && !regex.test(pendingValue)) {
				return field.validationCriterion.errorDescription;
			}
		} else {
			const approvedValue = getValue<string>(objectField, field, value);
			if (approvedValue && !regex.test(approvedValue)) {
				return field.validationCriterion.errorDescription;
			}
		}
	}

	return undefined;
}

export function getValidationWarningFromValue(
	field: Field,
	value: string | undefined,
) {
	if (
		field &&
		field.validationCriterion &&
		field.validationCriterion.validationRegex
	) {
		const regex = new RegExp(field.validationCriterion.validationRegex);
		if (value && !regex.test(value)) {
			return field.validationCriterion.errorDescription ?? undefined;
		}
	}

	return undefined;
}

export function isRejected(objectField: ObjectField, field: Field): boolean {
	if (getValue(objectField, field, 'pending') === undefined) {
		return false;
	}

	return (
		objectField.rejectedDate !== null && objectField.rejectedDate !== undefined
	);
}

export function isSubmitted(objectField: ObjectField, field: Field): boolean {
	return (
		objectField.submittedDate !== null &&
		objectField.submittedDate !== undefined &&
		!isRejected(objectField, field)
	);
}

export function isPendingApproval(
	objectField: ObjectField,
	field: Field,
): boolean {
	return isSubmitted(objectField, field) && !isRejected(objectField, field);
}
