import { AxiosInstance } from 'axios';
import { DataImportsEndpoints, dataImports } from './dataImports';

export type Cash4Endpoints = {
	dataImports: DataImportsEndpoints;
};

export function cash4(axiosInstance: AxiosInstance): Cash4Endpoints {
	return {
		dataImports: dataImports(axiosInstance),
	};
}
