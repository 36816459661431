import { Box, CircularProgress, Grid, Switch, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { FormModal } from 'shared/components/formModal';
import { stonlyData } from 'stonly/functions';
import SecurityGroupConfigurationViewModel from './securityGroupConfigurationViewModel';

interface IProps {
	viewModel: SecurityGroupConfigurationViewModel;
}

export const SecurityGroupConfigurationTestIds = {
	toggle: 'SecurityGroupConfigurationToggle',
};

export const SecurityGroupConfiguration: React.FC<IProps> = observer(
	({ viewModel }) => {
		useEffect(() => {
			viewModel.load();
		}, [viewModel]);
		const { requiresReview, loading, updatingApprovalRequirement } = viewModel;

		return (
			<>
				<Typography variant="h3" marginBottom={1}>
					Security Group Approvals
				</Typography>

				<Grid container>
					<Grid item sm={9}>
						<Typography>
							Approvals required from a secondary admin when adding entities and
							members to groups and deleting existing groups.
						</Typography>
					</Grid>
					<Grid item sm={3}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							{updatingApprovalRequirement ? (
								<CircularProgress sx={{ marginRight: 1 }} size={25} />
							) : null}
							<Switch
								data-testid={SecurityGroupConfigurationTestIds.toggle}
								{...stonlyData({ id: 'security-group-approval-toggle' })}
								readOnly={loading}
								disabled={loading}
								name="require reviews for security groups"
								checked={requiresReview}
								onChange={viewModel.handleRequireReviewsToggle}
							/>
						</Box>
					</Grid>

					<FormModal
						open={viewModel.isOpen()}
						onClose={viewModel.closeModal}
						onSubmit={viewModel.submitModal}
						fullWidth
						title="Turning Off Security Group Approvals"
						description="Turning off approvals for security groups will automatically approve any requests to add or remove members or entities."
						submitButtonLabel="Turn Off Approvals"
						loadingMessage="Loading..."
						error=""
						loading={updatingApprovalRequirement}
						submitDisabled={updatingApprovalRequirement}
					/>
				</Grid>
			</>
		);
	},
);

const ConnectedSecurityGroupConfiguration = observer(() => {
	const [viewModel] = useState(new SecurityGroupConfigurationViewModel());
	return <SecurityGroupConfiguration viewModel={viewModel} />;
});
export default ConnectedSecurityGroupConfiguration;
