import { IFieldUpdate } from '../../entities/entitiesApiTypes';
import { Entity } from '../../entities/objects/entity';
import { FieldSet } from '../../shared/fieldSets/fieldTypes';
import { Option } from 'shared/types/referenceDataTypes';
import { CollectionList } from './collectionList';

const fieldPrefix = 't4_field_address_';
export const AddressFields = {
	type: `${fieldPrefix}type`,
	address_1: `${fieldPrefix}1`,
	address_2: `${fieldPrefix}2`,
	po_box: `${fieldPrefix}po_box`,
	city: `${fieldPrefix}city`,
	street_name: `${fieldPrefix}street_name`,
	country: `${fieldPrefix}country`,
	state_province: `${fieldPrefix}state_province`,
	building_number: `${fieldPrefix}building_number`,
	postal_code: `${fieldPrefix}postal_code`,
};

export const AddressTabName = 'Addresses';

export class AddressList extends CollectionList {
	public readonly addressType: Option;

	constructor(
		entity: Entity,
		collectionDefinition: FieldSet,
		addressType: Option,
	) {
		super(entity, collectionDefinition);
		this.addressType = addressType;
	}

	get displayName() {
		return this.addressType.displayName;
	}

	get isFromTo(): boolean {
		return ['OPER', 'REGIS'].includes(this.addressType.code);
	}

	public get fieldUpdatesForNewItem(): IFieldUpdate[] {
		return [
			{
				fieldIdentifier: AddressFields.type,
				value: this.addressType.id,
			},
		];
	}
}
