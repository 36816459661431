import { flow, makeAutoObservable } from 'mobx';
import { UseErrorHandlerProps } from 'shared/hooks/useErrorHandler';
import { Option } from 'shared/types/referenceDataTypes';
import { parseError } from 'utilities/errors/errorUtils';
import { EntityType, SubObjectType } from '../../entity4Constants';
import { CommonCustomerFieldsRepository } from '../../fields/customerFieldsRepository';
import { AdornmentType } from '../../shared/components/molecules/inputAdornmentWithModal';
import { CommonDocumentStore, DocumentStore } from '../documentStore';
import { IDocumentDto } from '../documentTypes';
import { documentTypeOptionListSourceId } from '../documentUtils';
import { Document } from './document';

export class DocumentViewModel {
	private _documentStore: DocumentStore;

	private _loading = false;
	private _document?: Document = undefined;
	private _documentTypes: Option[] = [];
	private _error?: string = undefined;

	private _documentId?: string = undefined;
	private _entityId: string;
	private _entityType: EntityType;
	public subObjectType?: SubObjectType = undefined;
	public subObjectId?: string = undefined;

	private _validContentTypes = `image/jpeg, image/tiff, image/bmp, image/png, image/svg+xml, image/gif, application/pdf, application/msword,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
    application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.visio, application/vnd.ms-visio.viewer, application/xml, text/plain, text/csv, text/xml`;
	private _maxFileSizeInMB = 50;
	private _maxFileSize = this._maxFileSizeInMB * Math.pow(1024, 2);

	private _open: boolean = false;
	private _uploading: boolean = false;
	private _multipleUpload: boolean = false;

	private _externalUrl = '';
	private _file: File | undefined = undefined;
	private _fileName = '';

	constructor(
		handleError: UseErrorHandlerProps,
		entityType: EntityType,
		entityId: string,
		documentId?: string,
		documentStore: DocumentStore = CommonDocumentStore,
	) {
		makeAutoObservable(this);
		this._documentStore = documentStore;
		this._documentId = documentId;
		this._entityType = entityType;
		this._entityId = entityId;

		if (this._documentId) {
			this.load().catch((error) => handleError(error));
		}
	}

	public load = flow(function* (this: DocumentViewModel) {
		try {
			this._loading = true;
			const document: Document = yield this._documentStore.getDocumentById(
				this._entityType,
				this._entityId,
				this._documentId!,
			);
			const documentTypes: Option[] =
				yield CommonCustomerFieldsRepository.getOptionsByOptionListSourceIdentifier(
					documentTypeOptionListSourceId,
				);

			if (document) this._document = document;
			if (documentTypes) this._documentTypes = documentTypes;
		} catch (error) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});

	public open = () => {
		this._open = true;
	};

	public close = () => {
		this.clear();
		this._open = false;
	};

	public clear = () => {
		this._file = undefined;
		this._fileName = '';
		this._externalUrl = '';
		this._error = undefined;
	};

	public isLoading = () => this._loading;
	public isOpen = () => this._open;
	public isUploading = () => this._uploading;
	public getError = () => this._error;
	public getExternalUrl = () => this._externalUrl;
	public getFile = () => this._file;
	public getFileName = () => this._fileName;
	public getMultipleUploadEnabeld = () => this._multipleUpload;
	public canSave = (): boolean =>
		this._uploading === false &&
		((this._file !== undefined &&
			this.externalUrlIsEmpty() &&
			this._fileName !== '') ||
			(this._file === undefined &&
				!this.externalUrlIsEmpty() &&
				this._fileName !== ''));

	public toggleMultipleUpload = () =>
		(this._multipleUpload = !this._multipleUpload);
	public setFile = (file: File) => {
		this._file = file ?? undefined;
		this._fileName = file?.name ?? '';
	};
	public setExternalUrl = (value: string) => (this._externalUrl = value ?? '');
	public setFileName = (value: string) => (this._fileName = value ?? '');

	public externalUrlIsEmpty = () => this._externalUrl === '';
	public isUrlFieldDisabled = () => this._file !== undefined;

	public get loading() {
		return this._loading;
	}

	public get document() {
		return this._document;
	}

	public get entityType() {
		return this._entityType;
	}

	public get entityId() {
		return this._entityId;
	}

	public get error() {
		return this._error;
	}

	public get formattedFileSize() {
		return this.formatFileSize(this._file?.size);
	}

	public get validContentTypes() {
		return this._validContentTypes;
	}

	public get validContentTypesAsArray() {
		return this._validContentTypes.split(',').map((item) => {
			return item.trim();
		});
	}

	public get documentTypes() {
		return this._documentTypes;
	}

	public get currentDocumentType(): Option | null {
		return (
			this.documentTypes.find((x) => x.id === this._document?.documentTypeId) ??
			null
		);
	}

	public formatFileSize(bytes: string | number | undefined) {
		if (typeof bytes === 'string') {
			bytes = parseInt(bytes);
		}
		if (bytes) {
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			const i = Math.min(
				parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10),
				sizes.length - 1,
			);
			return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)} ${sizes[i]}`;
		}
		return 'n/a';
	}

	public async changeDocumentType(option: Option | null) {
		let toSave = undefined;
		if (option?.id) {
			toSave = option.id;
		}
		await this.document?.update(
			this._entityType,
			this._entityId,
			'documentTypeId',
			toSave,
		);
	}

	public get documentStatuses() {
		const possibleValues = ['Active', 'Inactive'];

		let menuItems = possibleValues.map((x) => {
			return { id: x, displayName: x };
		});

		return menuItems;
	}

	public async changeStatus(option: Option | null) {
		let toSave = undefined;
		if (option?.id) {
			const status = this.documentStatuses?.find((s) => s.id === option.id);
			if (status) {
				toSave = option.id;
			}
		}

		await this.document?.update(
			this._entityType,
			this._entityId,
			'status',
			toSave,
		);
	}

	public adornmentType<T extends keyof IDocumentDto>(
		fieldKey: T,
	): AdornmentType {
		if (this.document!.isWaiting(fieldKey)) {
			return 'loading';
		}
		if (this.document!.isSaved(fieldKey)) {
			return 'success';
		}
		return 'info';
	}

	public validFile(file: File) {
		if (!this.validFileType(file.type) || file.size > this._maxFileSize) {
			this._error = `This document is too large to upload. The maximum file size for uploads is ${this._maxFileSizeInMB} MB. Reduce the file size, or select a different document.`;
			return false;
		}

		return true;
	}

	public validFileType(fileType: string) {
		const validFileTypesArray = this.validContentTypesAsArray;

		return validFileTypesArray.indexOf(fileType) !== -1;
	}
}
