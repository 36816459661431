import { Collapse, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { T4Alert } from '../atoms/t4Alert';
import { T4Button } from '../atoms/t4Button';

export interface T4AlertStackProps {
	info?: string;
	warning?: string;
	error?: string;
	customError?: string;
	errors?: string[];
	warnings?: string[];
	onTryAgainClick?: () => void;
}
export const T4AlertStack: FC<T4AlertStackProps> = ({
	info,
	warning,
	error,
	customError,
	errors,
	warnings,
	onTryAgainClick,
}) => (
	<Collapse
		in={
			Boolean(info) ||
			Boolean(warning) ||
			Boolean(error) ||
			Boolean(customError) ||
			Boolean(errors) ||
			Boolean(warnings)
		}
	>
		<Stack spacing={1} sx={{ paddingTop: '0.25rem' }}>
			{info && (
				<T4Alert severity="info" variant="outlined">
					{info}
				</T4Alert>
			)}
			{warning && (
				<T4Alert severity="warning" variant="outlined">
					{warning}
				</T4Alert>
			)}
			{error && (
				<T4Alert
					severity="error"
					variant="outlined"
					action={
						onTryAgainClick && (
							<T4Button t4buttontype="tryAgain" onClick={onTryAgainClick}>
								Try Again
							</T4Button>
						)
					}
				>
					{error}
				</T4Alert>
			)}
			{customError && (
				<T4Alert severity="error" variant="outlined">
					{customError}
				</T4Alert>
			)}
			{errors && errors.length > 0 && (
				<T4Alert severity="error" variant="outlined">
					{errors.map((err, index) => {
						return (
							<Typography key={index} variant="body2">
								{err}
							</Typography>
						);
					})}
				</T4Alert>
			)}
			{warnings && warnings.length > 0 && (
				<T4Alert severity="warning" variant="outlined">
					{warnings.map((warning, index) => (
						<Typography key={index} variant="body2">
							{warning}
						</Typography>
					))}
				</T4Alert>
			)}
		</Stack>
	</Collapse>
);
