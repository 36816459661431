import {
	makeObservable,
	observable,
	runInAction,
	override,
} from 'mobx';
import { getEntityType } from '../../../entity4Constants';
import { FieldDataType } from '../../../shared/fieldSets/fieldTypes';
import { IEntityFieldApiData } from '../../entitiesApiTypes';
import { EntityRepository } from '../../entityRepository';
import { Entity } from '../entity';
import { EntityFieldBase } from './field';
import { NETWORK_ERROR } from 'features/entity4/globalDocuments/pages/models/globalDocumentViewModel';

// eslint-disable-next-line mobx/missing-observer
export const CreateNewEntityField = (
	entity: Entity,
	identifier: string,
	fieldType: FieldDataType,
	onUpdate: () => void,
): EntityField =>
	new EntityField(
		{
			id: '', // this is bad and we need to get rid of CreateNewEntityField altogether
			identifier,
			fieldType,
		},
		entity,
		undefined,
		undefined,
		onUpdate,
	);

export class EntityField extends EntityFieldBase {
	public _entity: Entity;

	public _entityRepository: EntityRepository;

	public _onUpdate: () => void = () => {};

	constructor(
		data: IEntityFieldApiData,
		entity: Entity,
		created?: boolean,
		entityRepository?: EntityRepository,
		onUpdate?: () => void,
	) {
		super(data, created);
		makeObservable<EntityField>(this, {
			_entity: observable,
			_entityRepository: observable,
			_onUpdate: false,
			save: override,
			savePhoneNumber: override,
			approve: override,
			reject: override,
			unSubmit: override,
		});
		this._entity = entity;
		if (onUpdate) {
			this._onUpdate = onUpdate;
		}

		this._entityRepository =
			entityRepository ??
			new EntityRepository(
				entity.entityType,
				entity.id,
				getEntityType(entity.data.parentEntityTypeId),
				entity.data.parentEntityId,
			);
	}

	public async save(value: any): Promise<void> {
		const isNew = !Boolean(this.id);
		runInAction(() => {
			this.loading = true;
			this.loadingSuccess = false;
			this.loadingErrorMessage = '';
		});
		try {
			const entityFieldData = await this._entityRepository.updateField({
				fieldIdentifier: this.data.identifier,
				value: value ?? null,
			});
			this.updateData(entityFieldData);
			if (isNew) {
				this._entity.addField(this);
			}
		} catch (error) {
			runInAction(() => {
				if (typeof error === 'string') {
					this.loadingErrorMessage = error;
				} else {
					this.loadingErrorMessage = NETWORK_ERROR;
				}
			});
		} finally {
			runInAction(() => {
				this._onUpdate();
				this.loading = false;
				this.loadingSuccess = true;
			});
		}
	}

	public async savePhoneNumber(
		countryCode: string | null,
		number: string | null,
		extension: string | null,
	): Promise<void> {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		this._entityRepository
			.updatePhoneNumberField(this.id, countryCode, number, extension)
			.then((field) => {
				this.updateData(field);
				this.loadingSuccess = true;
			})
			.catch((error) => {
				this.loadingErrorMessage = error;
			})
			.finally(() => {
				this.loading = false;
			});
	}

	approve() {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		return this._entityRepository
			.approveField(this.id!)
			.then((data) => this.updateData(data))
			.catch((error) => (this.loadingErrorMessage = error))
			.finally(() => {
				this.loading = false;
				this.loadingSuccess = true;
			});
	}

	reject(comment: string) {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		return this._entityRepository
			.rejectField(this.id!, comment)
			.then((data) => this.updateData(data))
			.catch((error) => (this.loadingErrorMessage = error))
			.finally(() => {
				this.loading = false;
				this.loadingSuccess = true;
			});
	}

	unSubmit() {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		return this._entityRepository
			.unsubmitField(this.id!)
			.then((data) => this.updateData(data))
			.catch((error) => (this.loadingErrorMessage = error))
			.finally(() => {
				this.loading = false;
			});
	}
}
