export const EntityStatusOptionColors: Map<string, string> = new Map([
	['Active', '#a6c09a'],
	['Active Non-Trading', '#a7c7d2'],
	['De-registered', '#c5a8ce'],
	['Dissolved', '#8aa4b8'],
	['Divested', '#d7add4'],
	['Dormant', '#fbc59c'],
	['Liquidated-Solvent', '#e3d69d'],
	['Merged', '#f6a3a2'],
	['Voluntary Suspended', '#fbfbb8'],
	['Pending Dissolution', '#d1ebfd'],
	['Pending Establishment', '#fbcee5'],
	['Pending Liquidation', '#fbcea9'],
	['Strike Off Requested', '#e5fac6'],
	['Struck Off', '#e3dddd'],
	['No status defined', '#e0dfdf'],
]);

export const AsOfDateKey = 'as-of-date';
export const EntityStatusKey = 'entityStatus';
export const EntityRegionKey = 'entityRegion';
export const FunctionalCurrencyKey = 'functionalCurrencyCode';
export const ERPCodeKey = 'erpCode';
export const ERPPlatformKey = 'erpPlatform';
export const FormOfOrganizationKey = 'formOfOrganization';
export const IncorporatedDateKey = 'incorporatedDate';
export const DissolutionDateKey = 'dissolutionDate';
export const AcquiredCompanyKey = 'acquiredCompany';
export const AcquisitionDateKey = 'acquisitionDate';
export const FormationStateKey = 'stateProvince';
export const RegistrationNumberKey = 'registrationNumber';
export const TaxIdNumberKey = 'taxIdNumber';
export const TaxIdCountryKey = 'taxIdCountry';
export const LEIIdentifierKey = 'leiIdentifier';

export const DisplayLegendKey = 'displayLegend';
export const DisplaySecondaryOwnershipKey = 'displaySecondaryOwnership';
export const DisplayAuxiliaryNodesKey = 'displayAuxiliaryNodes';
export const DisplayCompactKey = 'displayCompact';
export const DisplayOverviewMapKey = 'displayOverviewMap';

export const DisplayFlagKey = 'displayFlag';

export const CardOptionsDisplayKey = 'cardOptionsColor';
export const CardOptionUserDefinedColorKey = 'userDefinedColor';

export const InformationOptionsDisplayColumnOne = [
	{ title: 'General', items: [EntityStatusKey, EntityRegionKey] },
	{
		title: 'Accounting',
		items: [FunctionalCurrencyKey, ERPCodeKey, ERPPlatformKey],
	},
	{
		title: 'Incorporation',
		items: [FormOfOrganizationKey, IncorporatedDateKey, DissolutionDateKey],
	},
];

export const InformationOptionsDisplayColumnTwo = [
	{ title: 'Acquisition', items: [AcquiredCompanyKey, AcquisitionDateKey] },
	{ title: 'Registrations', items: [FormationStateKey, RegistrationNumberKey] },
	{ title: 'Tax', items: [TaxIdNumberKey, TaxIdCountryKey] },
	{ title: 'External Codes', items: [LEIIdentifierKey] },
];

export const AllChartOptions = [
	DisplayAuxiliaryNodesKey,
	DisplaySecondaryOwnershipKey,
	DisplayLegendKey,
	DisplayCompactKey,
	DisplayOverviewMapKey,
];

export const ChartOptionsMap = new Map([
	[DisplayAuxiliaryNodesKey, 'Standalone Entities'],
	[DisplaySecondaryOwnershipKey, 'Secondary Ownership'],
	[DisplayLegendKey, 'Display Legend'],
	[DisplayCompactKey, 'Display Compact'],
	[DisplayOverviewMapKey, 'Display Overview Map'],
]);

export const InformationOptionsMap = new Map([
	[EntityStatusKey, 'Entity Status'],
	[EntityRegionKey, 'Entity Region'],
	[FormationStateKey, 'Home Registration'],
	[FunctionalCurrencyKey, 'Functional Currency'],
	[IncorporatedDateKey, 'Incorporated Date'],
	[ERPCodeKey, 'ERP Code'],
	[ERPPlatformKey, 'ERP Platform'],
	[AcquiredCompanyKey, 'Acquired Company'],
	[AcquisitionDateKey, 'Acquisition Date'],
	[DissolutionDateKey, 'Dissolution Date'],
	[LEIIdentifierKey, 'LEI Identifier'],
	[RegistrationNumberKey, 'Registration Number'],
	[TaxIdNumberKey, 'Tax ID Number'],
	[TaxIdCountryKey, 'Tax ID Country'],
	[FormOfOrganizationKey, 'Form of Organization'],
]);
