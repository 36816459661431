import {
	Payment,
	PaymentApprovalLevelStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';

export const getApprovalStatusText = (payment: Payment | undefined): string => {
	if (!payment?.approvalState || payment.approvalState === null)
		return 'No matching approval tier found.';

	if (
		payment.approvalState.currentStatus !==
		PaymentApprovalLevelStatusTypes[PaymentApprovalLevelStatusTypes.Pending]
	)
		return (
			payment.approvalState.currentStatus ??
			'Approval status is currently unavailable.'
		); // null coalesce string handles the edge case where approval state exists but no status is set

	const completedLevelCount =
		payment?.approvalState?.paymentApprovalLevelStates.filter(
			(levelState) =>
				levelState.status ===
				PaymentApprovalLevelStatusTypes[
					PaymentApprovalLevelStatusTypes.Approved
				],
		).length ?? 0;
	return `${completedLevelCount} of ${payment?.approvalState?.paymentApprovalLevelStates.length} approvals`;
};
