import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { PowerOfAttorneyType } from 'modules/clients/customer-api/src/api/referenceData';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { parseErrorsV2 } from 'utilities/errors/errorUtils';
import {
	BaseRelationship,
	PowerOfAttorneyRelationship,
} from '../relationshipsObjectTypes';

export class EntityRelationshipsListModel {
	private readonly _entityId: string;
	private _loading: boolean = false;
	private _error: string | undefined = undefined;

	private _data: EntityRelationships | null = null;
	private _powerOfAttorneys: PowerOfAttorneyType[] = [];

	constructor(entityId: string, powerOfAttorneys: PowerOfAttorneyType[] = []) {
		makeAutoObservable(this);

		this._entityId = entityId;
		this._powerOfAttorneys = powerOfAttorneys;
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get data(): EntityRelationships | null {
		return this._data;
	}
	public get flatData(): BaseRelationship[] {
		let relationships: BaseRelationship[] = this._data?.accounts ?? [];
		relationships = relationships.concat(this._data?.children ?? []);
		relationships = relationships.concat(this.data?.parents ?? []);
		relationships = relationships.concat(this._data?.staff ?? []);

		return relationships;
	}
	public get powerOfAttorneys() {
		return this._powerOfAttorneys ?? [];
	}

	// relationships load function
	public load = flow(function* (this: EntityRelationshipsListModel) {
		this._loading = true;
		let errors = [];

		try {
			const relationshipsResponse: AxiosResponse<
				ApiResponse<EntityRelationships>
			> = yield customerApi.get<ApiResponse<EntityRelationships>>(
				`entity/${this._entityId}/relationships`,
			);

			if (relationshipsResponse.data?.error) {
				errors.push(relationshipsResponse.data.error);
			}

			if (relationshipsResponse.data?.value) {
				this._data = relationshipsResponse.data.value;
			}
		} catch (error: any) {
			const parsedError = parseErrorsV2(error);

			if (parsedError) {
				errors.push(parsedError);
			}
		}

		this._error = errors.join(', ');
		this._loading = false;
	});
}

interface EntityRelationships {
	entityName: string;
	accounts: EntityAccountRelationship[];
	parents: ParentOwnerRelationship[];
	children: ChildOwnerRelationship[];
	staff: EntityStaffRelationship[];
	powerOfAttorneys: LegalEntityPowerOfAttorneyRelationship[];
}

interface EntityAccountRelationship extends BaseRelationship {
	accountName: string | null;
	accountStatus: string | null;
	counterpartyName: string | null;
}

interface ParentOwnerRelationship extends BaseRelationship {
	owner: string | null;
	ownershipLevel: string | null;
}

interface ChildOwnerRelationship extends BaseRelationship {
	childEntity: string | null;
	formOfOrganization: string | null;
	ownershipLevel: string | null;
}

interface EntityStaffRelationship extends BaseRelationship {
	staffName: string | null;
	title: string | null;
}

export type LegalEntityPowerOfAttorneyRelationship =
	PowerOfAttorneyRelationship & {
		staffDisplayName: string;
	};
