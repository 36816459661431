import { OpenInNewOutlined } from '@mui/icons-material';
import { Button, Divider, Grid, SxProps, Typography } from '@mui/material';
import { AutoCompleteOptionWithDescription } from 'features/entity4/fields/components/autoComplete/autoCompleteUtils';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4DateField } from 'features/entity4/shared/components/atoms/t4DateField';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { OptionDto } from 'features/entity4/shared/fieldSets/FieldRepository';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { GlobalDocumentViewModel } from '../pages/models/globalDocumentViewModel';
import { DOCUMENT_STATUSES } from '../shared/documentConstants';

export type DocumentViewProps = {
	isReadonly: boolean;
	viewModel: GlobalDocumentViewModel;
	sx?: SxProps;
};

export const DocumentView: FC<DocumentViewProps> = observer(
	({ isReadonly, viewModel, sx = {} }) => (
		<Grid container sx={{ ...sx, gap: 2 }}>
			<Grid item xs={12}>
				<Typography variant="h3">Document Details</Typography>
			</Grid>
			{viewModel.getErrors().length > 0 && (
				<Grid item xs={12}>
					<T4AlertStack errors={viewModel.getErrors()} />
				</Grid>
			)}
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid container item xs={12} sx={{ gap: 2 }}>
				<Grid container item xs={12}>
					<Grid
						container
						item
						xs={12}
						columnSpacing={2}
						sx={{
							flexDirection: 'row',
							flexWrap: 'nowrap',
						}}
					>
						<Grid item xs={12}>
							<T4TextFieldV2
								label="Document Name"
								value={viewModel.document?.getName() ?? ''}
								onChange={(value) => viewModel.document?.setName(value)}
								onBlur={() => viewModel.updateName()}
								InputProps={{ readOnly: isReadonly }}
								startAdornment={
									<T4FieldAdornment
										title="Document Name"
										t4AdornmentType={viewModel.nameAdornmentState}
										description="The name of the document that was uploaded."
									/>
								}
							/>
						</Grid>
						<Grid item>
							<Button
								startIcon={<OpenInNewOutlined />}
								variant="outlined"
								color="primary"
								fullWidth
								onClick={() => viewModel.onViewFileClick()}
								sx={{
									marginTop: '8px',
									marginBottom: '4px',
									height: '40px',
								}}
							>
								<Typography noWrap color="inherit" variant="button">
									View File
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<T4AlertStack errors={viewModel.nameErrors} />
					</Grid>
				</Grid>
				{viewModel.document?.getData().externalUrl && (
					<Grid item xs={12}>
						<T4TextFieldV2
							label="File URL"
							value={viewModel.document?.getExternalUrl() ?? ''}
							onChange={(value) => viewModel.document?.setExternalUrl(value)}
							onBlur={() => viewModel.updateExternalUrl()}
							InputProps={{ readOnly: isReadonly }}
						/>
						<T4AlertStack errors={viewModel.externalUrlErrors} />
					</Grid>
				)}
				<Grid item xs={12} sm={12}>
					<T4Autocomplete<OptionDto>
						loading={viewModel.isLoading()}
						options={viewModel.getDocumentTypes()}
						isOptionEqualToValue={(a, b) => a.code === b.code}
						getOptionLabel={(option) => option.displayName}
						renderOption={(props, option) => (
							<AutoCompleteOptionWithDescription
								{...props}
								label={option.displayName}
								description={option.detail}
							/>
						)}
						label="Document Type"
						value={viewModel.document?.getType() ?? null}
						onSelect={(type) => viewModel.updateType(type)}
						readOnly={isReadonly}
						startAdornment={
							<T4FieldAdornment
								title="Document Type"
								t4AdornmentType={viewModel.typeAdornmentState}
								description="The document type is used to categorize documents according to their use within an entity or object."
							/>
						}
					/>
					<T4AlertStack errors={viewModel.typeErrors} />
				</Grid>
				<Grid item xs={12} sm={12}>
					<T4TextFieldV2
						label="Description"
						value={viewModel.document?.getDescription() ?? ''}
						onChange={(value) => viewModel.document?.setDescription(value)}
						onBlur={async () => viewModel.updateDescription()}
						InputProps={{ readOnly: isReadonly }}
						startAdornment={
							<T4FieldAdornment
								title="Description"
								t4AdornmentType={viewModel.descriptionAdornmentState}
								description="A short, user-defined description of the document up to 1,024 characters in length."
							/>
						}
						multiline
						minRows={3}
						maxRows={3}
					/>
					<T4AlertStack errors={viewModel.descriptionErrors} />
				</Grid>
				<Grid item xs={12} sm={12}>
					<T4Autocomplete<string>
						loading={viewModel.isLoading()}
						options={DOCUMENT_STATUSES}
						isOptionEqualToValue={(a, b) => a === b}
						getOptionLabel={(status) => status}
						label="Document Status"
						value={viewModel.document?.getStatus() ?? null}
						readOnly={isReadonly}
						onSelect={async (status) => viewModel.updateStatus(status)}
						startAdornment={
							<T4FieldAdornment
								title="Document Status"
								t4AdornmentType={viewModel.statusAdornmentState}
								description="Indicates the status of the document."
							/>
						}
					/>
					<T4AlertStack errors={viewModel.statusErrors} />
				</Grid>
				<Grid item xs={12} sm={12}>
					<T4DateField
						id="document-signeddate"
						label="Signed Date"
						value={viewModel.document?.getSignedDate() ?? null}
						disabled={isReadonly}
						onChange={(date) => viewModel.document?.setSignedDate(date)}
						onAccept={(date) => {
							viewModel.document?.setSignedDate(date);
							viewModel.udpateSignedDate();
						}}
						onBlur={() => viewModel.udpateSignedDate()}
						slotProps={{
							field: {
								InputProps: {
									startAdornment: (
										<T4FieldAdornment
											title="Signed Date"
											t4AdornmentType={viewModel.signedDateAdornmentState}
											description="The date that the document was physically signed. Signature date is required on certain documents like W-8BEN-E and W-9 US tax forms."
										/>
									),
								},
							},
						}}
					/>
					<T4AlertStack errors={viewModel.signedDateErrors} />
				</Grid>
				<Grid item xs={12} sm={12}>
					<T4DateField
						id="document-expirationdate"
						label="Expiration Date"
						value={viewModel.document?.getExpirationDate() ?? null}
						disabled={isReadonly}
						onChange={(date) => viewModel.document?.setExpirationDate(date)}
						onAccept={(date) => {
							viewModel.document?.setExpirationDate(date);
							viewModel.udpateExpirationDate();
						}}
						onBlur={() => viewModel.udpateExpirationDate()}
						slotProps={{
							field: {
								InputProps: {
									startAdornment: (
										<T4FieldAdornment
											title="Expiration Date"
											t4AdornmentType={viewModel.expirationDateAdornmentState}
											description="The date the document expires, or through which it is effective."
										/>
									),
								},
							},
						}}
					/>
					<T4AlertStack errors={viewModel.expirationDateErrors} />
				</Grid>
				<Grid container item spacing={2}>
					<Grid item xs={12} sm={6}>
						<T4DateField
							id="document-effectivefrom"
							label="Effective From"
							value={viewModel.document?.getEffectiveFromDate() ?? null}
							disabled={isReadonly}
							onChange={(date) => viewModel.document?.setEffectiveFrom(date)}
							onAccept={(date) => {
								viewModel.document?.setEffectiveFrom(date);
								viewModel.udpateEffectiveFrom();
							}}
							onBlur={() => viewModel.udpateEffectiveFrom()}
							slotProps={{
								field: {
									InputProps: {
										startAdornment: (
											<T4FieldAdornment
												title="Effective From"
												t4AdornmentType={viewModel.effectiveFromAdornmentState}
												description="The date from which the document is effective, generally from a legal or authorization perspective."
											/>
										),
									},
								},
							}}
						/>
						<T4AlertStack errors={viewModel.effectiveFromErrors} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<T4DateField
							id="document-effectiveto"
							label="Effective To"
							value={viewModel.document?.getEffectiveToDate() ?? null}
							disabled={isReadonly}
							onChange={(date) => viewModel.document?.setEffectiveTo(date)}
							onAccept={(date) => {
								viewModel.document?.setEffectiveTo(date);
								viewModel.udpateEffectiveTo();
							}}
							onBlur={() => viewModel.udpateEffectiveTo()}
							slotProps={{
								field: {
									InputProps: {
										startAdornment: (
											<T4FieldAdornment
												title="Effective To"
												t4AdornmentType={viewModel.effectiveToAdornmentState}
												description="The date to which the document is effective, generally from a legal or authorization perspective."
											/>
										),
									},
								},
							}}
						/>
						<T4AlertStack errors={viewModel.effectiveToErrors} />
					</Grid>
				</Grid>
				<Grid container item xs={12} spacing={2}>
					<Grid item xs={12} sm={4}>
						<T4TextFieldV2
							label="Uploaded By"
							value={viewModel.document?.getUploadedBy() ?? ''}
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<T4DateField
							id="document-uploadeddate"
							label="Uploaded Date"
							value={viewModel.document?.getUploadedDate() ?? null}
							disabled
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<T4TextFieldV2
							label="File Size"
							value={viewModel.document?.getFileSize() ?? ''}
							disabled
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	),
);
