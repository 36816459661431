import { History } from 'history';
import { makeAutoObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { paths } from '../../../../shared/constants/paths';
import { EntityType, aspectUrlIds } from '../../entity4Constants';
import { JurisdictionRepository } from '../../entityAspect/RegisteredAgentDetails/jurisdictionRepository';
import FrontendRepository, {
	IEntityAspectDto,
} from '../../shared/repositories/frontendRepository';
import { EntityRepository } from '../entityRepository';

export class AspectManager {
	private _entityId: string | undefined;
	private _entityType: EntityType;
	private _history: History;
	private _entityRepository: EntityRepository;

	private _aspects: IEntityAspectDto[] = [];
	private _availableAspects: IEntityAspectDto[] = [];

	private _loadingAspects: boolean = false;
	private _aspectsError: string = '';

	private _loadingAddAspect: boolean = false;
	private _addAspectError: string = '';

	private _loadingDeleteAspect: boolean = false;

	constructor(
		entityId: string | undefined,
		entityType: EntityType,
		history: History,
		entityRepository?: EntityRepository,
	) {
		makeAutoObservable(this);
		this._entityId = entityId;
		this._entityType = entityType;
		this._history = history;
		this._entityRepository =
			entityRepository ?? new EntityRepository(entityType, entityId!);
	}

	public get aspects() {
		return this._aspects;
	}

	public get availableAspects() {
		return this._availableAspects;
	}

	public get hasAspectsError() {
		return this._aspectsError !== '';
	}

	public get loadingAspects() {
		return this._loadingAspects;
	}

	public get addAspectError() {
		return this._addAspectError;
	}

	public set addAspectError(value: string) {
		this._addAspectError = value;
	}

	public get loadingDeleteAspect() {
		return this._loadingDeleteAspect;
	}

	public get loadingAddAspect() {
		return this._loadingAddAspect;
	}

	public getAvailableAspect(urlId: string) {
		return this._availableAspects.find((x) => x.urlId === urlId);
	}

	public loadAspects = async () => {
		if (!this._entityId) return;
		try {
			this._aspectsError = '';
			this._loadingAspects = true;

			const response = await FrontendRepository.getEntityAspects(
				this._entityId,
				this._entityType,
			);
			this._aspects = response.aspects;
			this._availableAspects = response.availableAspects;
		} catch (error) {
			this._aspectsError = error as string;
		} finally {
			this._loadingAspects = false;
		}
	};

	public addAspect = async (aspectUrlId: string): Promise<void> => {
		if (!this._entityId) return;
		try {
			this._addAspectError = '';
			this._loadingAddAspect = true;

			const availableAspect = this.getAvailableAspect(aspectUrlId);
			if (!availableAspect) {
				this._addAspectError = 'Invalid aspect';
				return;
			}

			await this._entityRepository.addAspect(availableAspect.id);
			await this.loadAspects();

			const path =
				aspectUrlId === aspectUrlIds.subAccounts
					? paths.entity4.objects.object.subaccounts.href
					: aspectUrlId === aspectUrlIds.registeredAgentDetails
					? paths.entity4.objects.object.registeredAgentDetails.href
					: paths.entity4.href;

			this._history.push(
				generatePath(path, {
					objectType: this._entityType,
					objectId: this._entityId,
				}),
			);
		} catch (error) {
			this._addAspectError = error as string;
		} finally {
			this._loadingAddAspect = false;
		}
	};

	public deleteAspect = async (aspectUrlId: string) => {
		try {
			this._loadingDeleteAspect = true;

			switch (aspectUrlId) {
				case aspectUrlIds.registeredAgentDetails:
					await this.deleteRegisteredAgentDetailsAspect();
					return;
				default:
					return;
			}
		} catch {
		} finally {
			this._loadingDeleteAspect = false;
		}
	};

	private deleteRegisteredAgentDetailsAspect = async () => {
		if (!this._entityId) return;
		await JurisdictionRepository.deleteRegisteredAgentDetailsAspect(
			this._entityId,
			this._entityType,
		);
		await this.loadAspects();

		this._history.push(
			generatePath(paths.entity4.objects.object.information.href, {
				objectType: this._entityType,
				objectId: this._entityId,
			}),
		);
	};
}
