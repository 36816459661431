/* eslint-disable mobx/missing-observer */
import { DateRange } from '@mui/x-date-pickers-pro';
import {
	useCash4Configurations,
	UseCash4ConfigurationsProps,
} from 'features/administration/_hooks/useCash4Configurations';
import { CurrencyConfiguration } from 'features/cash4/balances/balancesModel';
import {
	DeleteTransactionResponse,
	Transaction,
} from 'modules/clients/customer-api/src/transactions';
import moment, { Moment } from 'moment';
import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import {
	dateRangeToQueryParam,
	isValidDateRange,
} from 'shared/utilities/dateUtilities';
import { CurrencyOption, TransactionListItem } from '../models';
import { getTransactionListItem } from '../utilities';

//#region Context

type TransactionsContextProps = {
	dateRange: DateRange<Moment>;
	handleDateRangeChange: (dateRange: DateRange<Moment>) => void;
	defaultDateRange: DateRange<Moment>;
	fetchTransactions: (
		dateRange: DateRange<Moment>,
		legalEntityGroupIds: string[],
	) => Promise<TransactionListItem[]>;
	currencyOption: CurrencyOption;
	configurations: UseCash4ConfigurationsProps['configurations'];
	handleCurrencyOptionChange: (value: CurrencyOption['id']) => void;
	deleteCashTransactionApiCall: (
		transactionId: string,
	) => Promise<DeleteTransactionResponse>;
};

const TransactionsContext = createContext<TransactionsContextProps>({
	dateRange: [moment(), moment()],
	handleDateRangeChange: () => {},
	defaultDateRange: [moment(), moment()],
	fetchTransactions: async () => [],
	currencyOption: { id: 'original', value: 'Account Currency' },
	configurations: undefined,
	handleCurrencyOptionChange: () => {},
	deleteCashTransactionApiCall: async () => ({
		error: null,
		errors: null,
		failure: false,
		success: true,
		value: '',
	}),
});

//#endregion

//#region Provider

export type TransactionProviderProps = {
	children: ReactNode;
};

export const TransactionsProvider: FC<TransactionProviderProps> = ({
	children,
}) => {
	const { configurations } = useCash4Configurations();
	const { transactionStartDate, transactionEndDate } = useSessionStorage();

	const defaultDateRange: DateRange<Moment> = [
		moment().subtract(8, 'days').startOf('day'),
		moment().subtract(1, 'days').endOf('day'),
	];

	const [dateRange, setDateRange] = useState<DateRange<Moment>>(
		[moment(transactionStartDate), moment(transactionEndDate)] ||
			defaultDateRange,
	);
	const [currencyConfiguration, setCurrencyConfiguration] =
		useState<CurrencyConfiguration>({} as CurrencyConfiguration);

	const currencyOptions: CurrencyOption[] = [
		{ id: 'original', value: 'Account Currency' },
		{
			id: 'reporting',
			value: `Reporting Currency (${currencyConfiguration?.reportingCurrencyCode?.code})`,
		},
	];

	const [currencyOption, setCurrencyOption] = useState<CurrencyOption>(
		currencyOptions[0],
	);

	const handleCurrencyOptionChange = (value: CurrencyOption['id']) => {
		const selectedOption = currencyOptions.find(
			(option) => option.id === value,
		);

		if (selectedOption) {
			setCurrencyOption(selectedOption);
		}
	};

	const handleDateRangeChange = (dateRange: DateRange<Moment>) => {
		if (isValidDateRange(dateRange)) {
			setDateRange(dateRange);
		}
	};

	const { customerApiClient } = useClients();

	const fetchTransactions = async (
		dateRange: DateRange<Moment>,
		legalEntityGroupIds: string[] = [],
	): Promise<TransactionListItem[]> => {
		if (!isValidDateRange(dateRange)) return Promise.resolve([]);

		let queryParams = new URLSearchParams();

		queryParams = dateRangeToQueryParam(queryParams, dateRange);

		legalEntityGroupIds.forEach((id) => {
			queryParams.append('legalEntityGroupIds', id);
		});

		let response = await customerApiClient.transactions.all(queryParams);

		const transactions: Transaction[] = response.data.value;

		const transactionListItems: TransactionListItem[] =
			transactions.map((transaction) => getTransactionListItem(transaction)) ??
			[];

		return transactionListItems;
	};

	const fetchCurrencyConfiguration = useCallback(async () => {
		const response =
			await customerApiClient.cash4.configurations.getCurrencyConfiguration();
		const currencyConfiguration = response.data.value;

		const safeCurrencyConfiguration: CurrencyConfiguration = {
			reportingCurrencyCode: {
				name: currencyConfiguration.reportingCurrencyCode.name || '',
				code: currencyConfiguration.reportingCurrencyCode.code || '',
			},
			activeCurrencies: currencyConfiguration.activeCurrencies.map(
				(currency) => ({
					name: currency.name || '',
					code: currency.code || '',
				}),
			),
		};

		setCurrencyConfiguration(safeCurrencyConfiguration);
	}, [customerApiClient.cash4.configurations]);

	useEffect(() => {
		fetchCurrencyConfiguration();
	}, [fetchCurrencyConfiguration]);

	const deleteCashTransactionApiCall = async (transactionId: string) => {
		try {
			const response = await customerApiClient.transactions.deleteTransaction(
				transactionId,
			);
			if (response.data.error) {
				throw new Error(response.data.error);
			}
			return response.data;
		} catch (error) {
			throw error;
		}
	};

	return (
		<TransactionsContext.Provider
			value={{
				dateRange,
				handleDateRangeChange,
				defaultDateRange,
				configurations: configurations,
				fetchTransactions,
				currencyOption,
				handleCurrencyOptionChange,
				deleteCashTransactionApiCall,
			}}
		>
			{children}
		</TransactionsContext.Provider>
	);
};

//#endregion

export type UseTransactionsProps = TransactionsContextProps;

export function useTransactions(): UseTransactionsProps {
	const context = useContext(TransactionsContext);
	if (context === undefined) {
		throw new Error(
			'useTransactions must be used within a TransactionsProvider',
		);
	}
	return context;
}
