import { FC } from 'react';
import { useCreateConnection } from '../hooks/useCreateConnection';
import { ConnectionForm } from './connectionForm';
import { ConfirmFormModal } from 'shared/components/confirmFormModal';

export type CreateConnectionModalProps = {
	isOpen: boolean;
	onClose: () => void;
	fetchConnections: () => Promise<void>;
	setActiveBankTab: (bankCode: string) => void;
};

export const CreateConnectionModal: FC<CreateConnectionModalProps> = ({
	isOpen,
	onClose,
	fetchConnections,
	setActiveBankTab,
}) => {
	const {
		loading,
		error,
		errors,
		connectors,
		connector,
		connectionName,
		connectionParameters,
		disableSubmit,
		isDirty,
		setConnector,
		setConnectionName,
		setConnectionParameter,
		open,
		clear,
		create,
	} = useCreateConnection();

	return (
		<ConfirmFormModal
			loading={loading}
			isOpen={isOpen}
			isDirty={isDirty}
			disableSubmit={disableSubmit}
			modalTitle="Create Connection"
			cancelModalTitle="Discard Connection?"
			cancelModalDescription="Are you sure you want to discard this Connection? Your changes will not be saved."
			onSubmit={async () => {
				if (await create()) {
					const bankCode = connector!.bankCode;
					onClose();
					clear();
					await fetchConnections();
					setActiveBankTab(bankCode);
				}
			}}
			onOpen={open}
			onClose={() => {
				onClose();
				clear();
			}}
			submitButtonLabel="Create"
			error={error}
		>
			<ConnectionForm
				readOnly={loading}
				errors={errors}
				connectors={connectors}
				connector={connector}
				connectionName={connectionName}
				connectionParameters={connectionParameters}
				setConnector={setConnector}
				setConnectionName={setConnectionName}
				setConnectionParameter={setConnectionParameter}
			/>
		</ConfirmFormModal>
	);
};
