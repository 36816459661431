import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { FC, useEffect, useState } from 'react';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { T4Autocomplete } from '../../shared/components/atoms/t4Autocomplete';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';
import CommonReferenceRepository from '../../shared/repositories/referenceRepository';

export interface ReferenceFieldFormViewProps {
	field: Field;
	value: ReferenceDataValue | undefined;
	setValue: (field: Field, value: ReferenceDataValue | undefined) => void;
}

export const ReferenceFieldFormView: FC<ReferenceFieldFormViewProps> = observer(
	({ field, value, setValue }) => {
		const [isLoading, setIsLoading] = useState<boolean>(true);
		const [options, setOptions] = useState<ReferenceDataValue[]>([]);

		useEffect(() => {
			async function getOptions() {
				setIsLoading(true);
				const options = await CommonReferenceRepository.getCollection(
					field.referenceCollectionName,
				);

				if ((options?.length ?? 0) > 0) {
					setOptions(options);
				}
				setIsLoading(false);
			}
			getOptions();
		}, [field.referenceCollectionName]);

		return (
			<T4Autocomplete<ReferenceDataValue>
				id={field.id}
				label={field.name}
				loading={isLoading}
				options={options}
				getOptionLabel={(option) => option.displayName}
				isOptionEqualToValue={(a, b) => a.identifier === b.identifier}
				value={value}
				onChange={(_, value) => setValue(field, value ?? undefined)}
				disableClearable={field.isCreationRequirement}
				required={field.isCreationRequirement}
				startAdornment={
					<T4FieldAdornment
						id={field.id}
						t4AdornmentType={'info'}
						title={field.name}
						description={field.description}
					/>
				}
			/>
		);
	},
);
