import { useAuth0 } from '@auth0/auth0-react';
import {
	LDProvider,
	basicLogger,
	useLDClient,
} from 'launchdarkly-react-client-sdk';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, useEffect } from 'react';
import { useConfigurations } from 'shared/hooks/useConfigurations';

export interface T4LanchDarklyProviderProps {
	children: ReactNode;
}
export const T4LanchDarklyProvider: FC<T4LanchDarklyProviderProps> = observer(
	({ children }) => {
		const { featureFlagKey } = useConfigurations();

		return (
			<LDProvider
				clientSideID={featureFlagKey}
				options={{
					...(process.env.REACT_APP_DISABLE_LAUNCHDARKLY_LOGGING === 'true'
						? { logger: basicLogger({ level: 'none' }) }
						: {}),
				}}
			>
				<T4LaunchDarklyInnerProvider>{children}</T4LaunchDarklyInnerProvider>
			</LDProvider>
		);
	},
);

interface T4LaunchDarklyInnerProviderProps {
	children: ReactNode;
}
const T4LaunchDarklyInnerProvider: FC<T4LaunchDarklyInnerProviderProps> =
	observer(({ children }) => {
		const { user } = useAuth0();
		const ldClient = useLDClient();

		useEffect(() => {
			if (user) {
				ldClient?.identify({
					kind: 'user',
					key: user.sub,
					custom: {
						organization: user.org_id,
					},
				});
			}
		});

		return <>{children}</>;
	});
