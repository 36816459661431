import { AxiosResponse } from 'axios';
import { Tag } from 'modules/clients/customer-api/src/entity4/tags';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTags } from 'shared/hooks/useTags';
import { parseError, parseErrorsV2 } from 'utilities/errors/errorUtils';
import { ObjectPathParams } from '../../../../shared/constants/paths';
import { useClients } from '../../../../shared/hooks/useClients';
import { ApiResponse } from '../../../../utilities/api';
import { EntityType } from '../../entity4Constants';
import FrontendRepository, {
	IEntityHeaderDto,
} from '../../shared/repositories/frontendRepository';
import { useHeader } from 'shared/providers/contexts/headerContext';

export type PageHeader = {
	name: string;
	entityObjectTags: Tag[];
	addTag: (tagName: string) => Promise<void>;
	removeTag: (tagId: string) => void;
	entityType: EntityType;
	objectId: string | undefined;
};

export const usePageHeader = (): PageHeader => {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();
	const { addTag, refetchTags } = useTags();
	const { objectType, objectId } = useParams<ObjectPathParams>();
	const { updateObjectName } = useHeader();

	const [name, setName] = useState<string>('');
	const [entityObjectTags, setEntityObjectTags] = useState<Tag[]>([]);

	useEffect(() => {
		if (!objectId || !objectType) return;

		(async () => {
			try {
				const headerResponse: AxiosResponse<ApiResponse<IEntityHeaderDto>> =
					await FrontendRepository.getEntityHeader(objectType, objectId);

				if (headerResponse.status === 404) {
					enqueueSnackbar('404 cannot locate', {
						variant: 'error',
					});

					return;
				} else if (!headerResponse.data.success || !headerResponse.data.value) {
					if (headerResponse.data.error) {
						enqueueSnackbar(parseErrorsV2(headerResponse.data.error), {
							variant: 'error',
						});

						return;
					}

					enqueueSnackbar('Something unexpected happened.', {
						variant: 'error',
					});

					return;
				} else {
					setName(headerResponse.data.value.name);
					updateObjectName(headerResponse.data.value.name);
					setEntityObjectTags(headerResponse.data.value.tags);
				}
			} catch (error) {
				enqueueSnackbar(parseErrorsV2(error), {
					variant: 'error',
				});
			}
		})();
	}, [enqueueSnackbar, objectId, objectType]);

	const addObjectTag = useCallback(
		async (tagName: string) => {
			const tag = await addTag(objectType, objectId, tagName);

			if (tag && !entityObjectTags.some((x) => x.name === tag.name)) {
				setEntityObjectTags([...entityObjectTags, tag]);
			}
		},
		[addTag, objectId, entityObjectTags, objectType],
	);

	const removeTag = useCallback(
		async (tagId: string) => {
			try {
				let response = await customerApiClient.entity4.tags.remove({
					entityType: objectType,
					entityId: objectId as string,
					tagId: tagId,
				});

				if (response.status !== 200 || !response.data.success) {
					enqueueSnackbar('Something unexpected happened.', {
						variant: 'error',
					});
					return;
				}

				let shortenedTagArray = entityObjectTags.filter(
					(x) => x.tagId !== response.data.value,
				);
				setEntityObjectTags(shortenedTagArray);
				refetchTags();
			} catch (err: any) {
				enqueueSnackbar(parseError(err), {
					variant: 'error',
				});
			}
		},
		[
			customerApiClient.entity4.tags,
			objectType,
			objectId,
			entityObjectTags,
			refetchTags,
			enqueueSnackbar,
		],
	);

	return {
		name,
		entityObjectTags,
		addTag: addObjectTag,
		removeTag,
		entityType: objectType,
		objectId,
	};
};
