import { useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios, { AxiosRequestConfig } from 'axios';
import { observer } from 'mobx-react-lite';
import {
	T4ApplicationApiClient,
	getApplicationApiClient,
} from 'modules/clients/apiGateway/client';
import {
	T4CustomerClient,
	getCustomerApiClient,
} from 'modules/clients/customer-api/src/client';
import { FC, ReactNode, createContext, useCallback, useMemo } from 'react';
import { useConfigurations } from 'shared/hooks/useConfigurations';
import { customerApi } from './customerApi';

export type ClientsContextProps = {
	customerApiClient: T4CustomerClient;
	applicationApiClient: T4ApplicationApiClient;
};

export const ClientsContext = createContext<ClientsContextProps>({
	customerApiClient: getCustomerApiClient(axios.create()),
	applicationApiClient: getApplicationApiClient(axios.create()),
});

export type T4ClientsProviderProps = {
	children: ReactNode;
};

export const T4ClientsProvider: FC<T4ClientsProviderProps> = observer(
	({ children }) => {
		const { getAccessTokenSilently } = useAuth0();
		const { customerApiUrl, apiGateway, auth0Audience } = useConfigurations();

		const accessTokenInterceptor = useCallback(
			async (axiosRequestConfiguration: AxiosRequestConfig<any>) => {
				axiosRequestConfiguration.headers = {
					...axiosRequestConfiguration.headers,
					Authorization: `Bearer ${await getAccessTokenSilently({
						authorizationParams: {
							audience: auth0Audience,
						},
					})}`,
				};

				return axiosRequestConfiguration;
			},
			[auth0Audience, getAccessTokenSilently],
		);

		const apiSubscriptionKeyInterceptor = useCallback(
			async (axiosRequestConfiguration: AxiosRequestConfig<any>) => {
				axiosRequestConfiguration.headers = {
					...axiosRequestConfiguration.headers,
					...(apiGateway.apiSubscriptionKey && {
						'Api-Subscription-Key': apiGateway.apiSubscriptionKey,
					}),
				};

				return axiosRequestConfiguration;
			},
			[apiGateway.apiSubscriptionKey],
		);

		const queryClient = useMemo(() => new QueryClient(), []);

		const customerApiClient = useMemo(() => {
			const axiosInstance = axios.create({
				baseURL: customerApiUrl,
				validateStatus: (_) => true,
			});

			axiosInstance.interceptors.request.use(accessTokenInterceptor);
			customerApi.interceptors.request.use(accessTokenInterceptor);

			return getCustomerApiClient(axiosInstance);
		}, [accessTokenInterceptor, customerApiUrl]);

		const apiGatewayClient = useMemo(() => {
			const axiosInstance = axios.create({
				baseURL: `${apiGateway.baseUrl}`,
				validateStatus: (_) => true,
			});

			axiosInstance.interceptors.request.use(accessTokenInterceptor);
			axiosInstance.interceptors.request.use(apiSubscriptionKeyInterceptor);

			return getApplicationApiClient(axiosInstance);
		}, [
			accessTokenInterceptor,
			apiGateway.baseUrl,
			apiSubscriptionKeyInterceptor,
		]);

		return (
			<QueryClientProvider client={queryClient}>
				<ClientsContext.Provider
					value={{
						customerApiClient: customerApiClient,
						applicationApiClient: apiGatewayClient,
					}}
				>
					{children}
				</ClientsContext.Provider>
			</QueryClientProvider>
		);
	},
);
