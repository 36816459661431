import { Box, Grid } from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { observer } from 'mobx-react-lite';
import { FC, useMemo, useState } from 'react';
import { T4Checkbox } from '../../components/atoms/t4Checkbox';
import { T4FieldAdornment } from '../../components/molecules/t4FieldAdornment';
import { FieldViewProps, FieldViews } from './fieldViewTypes';

const FieldViewBoolean: FC<FieldViewProps> = observer(
	({ fieldDefinition, fieldData, ...props }) => {
		const { viewType } = useProfileView();

		const [checked, setChecked] = useState(Boolean(fieldData?.approvedValue));
		const isReadOnly = useMemo<boolean>(
			() => viewType !== FieldViews.edit,
			[viewType],
		);

		return (
			<Box>
				<Grid item container flexDirection="row" flexWrap="nowrap" xs="auto">
					{fieldDefinition.description && (
						<Grid
							item
							sx={{
								marginLeft: viewType === FieldViews.default ? '-5px' : '14px',
								alignSelf: 'center',
							}}
						>
							<T4FieldAdornment
								id={fieldData?.id}
								t4AdornmentType={fieldData?.loadingState}
								title={fieldDefinition.name}
								description={fieldDefinition.description}
							/>
						</Grid>
					)}
					<Grid
						item
						sx={{
							marginLeft: fieldDefinition.description ? '0' : '20px',
						}}
					>
						<T4Checkbox
							id={fieldData?.id}
							label={fieldDefinition.name}
							value={checked}
							onChange={(event, isChecked) => {
								event.preventDefault();

								if (!isReadOnly) {
									if (fieldData) {
										fieldData.save(isChecked).then(() => setChecked(isChecked));
									} else {
										props.createField(isChecked.toString());
									}
								}
							}}
							readOnly={viewType !== FieldViews.edit}
							checked={checked}
						/>
					</Grid>
				</Grid>
			</Box>
		);
	},
);

export default FieldViewBoolean;
