import { Divider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, Fragment } from 'react';
import { NavigationSection, NavigationSectionProps } from './navigationSection';

export type NavigationMenuProps = {
	sections: NavigationSectionProps[];
};

export const NavigationMenu: FC<NavigationMenuProps> = observer(
	({ sections }) => (
		<Fragment>
			{sections.map((section, index) => {
				const navSection = (
					<NavigationSection
						key={`navigation-section-${section.title?.toLowerCase()}}`}
						title={section.title}
						navigationItems={section.navigationItems}
					/>
				);

				if (index !== sections.length - 1) {
					return (
						<Fragment
							key={`navigation-section-${section.title?.toLowerCase()}}`}
						>
							{navSection}
							<Divider
								sx={(theme) => ({ borderColor: theme.palette.grey[50] })}
							/>
						</Fragment>
					);
				} else return navSection;
			})}
		</Fragment>
	),
);
