import { Alert, Collapse, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { T4TryAgainButton } from '../../../styleComponents/t4Components';

export type FieldErrorProps = {
	error?: string;
	customError?: string;
	warning?: string;
	onTryAgainClick?: () => void;
};

export const FieldError: React.FC<FieldErrorProps> = observer(
	({ error, customError, warning, onTryAgainClick }) => (
		<Collapse in={Boolean(error) || Boolean(customError) || Boolean(warning)}>
			<Stack spacing={1} sx={{ paddingTop: '0.25rem' }}>
				{error && (
					<Alert
						severity="error"
						variant="outlined"
						action={
							onTryAgainClick ? (
								<T4TryAgainButton onClick={onTryAgainClick}>
									Try Again
								</T4TryAgainButton>
							) : undefined
						}
						sx={{
							'& .MuiAlert-action': {
								alignItems: 'center',
								paddingTop: 0,
								paddingRight: '0.5rem',
							},
						}}
					>
						{error}
					</Alert>
				)}
				{customError && (
					<Alert severity="error" variant="outlined">
						{customError}
					</Alert>
				)}
				{warning && (
					<Alert severity="warning" variant="outlined">
						{warning}
					</Alert>
				)}
			</Stack>
		</Collapse>
	),
);
