import { EntityType } from 'features/entity4/entity4Constants';
import { flow, makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { parseError } from 'utilities/errors/errorUtils';
import { Document } from './document';

export class DeleteDocumentViewModel {
	private _deleting: boolean = false;
	private _error?: string = undefined;

	private _open: boolean = false;
	private _document?: Document = undefined;
	private _objectType?: EntityType = undefined;
	private _objectId?: string = undefined;

	private _refetch: () => CancellablePromise<void>;

	constructor(refetch: () => CancellablePromise<void>) {
		makeAutoObservable(this);

		this._refetch = refetch;
	}

	public isOpen = (): boolean => this._open;
	public isDeleting = (): boolean => this._deleting;
	public getError = (): string | undefined => this._error;
	public getDocumentName = (): string | undefined => this._document?.name ?? '';

	public open(document: Document, objectType: EntityType, objectId: string) {
		this._document = document;
		this._objectType = objectType;
		this._objectId = objectId;
		this._open = true;
	}

	public close() {
		this._document = undefined;
		this._objectType = undefined;
		this._objectId = undefined;
		this._open = false;
		this._refetch();
	}

	public delete = flow(function* (this: DeleteDocumentViewModel) {
		if (this._document && this._objectType && this._objectId) {
			try {
				this._deleting = true;
				this._error = undefined;

				yield this._document.remove(this._objectType, this._objectId);
			} catch (error) {
				this._error = parseError(error);
			} finally {
				this._deleting = false;
			}
			if (this._error === undefined) {
				this.close();
			}
		}
	});
}
