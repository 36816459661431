import { Share } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { useGetPayment } from '../hooks/usePayments';
import { PaymentApprovalsTab } from './paymentApprovalsTab';
import { PaymentHistoryTab } from './paymentHistoryTab';
import { PaymentStatusAndDetailsTab } from './paymentStatusAndDetailsTab';
import { PaymentStatusTypes } from 'modules/clients/apiGateway/payments4/payments';

interface PaymentDetailsDrawerProps {
	paymentId: string | null;
	onClose: () => void;
}

export const PaymentDetailsDrawer: FC<PaymentDetailsDrawerProps> = ({
	paymentId,
	onClose,
}) => {
	// #region State

	const { enqueueSnackbar } = useSnackbar();

	const {
		isLoading,
		isFetching,
		data: payment,
		error,
	} = useGetPayment(paymentId);
	useEffect(() => {
		if (!isLoading && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
			onClose();
		}
	}, [isLoading, error, enqueueSnackbar, onClose]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	// if payent is rejected, open in approvals tab first
	useEffect(() => {
		if (
			payment?.currentStatus === PaymentStatusTypes[PaymentStatusTypes.Rejected]
		)
			setTabIndex(1);
	}, [payment]);

	// #endregion

	return (
		<T4DrawerBase
			title="Payment Record Details"
			open={!!paymentId}
			onClose={() => {
				onClose();
				setTabIndex(0);
			}}
			tabs={
				<Tabs
					indicatorColor="primary"
					value={tabIndex}
					onChange={(_, index) => setTabIndex(index)}
				>
					<Tab label="Status and Details" tabIndex={0} />
					<Tab label="Approvals" tabIndex={1} />
					<Tab label="History" tabIndex={2} />
				</Tabs>
			}
			utilityActions={[
				<CopyToClipboardIconButton
					valueToCopy={window.location.href}
					initialTooltipText="Copy Link"
				>
					<Share />
				</CopyToClipboardIconButton>,
			]}
			actions={[
				<DrawerCancelButton
					label="Close"
					onCancel={() => {
						onClose();
						setTabIndex(0);
					}}
				/>,
			]}
			initializing={isLoading || isFetching}
			disableNavigationCollapse
		>
			<PaymentStatusAndDetailsTab tabIndex={tabIndex} payment={payment} />
			<PaymentApprovalsTab tabIndex={tabIndex} payment={payment} />
			<PaymentHistoryTab tabIndex={tabIndex} payment={payment} />
		</T4DrawerBase>
	);
};
