import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { parseError } from 'utilities/errors/errorUtils';
import { BaseRelationship } from '../relationshipsObjectTypes';

export class PartnerRelationshipsListModel {
	private readonly _entityId: string;
	private _loading: boolean = false;
	private _error: string | undefined = undefined;

	private _data: PartnerRelationships | null = null;

	constructor(entityId: string) {
		makeAutoObservable(this);

		this._entityId = entityId;
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get data(): PartnerRelationships | null {
		return this._data;
	}
	public get flatData(): BaseRelationship[] {
		return this._data?.accounts ?? [];
	}

	// relationships load function
	public load = flow(function* (this: PartnerRelationshipsListModel) {
		try {
			this._loading = true;
			const response: AxiosResponse<
				ApiResponse<PartnerRelationships>
			> = yield customerApi.get<ApiResponse<PartnerRelationships>>(
				`partner/${this._entityId}/relationships`
			);

			if (response.data.error) throw response.data.error;
			this._data = response.data.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}

interface PartnerRelationships {
	entityName: string;
	accounts: PartnerAccountRelationship[];
}

interface PartnerAccountRelationship extends BaseRelationship {
	accountName: string | null;
	accountStatus: string | null;
	counterpartyName: string | null;
}
