import DashboardPageModel from './dashboardPageModel';
import * as powerBi from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import styles from './dashboardPage.module.css';

class DashboardPageViewModel {
	private model: DashboardPageModel;

	constructor(model?: DashboardPageModel) {
		this.model = model ?? new DashboardPageModel();
	}

	public init() {
		return this.model.init();
	}

	public getReportList = () =>
		this.model.getReports().map((report) => ({
			name: report.reportName,
			id: report.reportId,
		}));

	public setReport = (_: React.SyntheticEvent<any>, newValue: string) => {
		this.model.setSelectedReport(newValue);
	};

	public getSelectedReport = () => this.model.getSelectedReport();

	public getEmbedConfig(): powerBi.IReportEmbedConfiguration {
		const reportDetails = this.model.getSelectedReport();

		return {
			type: 'report',
			id: reportDetails?.reportId,
			embedUrl: reportDetails?.embedUrl,
			accessToken: this.model.getToken(),
			tokenType: powerBi.models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
			settings: {
				panes: {
					pageNavigation: {
						visible: true,
						position: powerBi.models.PageNavigationPosition.Left,
					},
					filters: {
						expanded: false,
						visible: false,
					},
				},
			},
		};
	}

	public renderDashboard() {
		return (
			<PowerBIEmbed
				cssClassName={styles.report}
				embedConfig={this.getEmbedConfig()}
			/>
		);
	}
}

export default DashboardPageViewModel;
