import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { parseError } from 'utilities/errors/errorUtils';
import { BaseRelationship } from '../relationshipsObjectTypes';

export class CounterpartyRelationshipsListModel {
	private readonly _entityId: string;
	private _loading: boolean = false;
	private _error: string | undefined = undefined;

	private _data: CounterpartyRelationships | null = null;

	constructor(entityId: string) {
		makeAutoObservable(this);

		this._entityId = entityId;
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get data(): CounterpartyRelationships | null {
		return this._data;
	}
	public get flatData(): BaseRelationship[] {
		let relationships: BaseRelationship[] = this._data?.accountsOwned ?? [];
		relationships = relationships.concat(this._data?.accountsHeld ?? []);

		return relationships;
	}

	// relationships load function
	public load = flow(function* (this: CounterpartyRelationshipsListModel) {
		try {
			this._loading = true;
			const response: AxiosResponse<
				ApiResponse<CounterpartyRelationships>
			> = yield customerApi.get<ApiResponse<CounterpartyRelationships>>(
				`counterparty/${this._entityId}/relationships`
			);

			if (response.data.error) throw response.data.error;
			this._data = response.data.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}

interface CounterpartyRelationships {
	entityName: string;
	accountsOwned: CounterpartyAccountRelationship[];
	accountsHeld: CounterpartyAccountRelationship[];
}

interface CounterpartyAccountRelationship extends BaseRelationship {
	accountName: string | null;
	accountStatus: string | null;
	counterparty: string | null;
}
