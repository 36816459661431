import { useTheme } from '@mui/material';
import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export interface T4PhoneNumberData {
	countryCode: string | null;
	number: string | null;
}

export type T4PhoneNumberProps = Omit<
	MuiPhoneNumberProps,
	'defaultCountry' | 'autoFormat' | 'disableAreaCodes' | 'fullWidth'
>;
export const T4PhoneNumber: FC<T4PhoneNumberProps> = observer(
	({ id, label = 'Phone Number', sx, ...props }) => {
		const theme = useTheme();

		return (
			<MuiPhoneNumber
				{...props}
				id={`t4-phone-number${id ? `-${id}` : ''}`}
				label={label}
				defaultCountry="us"
				autoFormat
				disableAreaCodes
				fullWidth
				inputProps={{
					'data-testid': 't4-phone-number-input',
					style: {
						color: theme.palette.charcoal[500],
						padding: '8.5px 16px',
						paddingLeft: '0px',
					},
				}}
				sx={{
					...sx,
					marginTop: '8px',
					...(props.InputProps?.readOnly && {
						'& label.Mui-focused': {
							color: '#414042',
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#C0C0C0',
								borderWidth: '1px',
							},
							'&:hover fieldset': {
								borderColor: '#C0C0C0',
								borderWidth: '1px',
							},
						},
					}),
				}}
				disableDropdown={props.InputProps?.readOnly}
			/>
		);
	},
);
