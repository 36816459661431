import {
	Button,
	ButtonProps,
	OutlinedTextFieldProps,
	styled,
	TextField,
} from '@mui/material';

import { FieldViews } from '../fieldSets/fieldViews/fieldViewTypes';

interface T4StyledOutlinedTextFieldProps extends OutlinedTextFieldProps {
	fieldviewtype?: FieldViews;
}
export const T4StyledOutlinedTextField = styled(
	TextField,
)<T4StyledOutlinedTextFieldProps>(
	({ theme, fieldviewtype: fieldViewType }) => ({
		...(fieldViewType === FieldViews.default && {
			'& .MuiFormLabel-root.Mui-disabled': {
				color: theme.palette.text.primary,
			},
			'& .MuiInputBase-input': {
				color: theme.palette.text.primary,
			},
			'& .MuiFormControlLabel-root.Mui-disabled': {
				color: theme.palette.text.primary,
			},
			'& .MuiFormControlLabel-label': {
				color: theme.palette.text.primary,
			},
		}),
	}),
);

export const T4TryAgainButton = styled(Button)<ButtonProps>(({ theme }) => ({
	appearance: 'none',
	padding: 0,
	margin: 0,
	marginLeft: theme.spacing(0.5),
	background: 'transparent',
	border: 0,
	fontSize: 'inherit',
	color: theme.palette.error.main,
	textDecoration: 'underline',
	cursor: 'pointer',

	'&:hover': {
		textDecoration: 'none',
	},
}));

export const T4RejectButton = styled(Button)<ButtonProps>(({ theme }) => ({
	backgroundColor: theme.palette.error.main,
	'&:hover': { backgroundColor: theme.palette.error.dark },
}));
