import { AxiosInstance } from 'axios';
import { Read, T4ApiResponse, Write } from 'modules/clients/types';
import { Payment, PaymentAmount, PaymentParty } from '../payments';

export enum PaymentTemplateStatusTypes {
	Created = 1,
	Approved = 2,
	Rejected = 3,
}

export type PaymentTemplate = {
	id: string;
	version: number;
	name: string;
	currentStatus: string;
	paymentType: string;
	priorityType: string;
	currencyCode: string;
	initiator: PaymentParty;
	payee: PaymentParty;
	referenceData: string | null;
	statusHistory: PaymentTemplateHistory[];
	createdOn: string;
};

export type PaymentTemplateHistory = {
	paymentTemplateStatusType: string;
	reason: string | null;
	createdBy: string;
	createdOn: string;
};

// #region Requests

export type CreatePaymentTemplateRequest = {
	name: string;
	paymentType: string;
	priorityType: string;
	initiator: PaymentParty;
	payee: PaymentParty;
	currencyCode: string;
	referenceData: string | null;
};

export type SubmitPaymentRequest = {
	paymentTemplateVersion: number;
	instructedAmount: PaymentAmount;
	valueDate: string; // DateOnly
	referenceData: string | null;
};

// #endregion

export type PaymentTemplateEndpoints = {
	get: Read<T4ApiResponse<PaymentTemplate>, string>;
	getAll: Read<T4ApiResponse<PaymentTemplate[]>>;
	create: Write<T4ApiResponse<PaymentTemplate>, CreatePaymentTemplateRequest>;
	submitPayment: Write<
		T4ApiResponse<Payment>,
		{ id: string; data: SubmitPaymentRequest }
	>;
	delete: Write<T4ApiResponse<string>, string>;
};

export function paymentTemplates(
	axiosInstance: AxiosInstance,
): PaymentTemplateEndpoints {
	return {
		get: async (id, config = {}) => {
			return await axiosInstance.get(`payments4/paymenttemplates/${id}`, {
				...config,
			});
		},
		getAll: async (_, config = {}) => {
			return await axiosInstance.get('payments4/paymenttemplates', {
				...config,
			});
		},
		create: async (data, config = {}) => {
			return await axiosInstance.post('payments4/paymenttemplates', data, {
				...config,
			});
		},
		submitPayment: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.post(
				`payments4/paymenttemplates/${id}/payments/submit`,
				data,
				{ ...config },
			);
		},
		delete: async (id, config: any = {}) => {
			return await axiosInstance.delete(`payments4/paymenttemplates/${id}`, {
				...config,
			});
		},
	};
}
