export class FileUploadValidation {
	private _validContentTypes: string[] = [];
	private _maxFileSizeInMB: number = 0;
	private _maxFileSize: number = 0;

	constructor(
		validContentTypes: string[],
		maxFileSizeInMB: number
	) {
		this._validContentTypes = validContentTypes;
		this._maxFileSizeInMB = maxFileSizeInMB;
		this._maxFileSize = this._maxFileSizeInMB * Math.pow(1024, 2);
	}

	public getMaxFileSizeInMB = (): number => this._maxFileSizeInMB;
	public getValidContentTypes = (): string[] => this._validContentTypes;

	public isFileValid(file: File | null | undefined): boolean {
		return (
			file !== undefined &&
			file !== null &&
			this.isFileTypeValid(file.type) &&
			file.size <= this._maxFileSize
		);
	}
	public isFileTypeValid(fileType: string): boolean {
		return this._validContentTypes.indexOf(fileType) !== -1;
	}
}
