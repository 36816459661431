import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { DashboardPage } from 'shared/dashboardPage/dashboardPage';
import DashboardPageModel, {
	IWorkspaceEmbedDto,
} from 'shared/dashboardPage/dashboardPageModel';
import DashboardPageViewModel from 'shared/dashboardPage/dashboardPageViewModel';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';

export const Entity4AnalyticsStudioPage: FC = observer(() => {
	const viewModel = useMemo(
		() =>
			new DashboardPageViewModel(
				new DashboardPageModel(() =>
					customerApi
						.get<ApiResponse<IWorkspaceEmbedDto>>('/embed/reports')
						.then((response) => response.data.value),
				),
			),
		[],
	);

	return (
		<>
			<PageHeader
				id={pageHeaderStonlyIds.dashboardPage}
				title="Analytics Studio - Classic"
			/>
			<DashboardPage viewModel={viewModel} />
		</>
	);
});
