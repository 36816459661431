import { Diagram, DiagramInitOptions, GraphLinksModel } from 'gojs';

export function getDiagram(
	options?: DiagramInitOptions,
	diagramListeners?: [
		name: go.DiagramEventName,
		listener: go.DiagramEventHandler,
	][],
) {
	const diagram = new Diagram({
		...options,
		model: new GraphLinksModel({
			nodeKeyProperty: 'key',
			linkKeyProperty: 'key',
		}),
	});

	if (diagramListeners) {
		diagramListeners.forEach(([name, listener]) => {
			diagram.addDiagramListener(name, listener);
		});
	}

	return diagram;
}
