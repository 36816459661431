import { flow, makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { EntityType } from '../../../../entity4Constants';
import { TaxIdForm } from './taxIdForm';
import { TaxIdRepository } from './taxIdRepository';

export interface TaxIdPurposeData {
	taxIdPurposeOptionId: string;
	purposeName: string;
}

export interface TaxIdStatusData {
	taxIdStatusOptionId: string;
	status: string;
}

export interface TaxIdData {
	taxIdId: string;
	purposes: TaxIdPurposeData[];
	primaryFederalIncomeTaxIdDefined: boolean;
	isPrimaryFederalIncomeTaxId: boolean;
	countryReferenceData: string;
	taxIdNumber: string;
	issuedDate: string;
	taxIdTypeReferenceData: string;
	stateReferenceData: string;
	countyParish: string;
	city: string;
	status: TaxIdStatusData;
	issuingAuthorityReferenceData: string;
	updatedDate: Date;
}

export class TaxId {
	private entityId: string;
	private _taxIdId: string;
	private _updateLastAutoSave: (autoSaveDate?: Date) => void;
	private taxIdRepository: TaxIdRepository;

	private data?: TaxIdData;
	private form: TaxIdForm;

	public setPrimaryFederalIncomeTaxIdModalOpen: boolean = false;

	constructor(
		entityId: string,
		entityType: EntityType,
		taxIdId: string,
		updateLastAutoSave: (autoSaveDate?: Date) => void,
		taxIdRepository?: TaxIdRepository,
	) {
		makeAutoObservable(this);

		this.entityId = entityId;
		this._taxIdId = taxIdId;
		this._updateLastAutoSave = updateLastAutoSave;
		this.taxIdRepository = taxIdRepository ?? new TaxIdRepository(entityType);

		this.form = new TaxIdForm(this.taxIdRepository);
	}

	public load = flow(function* (this: TaxId) {
		yield this.fetch();
		this.form.setData(this.data);
		this.form.fetchCountries();
		this.form.fetchStateProvinces();
		this.form.fetchIssuingAuthorities();
		this.form.fetchTaxIdTypes();
		if (this.data?.updatedDate) {
			this._updateLastAutoSave(this.data?.updatedDate);
		}
	});

	public fetch = flow(function* (this: TaxId) {
		this.data = yield this.taxIdRepository.getTaxId(
			this.entityId,
			this.taxIdId,
		);
		this.form.updateData(this.data);
	});

	public get loadingSelectedPurposes() {
		return (
			this.form.isLoadingPurposes ||
			this.form.purposeAdornmentState === 'loading'
		);
	}

	public isSelectedPurpose(option: Option) {
		const index = this.form.selectedPurposes.findIndex(
			(purpose) => purpose.id === option.id,
		);
		return index >= 0;
	}

	public get taxIdId() {
		return this._taxIdId;
	}

	public get selectedPurposes() {
		return this.form?.selectedPurposes ?? [];
	}

	public get isPrimaryFederalIncomeTaxId() {
		return this.form?.isPrimaryFederalIncomeTaxId ?? false;
	}

	public get country() {
		return this.form?.country ?? null;
	}

	public get taxIdNumber() {
		return this.form?.taxIdNumber ?? null;
	}

	public get issuedDate() {
		return this.form?.issuedDate ?? null;
	}

	public get taxIdType() {
		return this.form?.taxIdType ?? null;
	}

	public get stateProvince() {
		return this.form?.stateProvince ?? null;
	}

	public get countyParish() {
		return this.form?.countyParish;
	}

	public get city() {
		return this.form?.city ?? null;
	}

	public get status() {
		return this.form?.taxIdStatus ?? null;
	}

	public get issuingAuthority() {
		return this.form?.issuingAuthority ?? null;
	}

	public get purposes() {
		return this.form?.purposes ?? [];
	}

	public get countries() {
		return this.form?.countries ?? [];
	}

	public get types() {
		return this.form?.taxIdTypes ?? [];
	}

	public get stateProvinces() {
		return this.form?.stateProvinces ?? [];
	}

	public get statuses() {
		return this.form?.taxIdStatuses ?? [];
	}

	public get issuingAuthorities() {
		return this.form?.issuingAuthorities ?? [];
	}

	public get issuingAuthorityUrl() {
		return this.form?.issuingAuthorityUrl ?? '';
	}

	public get purposeAdornmentState() {
		return this.form.purposeAdornmentState;
	}

	public get isPrimaryFederalIncomeTaxIdAdornmentState() {
		return this.form.isPrimaryFederalIncomeTaxIdAdornmentState;
	}

	public get countryAdornmentState() {
		return this.form.countryAdornmentState;
	}

	public get taxIdNumberAdornmentState() {
		return this.form.taxIdNumberAdornmentState;
	}

	public get issuedDateAdornmentState() {
		return this.form.issuedDateAdornmentState;
	}

	public get taxIdTypeAdornmentState() {
		return this.form.taxIdTypeAdornmentState;
	}

	public get stateProvinceAdornmentState() {
		return this.form.stateProvinceAdornmentState;
	}

	public get countyParishAdornmentState() {
		return this.form.countyParishAdornmentState;
	}

	public get cityAdornmentState() {
		return this.form.cityAdornmentState;
	}

	public get taxIdStatusAdornmentState() {
		return this.form.taxIdStatusAdornmentState;
	}

	public get issuingAuthorityAdornmentState() {
		return this.form.issuingAuthorityAdornmentState;
	}

	public get updatedDate() {
		return this.data?.updatedDate;
	}

	public onPurposeFocus() {
		if (!this.form?.hasLoadedPurposes) {
			this.form?.fetchPurposes();
		}
	}

	public onPurposeChange(purposes: Option[]) {
		this.form.selectedPurposes = purposes;
	}

	public onPurposeClose() {
		if (
			this.form.selectedPurposes.every(
				(selectedPurpose) =>
					this.data?.purposes.some(
						(purpose) => purpose.taxIdPurposeOptionId === selectedPurpose.id,
					),
			) &&
			this.data?.purposes.every(
				(purpose) =>
					this.form?.selectedPurposes.some(
						(selectedPurpose) =>
							purpose.taxIdPurposeOptionId === selectedPurpose.id,
					),
			)
		)
			return;
		this.form.purposeAdornmentState = 'loading';
		this.updateTaxId()
			.then(() => {
				this.form.purposeAdornmentState = 'success';
			})
			.finally(() => {
				if (this.form.purposeAdornmentState !== 'success')
					this.form.purposeAdornmentState = 'info';
			});
	}

	public onIsPrimaryFederalIncomeTaxIdClick(isPrimary: boolean) {
		if (isPrimary && this.data?.primaryFederalIncomeTaxIdDefined) {
			this.setPrimaryFederalIncomeTaxIdModalOpen = true;
		} else {
			this.updateIsPrimaryFederalIncomeTaxId(isPrimary);
		}
	}

	public updateIsPrimaryFederalIncomeTaxId(isPrimary: boolean) {
		if (isPrimary !== this.data?.isPrimaryFederalIncomeTaxId) {
			this.form.isPrimaryFederalIncomeTaxIdAdornmentState = 'loading';
			if (this.form) this.form.isPrimaryFederalIncomeTaxId = isPrimary;
			this.updateTaxId()
				.then(() => {
					this.form.isPrimaryFederalIncomeTaxIdAdornmentState = 'success';
				})
				.finally(() => {
					if (
						this.form.isPrimaryFederalIncomeTaxIdAdornmentState !== 'success'
					) {
						this.form.isPrimaryFederalIncomeTaxIdAdornmentState = 'info';
						this.form.isPrimaryFederalIncomeTaxId = !isPrimary;
						this.setPrimaryFederalIncomeTaxIdModalOpen = false;
					}
				});
		}
	}

	public onCountryFocus() {
		if (!this.form?.hasLoadedCountries) {
			this.form?.fetchCountries();
		}
	}

	public onCountrySelect(option: ReferenceDataValue | null) {
		if (option && this.form?.country !== option) {
			if (this.form) this.form.country = option;
			this.form?.fetchStateProvinces();
			this.form?.fetchTaxIdTypes();
			this.form?.fetchIssuingAuthorities();
			this.form.countryAdornmentState = 'loading';
			this.updateTaxId()
				.then(() => {
					this.form.countryAdornmentState = 'success';
				})
				.finally(() => {
					if (this.form.countryAdornmentState !== 'success')
						this.form.countryAdornmentState = 'info';
				});
		}
	}

	public onTaxIdNumberChange(value: string | null) {
		if (value !== this.taxIdNumber) {
			if (value === '') value = null;
			if (this.form) this.form.taxIdNumber = value;
		}
	}

	public onTaxIdNumberBlur() {
		if (this.form?.taxIdNumber !== this.data?.taxIdNumber) {
			this.form.taxIdNumberAdornmentState = 'loading';
			this.updateTaxId()
				.then(() => {
					this.form.taxIdNumberAdornmentState = 'success';
				})
				.finally(() => {
					if (this.form.taxIdNumberAdornmentState !== 'success')
						this.form.taxIdNumberAdornmentState = 'info';
				});
		}
	}

	public onIssuedDateChange(value: Moment | null) {
		value = moment(value);
		if (value.inspect().includes('NaN')) value = null;
		this.form.issuedDate = value;
	}
	public onIssuedDateAccept(value: Moment | null) {
		this.onIssuedDateChange(value);
		this.onIssuedDateBlur();
	}

	public onIssuedDateBlur() {
		const currentDate = this.data?.issuedDate
			? moment(this.data.issuedDate)
			: null;

		if (this.form.issuedDate !== null && !this.form.issuedDate.isValid())
			return;

		if (
			(currentDate &&
				this.form.issuedDate &&
				!currentDate.isSame(this.form.issuedDate)) ||
			(currentDate && !this.form.issuedDate) ||
			(this.form.issuedDate && this.form.issuedDate.isValid() && !currentDate)
		) {
			this.form.issuedDateAdornmentState = 'loading';
			this.updateTaxId()
				.then(() => {
					this.form.issuedDateAdornmentState = 'success';
				})
				.finally(() => {
					if (this.form.issuedDateAdornmentState !== 'success')
						this.form.issuedDateAdornmentState = 'info';
				});
		}
	}

	public onTaxIdTypeFocus() {
		if (!this.form?.hasLoadedTaxIdTypes) {
			this.form?.fetchTaxIdTypes();
		}
	}

	public onTaxIdTypeSelect(option: ReferenceDataValue | null) {
		if (this.form?.taxIdType !== option) {
			if (this.form) this.form.taxIdType = option;
			this.form.taxIdTypeAdornmentState = 'loading';
			this.updateTaxId()
				.then(() => {
					this.form.taxIdTypeAdornmentState = 'success';
				})
				.finally(() => {
					if (this.form.taxIdTypeAdornmentState !== 'success')
						this.form.taxIdTypeAdornmentState = 'info';
				});
		}
	}

	public onStateProvinceFoucs() {
		if (!this.form?.hasLoadedStateProvinces) {
			this.form?.fetchStateProvinces();
		}
	}

	public onStateProvinceSelect(option: ReferenceDataValue | null) {
		if (this.form?.stateProvince !== option) {
			if (this.form) this.form.stateProvince = option;
			this.form?.fetchIssuingAuthorities();
			this.form.stateProvinceAdornmentState = 'loading';
			this.updateTaxId()
				.then(() => {
					this.form.stateProvinceAdornmentState = 'success';
				})
				.finally(() => {
					if (this.form.stateProvinceAdornmentState !== 'success')
						this.form.stateProvinceAdornmentState = 'info';
				});
		}
	}

	public onCityChange(value: string | null) {
		if (this.form?.city !== value) {
			if (value === '') value = null;
			if (this.form) this.form.city = value;
		}
	}

	public onCityBlur() {
		if (this.form?.city !== this.data?.city) {
			this.form.cityAdornmentState = 'loading';
			this.updateTaxId()
				.then(() => {
					this.form.cityAdornmentState = 'success';
				})
				.finally(() => {
					if (this.form.cityAdornmentState !== 'success')
						this.form.cityAdornmentState = 'info';
				});
		}
	}

	public onCountyParishChange(value: string | null) {
		if (this.form?.countyParish !== value) {
			if (value === '') value = null;
			this.form.countyParish = value;
		}
	}

	public onCountyParishBlur() {
		if (this.form?.countyParish !== this.data?.countyParish) {
			this.form.countyParishAdornmentState = 'loading';
			this.updateTaxId()
				.then(() => {
					this.form.countyParishAdornmentState = 'success';
				})
				.finally(() => {
					if (this.form.countyParishAdornmentState !== 'success')
						this.form.countyParishAdornmentState = 'info';
				});
		}
	}

	public onStatusFocus() {
		if (this.form?.taxIdStatuses.length === 0) {
			this.form?.fetchTaxIdStatuses();
		}
	}

	public onStatusSelect(option: Option | null) {
		if (this.form?.taxIdStatus !== option) {
			if (this.form) this.form.taxIdStatus = option;
			this.form.taxIdStatusAdornmentState = 'loading';
			this.updateTaxId()
				.then(() => {
					this.form.taxIdStatusAdornmentState = 'success';
				})
				.finally(() => {
					if (this.form.taxIdStatusAdornmentState !== 'success')
						this.form.taxIdStatusAdornmentState = 'info';
				});
		}
	}

	public onIssuingAuthorityFocus() {
		if (!this.form?.hasLoadedIssuingAuthorities) {
			this.form?.fetchIssuingAuthorities();
		}
	}

	public onIssuingAuthoritySelect = flow(function* (
		this: TaxId,
		option: ReferenceDataValue | null,
	) {
		if (this.form?.issuingAuthority !== option) {
			if (this.form) {
				this.form.issuingAuthority = option;
			}

			this.form.issuingAuthorityAdornmentState = 'loading';

			yield this.updateTaxId();

			this.form.issuingAuthorityAdornmentState = 'success';
			if (this.form.issuingAuthorityAdornmentState !== 'success') {
				this.form.issuingAuthorityAdornmentState = 'info';
			}
		}
	});

	public makePrimaryFederalIncomeTaxId(this: TaxId): Promise<string> {
		return this.taxIdRepository
			.makePrimaryFederalIncomeTaxId(this.entityId, this.taxIdId)
			.finally(() => {
				this.fetch();
				this.form.isPrimaryFederalIncomeTaxId =
					this.data?.isPrimaryFederalIncomeTaxId ?? null;
			});
	}

	public updateTaxId = flow(function* (this: TaxId) {
		yield this.form
			?.updateTaxId(this.entityId, this.taxIdId)
			.finally(() => this._updateLastAutoSave());
		this.fetch();
	});

	public get stateProvinceError() {
		return !this.form.hasLoadedCountries ||
			!this.form.hasLoadedStateProvinces ||
			this.stateProvince === null ||
			this.form.stateProvinces.some(
				(stateProvince) => stateProvince.value === this.stateProvince?.value,
			)
			? ''
			: 'The State/Province is not available for the selected Country. Select a different State/Province.';
	}

	public get issuingAuthorityError() {
		return !this.form.hasLoadedCountries ||
			!this.form.hasLoadedStateProvinces ||
			!this.form.hasLoadedIssuingAuthorities ||
			this.issuingAuthority === null ||
			this.form.issuingAuthorities.some(
				(issuingAthority) =>
					issuingAthority.value === this.issuingAuthority?.value,
			)
			? ''
			: 'The Issuing Authority is not available for either the selected Country or the State/Province. Select a different Issuing Authority.';
	}

	public get taxIdTypeError() {
		return !this.form.hasLoadedCountries ||
			!this.form.hasLoadedTaxIdTypes ||
			this.taxIdType === null ||
			this.form.taxIdTypes.some(
				(taxIdType) => taxIdType.value === this.taxIdType?.value,
			)
			? ''
			: 'The Tax ID Type is not available for the selected Country. Select a different Tax ID Type.';
	}
}
