import { Container } from '@mui/material';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';

export type T4SectionProps = {
	error?: string | null;
	errors?: string[];
	children: ReactNode;
};

export const T4Section: FC<T4SectionProps> = observer(
	({ error, errors, children }) => {
		const allErrors = [...(errors ?? []), error];

		return (
			<Container>
				{allErrors.length > 1 && <T4AlertStack errors={errors} />}
				{children}
			</Container>
		);
	},
);
