export const stonlyIds = {
	investmentsHoldingsRoot: 'investment-holdings',
	investmentsTransactionsRoot: 'investment-transactions',
	investmentsHoldingsPage: 'investment-holdings-page',
	investmentsHoldingsGrid: 'investment-holdings-grid',
	investmentsTransactionsGrid: 'investment-transactions-grid',
	investmentsHoldingsGridFilter: 'investment-holdings-grid-filter',
	investmentsTransactionsGridFilter: 'investment-transactions-grid-filter',
	investmentsHoldingsDateRange: 'investment-holdings-date-range',
	investmentsHoldingsRowContextMenu: 'investment-holdings-row-context-menu',
	investmentsHoldingsDeleteConfirmationModal:
		'investment-holdings-delete-confirmation-modal',
	investmentsTransactionsDeleteConfirmationModal:
		'investment-transactions-delete-confirmation-modal',
	investmentsHoldingsDeleteConfirmationCancelButton:
		'investment-holdings-delete-confirmation-cancel',
	investmentsTransactionsDeleteConfirmationCancelButton:
		'investment-transactions-delete-confirmation-cancel',
	investmentsHoldingsDeleteConfirmationDeleteButton:
		'investment-holdings-delete-confirmation-delete',
	investmentsTransactionsDeleteConfirmationDeleteButton:
		'investment-transactions-delete-confirmation-delete',
};
