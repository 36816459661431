import { Grid } from '@mui/material';
import { getDateWarningMessage } from 'features/entity4/entities/utilities/dateUtilities';
import { T4DateField } from 'features/entity4/shared/components/atoms/t4DateField';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { FC } from 'react';

interface IGeneralRelationshipFieldsProps {
	effectiveFrom: Moment | null;
	effectiveFromOnChange: (value: Moment | null) => void;
	effectiveTo: Moment | null;
	effectiveToOnChange: (value: Moment | null) => void;
	notes: string | null;
	notesOnChange: (value: string | null) => void;
	inputDisabled: boolean;
}

export const GeneralRelationshipFields: FC<IGeneralRelationshipFieldsProps> =
	observer(
		({
			effectiveFrom,
			effectiveTo,
			notes,
			effectiveFromOnChange,
			effectiveToOnChange,
			notesOnChange,
			inputDisabled,
		}) => {
			function getEffectiveFromError() {
				return getDateWarningMessage(
					'Effective From',
					effectiveFrom,
					effectiveTo ?? undefined,
				);
			}

			function getEffectiveToError() {
				return getDateWarningMessage('Effective To', effectiveTo);
			}

			return (
				<Grid container>
					<Grid container item columnSpacing={2} sx={{ marginTop: '16px' }}>
						<Grid item xs={6}>
							<T4DateField
								id={'relationship-effectivefrom'}
								label="Effective From"
								value={effectiveFrom}
								onChange={effectiveFromOnChange}
								disabled={inputDisabled}
							/>
						</Grid>
						<Grid item xs={6}>
							<T4DateField
								id={'relationship-effectiveto'}
								label="Effective To"
								value={effectiveTo}
								onChange={effectiveToOnChange}
								disabled={inputDisabled}
							/>
						</Grid>
						<Grid item xs={12} sx={{ marginTop: '8px' }}>
							<T4AlertStack warning={getEffectiveFromError()} />
							<T4AlertStack warning={getEffectiveToError()} />
						</Grid>
					</Grid>

					<Grid container item sx={{ marginTop: '16px' }}>
						<Grid item xs={12}>
							<T4TextFieldV2
								id="relationship-notes"
								label="Notes"
								value={notes}
								onChange={notesOnChange}
								multiline
								minRows={3}
								maxRows={6}
								disabled={inputDisabled}
								helperText={`${notes?.length ?? 0}/2048`}
							/>
						</Grid>
					</Grid>
				</Grid>
			);
		},
	);
