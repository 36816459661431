import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { ConnectionsListPage } from './accountIntegrationsPage/connectionsListPage';
import AccountsListRoute from './accountsList';

export const AccountIntegrationsPage: FC = observer(() => {
	const {
		cash4: { isIntegrationsAdmin },
	} = useUser();

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.accountIntegrationsPage}
					title="Account Integrations"
					disableGutters={true}
					aspects={[
						{
							label: 'Accounts',
							link: paths.cash4.accountIntegrations.accounts.href,
						},
						{
							label: 'Connections',
							link: paths.cash4.accountIntegrations.connections.href,
						},
					]}
				/>
			}
			hasAccess={isIntegrationsAdmin}
		>
			<Switch>
				<Route
					path={paths.cash4.accountIntegrations.accounts.href}
					component={AccountsListRoute}
					exact
				/>
				<Route
					path={paths.cash4.accountIntegrations.connections.href}
					component={ConnectionsListPage}
					exact
				/>
				<Route>
					<CannotDisplay
						headingText={NOT_FOUND_MESSAGING.HEADING}
						bodyText={NOT_FOUND_MESSAGING.BODY}
						imageSrc={NOT_FOUND_MESSAGING.IMAGE}
						buttonText={RETURN_TO_HOME}
						buttonHref={paths.root.href}
					/>
				</Route>
			</Switch>
		</T4View>
	);
});
