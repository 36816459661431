import {
	EntityType,
	entityTypeToEntityTypeId,
} from 'features/entity4/entity4Constants';
import { flow, makeAutoObservable } from 'mobx';
import {
	DataLists,
	GetDataListsRequest,
} from 'modules/clients/customer-api/src/api/referenceData';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { parseError } from 'utilities/errors/errorUtils';
import {
	FrontendRegistrationRepository,
	RegistrationListItem,
} from '../../frontendRegistrationRepository';
import { CreateRegistrationViewModel } from './createRegistrationViewModel';
import { DeleteRegistrationViewModel } from './deleteRegistrationViewModel';

const COUNTRIES_LISTNAME = 'Countries';
const STATEPROVINCE_LISTNAME = 'CountrySubdivisions';
const ENTITYLEGALFORMCODES_LISTNAME = 'EntityLegalFormCodes';
const REGISTRATIONSTATUS_LISTID = 'af802bb4-30d5-45c6-8e9b-6163e79aeb2f';

export type Registration = {
	id: string;
	isHomeRegistration: boolean;
	registrationNumber: string;
	country: string;
	state: string | null;
	county: string | null;
	city: string | null;
	status: string | null;
	expirationDate: string | null;
	entityLegalFormCode: string | null;
};

export class RegistrationListViewModel {
	private _entityId: string;
	private _fetchReferenceData: (
		request: GetDataListsRequest,
	) => Promise<DataLists | undefined>;
	private _frontendRegistrationRepository: FrontendRegistrationRepository;
	public createRegistrationModal: CreateRegistrationViewModel;
	public deleteRegistrationModal: DeleteRegistrationViewModel;

	private _entityLegalFormCodes: ReferenceDataValue[] = [];
	private _countries: ReferenceDataValue[] = [];
	private _stateProvinces: ReferenceDataValue[] = [];
	private _statuses: Option[] = [];

	private _error?: string;
	private _loading: boolean = true;
	private _registrations: RegistrationListItem[] = [];

	constructor(
		entityId: string,
		frontendRegistrationRepository: FrontendRegistrationRepository,
		fetchReferenceData: (
			request: GetDataListsRequest,
		) => Promise<DataLists | undefined>,
	) {
		makeAutoObservable(this);

		this._entityId = entityId;
		this._frontendRegistrationRepository = frontendRegistrationRepository;
		this._fetchReferenceData = fetchReferenceData;
		this.createRegistrationModal = new CreateRegistrationViewModel(
			entityId,
			frontendRegistrationRepository,
		);
		this.deleteRegistrationModal = new DeleteRegistrationViewModel(
			entityId,
			frontendRegistrationRepository,
		);
	}

	public load = flow(function* (this: RegistrationListViewModel) {
		this._error = undefined;
		this._loading = true;
		try {
			const response =
				yield this._frontendRegistrationRepository.getRegistrations(
					this._entityId,
				);
			this._registrations = response.value;

			const referenceResponse = yield this._fetchReferenceData({
				objectTypeId: entityTypeToEntityTypeId.get(EntityType.Entity)!,
				referenceListNames: [
					COUNTRIES_LISTNAME,
					STATEPROVINCE_LISTNAME,
					ENTITYLEGALFORMCODES_LISTNAME,
				],
				optionListIds: [REGISTRATIONSTATUS_LISTID],
			});
			if (referenceResponse) {
				this._entityLegalFormCodes =
					referenceResponse.referenceLists[ENTITYLEGALFORMCODES_LISTNAME] ?? [];
				this._countries =
					referenceResponse.referenceLists[COUNTRIES_LISTNAME] ?? [];
				this._stateProvinces =
					referenceResponse.referenceLists[STATEPROVINCE_LISTNAME] ?? [];
				this._statuses =
					referenceResponse.optionLists[REGISTRATIONSTATUS_LISTID]?.options ??
					[];
			}
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});

	public get loading(): boolean {
		return this._loading;
	}

	public get error(): string | undefined {
		return this._error;
	}

	public get entityId(): string {
		return this._entityId;
	}

	public get registrations(): Registration[] {
		return this._registrations.map((x) => ({
			id: x.id,
			isHomeRegistration: x.isHomeRegistration,
			registrationNumber: x.registrationNumber,
			country: x.country.displayName,
			state: x.state?.displayName ?? null,
			county: x.county,
			city: x.city,
			status: x.status?.displayName ?? null,
			expirationDate: x.expirationDate,
			entityLegalFormCode: x.entityLegalFormCode?.displayName ?? null,
		}));
	}

	public get entityLegalFormCodes(): string[] {
		return [...new Set(this._entityLegalFormCodes.map((x) => x.displayName))];
	}
	public get countries(): string[] {
		return [...new Set(this._countries.map((x) => x.displayName))];
	}
	public get stateProvinces(): string[] {
		return [...new Set(this._stateProvinces.map((x) => x.displayName))];
	}
	public get statuses(): string[] {
		return [...new Set(this._statuses.map((x) => x.displayName))];
	}

	public onAddRegistrationClick(isAuthor: boolean) {
		if (isAuthor) {
			this.createRegistrationModal.openModal();
		}
	}
}
