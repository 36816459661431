import { SwapHoriz } from '@mui/icons-material';
import {
	Divider,
	Grid,
	IconButton,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { observer } from 'mobx-react-lite';
import { Reconciliation } from 'modules/clients/customer-api/src/api/cash4';
import { FC, useCallback } from 'react';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { formatCurrency } from 'utilities/currencyUtils';
import { ReconciliationDrawer } from './_components/reconciliationDrawer';
import {
	calculateDifference,
	calculateTotal,
	calculateVariance,
	getCurrencyCode,
} from './_hooks/useReconciliationCalculations';
import { useReconciliationsContext } from './_providers/reconciliationsProvider';

export const ReconciliationsPage: FC = observer(() => {
	const {
		reconciliationQueryContext: { data: reconciliations },
		startReconciliationCreation,
		viewReconciliation,
		editReconciliation,
		deleteReconciliation,
	} = useReconciliationsContext();

	const onCreateReconciliationHandler = useCallback(() => {
		startReconciliationCreation();
	}, [startReconciliationCreation]);

	const onViewClickHandler = useCallback(
		(reconciliation: Reconciliation) => {
			if (reconciliation) {
				viewReconciliation(reconciliation);
			}
		},
		[viewReconciliation],
	);

	const onEditClickHandler = useCallback(
		(reconciliation: Reconciliation) => {
			if (reconciliation) {
				editReconciliation(reconciliation);
			}
		},
		[editReconciliation],
	);

	const onDeleteClickHandler = useCallback(
		(reconciliation: Reconciliation) => {
			if (reconciliation) {
				deleteReconciliation(reconciliation);
			}
		},
		[deleteReconciliation],
	);

	return (
		<T4View
			header={<PageHeader id={'reconciliations'} title="Reconciliations" />}
		>
			<Grid
				container
				item
				sx={{
					height: '100%',
					gap: 2,
					flexDirection: 'column',
					flexWrap: 'nowrap',
				}}
			>
				<Grid
					container
					item
					xs="auto"
					sx={{ gap: 1, justifyContent: 'flex-end' }}
				>
					<Grid item xs="auto">
						<T4Button
							variant="outlined"
							startIcon={<SwapHoriz />}
							onClick={onCreateReconciliationHandler}
							sx={{ marginTop: '8px' }}
						>
							Reconcile
						</T4Button>
					</Grid>
				</Grid>
				<Grid item xs={true}>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid<Reconciliation>
							stonlyId={'c4-reconciliations'}
							tableKey={'c4-reconciliations'}
							pagination
							autoPageSize
							showCustomViewButton
							showToolbar={true}
							hideFooter={false}
							rows={reconciliations}
							initialState={{
								sorting: {
									sortModel: [{ field: 'cratedOn', sort: 'desc' }],
								},
							}}
							pinnedColumns={{
								left: [USER_PREFERENCES_FIELD_OPTIONS],
							}}
							noRowsOverlay={() => {
								return (
									<Grid
										container
										sx={{
											height: '100%',
											gap: 2,
											flexDirection: 'column',
											alignContent: 'center',
											justifyContent: 'center',
										}}
									>
										<Grid item xs="auto" sx={{ alignSelf: 'center' }}>
											<IconButton
												disabled
												sx={(theme) => ({
													height: '6.25rem',
													width: '6.25rem',
													backgroundColor: theme.palette.cornflower[50],
													'&.Mui-disabled': {
														backgroundColor: theme.palette.cornflower[50],
													},
												})}
											>
												<SwapHoriz
													sx={(theme) => ({
														height: '5rem',
														width: '5rem',
														color: theme.palette.denim[500],
													})}
												/>
											</IconButton>
										</Grid>
										<Grid item xs="auto" sx={{ alignSelf: 'center' }}>
											<Typography variant="h3">No reconciliations</Typography>
										</Grid>
										<Grid item xs="auto">
											<Typography>
												{'Select '}
												<Typography
													component={'span'}
													onClick={onCreateReconciliationHandler}
													sx={(theme) => ({
														textDecoration: 'underline',
														cursor: 'pointer',
														color: theme.palette.secondary.main,
													})}
												>
													reconcile
												</Typography>
												{' to create records.'}
											</Typography>
										</Grid>
									</Grid>
								);
							}}
							columns={[
								{
									...getOptionsMenuColDef((params) => {
										return (
											<OverflowMenu id={'c4-reconciliations'}>
												<MenuItem
													onClick={() => onViewClickHandler(params.row)}
												>
													<ListItemText>View</ListItemText>
												</MenuItem>
												<MenuItem
													onClick={() => onEditClickHandler(params.row)}
												>
													<ListItemText>Edit</ListItemText>
												</MenuItem>
												<Divider />
												<DeleteMenuItem
													onClick={() => onDeleteClickHandler(params.row)}
												>
													Delete
												</DeleteMenuItem>
											</OverflowMenu>
										);
									}),
								},
								{
									field: 'status',
									headerName: 'Reconciliation status',
								},
								{
									field: '_with',
									headerName: 'Reconciled with',
									valueGetter: (params) => {
										return (
											(params.row.projectedItems.length ?? 0) +
											(params.row.reportedItems.length ?? 0)
										);
									},
									valueFormatter: (params) => {
										let formattedValue: string | undefined;
										if (params.value && typeof params.value === 'number') {
											const records = new Intl.NumberFormat('en-US').format(
												params.value,
											);
											formattedValue = `${records} records`;
										}

										return formattedValue;
									},
								},
								{
									field: '_variance',
									headerName: 'Variance',
									type: 'number',
									valueGetter: (params) => calculateVariance(params.row),
									valueFormatter: (params) =>
										`${Math.round((params.value ?? 0) * 10000) / 100}%`,
								},
								{
									field: '_variance_amount',
									headerName: 'Variance amount',
									headerAlign: 'right',
									align: 'right',
									type: 'number',
									valueGetter: (params) =>
										`${calculateDifference(params.row)} ${getCurrencyCode(
											params.row,
										)}`,
									valueFormatter: (params) => {
										const values = params.value.split(' ');

										return formatCurrency(values[0], {
											currency: values[1],
										});
									},
								},
								{
									field: '_reconciled_amount',
									headerName: 'Reconciled amount',
									align: 'right',
									headerAlign: 'right',
									type: 'number',
									valueGetter: (params) =>
										`${calculateTotal(params.row)} ${getCurrencyCode(
											params.row,
										)}`,
									valueFormatter: (params) => {
										const values = params.value.split(' ');

										return formatCurrency(values[0], {
											currency: values[1],
										});
									},
								},
								{
									field: 'currencyCode',
									headerName: 'Currency',
									valueGetter: (params) => getCurrencyCode(params.row),
								},
								{
									...getDateColumnDefinition(),
									field: 'createdOn',
									headerName: 'Created on',
								},
								{
									field: 'createdBy',
									headerName: 'Created by',
								},
							]}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>
			<ReconciliationDrawer />
		</T4View>
	);
});
