import { List, ListItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { LegalEntityGroupCollectionDrawer } from './legalEntityGroupCollectionDrawer';
import { LegalEntityGroupCollectionListItem } from './legalEntityGroupsPage';

export type LegalEntityGroupCollectionActionsProps = {
	isDrawerOpen: boolean;
	closeDrawer: () => void;
	isDeleteModalOpen: boolean;
	closeDeleteModal: () => void;
	collection?: LegalEntityGroupCollectionListItem;
};

export const LegalEntityGroupCollectionActions: FC<LegalEntityGroupCollectionActionsProps> =
	observer(
		({
			collection,
			isDrawerOpen,
			closeDrawer,
			isDeleteModalOpen,
			closeDeleteModal,
		}) => {
			const { enqueueSnackbar } = useSnackbar();
			const { isLoading, removeCollection } = useLegalEntityGroups();

			return (
				<>
					<LegalEntityGroupCollectionDrawer
						open={isDrawerOpen}
						closeDrawer={closeDrawer}
						id={collection?.id}
					/>

					{collection && !isDrawerOpen && (
						<FormModal
							open={isDeleteModalOpen}
							title="Remove collection"
							description={`Are you sure you want to delete "${
								collection.name
							}"? ${
								collection.groups!.length > 0
									? 'The following entity groups will be reassigned to the General Collection:'
									: ''
							}`}
							submitButtonColor="error"
							submitButtonLabel="Remove"
							submitDisabled={isLoading}
							onSubmit={async () => {
								const response = await removeCollection(collection.id);

								if (response.success) {
									enqueueSnackbar(
										`"${collection.name}" was successfully removed and entity groups have been reassigned to the General Collection.`,
										{
											variant: 'success',
										},
									);
								} else
									enqueueSnackbar(flattenErrors(response.errors), {
										variant: 'error',
									});

								closeDeleteModal();
							}}
							onClose={closeDeleteModal}
							loading={isLoading}
						>
							<List
								sx={{
									listStyleType: 'disc',
									listStylePosition: 'inside',
									fontSize: '.8rem',
									flexWrap: 'wrap',
									maxHeight: '400px',
								}}
							>
								{collection.groups!.map((group) => (
									<ListItem
										key={group.id}
										sx={{ display: 'list-item', paddingY: '4px' }}
									>
										{group.name}
									</ListItem>
								))}
							</List>
						</FormModal>
					)}
				</>
			);
		},
	);
