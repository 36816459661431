import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ObjectSubaccountPathParams, paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { EntityType } from '../entity4Constants';
import { useProfileView } from '../entityProfile/providers/entityProfileContextProvider';
import { SubAccountViewModel } from './Subaccounts/models/subAccountViewModel';
import { SubAccountView } from './Subaccounts/subAccountView';

export const SubObjectPageRoute: FC = observer(() => {
	const history = useHistory();
	const errorHandler = useErrorHandler();
	const { loadingObject, fetchObject } = useProfileView();
	const { objectType, objectId, subaccountId } =
		useParams<ObjectSubaccountPathParams>();
	const { fetch } = useReferenceDataFetcher();
	const { updateLastAutoSave, updateSubObjectName } = useHeader();
	const { customerApiClient } = useClients();
	const { data, error, isLoading } = useQuery({
		queryKey: ['object-aspects-subaccount'],
		refetchOnWindowFocus: false,
		queryFn: () =>
			customerApiClient.api.common.objectAspects({ objectType: 'Subaccount' }),
	});

	const [subAccount] = useState(
		new SubAccountViewModel(
			objectType,
			objectId,
			EntityType.Subaccount,
			subaccountId,
			history,
			fetch,
			updateLastAutoSave,
		),
	);

	useEffect(() => {
		updateSubObjectName('');
		subAccount
			.load()
			.catch((error) => errorHandler(error))
			.finally(() => {
				if (subAccount.name) {
					updateSubObjectName(subAccount.name);
				}
			});
	}, [subAccount, errorHandler, updateSubObjectName]);

	useEffect(() => {
		fetchObject(subaccountId);

		return () => {
			fetchObject();
		};
	}, [fetchObject, subaccountId]);

	if (
		error ||
		(data && data.data && data.data.error) ||
		(data &&
			data.data &&
			data.data.error &&
			Object.values(data.data.errors).length > 0)
	) {
		history.push(generatePath(paths.error.href));
	}

	return (
		<SubAccountView
			isLoading={loadingObject || isLoading}
			subAccount={subAccount}
			objectAspects={data?.data?.data ?? []}
		/>
	);
});
