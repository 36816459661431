import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';

export type UseT4QueryOptions = {
	errorMessage?: string;
};

export type UseT4QueryProps<TData> = {
	loading: boolean;
	data: TData | undefined;
	refetch: () => Promise<void>;
};

export function useT4Query<TData>(
	query: () => Promise<TData>,
	options?: UseT4QueryOptions,
): UseT4QueryProps<TData> {
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState<TData>();

	const refetch = useCallback(async () => {
		try {
			setData(await query());
		} catch {
			enqueueSnackbar(options?.errorMessage ?? 'Unable to fetch data.', {
				variant: 'error',
			});
		}
	}, [enqueueSnackbar, options?.errorMessage, query]);

	const initalize = useCallback(async () => {
		setLoading(true);
		refetch();
		setLoading(false);
	}, [refetch]);

	useEffect(() => {
		initalize();
	}, [initalize]);

	return {
		loading: loading,
		data: data,
		refetch: refetch,
	};
}
