import { Button, ButtonProps, styled } from '@mui/material';
import { FC } from 'react';

type T4ButtonType = 'tryAgain';
export interface T4ButtonProps
	extends Pick<
		ButtonProps,
		| 'color'
		| 'disabled'
		| 'endIcon'
		| 'id'
		| 'onClick'
		| 'size'
		| 'startIcon'
		| 'sx'
		| 'variant'
		| 'fullWidth'
		| 'title'
		| 'type'
	> {
	t4buttontype?: T4ButtonType;
}
export const T4Button: FC<T4ButtonProps> = ({ id, ...props }) => (
	<StyledButton
		id={`t4-button${id ? `-${id}` : ''}`}
		data-testid="t4-button"
		{...props}
	/>
);

interface StyledButtonProps extends ButtonProps {
	t4buttontype?: T4ButtonType;
}
const StyledButton = styled(Button)<StyledButtonProps>(
	({ theme, t4buttontype }) => ({
		...(t4buttontype === 'tryAgain' && {
			appearance: 'none',
			padding: 0,
			margin: 0,
			background: 'transparent',
			border: 0,
			fontSize: 'inherit',
			color: theme.palette.error.main,
			textDecoration: 'underline',
			cursor: 'pointer',

			'&:hover': {
				textDecoration: 'none',
			},
		}),
	}),
);
