import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { Errors } from 'utilities/errors/errorUtils';
import {
	CashFlowClass,
	CashFlowListItem,
	CashFlowType,
} from '../categoriesViewModel';

export type EditTypeErrors = Errors<{
	code: string;
	name: string;
}>;

export class EditTypeViewModel {
	private _refech: () => CancellablePromise<void>;

	private _loading: boolean = false;
	private _error?: string;
	private _errors: EditTypeErrors = { code: [], name: [] };
	private _open: boolean = false;
	private _classes: CashFlowClass[] = [];
	private _class?: CashFlowClass;
	private _type?: CashFlowType;
	private _code?: string;
	private _name?: string;

	constructor(refetch: () => CancellablePromise<void>) {
		makeAutoObservable(this);

		this._refech = refetch;
	}

	public isLoading = () => this._loading;
	public getError = () => this._error;
	public getCodeErrors = () => this._errors.code;
	public getNameErrors = () => this._errors.name;
	public isOpen = () => this._open;
	public isSubmitDisabled = () => !this._code || !this._name;
	public getClasses = () => this._classes;
	public getClass = () => this._class;
	public getCode = () => this._code;
	public getName = () => this._name;

	public setName = (value: string) => (this._name = !value ? undefined : value);
	public setCode = (value: string) =>
		(this._code = !value ? undefined : value.toUpperCase());

	public open(row: CashFlowListItem, classes?: CashFlowClass[]) {
		const theClass = classes?.find((_class) => _class.id === row.classId);
		const theType = theClass?.types.find((type) => type.id === row.id);

		this._classes = classes ?? [];
		this._class = theClass;
		this._type = theType;
		this._code = row.code;
		this._name = row.name;
		this._open = true;
	}

	public close() {
		this._loading = false;
		this._error = undefined;
		this._errors = { code: [], name: [] };
		this._classes = [];
		this._class = undefined;
		this._type = undefined;
		this._code = undefined;
		this._name = undefined;
		this._open = false;
	}

	public save = flow(function* (this: EditTypeViewModel) {
		try {
			this._loading = true;
			this._error = undefined;
			this._errors = { code: [], name: [] };

			const response: AxiosResponse<
				ApiResponse<CashFlowClass[]>
			> = yield customerApi.post<AxiosResponse<ApiResponse<CashFlowClass[]>>>(
				`/categories/${this._class?.id}/types/${this._type?.id}`,
				{
					code: this._code?.trim(),
					name: this._name?.trim(),
				}
			);

			if (response.data.errors) {
				this._errors = (response.data.errors as unknown) as EditTypeErrors;
			} else if (response.data.error) {
				this._error = response.data.error;
			} else {
				this._refech();
				this.close();
			}
		} catch (error) {
			this._error = (error as Error).message;
		} finally {
			this._loading = false;
		}
	});
}
