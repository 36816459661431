import { SnackbarProvider } from 'notistack';
import { FC } from 'react';

import { MaterialDesignContent } from 'notistack';
import { styled, useTheme } from '@mui/material';

export const T4SnackbarProvider: FC = ({ children }) => {
	const theme = useTheme();
	const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
		'&.notistack-MuiContent-info': {
			backgroundColor: theme.common?.denim?.main,
		},
	}));

	return (
		<SnackbarProvider
			Components={{
				info: StyledMaterialDesignContent,
			}}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom',
			}}
		>
			{children}
		</SnackbarProvider>
	);
};
