import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { CreateNewEntityField } from '../../../entities/objects/fields/entityField';
import { FieldSetViewProps } from '../fieldSetResolver';
import CommonFieldStore from '../fieldStore';
import { getFieldView } from '../fieldViewResolver';
import { useHeader } from 'shared/providers/contexts/headerContext';

export const SingleFieldSetView: React.FC<FieldSetViewProps> = observer(
	({ fieldSetDefinition, entity }) => {
		const { updateLastAutoSave } = useHeader();
		const field = fieldSetDefinition.fields[0];
		if (!field) return null;

		const View = getFieldView(field);

		const fieldData = useMemo(() => {
			return (
				entity.getField(field.id) ||
				CreateNewEntityField(
					entity,
					field.id,
					field.fieldType,
					updateLastAutoSave,
				)
			);
		}, [entity, field.fieldType, field.id, updateLastAutoSave]);

		const parentField = field.parentFieldIdentifier
			? entity.getField(field.parentFieldIdentifier)
			: undefined;

		const parentFieldDefinition = field.parentFieldIdentifier
			? CommonFieldStore.fieldToParentDefinition.get(field.identifier)
			: undefined;

		return entity ? (
			<View
				fieldDefinition={field}
				fieldData={fieldData}
				parentField={parentField}
				parentFieldDefinition={parentFieldDefinition}
				createField={() => {
					/* does not need to be implemented yet */
					/* We did not modify the lifecycle of a field, only lifecycle of a collection field */
				}}
			/>
		) : null;
	},
);
