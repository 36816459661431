import {
	GridRenderEditCellParams,
	GridValidRowModel,
	useGridApiContext,
} from '@mui/x-data-grid-pro';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { observer } from 'mobx-react-lite';
import {
	LegalEntityGroup,
	LegalEntityGroupCollection,
} from 'modules/clients/customer-api/src/entity4/legalEntityGroups';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { T4MultiSelectAutocomplete } from 'shared/components/autocompletes/t4MultiselectAutocomplete';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { IListColumnDto } from '../../shared/repositories/frontendRepository';
import { Tag } from '../../../../modules/clients/customer-api/src/entity4/tags';

// EDIT CELL COMPONENTS
export const OptionEditCellDropdown: FC<{
	params: GridRenderEditCellParams;
	definition: IListColumnDto;
	options: Option[];
}> = observer(({ params, definition, options }) => {
	const gridContext = useGridApiContext();

	const handleOnSelect = (value: Option | null) => {
		gridContext.current.setEditCellValue({
			id: params.id,
			field: params.field,
			value: value,
		});
	};

	const value = params.row[`${definition.identifier}_data`] as Option;

	return (
		<T4Autocomplete<Option, false, boolean, false>
			id={definition.identifier}
			options={options}
			value={value ?? null}
			onSelect={handleOnSelect}
			getOptionLabel={(option: Option) => option.displayName}
			isOptionEqualToValue={(option: Option, value: Option) =>
				option.id === value?.id
			}
			disableClearable={definition.isCreationRequirement}
		/>
	);
});

export const ReferenceEditCellDropdown: FC<{
	params: GridRenderEditCellParams;
	definition: IListColumnDto;
	options: ReferenceDataValue[];
}> = observer(({ params, definition, options }) => {
	const gridApiContext = useGridApiContext();

	const handleOnSelect = (value: ReferenceDataValue | null) => {
		gridApiContext.current.setEditCellValue({
			id: params.id,
			field: params.field,
			value: value,
		});
	};

	const value = params.row[
		`${definition.identifier}_data`
	] as ReferenceDataValue;
	const parentValue = definition.parentFieldIdentifier
		? params.row[`${definition.parentFieldIdentifier}_data`]?.identifier
		: undefined;

	const currentOptions =
		parentValue !== undefined
			? options.filter((x) => x.parentIdentifier === parentValue)
			: options;

	return (
		<T4Autocomplete<ReferenceDataValue, false, boolean, false>
			id={definition.identifier}
			options={currentOptions}
			value={value ?? null}
			onSelect={handleOnSelect}
			getOptionLabel={(option: ReferenceDataValue) => option.displayName}
			isOptionEqualToValue={(a: ReferenceDataValue, b: ReferenceDataValue) =>
				a?.identifier === b?.identifier
			}
			disableClearable={definition.isCreationRequirement}
		/>
	);
});

export const LegalEntityGroupsEditCellDropdown: FC<{
	params: GridRenderEditCellParams<GridValidRowModel>;
	options: LegalEntityGroupCollection[];
}> = ({ params, options }) => {
	type LegalEntityGroupOption = {
		id: string;
		collectionName: string;
		name: string;
	};
	const gridApiContext = useGridApiContext();

	const legalEntityGroups = useMemo<LegalEntityGroup[]>(
		() => [...options.flatMap((collection) => collection.legalEntityGroups)],
		[options],
	);

	const legalEntityGroupOptions = useMemo<LegalEntityGroupOption[]>(
		() => [
			...options.flatMap((collection) =>
				collection.legalEntityGroups.map((x) => ({
					id: x.id,
					collectionName: collection.name,
					name: x.name,
				})),
			),
		],
		[options],
	);

	const originalLegalEntityGroupOptions: LegalEntityGroupOption[] =
		useMemo(() => {
			const originalIds =
				params.row.legalEntityGroups?.map((x: LegalEntityGroup) => x.id) ?? [];
			return legalEntityGroupOptions.filter((x) => originalIds.includes(x.id));
		}, [params.row, legalEntityGroupOptions]);

	const [selectedLegalEntityGroupOptions, setSelectedLegalEntityGroupOptions] =
		useState<LegalEntityGroupOption[]>(originalLegalEntityGroupOptions);

	const update = useCallback(
		(values: LegalEntityGroupOption[]) => {
			setSelectedLegalEntityGroupOptions(values);
			gridApiContext.current.setEditCellValue({
				id: params.id,
				field: params.field,
				value: legalEntityGroups.filter((x) =>
					values.map((y) => y.id).includes(x.id),
				),
			});
		},
		[params.id, params.field, gridApiContext, legalEntityGroups],
	);

	useEffect(() => {
		return () => {
			setSelectedLegalEntityGroupOptions([]);
		};
	}, []);

	return (
		<T4MultiSelectAutocomplete<LegalEntityGroupOption>
			options={legalEntityGroupOptions}
			value={selectedLegalEntityGroupOptions}
			getOptionKey={(option) => option.id}
			getOptionLabel={(x) => x.name}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			groupBy={(option) => option.collectionName}
			onChange={(_, values) => {
				update(values);
			}}
		/>
	);
};

export const EntityObjectTagsEditCellDropdown: FC<{
	params: GridRenderEditCellParams<GridValidRowModel>;
	options: Tag[];
}> = ({ params, options }) => {
	const gridApiContext = useGridApiContext();

	const update = useCallback(
		(values: Tag[]) => {
			gridApiContext.current.setEditCellValue({
				id: params.id,
				field: params.field,
				value: values,
			});
		},
		[params.id, params.field, gridApiContext],
	);

	return (
		<T4MultiSelectAutocomplete<Tag>
			options={options}
			value={params.row.tags}
			getOptionKey={(tag: Tag) => tag.tagId}
			getOptionLabel={(tag: Tag) => tag.name}
			isOptionEqualToValue={(tag: Tag, value: Tag) => tag.tagId === value.tagId}
			onChange={(_, values: Tag[]) => {
				update(values);
			}}
		/>
	);
};
