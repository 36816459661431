import { action, makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { formatDate } from 'shared/utilities/dateUtilities';
import { isDateEqual } from '../../entities/utilities/dateUtilities';
import { ValueStatus } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import CommonRegistrationStore, {
	RegistrationStore,
} from '../registrationStore';
import { IRegisteredAgentDto, IRegistrationDto } from '../registrationTypes';

const maybeMoment = (str: string | null | undefined) =>
	Boolean(str) ? moment(str) : null;

export class Registration {
	private _store: RegistrationStore;
	private _dto: IRegistrationDto;
	private _error?: string;

	public expirationDate: Moment | null = null;
	public lastFiledDate: Moment | null = null;
	public registrationDate: Moment | null = null;
	public dissolutionDate: Moment | null = null;

	public options = null;

	private _fieldStatus: Map<keyof IRegistrationDto, ValueStatus>;

	public static dateKeys = [
		'expirationDate',
		'lastFiledDate',
		'registrationDate',
		'dissolutionDate',
	];

	constructor(
		dto: IRegistrationDto,
		store: RegistrationStore = CommonRegistrationStore
	) {
		this._store = store;

		this._dto = {
			entityId: dto.entityId,
			homeRegistrationDefined: dto.homeRegistrationDefined,
			isHomeRegistration: dto.isHomeRegistration,
			registrationId: dto.registrationId,
		};
		this.sync(dto);

		this._fieldStatus = new Map();

		makeAutoObservable(this, {
			asDto: false,
			sync: action,
			remove: action,
			update: action,
		});
	}

	public static make(dto: IRegistrationDto) {
		const registration = new Registration(dto);
		return registration;
	}

	public sync(dto: IRegistrationDto): void {
		const { entityId, registrationId, registrationVersionId, ...safeFields } =
			dto;

		this._dto = {
			...this._dto,
			...safeFields,
		};

		this.expirationDate = maybeMoment(safeFields.expirationDate);
		this.lastFiledDate = maybeMoment(safeFields.lastFiledDate);
		this.registrationDate = maybeMoment(safeFields.registrationDate);
		this.dissolutionDate = maybeMoment(safeFields.dissolutionDate);
	}

	public asDto() {
		return {
			...this._dto,
			expirationDate: formatDate(this.expirationDate),
			lastFiledDate: formatDate(this.lastFiledDate),
			registrationDate: formatDate(this.registrationDate),
			dissolutionDate: formatDate(this.dissolutionDate),
		} as IRegistrationDto;
	}

	public remove() {
		this._store.remove(this);
	}

	public error() {
		return this._error;
	}

	public get id(): string {
		return this._dto.registrationId;
	}

	public get entityId(): string {
		return this._dto.entityId;
	}

	public get entityCode(): string | undefined {
		return this._dto.entityCode;
	}

	public get registrationNumber(): string {
		return this._dto.registrationNumber!;
	}

	public get isHomeRegistration(): boolean {
		return this._dto.isHomeRegistration;
	}

	public get statusId(): string | undefined {
		return this._dto.statusId;
	}

	public get assumedName(): string {
		return this._dto.assumedName!;
	}

	public get registrationCountry(): string {
		return this._dto.registrationCountry!;
	}

	public get registrationState(): string {
		return this._dto.registrationState!;
	}

	public get registrationCounty(): string | null {
		return this._dto.registrationCounty!;
	}

	public get registrationCity(): string | null {
		return this._dto.registrationCity!;
	}

	public get registrationLegalForm(): string {
		return this._dto.registrationLegalForm!;
	}

	public get registrationAuthority(): string {
		return this._dto.registrationAuthority!;
	}

	public get registeredAgent(): IRegisteredAgentDto | null {
		return this._dto.registeredAgent!;
	}

	public get approvedDate(): Moment {
		return maybeMoment(this._dto.approvedDate)!;
	}

	public get approvedBy(): string {
		return this._dto.approvedBy!;
	}

	public get updatedDate(): Moment {
		return maybeMoment(this._dto.updatedDate)!;
	}

	public get updatedBy(): string {
		return this._dto.updatedBy!;
	}

	public async update<T extends keyof IRegistrationDto>(
		key: T,
		value: IRegistrationDto[T]
	): Promise<IRegistrationDto> {
		const origValue = this._dto[key];
		try {
			this._dto[key] = value;
			this.setIsWaiting(key);
			await this._store.update(this, key, value);
			this.setIsSaved(key);
		} catch (err) {
			this._dto[key] = origValue;
			this.setIsErrored(key);
			throw err;
		}

		return this._dto;
	}

	public isDirty<T extends keyof IRegistrationDto>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Dirty;
	}

	public isWaiting<T extends keyof IRegistrationDto>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Waiting;
	}

	public isSaved<T extends keyof IRegistrationDto>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Saved;
	}

	public isErrored<T extends keyof IRegistrationDto>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Errored;
	}

	public setIsDirty<T extends keyof IRegistrationDto>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Dirty);
	}

	public setIsWaiting<T extends keyof IRegistrationDto>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Waiting);
	}

	public setIsSaved<T extends keyof IRegistrationDto>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Saved);
	}

	public setIsErrored<T extends keyof IRegistrationDto>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Errored);
	}

	public changeDate(
		key:
			| 'expirationDate'
			| 'lastFiledDate'
			| 'registrationDate'
			| 'dissolutionDate',
		value: Moment | null
	) {
		if (!isDateEqual(value, this._dto[key])) {
			this[key] = value;
			const str = formatDate(value);
			this._dto[key] = str;
			this.update(key, str);
		}
	}
}
