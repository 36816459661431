import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { Registration } from '../models/registration';
import { RegistrationFieldAdornment } from './registrationAdornment';
import { useHeader } from 'shared/providers/contexts/headerContext';

export interface IRegistrationTextInputProps {
	registration: Registration;
	fieldKey:
		| 'assumedName'
		| 'registrationNumber'
		| 'registrationCounty'
		| 'registrationCity';
	isReadOnly: boolean;
	label: string;
	description: string;
}

export const RegistrationTextInput: React.FC<IRegistrationTextInputProps> =
	observer(({ registration, fieldKey, label, description, isReadOnly }) => {
		const { updateLastAutoSave } = useHeader();
		const [value, setValue] = useState(registration[fieldKey]);

		const disabled = isReadOnly;

		const saveValue = async () => {
			if (registration.isDirty(fieldKey)) {
				await registration
					.update(
						fieldKey,
						!isStringUndefinedOrNullOrWhitespace(value) ? value : null,
					)
					.finally(() => updateLastAutoSave());
				registration.setIsSaved(fieldKey);
			}
		};

		return (
			<T4TextFieldV2
				label={label}
				value={value}
				disabled={disabled}
				onChange={(value) => {
					registration.setIsDirty(fieldKey);
					setValue(value);
				}}
				onBlur={saveValue}
				InputProps={{
					startAdornment: (
						<RegistrationFieldAdornment
							isSaved={registration.isSaved(fieldKey)}
							isLoading={registration.isWaiting(fieldKey)}
							description={description}
						/>
					),
				}}
			/>
		);
	});
