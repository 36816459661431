import { Grid } from '@mui/material';
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	GridToolbarProps,
	GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { stonlyData } from '../../../stonly/functions';

export const T4Toolbar: FC<GridToolbarProps> = observer(
	({
		showQuickFilter,
		quickFilterProps,
		csvOptions,
		customViewButton,
		hideExport = false,
		stonlyId,
	}) => {
		return (
			<GridToolbarContainer>
				<Grid container sx={{ justifyContent: 'space-between' }}>
					<Grid container item xs="auto">
						<Grid item xs="auto">
							<GridToolbarColumnsButton
								{...stonlyData({
									id: 'table-column-button',
								})}
							/>
						</Grid>
						<Grid item xs="auto">
							<GridToolbarFilterButton
								{...stonlyData({
									id: 'table-filter-button',
								})}
							/>
						</Grid>
						{customViewButton && (
							<Grid item xs="auto">
								{customViewButton}
							</Grid>
						)}
						{!hideExport && (
							<Grid item xs="auto">
								<GridToolbarExport
									csvOptions={csvOptions}
									{...stonlyData({
										id: 'table-export-button',
									})}
								/>
							</Grid>
						)}
					</Grid>
					{showQuickFilter && (
						<Grid container item xs={true} sx={{ justifyContent: 'flex-end' }}>
							<GridToolbarQuickFilter
								{...quickFilterProps}
								sx={{
									maxWidth: '280px',
									minWidth: '150px',
								}}
								{...stonlyData({
									id: 'table-search-input',
								})}
							/>
						</Grid>
					)}
				</Grid>
			</GridToolbarContainer>
		);
	},
);
