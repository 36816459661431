import { AxiosInstance } from 'axios';
import { Write } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Collection } from '../..';
import { CreateCollection } from '../../collections';
import { SubaccountInput } from '../fields';
import {
	SubaccountCollectionFieldEndpoints,
	subaccountCollectionFields,
} from './fields';

//#region Request Models

export type CreateCollectionInput = SubaccountInput & CreateCollection;

export type DeleteCollectionInput = SubaccountInput & {
	collectionId: string;
};

//#endregion

export type SubaccountCollectionEndpoints = {
	create: Write<
		ApiResponse<Collection>,
		CreateCollectionInput,
		CreateCollection
	>;
	delete: Write<ApiResponse<string>, DeleteCollectionInput, undefined>;
	fields: SubaccountCollectionFieldEndpoints;
};

export function subaccountCollections(
	axiosInstance: AxiosInstance,
): SubaccountCollectionEndpoints {
	return {
		fields: subaccountCollectionFields(axiosInstance),
		create: async (input, config = {}) =>
			await axiosInstance.post(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/collections`,
				{
					collectionIdentifier: input.collectionIdentifier,
					parentCollectionId: input.parentCollectionId,
					fieldUpdates: input.fieldUpdates,
					phoneNumberFieldUpdates: input.phoneNumberFieldUpdates,
				},
				{
					...config,
				},
			),
		delete: async (input, config = {}) =>
			await axiosInstance.delete(
				`/${input!.objectType}/${input!.objectId}/subaccount/${
					input!.subaccountId
				}/collections/${input!.collectionId}`,
				{
					...config,
				},
			),
	};
}
