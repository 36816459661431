import {
	Breadcrumbs,
	Link as MUILink,
	Typography,
	useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { stonlyData } from 'stonly/functions';

export interface IBreadcrumb {
	label: string;
	href?: string;
}

export const dataTestIds = {
	trailingSlash: 'trailing-slash',
};

export interface BreadcrumbListProps {
	breadcrumbs: IBreadcrumb[];
}

export const BreadcrumbList: FC<BreadcrumbListProps> = observer(
	({ breadcrumbs }) => {
		const theme = useTheme();

		return (
			<Breadcrumbs
				aria-label="breadcrumb"
				separator={
					<Typography sx={{ color: theme.palette.grey[50] }}>/</Typography>
				}
			>
				{breadcrumbs
					.map((item, index) => {
						return item.href ? (
							<MUILink
								key={`${item.label.toLowerCase()}-${index}`}
								{...stonlyData({
									id: `${item.label.toLowerCase()}-breadcrumb`,
								})}
								role="breadcrumb"
								component={Link}
								to={item.href}
								color={theme.palette.grey[50]}
								sx={{ textDecoration: 'none' }}
							>
								{item.label}
							</MUILink>
						) : (
							<Typography
								key={`${item.label.toLowerCase()}-${index}`}
								sx={{ color: theme.palette.grey[50] }}
							>
								{item.label}
							</Typography>
						);
					})
					.concat(
						<Typography
							data-testid={dataTestIds.trailingSlash}
							key={dataTestIds.trailingSlash}
						/>,
					)}
			</Breadcrumbs>
		);
	},
);
