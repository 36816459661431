import { useEffect, useState } from 'react';

export type ClientWindowSize = {
	height: number;
	width: number;
};

export function useWindowSize() {
	const [size, setSize] = useState<ClientWindowSize>({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	useEffect(() => {
		window.addEventListener('resize', () => {
			setSize({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		});
	}, []);

	return {
		height: size.height,
		width: size.width,
	};
}
