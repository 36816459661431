import {
	BackupTable,
	Balance,
	Input,
	SettingsInputComponent,
	TrendingUp,
} from '@mui/icons-material';
import { SigmaPage } from 'features/shared/sigma/sigmaPage';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import {
	Redirect,
	Route,
	Switch,
	generatePath,
	useLocation,
} from 'react-router-dom';
import { Layout } from 'shared/components/layout';
import { Navbar } from 'shared/components/navbar';
import { NavigationItemProps } from 'shared/components/navigation/navigationItem';
import { NavigationMenu } from 'shared/components/navigation/navigationMenu';
import SolutionPicker from 'shared/components/solutionPicker/solutionPicker';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { T4Solution } from 'shared/t4Solutions';
import { CannotDisplay } from '../../shared/components/cannotDisplay';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from '../../shared/constants/cannotDisplayMessaging';
import { AccountIntegrationsPage } from './accountIntegrations/accountIntegrationsPage';
import AnalyticsStudioPage from './analyticsStudio/analyticsStudioPage';
import { BalancesPage } from './balances/balancesPage';
import { CategoriesRoute } from './categories/categoriesPage';
import { DataImportsPage } from './dataImports/dataImportsPage';
import HoldingsRoute from './investments/holdings/holdingsPage';
import InvestmentTransactionsRoute from './investments/transactions/transactionsPage';
import { ReconciliationsProvider } from './reconciliations/_providers/reconciliationsProvider';
import { ReconciliationsPage } from './reconciliations/reconciliationsPage';
import { DataProvider } from './rules/createRuleModal/providers/DataProvider';
import { RulesRoute } from './rules/rulesPage';
import { TransactionsProvider } from './transactions/providers/useTransactions';
import { TransactionsPage } from './transactions/transactionsPage';

export const Cash4Layout: FC = observer(() => {
	const location = useLocation();
	const {
		cash4: { isAuthor, isViewer, isDataImporter, isIntegrationsAdmin },
	} = useUser();
	const {
		sigmaStudioEnabled,
		investmentTransactionsEnabled,
		projectedTransactionsEnabled,
	} = useT4FeatureFlags();

	const [selectedItem, setSelectedItem] = useState('');
	const [navigationItems, setNavigationItems] = useState<NavigationItemProps[]>(
		[],
	);

	useEffect(() => {
		const path = location.pathname;
		if (path.includes(paths.cash4.transactions.href)) {
			setSelectedItem('transactions');
		} else if (path.includes(paths.cash4.rules.href)) {
			setSelectedItem('rules');
		} else if (path.includes(paths.cash4.investments.holdings.href)) {
			setSelectedItem('holdings');
		} else if (path.includes(paths.cash4.investments.transactions.href)) {
			setSelectedItem('investment-transactions');
		} else if (path.includes(paths.cash4.categories.href)) {
			setSelectedItem('categories');
		} else if (path.includes(paths.cash4.analyticsStudioBeta.href)) {
			setSelectedItem('sigmaStudio');
		} else if (path.includes(paths.cash4.analytics.href)) {
			setSelectedItem('analyticsStudio');
		} else if (path.includes(paths.cash4.accountIntegrations.href)) {
			setSelectedItem('accountIntegrations');
		} else if (path.includes(paths.cash4.dataImports.href)) {
			setSelectedItem('dataImports');
		} else if (path.includes(paths.cash4.balances.href)) {
			setSelectedItem('balances');
		} else if (path.includes(paths.cash4.reconciliations.href)) {
			setSelectedItem('reconciliations');
		} else {
			setSelectedItem('');
		}
	}, [location]);

	useEffect(() => {
		const cash4Items = [
			{
				id: 'balances-sidebar-navigation',
				to: generatePath(paths.cash4.balances.href),
				label: 'Balances',
				selected: selectedItem === 'balances',
			},
			{
				id: 'transactions-sidebar-navigation',
				to: generatePath(paths.cash4.transactions.href),
				label: 'Transactions',
				selected: selectedItem === 'transactions',
			},
		];
		if (projectedTransactionsEnabled) {
			cash4Items.push({
				id: 'reconciliations-sidebar-navigation',
				to: generatePath(paths.cash4.reconciliations.href),
				label: 'Reconciliations',
				selected: selectedItem === 'reconciliations',
			});
		}
		cash4Items.push(
			{
				id: 'categories-sidebar-navigation',
				to: generatePath(paths.cash4.categories.href),
				label: 'Categories',
				selected: selectedItem === 'categories',
			},
			{
				id: 'rules-sidebar-navigation',
				to: generatePath(paths.cash4.rules.href),
				label: 'Rules',
				selected: selectedItem === 'rules',
			},
		);

		let items: NavigationItemProps[] = [
			{
				id: 'cash4-sidebar-navigation',
				label: 'Cash',
				icon: <Balance />,
				children: cash4Items,
			},

			{
				id: 'investments-sidebar-navigation',
				label: 'Investments',
				icon: <TrendingUp />,
				children: [
					{
						id: 'holdings-sidebar-navigation',
						to: generatePath(paths.cash4.investments.holdings.href),
						label: 'Holdings',
						selected: selectedItem === 'holdings',
					},
				],
			},
		];

		if (investmentTransactionsEnabled) {
			items[items.length - 1].children!.push({
				id: 'investment-transactions-sidebar-navigation',
				to: generatePath(paths.cash4.investments.transactions.href),
				label: 'Transactions',
				selected: selectedItem === 'investment-transactions',
			});
		}

		if (sigmaStudioEnabled) {
			items.push({
				id: 'analytics-sidebar-navigation',
				label: 'Analytics Studio',
				icon: <BackupTable />,
				children: [
					{
						id: 'analytics-studio-sidebar-navigation',
						to: generatePath(paths.cash4.analytics.href),
						label: 'Classic',
						selected: selectedItem === 'analyticsStudio',
					},
					{
						id: 'analytics-studio-beta-sidebar-navigation',
						to: generatePath(paths.cash4.analyticsStudioBeta.href),
						label: 'Beta',
						selected: selectedItem === 'sigmaStudio',
					},
				],
			});
		} else {
			items.push({
				id: 'analytics-studio-sidebar-navigation',
				to: generatePath(paths.cash4.analytics.href),
				label: 'Analytics Studio',
				icon: <BackupTable />,
				selected: selectedItem === 'analyticsStudio',
			});
		}
		if (isIntegrationsAdmin) {
			items.push({
				id: 'account-integrations-sidebar-navigation',
				to: generatePath(paths.cash4.accountIntegrations.accounts.href),
				label: 'Account Integrations',
				icon: <SettingsInputComponent />,
				selected: selectedItem === 'accountIntegrations',
			});
		}

		if (isDataImporter) {
			items.push({
				id: 'data-imports-navigation',
				to: generatePath(paths.cash4.dataImports.href),
				label: 'Data Imports',
				icon: <Input />,
				selected: selectedItem === 'dataImports',
			});
		}

		setNavigationItems(items);
	}, [
		isIntegrationsAdmin,
		isDataImporter,
		selectedItem,
		sigmaStudioEnabled,
		investmentTransactionsEnabled,
		location.pathname,
		projectedTransactionsEnabled,
	]);

	const accessDeniedPage = (
		<CannotDisplay
			headingText={ACCESS_DENIED_MESSAGING.HEADING}
			bodyText={ACCESS_DENIED_MESSAGING.BODY}
			imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
		/>
	);

	return (
		<Layout
			appBar={
				<Navbar logo={<SolutionPicker activeSolution={T4Solution.Cash4} />} />
			}
			navElements={
				<NavigationMenu
					sections={[
						{
							navigationItems: navigationItems,
						},
					]}
				/>
			}
		>
			{isAuthor || isViewer ? (
				<Switch>
					<Route path={paths.cash4.href} exact>
						<Redirect to={paths.cash4.balances.href} />
					</Route>
					<Route
						path={paths.cash4.balances.href}
						component={BalancesPage}
						exact
					/>
					<Route
						path={`${paths.cash4.balances.href}/:balanceId`.concat(
							validIdRegex,
						)}
						component={BalancesPage}
						exact
					/>
					<Route path={paths.cash4.transactions.href} exact>
						<DataProvider>
							<ReconciliationsProvider>
								<TransactionsProvider>
									<TransactionsPage />
								</TransactionsProvider>
							</ReconciliationsProvider>
						</DataProvider>
					</Route>
					<Route
						path={`${paths.cash4.transactions.href}/:transactionId`.concat(
							validIdRegex,
						)}
					>
						<DataProvider>
							<ReconciliationsProvider>
								<TransactionsProvider>
									<TransactionsPage />
								</TransactionsProvider>
							</ReconciliationsProvider>
						</DataProvider>
					</Route>
					{projectedTransactionsEnabled && (
						<Route path={paths.cash4.reconciliations.href}>
							<DataProvider>
								<ReconciliationsProvider>
									<ReconciliationsPage />
								</ReconciliationsProvider>
							</DataProvider>
						</Route>
					)}
					<Route
						path={`${paths.cash4.projectedTransactions.href}/:projectionId`.concat(
							validIdRegex,
						)}
					>
						<DataProvider>
							<ReconciliationsProvider>
								<TransactionsProvider>
									<TransactionsPage />
								</TransactionsProvider>
							</ReconciliationsProvider>
						</DataProvider>
					</Route>
					<Route
						path={paths.cash4.categories.href}
						component={CategoriesRoute}
						exact
					/>
					<Route path={paths.cash4.rules.href} component={RulesRoute} exact />
					<Route
						path={`${paths.cash4.rules.href}/:ruleId`.concat(validIdRegex)}
						component={RulesRoute}
					/>
					<Route
						path={`${paths.cash4.rules.href}/create`}
						component={RulesRoute}
						exact
					/>
					<Route
						path={paths.cash4.investments.holdings.href}
						component={HoldingsRoute}
						exact
					/>
					<Route
						path={paths.cash4.investments.transactions.href}
						component={InvestmentTransactionsRoute}
						exact
					/>
					<Route
						path={paths.cash4.analytics.href}
						component={AnalyticsStudioPage}
						exact
					/>
					<Route path={paths.cash4.analyticsStudioBeta.href} exact>
						<SigmaPage />
					</Route>
					<Route path={paths.cash4.accountIntegrations.href}>
						{isIntegrationsAdmin ? (
							<AccountIntegrationsPage />
						) : (
							accessDeniedPage
						)}
					</Route>
					<Route path={paths.cash4.dataImports.href} exact>
						{isDataImporter ? <DataImportsPage /> : accessDeniedPage}
					</Route>
					<Route>
						<CannotDisplay
							headingText={NOT_FOUND_MESSAGING.HEADING}
							bodyText={NOT_FOUND_MESSAGING.BODY}
							imageSrc={NOT_FOUND_MESSAGING.IMAGE}
							buttonText={RETURN_TO_HOME}
							buttonHref={paths.root.href}
						/>
					</Route>
				</Switch>
			) : (
				accessDeniedPage
			)}
		</Layout>
	);
});
