import { AxiosInstance } from 'axios';
import { Read, Write } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { CollectionEndpoints, collections } from './collections';
import { ObjectFieldEndpoints, objectFields } from './fields';
import { SubaccountEndpoints, subaccounts } from './subaccounts';

//#region Models

export enum ObjectType {
	entity = 1,
	partner = 2,
	counterparty = 3,
	staff = 4,
	account = 5,
	subaccount = 6,
}

export enum FieldType {
	text = 0,
	boolean = 1,
	date = 2,
	option = 3,
	phoneNumber = 4,
	longText = 5,
	address = 6,
}

export type Object = {
	id: string;
	entityTypeId: ObjectType;
	parentEntityId?: string;
	parentEntityTypeId?: ObjectType;
	fields: Field[];
	collections: Collection[];
};

export type Field = {
	id: string;
	identifier: string;
	fieldType: FieldType;
	updatedBy: string;
	updatedDate: string;
	approvedBy?: string;
	approvedDate?: string;
	rejectedBy?: string;
	rejectedDate?: string;
	rejectedComment?: string;
	submittedDate?: string;
	approvedValue?: any;
	approvedOptionSourceId?: string;
	pendingValue?: any;
	pendingOptionSourceId?: string;
	// displayValue: string;
	approvedPhoneNumber?: PhoneNumber;
	pendingPhoneNumber?: PhoneNumber;
};

export type PhoneNumber = {
	phoneNumberId: string;
	countryCode?: string;
	number?: string;
	extension?: string;
};

export type Collection = {
	id: string;
	identifier: string;
	parentId?: string;
	createdDate: string;
	fields: Field[];
};

//#endregion

//#region Request Models

export type UpdateField = {
	fieldIdentifier: string;
	value: any;
};

export type ObjectsInput = {
	objectType:
		| 'entity'
		| 'partner'
		| 'counterparty'
		| 'staff'
		| 'account'
		| 'subaccount';
};

export type ObjectInput = ObjectsInput & {
	objectId: string;
};

export type UpdateObjectInput = ObjectInput & {
	fieldIdentifier: string;
	value: any;
};

//#endregion

export type ObjectsEndpoints = {
	all: Read<Object[], ObjectsInput>;
	get: Read<ApiResponse<Object>, ObjectInput>;
	update: Write<ApiResponse<Field>, UpdateObjectInput, UpdateField>;
	fields: ObjectFieldEndpoints;
	collections: CollectionEndpoints;
	subaccounts: SubaccountEndpoints;
};

export function objects(axiosInstance: AxiosInstance): ObjectsEndpoints {
	return {
		all: async (input) => await axiosInstance.get(`/${input!.objectType}`),
		get: async (input) =>
			await axiosInstance.get(`/${input!.objectType}/${input!.objectId}`),
		update: async (input, config = {}) =>
			await axiosInstance.patch(`/${input!.objectType}/${input!.objectId}`, {
				...config,
				data: {
					fieldIdentifier: input.fieldIdentifier,
					value: input.value,
				},
			}),
		fields: objectFields(axiosInstance),
		collections: collections(axiosInstance),
		subaccounts: subaccounts(axiosInstance),
	};
}
