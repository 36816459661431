import { AxiosInstance } from 'axios';
import { T4DataResponse, T4Response } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Write } from '../../../transactions';

//#region Models

export type Subaccount = T4DataResponse<{
	id: string;
	code: string;
}>;

//#endregion

//#region Responses

export type DeleteSubaccountRequest = {
	accountId: string;
	id: string;
};
export type DeleteSubaccountResponse = T4Response<
	ApiResponse<Subaccount>,
	DeleteSubaccountRequest
>;

//#endregion

export type SubaccountEndpoints = {
	delete: Write<ApiResponse<Subaccount>, DeleteSubaccountRequest>;
};

export function subaccounts(axiosInstance: AxiosInstance): SubaccountEndpoints {
	return {
		delete: async (data, config = {}): Promise<DeleteSubaccountResponse> =>
			await axiosInstance.delete(
				`account/${data.accountId}/subaccount/${data.id}`,
				{
					...config,
				},
			),
	};
}
