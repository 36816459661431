import {
	GridColDef,
	GridRowModel,
	GridRowParams,
	GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { flow, makeAutoObservable } from 'mobx';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { FrontendGroupRepository, GroupRepository } from '../groupRepository';
import { ManageGroupMembersModel } from './manageGroupMembersButtonModel';

export class ManageGroupMembersViewModel {
	private manageMembersModel: ManageGroupMembersModel;

	private _openModal: boolean;
	private _successfulSubmit: boolean;
	private _successSnackbarOpen: boolean;

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
		groupRepository: GroupRepository,
	) {
		makeAutoObservable(this);
		this.manageMembersModel = new ManageGroupMembersModel(
			groupId,
			frontendGroupRepository,
			groupRepository,
		);

		this._successfulSubmit = false;
		this._successSnackbarOpen = false;
		this._openModal = false;
	}

	// Manage Members Modal Functions
	public isModalOpen = () => {
		return this._openModal;
	};
	public openModal = async () => {
		this._openModal = true;
		await this.loadAvailableMembers();
	};
	public closeModal = () => {
		this._openModal = false;
		this.manageMembersModel.clear();
	};
	public isRowSelectable = (params: GridRowParams) => {
		return !params.row.hasOpenRequest;
	};

	// Manage Members Modal List Functions
	public getAvailableMembersError = () => {
		return isStringUndefinedOrNullOrWhitespace(
			this.manageMembersModel.availableMembersError,
		)
			? undefined
			: this.manageMembersModel.availableMembersError;
	};
	public getAvailableMembersLoading = () => {
		return this.manageMembersModel.availableMembersLoading;
	};

	public getAvailableMembersListColumns = (): GridColDef[] => {
		return [
			{
				field: 'email',
				headerName: 'Email',
				flex: 10,
				minWidth: 200,
			},
			{
				...getDateColumnDefinition(),
				field: 'lastLogin',
				headerName: 'Last Login',
				flex: 10,
				minWidth: 200,
			},
		];
	};

	public getAvailableMembersListRows = (): GridRowModel[] => {
		return this.manageMembersModel.availableMembers.map((member) => {
			return {
				id: member.id,
				email: member.email,
				lastLogin: member.lastLogin,
				hasOpenRequest: member.hasOpenRequest,
			};
		});
	};

	public loadAvailableMembers = flow(function* (
		this: ManageGroupMembersViewModel,
	) {
		yield this.manageMembersModel.loadAvailableMembers();
		if (!this.getAvailableMembersError()) {
			this.manageMembersModel.setSelectionModel();
		}
	});

	// Manage Members Modal Update Functions
	public isSuccessfulSubmit = () => {
		return this._successfulSubmit;
	};
	public resetSuccessfulSubmit = () => {
		this._successfulSubmit = false;
	};
	public isSuccessSnackbarOpen = () => {
		return this._successSnackbarOpen;
	};
	public closeSuccessSnackbar = () => {
		this._successSnackbarOpen = false;
	};

	public getSelectionModel = (): GridRowSelectionModel => {
		return this.manageMembersModel.selectionModel;
	};
	public onSelectionChange = (selectionModel: GridRowSelectionModel) => {
		this.manageMembersModel.onSelectionModelChange(selectionModel);
	};

	public getManageMembersError = () => {
		return this.manageMembersModel.error;
	};
	public getManageMembersLoading = () => {
		return this.manageMembersModel.loading;
	};

	public canSubmitUpdates = () => {
		if (
			this.manageMembersModel.loading ||
			this.manageMembersModel.availableMembersLoading ||
			!isStringUndefinedOrNullOrWhitespace(
				this.manageMembersModel.availableMembersError,
			)
		) {
			return false;
		}
		return true;
	};

	public manageMembersSubmit = flow(function* (
		this: ManageGroupMembersViewModel,
	) {
		yield this.manageMembersModel.updateGroupMembers();
		if (isStringUndefinedOrNullOrWhitespace(this.manageMembersModel.error)) {
			this.closeModal();
			this._successfulSubmit = true;
			this._successSnackbarOpen = true;
		}
	});
}
