import { createContext } from 'react';

export enum AdminViewModes {
	default,
	edit,
}

export type AdminViewModeContextProps = {
	viewMode: AdminViewModes;
	setViewMode: (viewMode: AdminViewModes) => void;
};

export const AdminViewModeContext = createContext<AdminViewModeContextProps>(
	{} as AdminViewModeContextProps
);
