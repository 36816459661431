import { Button, Typography } from '@mui/material';
import { CustomModal } from 'features/entity4/entities/components/customModal';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { DeleteRegistrationViewModel } from '../models/deleteRegistrationViewModel';
import { RegistrationListViewModel } from '../models/registrationListViewModel';

export const testIds = {
	modal: 'modal',
	cancelButton: 'cancel-button',
	deleteButton: 'delete-button',
};

export interface DeleteRegistrationModalProps {
	registrationsListViewModel: RegistrationListViewModel;
	viewModel: DeleteRegistrationViewModel;
}
export const DeleteRegistrationModal: FC<DeleteRegistrationModalProps> = observer(
	({ registrationsListViewModel, viewModel }) => (
		<CustomModal
			data-testid={testIds.modal}
			title={'Confirm Delete'}
			open={viewModel.isOpen}
			actions={[
				<Button
					data-testid={testIds.cancelButton}
					color="secondary"
					onClick={() => viewModel.closeModal()}
				>
					Cancel
				</Button>,
				<Button
					data-testid={testIds.deleteButton}
					color="error"
					variant="contained"
					onClick={() =>
						viewModel.delete().then(() => {
							registrationsListViewModel.load();
							viewModel.closeModal();
						})
					}
				>
					Delete
				</Button>,
			]}
		>
			<Typography>
				Are you sure you want to delete this registration?
			</Typography>
		</CustomModal>
	)
);
