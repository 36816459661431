import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { paths } from 'shared/constants/paths';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { T4Solution } from 'shared/t4Solutions';
import Background from '../../assets/solutions_background.jpg';
import Logo from '../../images/logos/Treasury4_GrayGreen_Logo.svg';
import C4Logo from '../../images/logos/cash4/Cash4_WhiteGreen_Logo.svg';
import E4Logo from '../../images/logos/entity4/Entity4_WhiteGreen_Logo.svg';
import P4Logo from '../../images/logos/payments4/Payments4_WhiteGreen_Logo.svg';
import { rem } from '../../utilities/styles';
import SolutionCard from './solutionCard';

export interface ISolutionLink {
	index: number;
	name: string;
	description: string;
	image: string;
	url: string;
	enabled: boolean;
}

const SolutionsPage: FC = observer(() => {
	const theme = useTheme();
	const { cash4Enabled, payments4Module } = useT4FeatureFlags();

	const solutionItems = useMemo(() => {
		let modules: T4Solution[] = [T4Solution.Entity4];
		if (cash4Enabled) {
			modules.push(T4Solution.Cash4);
		}

		if (payments4Module) {
			modules.push(T4Solution.Payments4);
		}

		return [
			{
				index: 0,
				name: 'Entity 4',
				description: 'Legal Entity Management',
				image: E4Logo,
				url: paths.entity4.href,
				enabled: modules.find((x) => x === T4Solution.Entity4) !== undefined,
			},
			{
				index: 1,
				name: 'Cash 4',
				description: 'Global Cash Management',
				image: C4Logo,
				url: paths.cash4.href,
				enabled: modules.find((x) => x === T4Solution.Cash4) !== undefined,
			},
			{
				index: 2,
				name: 'Payment 4',
				description: 'Secure Payments Management',
				image: P4Logo,
				url: paths.payments4.paymentStatus.href,
				enabled: modules.find((x) => x === T4Solution.Payments4) !== undefined,
			},
		];
	}, [cash4Enabled, payments4Module]);

	return (
		<Box
			display="flex"
			alignItems="center"
			sx={{
				minHeight: `100vh`,
				minWidth: '100%',
				backgroundImage: `url(${Background})`,
				backgroundPosition: 'center center',
				backgroundSize: 'cover',
				paddingBottom: rem(65),

				[theme.breakpoints.down('sm')]: {
					paddingBottom: rem(40),
				},
			}}
		>
			<Container
				sx={{
					display: 'flex',
					justifyContent: 'center',
					flexWrap: 'wrap',
				}}
			>
				<Grid
					container
					sx={{
						justifyItems: 'center',
						maxWidth: '100%',
						flexWrap: 'wrap',
					}}
				>
					<Grid item xs={12}>
						<Box
							component="img"
							src={Logo}
							alt="Treasury 4"
							sx={{
								display: 'block',
								margin: `${rem(55)} auto 0`,
								maxHeight: rem(90),
								maxWidth: rem(515),

								[theme.breakpoints.down('sm')]: {
									margin: `${rem(40)} auto 0`,
									maxWidth: rem(380),
								},

								[theme.breakpoints.down('xs')]: {
									margin: `${rem(40)} auto 0`,
									maxWidth: rem(250),
								},
							}}
						/>
					</Grid>
					<Grid item xs={12} sx={{ marginBottom: '24px' }}>
						<Typography align="center" variant="h4">
							Solutions
						</Typography>
					</Grid>

					<Grid
						container
						item
						xs={12}
						sx={{
							flexWrap: 'wrap',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						{solutionItems
							.sort((a, b) => a.index - b.index)
							.map((solution) => (
								<Grid
									item
									xs={12}
									sm={12}
									md={4}
									key={`Soulution_Card_${solution.name}`}
									sx={{ padding: '16px' }}
								>
									<SolutionCard
										name={solution.name}
										text={solution.description}
										image={solution.image}
										urlPath={solution.url}
										isDisabled={!solution.enabled}
									/>
								</Grid>
							))}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
});

export default SolutionsPage;
