/* eslint-disable mobx/missing-observer */
import { Tooltip } from '@mui/material';
import React, { useState } from 'react';

type Placement =
	| 'top'
	| 'bottom'
	| 'right'
	| 'left'
	| 'top-start'
	| 'top-end'
	| 'bottom-start'
	| 'bottom-end'
	| 'right-start'
	| 'right-end'
	| 'left-start'
	| 'left-end'
	| undefined;

interface T4CopyButtonWrapperProps {
	children: React.ReactElement;
	copyText: string;
	placement?: Placement;
	initialTooltipText?: string;
}

// This is a convenience wrapper for the copy button
export const T4CopyButtonWrapper: React.FC<T4CopyButtonWrapperProps> = ({
	children,
	copyText,
	placement,
	initialTooltipText = 'Copy to Clipboard',
}) => {
	const [tooltipTitle, setTooltipTitle] = useState<string>(initialTooltipText);

	const copyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(copyText);
			setTooltipTitle('Copied!');
		} catch (err) {
			setTooltipTitle('Failed to copy!');
		} finally {
			setTimeout(() => setTooltipTitle('Copy to Clipboard'), 2000);
		}
	};

	const handleChildClick = (event: React.MouseEvent) => {
		// Call the original onClick if it exists
		if (children.props.onClick) {
			children.props.onClick(event);
		}
		// Then perform the copy action
		copyToClipboard();
	};

	// Clone the child element and enhance its props
	const childWithProps = React.cloneElement(children, {
		onClick: handleChildClick,
	});

	return (
		<Tooltip
			title={tooltipTitle}
			placement={placement}
			enterDelay={300}
			leaveDelay={200}
		>
			{childWithProps}
		</Tooltip>
	);
};
