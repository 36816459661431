import { Grid } from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { LegalEntityGroup } from 'modules/clients/customer-api/src/entity4/legalEntityGroups';
import { FC, useCallback, useMemo } from 'react';
import { T4MultiSelectAutocomplete } from 'shared/components/autocompletes/t4MultiselectAutocomplete';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { useLegalEntityGroupCollectionDrawer } from '../hooks/useLegalEntityGroupCollectionDrawer';

export type LegalEntityGroupCollectionDrawerProps = {
	id?: string;
	open: boolean;
	closeDrawer: () => void;
};

const stonlyIds = {
	cancelButton: 'legalentitygroup-collection-drawer-cancel-button',
	submitButton: 'legalentitygroup-collection-drawer-submit-button',
};

export const LegalEntityGroupCollectionDrawer: FC<
	LegalEntityGroupCollectionDrawerProps
> = ({ id, open, closeDrawer }) => {
	const {
		loading,
		legalEntityGroups,
		name,
		nameError,
		description,
		descriptionError,
		selectedLegalEntityGroups,
		setName,
		setDescription,
		setSelectedLegalEntityGroups,
		resetForm,
		submit,
	} = useLegalEntityGroupCollectionDrawer(id);

	const drawerText = useMemo(
		() =>
			!id
				? { title: 'Create collection', submitButtonText: 'Create' }
				: { title: 'Edit collection', submitButtonText: 'Save' },
		[id],
	);

	const submitDisabled = useMemo(
		() => isStringUndefinedOrNullOrWhitespace(name),
		[name],
	);

	const close = useCallback(() => {
		closeDrawer();
		resetForm();
	}, [closeDrawer, resetForm]);

	return (
		<T4DrawerBase
			open={open}
			onClose={close}
			title={drawerText.title}
			loading={loading}
			disableNavigationCollapse={true}
			actions={[
				<DrawerCancelButton
					stonlyId={stonlyIds.cancelButton}
					onCancel={close}
				/>,
				<DrawerSubmitButton
					stonlyId={stonlyIds.submitButton}
					label={drawerText.submitButtonText}
					onSubmit={async () => {
						if (await submit()) {
							close();
						}
					}}
					disabled={submitDisabled || loading}
				/>,
			]}
		>
			<Grid container sx={{ gap: 1 }}>
				<Grid item xs={12}>
					<T4TextFieldV2
						label="Name"
						value={name ?? ''}
						onChange={setName}
						InputProps={{
							readOnly: loading,
						}}
						required
						error={nameError !== undefined}
						helperText={nameError}
					/>
				</Grid>
				<Grid item xs={12}>
					<T4TextFieldV2
						label="Description"
						value={description ?? ''}
						onChange={setDescription}
						InputProps={{
							readOnly: loading,
						}}
						multiline
						maxRows={3}
						minRows={3}
						error={descriptionError !== undefined}
						helperText={descriptionError}
					/>
				</Grid>
				<Grid item xs={12}>
					<T4MultiSelectAutocomplete<LegalEntityGroup>
						label="Assign Entity Groups to Collection"
						options={legalEntityGroups}
						value={selectedLegalEntityGroups}
						onSelect={setSelectedLegalEntityGroups}
						getOptionKey={(option) => option.id}
						getOptionLabel={(option) => option.name}
						isOptionEqualToValue={(option, value) => option.id === value.id}
					/>
				</Grid>
			</Grid>
		</T4DrawerBase>
	);
};
