import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { Layout } from 'shared/components/layout';
import { Navbar } from 'shared/components/navbar';
import { NavigationMenu } from 'shared/components/navigation/navigationMenu';
import SolutionPicker from 'shared/components/solutionPicker/solutionPicker';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { T4Solution } from 'shared/t4Solutions';
import { ApprovalsPage } from './approvals/approvalsPage';
import { AuditPage } from './auditLog/auditPage';
import { Cash4ConfigurationsPage } from './cash4Configurations/cash4ConfigurationsPage';
import { ConfigurationPage } from './configurations/configurationPage';
import { LegalEntityGroupsPage } from './legalEntityGroups/components/legalEntityGroupsPage';
import { AdministrationPage } from './pages/administrationPage';
import { PaymentApprovalsPageRoutes } from './payments4/paymentApprovals/paymentApprovalsPage';
import { AdminProvider } from './providers/adminViewModeProvider';
import { GroupsRoute } from './securityGroups/groupList/groupList';
import { UserEntitlementsPage } from './userEntitlementReport/userEntitlementsList';

export const AdministrationRoutes: FC = observer(() => {
	const location = useLocation();
	const { isAdmin, payments4 } = useUser();

	const navigationSections = useMemo(() => {
		const navigationDisabled = !isAdmin;

		return [
			{
				title: 'Global Settings',
				navigationItems: [
					{
						id: 'legal-entity-groups',
						to: paths.administration.legalEntityGroups.href,
						label: 'Entity Groups',
						disabled: navigationDisabled,
						selected: location.pathname.includes(
							paths.administration.legalEntityGroups.href,
						),
					},
					{
						id: 'security-groups',
						to: paths.administration.groups.href,
						label: 'Security Groups',
						disabled: navigationDisabled,
						selected: location.pathname.includes(
							paths.administration.groups.href,
						),
					},
					{
						id: 'configurations',
						to: paths.administration.securityGroupApprovals.href,
						label: 'Configurations',
						disabled: navigationDisabled,
						selected: location.pathname.includes(
							paths.administration.securityGroupApprovals.href,
						),
					},
					{
						id: 'userEntitlements',
						to: paths.administration.userEntitlements.href,
						label: 'User Entitlement Report',
						disabled: navigationDisabled,
						selected: location.pathname.includes(
							paths.administration.userEntitlements.href,
						),
					},
				],
			},
			{
				title: 'Entity4 Settings',
				navigationItems: [
					{
						id: 'audit-log',
						to: paths.administration.auditLog.href,
						label: 'Audit Log',
						disabled: navigationDisabled,
						selected: location.pathname.includes(
							paths.administration.auditLog.href,
						),
					},
					{
						id: 'approvals',
						to: paths.administration.entity4Configurations.href,
						label: 'Approvals',
						disabled: navigationDisabled,
						selected: location.pathname.includes(
							paths.administration.entity4Configurations.href,
						),
					},
				],
			},
			{
				title: 'Cash4 Settings',
				navigationItems: [
					{
						id: 'cash4-configurations',
						to: paths.administration.cash4Configuration.href,
						label: 'Configurations',
						disabled: navigationDisabled,
						selected: location.pathname.includes(
							paths.administration.cash4Configuration.href,
						),
					},
				],
			},
			{
				title: 'Payments4 Settings',
				navigationItems: [
					{
						id: 'payments4-payment-approvals',
						to: paths.administration.payments4.paymentApprovalsManagement.href,
						label: 'Payment Approvals Management',
						disabled: navigationDisabled || !payments4.isAdmin,
						selected: location.pathname.includes(
							paths.administration.payments4.paymentApprovalsManagement.href,
						),
					},
				],
			},
		];
	}, [isAdmin, payments4, location.pathname]);

	return (
		<AdminProvider>
			<Layout
				appBar={
					<Navbar
						logo={<SolutionPicker activeSolution={T4Solution.Administration} />}
					/>
				}
				navElements={<NavigationMenu sections={navigationSections} />}
				toggleable={false}
			>
				<Switch>
					<Route exact path={paths.administration.href}>
						<AdministrationPage />
					</Route>
					<Route exact path={paths.administration.legalEntityGroups.href}>
						<LegalEntityGroupsPage />
					</Route>
					<Route path={paths.administration.groups.href}>
						<GroupsRoute />
					</Route>
					<Route exact path={paths.administration.securityGroupApprovals.href}>
						<ConfigurationPage />
					</Route>
					<Route exact path={paths.administration.userEntitlements.href}>
						<UserEntitlementsPage />
					</Route>

					<Route exact path={paths.administration.auditLog.href}>
						<AuditPage />
					</Route>
					<Route exact path={paths.administration.entity4Configurations.href}>
						<ApprovalsPage />
					</Route>
					<Route exact path={paths.administration.cash4Configuration.href}>
						<Cash4ConfigurationsPage />
					</Route>
					<Route path={paths.administration.payments4.href}>
						{payments4.isAdmin ? (
							<PaymentApprovalsPageRoutes />
						) : (
							<CannotDisplay
								headingText={ACCESS_DENIED_MESSAGING.HEADING}
								bodyText={ACCESS_DENIED_MESSAGING.BODY}
								imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
							/>
						)}
					</Route>

					<Route>
						<CannotDisplay
							headingText={NOT_FOUND_MESSAGING.HEADING}
							bodyText={NOT_FOUND_MESSAGING.BODY}
							imageSrc={NOT_FOUND_MESSAGING.IMAGE}
							buttonText={RETURN_TO_HOME}
							buttonHref={paths.root.href}
						/>
					</Route>
				</Switch>
			</Layout>
		</AdminProvider>
	);
});
