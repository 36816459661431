import { VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useSensitiveField } from '../object/providers/sensitiveFieldProvider';

export const VisibilityOffIcon: FC = observer((props) => {
	const { showField, setShowField } = useSensitiveField();

	return (
		<InputAdornment {...props} position="end">
			<IconButton onClick={() => setShowField(!showField)}>
				<VisibilityOff />
			</IconButton>
		</InputAdornment>
	);
});
