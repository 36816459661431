import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { AddSubtypeViewModel } from './addSubtypeModal/addSubtypeViewModel';
import { AddTypeViewModel } from './addTypeModal/addTypeViewModel';
import { DeleteSubtypeViewModel } from './deleteSubtypeModal/deleteSubtypeViewModel';
import { DeleteTypeViewModel } from './deleteTypeModal/deleteTypeViewModel';
import { EditSubtypeViewModel } from './editSubtypeModal/editSubtypeViewModel';
import { EditTypeViewModel } from './editTypeModal/editTypeViewModel';

export class CategoriesViewModel {
	private _loading: boolean = true;
	private _error?: string;
	private _categories: CashFlowClass[] = [];
	private _depth: number = 0;

	public readonly addTypeViewModel: AddTypeViewModel;
	public readonly addSubtypeViewModel: AddSubtypeViewModel;

	public readonly editTypeViewModel: EditTypeViewModel;
	public readonly editSubtypeViewModel: EditSubtypeViewModel;

	public readonly deleteTypeViewModel: DeleteTypeViewModel;
	public readonly deleteSubtypeViewModel: DeleteSubtypeViewModel;

	constructor() {
		makeAutoObservable(this);

		this.addTypeViewModel = new AddTypeViewModel(() => this.load());
		this.addSubtypeViewModel = new AddSubtypeViewModel(() => this.load());
		this.editTypeViewModel = new EditTypeViewModel(() => this.load());
		this.editSubtypeViewModel = new EditSubtypeViewModel(() => this.load());
		this.deleteTypeViewModel = new DeleteTypeViewModel(() => this.load());
		this.deleteSubtypeViewModel = new DeleteSubtypeViewModel(() => this.load());
	}

	public isLoading = () => this._loading;
	public getError = () => this._error;
	public getClasses = () => this._categories.slice();
	public getDepth = () => this._depth;
	public setDepth = (value: number) => (this._depth = value);

	public load = flow(function* (this: CategoriesViewModel) {
		try {
			this._loading = true;
			this._error = undefined;

			const response: AxiosResponse<ApiResponse<CashFlowClass[]>> =
				yield customerApi.get<ApiResponse<CashFlowListItem[]>>(`/categories`);

			if (response.data.error) {
				this._error = response.data.error;
			}

			this._categories = response.data.value;
		} catch (error) {
			this._error = (error as Error)?.message;
		} finally {
			this._loading = false;
		}
	});

	public getCategories = (): CashFlowListItem[] => {
		return this._categories.slice().flatMap((_class) => [
			{
				id: _class.id,
				classId: _class.id,
				path: [_class.name],
				code: _class.code,
				name: _class.name,
				transactionCount: _class.transactionCount,
				transactionRuleCount: _class.transactionRuleCount,
			},
			..._class.types.flatMap((type) => [
				{
					id: type.id,
					classId: type.classId,
					typeId: type.id,
					path: [_class.name, type.name],
					code: type.code,
					name: type.name,
					transactionCount: type.transactionCount,
					transactionRuleCount: type.transactionRuleCount,
				},
				...type.subtypes.map((subtype) => ({
					id: subtype.id,
					classId: subtype.classId,
					typeId: subtype.typeId,
					subtypeId: subtype.id,
					path: [_class.name, type.name, subtype.name],
					code: subtype.code,
					name: subtype.name,
					transactionCount: subtype.transactionCount,
					transactionRuleCount: subtype.transactionRuleCount,
				})),
			]),
		]);
	};
}

export interface CashFlowListItem {
	id: string;
	classId: string;
	typeId?: string;
	subtypeId?: string;
	path: string[];
	name: string;
	code: string;
	transactionCount: number;
	transactionRuleCount: number;
}

export interface CashFlowClass {
	id: string;
	code: string;
	name: string;
	transactionCount: number;
	transactionRuleCount: number;
	types: CashFlowType[];
}

export interface CashFlowType {
	classId: string;
	id: string;
	code: string;
	name: string;
	transactionCount: number;
	transactionRuleCount: number;
	subtypes: CashFlowSubtype[];
}

export interface CashFlowSubtype {
	classId: string;
	typeId: string;
	id: string;
	code: string;
	name: string;
	transactionCount: number;
	transactionRuleCount: number;
}
