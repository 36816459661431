import { ThemeOptions } from '@mui/material';
import { brandColors } from './brandColors';

type ModeType = 'light' | 'dark';

export const baseTheme = (mode: ModeType): ThemeOptions => ({
	palette: {
		mode: mode,
		charcoal: {
			...brandColors.charcoal,
		},
		equityGreen: {
			...brandColors.equityGreen,
		},
		denim: {
			...brandColors.denim,
		},
		maize: {
			...brandColors.maize,
		},
		cornflower: {
			...brandColors.cornflower,
		},
		grey: {
			...brandColors.grey,
		},
		debtRed: {
			...brandColors.debtRed,
		},
		jasper: {
			...brandColors.jasper,
		},
	},
	typography: {
		h1: {
			fontSize: '2rem',
		},
		h2: {
			fontSize: '1.75rem',
		},
		h3: {
			fontSize: '1.5rem',
		},
		h4: {
			fontSize: '1.25rem',
		},
		h5: {
			fontSize: '1.25rem',
		},
		h6: {
			fontSize: '1rem',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					padding: '.375rem 1rem',
				},
				startIcon: {
					marginTop: '-1px',
				},
			},
		},
		MuiTabs: {
			defaultProps: {
				textColor: 'inherit',
				indicatorColor: 'secondary',
			},
		},
	},
});
