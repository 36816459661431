/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useOrgId } from 'shared/hooks/useOrgId';

export const T4StonlyWidgetProvider: FC = observer(() => {
	if (process.env.REACT_APP_DISABLE_STONLY === 'true') return null;

	const { user, isAuthenticated } = useAuth0();
	const orgId = useOrgId();

	if (user && isAuthenticated && orgId) {
		window.STONLY_WID = '4d6bb460-bc21-11ec-9fb8-0ae9fa2a18a2';

		(function (
			s: Window & typeof globalThis,
			t: Document,
			o: string,
			n: string,
			l?: any,
			y?: any,
			w?: any,
			g?: any,
		) {
			s.StonlyWidget ||
				(((
					(w = s.StonlyWidget =
						function () {
							w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
						}) as any
				).scriptPath = n),
				(w.queue = []),
				(((y = t.createElement(o)) as any).async = !0),
				(g = new XMLHttpRequest()).open(
					'GET',
					n + 'version?v=' + Date.now(),
					!0,
				),
				(g.onreadystatechange = function () {
					4 === g.readyState &&
						((y.src =
							n +
							'stonly-widget.js?v=' +
							(200 === g.status ? g.responseText : Date.now())),
						(l = t?.getElementsByTagName(o)[0])?.parentNode?.insertBefore(
							y,
							l,
						));
				}),
				g.send());
		})(window, document, 'script', 'https://stonly.com/js/widget/v2/');

		(function (
			s: Window & typeof globalThis,
			t: Document,
			o: string,
			n: string,
			l?: any,
			y?: any,
			_?: any,
		) {
			s.stonlyTrack ||
				(((
					(_ = s.stonlyTrack =
						function () {
							_._api ? _._api.apply(_, arguments) : _.queue.push(arguments);
						}) as any
				).queue = []),
				(((y = t.createElement(o)) as any).async = !0),
				(y.src = n),
				(l = t?.getElementsByTagName(o)[0])?.parentNode?.insertBefore(y, l));
		})(window, document, 'script', 'https://stonly.com/js/tracker/stn.js');

		window.stonlyTrack('init', window.STONLY_WID);
		window.stonlyTrack('identify', `${user.sub}`, {
			organization: orgId,
		});
	} else {
		window.STONLY_WID = '';
		window.StonlyWidget = undefined;
		window.stonlyTrack = undefined;
	}

	return null;
});
