import { useQuery } from '@tanstack/react-query';
import { Payment } from 'modules/clients/apiGateway/payments4/payments';
import { T4DataResponse2 } from 'modules/clients/types';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export const PaymentQueryKeys = {
	getAllPayments: 'get-all-payments',
	getPayment: 'get-payment',
};

export const useGetAllPayments = () => {
	const { applicationApiClient } = useClients();

	const loadPayments = useCallback(async () => {
		try {
			const response = await applicationApiClient.payments4.payments.getAll();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<Payment[]>).data;
			else throw new Error();
		} catch {
			throw new Error('Unable to load payments. Please try again later.');
		}
	}, [applicationApiClient]);

	return useQuery<Payment[], Error>(
		[PaymentQueryKeys.getAllPayments],
		loadPayments,
		{
			refetchOnWindowFocus: false,
		},
	);
};

export const useGetPayment = (id: string | null) => {
	const { applicationApiClient } = useClients();

	const loadPayment = useCallback(async () => {
		try {
			if (id === null) return;
			const response = await applicationApiClient.payments4.payments.get(id);
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<Payment>).data;
			else if (response.status === 404) throw new Error('Payment not found.');
			else throw new Error();
		} catch (error: any) {
			throw new Error(
				error?.message ?? 'Unable to load payment. Please try again later.',
			);
		}
	}, [id, applicationApiClient]);

	return useQuery<Payment | undefined, Error>(
		[PaymentQueryKeys.getPayment, id],
		loadPayment,
		{
			refetchOnWindowFocus: false,
			enabled: id !== null,
		},
	);
};
