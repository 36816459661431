import { AxiosInstance } from 'axios';
import { T4DataResponse, T4Response } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Write } from '../../transactions';

//#region Models

export type Partner = T4DataResponse<{
	id: string;
	code: string;
}>;

//#endregion

//#region Responses

export type DeletePartnerData = {
	id: string;
};
export type DeletePartnerResponse = T4Response<
	ApiResponse<Partner>,
	DeletePartnerData
>;

//#endregion

export type PartnerEndpoints = {
	delete: Write<ApiResponse<Partner>, DeletePartnerData>;
};

export function partners(axiosInstance: AxiosInstance): PartnerEndpoints {
	return {
		delete: async (data, config = {}): Promise<DeletePartnerResponse> =>
			await axiosInstance.delete(`partner/${data.id}`, {
				...config,
			}),
	};
}
