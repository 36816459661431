import React from 'react';
import { observer } from 'mobx-react-lite';
import { Address } from './addressModel';
import { InputAdornmentWithModal } from '../../../shared/components/molecules/inputAdornmentWithModal';
import { T4AlertStack } from '../../../shared/components/molecules/t4AlertStack';
import { AddressFormModel } from './addressFormModel';
import { T4TextFieldV2 } from '../../../shared/components/atoms/t4TextField';
import { useHeader } from 'shared/providers/contexts/headerContext';

interface IAddressTextInputProps {
	address: Address;
	fieldKey: keyof AddressFormModel;
	readOnly?: boolean;
	disabled?: boolean;
	label: string;
	description: string;
}

export const AddressTextInput: React.FC<IAddressTextInputProps> = observer(
	({ address, fieldKey, label, description, readOnly, disabled }) => {
		const { updateLastAutoSave } = useHeader();
		const saveValue = async () => {
			if (
				address.addressForm[fieldKey] !== address[fieldKey as keyof Address]
			) {
				await address
					.update(
						fieldKey as keyof Address,
						address.addressForm[fieldKey] as string,
					)
					.finally(() => updateLastAutoSave());
			}
		};

		const onTextFieldChange = (value: any) => {
			address.addressForm.setValue(fieldKey, value);
		};

		const adornmentType = address.addressForm.isWaiting(fieldKey)
			? 'loading'
			: address.addressForm.isSaved(fieldKey)
			? 'success'
			: 'info';

		return (
			<>
				<T4TextFieldV2
					label={label}
					value={address.addressForm.getField(fieldKey) ?? ''}
					onChange={onTextFieldChange}
					onBlur={saveValue}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						startAdornment: (
							<InputAdornmentWithModal
								description={description}
								adornmentType={adornmentType}
							/>
						),
						readOnly,
						disabled,
					}}
				/>
				<T4AlertStack
					error={address.shouldDisplayFieldError(fieldKey as keyof Address)}
				/>
			</>
		);
	},
);
