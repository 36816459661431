import { Divider, MenuItem, Tooltip, Typography } from '@mui/material';
import {
	GRID_REORDER_COL_DEF,
	GridColDef,
	GridRenderCellParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { capitalizeString } from 'utilities/stringUtils';
import {
	MatchCondition,
	TransactionRule,
	TransactionRuleListItem,
	TransactionRuleRequest,
} from '../models';

export const stonlyIds = {
	runRulesButton: 'run-rules-button',
	createRuleButton: 'create-rule-button',
	editRuleButton: 'edit-rule-button',
	deleteRuleButton: 'delete-rule-button',
	menuOptionsButton: 'menu-options-button',
};

export interface Cash4UserProps {
	isAuthor: boolean;
	isViewer: boolean;
	isDataImporter: boolean;
	isIntegrationsAdmin: boolean;
}

export const dataTestIds = {
	runRulesButton: 'run-rules-button',
	createRuleButton: 'create-rule-button',
	editRuleButton: 'edit-rule-button',
	deleteRuleButton: 'delete-rule-button',
	menuOptionsButton: 'menu-options-button',
};

export const getColumns = (
	cash4User: Cash4UserProps,
	handleRuleModal: (row: TransactionRuleListItem) => void,
	handleDeleteModal: (row: TransactionRuleListItem) => void,
): GridColDef[] => {
	let columns: GridColDef[] = [
		{
			field: 'priority',
			headerName: 'Priority',
			description: 'Priority',
			flex: 1 / 2,
			sortable: false,
			filterable: false,
			hideable: false,
		},
		{
			field: 'name',
			headerName: 'Rule Name',
			description: 'Rule Name',
			type: 'string',
			flex: 2,
			sortable: false,
		},
		{
			field: 'conditions',
			headerName: 'Conditions',
			description: 'Conditions',
			type: 'string',
			cellClassName: (_: any) => 'conditions-cell',
			renderCell: (params: GridRenderCellParams) => {
				return (
					<Typography
						width={1}
						variant="body2"
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box!important',
							lineClamp: 2,
							WebkitLineClamp: 2,
							WebkitBoxOrient: 'vertical',
						}}
					>
						{getConditionsDescription(params.row.matchConditions)}
					</Typography>
				);
			},
			valueGetter: (value: GridValueGetterParams<TransactionRuleListItem>) => {
				return getConditionsDescription(value.row.matchConditions);
			},
			flex: 4,
			sortable: false,
		},
		{
			field: 'cfc',
			headerName: 'CFC',
			description: 'Cash Flow Class',
			type: 'string',
			width: DataGridColumnWidths.threeChar,
			sortable: false,
			renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
				return (
					<Tooltip title={params.row.cfc.name}>
						<Typography variant="body2" noWrap>
							{params.row.cfc.code}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: 'cfcName',
			headerName: 'CFC Description',
			description: 'Cash Flow Class',
			type: 'string',
			renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
				return params.row.cfc.name;
			},
		},
		{
			field: 'cft',
			headerName: 'CFT',
			description: 'Cash Flow Type',
			type: 'string',
			width: DataGridColumnWidths.threeChar,
			sortable: false,
			renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
				return (
					<Tooltip title={params.row.cft.name}>
						<Typography variant="body2" noWrap>
							{params.row.cft.code}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: 'cftName',
			headerName: 'CFT Description',
			description: 'Cash Flow Type',
			type: 'string',
			renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
				return params.row.cft.name;
			},
		},
		{
			field: 'cfst',
			headerName: 'CFST',
			description: 'Cash Flow Subtype',
			type: 'string',
			width: DataGridColumnWidths.fourChar,
			sortable: false,
			renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
				if (params.row.cfst) {
					return (
						<Tooltip title={params.row.cfst.name}>
							<Typography variant="body2" noWrap>
								{params.row.cfst.code}
							</Typography>
						</Tooltip>
					);
				}

				return '';
			},
		},
		{
			field: 'cfstName',
			headerName: 'CFST Description',
			description: 'Cash Flow Subtype',
			type: 'string',
			renderCell: (params: GridRenderCellParams<TransactionRuleListItem>) => {
				if (params.row.cfst) {
					return params.row.cfst.name;
				}

				return '';
			},
		},
		{
			field: 'glCode',
			headerName: 'GL Code',
			description: 'GL Code',
			type: 'string',
			sortable: false,
			valueGetter: (params) => {
				return params.value?.code;
			},
		},
		{
			field: 'transactionCount',
			headerName: 'Transaction Count',
			description: 'Transaction Count',
			type: 'number',
			sortable: false,
		},
		{
			field: 'lastAppliedOn',
			headerName: 'Last Applied On',
			description: 'Last Applied On',
			sortable: false,
			type: 't4Date',
		},
	];

	if (cash4User.isAuthor) {
		columns.unshift(
			{
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<TransactionRuleListItem>) => {
						return (
							<OverflowMenu
								iconButtonProps={{
									'data-testid': `${dataTestIds.menuOptionsButton}-${params.row.id}`,
									...stonlyData({
										id: stonlyIds.menuOptionsButton,
									}),
								}}
								id={`rulesId-more-menu`}
							>
								<MenuItem
									{...stonlyData({
										id: stonlyIds.editRuleButton,
									})}
									data-testid={`${dataTestIds.editRuleButton}-${params.row.id}`}
									onClick={(event) => {
										handleRuleModal(params.row);
									}}
								>
									Edit Rule
								</MenuItem>
								<Divider />
								<DeleteMenuItem
									{...stonlyData({
										id: stonlyIds.deleteRuleButton,
									})}
									data-testid={`${dataTestIds.deleteRuleButton}-${params.row.id}`}
									onClick={(event) => {
										handleDeleteModal(params.row);
									}}
								/>
							</OverflowMenu>
						);
					},
				),
				disableExport: true,
			},
			{
				...GRID_REORDER_COL_DEF,
				hideable: false,
			},
		);
	}

	return columns;
};

export const getConditionsDescription = (conditions: MatchCondition[]) => {
	let conditionArr: string[] = [];

	conditions.forEach((condition) => {
		conditionArr.push(
			`${condition.field} ${condition.operator} ${mapValuesToString(
				condition.values,
			)}`,
		);
	});

	let description = '';
	conditionArr.forEach((element, index) => {
		if (index > 0 && index <= conditionArr.length - 1) description += ', and ';

		if (index === 0) description += capitalizeString(element);
		else description += element;
		if (index === conditionArr.length - 1) description += '.';
	});
	return description;
};

const mapValuesToString = (values: string[]): string => {
	return values
		.map((value, index) => {
			return index === values.length - 1 ? `${value}` : `${value} or `;
		})
		.join(' ');
};

export const mapRuleToRuleListItem = (
	rule: TransactionRule,
): TransactionRuleListItem => {
	const mappedRule = {
		...rule,
		id: rule.id,
		priority: rule.priority,
		name: rule.name,
		matchConditions: rule.matchConditions,
		cfc: rule.cashFlowClass,
		cft: rule.cashFlowType,
		cfst: rule.cashFlowSubtype,
		glCode: rule.glCode,
		menu: rule,
		transactionCount: rule.transactionCount,
		lastAppliedOn: rule.lastAppliedOn,
	};
	return mappedRule;
};

export const mapRuleToRuleRequest = (
	rule: TransactionRule,
): TransactionRuleRequest => {
	return {
		transactionId: rule.id,
		name: rule.name,
		priority: rule.priority,
		isManualCategorization: rule.isManualCategorization,
		matchConditions: rule.matchConditions,
		classId: rule.cashFlowClass.id,
		typeId: rule.cashFlowType.id,
		subtypeId: rule.cashFlowSubtype?.id,
		glCode: rule.glCode,
	};
};

export const sortRules = (rules: TransactionRuleListItem[]) => {
	return rules.sort((a, b) => {
		if (a.priority === 0 && b.priority === 0) return 0;
		if (a.priority === 0) return 1;
		if (b.priority === 0) return -1;
		return a.priority - b.priority;
	});
};
