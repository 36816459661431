import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Collapse,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useUser } from 'shared/hooks/useUser';
import ModalBase from '../../../../../../shared/components/modalBase';
import { EntityFieldBase } from '../../../../entities/objects/fields/field';
import { T4RejectButton } from '../../../styleComponents/t4Components';
import { Field } from '../../fieldTypes';

export interface IFieldViewRejectButtonProps {
	entityId: string;
	fieldDefinition: Field;
	fieldData?: EntityFieldBase;
	title?: string | JSX.Element;
}
export const FieldViewRejectButton: React.FC<IFieldViewRejectButtonProps> = ({
	fieldDefinition,
	fieldData,
	title,
}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectionComment, setRejectionComment] = useState('');
	const [saving, setSaving] = useState(false);
	const { id: userId } = useUser();

	const openModal = () => {
		setErrorMessage('');
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setRejectionComment('');
	};

	const onRejectSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!rejectionComment) {
			setErrorMessage('Please provide a rejection comment');
			return;
		}

		setSaving(true);
		fieldData
			?.reject(rejectionComment)
			.catch(setErrorMessage)
			.finally(() => {
				setSaving(false);
				closeModal();
			});
	};

	const disableApproveRejectButton = fieldData?.updatedBy() === userId;

	const onRejectCommentChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRejectionComment(event.target.value);
	};
	return (
		<>
			<T4RejectButton
				fullWidth
				variant="contained"
				size="small"
				color="primary"
				onClick={openModal}
				disabled={disableApproveRejectButton}
				{...{ 'data-testid': 'field-view-reject-button' }}
			>
				Reject
			</T4RejectButton>
			<ModalBase
				title={
					title ? (
						title
					) : (
						<>
							Rejecting <strong>{fieldData?.pendingValue}</strong> as{' '}
							<strong>{fieldDefinition.name}</strong>
						</>
					)
				}
				onClose={closeModal}
				open={modalOpen}
				actions={<></>}
			>
				<form onSubmit={onRejectSubmit}>
					<Box marginBottom={2}>
						<Collapse in={Boolean(errorMessage)}>
							<Box marginBottom={2}>
								<Alert severity="error">{errorMessage}</Alert>
							</Box>
						</Collapse>
						<TextField
							sx={{ mt: 1 }}
							variant="outlined"
							fullWidth
							value={rejectionComment}
							onChange={onRejectCommentChange}
							label="Reason for rejection*"
						/>
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Grid container spacing={1} justifyContent="flex-end">
							<Grid item>
								<Button onClick={closeModal} type="button" color="secondary">
									Cancel
								</Button>
							</Grid>
							<Grid item>
								<T4RejectButton
									type="submit"
									color="primary"
									variant="contained"
									disabled={!rejectionComment}
								>
									Submit
								</T4RejectButton>
							</Grid>
						</Grid>
						{saving ? (
							<Box display="flex" alignItems="center">
								<Box marginRight={2}>
									<Typography>Rejecting...</Typography>
								</Box>
								<CircularProgress size={25} />
							</Box>
						) : null}
					</Box>
				</form>
			</ModalBase>
		</>
	);
};
