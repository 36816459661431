export const RelationshipModalVerbiage = {
	create: {
		title: 'Add Relationship',
		submitTitle: 'Add',
		cancelTitle: 'Cancel',
	},
	cancelConfirmation: {
		submitButtonLabel: 'Yes, Cancel',
		cancelButtonLabel: 'No',
		title: 'Cancel Relationship Creation?',
		description:
			'Are you sure you want to cancel the creation of this relationship? Your changes will not be saved.',
	},
	duplicateRelationshipConfirmation: {
		submitButtonLabel: 'Add',
		title: 'Are you sure?',
		description:
			'There is already an existing relationship for this type and item on this record. Are you sure you want to add this relationship?',
	},
};
