import { Error as ErrorIcon } from '@mui/icons-material';
import { Button, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'shared/constants/paths';

export const Error: FC = observer(() => {
	const history = useHistory();

	return (
		<Container
			sx={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
			}}
		>
			<Grid
				container
				rowSpacing={2}
				sx={{
					flexDirection: 'column',
					flexWrap: 'nowrap',
					alignItems: 'center',
				}}
			>
				<Grid item xs={12}>
					<ErrorIcon sx={{ height: '200px', width: '200px' }} />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h1">Error</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle1" align="center">
						Something went wrong.
					</Typography>
				</Grid>
				<Grid
					container
					item
					columnSpacing={2}
					xs={12}
					sx={{
						flexDirection: 'row',
						flexWrap: 'nowrap',
						justifyContent: 'center',
					}}
				>
					<Grid item>
						<Button
							variant="outlined"
							onClick={() => history.push(generatePath(paths.entity4.href))}
						>
							Go Home
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
});
