import { CustomModal } from 'features/entity4/entities/components/customModal';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import {
	T4SecondaryButton,
	T4DestructiveButton,
} from 'shared/components/buttons';
import { DeleteDocumentViewModel } from '../models/deleteDocumentViewModel';

export interface DeleteDocumentModalProps {
	viewModel: DeleteDocumentViewModel;
}
export const DeleteDocumentModal: FC<DeleteDocumentModalProps> = observer(
	({ viewModel }) => {
		return (
			<CustomModal
				open={viewModel.isOpen()}
				title="Confirm Delete"
				actions={[
					<T4SecondaryButton
						label="Cancel"
						onClick={() => viewModel.close()}
					/>,
					<T4DestructiveButton
						label="Delete"
						onClick={() => viewModel.delete()}
						loading={viewModel.isDeleting()}
					/>,
				]}
			>
				{`Are you sure you want to delete ${viewModel.getDocumentName()}? Doing so will delete the source file.`}
			</CustomModal>
		);
	}
);
