import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { BooleanField } from 'features/entity4/components/booleanField';
import { DateField } from 'features/entity4/components/dateField';
import { OptionField } from 'features/entity4/components/optionField';
import { StringField } from 'features/entity4/components/stringField';
import { FieldDataType } from 'features/entity4/shared/fieldSets/fieldTypes';
import { IListColumnDto } from 'features/entity4/shared/repositories/frontendRepository';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { referenceDataToJson } from 'shared/utilities/referenceDataUtilities';
import { stonlyData } from 'stonly/functions';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { FormModal } from '../../../../shared/components/formModal';
import { paths } from '../../../../shared/constants/paths';
import { EntityConfig, EntityType } from '../../entity4Constants';
import { EntityRepository } from '../entityRepository';

type FieldEntries = {
	[key: string]: any | null;
};

export type CreateObjectButtonProps = {
	objectType: EntityType;
	parentObjectId?: string;
	parentObjectType?: EntityType;
	creationFields: IListColumnDto[];
	disabled?: boolean;
	getReferenceList: (referenceCollectionName: string) => ReferenceDataValue[];
	getOptionList: (optionListId: string) => Option[];
};

export const CreateEntityButton: FC<CreateObjectButtonProps> = observer(
	({
		objectType,
		parentObjectId,
		parentObjectType,
		creationFields,
		disabled,
		getOptionList,
		getReferenceList,
	}) => {
		const history = useHistory();

		//#region State

		const [error, setError] = useState('');
		const [modalOpen, setModalOpen] = useState(false);
		const [loading, setLoading] = useState(false);
		const [fieldEntries, setFieldEntries] = useState<FieldEntries>({});

		const entityRepository = useMemo(
			() =>
				new EntityRepository(
					objectType,
					undefined,
					parentObjectType,
					parentObjectId,
				),
			[objectType, parentObjectId, parentObjectType],
		);

		const createConfig = useMemo(
			() => EntityConfig.get(objectType)!.createButtonConfig,
			[objectType],
		);

		//#endregion

		useEffect(() => {
			let fields: FieldEntries = {};
			creationFields.forEach((field) => (fields[field.identifier] = null));
			setFieldEntries(fields);
		}, [creationFields]);

		return (
			<>
				<Button
					startIcon={<Add />}
					type="button"
					variant="outlined"
					color="primary"
					onClick={() => setModalOpen(true)}
					disabled={disabled}
					data-testid={`${createConfig.entityName.toLowerCase()}-create-button`}
					{...stonlyData({
						id: `${createConfig.entityName.toLowerCase()}-create-button`,
					})}
				>
					Create
				</Button>
				<FormModal
					open={modalOpen}
					onClose={() => {
						setFieldEntries({});
						setError('');
						setModalOpen(false);
					}}
					title={createConfig.modalTitle}
					fullWidth
					onSubmit={async () => {
						setError('');
						setLoading(true);

						try {
							const fieldValues = Object.entries(fieldEntries)
								.filter((entry) => Boolean(entry[1]))
								.map((entry) => {
									return {
										fieldIdentifier: entry[0],
										value: entry[1],
									};
								});

							const entityId = await entityRepository.create(fieldValues);
							if (parentObjectId || parentObjectType) {
								history.push(
									generatePath(
										paths.entity4.objects.object.subaccounts.subaccount.href,
										{
											objectType: parentObjectType,
											objectId: parentObjectId,
											subaccountId: entityId,
										},
									) + '?view=edit',
								);
							} else {
								history.push(
									generatePath(paths.entity4.objects.object.information.href, {
										objectType: objectType,
										objectId: entityId,
									}) + '?view=edit',
								);
							}
						} catch (error: any) {
							setError(error?.message || error || 'Unexpected Error');
						} finally {
							setLoading(false);
						}
					}}
					description={createConfig.description}
					tips={createConfig.tips}
					error={error}
					loading={loading}
					submitButtonLabel="Create"
					loadingMessage="Creating..."
					submitDisabled={
						creationFields.some(
							({ isCreationRequirement, identifier }) =>
								isCreationRequirement &&
								isStringUndefinedOrNullOrWhitespace(fieldEntries[identifier]),
						) || loading
					}
					cancelDisabled={loading}
				>
					<Grid container spacing={1} direction="column">
						{creationFields
							.sort((a, b) => (a?.sortOrder ?? 0) - (b?.sortOrder ?? 0))
							.map(
								({
									identifier,
									columnName,
									description,
									isCreationRequirement,
									fieldType,
									referenceCollectionName,
									optionListId,
								}) => {
									const id = `field-${identifier}`;
									const value = fieldEntries[identifier];

									const optionList: Option[] = optionListId
										? getOptionList(optionListId)
										: [];
									const referenceList: ReferenceDataValue[] =
										referenceCollectionName
											? getReferenceList(referenceCollectionName)
											: [];

									const referenceValue = referenceList.find(
										(x) => referenceDataToJson(x) === value,
									);

									const setValue = (value: any) => {
										setFieldEntries({
											...fieldEntries,
											[identifier]: value ?? null,
										});
									};

									return (
										<Grid key={id} item>
											{fieldType === FieldDataType.boolean ? (
												<BooleanField
													id={id}
													label={columnName}
													required={isCreationRequirement}
													checked={value}
													onChange={(value) => setValue(value)}
												/>
											) : fieldType === FieldDataType.date ? (
												<DateField
													id={id}
													label={undefined}
													required={isCreationRequirement}
													value={value}
													onChange={(value) => setValue(value)}
												/>
											) : fieldType === FieldDataType.options ? (
												<OptionField
													id={id}
													label={columnName}
													value={
														optionList?.find((x) => x.id === value) ?? null
													}
													options={optionList}
													isOptionEqualToValue={(a, b) => a?.id === b?.id}
													getOptionLabel={(option) => option?.displayName}
													onSelect={(value) => setValue(value?.id)}
													startAdornmentProps={{
														title: columnName,
														description: description,
													}}
												/>
											) : referenceCollectionName !== undefined ? (
												<OptionField
													id={id}
													label={columnName}
													value={
														referenceValue
															? referenceDataToJson(referenceValue)
															: null
													}
													options={
														referenceCollectionName
															? getReferenceList(referenceCollectionName).map(
																	(x) => referenceDataToJson(x),
															  )
															: []
													}
													getOptionLabel={(option) =>
														referenceList.find(
															(x) => referenceDataToJson(x) === option,
														)?.displayName ?? ''
													}
													onSelect={(value) => setValue(value)}
													startAdornmentProps={{
														title: columnName,
														description: description,
													}}
												/>
											) : (
												<StringField
													id={id}
													label={columnName}
													required={isCreationRequirement}
													value={value ?? ''}
													onChange={(value) => setValue(value)}
													adornmentProps={{
														title: columnName,
														description: description,
													}}
												/>
											)}
										</Grid>
									);
								},
							)}
					</Grid>
				</FormModal>
			</>
		);
	},
);
