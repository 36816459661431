import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type ActuallyPrettyGoodDataGridWrapperProps = {
	sx?: SxProps;
};

/**
 * Suggested by others who use MUI to wrap data grids if you wish to the data grid to fill parent.
 * If we don't do this the data grid will stretch the parent component.
 */
export const ActuallyPrettyGoodDataGridWrapper: FC<ActuallyPrettyGoodDataGridWrapperProps> =
	observer(({ sx, children }) => {
		return (
			<Box sx={{ flex: 1, position: 'relative', height: '100%' }}>
				<Box sx={{ ...sx, position: 'absolute', inset: 0 }}>{children}</Box>
			</Box>
		);
	});
