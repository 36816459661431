import { Alert, Box, CircularProgress, Paper } from '@mui/material';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { T4Section as T4SectionV2 } from 'shared/components/t4Section';

interface T4SectionProps {
	loading?: boolean;
	padding?: number;
	alertMessage?: string | null;
	error?: string | null;
	errors?: string[];
}

/** @deprecated Please use @see {@link T4SectionV2} */
export const T4Section: FC<T4SectionProps> = observer(
	({
		loading = false,
		padding = 3,
		alertMessage,
		error,
		errors,
		children,
		...props
	}) => (
		<Box paddingY={padding} {...props}>
			{loading ? (
				<Box
					width="100%"
					height="100%"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Box>
			) : (
				<Paper sx={{ boxShadow: 'none' }}>
					{error ? (
						<Alert severity="error">{error}</Alert>
					) : errors && errors.length > 1 ? (
						<T4AlertStack errors={errors} />
					) : children ? (
						children
					) : (
						<Alert severity="info">{alertMessage}</Alert>
					)}
				</Paper>
			)}
		</Box>
	),
);
