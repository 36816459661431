import { MoreVert } from '@mui/icons-material';
import {
	IconButton,
	IconButtonProps,
	Menu,
	MenuProps,
	SvgIconProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useState } from 'react';
import { BaseIds } from 'shared/interfaces/baseIds';

export type OverflowMenuProps = {
	id: string;
	iconButtonProps?: Partial<Omit<IconButtonProps, 'onClick'>> & BaseIds;
	iconProps?: Partial<SvgIconProps>;
	menuProps?: Partial<
		Omit<MenuProps, 'open' | 'anchorEl' | 'onClose' | 'onClick'>
	> &
		BaseIds;
};

export const OverflowMenu: FC<OverflowMenuProps> = observer(
	({ id, children, iconButtonProps = {}, iconProps = {}, menuProps = {} }) => {
		const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>(
			undefined,
		);

		const onClick = useCallback(
			(event: React.MouseEvent<HTMLButtonElement>) => {
				event.stopPropagation();
				setAnchorElement(event.currentTarget);
			},
			[],
		);

		const onClose = useCallback(() => {
			setAnchorElement(undefined);
		}, []);

		return (
			<>
				<IconButton
					{...iconButtonProps}
					onClick={onClick}
					aria-controls={id}
					aria-haspopup="true"
					aria-label="More options"
				>
					<MoreVert {...iconProps} />
				</IconButton>
				<Menu
					{...menuProps}
					id={id}
					open={Boolean(anchorElement)}
					anchorEl={anchorElement}
					onClose={onClose}
					onClick={onClose}
				>
					{children}
				</Menu>
			</>
		);
	},
);
