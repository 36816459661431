import { action, makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { formatDate } from 'shared/utilities/dateUtilities';
import { EntityType } from '../../entity4Constants';
import { ValueStatus } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { CommonDocumentStore, DocumentStore } from '../documentStore';
import { IDocumentDto } from '../documentTypes';

const maybeMoment = (str: string | null | undefined) =>
	Boolean(str) ? moment(str) : null;

export type DateFields =
	| 'signedDate'
	| 'expirationDate'
	| 'effectiveFrom'
	| 'effectiveTo'
	| 'uploadedDate';

export type TextFields =
	| 'name'
	| 'description'
	| 'externalUrl'
	| 'fileSize'
	| 'uploadedByName';

export class Document {
	private _store: DocumentStore;
	private _dto: IDocumentDto;

	public signedDate: Moment | null = null;
	public expirationDate: Moment | null = null;
	public effectiveFrom: Moment | null = null;
	public effectiveTo: Moment | null = null;
	public uploadedDate: Moment | null = null;

	private _fieldStatus: Map<keyof IDocumentDto, ValueStatus>;
	private _errorMessage: Map<keyof IDocumentDto, string>;

	public options = null;

	public static dateKeys = [
		'signedDate',
		'expirationDate',
		'effectiveFrom',
		'effectiveTo',
		'uploadedDate',
	];

	constructor(dto: IDocumentDto, store: DocumentStore = CommonDocumentStore) {
		this._store = store;
		this._dto = {
			...dto,
		};

		this.sync(dto);

		this._fieldStatus = new Map();
		this._errorMessage = new Map();

		makeAutoObservable(this, {
			asDto: false,
			sync: action,
			update: action,
		});
	}

	public static make(dto: IDocumentDto) {
		const document = new Document(dto);
		return document;
	}

	public sync(dto: IDocumentDto) {
		const { documentId, ...safeFields } = dto;

		this._dto = {
			...this._dto,
			...safeFields,
		};

		this.signedDate = maybeMoment(safeFields.signedDate);
		this.expirationDate = maybeMoment(safeFields.expirationDate);
		this.effectiveFrom = maybeMoment(safeFields.effectiveFrom);
		this.effectiveTo = maybeMoment(safeFields.effectiveTo);
		this.uploadedDate = maybeMoment(safeFields.uploadedDate);
	}

	public asDto() {
		return {
			...this._dto,
			signedDate: formatDate(this.signedDate),
			expirationDate: formatDate(this.expirationDate),
			effectiveFrom: formatDate(this.effectiveFrom),
			effectiveTo: formatDate(this.effectiveTo),
		} as IDocumentDto;
	}

	get id() {
		return this._dto.documentId;
	}

	get name() {
		return this._dto.name;
	}

	get documentTypeId() {
		return this._dto.documentTypeId;
	}

	get subObjectDisplayName() {
		return this._dto.subObjectDisplayName;
	}

	get description() {
		return this._dto.description;
	}

	get externalUrl() {
		return this._dto.externalUrl;
	}

	get status() {
		return this._dto.status;
	}

	get uploadedById() {
		return this._dto.uploadedById;
	}

	get uploadedByName() {
		return this._dto.uploadedByName;
	}

	get contentType() {
		return this._dto.contentType;
	}

	get fileSize() {
		return this._dto.fileSize;
	}

	public async update<T extends keyof IDocumentDto>(
		entityType: EntityType,
		entityId: string,
		key: T,
		value: IDocumentDto[T]
	) {
		const origValue = this._dto[key];
		try {
			this._dto[key] = value;
			this.setIsWaiting(key);
			await this._store.update(entityType, entityId, this, key, value);
			this.setIsSaved(key);
		} catch (err) {
			this._dto[key] = origValue;
			this.setIsErrored(key, err as string);
		}
	}

	public async download(entityType: EntityType, entityId: string) {
		return await this._store.download(
			entityType,
			entityId,
			this._dto.documentId
		);
	}

	public async remove(entityType: EntityType, entityId: string) {
		await this._store.remove(entityType, entityId, this);
	}

	public isDirty<T extends keyof IDocumentDto>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Dirty;
	}

	public isWaiting<T extends keyof IDocumentDto>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Waiting;
	}

	public isSaved<T extends keyof IDocumentDto>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Saved;
	}

	public isErrored<T extends keyof IDocumentDto>(key: T) {
		return this._fieldStatus.get(key) === ValueStatus.Errored;
	}

	public duplicateNameError<T extends keyof IDocumentDto>(key: T): string {
		if (key === 'name' && this.isErrored(key)) {
			return 'There is already a file with this name for this entity.';
		}

		return '';
	}

	public setIsDirty<T extends keyof IDocumentDto>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Dirty);
	}

	public setIsWaiting<T extends keyof IDocumentDto>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Waiting);
	}

	public setIsSaved<T extends keyof IDocumentDto>(key: T) {
		this._fieldStatus.set(key, ValueStatus.Saved);
		this._errorMessage.set(key, '');
	}

	public setIsErrored<T extends keyof IDocumentDto>(key: T, error: string) {
		this._fieldStatus.set(key, ValueStatus.Errored);
		this._errorMessage.set(key, error);
	}

	public getErrorMessage<T extends keyof IDocumentDto>(key: T) {
		return this._errorMessage.get(key);
	}
}
