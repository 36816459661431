import { Grid, Tab, Tabs } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { useClients } from 'shared/hooks/useClients';
import { useT4Layout } from 'shared/hooks/useT4Layout';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { stonlyData } from 'stonly/functions';
import NotificationListViewModel, {
	T4Notification,
} from './notificationListViewModel';
import {
	useApprovalNavigation,
	useRejectionNavigation,
} from './useNotificationNavigation';

const pendingApprovals = 'Pending Approvals';
const rejectedChanges = 'Rejected Changes';

export const NotificationsPage: FC = observer(() => {
	const { customerApiClient } = useClients();
	const { isAuthor, isApprover } = useUser();
	const { setNotificationsCount } = useT4Layout();
	const approvalNavigation = useApprovalNavigation();
	const rejectionNavigation = useRejectionNavigation();

	const [viewModel] = useState<NotificationListViewModel>(
		new NotificationListViewModel(customerApiClient),
	);
	const tabs = useMemo(() => {
		let availibleTabs: string[] = [];

		if (isApprover) {
			availibleTabs.push(pendingApprovals);
		}
		if (isAuthor) {
			availibleTabs.push(rejectedChanges);
		}

		return availibleTabs;
	}, [isApprover, isAuthor]);
	const [currentTab, setCurrentTab] = useState(tabs?.[0]);

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	useEffect(() => {
		if (!viewModel.isLoading()) {
			setNotificationsCount(viewModel.getNotificationsCount());
		}
	}, [setNotificationsCount, viewModel]);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.notificationsListView}
					title="Notifications"
				/>
			}
		>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', gap: 2 }}
			>
				<Grid item xs="auto" sx={{ width: '100%' }}>
					<Tabs
						color="secondary"
						value={currentTab}
						onChange={(_, newValue) => {
							setCurrentTab(newValue);
						}}
						aria-label="notifications tab"
					>
						{tabs.map((title) => (
							<Tab
								label={title}
								value={title}
								{...stonlyData({
									id: `${title.replace(/\s/g, '-').toLowerCase()}-tab`,
								})}
							/>
						))}
					</Tabs>
				</Grid>
				<Grid item xs={true} sx={{ width: '100%', height: '100%' }}>
					{currentTab === pendingApprovals && (
						<UserPreferencesDataGrid
							tableKey="PendingApprovalListView"
							stonlyId="pending-approval-list"
							columns={[
								{
									field: 'objectName',
									headerName: 'Display Name',
									flex: 20,
									minWidth: 150,
								},
								{
									field: 'objectType',
									headerName: 'Object Type',
									flex: 1,
									minWidth: 150,
								},
								{
									field: 'fieldName',
									headerName: 'Field Changed',
									flex: 20,
									minWidth: 150,
								},
								{
									...getDateColumnDefinition(),
									field: 'dateChanged',
									headerName: 'Date Changed',
									flex: 5,
									minWidth: 150,
								},
								{
									field: 'changedBy',
									headerName: 'Changed By',
									flex: 20,
									minWidth: 150,
								},
							]}
							rows={viewModel.getPendingApprovals()}
							loading={viewModel.isLoading()}
							hideFooter={false}
							errorMessage={viewModel.getError()}
							emptyMessage="No Approvals Required"
							pagination
							showToolbar
							showCustomViewButton
							onCellClick={(params: GridCellParams<T4Notification>) =>
								approvalNavigation(params.row)
							}
							sx={{ height: '70vh' }}
						/>
					)}
					{currentTab === rejectedChanges && (
						<UserPreferencesDataGrid
							tableKey="RejectionListView"
							stonlyId="rejection-list"
							columns={[
								{
									field: 'objectName',
									headerName: 'Display Name',
									flex: 20,
									minWidth: 150,
								},
								{
									field: 'objectType',
									headerName: 'Object Type',
									flex: 1,
									minWidth: 150,
								},
								{
									field: 'fieldName',
									headerName: 'Field Rejected',
									flex: 20,
									minWidth: 150,
								},
								{
									...getDateColumnDefinition(),
									field: 'dateChanged',
									headerName: 'Date Rejected',
									flex: 5,
									minWidth: 150,
								},
								{
									field: 'changedBy',
									headerName: 'Changed By',
									flex: 20,
									minWidth: 150,
								},
								{
									field: 'rejectedBy',
									headerName: 'Rejected By',
									flex: 20,
									minWidth: 150,
								},
							]}
							rows={viewModel.getRejections()}
							loading={viewModel.isLoading()}
							pagination
							hideFooter={false}
							errorMessage={viewModel.getError()}
							emptyMessage="No Rejections to Display"
							showToolbar
							showCustomViewButton
							onCellClick={(params: GridCellParams<T4Notification>) =>
								rejectionNavigation(params.row)
							}
							sx={{ height: '70vh' }}
						/>
					)}
				</Grid>
			</Grid>
		</T4View>
	);
});
