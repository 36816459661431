import { LegalEntityIdentifier } from 'modules/clients/customer-api/src/entity4/entities';
import {
	CreateLegalEntityGroupRequest,
	LegalEntityGroupCollection,
	UpdateLegalEntityGroupRequest,
} from 'modules/clients/customer-api/src/entity4/legalEntityGroups';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { ErrorsObject } from 'utilities/errors/errorUtils';
import { areArraysEqual } from 'utilities/objectUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';

export type UseCreateLegalEntityGroupButtonProps = {
	isLoading: boolean;
	isInitializing: boolean;
	isValidForm: boolean;

	legalEntityIdentifiers: LegalEntityIdentifier[];
	legalEntityGroupCollections: LegalEntityGroupCollection[];

	name: string | null;
	nameError: string | undefined;
	description: string | null;
	descriptionError: string | undefined;
	collection: LegalEntityGroupCollection | null;
	legalEntities: LegalEntityIdentifier[];
	setName: (name: string | null) => void;
	setDescription: (description: string | null) => void;
	setCollection: (collection: LegalEntityGroupCollection) => void;
	setLegalEntities: (legalEntities: LegalEntityIdentifier[]) => void;
	resetForm: () => void;

	initialize: () => Promise<void>;
	submit: () => Promise<boolean>;
};

export type LegalEntityGroupForm = {
	name: string | null;
	description: string | null;
	collection: LegalEntityGroupCollection | null;
	legalEntities: LegalEntityIdentifier[];
};

const emptyState: LegalEntityGroupForm = {
	name: null,
	description: null,
	collection: null,
	legalEntities: [],
};

export const useLegalEntityGroupDrawer = (
	id?: string,
): UseCreateLegalEntityGroupButtonProps => {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const { legalEntityGroupCollections, createGroup, updateGroup } =
		useLegalEntityGroups();

	// #region State

	const [isInitializing, setIsInitializing] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ErrorsObject>({});
	const [legalEntityIdentifiers, setLegalEntityIdentifiers] = useState<
		LegalEntityIdentifier[]
	>([]);

	const [initialForm, setInitialForm] =
		useState<LegalEntityGroupForm>(emptyState);

	const [name, setName] = useState<string | null>(null);
	const [description, setDescription] = useState<string | null>(null);
	const [collection, setCollection] =
		useState<LegalEntityGroupCollection | null>(
			legalEntityGroupCollections.find((x) => x.isDefault) ?? null,
		);
	const [legalEntities, setLegalEntities] = useState<LegalEntityIdentifier[]>(
		[],
	);

	// #endregion

	// #region Initialization

	const initialize = useCallback(async () => {
		try {
			setErrors({});
			setIsInitializing(true);

			const response =
				await customerApiClient.entity4.entities.getIdentifiers();

			if (!response.data.success) {
				throw new Error();
			}
			setLegalEntityIdentifiers(response.data.value);

			if (id) {
				const collection = legalEntityGroupCollections.find((coll) =>
					coll.legalEntityGroups.map((group) => group.id).includes(id),
				);
				const existingGroup = collection!.legalEntityGroups.find(
					(group) => group.id === id,
				);

				setName(existingGroup!.name);
				setDescription(existingGroup!.description ?? null);
				setCollection(collection!);
				setLegalEntities(existingGroup!.legalEntities);

				setInitialForm({
					name: existingGroup!.name,
					description: existingGroup!.description ?? null,
					collection: collection!,
					legalEntities: existingGroup!.legalEntities,
				});
			}
		} catch (error: any) {
			enqueueSnackbar(
				'Could not load legal entities. Please refresh the page and try again.',
				{
					variant: 'error',
				},
			);
		} finally {
			setIsInitializing(false);
		}
	}, [id, legalEntityGroupCollections, enqueueSnackbar, customerApiClient]);

	// #endregion

	// #region Form Functions

	const setFormName = (value: string | null) => {
		setErrors((oldState) => {
			delete oldState['Name'];
			return oldState;
		});
		setName(value);
	};

	const setFormDescription = (value: string | null) => {
		setErrors((oldState) => {
			delete oldState['Description'];
			return oldState;
		});
		if (isStringUndefinedOrNullOrWhitespace(value)) setDescription(null);
		else setDescription(value);
	};

	const isValidForm = useCallback(() => {
		if (isStringUndefinedOrNullOrWhitespace(name)) return false;
		if (collection === null) return false;

		if (id) {
			return !(
				name === initialForm.name &&
				description === initialForm.description &&
				collection.id === initialForm.collection!.id &&
				areArraysEqual(
					legalEntities.map((x) => x.id),
					initialForm.legalEntities.map((x) => x.id),
				)
			);
		}

		return true;
	}, [id, name, description, collection, legalEntities, initialForm]);

	const getLegalEntityChanges = useCallback((): {
		idsToAdd: string[];
		idsToRemove: string[];
	} => {
		const initialIds = initialForm.legalEntities.map((entity) => entity.id);
		const currentIds = legalEntities.map((entity) => entity.id);

		return {
			idsToAdd: currentIds.filter((x) => !initialIds.includes(x)),
			idsToRemove: initialIds.filter((x) => !currentIds.includes(x)),
		};
	}, [legalEntities, initialForm.legalEntities]);

	// #endregion

	// #region Submit Actions

	const create: () => Promise<boolean> = useCallback(async () => {
		try {
			if (id !== undefined) return false;
			if (!isValidForm()) return false;
			setErrors({});
			setIsLoading(true);

			const { idsToAdd } = getLegalEntityChanges();
			const request = {
				name: name!.trim(),
				description: description !== null ? description!.trim() : null,
				collectionId: collection!.id,
				legalEntityIds: idsToAdd,
			} as CreateLegalEntityGroupRequest;

			const response = await createGroup(request);
			if (!response.success) {
				setErrors(response.errors);
				return false;
			}

			enqueueSnackbar('Successfully created entity group.', {
				variant: 'success',
			});
			return true;
		} catch (error: any) {
			enqueueSnackbar(
				'An unexpected error occured when creating the entity group. Please try again.',
				{
					variant: 'error',
				},
			);
			return false;
		} finally {
			setIsLoading(false);
		}
	}, [
		id,
		name,
		description,
		collection,
		getLegalEntityChanges,
		isValidForm,
		createGroup,
		enqueueSnackbar,
	]);

	const update: () => Promise<boolean> = useCallback(async () => {
		try {
			if (!id) return false;
			if (!isValidForm()) return false;
			setErrors({});
			setIsLoading(true);

			const { idsToAdd, idsToRemove } = getLegalEntityChanges();
			const request = {
				name: name!.trim(),
				description: description?.trim(),
				collectionId: collection!.id,
				legalEntityIdsToAdd: idsToAdd,
				legalEntityIdsToRemove: idsToRemove,
			} as UpdateLegalEntityGroupRequest;

			const response = await updateGroup(id, request);
			if (!response.success) {
				setErrors(response.errors);
				return false;
			}

			enqueueSnackbar(`"${name}" was successfully updated.`, {
				variant: 'success',
			});
			return true;
		} catch (error: any) {
			enqueueSnackbar(
				'An unexpected error occured when updating the entity group. Please try again.',
				{
					variant: 'error',
				},
			);
			return false;
		} finally {
			setIsLoading(false);
		}
	}, [
		id,
		name,
		description,
		collection,
		getLegalEntityChanges,
		isValidForm,
		updateGroup,
		enqueueSnackbar,
	]);

	// #endregion

	// #region State Cleanup Functions

	const resetForm = useCallback(() => {
		setIsInitializing(false);
		setIsLoading(false);
		setErrors({});
		setName(null);
		setDescription(null);
		setCollection(legalEntityGroupCollections.find((x) => x.isDefault) ?? null);
		setLegalEntities([]);
		setInitialForm(emptyState);
	}, [legalEntityGroupCollections]);

	useEffect(() => {
		resetForm();

		return () => {
			resetForm();
		};
	}, [id, resetForm]);

	// #endregion

	return {
		isLoading,
		isInitializing,
		isValidForm: isValidForm(),

		legalEntityIdentifiers,
		legalEntityGroupCollections,

		name,
		nameError: errors['Name']?.join(' '),
		description,
		descriptionError: errors['Description']?.join(' '),
		collection,
		legalEntities,
		setName: setFormName,
		setDescription: setFormDescription,
		setCollection,
		setLegalEntities,
		resetForm,

		initialize,
		submit: !id ? create : update,
	};
};
