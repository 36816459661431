import { ConnectionIndex } from 'modules/clients/customer-api/src/cash4/connections';
import { FC } from 'react';
import { ConfirmFormModal } from 'shared/components/confirmFormModal';
import { useEditConnectionProps } from '../hooks/useEditConnection';
import { ConnectionForm } from './connectionForm';

export type EditConnectionModalProps = {
	isOpen: boolean;
	connectionIndex: ConnectionIndex | undefined;
	onClose: () => void;
	fetchConnections: () => Promise<void>;
};

export const EditConnectionModal: FC<EditConnectionModalProps> = ({
	isOpen,
	connectionIndex,
	onClose,
	fetchConnections,
}) => {
	const {
		loading,
		error,
		errors,
		disableSubmit,
		connectors,
		connector,
		connectionName,
		connectionParameters,
		open,
		close,
		update,
		setConnectionName,
		setConnectionParameter,
		isDirty,
	} = useEditConnectionProps(onClose);

	return (
		<ConfirmFormModal
			loading={loading}
			isOpen={isOpen}
			isDirty={isDirty}
			disableSubmit={disableSubmit}
			modalTitle="Edit Connection"
			cancelModalTitle="Discard Connection Edits?"
			cancelModalDescription="Are you sure you want to discard your changes to this Connection? Your changes will not be saved.."
			onSubmit={async () => {
				await update();
				fetchConnections();
			}}
			onOpen={() => {
				if (connectionIndex) {
					open(connectionIndex);
				}
			}}
			onClose={close}
			submitButtonLabel="Save"
			error={error}
		>
			<ConnectionForm
				readOnly={loading}
				errors={errors}
				connectors={connectors}
				connector={connector}
				connectionName={connectionName}
				connectionParameters={connectionParameters}
				setConnector={() => {}}
				setConnectionName={setConnectionName}
				setConnectionParameter={setConnectionParameter}
			/>
		</ConfirmFormModal>
	);
};
