import { observer } from 'mobx-react-lite';
import { FC, ReactNode, createContext, useContext } from 'react';

export type SensitiveFieldContextProps = {
	isSensitive: boolean;
	hasRequestedSensitiveFieldData: boolean;
	showField: boolean;
	setShowField: (show: boolean) => void;
	setHasRequestedSensitiveFieldData: (hasRequested: boolean) => void;
};

const SensitiveFieldContext = createContext<SensitiveFieldContextProps>({
	isSensitive: false,
	hasRequestedSensitiveFieldData: false,
	showField: false,
	setShowField: (_: boolean) => {},
	setHasRequestedSensitiveFieldData: (_: boolean) => {},
});

export type SensitiveFieldProviderProps = SensitiveFieldContextProps & {
	children: ReactNode;
};

export const SensitiveFieldProvider: FC<SensitiveFieldProviderProps> = observer(
	({
		isSensitive,
		hasRequestedSensitiveFieldData,
		showField,
		setShowField,
		setHasRequestedSensitiveFieldData,
		children,
	}) => (
		<SensitiveFieldContext.Provider
			value={{
				isSensitive: isSensitive,
				hasRequestedSensitiveFieldData: hasRequestedSensitiveFieldData,
				showField: showField,
				setShowField: setShowField,
				setHasRequestedSensitiveFieldData: setHasRequestedSensitiveFieldData,
			}}
		>
			{children}
		</SensitiveFieldContext.Provider>
	),
);

export function useSensitiveField() {
	return useContext(SensitiveFieldContext);
}
