import { CheckCircle, DoNotDisturbOn } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { observer } from 'mobx-react-lite';
import { FxRateSet } from 'modules/clients/customer-api/src/api/administration';
import moment from 'moment';
import { FC, useCallback, useEffect, useState } from 'react';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { useCash4Configurations } from '../_hooks/useCash4Configurations';

type ProviderType = 'Customer' | 'Treasury4';

export const Cash4ConfigurationsPage: FC = observer(() => {
	const { isAdmin } = useUser();
	const { loading, configurations, update } = useCash4Configurations();

	const [isSaved, setIsSaved] = useState<boolean>(true);
	const [provider, setProvider] = useState<ProviderType>(() =>
		configurations?.isCustomerRateSet ? 'Customer' : 'Treasury4',
	);
	const [currencyCodes, setCurrencyCodes] = useState<string[]>([]);
	const [fxRateSet, setFxRateSet] = useState<FxRateSet>();
	const [reportingCurrency, setReportingCurrency] = useState<string>();
	const [fxRateSets, setFxRateSets] = useState<FxRateSet[]>([]);

	const handleProviderChange = useCallback((value: ProviderType | null) => {
		if (value) {
			setIsSaved(false);
			setReportingCurrency(undefined);
			setFxRateSet(undefined);
			setProvider(value);
			if (value === 'Customer') {
				setCurrencyCodes(['USD']);
			} else {
				setCurrencyCodes([]);
			}
		}
	}, []);

	const handleFxRateSetChange = useCallback(
		(value: FxRateSet | null) => {
			setIsSaved(false);
			setReportingCurrency(undefined);
			setFxRateSet(value ?? undefined);
			if (provider === 'Customer') {
				setCurrencyCodes(value?.baseCurrencies ?? []);
			}
		},
		[provider],
	);

	const handleReportingCurrencyChange = useCallback(
		async (value: string | null) => {
			setIsSaved(false);
			setReportingCurrency(value ?? undefined);

			if (provider && fxRateSet && value) {
				const isCustomerRateSet = provider === 'Customer';
				const fxRateSetId = fxRateSet.id;
				const reportingCurrencyCode = value;

				if (
					isCustomerRateSet !== configurations?.isCustomerRateSet ||
					fxRateSetId !== configurations?.fxRateSetId ||
					reportingCurrencyCode !== configurations?.reportingCurrencyCode
				) {
					await update({
						isCustomerRateSet: isCustomerRateSet,
						fxRateSetId: fxRateSetId,
						reportingCurrencyCode: reportingCurrencyCode,
					});
				}
				setIsSaved(true);
			}
		},
		[
			configurations?.fxRateSetId,
			configurations?.isCustomerRateSet,
			configurations?.reportingCurrencyCode,
			fxRateSet,
			provider,
			update,
		],
	);

	useEffect(() => {
		if (provider === 'Customer') {
			setFxRateSets(configurations?.customerFxRateSets ?? []);
			setCurrencyCodes(
				configurations?.customerFxRateSets.find(
					(x) => x.id === configurations.fxRateSetId,
				)?.baseCurrencies ?? [],
			);
		} else {
			setFxRateSets(configurations?.t4FxRateSets ?? []);
			setCurrencyCodes(['USD']);
		}
	}, [
		configurations?.customerFxRateSets,
		configurations?.fxRateSetId,
		configurations?.t4FxRateSets,
		provider,
	]);

	useEffect(() => {
		if (!loading && configurations) {
			setProvider(configurations?.isCustomerRateSet ? 'Customer' : 'Treasury4');
			setFxRateSet(
				configurations.isCustomerRateSet
					? configurations?.customerFxRateSets?.find(
							(fxRateSet) => fxRateSet.id === configurations?.fxRateSetId,
					  )
					: configurations?.t4FxRateSets?.find(
							(fxRateSet) => fxRateSet.id === configurations?.fxRateSetId,
					  ),
			);
			setReportingCurrency(configurations?.reportingCurrencyCode);
			setIsSaved(true);
		}
	}, [
		configurations,
		configurations?.customerFxRateSets,
		configurations?.fxRateSetId,
		configurations?.isCustomerRateSet,
		configurations?.reportingCurrencyCode,
		configurations?.t4FxRateSets,
		loading,
	]);

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.cash4ConfigurationsPage}
					title="Configurations"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
			hasAccess={isAdmin}
		>
			<Grid container sx={{ gap: 2 }}>
				<Grid container item xs={12} sx={{ gap: 2 }}>
					<Grid container item xs="auto" sx={{ paddingRight: '2rem' }}>
						<Typography variant="h3" sx={{ alignSelf: 'center' }}>
							FX Rate Settings
						</Typography>
					</Grid>
					<Grid container item xs="auto">
						{isSaved ? (
							<CheckCircle color="success" sx={{ alignSelf: 'center' }} />
						) : (
							<DoNotDisturbOn color="warning" sx={{ alignSelf: 'center' }} />
						)}
					</Grid>
					<Grid item xs="auto">
						<Typography
							sx={{ fontSize: '0.9rem' }}
						>{`Last autosave`}</Typography>
						<Typography sx={{ fontSize: '0.9rem' }}>
							{moment(configurations?.lastUpdatedOn).format('h:mm a, ')}
							{moment(configurations?.lastUpdatedOn)
								.format('DD-MMM-YYYY')
								.toUpperCase()}
						</Typography>
					</Grid>
				</Grid>
				<Grid container item xs={12}>
					<T4Autocomplete<ProviderType>
						loading={loading}
						label="Provider"
						required
						disableClearable
						options={['Treasury4', 'Customer']}
						value={provider}
						onChange={(_, value) => handleProviderChange(value)}
						sx={{
							width: '400px',
						}}
					/>
				</Grid>
				<Grid container item xs={12}>
					<T4Autocomplete<FxRateSet>
						loading={loading}
						disabled={!provider}
						label="FX Rate Set"
						required
						options={fxRateSets}
						value={fxRateSet ?? null}
						isOptionEqualToValue={(a, b) => a?.id === b?.id}
						getOptionLabel={(option) => option?.name ?? ''}
						onChange={(_, value) => handleFxRateSetChange(value)}
						sx={{
							width: '400px',
						}}
					/>
				</Grid>
				<Grid container item xs={12}>
					<T4Autocomplete
						loading={loading}
						disabled={!fxRateSet}
						label="Reporting Currency Code"
						required
						options={currencyCodes}
						value={reportingCurrency ?? null}
						onChange={(_, value) => handleReportingCurrencyChange(value)}
						sx={{
							width: '400px',
						}}
					/>
				</Grid>
			</Grid>
		</T4View>
	);
});
