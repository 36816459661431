/* eslint-disable mobx/missing-observer */
import LockIcon from '@mui/icons-material/Lock';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { paths } from 'shared/constants/paths';
import { TransactionListItem } from '../../models';
import { useEditRule } from '../../providers/useEditRule';
import { useManualCategorization } from '../../providers/useManualCategorization';
import { deleteRule } from '../../services';
import { TabulatedRow } from './TabulatedRow';

export type RulesViewProps = {
	transaction: TransactionListItem;
};

export const RulesView: FC<RulesViewProps> = ({ transaction }) => {
	const { setIsManualCategorization } = useEditRule();

	useEffect(() => {
		if (transaction.transactionRuleName === transaction.id) {
			setIsManualCategorization(true);
		} else {
			setIsManualCategorization(false);
		}
	}, [
		setIsManualCategorization,
		transaction.id,
		transaction.transactionRuleName,
	]);

	return (
		<Box
			sx={{
				border: '1px solid black',
				p: 2,
				mt: 2,
				borderRadius: '5px',
			}}
		>
			<RulesHeader transaction={transaction} />
			<TabulatedRow label="Cash Flow Class" value={transaction.cfcName} />
			<TabulatedRow label="Cash Flow Type" value={transaction.cftName} />
			<TabulatedRow label="Cash Flow Subtype" value={transaction.cfstName} />
			<TabulatedRow label="GL Code" value={transaction.glNumber} showBlank="" />
		</Box>
	);
};

export default RulesView;

interface RulesHeaderProps {
	transaction: TransactionListItem;
}

const RulesHeader: React.FC<RulesHeaderProps> = ({ transaction }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const queryClient = useQueryClient();
	const { enqueueSnackbar } = useSnackbar();

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const { clearRule } = useManualCategorization();
	const { setIsEditing, isManualCategorization, setIsManualCategorization } =
		useEditRule();

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRuleClick = () => {
		setIsEditing(true);
		handleClose();
	};

	const handleViewRule = () => {
		handleClose();
		window.open(
			`${paths.cash4.rules.href}/${transaction.transactionRuleId}`,
			'_blank',
		);
	};

	const mutation = useMutation((ruleId: string) => deleteRule(ruleId), {
		onSuccess: () => {
			setIsManualCategorization(false);
			queryClient.invalidateQueries(['transaction', transaction.id]);
			queryClient.invalidateQueries(['transactions']);
			enqueueSnackbar('Rule Deleted', { variant: 'success' });
			clearRule();
			handleClose();
		},
		onError: () => {
			enqueueSnackbar('Rule Delete Operation Failed.', { variant: 'error' });
		},
	});

	const handleRemove = useCallback(() => {
		mutation.mutate(transaction.transactionRuleId);
	}, [mutation, transaction.transactionRuleId]);

	const MenuItems = isManualCategorization
		? [
				<MenuItem onClick={handleRuleClick} key={1} disabled>
					Edit Category
				</MenuItem>,
				<MenuItem onClick={handleRemove} key={2} disabled={mutation.isLoading}>
					Clear Category
				</MenuItem>,
		  ]
		: [
				<MenuItem
					onClick={handleViewRule}
					key={3}
					disabled={!transaction.transactionRuleName}
				>
					View Rule
				</MenuItem>,
				<MenuItem onClick={handleRuleClick} key={4}>
					Manually Categorize
				</MenuItem>,
		  ];

	const ruleName = isManualCategorization
		? 'Manual Categorization'
		: transaction.transactionRuleName || 'No Rule Matched';

	return (
		<>
			<Stack direction="row" spacing={1} alignItems="center">
				<Typography>Rule</Typography>
				<Tooltip title={ruleName}>
					<Typography
						sx={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							...(transaction.transactionRuleName && {
								color: 'secondary.main',
							}),
							fontWeight: '600',
						}}
					>
						{isManualCategorization ? (
							<Box
								display="flex"
								alignItems="center"
								gap={0.5}
								component={'span'}
							>
								<span>Manual Categorization</span>
								<LockIcon fontSize="small" />{' '}
							</Box>
						) : (
							ruleName
						)}
					</Typography>
				</Tooltip>
				<Box flexGrow={1}></Box>
				<IconButton onClick={handleOpen} data-testid={'menu'}>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="rule-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
				>
					{MenuItems}
				</Menu>
			</Stack>
			<Divider sx={{ mt: 1, mb: 1 }} />
		</>
	);
};
