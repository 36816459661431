import { GridCallbackDetails } from '@mui/x-data-grid-pro';
import { flow, makeAutoObservable } from 'mobx';
import {
	DataLists,
	GetDataListsRequest,
} from 'modules/clients/customer-api/src/api/referenceData';
import { EntityType } from '../../../entity4Constants';
import { IRegisteredAgentJurisdictionsListDto } from '../jurisdictionApiTypes';
import { JurisdictionRepository } from '../jurisdictionRepository';
import { Jurisdiction } from './jurisdictionModel';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { removeIdentifierFromReferenceDisplayName } from 'shared/utilities/referenceDataUtilities';

export type RegisteredAgentJurisdiction = {
	id: string;
	location: string;
	streetAddress: string;
	mailingAddress: string;
};

export class RegisteredAgentJurisdictionListViewModel {
	public entityId: string;
	public entityType: EntityType;
	public aspectId: string;
	private _fetchReferenceData: (
		request: GetDataListsRequest,
	) => Promise<DataLists | undefined>;

	public jurisdictions: IRegisteredAgentJurisdictionsListDto[] = [];
	public pageSize: number;

	private _deleteModalOpen = false;
	private _deleteLoading = false;
	private _jurisdictionToDelete: Jurisdiction | null = null;
	private _locations: string[] = [];

	private _deleteAspectModalOpen = false;

	private _loading: boolean;
	private _error: string;

	constructor(
		entityId: string,
		entityType: EntityType,
		aspectId: string,
		fetchReferenceData: (
			request: GetDataListsRequest,
		) => Promise<DataLists | undefined>,
	) {
		makeAutoObservable(this);
		this.entityId = entityId;
		this.entityType = entityType;
		this.aspectId = aspectId;
		this._fetchReferenceData = fetchReferenceData;

		this.pageSize = 25;

		this._loading = false;
		this._error = '';
	}

	get loading(): boolean {
		return this._loading;
	}

	get error(): string | undefined {
		return this._error !== '' ? this._error : undefined;
	}

	get rows(): RegisteredAgentJurisdiction[] {
		return this.jurisdictions.map((x) => {
			return {
				id: x.id,
				location: this.parseLocation(x.country, x.state),
				streetAddress: x.streetAddress,
				mailingAddress: x.mailingAddress,
				entityId: this.entityId,
			};
		});
	}

	public setPageSize = (pageSize: number, _: GridCallbackDetails) => {
		this.pageSize = pageSize;
	};

	public load = flow(function* (
		this: RegisteredAgentJurisdictionListViewModel,
	) {
		try {
			this._loading = true;
			this.setError('');

			const response =
				yield JurisdictionRepository.getRegisteredAgentJurisdictionsList(
					this.entityId,
					this.entityType,
				);
			this.jurisdictions = response;

			const referenceDataResponse: DataLists = yield this._fetchReferenceData({
				referenceListNames: ['Countries', 'CountrySubdivisions'],
				optionListIds: [],
			});
			if (referenceDataResponse) {
				this.parseLocations(
					referenceDataResponse.referenceLists['Countries'] ?? [],
					referenceDataResponse.referenceLists['CountrySubdivisions'] ?? [],
				);
			}
		} catch (error) {
			this.setError(error);
		} finally {
			this._loading = false;
		}
	});

	private parseLocation = (
		country: ReferenceDataValue,
		state?: ReferenceDataValue | null,
	) => {
		const parsedCountry = removeIdentifierFromReferenceDisplayName(country);
		if (state !== undefined && state !== null) {
			const parsedState = removeIdentifierFromReferenceDisplayName(state);
			return `${parsedCountry}, ${parsedState}`;
		}

		return parsedCountry;
	};

	private parseLocations = (
		countries: ReferenceDataValue[],
		states: ReferenceDataValue[],
	) => {
		// state is nullable so it is possible for a user to only select a country as a valid location
		const countriesOnly: string[] = [
			...countries.map((x) => removeIdentifierFromReferenceDisplayName(x)),
		].sort();

		let countryStateMatches: string[] = [];
		countries.forEach((country) => {
			const matchingStates = states.filter(
				(state) => state.parentIdentifier === country.identifier,
			);
			countryStateMatches = countryStateMatches.concat(
				matchingStates.map((state) => this.parseLocation(country, state)),
			);
		});
		countryStateMatches.sort();
		this._locations = [...new Set(countriesOnly.concat(countryStateMatches))];
	};

	public getLocations = () => {
		return this._locations;
	};

	// delete jurisdiction modal functions
	public get deleteModalOpen() {
		return this._deleteModalOpen;
	}

	public get deleteLoading() {
		return this._deleteLoading;
	}

	public openDeleteModal = (targetToDelete: Jurisdiction) => {
		this._deleteModalOpen = true;
		this._jurisdictionToDelete = targetToDelete;
	};

	public closeDeleteModal = () => {
		this._deleteModalOpen = false;
		this._jurisdictionToDelete = null;
	};

	public deleteJurisdiction = async () => {
		try {
			this._deleteLoading = true;
			if (this._jurisdictionToDelete == null) return;

			await JurisdictionRepository.deleteRegisteredAgentJurisdiction(
				this.entityId,
				this.entityType,
				this._jurisdictionToDelete.id,
			);
			this.closeDeleteModal();
			this.load();
		} catch (error) {
			this.setError(error);
		} finally {
			this._deleteLoading = false;
		}
	};

	// delete registered agent details aspect modal functions
	public get deleteAspectModalOpen() {
		return this._deleteAspectModalOpen;
	}

	public openDeleteAspectModal = () => {
		this._deleteAspectModalOpen = true;
	};

	public closeDeleteAspectModal = () => {
		this._deleteAspectModalOpen = false;
	};

	private setError = (error: any) => {
		if (error instanceof Error) this._error = error.message;
		else if (typeof error === 'string') this._error = error;
		else throw new Error(`Unhandled error type.`);
	};
}
