import { List, ListItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { LegalEntityGroupDrawer } from './legalEntityGroupDrawer';
import { LegalEntityGroupCollectionListItem } from './legalEntityGroupsPage';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';

export type LegalEntityGroupActionsProps = {
	isDrawerOpen: boolean;
	closeDrawer: () => void;
	isDeleteModalOpen: boolean;
	closeDeleteModal: () => void;
	group?: LegalEntityGroupCollectionListItem;
};

export const LegalEntityGroupActions: FC<LegalEntityGroupActionsProps> =
	observer(
		({
			group,
			isDrawerOpen,
			closeDrawer,
			isDeleteModalOpen,
			closeDeleteModal,
		}) => {
			const { enqueueSnackbar } = useSnackbar();
			const { isLoading, deleteGroup } = useLegalEntityGroups();

			return (
				<>
					<LegalEntityGroupDrawer
						isOpen={isDrawerOpen}
						closeDrawer={closeDrawer}
						id={group?.id}
					/>

					{group && !isDrawerOpen && (
						<FormModal
							open={isDeleteModalOpen}
							title="Delete entity group"
							description={`Are you sure you want to delete "${group.name}"? ${
								group.legalEntities.length > 0
									? 'Doing so will remove the group from the following legal entities:'
									: ''
							}`}
							submitButtonColor="error"
							submitButtonLabel="Delete"
							submitDisabled={isLoading}
							onSubmit={async () => {
								const response = await deleteGroup(group.id);

								if (response.success) {
									enqueueSnackbar(`"${group.name}" was successfully deleted.`, {
										variant: 'success',
									});
								} else
									enqueueSnackbar(flattenErrors(response.errors), {
										variant: 'error',
									});

								closeDeleteModal();
							}}
							onClose={closeDeleteModal}
							loading={isLoading}
						>
							<List
								sx={{
									listStyleType: 'disc',
									listStylePosition: 'inside',
									fontSize: '.8rem',
									flexWrap: 'wrap',
									maxHeight: '400px',
								}}
							>
								{group.legalEntities.map((legalEntity) => (
									<ListItem
										key={legalEntity.id}
										sx={{ display: 'list-item', paddingY: '4px' }}
									>
										{legalEntity.displayName}
									</ListItem>
								))}
							</List>
						</FormModal>
					)}
				</>
			);
		},
	);
