import { RepositoryBase } from 'models/repositoryBase';
import { OptionDto } from '../../shared/fieldSets/FieldRepository';

export interface GlobalDocumentListItem {
	area: string;
	contentType: string;
	documentName: string;
	entityId: string;
	entityTypeId: string;
	id: string;
	recordName: string;
	status?: string;
	type?: string;
	uploadedByEmail?: string;
	uploadedByName: string;
	uploadedById: string;
	uploadedDate?: string;
	externalUrl?: string;
	expirationDate?: string;
}
export interface DocumentView {
	documentTypes: OptionDto[];
	title?: string;
	document: DocumentDto;
}

export interface FileDownload {
	type: string;
	contents: BlobPart;
}

export interface DocumentDto {
	id: string;
	name?: string;
	type?: OptionDto;
	description?: string;
	status?: string;
	signedDate?: string;
	expirationDate?: string;
	effectiveFrom?: string;
	effectiveTo?: string;
	externalUrl?: string;
	uploadedBy: string;
	uploadedDate: string;
	fileSize: string;
	fileType: string;
	updatedDate: Date;
}

export type UpdateDocumentParams = Pick<
	DocumentDto,
	| 'name'
	| 'type'
	| 'description'
	| 'status'
	| 'signedDate'
	| 'expirationDate'
	| 'effectiveFrom'
	| 'effectiveTo'
	| 'externalUrl'
>;

export class GlobalDocumentsRepository extends RepositoryBase {
	public async getAllDocumentListItems(): Promise<GlobalDocumentListItem[]> {
		const response = await super.get<GlobalDocumentListItem[]>('/documents');

		if (response.success) return response.value;

		throw response.error;
	}

	public async getDocumentView(id: string): Promise<DocumentView> {
		const response = await super.get<DocumentView>(`/documents/${id}`);

		if (response.success) return response.value;

		throw response.errors;
	}

	public async getFile(id: string): Promise<BlobPart> {
		const response = await super.getFile(`/documents/${id}/file`);

		if (response) return response;

		throw new Error('Unable to download file');
	}

	public async updateDocument(
		id: string,
		params: UpdateDocumentParams,
	): Promise<DocumentDto> {
		const response = await super.patch<DocumentDto>(
			`/documents/${id}`,
			params,
			{
				// TODO: set on global scale?
				validateStatus: () => true,
			},
		);

		if (response.success) return response.value;

		throw response.errors;
	}
}
