import { useQuery } from '@tanstack/react-query';
import { ApprovalRule } from 'modules/clients/apiGateway/payments4/approvalRules';
import { T4DataResponse2 } from 'modules/clients/types';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export const useGetAllPaymentApprovalRules = () => {
	const { applicationApiClient } = useClients();

	const loadPaymentApprovalRules = useCallback(async () => {
		try {
			const response =
				await applicationApiClient.payments4.approvalRules.getAll();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<ApprovalRule[]>).data;
			else throw new Error();
		} catch {
			throw new Error(
				'Unable to load payment approval rules. Please try again later.',
			);
		}
	}, [applicationApiClient]);

	return useQuery<ApprovalRule[], Error>(
		['payment-approval-rules'],
		loadPaymentApprovalRules,
		{
			refetchOnWindowFocus: false,
		},
	);
};
