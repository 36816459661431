import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { convertDate, formatReadDate } from 'shared/utilities/dateUtilities';
import calculateColumnWidth from './calculateColumnWidth';

const processColumnDefinitions = (
	columnDefinitions: GridColDef[],
	columnVisibilityModel: GridColumnVisibilityModel,
	rows: readonly any[],
): GridColDef[] => {
	return columnDefinitions.map((columnDefinition) => {
		// if width or flex already defined or hidden, don't calculate
		if (
			columnDefinition.width ||
			columnDefinition.flex ||
			columnVisibilityModel[columnDefinition.field] === false
		)
			return columnDefinition;

		return {
			...columnDefinition,
			width: calculateColumnWidth(
				columnDefinition,
				rows.map((row) => {
					const value = row[columnDefinition.field] ?? null;

					// adjust value formatting for string length based on column definition type
					switch (columnDefinition.type) {
						case 'date':
							return formatReadDate(convertDate(value))?.length ?? null;
						default:
							return value?.length ?? null;
					}
				}),
			),
		};
	});
};
export default processColumnDefinitions;
