import { Alert, Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { brandColors } from 'shared/theme/brandColors';
import { useDataContext } from '../providers/DataProvider';

const RuleMatchCount: React.FC = () => {
	const { rule } = useDataContext();
	return (
		<Alert
			sx={{ bgcolor: brandColors.denim[100] }}
			severity="info"
			icon={<ErrorOutlineIcon sx={{ color: brandColors.denim[500] }} />}
		>
			<span>This rule applies to</span>{' '}
			<strong> {(rule?.menu.transactionCount ?? 0).toLocaleString()} </strong>
			<span>transactions.</span>
		</Alert>
	);
};

export default RuleMatchCount;
