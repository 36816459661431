import { Grid } from '@mui/material';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { FC } from 'react';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { T4Checkbox } from '../../shared/components/atoms/t4Checkbox';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { useFieldView } from '../object/hooks/useFieldView';
import { Collection } from '../object/models/collection';
import { T4Object } from '../object/models/t4Object';
import { ViewType } from '../object/utilities';
import { VisibilityOffIcon } from './visibilityOffIcon';
import { useHeader } from 'shared/providers/contexts/headerContext';

export type BooleanViewProps = {
	object: T4Object;
	field: Field;
	fieldViewType: ViewType;
	collection: Collection | undefined;
};

export const BooleanFieldView: FC<BooleanViewProps> = observer(
	({ object, field, fieldViewType, collection }) => {
		const { viewType } = useProfileView();
		const { updateLastAutoSave } = useHeader();
		const {
			isReadOnly,
			hasPermission,
			showVisibilityIcon,
			error,
			warning,
			label,
			adornmentState,
			value,
			update,
		} = useFieldView<boolean>(
			object,
			field,
			fieldViewType,
			updateLastAutoSave,
			collection,
		);

		return (
			<Grid
				item
				container
				wrap="nowrap"
				xs="auto"
				sx={{
					height: viewType === FieldViews.default ? '56px' : '48px',
					marginTop: viewType === FieldViews.default ? '8px !important' : '0px',
				}}
			>
				<Grid
					container
					item
					wrap="nowrap"
					xs={12}
					sx={{ justifyContent: 'space-between', alignSelf: 'flex-end' }}
				>
					<Grid container item xs="auto" sx={{ alignSelf: 'center' }}>
						{field.description && (
							<Grid
								item
								xs="auto"
								sx={{
									marginLeft: viewType === FieldViews.default ? '0px' : '14px',
									alignSelf: 'center',
								}}
							>
								<T4FieldAdornment
									id={field.id}
									data-testid={'boolean-field-adornment'}
									hasPermission={hasPermission}
									t4AdornmentType={adornmentState}
									title={field.name}
									description={field.description}
									iconButtonProps={{
										sx: {
											...(viewType === FieldViews.default
												? { marginLeft: '-5px' }
												: {}),
										},
									}}
								/>
							</Grid>
						)}

						<Grid
							item
							xs="auto"
							sx={{
								marginLeft:
									viewType === FieldViews.default
										? '6px'
										: field.description
										? '0'
										: '20px',
							}}
						>
							<T4Checkbox
								id={field.id}
								data-testid={`${fieldViewType}-boolean-field-checkbox`}
								label={label}
								value={value ?? false}
								checked={value ?? false}
								disabled={!hasPermission}
								onChange={(event, value) => {
									if (!isReadOnly) {
										update(value);
									} else {
										event.preventDefault();
									}
								}}
								readOnly={isReadOnly}
							/>
						</Grid>
					</Grid>
					{showVisibilityIcon && (
						<Grid
							item
							xs="auto"
							sx={{
								alignSelf: 'center',
								paddingRight:
									viewType === FieldViews.default ? undefined : '14px',
							}}
						>
							<VisibilityOffIcon />
						</Grid>
					)}
				</Grid>
				<T4AlertStack error={error} warning={warning} />
			</Grid>
		);
	},
);
