import { Dialog, Grid, Typography } from '@mui/material';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type C4CancelDialogProps = {
	open: boolean;
	title: string;
	description: string;
	onConfirm: () => void | Promise<void>;
	onCancel: () => void | Promise<void>;
	color?: 'error' | 'warning';
};

export const C4CancelDialog: FC<C4CancelDialogProps> = observer(
	({ open, title, description, color = 'error', onConfirm, onCancel }) => {
		return (
			<Dialog open={open}>
				<Grid container sx={{ gap: 2, padding: '1.5rem' }}>
					<Grid item xs={12}>
						<Typography variant="h3">{title}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1">{description}</Typography>
					</Grid>
					<Grid
						container
						item
						xs={12}
						sx={{ gap: 1, justifyContent: 'flex-end' }}
					>
						<Grid item xs="auto">
							<T4Button color="secondary" onClick={() => onCancel()}>
								Return
							</T4Button>
						</Grid>
						<Grid item xs="auto">
							<T4Button
								color={color}
								variant="contained"
								onClick={() => onConfirm()}
							>
								Discard
							</T4Button>
						</Grid>
					</Grid>
				</Grid>
			</Dialog>
		);
	},
);
