import { Grid, ListItemText, MenuItem, Tooltip } from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueFormatterParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import {
	Payment,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4Link } from 'shared/components/t4Link';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { formatCurrency } from 'utilities/currencyUtils';
import { useGetAllPayments } from '../hooks/usePayments';
import { PaymentDetailsDrawer } from '../paymentDetailsDrawer/paymentDetailsDrawer';
import { SubmitPaymentDrawer } from '../submitPaymentDrawer';

const stonlyIds = {
	grid: 'payment-statuses',
	submitButton: 'submit-payment-button',
};

const dateRangeDefault: DateRange<Moment> = [
	moment().subtract(7, 'days').startOf('day'),
	moment().startOf('day'),
];

export const PaymentStatusPageRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={`${paths.payments4.paymentStatus.href}/:paymentId`.concat(
					validIdRegex,
				)}
				exact
			>
				<PaymentStatusPage />
			</Route>
			<Route path={paths.payments4.paymentStatus.href} exact>
				<PaymentStatusPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};

export const PaymentStatusPage: FC = () => {
	// #region State

	const { payments4 } = useUser();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { paymentId: paymentIdQueryParam } = useParams<{
		paymentId: string | undefined;
	}>();

	const { isLoading, isFetching, data, error, refetch } = useGetAllPayments();
	useEffect(() => {
		if (!isLoading && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	}, [isLoading, error, enqueueSnackbar]);

	const [selectedPaymentId, setSelectedPaymentId] = useState<string | null>(
		null,
	);
	const [dateRange, setDateRange] =
		useState<DateRange<Moment>>(dateRangeDefault);
	useEffect(() => {
		setSelectedPaymentId(paymentIdQueryParam ?? null);
	}, [paymentIdQueryParam]);

	const payments = useMemo(
		() =>
			data?.filter(
				(x) =>
					x.currentStatus !== PaymentStatusTypes[PaymentStatusTypes.Rejected] &&
					moment(x.createdOn).isBetween(
						dateRange[0],
						dateRange[1],
						'days',
						'[]',
					),
			) ?? [],
		[data, dateRange],
	);

	const [isSubmitDrawerOpen, setIsSubmitDrawerOpen] = useState<boolean>(false);

	/**
	 * if user is coming from payment details drawer approvals tab with intention to create a new payment
	 * open submit payment drawer + clear the location state
	 */
	useEffect(() => {
		if (
			history.location?.state?.openSubmitPaymentDrawer === true &&
			isSubmitDrawerOpen === false
		) {
			setIsSubmitDrawerOpen(true);
			let state = { ...history.location.state };
			delete state.openSubmitPaymentDrawer;
			history.replace({ ...history.location, state });
		}
	}, [history, history.location, isSubmitDrawerOpen]);

	// #endregion

	// #region Memoized Values

	const columns: GridColDef[] = useMemo(() => {
		return [
			{
				...getOptionsMenuColDef((params: GridRenderCellParams<Payment>) => (
					<OverflowMenu id="payment-options-menu">
						<MenuItem
							onClick={() => {
								setSelectedPaymentId(params.row.id);
								history.push(
									`${paths.payments4.paymentStatus.href}/${params.row.id}`,
								);
							}}
						>
							<ListItemText>View Details</ListItemText>
						</MenuItem>
					</OverflowMenu>
				)),
			},
			{
				field: 'currentStatus',
				headerName: 'Current Status',
				description: 'Current Status',
				type: 'string',
				minWidth: 150,
				renderCell: (params: GridRenderCellParams<Payment>) => {
					if (
						params.value === PaymentStatusTypes[PaymentStatusTypes.Submitted]
					) {
						return (
							<T4Link
								to={`${paths.payments4.paymentApprovals.href}/${params.row.id}/review`}
							>
								Pending Approval
							</T4Link>
						);
					}

					return params.value;
				},
				sortComparator: (v1: string, v2: string) =>
					PaymentStatusTypes[v1 as keyof typeof PaymentStatusTypes] -
					PaymentStatusTypes[v2 as keyof typeof PaymentStatusTypes],
			},
			{
				...getDateColumnDefinition(),
				field: 'createdOn',
				headerName: 'Created Date',
				description: 'Created Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'valueDate',
				headerName: 'Value Date',
				description: 'Value Date',
			},
			{
				field: 'initiatorName',
				headerName: 'Initiator',
				description: 'Initiator',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.name,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorBank',
				headerName: 'Initiator Bank',
				description: 'Initiator Bank',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.bank.name,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountName',
				headerName: 'Initiator Account',
				description: 'Initiator Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.accountName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountNumber',
				headerName: 'Initiator Account Number',
				description: 'Initiator Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.initiator.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeName',
				headerName: 'Payee',
				description: 'Payee',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.name,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeBank',
				headerName: 'Payee Bank',
				description: 'Payee Bank',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.bank.name,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountName',
				headerName: 'Payee Account',
				description: 'Payee Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.accountName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountNumber',
				headerName: 'Payee Account Number',
				description: 'Payee Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.payee.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'paymentType',
				headerName: 'Payment Type',
				description: 'Payment Type',
				type: 'string',
				minWidth: 125,
			},
			{
				field: 'amount',
				headerName: 'Amount',
				description: 'Amount',
				type: 'number',
				valueFormatter: (params: GridValueFormatterParams) => {
					const row: Payment = params.api.getRow(params.id!)!;
					return formatCurrency(row.instructedAmount.value, {
						currency: row.instructedAmount.currencyCode ?? undefined,
						currencySign: 'accounting',
						currencyDisplay: 'narrowSymbol',
					})?.replace(row.instructedAmount.currencyCode!, '');
				},
				minWidth: DataGridColumnWidths.small,
			},
			{
				field: 'currency',
				headerName: 'CCY',
				description: 'CCY',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<Payment>) =>
					params.row.instructedAmount.currencyCode,
				minWidth: 125,
			},
			{
				field: 'endToEndId',
				headerName: 'End-to-End ID',
				description: 'End-to-End ID',
				type: 'string',
				minWidth: DataGridColumnWidths.medium,
			},
		];
	}, [history]);

	const CreatePaymentButton = useMemo(
		() => (
			<T4Button
				id={stonlyIds.submitButton}
				{...stonlyData({ id: stonlyIds.submitButton })}
				variant="outlined"
				onClick={() => setIsSubmitDrawerOpen(true)}
				disabled={!payments4.isPaymentInitiator || isFetching}
			>
				Create Payment
			</T4Button>
		),
		[payments4, isFetching],
	);

	const SubmitDrawer = useMemo(
		() => (
			<SubmitPaymentDrawer
				isOpen={isSubmitDrawerOpen}
				onClose={() => setIsSubmitDrawerOpen(false)}
				refetch={refetch}
			/>
		),
		[isSubmitDrawerOpen, refetch],
	);

	const PaymentRecordDetails = useMemo(
		() => (
			<PaymentDetailsDrawer
				paymentId={selectedPaymentId}
				onClose={() => {
					setSelectedPaymentId(null);
					history.push(paths.payments4.paymentStatus.href);
				}}
			/>
		),
		[history, selectedPaymentId],
	);

	// #endregion

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.payments4.paymentStatusPage}
					title="Payment Status"
				/>
			}
			loading={isLoading && isFetching}
		>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', gap: 2 }}
			>
				<Grid
					container
					item
					xs="auto"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						gap: 2,
					}}
				>
					<Grid item xs="auto">
						<T4DateRangePicker
							value={dateRange}
							onAccept={(dateRange) => setDateRange(dateRange)}
							disableFuture
							showShortcuts
							shortcutResetDefaults={dateRangeDefault}
							disabled={isLoading || isFetching}
						/>
					</Grid>
					<Grid
						item
						xs
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
						}}
					>
						<Tooltip
							disableFocusListener
							disableTouchListener
							title={
								!payments4.isPaymentInitiator
									? 'You do not have the correct permission to submit payments. Contact your administrator if you require access.'
									: ''
							}
						>
							<span>{CreatePaymentButton}</span>
						</Tooltip>
					</Grid>
				</Grid>

				<Grid item xs={true} sx={{ height: '50vh' }}>
					<UserPreferencesDataGrid<Payment>
						stonlyId={stonlyIds.grid}
						tableKey={stonlyIds.grid}
						columns={columns}
						rows={payments ?? []}
						showToolbar
						showCustomViewButton
						initialState={{
							sorting: {
								sortModel: [
									{
										field: 'createdOn',
										sort: 'desc',
									},
									{
										field: 'currentStatus',
										sort: 'asc',
									},
								],
							},
						}}
					/>
				</Grid>
			</Grid>

			{SubmitDrawer}
			{PaymentRecordDetails}
		</T4View>
	);
};
