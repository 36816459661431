import { AxiosResponse } from 'axios';
import {
	EntityConfig,
	EntityTypeId,
	getEntityType,
} from 'features/entity4/entity4Constants';
import { flow, makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { flattenErrors, parseError } from 'utilities/errors/errorUtils';

export class DeleteRelationshipViewModel {
	public readonly entityType: EntityTypeId;
	public readonly entityId: string;
	private _relationshipId: string | null = null;
	private _refetch: () => CancellablePromise<void>;
	private _error: string | undefined = undefined;
	private _errors: string[] = [];
	private _deleting: boolean = false;
	private _isOpen: boolean = false;

	constructor(
		entityType: EntityTypeId,
		entityId: string,
		refetch: () => CancellablePromise<void>,
	) {
		makeAutoObservable(this);
		this.entityType = entityType;
		this.entityId = entityId;
		this._refetch = () => refetch();
	}

	public get isOpen(): boolean {
		return this._isOpen;
	}

	public get deleting(): boolean {
		return this._deleting;
	}

	public get error(): string | undefined {
		return this._error;
	}
	public getErrors() {
		return this._errors;
	}

	public openModal(relationshipId: string): void {
		this._relationshipId = relationshipId;
		this._isOpen = true;
	}

	public closeModal(): void {
		this._isOpen = false;
	}

	public delete = flow(function* (this: DeleteRelationshipViewModel) {
		try {
			this._error = undefined;
			this._deleting = true;

			if (this._relationshipId) {
				const route = EntityConfig.get(
					getEntityType(this.entityType)!,
				)!.controllerRoute;

				var response: AxiosResponse<ApiResponse<string>> =
					yield customerApi.delete<ApiResponse<string>>(
						`${route}/${this.entityId}/relationships/${this._relationshipId}`,
					);
				if (response.data.error) this._error = response.data.error;
				else if (response.data.errors)
					this._errors = flattenErrors(response.data.errors);
				else {
					this.closeModal();
					this._refetch();
				}
			}
		} catch (error) {
			this._error = parseError(error);
		} finally {
			this._deleting = false;
		}
	});
}
