import { DialogActions, Grid } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4Modal } from 'features/entity4/shared/components/atoms/t4Modal';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Option, Tab } from 'modules/clients/customer-api/src/api/common';
import { FC, useCallback, useMemo, useState } from 'react';
import { T4Object } from '../object/models/t4Object';
import {
	contactCollectionIdentifier,
	phoneNumberCollectionIdentifier,
	phoneNumberTypeIdentifier,
} from './contactsView';
import { useCreatePhoneNumber } from './hooks/useCreatePhoneNumber';

export type CreatePhoneNumberModalProps = {
	object: T4Object;
	tab: Tab;
	parentCollectionId: string | undefined;
	open: boolean;
	onClose?: () => void;
};

export const CreatePhoneNumberModal: FC<CreatePhoneNumberModalProps> = observer(
	({ object, tab, parentCollectionId, open, onClose }) => {
		const { isCreating, error, createCollection } = useCreatePhoneNumber(
			object,
			parentCollectionId,
		);

		const [phoneNumberType, setPhoneNumberType] = useState<Option>();

		const phoneNumberTypes = useMemo(
			() =>
				tab.fieldGroups
					.find((x) => x.identifier === contactCollectionIdentifier)!
					.childFieldGroups.find(
						(x) => x.identifier === phoneNumberCollectionIdentifier,
					)!
					.fields.find((x) => x.identifier === phoneNumberTypeIdentifier)!
					.optionList?.options ?? [],
			[tab.fieldGroups],
		);
		const canSave = useMemo(
			() => phoneNumberType !== undefined && !isCreating,
			[phoneNumberType, isCreating],
		);

		const handleClose = useCallback(() => {
			onClose?.();
			setPhoneNumberType(undefined);
		}, [onClose]);

		const handleSelect = useCallback((option: Option | null) => {
			setPhoneNumberType(option ?? undefined);
		}, []);

		const handleAdd = useCallback(async () => {
			if (phoneNumberType) {
				const result = await createCollection(phoneNumberType);

				if (result) {
					handleClose();
				}
			}
		}, [createCollection, handleClose, phoneNumberType]);

		return (
			<T4Modal
				open={open}
				title="Create Phone Number"
				fullWidth
				actions={
					<DialogActions
						sx={{
							padding: '1.5rem',
							paddingTop: '0.5rem',
						}}
					>
						<Grid
							container
							wrap="nowrap"
							sx={{
								justifyContent: 'flex-end',
								gap: 1,
							}}
						>
							<Grid item xs="auto">
								<T4Button color="secondary" onClick={() => handleClose()}>
									Cancel
								</T4Button>
							</Grid>
							<Grid item xs="auto">
								<T4Button
									variant="contained"
									disabled={!canSave}
									onClick={() => handleAdd()}
								>
									Add
								</T4Button>
							</Grid>
						</Grid>
					</DialogActions>
				}
			>
				<Grid container sx={{ gap: 2 }}>
					{error && (
						<Grid item xs={12}>
							<T4AlertStack error={error} />
						</Grid>
					)}
					<Grid item xs={12}>
						<T4Autocomplete<Option>
							label="Phone Number Type"
							options={phoneNumberTypes}
							getOptionLabel={(option) => option?.displayName}
							value={phoneNumberType ?? null}
							onChange={(_, value) => handleSelect(value)}
						/>
					</Grid>
				</Grid>
			</T4Modal>
		);
	},
);
