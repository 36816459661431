import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'shared/constants/paths';
import { getEntityTypeFromEntityName } from '../entity4Constants';
import { T4Notification } from './notificationListViewModel';

export const useApprovalNavigation = () => {
	const history = useHistory();

	return (notification: T4Notification) => {
		if (notification !== undefined) {
			let path = generatePath(paths.entity4.objects.object.information.href, {
				objectType: getEntityTypeFromEntityName(notification.objectType),
				objectId: notification.objectId,
			});

			if (notification.parentObjectId && notification.parentObjectType) {
				path = generatePath(
					paths.entity4.objects.object.subaccounts.subaccount.href,
					{
						objectType: getEntityTypeFromEntityName(
							notification.parentObjectType,
						),
						objectId: notification.parentObjectId,
						subaccountId: notification.objectId,
					},
				);
			}

			path += '?view=review';

			history.push(path);
		}
	};
};

export const useRejectionNavigation = () => {
	const history = useHistory();

	return (notification: T4Notification) => {
		if (notification !== undefined) {
			let path = generatePath(paths.entity4.objects.object.information.href, {
				objectType: getEntityTypeFromEntityName(notification.objectType),
				objectId: notification.objectId,
			});

			if (notification.parentObjectId && notification.parentObjectType) {
				path = generatePath(
					paths.entity4.objects.object.subaccounts.subaccount.href,
					{
						objectType: getEntityTypeFromEntityName(
							notification.parentObjectType,
						),
						objectId: notification.parentObjectId,
						subaccountId: notification.objectId,
					},
				);
			}

			path += '?view=edit';

			history.push(path);
		}
	};
};
