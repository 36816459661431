import { AppBar, Grid, Toolbar, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { stonlyData } from 'stonly/functions';

export type VisualizationViewProps = {
	stonlyId: string;
	title: string;
	headerElements: ReactNode[];
};

export const VisualizationView: FC<VisualizationViewProps> = observer(
	({ stonlyId, title, headerElements }) => (
		<AppBar position="relative" sx={{ backgroundColor: 'white', zIndex: 5 }}>
			<Toolbar
				sx={{
					padding: '1rem',
					paddingX: '2rem',
				}}
			>
				<Grid
					container
					sx={{
						width: '100%',
						justifyContent: 'space-between',
						gap: 2,
					}}
				>
					<Grid item xs="auto">
						<Typography
							{...stonlyData({
								id: `${stonlyId}-page-title`,
							})}
							noWrap
							sx={{
								fontWeight: 'lighter',
								fontSize: '2rem',
								color: '#414042',
							}}
						>
							{title}
						</Typography>
					</Grid>
					{headerElements.map((x, index) => (
						<Grid
							key={index}
							item
							xs="auto"
							sx={{
								alignSelf: 'center',
							}}
						>
							{x}
						</Grid>
					))}
				</Grid>
			</Toolbar>
		</AppBar>
	),
);
