import {
	DataImport,
	Template,
} from 'modules/clients/customer-api/src/entity4/dataImports';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { downloadFile } from 'utilities/fileUtilities';
import { useQueue } from '../../../../shared/hooks/useQueue';

export type UseDataImportsProps = {
	loading: boolean;
	loadErrors: string[];

	dataImports: DataImport[];
	templates: Template[];

	getErroredRecordCount: (dataImport: DataImport) => number;
	getSuccessfulRecordCount: (dataImport: DataImport) => number;
	load: () => Promise<void>;
	isOriginalDownloading: (dataImportId: string) => boolean;
	queueOriginalFileDownload: (dataImportId: string) => void;
	isErrorDownloading: (dataImportId: string) => boolean;
	queueErrorFileDownload: (dataImportId: string) => void;
};

export function useDataImports(): UseDataImportsProps {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(true);
	const [loadErrors, setLoadErrors] = useState<string[]>([]);
	const [dataImports, setDataImports] = useState<DataImport[]>([]);
	const [templates, setTemplates] = useState<Template[]>([]);

	const downloadQueue = useQueue();
	const [isDownloading, setIsDownloading] = useState<{
		[key: string]: {
			original: boolean;
			error: boolean;
		};
	}>({});

	const load = useCallback(async () => {
		try {
			setLoading(true);
			setLoadErrors([]);

			const dataImportsResult =
				await customerApiClient.entity4.dataImports.getAll();
			if (dataImportsResult.data.data) {
				setDataImports(dataImportsResult.data.data);
			} else {
				throw new Error('No data returned.');
			}

			const templatesResult =
				await customerApiClient.entity4.dataImports.dashboard();
			if (templatesResult.data.data) {
				setTemplates(templatesResult.data.data.templates);
			} else {
				throw new Error('No data returned.');
			}
		} catch {
			setLoadErrors(['An error occurred.']);
		} finally {
			setLoading(false);
		}
	}, [customerApiClient]);

	const getErroredRecordCount = (dataImport: DataImport): number => {
		return dataImport.details.filter((x) => Object.entries(x.errors).length > 0)
			.length;
	};

	const getSuccessfulRecordCount = (dataImport: DataImport): number => {
		return dataImport.details.filter(
			(x) => Object.entries(x.errors).length === 0,
		).length;
	};

	// #region Download Files

	const downloadOriginalFile = useCallback(
		async (dataImportId: string | undefined) => {
			if (dataImportId === undefined) return;

			const dataImport = dataImports.find((x) => x.id === dataImportId);
			if (dataImport === undefined) return;

			try {
				setIsDownloading((prev) => ({
					...prev,
					[dataImportId]: {
						...prev[dataImportId],
						original: true,
					},
				}));
				const response =
					await customerApiClient.entity4.dataImports.downloadOriginalFile(
						dataImportId,
					);

				if (response.status === 200 && response.data) {
					const fileName = `${dataImport.fileName}.${dataImport.fileType}`;
					const contentType = response.headers['content-type'];
					const file = new File([response.data], fileName, {
						type: contentType,
					});

					downloadFile(file, contentType, fileName);
				} else {
					throw new Error();
				}
			} catch (error: any) {
				enqueueSnackbar('System failed to download original file.', {
					variant: 'error',
				});
			} finally {
				setIsDownloading((prev) => ({
					...prev,
					[dataImportId]: {
						...prev[dataImportId],
						original: false,
					},
				}));
			}
		},
		[dataImports, customerApiClient, enqueueSnackbar],
	);

	const downloadErrorFile = useCallback(
		async (dataImportId: string | undefined) => {
			if (dataImportId === undefined) return;

			const dataImport = dataImports.find((x) => x.id === dataImportId);
			if (dataImport === undefined) return;

			try {
				setIsDownloading((prev) => ({
					...prev,
					[dataImportId]: {
						...prev[dataImportId],
						error: true,
					},
				}));
				const response =
					await customerApiClient.entity4.dataImports.downloadErrorFile(
						dataImportId,
					);

				if (response.status === 200 && response.data) {
					const fileName = `${dataImport.fileName}_errors.xlsx`;
					const contentType = response.headers['content-type'];
					const file = new File([response.data], fileName, {
						type: contentType,
					});

					downloadFile(file, contentType, fileName);
				} else {
					throw new Error();
				}
			} catch (error: any) {
				enqueueSnackbar('System failed to download error file.', {
					variant: 'error',
				});
			} finally {
				setIsDownloading((prev) => ({
					...prev,
					[dataImportId]: {
						...prev[dataImportId],
						error: false,
					},
				}));
			}
		},
		[dataImports, customerApiClient, enqueueSnackbar],
	);

	const queueOriginalFileDownload = useCallback(
		(dataImportId: string) => {
			downloadQueue.addTask(
				async () => await downloadOriginalFile(dataImportId),
			);
		},
		[downloadQueue, downloadOriginalFile],
	);

	const queueErrorFileDownload = useCallback(
		(dataImportId: string) => {
			downloadQueue.addTask(async () => await downloadErrorFile(dataImportId));
		},
		[downloadQueue, downloadErrorFile],
	);

	const isOriginalDownloading = useCallback(
		(dataImportId: string): boolean => {
			return isDownloading[dataImportId]?.original ?? false;
		},
		[isDownloading],
	);

	const isErrorDownloading = useCallback(
		(dataImportId: string): boolean => {
			return isDownloading[dataImportId]?.error ?? false;
		},
		[isDownloading],
	);

	// #endregion

	return {
		loading,
		loadErrors,

		dataImports,
		templates,

		getErroredRecordCount,
		getSuccessfulRecordCount,
		load,
		isOriginalDownloading,
		queueOriginalFileDownload,
		isErrorDownloading,
		queueErrorFileDownload,
	};
}
