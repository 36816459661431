export const roles = {
	Everyone: 'Everyone',
	Approver: 'Approver',
	Author: 'Author',
	Viewer: 'Viewer',
	Admin: 'Admin',
	AccountUser: 'Account User',
	StaffUser: 'Staff User',
	Entity4DataImporter: 'Entity4 Data Importer',

	//#region Cash4

	Cash4Author: 'Cash4 Author',
	Cash4Viewer: 'Cash4 Viewer',
	Cash4DataImporter: 'Cash4 Data Importer',
	Cash4IntegrationsAdmin: 'Cash4 Integrations Admin',

	//#endregion

	//#region Payments4
	Payments4: {
		Admin: 'Payments4 Admin',
		PaymentInitiator: 'Payments4 Payment Initiator',
		PaymentApprover: 'Payments4 Payment Approver',
		TemplateCreator: 'Payments4 Template Creator',
	},

	//#endregion
};
