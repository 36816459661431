import { CircularProgress, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, Ref } from 'react';
import { ACCESS_DENIED_MESSAGING } from '../constants/cannotDisplayMessaging';
import { CannotDisplay } from './cannotDisplay';

export type T4ViewProps = {
	loading?: boolean;
	hasAccess?: boolean;
	header?: ReactNode;
	disablePadding?: true;
	children: ReactNode;
	containerRef?: Ref<HTMLDivElement> | null;
};

export const T4View: FC<T4ViewProps> = observer(
	({
		loading = false,
		hasAccess = true,
		header,
		disablePadding,
		containerRef = null,
		children,
	}) => (
		<Grid
			container
			direction="column"
			wrap="nowrap"
			sx={{
				width: '100%',
				height: '100%',
			}}
		>
			{header && !loading && (
				<Grid item xs="auto" sx={{ zIndex: 100, position: 'sticky', top: 0 }}>
					{header}
				</Grid>
			)}
			<Grid
				ref={containerRef}
				container
				item
				xs={true}
				sx={{
					...(disablePadding === undefined
						? {
								padding: '2rem',
						  }
						: {}),
					justifyContent: 'center',
					overflowY: 'auto',
				}}
			>
				{loading ? (
					<Grid
						item
						xs="auto"
						sx={{
							width: '100%',
							...(disablePadding === true
								? {
										paddingY: '1.5rem',
								  }
								: {}),
						}}
					>
						<CircularProgress />
					</Grid>
				) : (
					<Grid
						item
						xs={12}
						sx={{ height: '100%', width: '100%', flexWrap: 'nowrap' }}
					>
						{hasAccess ? (
							children
						) : (
							<CannotDisplay
								headingText={ACCESS_DENIED_MESSAGING.HEADING}
								bodyText={ACCESS_DENIED_MESSAGING.BODY}
								imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
							/>
						)}
					</Grid>
				)}
			</Grid>
		</Grid>
	),
);
