import { MenuItem } from '@mui/material';
import { AdminViewModes } from 'features/administration/providers/contexts/adminViewModeContext';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { stonlyData } from 'stonly/functions';
import { useGroupNavigation } from '../useGroupNavigation';

interface IGroupOptionMenuProps {
	groupId: string;
}
export const dataTestIds = {
	securityGroupsListViewDetails: 'security-groups-list-view-details',
	overflowMenuButton: 'overflow-menu-button',
	securityGroupsListEditDetails: 'security-groups-list-edit-details',
};
export const stonlyIds = {
	securityGroupsListViewDetails: 'security-groups-list-view-details',
	securityGroupsListEditDetails: 'security-groups-list-edit-details',
	securityGroupsListOverflowMenuDropdown:
		'security-groups-list-overflow-menu-dropdown',
};

export const GroupOptionsMenu: FC<IGroupOptionMenuProps> = observer(
	({ groupId }) => {
		const groupNavigation = useGroupNavigation();

		return (
			<OverflowMenu
				id={`groupId-more-menu-${groupId}`}
				iconButtonProps={{
					'data-testid': dataTestIds.overflowMenuButton,
					...stonlyData({
						id: stonlyIds.securityGroupsListOverflowMenuDropdown,
					}),
				}}
			>
				<MenuItem
					onClick={() => groupNavigation(groupId)}
					{...stonlyData({ id: stonlyIds.securityGroupsListViewDetails })}
					data-testid={dataTestIds.securityGroupsListViewDetails}
				>
					View Details
				</MenuItem>
				<MenuItem
					onClick={() => groupNavigation(groupId, AdminViewModes.edit)}
					{...stonlyData({ id: stonlyIds.securityGroupsListEditDetails })}
					data-testid={dataTestIds.securityGroupsListEditDetails}
				>
					Edit Details
				</MenuItem>
			</OverflowMenu>
		);
	},
);
