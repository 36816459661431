import { flow, makeAutoObservable } from 'mobx';
import { parseError } from 'utilities/errors/errorUtils';
import { EntityType } from '../../../../entity4Constants';
import { TaxIdStatusData } from './taxId';
import { TaxIdRepository } from './taxIdRepository';

export interface TaxIdListItemData {
	taxIdId: string;
	purposes: string[];
	taxIdNumber: string | null;
	isPrimaryFederalIncomeTaxId: boolean;
	location: string | null;
	issuedDate: string | null;
	status: TaxIdStatusData | null;
}

export class TaxIds {
	public entityId: string;
	public entityType: EntityType;
	private taxIdRepository: TaxIdRepository;

	private data: TaxIdListItemData[] = [];

	private _error: string = '';
	private _loading: boolean = false;
	private _isDeletingTaxId: boolean = false;

	constructor(
		entityId: string,
		entityType: EntityType,
		taxIdRepository?: TaxIdRepository,
	) {
		makeAutoObservable(this);

		this.entityId = entityId;
		this.entityType = entityType;
		this.taxIdRepository = taxIdRepository ?? new TaxIdRepository(entityType);
	}

	public get loading() {
		return this._loading;
	}

	public get isDeletingTaxId() {
		return this._isDeletingTaxId;
	}

	public get error(): string | undefined {
		return this._error !== '' ? this._error : undefined;
	}

	public fetch = flow(function* (this: TaxIds) {
		try {
			this._loading = true;
			this.data = yield this.taxIdRepository.getTaxIds(this.entityId);
		} catch (error) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});

	public deleteTaxId = flow(function* (this: TaxIds, taxIdId: string) {
		try {
			this._isDeletingTaxId = true;
			yield this.taxIdRepository.deleteTaxId(this.entityId, taxIdId);
			yield this.fetch();
		} finally {
			this._isDeletingTaxId = false;
		}
	});

	public get taxIds() {
		return this.data;
	}
}
