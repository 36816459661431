import { Grid, ListItemText, MenuItem, Tooltip } from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import {
	PaymentTemplate,
	PaymentTemplateStatusTypes,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { FormModal } from 'shared/components/formModal';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { useGetAllPaymentTemplates } from '../hooks/usePaymentTemplates';
import { SubmitPaymentDrawer } from '../submitPaymentDrawer';
import { CreatePaymentTemplateDrawer } from './createPaymentTemplateDrawer';
import { TemplateDetailsDrawer } from './templateDetailsDrawer';

const stonlyIds = {
	grid: 'payment-templates',
	optionsMenu: 'payment-template-option-menu',
	sumbmitPaymentMenuItem: 'option-menu-submit-payment',
	deleteTemplateMenuItem: 'option-menu-delete-template',
	createButton: 'create-payment-template-button',
};

export const TemplatesPageRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={`${paths.payments4.templates.href}/:templateId`.concat(
					validIdRegex,
				)}
				exact
			>
				<TemplatesPage />
			</Route>
			<Route path={paths.payments4.templates.href} exact>
				<TemplatesPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};

export const TemplatesPage: FC = () => {
	// #region State

	const { payments4 } = useUser();
	const { applicationApiClient } = useClients();
	const { fetch } = useReferenceDataFetcher();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { templateId: templateIdQueryParam } = useParams<{
		templateId: string | undefined;
	}>();

	const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState<boolean>(false);
	const [templateIdToDelete, setTemplateIdToDelete] = useState<string | null>(
		null,
	);
	const [isDeleteTemplateLoading, setIsDeleteTemplateLoading] =
		useState<boolean>(false);

	const [currencyCodes, setCurrencyCodes] = useState<string[]>([]);

	const { isLoading, isFetching, data, error, refetch } =
		useGetAllPaymentTemplates();

	useEffect(() => {
		if (!isLoading && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	}, [isLoading, error, enqueueSnackbar]);

	const [templateToSubmitPayment, setTemplateToSubmitPayment] =
		useState<PaymentTemplate | null>(null);

	const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
		null,
	);
	useEffect(
		() => setSelectedTemplateId(templateIdQueryParam ?? null),
		[templateIdQueryParam],
	);

	useEffect(() => {
		(async () => {
			const response = await fetch({
				referenceListNames: ['FunctionalCurrency'],
			});
			if (response) {
				setCurrencyCodes(
					response.referenceLists['FunctionalCurrency'].map((x) => x.value),
				);
			}
		})();

		return () => {
			setCurrencyCodes([]);
		};
	}, [fetch]);

	const deleteTemplate = useCallback(
		async (templateId: string) => {
			try {
				setIsDeleteTemplateLoading(true);
				const response =
					await applicationApiClient.payments4.paymentTemplates.delete(
						templateId,
					);
				if (response.status === 200) {
					enqueueSnackbar('Successfully deleted template.', {
						variant: 'success',
					});
					refetch();
				} else throw new Error();
			} catch {
				enqueueSnackbar('Unable to delete template. Please try again later.', {
					variant: 'error',
				});
			} finally {
				setIsDeleteTemplateLoading(false);
			}
		},
		[refetch, applicationApiClient, enqueueSnackbar],
	);

	// #endregion

	// #region Memoized Values

	const columns: GridColDef[] = useMemo(
		() => [
			{
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<PaymentTemplate>) => (
						<OverflowMenu
							id={stonlyIds.optionsMenu}
							iconButtonProps={{
								...stonlyData({ id: stonlyIds.optionsMenu }),
							}}
						>
							<MenuItem
								onClick={() => {
									setSelectedTemplateId(params.row.id);
									history.push(
										`${paths.payments4.templates.href}/${params.row.id}`,
									);
								}}
							>
								<ListItemText>View Details</ListItemText>
							</MenuItem>
							<Tooltip
								title={
									!payments4.isPaymentInitiator
										? 'You do not have the correct permission to submit payments. Contact your administrator if you require access.'
										: ''
								}
							>
								<span>
									<MenuItem
										{...stonlyData({ id: stonlyIds.sumbmitPaymentMenuItem })}
										onClick={() => setTemplateToSubmitPayment(params.row)}
										disabled={!payments4.isPaymentInitiator}
									>
										<ListItemText>Create Payment</ListItemText>
									</MenuItem>
								</span>
							</Tooltip>
							<Tooltip
								title={
									!payments4.isTemplateCreator
										? 'You do not have the correct permission to delete templates. Contact your administrator if you require access.'
										: ''
								}
							>
								<span>
									<DeleteMenuItem
										{...stonlyData({ id: stonlyIds.deleteTemplateMenuItem })}
										onClick={() => setTemplateIdToDelete(params.row.id)}
										disabled={!payments4.isTemplateCreator}
									/>
								</span>
							</Tooltip>
						</OverflowMenu>
					),
				),
			},
			{
				field: 'name',
				headerName: 'Template Name',
				description: 'Template Name',
				type: 'string',
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'paymentType',
				headerName: 'Payment Type',
				description: 'Payment Type',
				type: 'string',
				minWidth: 125,
			},
			{
				field: 'initiatorName',
				headerName: 'Initiator Name',
				description: 'Initiator Name',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.initiator.name,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorBank',
				headerName: 'Initiator Bank Name',
				description: 'Initiator Bank Name',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.initiator.bank.name,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountName',
				headerName: 'Initiator Account',
				description: 'Initiator Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.initiator.accountName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'initiatorAccountNumber',
				headerName: 'Initiator Account Number',
				description: 'Initiator Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.initiator.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeName',
				headerName: 'Payee Name',
				description: 'Payee Name',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.payee.name,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeBank',
				headerName: 'Payee Bank Name',
				description: 'Payee Bank Name',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.payee.bank.name,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountName',
				headerName: 'Payee Account',
				description: 'Payee Account',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.payee.accountName,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'payeeAccountNumber',
				headerName: 'Payee Account Number',
				description: 'Payee Account Number',
				type: 'string',
				valueGetter: (params: GridValueGetterParams<PaymentTemplate>) =>
					params.row.payee.accountNumber,
				minWidth: DataGridColumnWidths.medium,
			},
			{
				field: 'currencyCode',
				headerName: 'CCY',
				description: 'Currency Code',
				type: 'string',
				minWidth: 125,
			},
			{
				field: 'currentStatus',
				headerName: 'Status',
				description: 'Status',
				type: 'string',
				minWidth: 150,
				renderCell: (params: GridRenderCellParams<PaymentTemplate>) => {
					if (
						params.value ===
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Created]
					) {
						return 'Pending Approval';
					}

					return params.value;
				},
			},
			{
				...getDateColumnDefinition(),
				field: 'createdOn',
				headerName: 'Created Date',
				description: 'Created Date',
			},
		],
		[payments4, history],
	);

	const CreatePaymentTemplateButton = useMemo(
		() => (
			<T4Button
				id={stonlyIds.createButton}
				{...stonlyData({ id: stonlyIds.createButton })}
				variant="outlined"
				onClick={() => setIsCreateDrawerOpen(true)}
				disabled={!payments4.isTemplateCreator || isFetching}
			>
				Create Template
			</T4Button>
		),
		[payments4, isFetching],
	);

	const SubmitDrawer = useMemo(
		() => (
			<SubmitPaymentDrawer
				isOpen={!!templateToSubmitPayment}
				onClose={() => setTemplateToSubmitPayment(null)}
				template={templateToSubmitPayment}
			/>
		),
		[templateToSubmitPayment],
	);

	const CreateTemplateDrawer = useMemo(
		() => (
			<CreatePaymentTemplateDrawer
				isOpen={isCreateDrawerOpen}
				onClose={() => setIsCreateDrawerOpen(false)}
				refetch={refetch}
				currencyCodes={currencyCodes}
			/>
		),
		[isCreateDrawerOpen, refetch, currencyCodes],
	);

	const TemplateDetails = useMemo(
		() => (
			<TemplateDetailsDrawer
				templateId={selectedTemplateId}
				onClose={() => {
					setSelectedTemplateId(null);
					history.push(paths.payments4.templates.href);
				}}
			/>
		),
		[selectedTemplateId, history],
	);

	const DeleteTemplateModalComponent = useMemo(
		() => (
			<FormModal
				title="Delete template?"
				description="This template will be permanently deleted from the system. This action cannot be undone."
				open={!!templateIdToDelete}
				onClose={() => setTemplateIdToDelete(null)}
				onSubmit={async () => {
					if (templateIdToDelete !== null) {
						await deleteTemplate(templateIdToDelete);
						setTemplateIdToDelete(null);
					}
				}}
				loading={isDeleteTemplateLoading}
				submitButtonLabel="Delete"
				submitButtonColor="error"
				submitDisabled={isDeleteTemplateLoading}
			/>
		),
		[templateIdToDelete, deleteTemplate, isDeleteTemplateLoading],
	);

	// #endregion

	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.payments4.templatesPage}
					title="Templates"
				/>
			}
			loading={isLoading && isFetching}
		>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', gap: 2 }}
			>
				<Grid
					item
					xs="auto"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
					}}
				>
					{!payments4.isTemplateCreator ? (
						<Tooltip
							disableFocusListener
							disableTouchListener
							title="You do not have the correct permission to create payment templates. Contact your administrator if you require access."
						>
							<span>{CreatePaymentTemplateButton}</span>
						</Tooltip>
					) : (
						CreatePaymentTemplateButton
					)}
				</Grid>

				<Grid item xs={true} sx={{ height: '50vh' }}>
					<UserPreferencesDataGrid
						stonlyId={stonlyIds.grid}
						tableKey={stonlyIds.grid}
						columns={columns}
						rows={data ?? []}
						showToolbar
						showCustomViewButton
						initialState={{
							sorting: {
								sortModel: [
									{
										field: 'initiatorName',
										sort: 'asc',
									},
									{
										field: 'name',
										sort: 'asc',
									},
								],
							},
						}}
					/>
				</Grid>

				{SubmitDrawer}

				{CreateTemplateDrawer}
				{TemplateDetails}
				{DeleteTemplateModalComponent}
			</Grid>
		</T4View>
	);
};
