import { makeAutoObservable } from 'mobx';

export class NewDocumentForm {
	private _file?: File = undefined;
	private _url?: string = undefined;
	private _name?: string = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	public getFile = () => this._file;
	public getUrl = () => this._url ?? '';
	public getName = () => this._name ?? '';

	public setFile = (file: File | undefined) => {
		this._file = file;
		this._name = file?.name;
	};
	public setUrl = (value: string | null | undefined) =>
		(this._url = value || undefined);
	public setName = (value: string | null | undefined) =>
		(this._name = value || undefined);

	public fileDocumentIsSavable() {
		return (
			this._file !== undefined &&
			this._name !== undefined &&
			this._url === undefined
		);
	}
	public urlDocumentIsSavable() {
		return (
			this._name !== undefined &&
			this._url !== undefined &&
			this._file === undefined
		);
	}
	public fileSelected() {
		return (
			this._file !== undefined
		)
	}
	public urlProvided() {
		return (
			this._url !== undefined
		)
	}
}
