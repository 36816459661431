import { Box, Divider, Grid, Typography } from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import {
	FieldGroup as NewFieldGroup,
	Tab as NewTab,
} from '../../../../modules/clients/customer-api/src/api/common';
import { Collection } from '../object/models/collection';
import { T4Object } from '../object/models/t4Object';
import { HeaderRef } from './entityFieldRenderer';
import {
	renderCollection,
	renderFieldGroup,
	renderFields,
} from './functions/v2EntityFieldRenderer';

export type FieldGroupViewProps = {
	object: T4Object;
	newTab: NewTab;
	newFieldGroup: NewFieldGroup;
	v2Refs: HeaderRef[];
	collection: Collection | undefined;
	hideDivider?: boolean;
};

export const FieldGroupView: FC<FieldGroupViewProps> = observer(
	({
		object,
		newTab,
		newFieldGroup,
		v2Refs,
		collection: parentCollection,
		hideDivider,
	}) => {
		const { entity4StaffPii } = useT4FeatureFlags();
		const { viewType } = useProfileView();

		const fields = renderFields(object, newFieldGroup, entity4StaffPii);

		if (fields.length === 0) return null;

		return (
			<Box>
				<Grid container sx={{ gap: 2 }}>
					<Grid item xs={12}>
						<Typography
							ref={
								v2Refs.find(
									(x) =>
										x.categoryName === newTab.name &&
										x.subcategoryName === newFieldGroup.name,
								)?.ref
							}
							variant="h3"
						>
							{newFieldGroup.name}
						</Typography>
					</Grid>
					{[
						...fields,
						...newFieldGroup.childFieldGroups
							.filter((x) => x.isCollection === false)
							.map((childFieldGroup, index, array) =>
								renderFieldGroup(
									object,
									newTab,
									childFieldGroup,
									v2Refs,
									entity4StaffPii,
									parentCollection,
									array.length - 1 === index,
								),
							),
						...newFieldGroup.childFieldGroups
							.filter((x) => x.isCollection === true)
							.map(
								(collection) =>
									renderCollection(
										object,
										newTab,
										collection,
										parentCollection,
										v2Refs,
									), // only supporting collections in collections
							),
					]
						.sort((a, b) => (a[0] as number) - (b[0] as number))
						.map((x, index) => (
							<Grid
								item
								key={`field-group-item-${newFieldGroup.id}-${index}`}
								xs={12}
							>
								{x[1]}
							</Grid>
						))}
					{!hideDivider && viewType === FieldViews.default && (
						<Grid item xs={12} sx={{ paddingBottom: '0.5rem' }}>
							<Divider />
						</Grid>
					)}
				</Grid>
			</Box>
		);
	},
);
