import { AxiosInstance, AxiosResponse } from 'axios';
import { T4DataResponse, T4DataResponse2 } from 'modules/clients/types';

//#region Read Models

export type Party = {
	id: string;
	type: string;
	name: string;
	accounts?: PartyAccount[];
};

export type PartyAccount = {
	id: string;
	name: string;
	number?: string;
	currencyCode?: string;
};

export type Reconciliation = {
	id: string;
	status: string;
	notes?: string;
	createdOn: string;
	createdBy: string;
	projectedItems: ProjectedItem[];
	reportedItems: ReportedItem[];
};

export type ReportedItem = {
	id: string;
	date: string;
	amount: number;
	currencyCode?: string;
	baiCode?: string;
	bankReference?: string;
	customerReference?: string;
	checkNumber?: string;
	detail?: string;
	note?: string;
	fiTransactionId?: string;
	reconciliationId?: string;
	reconciliationStatus: string;
	createdOn: string;
	categorization?: CategorizationDetail;
	c4Account: AccountInformation;
	e4Account?: AccountInformation;
};

export type ProjectedItem = {
	id: string;
	initiator: PartyInformation;
	payee: PartyInformation;
	date: string;
	amount: number;
	currencyCode: string;
	checkNumber?: string;
	label?: string;
	description?: string;
	reconciliationId?: string;
	reconciliationStatus: string;
	categorization?: CategorizationDetail;
	createdBy: string;
	createdOn: string;
	updatedBy?: string;
	updatedOn?: string;
	deletedBy?: string;
	deletedOn?: string;
};

export type PartyInformation = {
	type: string;
	object?: ObjectInformation;
	account?: AccountInformation;
};

export type BankInformation = {
	code: string;
	name?: string;
};

export type AccountInformation = {
	id: string;
	name: string;
	number: string;
	bank?: BankInformation;
};

export type ObjectInformation = {
	id: string;
	name: string;
};

export type CategorizationDetail = {
	id: string;
	name: string;
	isManual: boolean;
	class: CashFlowDetail;
	type: CashFlowDetail;
	subtype?: CashFlowDetail;
	glCode?: GeneralLedgerDetail;
};

export type CashFlowDetail = {
	id: string;
	code: string;
	name: string;
};

export type GeneralLedgerDetail = {
	id: string;
	code: string;
};

//#endregion

//#region Write Models

export type ProjectedItemReq = {
	amount: number;
	currencyCode: string;
	expectedValueDate: string;
	label?: string;
	description?: string;
	checkNumber?: string;
	initiator: PartyReq;
	payee: PartyReq;
	categorization?: CategorizationReq;
};

export type PartyReq = {
	partyType: string;
	entityId?: string;
	accountId?: string;
};

export type CategorizationReq = {
	classId: string;
	typeId: string;
	subtypeId?: string;
	glCode?: GlCodeReq;
};

export type GlCodeReq = {
	id?: string;
	code?: string;
};

//#endregion

//#region Request Models

export type GetProjectedItemsRequest = {
	reconciliationStatuses?: string[];
};

export type GetReportedItemsRequest = {
	startDate: string;
	endDate: string;
	reconciliationStatuses?: string[];
};

//#endregion

export type Cash4Endpoints = {
	parties: () => Promise<AxiosResponse<T4DataResponse2<Party[]>>>;
	projected: (
		params?: GetProjectedItemsRequest,
	) => Promise<AxiosResponse<T4DataResponse2<ProjectedItem[]>>>;
	reported: (
		params: GetReportedItemsRequest,
	) => Promise<AxiosResponse<T4DataResponse2<ReportedItem[]>>>;
	reconciliations: () => Promise<
		AxiosResponse<T4DataResponse2<Reconciliation[]>>
	>;
	createProjected: (
		data: ProjectedItemReq,
	) => Promise<AxiosResponse<T4DataResponse<string>, ProjectedItemReq>>;
	updateProjected: (
		id: string,
		data: ProjectedItemReq,
	) => Promise<AxiosResponse<T4DataResponse<string>, ProjectedItemReq>>;
};

export function cash4(axiosInstance: AxiosInstance): Cash4Endpoints {
	return {
		parties: async () =>
			await axiosInstance.get<T4DataResponse2<Party[]>>('/api/cash4/parties'),
		projected: async (params) =>
			await axiosInstance.get<T4DataResponse2<ProjectedItem[]>>(
				'/api/cash4/projected',
				{
					params: params,
				},
			),
		reported: async (params) =>
			await axiosInstance.get<T4DataResponse2<ReportedItem[]>>(
				'/api/cash4/reported',
				{
					params: params,
				},
			),
		reconciliations: async () =>
			await axiosInstance.get<T4DataResponse2<Reconciliation[]>>(
				'/api/cash4/reconciliations',
			),
		createProjected: async (data) =>
			await axiosInstance.post<
				T4DataResponse<string>,
				AxiosResponse<T4DataResponse<string>, ProjectedItemReq>
			>('/api/v1.0/cash4/projectedtransactions', data),
		updateProjected: async (id, data) =>
			await axiosInstance.put<
				string,
				AxiosResponse<T4DataResponse<string>, ProjectedItemReq>
			>(`/api/v1.0/cash4/projectedtransactions/${id}`, data),
	};
}
