export const staffPiiFields = [
	't4_field_staff_employee_id_number',
	't4_field_date_of_birth',
	't4_field_country_of_birth',
	't4_field_city_of_birth',
	't4_field_country_of_citizenship_primary',
	't4_field_country_of_citizenship_secondary',
	't4_field_permanent_resident_card_holder',
	't4_field_country_of_tax_id_number',
	't4_field_country_of_passport',
	't4_field_passport_number',
	't4_field_passport_issued_date',
	't4_field_passport_expiration_date',
	't4_field_country_of_drivers_license',
	't4_field_state_province_code_of_drivers_license',
	't4_field_drivers_license_number',
	't4_field_drivers_license_issued_date',
	't4_field_drivers_license_expiration_date',
	't4_field_permanent_residence_card_number',
	't4_field_permanent_residence_card_issued_date',
	't4_field_permanent_residence_card_expiration_date',
	't4_field_staff_home_phone_number',
	't4_field_staff_cell_phone_number',
];
