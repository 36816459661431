import { Alert, Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Address } from './addressModel';
import { AddressTextInput } from './addressTextField';

interface IAddressWrapperProps {
	readOnly?: boolean;
	disabled?: boolean;
	address: Address | null;
	isMailingAddress?: boolean;
}

export const RegisteredAgentAddressWrapper: React.FC<IAddressWrapperProps> = observer(
	({ readOnly = false, disabled = false, address, isMailingAddress }) => {
		if (!address) {
			return <Alert severity="info">Address could not be loaded.</Alert>;
		}

		return (
			<Grid container columnSpacing={2}>
				<Grid item xs={12} sm={6}>
					<AddressTextInput
						address={address}
						fieldKey="buildingNumber"
						readOnly={readOnly}
						disabled={disabled}
						label="Building Number"
						description=""
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<AddressTextInput
						address={address}
						fieldKey="streetName"
						readOnly={readOnly}
						disabled={disabled}
						label="Street Name"
						description=""
					/>
				</Grid>
				{isMailingAddress ? (
					<Grid item xs={12} sm={12}>
						<AddressTextInput
							address={address}
							fieldKey="poBox"
							readOnly={readOnly}
							disabled={disabled}
							label="PO Box"
							description=""
						/>
					</Grid>
				) : null}
				<Grid item xs={12} sm={12}>
					<AddressTextInput
						address={address}
						fieldKey="address1"
						readOnly={readOnly}
						disabled={disabled}
						label="Address 1"
						description=""
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<AddressTextInput
						address={address}
						fieldKey="address2"
						readOnly={readOnly}
						disabled={disabled}
						label="Address 2"
						description=""
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<AddressTextInput
						address={address}
						fieldKey="city"
						readOnly={readOnly}
						disabled={disabled}
						label="City"
						description=""
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<AddressTextInput
						address={address}
						fieldKey="state"
						readOnly={true}
						disabled={disabled}
						label="State"
						description=""
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<AddressTextInput
						address={address}
						fieldKey="postalCode"
						readOnly={readOnly}
						disabled={disabled}
						label="Postal Code"
						description=""
					/>
				</Grid>
			</Grid>
		);
	}
);
