import { AxiosResponse } from 'axios';
import { History } from 'history';
import { flow, makeAutoObservable } from 'mobx';
import {
	DataLists,
	GetDataListsRequest,
} from 'modules/clients/customer-api/src/api/referenceData';
import { ApiResponse } from '../../../../../utilities/api';
import { parseError } from '../../../../../utilities/errors/errorUtils';
import DocumentListViewModel from '../../../documents/models/documentListViewModel';
import { IEntityApiData } from '../../../entities/entitiesApiTypes';
import { EntityRepository } from '../../../entities/entityRepository';
import { Entity } from '../../../entities/objects/entity';
import { EntityType, SubObjectType } from '../../../entity4Constants';

export class SubAccountViewModel {
	private _entityRepository: EntityRepository;

	private _updateLastAutoSave: (autoSaveDate?: Date) => void;

	private _entity?: Entity;
	private _error: string;

	private _documentList: DocumentListViewModel;

	private _loading: boolean = false;

	constructor(
		parentEntityType: EntityType,
		parentEntityId: string,
		aspectType: EntityType,
		entryId: string,
		history: History,
		fetchReferenceData: (
			request: GetDataListsRequest,
		) => Promise<DataLists | undefined>,
		updateLastAutoSave: (autoSaveDate?: Date) => void,
		entityRepository?: EntityRepository,
	) {
		makeAutoObservable(this);

		this._updateLastAutoSave = updateLastAutoSave;

		this._documentList = new DocumentListViewModel(
			parentEntityType,
			parentEntityId,
			history,
			fetchReferenceData,
			entryId,
			EntityType.Subaccount as unknown as SubObjectType,
		);

		this._entityRepository =
			entityRepository ??
			new EntityRepository(
				aspectType,
				entryId,
				parentEntityType,
				parentEntityId,
			);
		this._error = '';
	}

	public load = flow(function* (this: SubAccountViewModel) {
		this._loading = true;
		this.setError('');

		try {
			const subaccountResponse: AxiosResponse<ApiResponse<IEntityApiData>> =
				yield this._entityRepository.get();

			if (!subaccountResponse.data.success) {
				this.setError(
					parseError(
						subaccountResponse.data.error ?? 'Something unexpected happened.',
					),
				);
			} else if (!subaccountResponse.data.value) {
				this.setError(
					parseError(
						subaccountResponse.data.error ?? 'Something unexpected happened.',
					),
				);
			} else {
				const entityData: AxiosResponse<ApiResponse<IEntityApiData>> =
					yield this._entityRepository.get();

				this._entity = new Entity(
					entityData.data.value,
					undefined,
					this._updateLastAutoSave,
				);
			}
		} catch (error) {
			this.setError(parseError(error));
		} finally {
			this._loading = false;
			this._updateLastAutoSave(this._entity?.latestUpdatedDate);
		}
	});

	public get error(): string {
		return this._error;
	}

	public get loading() {
		return this._loading;
	}

	public get entity() {
		return this._entity;
	}

	public get documentList() {
		return this._documentList;
	}

	public get name() {
		const shortName = this._entity?.getField('t4_field_account_short_name')
			?.displayValue;
		const accountCode = this._entity?.getField('t4_field_account_code')
			?.displayValue;
		return shortName ? `${shortName} (${accountCode})` : accountCode;
	}

	private setError = (error: any) => {
		if (error instanceof Error) this._error = error.message;
		else if (typeof error === 'string') this._error = error;
		else throw new Error(`Unhandled error type.`);
	};
}
