import { Box, Grid, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { GroupPathParams } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { FrontendGroupRepository, GroupRepository } from '../groupRepository';
import { SingleGroupRequestApprovalModal } from '../shared/singleGroupRequestApprovalModal';
import { GroupMembersListViewModel } from './groupMembersListViewModel';
import { ManageGroupMembersButton } from './manageGroupMembersButton';

export const GroupMembersListRoute: FC = observer(() => {
	const { groupId } = useParams<GroupPathParams>();
	const frontendGroupsRepository = useMemo(
		() => new FrontendGroupRepository(),
		[],
	);
	const groupRepository = useMemo(() => new GroupRepository(), []);
	const { id } = useUser();
	const [viewModel] = useState(
		new GroupMembersListViewModel(
			groupId!,
			frontendGroupsRepository,
			groupRepository,
			id,
		),
	);

	return <GroupMembersList viewModel={viewModel} />;
});

interface IGroupMembersListProps {
	viewModel: GroupMembersListViewModel;
}

const stonlyIds = {
	groupMembersList: 'group-members-list',
};

export const GroupMembersList: FC<IGroupMembersListProps> = observer(
	({ viewModel }) => {
		useEffect(() => {
			viewModel.loadMembers();
		}, [viewModel]);

		const theme = useTheme();

		return (
			<Grid container sx={{ rowGap: 1.5 }}>
				<Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
					<Grid item xs="auto" />
					<Grid item xs="auto">
						<ManageGroupMembersButton
							viewModel={viewModel.manageMembersViewModel}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Box
						sx={{
							height: '65vh',
							'& .member-pending-request': {
								color: theme.palette.text.disabled,
							},
						}}
					>
						<UserPreferencesDataGrid
							stonlyId={stonlyIds.groupMembersList}
							tableKey="groupMemebersList"
							columns={viewModel.getMemberListColumns()}
							rows={viewModel.getMemberListRows()}
							loading={viewModel.getMemberListLoading()}
							errorMessage={viewModel.getMemberListError()}
							getRowClassName={viewModel.isRowPending}
						/>
					</Box>
				</Grid>
				<SingleGroupRequestApprovalModal
					viewModel={viewModel.manageRequestViewModel}
				/>
			</Grid>
		);
	},
);
