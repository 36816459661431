import { createContext } from 'react';

export interface T4LayoutContextProps {
	organizationCode: string;
	notificationsCount: number;
	setNotificationsCount: (count: number) => void;
	sideMenuOpen: boolean;
	setSideMenuOpen: (arg0: boolean) => void;
}

export const T4LayoutContext = createContext<T4LayoutContextProps>({
	organizationCode: '',
	notificationsCount: 0,
	setNotificationsCount: (_) => {},
	sideMenuOpen: true,
	setSideMenuOpen: (_) => {},
});
