import { T4DateField } from 'features/entity4/shared/components/atoms/t4DateField';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { FC } from 'react';
import {
	canSaveDate,
	getDateWarningMessage,
} from '../../entities/utilities/dateUtilities';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { T4AlertStack } from '../../shared/components/molecules/t4AlertStack';
import { Registration } from '../models/registration';
import { RegistrationFieldAdornment } from './registrationAdornment';

export type RegistrationDatePickerProps = {
	label: string;
	isReadOnly: boolean;
	dateKey:
		| 'expirationDate'
		| 'lastFiledDate'
		| 'registrationDate'
		| 'dissolutionDate';
	registration: Registration;
	description: string;
	maxDate?: Moment;
};

export const RegistrationDatePicker: FC<RegistrationDatePickerProps> = observer(
	({ label, dateKey, isReadOnly, registration, description, maxDate }) => {
		const { updateLastAutoSave } = useHeader();
		const saveDate = (date: Moment | null) => {
			if (canSaveDate(date, maxDate)) {
				registration.changeDate(dateKey, registration[dateKey]);
				updateLastAutoSave();
			}
		};

		return (
			<>
				<T4DateField
					value={registration[dateKey]}
					label={label}
					disabled={isReadOnly}
					onAccept={(date) => {
						registration[dateKey] = date;
						saveDate(date);
					}}
					onChange={(newValue) => {
						registration[dateKey] = newValue;
					}}
					onBlur={() => saveDate(registration[dateKey])}
					slotProps={{
						field: {
							InputProps: {
								startAdornment: (
									<RegistrationFieldAdornment
										isSaved={registration.isSaved(dateKey)}
										isLoading={registration.isWaiting(dateKey)}
										description={description}
										hasError={registration.isErrored(dateKey)}
									/>
								),
							},
						},
					}}
					maxDate={maxDate}
				/>
				<T4AlertStack
					warning={getDateWarningMessage(label, registration[dateKey], maxDate)}
				/>
			</>
		);
	},
);
