import { RepositoryBase } from 'models/repositoryBase';
import { ReferenceDataValue, Option } from 'shared/types/referenceDataTypes';
import { ApiResponse } from 'utilities/api';

export interface RegistrationListItem {
	id: string;
	isHomeRegistration: boolean;
	registrationNumber: string;
	country: ReferenceDataValue;
	state: ReferenceDataValue | null;
	county: string | null;
	city: string | null;
	status: Option | null;
	expirationDate: string | null;
	entityLegalFormCode: ReferenceDataValue | null;
}
interface CreateRegistrationInput {
	country: string;
	stateProvince?: string;
}

export class FrontendRegistrationRepository extends RepositoryBase {
	public async getRegistrations(
		entityId: string,
	): Promise<ApiResponse<RegistrationListItem[]>> {
		return await this.get<RegistrationListItem[]>(
			`api/v1.0/frontend/entity/${entityId}/registrations`,
		);
	}

	public async createRegistration(
		entityId: string,
		data: CreateRegistrationInput,
	): Promise<ApiResponse<string>> {
		return await this.post<string>(
			`api/v1.0/frontend/entity/${entityId}/registrations`,
			data,
		);
	}

	public async deleteRegistration(
		entityId: string,
		registrationId: string,
	): Promise<ApiResponse<string>> {
		return await this.delete<string>(
			`api/v1.0/frontend/entity/${entityId}/registrations/${registrationId}`,
		);
	}
}
