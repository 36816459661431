import { Add, DeleteOutlined } from '@mui/icons-material';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { Box, Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { stonlyData } from 'stonly/functions';
import { MatchCondition } from '../../models';
import { useDataContext } from '../providers/DataProvider';
import { FormInput, useFormContext } from '../providers/FormProvider';
import { dataTestIds, stonlyIds } from '../utilities';
import {
	isValidMatchConditionField,
	isValidMatchConditionOperator,
	isValidMatchConditionValue,
} from '../validation';

interface ConditionsProps {}

const Conditions: React.FC<ConditionsProps> = observer(() => {
	const { matchConditions, addMatchCondition } = useFormContext();

	const canAddCondition = true;

	return (
		<>
			<Grid container item xs={12}>
				<Grid item xs={12} padding={1}>
					<Box sx={{ bgcolor: '#e4e4e5', p: 1 }}>
						<Typography variant="subtitle2">
							This rule applies to transactions that match the following
							conditions
						</Typography>
					</Box>
				</Grid>
			</Grid>
			<Grid container item xs={12} paddingTop={1} rowSpacing={1.5}>
				{matchConditions.map((value, index) => (
					<Condition
						key={index}
						conditionIndex={index}
						matchCondition={value}
					/>
				))}
				{canAddCondition ? (
					<Grid item xs={12} paddingTop={1}>
						<Button
							type="button"
							variant="outlined"
							color="primary"
							startIcon={<Add />}
							data-testid={dataTestIds.addConditionButton}
							{...stonlyData({ id: stonlyIds.addConditionButton })}
							onClick={addMatchCondition}
						>
							Add "And" Condition
						</Button>
					</Grid>
				) : null}
			</Grid>
		</>
	);
});

export default Conditions;

interface ConditionProps {
	conditionIndex: number;
	matchCondition: FormInput<MatchCondition>;
}

const Condition: React.FC<ConditionProps> = observer(
	({ conditionIndex, matchCondition }) => {
		const { fields, operators, getOperatorOperations } = useDataContext();
		const {
			selectConditionField,
			selectConditionOperator,
			handleChangeConditionValue,
			clearConditionValues,
			removeMatchCondition,
			addMatchConditionOr,
			removeMatchConditionOr,
		} = useFormContext();

		const isConditionTypeDisabled = (option: string) => {
			return false;
		};

		const handleSelectConditionField = (
			event: React.ChangeEvent<{}>,
			value: string | null,
		) => {
			selectConditionField(conditionIndex, value);
			selectConditionOperator(conditionIndex, null);
			clearConditionValues(conditionIndex);
		};

		const handleSelectConditionOperator = (
			event: React.ChangeEvent<{}>,
			value: string | null,
		) => {
			if (noOrCondition(value) && matchCondition.field.values.length > 1) {
				enqueueSnackbar({
					message: `${value} operator does not support 'or' operation.`,
					variant: 'error',
				});
			} else {
				selectConditionOperator(conditionIndex, value);
			}
		};

		const handleRemoveMatchCondition = () => {
			if (matchCondition.field.values.length > 1) {
				removeMatchConditionOr(conditionIndex, 0);
			} else {
				removeMatchCondition(conditionIndex);
			}
		};

		const noOrCondition = (operator: string | null): boolean => {
			return !getOperatorOperations(operator ?? '').includes('Or');
		};

		return (
			<Grid
				key={`condition-wrapper-${conditionIndex}`}
				container
				item
				xs={12}
				justifyContent="center"
			>
				<Stack
					direction={'row'}
					alignItems="center"
					gap={1}
					sx={{ width: '100%' }}
				>
					<T4Autocomplete<string>
						data-testid={`field-${conditionIndex}`}
						label="Field"
						options={fields}
						getOptionDisabled={isConditionTypeDisabled}
						getOptionLabel={(option) => option ?? ''}
						isOptionEqualToValue={(option, value) => option === value}
						value={matchCondition.field.field}
						onChange={handleSelectConditionField}
						required
						error={
							!isValidMatchConditionField(
								matchCondition.field.field,
								matchCondition.dirty,
							).valid
						}
						helperText={
							isValidMatchConditionField(
								matchCondition.field.field,
								matchCondition.dirty,
							).helperText
						}
					/>
					<T4Autocomplete<string>
						data-testid={`operator-${conditionIndex}`}
						label="Operator"
						value={matchCondition.field.operator}
						onChange={handleSelectConditionOperator}
						required
						options={operators(matchCondition.field.field!) || []}
						disabled={!matchCondition.field}
						error={
							!isValidMatchConditionOperator(
								matchCondition.field.operator,
								matchCondition.dirty,
							).valid
						}
						helperText={
							isValidMatchConditionOperator(
								matchCondition.field.operator,
								matchCondition.dirty,
							).helperText
						}
					/>
					<T4TextFieldV2
						data-testid={`value-${conditionIndex}`}
						label="Value"
						value={matchCondition.field.values[0] ?? ''}
						onChange={(value) =>
							handleChangeConditionValue(value, conditionIndex, 0)
						}
						required
						error={
							!isValidMatchConditionValue(
								matchCondition.field.values[0] ?? '',
								matchCondition.dirty,
								matchCondition.field.values,
							).valid
						}
						helperText={
							isValidMatchConditionValue(
								matchCondition.field.values[0] ?? '',
								matchCondition.dirty,
								matchCondition.field.values,
							).helperText
						}
					/>
					<IconButton
						data-testid={`${dataTestIds.removeConditionButton}-${conditionIndex}`}
						{...stonlyData({
							id: `${stonlyIds.removeConditionButton}-${conditionIndex}`,
						})}
						onClick={handleRemoveMatchCondition}
					>
						<DeleteOutlined />
					</IconButton>
				</Stack>

				{matchCondition.field.values.slice(1).map((value, index) => (
					<OrCondition
						key={index}
						valueIndex={index + 1}
						conditionIndex={conditionIndex}
						value={value}
						matchCondition={matchCondition}
					/>
				))}
				<Grid item xs={12} paddingTop={1}>
					<SubdirectoryArrowRightIcon />
					<Tooltip title={noOrCondition(matchCondition.field.operator) ? 'The operator selected does not support this action' : ''} placement='right'>
						<span>
							<Button
								variant="outlined"
								color="primary"
								startIcon={<Add />}
								onClick={() => addMatchConditionOr(conditionIndex)}
								disabled={noOrCondition(matchCondition.field.operator)}
							>
								Add "Or" Condition
							</Button>
						</span>
					</Tooltip>
				</Grid>
			</Grid>
		);
	},
);

interface OrConditionProps {
	valueIndex: number;
	conditionIndex: number;
	value: string;
	matchCondition: FormInput<MatchCondition>;
}

const OrCondition: React.FC<OrConditionProps> = observer(
	({ valueIndex, conditionIndex, value, matchCondition }) => {
		const { handleChangeConditionValue, removeMatchConditionOr } =
			useFormContext();

		return (
			<Stack
				direction={'row'}
				alignItems="center"
				gap={1}
				sx={{ width: '100%' }}
			>
				<Typography sx={{ fontWeight: 'bold' }}>OR</Typography>
				<T4TextFieldV2 value={matchCondition.field.field} disabled />
				<T4TextFieldV2 value={matchCondition.field.operator} disabled />
				<T4TextFieldV2
					data-testid={`value-${conditionIndex}-${valueIndex}`}
					label="Value"
					value={value}
					onChange={(value) =>
						handleChangeConditionValue(value, conditionIndex, valueIndex)
					}
					required
					error={
						!isValidMatchConditionValue(
							value,
							matchCondition.dirty,
							matchCondition.field.values,
						).valid
					}
					helperText={
						isValidMatchConditionValue(
							value,
							matchCondition.dirty,
							matchCondition.field.values,
						).helperText
					}
				/>
				<IconButton
					data-testid={`${dataTestIds.removeConditionButton}-${conditionIndex}-${valueIndex}`}
					{...stonlyData({
						id: `${stonlyIds.removeConditionButton}-${conditionIndex}-${valueIndex}`,
					})}
					onClick={() => removeMatchConditionOr(conditionIndex, valueIndex)}
				>
					<DeleteOutlined />
				</IconButton>
			</Stack>
		);
	},
);
