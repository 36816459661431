import { Home } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridValidRowModel,
} from '@mui/x-data-grid-pro';
import {
	TableColumnPreference,
	TableCustomViewPreferences,
} from 'modules/clients/customer-api/src/userPreference';
import { ReactNode } from 'react';

export const primaryIconRenderCell = (
	shouldShowIcon: boolean,
	value: string | null | undefined,
	textColor: string = 'text',
) => {
	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			overflow="hidden"
			textOverflow="ellipsis"
		>
			{shouldShowIcon && (
				<Home
					fontSize="small"
					sx={{ marginRight: '5px' }}
					data-testid="test-icon"
				/>
			)}
			<Typography
				color={textColor}
				variant="body2"
				noWrap
				data-testid="test-text"
			>
				{value ?? null}
			</Typography>
		</Box>
	);
};

export const USER_PREFERENCES_FIELD_OPTIONS = 'options';

export const USER_PREFERENCES_UNHIDEABLE_FIELDS: string[] = [
	USER_PREFERENCES_FIELD_OPTIONS,
];

export const getOptionsMenuColDef = <T extends GridValidRowModel, V = any>(
	renderCell: (
		params: GridRenderCellParams<T, V, any, any>,
	) => ReactNode | null,
): GridColDef<T, V> => {
	return {
		...menuProps,
		field: USER_PREFERENCES_FIELD_OPTIONS,
		description: 'Menu',
		renderCell: renderCell,
	};
};

export const updateColumnWidths = (
	columnPreferences: TableColumnPreference[],
	columns: GridColDef[],
	selectedCustomView?: TableCustomViewPreferences,
): GridColDef[] => {
	let preferences = [...columnPreferences];
	if (selectedCustomView) {
		preferences = [...(selectedCustomView?.columns ?? [])];
	}

	preferences.forEach((cp) => {
		columns.forEach((c) => {
			if (c.field === cp.columnId && cp.width !== 0) {
				c.flex = undefined;
				c.width = cp.width;
			}
		});
	});

	return columns;
};

export const sortColumnsBasedOnUserPreferences = (
	columnPreferences: TableColumnPreference[] | undefined,
	columns: GridColDef[],
	selectedCustomView?: TableCustomViewPreferences,
): GridColDef[] => {
	if (
		!columnPreferences ||
		!columnPreferences.length ||
		!columns ||
		!columns.length
	) {
		return columns;
	}

	let preferences = [...columnPreferences];
	if (selectedCustomView) {
		preferences = [...(selectedCustomView?.columns ?? [])];
	}

	return columns.sort((a, b) => {
		const aPreference = preferences.find((p) => p.columnId === a.field);
		const bPreference = preferences.find((p) => p.columnId === b.field);

		return aPreference?.sortOrder === undefined
			? -1
			: (aPreference?.sortOrder ?? 0) - (bPreference?.sortOrder ?? 0);
	});
};

export const DataGridColumnWidths = {
	twoChar: 50,
	threeChar: 60,
	fourChar: 70,
	xsmall: 85,
	small: 100,
	medium: 200,
	large: 300,
};

export const menuProps: Partial<GridColDef> = {
	headerName: '',
	disableColumnMenu: true,
	disableReorder: true,
	disableExport: true,
	filterable: false,
	sortable: false,
	width: 50,
	align: 'center',
	hideable: false,
};
