import { DeleteOutlined } from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	DialogActions,
	Grid,
	MenuItem,
	Paper,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
	FieldGroup as NewFieldGroup,
	Tab as NewTab,
} from 'modules/clients/customer-api/src/api/common';
import { FC, useCallback, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { useUser } from '../../../../shared/hooks/useUser';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { T4Button } from '../../shared/components/atoms/t4Button';
import { T4Modal } from '../../shared/components/atoms/t4Modal';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { Collection } from '../object/models/collection';
import { T4Object as ObjectData } from '../object/models/t4Object';
import { getObjectInputType } from '../object/utilities';
import { CollectionMenu } from './collectionMenu';
import { HeaderRef } from './entityFieldRenderer';
import {
	renderCollection,
	renderFieldGroup,
	renderFields,
} from './functions/v2EntityFieldRenderer';

export type CollectionItemProps = {
	object: ObjectData;
	newTab: NewTab;
	newFieldGroup: NewFieldGroup;
	collection: Collection;
	v2Refs: HeaderRef[];
};

export const CollectionItem: FC<CollectionItemProps> = observer(
	({ object, newTab, newFieldGroup, collection, v2Refs = [] }) => {
		const { isAuthor } = useUser();
		const { viewType } = useProfileView();
		const { entity4StaffPii } = useT4FeatureFlags();
		const { customerApiClient } = useClients();

		const [isOpen, setIsOpen] = useState(false);

		const [isDeleting, setIsDeleting] = useState<boolean>(false);
		const [error, setError] = useState<string | undefined>();

		const deleteCollection = useCallback(async () => {
			if (object) {
				try {
					setIsDeleting(true);
					setError(undefined);

					const response =
						await customerApiClient.api.objects.collections.delete({
							objectId: object.id,
							objectType: getObjectInputType(object.objectType),
							collectionId: collection.id,
						});

					if (response && response.data && response.data.value) {
						const index = object.collections.findIndex(
							(x) => x.id === collection.id,
						);

						if (index >= 0) {
							object.collections.splice(index, 1);
						}
					}

					if (response && response.data && response.data.error) {
						setError(response.data.error);
					}
					if (
						response &&
						response.data &&
						response.data.errors &&
						(Object.values(response.data.errors)?.length ?? 0) > 0
					) {
						setError(
							error?.concat(flattenErrors(response.data.errors).join(', ')),
						);
					}
				} catch (error) {
					setError('Unable to delete collection.');
				} finally {
					setIsDeleting(false);
				}
			}
		}, [
			collection.id,
			customerApiClient.api.objects.collections,
			error,
			object,
		]);

		return (
			<Paper
				sx={{
					marginY: '0.5rem',
					padding: '1.5rem',
					paddingY: '1rem',
				}}
			>
				<Grid container>
					<Grid
						item
						xs={12}
						textAlign="end"
						sx={{
							minHeight: '30px',
						}}
					>
						{isAuthor && viewType === FieldViews.edit && (
							<CollectionMenu>
								<MenuItem onClick={() => setIsOpen(true)}>
									<DeleteOutlined color="error" />
									Delete
								</MenuItem>
							</CollectionMenu>
						)}
						<T4Modal
							title={`Delete ${newFieldGroup.singularName}`}
							open={isOpen}
							actions={
								<DialogActions
									sx={{
										paddingX: '1.5rem',
										paddingBottom: '1.5rem',
									}}
								>
									<Grid
										display="flex"
										justifyContent="space-between"
										width="100%"
									>
										{isDeleting ? (
											<Box display="flex" alignItems="center">
												<Box marginRight={2}>
													<Typography noWrap={true}>Deleting...</Typography>
												</Box>
												<CircularProgress size={25} />
											</Box>
										) : null}
										<Grid container spacing={1} justifyContent="flex-end">
											<Grid item>
												<T4Button
													id={`collection-deletion-cancel-${newFieldGroup.id}`}
													color="secondary"
													onClick={() => setIsOpen(false)}
												>
													Cancel
												</T4Button>
											</Grid>
											<Grid item>
												<T4Button
													id={`collection-deletion-delete-${newFieldGroup.id}`}
													color="error"
													variant="contained"
													disabled={isDeleting}
													onClick={() =>
														deleteCollection().then(() => {
															setIsOpen(false);
														})
													}
												>
													Delete
												</T4Button>
											</Grid>
										</Grid>
									</Grid>
								</DialogActions>
							}
						>
							{`Would you like to delete this ${newFieldGroup.singularName}?`}
						</T4Modal>
					</Grid>
					<Grid
						container
						item
						rowSpacing={1}
						sx={{
							marginTop: '-30px',
						}}
					>
						{[
							...renderFields(
								object,
								newFieldGroup,
								entity4StaffPii,
								collection,
							),
							...newFieldGroup.childFieldGroups
								.filter((x) => x.isCollection === false)
								.map((fieldGroup) =>
									renderFieldGroup(
										object,
										newTab,
										fieldGroup,
										v2Refs,
										entity4StaffPii,
										collection,
									),
								),
							...newFieldGroup.childFieldGroups
								.filter((x) => x.isCollection === true)
								.map((childCollection) =>
									renderCollection(
										object,
										newTab,
										childCollection,
										collection,
										v2Refs,
									),
								),
						]
							.filter((x) => x[1] !== null)
							.sort((a, b) => (a[0] as number) - (b[0] as number))
							.map((x) => (
								<Grid key={x[0]?.toString()} item xs={12}>
									{x[1]}
								</Grid>
							))}
					</Grid>
				</Grid>
			</Paper>
		);
	},
);
