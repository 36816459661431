import { FC } from 'react';
import { UseSetAccountIntegrationScheduleProps } from '../hooks/useSetAccountIntegrationSchedule';
import { ConfirmFormModal } from 'shared/components/confirmFormModal';
import { TimePicker } from '@mui/x-date-pickers-pro';
import { Grid } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';

export type SetAccountIntegrationScheduleModalProps = {
	setAccountIntegrationSchedule: UseSetAccountIntegrationScheduleProps;
};

export const SetAccountIntegrationScheduleModal: FC<
	SetAccountIntegrationScheduleModalProps
> = ({ setAccountIntegrationSchedule }) => {
	return (
		<ConfirmFormModal
			loading={setAccountIntegrationSchedule.isLoading}
			isOpen={setAccountIntegrationSchedule.isOpen}
			isDirty={setAccountIntegrationSchedule.isDirty}
			disableSubmit={setAccountIntegrationSchedule.isSubmitDisabled}
			error={setAccountIntegrationSchedule.error}
			modalTitle={'Account Integration Schedule'}
			cancelModalTitle={'Discard Schedule?'}
			cancelModalDescription={
				'Are you sure you want to discard this Account Integration Schedule? Your changes will not be saved.'
			}
			onSubmit={setAccountIntegrationSchedule.setAccountIntegrationSchedule}
			onClose={setAccountIntegrationSchedule.closeModal}
			submitButtonLabel="Save"
		>
			<Grid container sx={{ paddingTop: '8px', rowGap: 1 }}>
				<Grid item xs={12}>
					<TimePicker
						key="triggerTime"
						label="Trigger Time"
						value={setAccountIntegrationSchedule.triggerTime}
						onChange={setAccountIntegrationSchedule.setTriggerTime}
						onAccept={setAccountIntegrationSchedule.setTriggerTime}
						slotProps={{
							textField: { fullWidth: true, size: 'small', required: true },
						}}
						ampm={false}
					/>
				</Grid>
				<Grid item xs={12}>
					<T4Autocomplete<string, false, true, false>
						key="triggerTimezone"
						label="Trigger Timezone"
						options={setAccountIntegrationSchedule.timezones}
						value={setAccountIntegrationSchedule.triggerTimezone ?? ''}
						onSelect={setAccountIntegrationSchedule.setTriggerTimezone}
						disableClearable={true}
						required
					/>
				</Grid>
			</Grid>
		</ConfirmFormModal>
	);
};
