import {
	CheckCircleOutline,
	HighlightOff,
	InfoOutlined,
	Lock,
} from '@mui/icons-material';
import {
	CircularProgress,
	IconButton,
	IconButtonProps,
	InputAdornment,
	Tooltip,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { FieldDescription } from '../../fieldSets/fieldViews/common/fieldDescription';
import { T4Modal, T4ModalProps } from '../atoms/t4Modal';

export type T4AdornmentType = 'loading' | 'success' | 'info' | 'error';
export interface T4FieldAdornmentProps {
	id?: string;
	hasPermission?: boolean;
	t4AdornmentType?: T4AdornmentType;
	title?: T4ModalProps['title'];
	description?: string;
	iconButtonProps?: Partial<IconButtonProps>;
}
export const T4FieldAdornment: FC<T4FieldAdornmentProps> = observer(
	({
		id,
		hasPermission = true,
		t4AdornmentType,
		title,
		description,
		iconButtonProps = {},
		...props
	}) => {
		const [isOpen, setIsOpen] = useState(false);

		const setOpen = (isOpen: boolean) => {
			if (description) setIsOpen(true);
		};
		const partialId = id ? `-${id}` : '';

		return (
			<InputAdornment
				id={`t4-field-adornment${partialId}`}
				data-testid="t4-field-adornment"
				position="start"
				{...props}
			>
				{(description || t4AdornmentType !== 'info') && (
					<IconButton
						{...iconButtonProps}
						id={`t4-field-adornment-icon-button${partialId}`}
						data-testid="t4-field-adornment-icon-button"
						onClick={() => setOpen(true)}
						size="small"
					>
						{!hasPermission ? (
							<Tooltip title="You do not have the correct permissions to view or edit this field. Contact your administrator if you require access.">
								<Lock />
							</Tooltip>
						) : t4AdornmentType === 'loading' ? (
							<CircularProgress size={20} />
						) : t4AdornmentType === 'success' ? (
							<CheckCircleOutline color="primary" />
						) : t4AdornmentType === 'error' ? (
							<HighlightOff color="error" />
						) : (
							<InfoOutlined />
						)}
					</IconButton>
				)}
				<T4Modal
					id={`field-adornment${partialId}`}
					title={title}
					onClose={() => setIsOpen(false)}
					open={isOpen}
				>
					<FieldDescription description={description ?? ''} />
				</T4Modal>
			</InputAdornment>
		);
	},
);
