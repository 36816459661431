export const brandColors = {
	charcoal: {
		50: '#D1D2D3',
		100: '#C2C3C4',
		200: '#AEAFB0',
		300: '#939496',
		400: '#6F7172',
		500: '#404142',
		600: '#363738',
		700: '#2E2F30',
		800: '#272829',
		900: '#212222',

		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
		light: '#B2B3B3', // Charcoal at 40% opacity
	},
	equityGreen: {
		50: '#DBE7D6',
		100: '#D0DFC8',
		200: '#C0D5B6',
		300: '#ABC79E',
		400: '#8FB47D',
		500: '#6B9657',
		600: '#5B804A',
		700: '#4D6C3F',
		800: '#425C35',
		900: '#384E2D',
	},
	denim: {
		50: '#CEDBE7',
		100: '#BDCFDF',
		200: '#A8BFD5',
		300: '#8AAAC7',
		400: '#648DB4',
		500: '#426789',
		600: '#385774',
		700: '#304A63',
		800: '#293F54',
		900: '#223648',

		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
		A10: '#EBEFF3',
	},
	maize: {
		50: '#F4EFD9',
		100: '#F0EACC',
		200: '#ECE3BB',
		300: '#E5D9A4',
		400: '#DDCD85',
		500: '#D1BB5D',
		600: '#C7AD3A',
		700: '#AA9430',
		800: '#907D29',
		900: '#7B6B23',
	},
	cornflower: {
		50: '#DDE9ED',
		100: '#D1E2E7',
		200: '#C2D8DF',
		300: '#AECBD5',
		400: '#93BAC7',
		500: '#6FA3B4',
		600: '#558FA3',
		700: '#487A8A',
		800: '#3D6876',
		900: '#345864',
	},
	grey: {
		50: '#E2E2E2',
		100: '#D8D8D8',
		200: '#CBCBCB',
		300: '#BABABA',
		400: '#A3A3A3',
		500: '#848484',
		600: '#707070',
		700: '#5F5F5F',
		800: '#515151',
		900: '#454545',

		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
		legacy: '#848484',
	},
	jasper: {
		50: '#FDE8D9',
		100: '#FDE0CC',
		200: '#FCD6BB',
		300: '#FBC8A4',
		400: '#FAB686',
		500: '#F89E5D',
		600: '#F6802C',
		700: '#EC680A',
		800: '#C95909',
		900: '#AB4B07',
	},
	pomp: {
		50: '#E8DDEC',
		100: '#E1D1E6',
		200: '#D6C2DD',
		300: '#C9ADD2',
		400: '#B792C3',
		500: '#9F6EAF',
		600: '#8B569C',
		700: '#764985',
		800: '#643E71',
		900: '#553560',
	},
	debtRed: {
		50: '#F4BFC1',
		100: '#F0A9AC',
		200: '#EB8D90',
		300: '#E4676B',
		400: '#DB343A',
		500: '#A21D21',
		600: '#8A191C',
		700: '#751518',
		800: '#641215',
		900: '#550F11',

		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
		A15: '#F5DEDD',
	},
	errorRed: {
		50: '#F6C5C4',
		100: '#F3B1B0',
		200: '#EE9796',
		300: '#E97472',
		400: '#E24644',
		500: '#BF211E',
		600: '#A21C19',
		700: '#8A1816',
		800: '#751412',
		900: '#641110',
		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
		A100: '#DB303C',
		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
		A80: '#E25963',
		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
		A15: '#F9E0E0',
		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
		A10: '#FCEAEB',
		/**
		 * @deprecated This color needs to be updated to follow the MUI color naming conventions.
		 */
	},
	toffee: {
		50: '#F3E8DE',
		100: '#EFE0D2',
		200: '#EAD5C3',
		300: '#E3C8B0',
		400: '#D9B595',
		500: '#CD9C72',
		600: '#C0844F',
		700: '#AA6F3C',
		800: '#905F33',
		900: '#7B512C',
	},
};
