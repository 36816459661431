import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { parseError } from 'utilities/errors/errorUtils';

export interface IUserEntitlements {
	user: string;
	roles: string[];
	securityGroups: string[];
	lastLogin?: string;
}

export class UserEntitlementsListModel {
	private _loading: boolean;
	private _error: string;
	private _userEntitlements: IUserEntitlements[] = [];

	constructor() {
		makeAutoObservable(this);

		this._loading = false;
		this._error = '';
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get userEntitlements() {
		return this._userEntitlements;
	}

	public load = flow(function* (this: UserEntitlementsListModel) {
		try {
			this._loading = true;
			this._error = '';

			const response: AxiosResponse<ApiResponse<IUserEntitlements[]>> =
				yield customerApi.get<ApiResponse<IUserEntitlements[]>>(
					'api/v1.0/userentitlements',
				);

			if (response.data.error) throw response.data.error;
			this._userEntitlements = response.data.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}
