import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import CommonReferenceRepository, {
	ReferenceRepository,
} from './repositories/referenceRepository';
import { removeIdentifierFromReferenceDisplayName } from 'shared/utilities/referenceDataUtilities';

export class ReferenceStore {
	private _refRepo: ReferenceRepository;

	private _countries?: ReferenceDataValue[];

	constructor(referenceRepository: ReferenceRepository) {
		this._refRepo = referenceRepository;
	}

	public async getCountries() {
		if (this._countries) {
			return this._countries;
		}
		try {
			const countries = await this._refRepo
				.getCollection('Countries')
				.then((items) =>
					items.sort((a, b) => {
						if (a.value === 'US') return -1;
						if (b.value === 'US') return 1;

						const [nameA, nameB] = [a, b].map(
							removeIdentifierFromReferenceDisplayName,
						);

						if (nameA! > nameB!) return 1;
						if (nameA! < nameB!) return -1;
						return 0;
					}),
				);
			this._countries = countries;
			return this._countries;
		} catch (e) {
			throw e;
		}
	}

	public async getStateByCountry(countryCode: string) {
		const states = await this._refRepo.getCollection(
			'CountrySubdivisions',
			countryCode,
		);

		return states.sort((a, b) => {
			const [nameA, nameB] = [a, b].map((s) => s.value);

			if (nameA > nameB) return 1;
			if (nameA < nameB) return -1;
			return 0;
		});
	}

	public async getLegalForms(countryCode: string, stateCode?: string | null) {
		const legalForms = await this._refRepo.getElfCodes(countryCode, stateCode);

		return legalForms.sort((a, b) => {
			const [nameA, nameB] = [a, b].map((x) => x.value);

			if (nameA > nameB) return 1;
			if (nameA < nameB) return -1;
			return 0;
		});
	}

	public async getRegistrationAuthorities(countryCode: string) {
		const authorities =
			await this._refRepo.getRegistrationAuthoritiesByCountryCode(countryCode);

		return authorities.sort((a, b) => {
			const [nameA, nameB] = [a, b].map((x) => x.value);

			if (nameA > nameB) return 1;
			if (nameA < nameB) return -1;
			return 0;
		});
	}

	public async getRegistrationAuthorityByIdentifier(identifier: string) {
		const authority = await this._refRepo.getRegistrationAuthorityByIdentifier(
			identifier,
		);
		return authority;
	}
}

const CommonReferenceStore = new ReferenceStore(CommonReferenceRepository);
export default CommonReferenceStore;
