/* eslint-disable mobx/missing-observer */
import { Box, Typography } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { getColumnDefs } from 'features/cash4/shared/gridColDef/GridColDefFactory';
import { Cash4Account } from 'models/apiModels';
import { FC } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { brandColors } from 'shared/theme/brandColors';
import { columnsAccountEventsHistory } from '../utilities';

interface AccountEventsHistoryListProps {
	account: Cash4Account;
}

export const AccountEventsHistoryList: FC<AccountEventsHistoryListProps> = ({
	account,
}) => {
	const { customerApiClient } = useClients();
	const { data, isLoading, refetch } = useQuery(
		['getAccountEventsHistory', account.id],
		() => customerApiClient.cash4.accounts.getAccountEventsHistory(account.id),
		{ enabled: false },
	);

	// Getting the account events history on instantiation. If useQuery is enabled, then
	// it continues to refetch with every instance causing a lot of unnecessary traffic
	// and UI slowness.
	if (data === undefined) {
		refetch();
	}

	return (
		<Box
			sx={{
				padding: 1,
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: 2,
				border: `1px dashed ${brandColors.grey[100]}`,
				margin: 1,
			}}
		>
			<Typography variant="h6" textAlign={'center'} margin={2}>
				Account Events History
			</Typography>
			<DataGridPro
				columns={getColumnDefs(columnsAccountEventsHistory)}
				rows={data?.data ?? []}
				getRowId={(row) => row.accountSyncEventId ?? row.fileUploadId}
				sx={{
					width: '100%',
					borderRadius: 2,
					border: `1px dashed ${brandColors.grey[100]}`,
				}}
				hideFooter
				autoHeight
				loading={isLoading}
				disableRowSelectionOnClick
				density="compact"
				initialState={{
					sorting: {
						sortModel: [{ field: 'statementDate', sort: 'desc' }],
					},
				}}
			/>
		</Box>
	);
};
