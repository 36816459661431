import {
	Add,
	ChevronRight,
	Circle,
	DeleteOutline,
	ExpandMore,
} from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
	Badge,
	Box,
	Button,
	ClickAwayListener,
	Collapse,
	Divider,
	Grid,
	IconButton,
	Link,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Paper,
	Popper,
	Step,
	StepIcon,
	Stepper,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { T4Chip } from 'features/entity4/shared/components/atoms/t4Chip';
import { AccountIntegration } from 'modules/clients/customer-api/src/cash4/accountIntegrations';
import {
	ConnectionCheck,
	ConnectionIndex,
} from 'modules/clients/customer-api/src/cash4/connections';
import { FC, Fragment, useState } from 'react';
import { T4DataGrid } from 'shared/components/dataGrid/dataGrid';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { stonlyData } from 'stonly/functions';
import { connectionsListPagePrefix } from '../../../../../stonly/pagePrefixes';
import { UseAccountConnectionsProps } from '../hooks/useConnectionList';
import { useRemoveAccountIntegration } from '../hooks/useRemoveAccountIntegration';
import { AddAccountsIntegrationModal } from './addAccountsIntegrationModal';
import { AssignAccountModal } from './assignAccountModal';
import { RemoveAccountIntegrationModal } from './removeAccountIntegrationModal';
import { UpdateAccountIntegrationModal } from './updateAccountIntegrationModal';

export type BankConnectionProps = {
	loading: boolean;
	errorMessage: string;
	connection: ConnectionIndex;
	refetch: () => Promise<void>;
	connect: UseAccountConnectionsProps['connect'];
	onClickView: (connection: ConnectionIndex) => void;
	openSetAccountIntegrationScheduleModal: (
		connectionId: string,
		accountIntegration: AccountIntegration,
	) => void;
};

const stonlyIds = {
	menuOptionsButton: 'menu-options-button',
	editAccountIntegrationButton: 'edit-account-integration-button',
	assignAccountButton: 'assign-account-button',
	removeAccountIntegrationButton: 'remove-account-integration-button',
};

export const BankConnection: FC<BankConnectionProps> = ({
	loading,
	errorMessage,
	connection,
	refetch,
	connect,
	onClickView,
	openSetAccountIntegrationScheduleModal,
}) => {
	const theme = useTheme();

	// #region Assign Account Modal

	const [isAssignAccountModalOpen, setIsAssignAccountModalOpen] =
		useState<boolean>(false);
	const [assignAccountModalMetadata, setAssignAccountModalMetadata] = useState<{
		connection: ConnectionIndex | null;
		accountIntegration: AccountIntegration | null;
	}>({ connection: null, accountIntegration: null });
	const openAssignAccountModal = (
		connection: ConnectionIndex,
		accountIntegration: AccountIntegration,
	) => {
		setAssignAccountModalMetadata({
			connection,
			accountIntegration,
		});
		setIsAssignAccountModalOpen(true);
	};
	const closeAssignAccountModal = () => {
		setIsAssignAccountModalOpen(false);
		setAssignAccountModalMetadata({
			connection: null,
			accountIntegration: null,
		});
	};

	// #endregion

	// #region Add Account Integrations Modal

	const [addAccountIntegrationModalOpen, setAddAccountIntegrationModalOpen] =
		useState(false);

	// #endregion

	// #region Update Account Integrations Modal

	const [
		updateAccountIntegrationModalOpen,
		setUpdateAccountIntegrationModalOpen,
	] = useState(false);
	const [accountIntegrationToUpdate, setAccountIntegrationToUpdate] =
		useState<AccountIntegration | null>(null);
	const closeUpdateAccountIntegrationModal = () => {
		setUpdateAccountIntegrationModalOpen(false);
		setAccountIntegrationToUpdate(null);
	};

	// #endregion

	// #region Delete Account Integrations Modal

	const removeAccountIntegrationState = useRemoveAccountIntegration(refetch);

	// #endregion

	// #region Base Component State

	const [open, setOpen] = useState(true);
	const [connectionCheck, setConnectionCheck] =
		useState<ConnectionCheck | null>(null);
	const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
	const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
		page: 0,
		pageSize: 20,
	});

	// #endregion

	const getErrorText = (connection: ConnectionIndex) => {
		if (connection.credentialVerification === undefined)
			return 'An unexpected error occurred.';
		return connection.credentialVerification?.errorMessage || '';
	};

	const getColumns = (): GridColDef[] => {
		let columns: GridColDef[] = [
			{
				field: 'viewSchedule',
				headerName: '',
				description: 'View Schedule',
				width: DataGridColumnWidths.threeChar,
				sortable: false,
				filterable: false,
				renderCell: (params: GridRenderCellParams<AccountIntegration>) => {
					return (
						<Tooltip title={'View Schedule'}>
							<IconButton
								onClick={() =>
									openSetAccountIntegrationScheduleModal(
										connection.id,
										params.row,
									)
								}
								{...stonlyData({
									id: `${connectionsListPagePrefix}-schedule-${connection.name
										.replaceAll(' ', '-')
										.toLocaleLowerCase()}`,
								})}
							>
								<ScheduleIcon />
							</IconButton>
						</Tooltip>
					);
				},
			},
			{
				field: 'accountDisplayName',
				headerName: 'Account Display Name',
				description: 'Account Display Name',
				type: 'string',
				flex: 1,
				valueGetter: (params: GridValueGetterParams<AccountIntegration>) =>
					params.row.entity4Account?.displayName,
			},
			{
				field: 'naturalAccountNumber',
				headerName: 'Natural Account Number',
				description: 'Natural Account Number',
				type: 'string',
				flex: 1,
				valueGetter: (params: GridValueGetterParams<AccountIntegration>) =>
					params.row.entity4Account?.naturalAccountNumber,
			},
			{
				field: 'priorDayStatementAccountNumber',
				headerName: 'Prior Day Statement Account Number',
				description: 'Prior Day Statement Account Number',
				type: 'string',
				flex: 1,
				valueGetter: (params: GridValueGetterParams<AccountIntegration>) =>
					params.row.entity4Account?.priorDayStatementsAccountNumber,
			},
			{
				field: 'accountIdentifier',
				headerName: 'Account Identifier',
				description: 'Account Identifier',
				type: 'string',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'startDate',
				headerName: 'Start Date',
				description: 'Start Date',
			},
			{
				...getDateColumnDefinition(),
				field: 'endDate',
				headerName: 'End Date',
				description: 'End Date',
			},
			{
				field: 'isConnected',
				headerName: 'Status',
				description: 'Status',
				type: 'boolean',
				minWidth: 150,
				renderCell: (params) => (
					<Fragment>
						<Circle
							sx={{
								marginRight: '5px',
								position: 'relative',
								top: '-1px',
								width: '15px',
							}}
							color={params.row.isConnected ? 'success' : 'error'}
						/>
						{params.row.isConnected ? 'Connected' : 'Not Connected'}
					</Fragment>
				),
			},
			{
				field: 'connectAccount',
				headerName: 'Connection Status',
				description: 'Connection Status',
				filterable: false,
				minWidth: 200,
				renderCell: ({ row }) => {
					return (
						<CustomStepper
							connectionChecks={row.connectionChecks}
							openPopper={(newAnchorEl, newConnectionCheck) => {
								if (connectionCheck === null) {
									setConnectionCheck(newConnectionCheck);
									setAnchorEl(newAnchorEl);
								} else if (newConnectionCheck?.id !== connectionCheck?.id) {
									setConnectionCheck(newConnectionCheck);
									setAnchorEl(newAnchorEl);
								}
							}}
						/>
					);
				},
			},
			{
				field: 'connect',
				headerName: 'Connect Account',
				description: 'Connect Account',
				filterable: false,
				minWidth: 180,
				maxWidth: 180,
				renderCell: ({ row }) => {
					return (
						<Link
							component="button"
							underline="hover"
							variant="body2"
							sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
							onClick={() => connect(connection.id, row.id)}
							{...stonlyData({
								id: `${connectionsListPagePrefix}-connect-button-${connection.name
									.replaceAll(' ', '-')
									.toLocaleLowerCase()}`,
							})}
						>
							Connect
						</Link>
					);
				},
			},
		];

		if (connection.connector.type === 'Api') {
			columns.unshift({
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<AccountIntegration>) => {
						if (connection.connector.type !== 'Api') {
							return null;
						}

						return (
							<OverflowMenu
								iconButtonProps={{
									...stonlyData({
										id: stonlyIds.menuOptionsButton,
									}),
								}}
								id={`account-integration-more-menu`}
							>
								<MenuItem
									{...stonlyData({
										id: stonlyIds.editAccountIntegrationButton,
									})}
									onClick={() => {
										setAccountIntegrationToUpdate(params.row);
										setUpdateAccountIntegrationModalOpen(true);
									}}
								>
									<ListItemText>Edit</ListItemText>
								</MenuItem>
								{!params.row.entity4Account && (
									<MenuItem
										{...stonlyData({
											id: stonlyIds.assignAccountButton,
										})}
										onClick={() => {
											openAssignAccountModal(connection, params.row);
										}}
									>
										<ListItemText>Assign Account</ListItemText>
									</MenuItem>
								)}

								<Divider />
								<MenuItem
									{...stonlyData({
										id: stonlyIds.removeAccountIntegrationButton,
									})}
									disabled={params.row.syncEventCount > 0}
									onClick={() =>
										removeAccountIntegrationState.remove(
											connection.id,
											params.row.id,
										)
									}
								>
									<ListItemIcon>
										<DeleteOutline color="error" />
									</ListItemIcon>
									<ListItemText>Remove</ListItemText>
								</MenuItem>
							</OverflowMenu>
						);
					},
				),
			});
		}

		return columns;
	};

	return (
		<Grid
			container
			sx={{
				border: '1px solid #e0e0e0',
				padding: '0.5rem',
			}}
		>
			<Grid
				container
				item
				xs={12}
				sx={{
					justifyContent: 'space-between',
				}}
			>
				<Grid
					container
					item
					xs="auto"
					columnGap={1}
					sx={{
						alignItems: 'center',
						flexWrap: 'nowrap',
					}}
				>
					<Grid item>
						<IconButton onClick={() => setOpen(!open)}>
							{open ? <ExpandMore /> : <ChevronRight />}
						</IconButton>
					</Grid>
					<Grid item>
						<Typography variant="h6">{connection.name}</Typography>
					</Grid>
					<Grid item>
						<Tooltip
							id={connection.id}
							title={getErrorText(connection)}
							disableFocusListener
							disableTouchListener
						>
							<div>
								<T4Chip
									{...stonlyData({
										id: `${connectionsListPagePrefix}-chip-${connection.name
											.replaceAll(' ', '-')
											.toLocaleLowerCase()}`,
									})}
									t4ChipColor={
										connection.credentialVerification?.success
											? 'lightgreen'
											: 'lightred'
									}
									label={connection.connector.type}
									icon={
										connection.credentialVerification?.success ? (
											<VerifiedIcon />
										) : (
											<ReportGmailerrorredIcon />
										)
									}
									sx={{
										'& .MuiChip-icon': {
											color: theme.common?.charcoal?.main,
										},
										textTransform: 'uppercase',
									}}
									onClick={() => onClickView(connection)}
								/>
							</div>
						</Tooltip>
					</Grid>
				</Grid>
				<Grid
					container
					item
					columnGap={1}
					xs="auto"
					sx={{
						alignContent: 'center',
					}}
				>
					<Grid
						item
						sx={{
							alignSelf: 'center',
						}}
					>
						<Box
							sx={{
								paddingX: '1rem',
							}}
						>
							<Badge
								color="secondary"
								badgeContent={connection.accountIntegrations?.length ?? 0}
							/>
						</Box>
					</Grid>
					<Grid item>
						{connection.connector.type !== 'Sftp' && (
							<Button
								startIcon={<Add />}
								type="button"
								variant="outlined"
								color="primary"
								onClick={() => setAddAccountIntegrationModalOpen(true)}
								{...stonlyData({
									id: `${connectionsListPagePrefix}-add-account-button-${connection.name
										.replaceAll(' ', '-')
										.toLocaleLowerCase()}`,
								})}
							>
								Add Account
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Collapse in={open}>
					<T4DataGrid<AccountIntegration>
						stonlyId="bank-connection-accounts"
						columns={getColumns()}
						rows={connection.accountIntegrations}
						loading={loading}
						errorMessage={errorMessage}
						pagination={true}
						paginationModel={paginationModel}
						onPaginationModelChange={(model) => setPaginationModel(model)}
						pageSizeOptions={[20]}
						autoHeight={true}
						hideFooter={false}
						sx={{
							marginTop: '0.5rem',
						}}
					/>
				</Collapse>
			</Grid>
			<Popper
				sx={{ zIndex: theme.zIndex.modal - 1 }}
				id="mouse-over-popover"
				open={connectionCheck !== null}
				anchorEl={anchorEl}
			>
				<ClickAwayListener onClickAway={() => setConnectionCheck(null)}>
					<Paper
						sx={{ padding: 1, maxWidth: '500px' }}
						onMouseLeave={() => setConnectionCheck(null)}
					>
						<Grid container sx={{ maxHeight: '300px', overflow: 'auto' }}>
							<Grid
								container
								item
								xs={12}
								sx={{ justifyContent: 'space-between' }}
							>
								<Grid item>
									<Typography variant="h6">
										{connection.connector.name}
									</Typography>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1">
									Status: {connectionCheck?.status}
								</Typography>
							</Grid>
							<Grid item xs={12} component="pre">
								Response: {connectionCheck?.response}
							</Grid>
						</Grid>
					</Paper>
				</ClickAwayListener>
			</Popper>

			<AddAccountsIntegrationModal
				isOpen={addAccountIntegrationModalOpen}
				onClose={() => setAddAccountIntegrationModalOpen(false)}
				connection={connection}
				refetch={refetch}
			/>

			<UpdateAccountIntegrationModal
				open={updateAccountIntegrationModalOpen}
				close={closeUpdateAccountIntegrationModal}
				accountIntegration={accountIntegrationToUpdate}
				connection={connection}
				refetch={refetch}
			/>

			<AssignAccountModal
				isOpen={isAssignAccountModalOpen}
				connection={assignAccountModalMetadata.connection}
				accountIntegration={assignAccountModalMetadata.accountIntegration}
				onClose={closeAssignAccountModal}
				refetch={refetch}
			/>

			<RemoveAccountIntegrationModal state={removeAccountIntegrationState} />
		</Grid>
	);
};

export type CustomStepperProps = {
	connectionChecks: ConnectionCheck[];
	openPopper: (
		newAnchorEl: HTMLDivElement | null,
		newConnectionCheck: ConnectionCheck | null,
	) => void;
};

export const CustomStepper: FC<CustomStepperProps> = ({
	connectionChecks,
	openPopper,
}) => {
	if (connectionChecks === undefined) {
		connectionChecks = [];
	}

	const activeStep = connectionChecks.filter(
		(connectionCheck) => connectionCheck.success,
	).length;

	return (
		<Stepper
			activeStep={activeStep}
			sx={{
				width: `${connectionChecks.length * 1.5}rem`,
			}}
		>
			{connectionChecks?.map((connectionCheck) => (
				<Step
					key={connectionCheck.id}
					sx={{
						display: 'flex',
						padding: 0,
					}}
					onClick={(event) => {
						if (!connectionCheck.success) {
							openPopper(event.currentTarget, connectionCheck);
						}
					}}
				>
					<Tooltip
						id={connectionCheck.id}
						title={connectionCheck.type}
						disableTouchListener
						disableFocusListener
						placement="top"
					>
						<div>
							<StepIcon
								icon={
									connectionCheck.success ? (
										<CheckCircleIcon
											color="success"
											sx={{ fontSize: '1rem' }}
										/>
									) : (
										<ErrorIcon
											color="error"
											sx={{ fontSize: '1rem', cursor: 'pointer' }}
										/>
									)
								}
							/>
						</div>
					</Tooltip>
				</Step>
			))}
		</Stepper>
	);
};
