import { EntityType, SubObjectType } from 'features/entity4/entity4Constants';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { History } from 'history';
import { flow, makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { generatePath } from 'react-router-dom';
import { FileUploadValidation } from 'shared/components/fileUploadBox/fileUploadValidation';
import { paths } from 'shared/constants/paths';
import { parseError } from 'utilities/errors/errorUtils';
import { CommonDocumentStore } from '../documentStore';
import { Document } from './document';
import { NewDocumentForm } from './newDocumentForm';

export class UploadDocumentViewModel {
	private _refetch: () => CancellablePromise<void>;
	private _history: History;

	private _documentValidation: FileUploadValidation;
	private _newDocumentForm: NewDocumentForm;

	private _open: boolean = false;
	private _uploading: boolean = false;
	private _error?: string = undefined;
	private _multipleUploads: boolean = false;
	private _successSnackbarOpen: boolean = false;

	private _objectType?: EntityType;
	private _objectId?: string;
	private _subobjectType?: SubObjectType;
	private _subobjectId?: string;
	private _setView?: (update: FieldViews) => void;

	constructor(refetch: () => CancellablePromise<void>, history: History) {
		makeAutoObservable(this);

		this._refetch = refetch;
		this._history = history;

		this._documentValidation = new FileUploadValidation(
			[
				'image/jpeg',
				'image/tiff',
				'image/bmp',
				'image/png',
				'image/svg+xml',
				'image/gif',
				'application/pdf',
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'application/vnd.ms-excel',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/vnd.ms-powerpoint',
				'application/vnd.openxmlformats-officedocument.presentationml.presentation',
				'application/vnd.visio',
				'application/vnd.ms-visio.viewer',
				'application/xml',
				'text/plain',
				'text/csv',
				'text/xml',
			],
			50,
		);

		this._newDocumentForm = new NewDocumentForm();
	}

	public getError = () => this._error;
	public isOpen = () => this._open;
	public isUploading = () => this._uploading;
	public isExternalUrlFieldDisabled = () =>
		this._newDocumentForm.getFile() !== undefined;
	public canSave = (): boolean => {
		return (
			this._newDocumentForm.fileDocumentIsSavable() ||
			this._newDocumentForm.urlDocumentIsSavable()
		);
	};
	public fileSelected = (): boolean => {
		return this._newDocumentForm.fileSelected();
	};
	public urlProvided = (): boolean => {
		return this._newDocumentForm.urlProvided();
	};
	public getUrl = () => this._newDocumentForm.getUrl();
	public getFileName = () => this._newDocumentForm.getName();
	public getFile = () => this._newDocumentForm.getFile();
	public getMultipleDocumentUploads = () => this._multipleUploads;
	public getDocumentValidation = () => this._documentValidation;
	public getAcceptedFileTypes = () =>
		// TODO: Move to singleton
		this._documentValidation.getValidContentTypes().toString();

	public toggleMultipleUploads = () =>
		(this._multipleUploads = !this._multipleUploads);
	public setFile = (file: File | undefined) => {
		this.closeSuccessSnackbar();
		this._error = undefined;
		if (this._documentValidation.isFileValid(file)) {
			this._newDocumentForm.setUrl(undefined);
			this._newDocumentForm.setFile(file);
		} else {
			// TODO: Better file error messaging
			this._error = `This document is too large to upload. The maximum file size for uploads is ${this._documentValidation.getMaxFileSizeInMB()} MB. Reduce the file size, or select a different document.`;
		}
	};
	public setUrl = (value: string) => {
		this.closeSuccessSnackbar();
		this._newDocumentForm.setUrl(value);
	};
	public setName = (value: string) => {
		this.closeSuccessSnackbar();
		this._newDocumentForm.setName(value);
	};
	public clear = () => {
		this._newDocumentForm.setFile(undefined);
		this._newDocumentForm.setUrl(undefined);
	};

	public isSuccessSnackbarOpen = () => {
		return this._successSnackbarOpen;
	};
	public closeSuccessSnackbar = () => {
		if (this._successSnackbarOpen) this._successSnackbarOpen = false;
	};
	private openSuccessSnackbar = () => {
		this._successSnackbarOpen = true;
	};

	public open = (
		objectType: EntityType,
		objectId: string,
		subobjectType?: SubObjectType,
		subobjectId?: string,
		setView?: (update: FieldViews) => void,
	) => {
		this._open = true;
		this._objectType = objectType;
		this._objectId = objectId;
		this._subobjectType = subobjectType;
		this._subobjectId = subobjectId;
		this._setView = setView;
	};

	public close = () => {
		this._open = false;
		this.clear();
	};

	public upload = flow<string | undefined, [userName: string]>(function* (
		this: UploadDocumentViewModel,
		userName: string,
	) {
		if (this._objectType !== undefined && this._objectId !== undefined) {
			try {
				this._error = undefined;
				this._uploading = true;

				const params =
					this._subobjectType !== SubObjectType.Subaccount
						? {
								entityType: this._objectType,
								entityId: this._objectId,
								subObjectId: this._subobjectId,
								subObjectType: this._subobjectType,
						  }
						: {
								entityType: this._subobjectType,
								entityId: this._subobjectId,
								subObjectId: undefined,
								subObjectType: undefined,
						  };

				const document: Document = yield CommonDocumentStore.create(
					params.entityType as EntityType,
					params.entityId!,
					{
						name: this._newDocumentForm.getName()?.trim(),
						uploadedByName: userName,
						externalUrl: this._newDocumentForm.getUrl()?.trim(),
					},
					this._newDocumentForm.getFile(),
					params.subObjectId,
					params.subObjectType,
				);

				if (this._multipleUploads) {
					this.clear();
					this.openSuccessSnackbar();
				} else {
					this.close();
					this.navigateToDocument(document.id);
					this._setView?.(FieldViews.edit);
				}

				this._refetch();
				return document.id;
			} catch (error) {
				if (typeof error !== 'string') {
					this._error =
						'Upload Error: The file you are trying to upload may contain a virus or malware.';
				} else {
					this._error = parseError(error);
				}
			} finally {
				this._uploading = false;
			}
		}
	});

	public navigateToDocument = (documentId: string) => {
		this._history.push(
			generatePath(paths.entity4.objects.object.documents.document.href, {
				objectType: this._objectType as string,
				objectId: this._objectId as string,
				documentId: documentId,
			}),
		);
	};

	public isValidFile = (item: DataTransferItem | null | undefined): boolean =>
		item !== undefined &&
		item !== null &&
		this._documentValidation.isFileTypeValid(item.type);
}
