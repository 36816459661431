import { AxiosResponse } from 'axios';
import { getDateWarningMessage } from 'features/entity4/entities/utilities/dateUtilities';
import {
	EntityConfig,
	EntityTypeId,
	getEntityType,
	getEntityTypeKey,
} from 'features/entity4/entity4Constants';
import { makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { PowerOfAttorneyType } from 'modules/clients/customer-api/src/api/referenceData';
import { Moment } from 'moment';
import { customerApi } from 'shared/providers/customerApi';
import {
	areDatesEqual,
	convertDate,
	formatDate,
} from 'shared/utilities/dateUtilities';
import { ApiResponse } from 'utilities/api';
import { flattenErrors, parseError } from 'utilities/errors/errorUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { BaseRelationship } from '../../relationshipsObjectTypes';

export interface IEditRelationshipForm {
	effectiveFrom: Moment | null;
	effectiveTo: Moment | null;
	notes: string | null;
	selectedPowerOfAttorneyType: PowerOfAttorneyType | null;
}

export interface IEditRelationshipData {
	id: string;
	typeId: string;
	entityTypeId: EntityTypeId;
	entityTypeDisplayName: string;
	relationshipDisplayName: string;
	entityDisplayName: string;
	definitionDescriptor: string;
	effectiveFrom: Moment | null;
	effectiveTo: Moment | null;
	notes: string | null;
	typeOfPowerOfAttorneyId: string | null;
}

export const getEntityTypeName = (row: any) => {
	switch (row.entityTypeId) {
		case EntityTypeId.Account:
			return row.accountName;
		case EntityTypeId.Staff:
			return row.name ?? row.staffName ?? row.staffDisplayName;
		case EntityTypeId.Counterparty:
			return row.counterpartyName ?? row.ownerName;
		case EntityTypeId.InternalEntity:
			return (
				row.entityName ??
				row.childEntity ??
				row.owner ??
				row.ownerName ??
				row.legalEntityDisplayName
			);
		case EntityTypeId.Partner:
			return row.ownerName;
		default:
			return '';
	}
};

export const rowToRelationshipData = (
	currentEntityTypeId: EntityTypeId,
	row: BaseRelationship,
) => {
	return {
		id: row.id,
		typeId: row.relationshipTypeId,
		entityTypeId: row.entityTypeId,
		entityTypeDisplayName: `${getEntityTypeKey(
			getEntityType(currentEntityTypeId)!,
		)} to ${getEntityTypeKey(getEntityType(row.entityTypeId)!)}`,
		relationshipDisplayName: row.relationshipTypeName,
		entityDisplayName: getEntityTypeName(row),
		effectiveFrom: row.effectiveFrom ? convertDate(row.effectiveFrom) : null,
		effectiveTo: row.effectiveTo ? convertDate(row.effectiveTo) : null,
		notes: row.notes ? row.notes : null,
		definitionDescriptor: row.definitionDescriptor,
		typeOfPowerOfAttorneyId: (row as any).typeOfPowerOfAttorneyId,
	} as IEditRelationshipData;
};

const initialForm: IEditRelationshipForm = {
	effectiveFrom: null,
	effectiveTo: null,
	notes: null,
	selectedPowerOfAttorneyType: null,
};

export class EditRelationshipDrawerViewModel {
	public readonly entityType: EntityTypeId;
	public readonly entityId: string;

	private _entityName: string | undefined;
	private _drawerOpen: boolean = false;

	private _updating: boolean = false;
	private _updateError: string | undefined = undefined;
	private _updateErrors: string[] = [];

	private _refetch: () => CancellablePromise<void>;

	private _form: IEditRelationshipForm = initialForm;
	private _relationshipData: IEditRelationshipData =
		{} as IEditRelationshipData;
	private _powerOfAttorneys: PowerOfAttorneyType[] = [];

	constructor(
		entityType: EntityTypeId,
		entityId: string,
		refetch: () => CancellablePromise<void>,
		powerOfAttorneys: PowerOfAttorneyType[] = [],
	) {
		makeAutoObservable(this);
		this.entityType = entityType;
		this.entityId = entityId;
		this._powerOfAttorneys = powerOfAttorneys;
		this._refetch = () => refetch();
	}

	public getPowerOfAttorneys() {
		return this._powerOfAttorneys ?? [];
	}

	public getSelectedPowerOfAttorney() {
		return this._form.selectedPowerOfAttorneyType;
	}

	public setPowerOfAttorney(powerOfAttorneyType: PowerOfAttorneyType | null) {
		this._form.selectedPowerOfAttorneyType = powerOfAttorneyType;
	}

	public setEntityName(value: string | undefined) {
		this._entityName = value;
	}

	public isUpdating() {
		return this._updating;
	}
	public getUpdateError() {
		return this._updateError;
	}
	public getUpdateErrors() {
		return this._updateErrors;
	}

	public getTypeDisplayName() {
		return this._relationshipData.entityTypeDisplayName;
	}
	public getRelationshipDisplayName() {
		return this._relationshipData.relationshipDisplayName;
	}
	public getRelatedEntityLabel() {
		return getEntityTypeKey(
			getEntityType(this._relationshipData.entityTypeId)!,
		);
	}

	// relationship directionality
	public getEntityName() {
		return this._entityName;
	}

	public getRelatedEntityName() {
		return this._relationshipData?.entityDisplayName;
	}

	public getDirectionDescription() {
		return this._relationshipData?.definitionDescriptor?.toLowerCase();
	}

	public isOpen() {
		return this._drawerOpen;
	}

	public isDirty() {
		return (
			!areDatesEqual(
				this._form.effectiveFrom,
				this._relationshipData.effectiveFrom,
			) ||
			!areDatesEqual(
				this._form.effectiveTo,
				this._relationshipData.effectiveTo,
			) ||
			this._form.notes !== this._relationshipData.notes ||
			this._form.selectedPowerOfAttorneyType?.id !==
				this._relationshipData.typeOfPowerOfAttorneyId
		);
	}

	public async openDrawer(relationship: IEditRelationshipData) {
		this._drawerOpen = true;
		this._relationshipData = relationship;
		this.setEffectiveFrom(
			this._relationshipData.effectiveFrom?.isValid()
				? this._relationshipData.effectiveFrom
				: null,
		);
		this.setEffectiveTo(
			this._relationshipData.effectiveTo?.isValid()
				? this._relationshipData.effectiveTo
				: null,
		);
		this.setNotes(this._relationshipData.notes);
		this.setPowerOfAttorney(
			this._powerOfAttorneys.find(
				(x) => x.id === this._relationshipData.typeOfPowerOfAttorneyId,
			) ?? null,
		);
	}
	public closeDrawer() {
		this._drawerOpen = false;
		this.resetForm();
	}
	private resetForm() {
		this.setEffectiveFrom(null);
		this.setEffectiveTo(null);
		this.setNotes(null);
		this._updateError = undefined;
		this._updateErrors = [];
	}

	public isSubmitDisabled() {
		return this._updating || !this.areDatesValid() || !this.isDirty();
	}

	public getEffectiveFrom() {
		return this._form.effectiveFrom;
	}
	public setEffectiveFrom(value: Moment | null) {
		this._form.effectiveFrom = value;
	}

	public getEffectiveTo() {
		return this._form.effectiveTo;
	}
	public setEffectiveTo(value: Moment | null) {
		this._form.effectiveTo = value;
	}

	public getNotes() {
		return this._form.notes;
	}
	public setNotes(value: string | null) {
		if (isStringUndefinedOrNullOrWhitespace(value)) this._form.notes = null
		else if (value && value.length <=2048) this._form.notes = value
	}

	public areDatesValid() {
		const effectiveFromValid =
			this._form.effectiveTo !== null
				? !Boolean(
						getDateWarningMessage(
							'EffectiveFrom',
							this._form.effectiveFrom,
							this._form.effectiveTo,
						),
				  )
				: !Boolean(
						getDateWarningMessage('EffectiveFrom', this._form.effectiveFrom),
				  );

		return (
			effectiveFromValid &&
			!Boolean(getDateWarningMessage('EffectiveTo', this._form.effectiveTo))
		);
	}

	public *updateRelationship() {
		if (this.isSubmitDisabled()) return;
		const route = EntityConfig.get(
			getEntityType(this.entityType)!,
		)!.controllerRoute;

		try {
			this._updating = true;
			this._updateError = undefined;

			const response: AxiosResponse<ApiResponse<string>> =
				yield customerApi.put<ApiResponse<string>>(
					`${route}/${this.entityId}/relationships/${this._relationshipData.id}`,
					{
						effectiveFrom: formatDate(this._form.effectiveFrom) ?? null,
						effectiveTo: formatDate(this._form.effectiveTo) ?? null,
						notes: this._form.notes,
						powerOfAttorneyTypeId: this._form.selectedPowerOfAttorneyType?.id,
					},
				);

			if (response.data.error) this._updateError = response.data.error;
			else if (response.data.errors)
				this._updateErrors = flattenErrors(response.data.errors);
			else {
				this.closeDrawer();
				yield this._refetch();
			}
		} catch (err: any) {
			this._updateError = parseError(err);
		} finally {
			this._updating = false;
		}
	}
}
