import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
	Payment,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { Moment } from 'moment';
import { FC, useMemo } from 'react';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { useUser } from 'shared/hooks/useUser';
import { dateRangeDefault } from './paymentApprovalsPage';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';

export const NeedsMyReviewTab: FC<{
	tabIndex: number;
	dateRange: DateRange<Moment>;
	setDateRange: (dateRange: DateRange<Moment>) => void;

	payments: Payment[];
	isLoading: boolean;
	isFetching: boolean;
	columns: GridColDef[];
}> = ({
	tabIndex,
	dateRange,
	setDateRange,
	payments,
	isLoading,
	isFetching,
	columns,
}) => {
	const { user } = useUser();

	const myReviews = useMemo(
		() =>
			payments
				.filter(
					(payment) =>
						payment.currentStatus ===
						PaymentStatusTypes[PaymentStatusTypes.Submitted],
				)
				.filter((payment) => {
					const currentLevel =
						payment.approvalState?.paymentApprovalLevelStates.find(
							(level) =>
								level.paymentApprovalLevelId ===
								payment.approvalState?.currentApprovalLevelId,
						);
					if (!currentLevel) return false;
					else {
						return currentLevel.approvers?.some((x) => x.userId === user.sub);
					}
				}),
		[payments, user],
	);

	return tabIndex === 0 ? (
		<div role="tabpanel" style={{ height: '100%' }}>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', gap: 2 }}
			>
				<Grid item xs="auto">
					<T4DateRangePicker
						value={dateRange}
						onAccept={(dateRange) => setDateRange(dateRange)}
						disableFuture
						showShortcuts
						shortcutResetDefaults={dateRangeDefault}
						disabled={isLoading || isFetching}
					/>
				</Grid>
				<Grid item xs={true} sx={{ height: '50vh' }}>
					<ActuallyPrettyGoodDataGridWrapper>
						<UserPreferencesDataGrid<Payment>
							stonlyId={'needs-my-review-payments'}
							tableKey={'needs-my-review-payments'}
							columns={columns}
							rows={myReviews}
							showToolbar
							showCustomViewButton
							initialState={{
								sorting: {
									sortModel: [
										{
											field: 'createdOn',
											sort: 'desc',
										},
										{
											field: 'valueDate',
											sort: 'asc',
										},
									],
								},
							}}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>
		</div>
	) : null;
};
