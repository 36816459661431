import { HighlightOff } from '@mui/icons-material';
import {
	Alert,
	CircularProgress,
	FormLabel,
	Grid,
	IconButton,
	Input,
	Link,
	Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CustomModal } from 'features/entity4/entities/components/customModal';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { FileContentType } from 'modules/clients/customer-api/src/api/v1/frontend/cash4/dataImports';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { T4SecondaryButton, T4SubmitButton } from 'shared/components/buttons';
import { useClients } from 'shared/hooks/useClients';
import { stonlyData } from 'stonly/functions';
import { Counterparty, ImportFileType, UploadParams } from '../utils/types';
import {
	MaxFileSizeInMB,
	backgroundImage,
	dataTypes,
	errorMessages,
	importFileTypes,
} from '../utils/utils';

interface DataImportsModalProps {
	open: boolean;
	onClose: () => void;
	counterparties: Counterparty[];
}

const DataImportsModal: React.FC<DataImportsModalProps> = ({
	open,
	onClose,
	counterparties,
}) => {
	const [fileType, setFileType] = useState<ImportFileType | null>(null);
	const [dataType, setDataType] = useState<string | null>(null);
	const [counterparty, setCounterparty] = useState<Counterparty | null>(null);
	const [files, setFiles] = useState<File[]>([]);
	const [error, setError] = useState<string>();
	const [isRefetching, setIsRefetching] = useState(false);

	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const showDataType = fileType?.type === 'CSV';
	const showCounterparty =
		(fileType! && dataType!) || (fileType! && !showDataType);
	const showFileUpload = showCounterparty && counterparty!;

	const acceptedExtensions =
		importFileTypes.find((ft) => ft.type === fileType?.type)
			?.acceptedExtensions || [];

	const handleAddFiles = (newFiles: File[]) => {
		handleRemoveError();

		const updatedFiles = newFiles.reduce((acc, file) => {
			// Skip the file if it already exists
			if (files.find((f) => f.name === file.name)) {
				return acc;
			}
			// Skip the file if it exceeds max file size
			if (file.size > MaxFileSizeInMB * 1024 * 1024) {
				setError(errorMessages(file).exceedsMaxFileSize);
				return acc;
			}
			// Skip the file if it does not have an accepted extension
			const fileExtension = '.' + file.name.split('.').pop();
			if (!acceptedExtensions.includes(fileExtension.toLowerCase())) {
				return acc;
			}
			// Add file
			return [...acc, file];
		}, files);

		setFiles(updatedFiles);
	};

	const handleRemoveFile = (file: File) => {
		setFiles(files.filter((f) => f !== file));
	};

	const handleRemoveError = () => {
		setError(undefined);
	};

	const handleRefetch = () => {
		return queryClient.refetchQueries(['imports']);
	};

	const handleClearState = () => {
		setFileType(null);
		setDataType(null);
		setCounterparty(null);
		setFiles([]);
	};

	const handleClose = () => {
		handleClearState();
		onClose();
	};

	const uploadFiles = async (
		fileType: ImportFileType['type'],
		formData: FormData,
	) => {
		switch (fileType) {
			case 'BAI2':
				return customerApiClient.api.v1.frontend.cash4.dataImports.uploadBai2Files(
					formData,
				);
			case 'CSV':
				return customerApiClient.api.v1.frontend.cash4.dataImports.uploadCsvFiles(
					formData,
				);
			default:
				throw new Error('Invalid file type');
		}
	};

	const { mutate, isLoading: isMutating } = useMutation(
		({ fileType, formData }: UploadParams) => {
			return uploadFiles(fileType, formData);
		},
		{
			onMutate: () => {
				setIsRefetching(true);
			},
			onSuccess: (data) => {
				if (data?.data?.failure) {
					enqueueSnackbar(data.data.error, {
						variant: 'error',
					});
				}
				if (data?.data?.successfulFileUploads) {
					handleRefetch().then(() => {
						data.data.successfulFileUploads.forEach((file: any) => {
							enqueueSnackbar(`${file.name} uploaded successfully`, {
								variant: 'success',
							});
						});
					});
				}

				if (data?.data?.unsuccessfulFileUploads) {
					data.data.unsuccessfulFileUploads.forEach((file: any) => {
						enqueueSnackbar(`${file} failed to upload`, {
							variant: 'error',
						});
					});
				}
			},

			onError: (error) => {
				enqueueSnackbar(error, {
					variant: 'error',
				});
			},
			onSettled: () => {
				setIsRefetching(false);
				handleClose();
			},
		},
	);

	const handleSubmit = () => {
		const formData = new FormData();

		const appendedFormData = {
			counterparty,
			fileContentType: getFileContentType(),
		};

		files.forEach((file) => {
			formData.append('files', file);
		});

		formData.append('viewModel', JSON.stringify(appendedFormData));

		if (fileType) {
			mutate({ fileType: fileType.type, formData });
		}
	};

	const getFileContentType = () => {
		switch (fileType?.type) {
			case 'BAI2':
				return 'Bai2';
			case 'CSV':
				return dataType?.includes('Cash Transactions')
					? 'Transactions'
					: 'Balances';
			default:
				return null;
		}
	};

	const isLoading = isMutating || isRefetching;

	const modalActions = [
		<T4SecondaryButton
			label="Cancel"
			onClick={handleClose}
			disabled={isLoading}
			{...stonlyData({ id: 'cancel-import-files-button' })}
			{...{ 'data-testid': 'cancel-import-files-button' }}
		/>,
		<T4SubmitButton
			label={isLoading ? 'Importing' : 'Import'}
			disabled={!files.length || isLoading}
			onClick={handleSubmit}
			startIcon={isLoading ? <CircularProgress size="1.5rem" /> : null}
			{...stonlyData({ id: 'import-files-button' })}
			{...{ 'data-testid': 'import-files-buttom' }}
		/>,
	];

	return (
		<CustomModal
			title={'Import Data'}
			open={open}
			actions={modalActions}
			onClose={() => {
				if (!isLoading) onClose();
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<T4Autocomplete
						label="File Type"
						options={importFileTypes}
						getOptionLabel={(value) => value.type}
						value={fileType}
						onSelect={(value) => setFileType(value)}
						required
						{...stonlyData({ id: 'file-type-dropdown' })}
						{...{ 'data-testid': 'file-type-dropdown' }}
					/>
				</Grid>
				{showDataType && (
					<Grid item xs={12} sx={{ paddingTop: '16px' }}>
						<T4Autocomplete<FileContentType>
							label="Data Type"
							options={dataTypes}
							getOptionLabel={(option) => option}
							value={dataType}
							onSelect={(value) => setDataType(value)}
							required
							{...stonlyData({ id: 'csvcontent-type-dropdown' })}
							{...{ 'data-testid': 'csvcontent-type-type-dropdown' }}
						/>
					</Grid>
				)}
				{showCounterparty && (
					<Grid item xs={12} sx={{ paddingTop: '16px' }}>
						<T4Autocomplete<Counterparty>
							label="Bank Code"
							options={counterparties}
							getOptionLabel={(options) => `${options.code} - ${options.name}`}
							value={counterparty}
							onSelect={(value) => setCounterparty(value)}
							required
							{...stonlyData({ id: 'bank-code-dropdown' })}
							{...{ 'data-testid': 'bank-code-dropdown' }}
						/>
					</Grid>
				)}
				{showFileUpload && (
					<Grid
						item
						xs={12}
						onDragEnter={(event) => {
							event.preventDefault();
							event.currentTarget.style.backgroundColor = '#ccc';
						}}
						onDragOver={(event) => {
							event.preventDefault();
							event.currentTarget.style.backgroundColor = '#ccc';
						}}
						onDragLeave={(event) => {
							event.preventDefault();
							event.currentTarget.style.backgroundColor = 'transparent';
						}}
						onDrop={(event) => {
							event.preventDefault();
							event.currentTarget.style.backgroundColor = 'transparent';
							handleAddFiles(Array.from(event.dataTransfer.files));
						}}
						sx={{
							marginTop: '1rem',
							padding: '2rem',
							backgroundImage: backgroundImage,
						}}
					>
						<Grid container item rowSpacing={2} xs={12}>
							<Grid item xs={12}>
								<Typography
									align="center"
									sx={{ fontWeight: '800', fontSize: '1.25rem' }}
								>
									Drag and drop or{' '}
									<FormLabel htmlFor="browse-files">
										<Link
											sx={{
												fontWeight: 800,
												fontSize: '1.25rem',
												cursor: 'pointer',
											}}
											{...stonlyData({ id: 'browse-files' })}
											{...{ 'data-testid': 'browse-files' }}
										>
											browse
										</Link>
									</FormLabel>{' '}
									your files
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography align="center">
									{acceptedExtensions
										.map((value) => `*${value.toUpperCase()}`)
										.join(', ')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography align="center">Maximum file size 10MB</Typography>
							</Grid>
						</Grid>
						{showFileUpload && (
							<Grid
								container
								item
								xs={12}
								sx={{
									marginTop: '0.5rem',
								}}
							>
								{files.map((file, index) => (
									<Grid
										container
										item
										key={`file-${index}-${file.name}`}
										columnSpacing={1}
										xs={12}
									>
										<Grid item sx={{ alignSelf: 'center' }}>
											<Typography>{file.name}</Typography>
										</Grid>
										<Grid item>
											<IconButton
												size="small"
												onClick={() => handleRemoveFile(file)}
											>
												<HighlightOff />
											</IconButton>
										</Grid>
									</Grid>
								))}
								{error && (
									<Grid item>
										<Alert
											sx={{ alignSelf: 'left', padding: 0 }}
											severity="warning"
										>
											{error}
										</Alert>
									</Grid>
								)}
							</Grid>
						)}
						<Input
							id="browse-files"
							type="file"
							sx={{ display: 'none' }}
							onChange={(event) => {
								const target = event.target as HTMLInputElement;
								if (target.files) {
									handleAddFiles(Array.from(target.files));
								}
								target.value = '';
							}}
							inputProps={{
								accept: acceptedExtensions,
								multiple: true,
							}}
						/>
					</Grid>
				)}
			</Grid>
		</CustomModal>
	);
};

export default DataImportsModal;
