import {
	ChevronRight,
	ContentCopy,
	ExpandMore,
	FileDownloadOutlined,
} from '@mui/icons-material';
import {
	Box,
	CircularProgress,
	Grid,
	IconButton,
	Tooltip,
} from '@mui/material';
import {
	GridRenderCellParams,
	GridRowParams,
	GridValueGetterParams,
	useGridApiRef,
} from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { DataImport } from 'modules/clients/customer-api/src/entity4/dataImports';
import moment from 'moment';
import { FC, useEffect } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { PageHeader } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { DataGridColumnWidths } from 'shared/utilities/dataGrid/dataGridUtils';
import { useDataImports } from '../hooks/useDataImportsPage';
import { CreateDataImportButton } from './createDataImportButton';
import { DataImportDetailPanel } from './dataImportDetailPanel';
import { DataImportStatusCell } from './dataImportStatusCell';
import { DownloadTemplatesButton } from './downloadTemplatesButton';

export const DataImportsPage: FC = observer(() => {
	const {
		loading,
		dataImports,
		templates,
		getErroredRecordCount,
		getSuccessfulRecordCount,
		load,
		isOriginalDownloading,
		queueOriginalFileDownload,
		isErrorDownloading,
		queueErrorFileDownload,
	} = useDataImports();

	const apiRef = useGridApiRef();

	useEffect(() => {
		load();
	}, [load]);

	return (
		<T4View
			loading={loading}
			header={<PageHeader id={'e4-data-imports'} title={'Data Imports'} />}
		>
			<Grid
				container
				item
				sx={{
					flexDirection: 'column',
					flexWrap: 'nowrap',
					gap: 2,
					height: '100%',
				}}
			>
				<Grid
					container
					item
					xs="auto"
					sx={{
						justifyContent: 'flex-end',
						gap: 2,
					}}
				>
					<Grid item>
						<DownloadTemplatesButton templates={templates} />
					</Grid>
					<Grid item>
						<CreateDataImportButton disabled={loading} reload={load} />
					</Grid>
				</Grid>
				<Grid item xs={true}>
					<UserPreferencesDataGrid<DataImport>
						tableKey="e4-data-imports"
						stonlyId="e4-data-imports"
						apiRef={apiRef}
						getDetailPanelHeight={() => 'auto'}
						getDetailPanelContent={(params: GridRowParams<DataImport>) => {
							return (
								<DataImportDetailPanel
									dataImport={params.row}
									erroredRecordCount={getErroredRecordCount(params.row)}
									successfulRecordCount={getSuccessfulRecordCount(params.row)}
									downloadOriginalFile={() =>
										queueOriginalFileDownload(params.row.id)
									}
									isOriginalDownloading={isOriginalDownloading(params.row.id)}
									isErrorDownloading={isErrorDownloading(params.row.id)}
									downloadErrorFile={() =>
										queueErrorFileDownload(params.row.id)
									}
								/>
							);
						}}
						hideFooter={false}
						showToolbar
						showCustomViewButton
						csvOptions={{
							fields: [
								'status',
								'objectType',
								'fileName',
								'createdOn',
								'createdBy',
							],
							fileName: `Entity4_Data-Imports-${moment().format('YYYY-MM-DD')}`,
						}}
						rows={dataImports}
						slots={{
							detailPanelExpandIcon: ChevronRight,
							detailPanelCollapseIcon: ExpandMore,
						}}
						sx={{
							height: '100%',
							minHeight: '600px',
						}}
						initialState={{
							sorting: {
								sortModel: [{ field: 'createdOn', sort: 'desc' }],
							},
						}}
						columns={[
							{
								field: 'status',
								headerName: 'Import Status',
								renderCell: ({
									value,
									row,
								}: GridRenderCellParams<DataImport, string>) => {
									return (
										<DataImportStatusCell
											erroredRecordCount={getErroredRecordCount(row)}
											status={value}
										/>
									);
								},
								flex: 0.75,
							},
							{
								field: 'copy',
								headerName: '',
								hideable: false,
								filterable: false,
								sortable: false,
								width: DataGridColumnWidths.threeChar,
								renderCell: ({ row }: GridRenderCellParams<DataImport>) => {
									const errorCount = getErroredRecordCount(row);
									let errorText = 'Import failure. File could not be read.';

									if (errorCount > 0) {
										if (row.status === 'Failure')
											errorText =
												'Records failed to import. Download error file to view detailed information.';
										else
											errorText = `${errorCount} row(s) contain one or more errors. Download error file to view detailed information.`;
									}

									if (row.status === 'Failure' || errorCount > 0)
										return (
											<CopyToClipboardIconButton
												valueToCopy={errorText}
												initialTooltipText={errorText}
											>
												<ContentCopy />
											</CopyToClipboardIconButton>
										);
									return null;
								},
							},
							{
								field: 'download',
								headerName: '',
								hideable: false,
								filterable: false,
								sortable: false,
								width: DataGridColumnWidths.threeChar,
								renderCell: ({ row }: GridRenderCellParams<DataImport>) => {
									const errorCount = getErroredRecordCount(row);
									return isOriginalDownloading(row.id) ||
										isErrorDownloading(row.id) ? (
										<Box
											sx={{
												display: 'inline-flex',
												flex: '0 0 auto',
												alignItems: 'center',
												justifyContent: 'center',
												padding: '8px',
											}}
										>
											<CircularProgress size={25} />
										</Box>
									) : (
										<Tooltip
											title={
												errorCount
													? 'Select which file to download.'
													: 'Download original file.'
											}
										>
											<IconButton
												onClick={() => {
													errorCount
														? apiRef.current.toggleDetailPanel(row.id)
														: queueOriginalFileDownload(row.id);
												}}
											>
												<FileDownloadOutlined />
											</IconButton>
										</Tooltip>
									);
								},
							},
							{
								field: 'objectType',
								headerName: 'Object Type',
								flex: 0.5,
							},
							{
								field: 'fileName',
								headerName: 'File Name',
								flex: 1,
								valueGetter: (params: GridValueGetterParams) => {
									return `${params.row.fileName}.${params.row.fileType}`;
								},
							},
							{
								...getDateColumnDefinition('DD-MMM-YYYY h:mm:ss a'),
								field: 'createdOn',
								headerName: 'Imported Date',
								flex: 1,
							},
							{
								field: 'createdBy',
								headerName: 'Imported By',
								flex: 1,
							},
						]}
					/>
				</Grid>
			</Grid>
		</T4View>
	);
});
