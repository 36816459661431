import { Entity } from '../../entities/objects/entity';
import { EntityCollection } from '../../entities/objects/entityCollection';
import { FieldSet } from '../../shared/fieldSets/fieldTypes';
import { CollectionList } from './collectionList';

const fieldPrefix = 't4_field_phone_';
export class PhoneList extends CollectionList {
	constructor(
		entity: Entity,
		collectionDefinition: FieldSet
	) {
		super(entity, collectionDefinition);
		const phoneNumbers = entity.getCollectionEntries('t4_fieldset_phone');
        this.items = this.sortPhoneNumbers(phoneNumbers);
	}

	private sortPhoneNumbers(unsorted: EntityCollection[]): EntityCollection[] {
		const byType = new Map<string, EntityCollection[]>();
		unsorted.forEach((phone) => {
			const identifier = `${fieldPrefix}type`;
			// Type selected before creation so it should always be populated
			const phoneType = phone.getField(identifier)?.data?.displayValue!;
			const existing = byType.get(phoneType) || 
				([] as EntityCollection[]);

			byType.set(phoneType, [...existing, phone]); 
		});

		return [...byType.keys()].sort().reduce((phones, phoneType) => {
			const sortedPhoneNumbers = byType.get(phoneType)!
				.sort((a, b) => b.createdDate.diff(a.createdDate));
			return phones.concat(sortedPhoneNumbers);
		}, [] as EntityCollection[]);
	}
}
