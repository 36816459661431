import { GridColDef } from '@mui/x-data-grid';
import {
	GridColumnVisibilityModel,
	GridRenderCellParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import {
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { formatCurrency } from 'utilities/currencyUtils';

type ColumnType = Partial<GridColDef>;
export type ExtendedGridColDef = GridColDef & {
	currencyCodeField?: string;
	decimalPlaces?: number;
	defaultVisibility?: boolean;
};

const columnTypes: Record<string, ColumnType> = {
	t4Date: {
		...getDateColumnDefinition(),
		type: 'date',
	},
	t4DateTime: {
		...getFormattedDateTimeColumnDefinition(),
		type: 'dateTime',
	},
	t4Money: {
		type: 'number',
		align: 'right',
		headerAlign: 'right',
		valueGetter: (params: GridValueGetterParams) => params.value,
		renderCell: (params: GridRenderCellParams<any, number>) => {
			const column = params.colDef as ExtendedGridColDef;
			const currencyCode =
				params.row[column.currencyCodeField || 'currencyCode'];
			const value = params.value;
			if (value == null) return undefined;
			const formattedValue = formatCurrency(value, {
				currency: currencyCode,
			});
			return formattedValue
				? formattedValue.replace(currencyCode, '')
				: undefined;
		},
	},
	t4Decimal: {
		type: 'number',
		align: 'right',
		headerAlign: 'right',
		renderCell: (params: GridRenderCellParams<any, number>) => {
			const column = params.colDef as ExtendedGridColDef;
			const value = Number(params.value);
			const decimalPlaces = column.decimalPlaces ?? 2;
			const formattedValue = new Intl.NumberFormat('en-US', {
				minimumFractionDigits: decimalPlaces,
				maximumFractionDigits: decimalPlaces,
			}).format(value);
			return isNaN(value) ? '' : formattedValue;
		},
	},
	// Add more types as needed
};

export const getColumnDefs = (columns: GridColDef[]): GridColDef[] => {
	return columns.map((col) => {
		const { type, ...rest } = col as GridColDef & { type?: string };
		if (type && columnTypes[type]) {
			return {
				align: 'left',
				headerAlign: 'left',
				width: 110,
				defaultVisibility: true,
				...columnTypes[type],
				...rest,
			};
		}
		return col;
	});
};

export function getColumnVisibilityModel(
	columns: ExtendedGridColDef[],
	defaultVisibility: boolean,
): GridColumnVisibilityModel {
	const result: GridColumnVisibilityModel = {};

	for (let column of columns) {
		if (column.defaultVisibility === defaultVisibility) {
			result[column.field] = defaultVisibility;
		}
	}

	return result;
}
