import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CancellablePromise } from 'mobx/dist/api/flow';
import { FC } from 'react';

export interface DeleteTaxIdDialogProps {
	taxIdId: string | null;
	open: boolean;
	onClose: () => void;
	onDelete: (taxIdId: string) => CancellablePromise<void>;
	isDeletingTaxId: boolean;
}
export const DeleteTaxIdDialog: FC<DeleteTaxIdDialogProps> = observer(
	({ taxIdId, open, onClose, onDelete, isDeletingTaxId }) => {
		return (
			<Dialog open={open}>
				<DialogTitle>Confirm Delete</DialogTitle>
				<DialogContent>
					Are you sure you want to delete this Tax ID?
				</DialogContent>
				<DialogActions
					sx={{
						paddingX: '1.5rem',
						paddingBottom: '1.5rem',
					}}
				>
					<Grid display="flex" justifyContent="space-between" width="100%">
						{isDeletingTaxId ? (
							<Box display="flex" alignItems="center">
								<Box marginRight={2}>
									<Typography noWrap={true}>Deleting...</Typography>
								</Box>
								<CircularProgress size={25} />
							</Box>
						) : null}
						<Grid container spacing={1} justifyContent="flex-end">
							<Grid item>
								<Button color="secondary" onClick={() => onClose()}>
									Cancel
								</Button>
							</Grid>
							<Grid item>
								<Button
									color="error"
									variant="contained"
									disabled={isDeletingTaxId}
									onClick={() => {
										if (taxIdId) onDelete(taxIdId).then(() => onClose());
									}}
								>
									Delete
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		);
	},
);
