import { AxiosInstance } from 'axios';
import { T4CustomResponse } from '../../../client';
import { Read, Write } from 'modules/clients/types';

//#region Models

export type ApprovalConfiguration = {
	id: string;
	type: {
		id: number;
		name: string;
	};
	approvalsRequired: number;
	rejectionsRequired: number;
	isActive: boolean;
};

//#endregion

//#region Endpoints

export type ToggleInput = {
	approvalTypeId: number;
};

export type UpdateFieldApprovalsInput = {
	configurationId: string;
	approvalsRequired: number;
	rejectionsRequired: number;
};

export type FieldApprovalEndpoints = {
	get: Read<T4CustomResponse<ApprovalConfiguration>>;
	toggle: Write<
		T4CustomResponse<ApprovalConfiguration, ToggleInput>,
		ToggleInput
	>;
	update: Write<
		T4CustomResponse<ApprovalConfiguration, UpdateFieldApprovalsInput>,
		UpdateFieldApprovalsInput
	>;
};

//#endregion

export function fieldApprovals(
	axiosInstance: AxiosInstance,
): FieldApprovalEndpoints {
	return {
		get: async (_, config = {}) =>
			axiosInstance.get('/api/v1.0/configuration/approvals/fieldapprovals', {
				...config,
			}),
		toggle: async (data, config = {}) =>
			axiosInstance.post('/api/v1.0/configuration/approvals', data, {
				...config,
			}),
		update: async (data, config = {}) =>
			axiosInstance.patch(
				`/api/v1.0/configuration/approvals/${data.configurationId}`,
				data,
				{
					...config,
				},
			),
	};
}
