import { InfoOutlined } from '@mui/icons-material';
import {
	Alert,
	Box,
	Breakpoint,
	Button,
	ButtonProps,
	CircularProgress,
	Collapse,
	Grid,
	Paper,
	Typography,
	useTheme,
} from '@mui/material';
import { FC, ReactNode } from 'react';
import { stonlyData } from 'stonly/functions';
import { T4Accordian } from '../../features/entity4/shared/components/atoms/t4Accordian';
import ModalBase from './modalBase';
import Draggable from 'react-draggable';

export const stonlyIds = {
	cancelButton: 'cancel-button',
	submitButton: 'submit-button',
	backButton: 'back-button',
};
export const formModalTestIds = {
	cancelButton: stonlyIds.cancelButton,
	submitButton: stonlyIds.submitButton,
};

export interface IFormModalProps {
	open: boolean;
	onClose: () => void;
	title: string;
	onSubmit: () => Promise<void> | void;
	error?: string;
	errors?: string[];
	loading: boolean;
	fullWidth?: boolean;
	maxWidth?: Breakpoint | false;
	description?: string;
	tips?: ReactNode;
	cancelButtonLabel?: string;
	submitButtonLabel?: string;
	submitButtonColor?: ButtonProps['color'];
	loadingMessage?: string;
	submitDisabled?: boolean;
	cancelDisabled?: boolean;
	submitButtonOnly?: boolean;
	cancelButtonOnly?: boolean;
	backButtonDisabled?: boolean;
	showBackButton?: boolean;
	onBackButtonClick?: () => void;
	isDraggable?: boolean;
}

export const isValid = (input: string | null | undefined): boolean => {
	return input !== null && input !== undefined && input.trim() !== '';
};

export const FormModal: FC<IFormModalProps> = ({
	open,
	onClose,
	title,
	onSubmit,
	error,
	errors,
	loading,
	fullWidth,
	maxWidth,
	description,
	tips,
	cancelButtonLabel,
	submitButtonLabel,
	submitButtonColor,
	loadingMessage,
	submitDisabled,
	cancelDisabled,
	submitButtonOnly,
	cancelButtonOnly = false,
	showBackButton,
	onBackButtonClick,
	backButtonDisabled,
	children,
	isDraggable = false,
}) => {
	const theme = useTheme();

	return (
		<ModalBase
			isDraggable={isDraggable}
			PaperComponent={isDraggable ? DraggableComponent : undefined}
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			title={title}
			onClose={onClose}
			open={open}
			actions={
				<Box
					display="flex"
					justifyContent="space-between"
					paddingX={3}
					paddingBottom={3}
				>
					{showBackButton ? (
						<Button
							{...stonlyData({ id: stonlyIds.backButton })}
							sx={{ whiteSpace: 'nowrap', paddingRight: '2em' }}
							type="button"
							color="secondary"
							onClick={onBackButtonClick}
							disabled={backButtonDisabled}
						>
							&lt; Go Back
						</Button>
					) : null}
					{loading ? (
						<Box display="flex" alignItems="center">
							<Box marginRight={2}>
								<Typography noWrap={true}>
									{loadingMessage ?? 'Loading...'}
								</Typography>
							</Box>
							<CircularProgress size={25} />
						</Box>
					) : null}
					<Grid container spacing={1} justifyContent="end">
						{!submitButtonOnly && (
							<Grid item>
								<Button
									data-testid={formModalTestIds.cancelButton}
									{...stonlyData({ id: stonlyIds.cancelButton })}
									onClick={onClose}
									color="secondary"
									disabled={cancelDisabled}
								>
									{cancelButtonLabel ?? 'Cancel'}
								</Button>
							</Grid>
						)}

						{!cancelButtonOnly && (
							<Grid item>
								<Button
									data-testid={stonlyIds.submitButton}
									{...stonlyData({
										id: stonlyIds.submitButton,
									})}
									type="submit"
									color={submitButtonColor ?? 'primary'}
									variant="contained"
									onClick={(event) => {
										event.preventDefault();

										if (onSubmit) {
											onSubmit();
										}
									}}
									disabled={submitDisabled}
								>
									{submitButtonLabel ?? 'Submit'}
								</Button>
							</Grid>
						)}
					</Grid>
				</Box>
			}
		>
			{description ? (
				<Typography marginBottom={2} component="div">
					{description}
				</Typography>
			) : null}
			{tips ? (
				<Box marginBottom={2}>
					<T4Accordian
						ariaControls="create-modal-content"
						accordianId="create-modal-id"
						sx={{
							backgroundColor: theme.palette.lightBackground?.main,
							boxShadow: 'none',
						}}
						summary={
							<Box display="flex" alignItems="center">
								<InfoOutlined color="secondary" sx={{ marginRight: '8px' }} />
								<Typography color="secondary">Helpful Tips</Typography>
							</Box>
						}
						details={<Typography component="div">{tips}</Typography>}
					/>
				</Box>
			) : null}
			<Collapse in={Boolean(error)}>
				<Box marginBottom={2}>
					<Alert severity="error">{error}</Alert>
				</Box>
			</Collapse>
			<Collapse in={errors && errors.length > 0}>
				{errors?.map((error) => (
					<Box marginBottom={2}>
						<Alert severity="error">{error}</Alert>
					</Box>
				))}
			</Collapse>
			<Box>{children}</Box>
		</ModalBase>
	);
};

const DraggableComponent: FC = (props) => {
	return (
		<Draggable
			handle=".draggable-dialog"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	);
};
