import {
	T4Autocomplete,
	T4AutocompleteProps,
} from '../shared/components/atoms/t4Autocomplete';
import {
	T4FieldAdornment,
	T4FieldAdornmentProps,
} from '../shared/components/molecules/t4FieldAdornment';

export type OptionFieldProps<
	TOption,
	TMultiple extends boolean = false,
	TDisableClearable extends boolean = boolean,
	TFreeSolo extends boolean = false,
> = Pick<
	T4AutocompleteProps<TOption, TMultiple, TDisableClearable, TFreeSolo>,
	| 'id'
	| 'label'
	| 'required'
	| 'disableClearable'
	| 'value'
	| 'options'
	| 'isOptionEqualToValue'
	| 'getOptionLabel'
	| 'onSelect'
> & {
	startAdornmentProps: Pick<T4FieldAdornmentProps, 'title' | 'description'>;
};

// eslint-disable-next-line mobx/missing-observer
export function OptionField<
	TOption,
	TMultiple extends boolean = false,
	TDisableClearable extends boolean = boolean,
	TFreeSolo extends boolean = false,
>({
	id,
	label,
	required,
	disableClearable,
	value,
	options,
	isOptionEqualToValue,
	getOptionLabel,
	onSelect,
	startAdornmentProps,
}: OptionFieldProps<TOption, TMultiple, TDisableClearable, TFreeSolo>) {
	return (
		<T4Autocomplete<TOption, TMultiple, TDisableClearable, TFreeSolo>
			id={id}
			label={label}
			required={required}
			disableClearable={disableClearable}
			value={value}
			options={options}
			isOptionEqualToValue={isOptionEqualToValue}
			getOptionLabel={getOptionLabel}
			onSelect={onSelect}
			startAdornment={
				<T4FieldAdornment
					id={id}
					t4AdornmentType="info"
					title={startAdornmentProps?.title}
					description={startAdornmentProps?.description}
				/>
			}
		/>
	);
}
