import React from 'react';
import { useLocation } from 'react-router-dom';

export type UseQueryProps = URLSearchParams;

export function useQuery(): UseQueryProps {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}
