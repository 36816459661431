import { useContext } from 'react';
import {
	T4LayoutContext,
	T4LayoutContextProps,
} from 'shared/providers/contexts/t4LayoutContext';

export function useT4Layout(): T4LayoutContextProps {
	const t4LayoutContext = useContext(T4LayoutContext);

	return t4LayoutContext;
}
