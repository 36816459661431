import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable } from 'mobx';
import moment from 'moment';
import { customerApi } from 'shared/providers/customerApi';
import { formatDate } from 'shared/utilities/dateUtilities';
import { ApiResponse } from 'utilities/api';
import { parseError } from 'utilities/errors/errorUtils';

export type BalancesErrors = {
	startDate: string[];
	endDate: string[];
};

export class BalancesCardModel {
	private _loading: boolean;
	private _error?: string;

	private _balanceCards: BalanceCards | null;

	constructor() {
		makeAutoObservable(this);

		this._loading = false;
		this._error = undefined;

		this._balanceCards = null;
	}

	public get loading() {
		return this._loading;
	}

	public get error() {
		return this._error;
	}

	public get balanceCards() {
		return this._balanceCards;
	}

	public get date() {
		return moment().subtract(1, 'days');
	}

	public load = flow(function* (this: BalancesCardModel) {
		try {
			this._loading = true;
			this._error = undefined;

			const response: AxiosResponse<ApiResponse<BalanceCards>> =
				yield customerApi.get<ApiResponse<BalanceCards>>(
					`/balances/priordayledger?clientDate=${formatDate(this.date)}`
				);

			if (response.data.error) this._error = response.data.error;
			else this._balanceCards = response.data.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}

export type BalanceCards = {
	dailyAccountOpeningBalances: number;
	dailyAccountClosingBalances: number;
};
