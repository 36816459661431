import { Box } from '@mui/material';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { Moment } from 'moment';
import { FC } from 'react';
import { useUser } from '../../../../shared/hooks/useUser';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { T4DateField } from '../../shared/components/atoms/t4DateField';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import { useFieldView } from '../object/hooks/useFieldView';
import { Collection } from '../object/models/collection';
import { T4Object } from '../object/models/t4Object';
import { ViewType } from '../object/utilities';
import { getDateWarningMessage } from '../utilities/dateUtilities';
import { VisibilityOffIcon } from './visibilityOffIcon';
import { useHeader } from 'shared/providers/contexts/headerContext';

export type DateFieldViewProps = {
	object: T4Object;
	field: Field;
	fieldViewType: ViewType;
	collection: Collection | undefined;
};

export const DateFieldView: FC<DateFieldViewProps> = observer(
	({ object, field, fieldViewType, collection }) => {
		const { isAuthor } = useUser();
		const { viewType } = useProfileView();
		const { updateLastAutoSave } = useHeader();
		const {
			hasPermission,
			showVisibilityIcon,
			error,
			warning,
			label,
			adornmentState,
			value,
			setValue,
			update,
		} = useFieldView<Moment>(
			object,
			field,
			fieldViewType,
			updateLastAutoSave,
			collection,
		);

		return (
			<Box>
				<T4DateField
					id={field.id}
					label={label}
					value={value ?? null}
					onChange={(date) => setValue(date ?? undefined)}
					onAccept={(date) => update(date ?? undefined)}
					onBlur={() => update(value ?? undefined)}
					disabled={!hasPermission}
					slotProps={{
						field: {
							InputProps: {
								startAdornment:
									field.description !== undefined ? (
										<T4FieldAdornment
											id={field.id}
											t4AdornmentType={adornmentState}
											hasPermission={hasPermission}
											title={field.name}
											description={field.description}
											iconButtonProps={{
												...(viewType === FieldViews.default
													? {
															sx: {
																marginLeft: '-5px',
															},
													  }
													: {}),
											}}
										/>
									) : undefined,
							},
						},
						textField: {
							variant:
								viewType === FieldViews.default ? 'standard' : 'outlined',
							InputProps: {
								endAdornment: showVisibilityIcon ? (
									<VisibilityOffIcon />
								) : undefined,
								sx: {
									paddingRight: '0px',
								},
							},
							sx: {
								'& .MuiInputAdornment-positionEnd': {
									paddingRight: '0px',
								},
							},
						},
					}}
					readOnly={viewType !== FieldViews.edit || !isAuthor}
				/>
				<T4AlertStack
					error={error}
					warning={getDateWarningMessage(field.name, value ?? null) || warning}
				/>
			</Box>
		);
	},
);
