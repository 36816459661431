import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { getPluralName } from 'features/entity4/entity4Constants';
import { observer } from 'mobx-react-lite';
import { RecentlyUpdated } from 'modules/clients/customer-api/src/entity4';
import { FC, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { T4Link } from 'shared/components/t4Link';
import { paths } from 'shared/constants/paths';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';

export type RecentlyUpdatedListProps = {
	recentlyUpdated: RecentlyUpdated[];
};

export const RecentlyUpdatedList: FC<RecentlyUpdatedListProps> = observer(
	({ recentlyUpdated, ...rest }) => {
		const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
			{
				page: 0,
				pageSize: 10,
			},
		);

		return (
			<UserPreferencesDataGrid<RecentlyUpdated>
				{...rest}
				stonlyId="recently-updated-panel"
				tableKey="recentlyUpdatedPanel"
				emptyMessage="No updates have been made in the last 14 days."
				pagination
				paginationModel={paginationModel}
				onPaginationModelChange={(model) => setPaginationModel(model)}
				pageSizeOptions={[10]}
				rows={recentlyUpdated}
				hideFooter={false}
				autoHeight
				sx={{
					'& .MuiChip-filled': {
						height: '28px',
					},
					'& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
						outline: 'none',
					},
				}}
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'lastUpdated',
								sort: 'desc',
							},
						],
					},
				}}
				columns={[
					{
						field: 'displayName',
						headerName: 'Display Name',
						flex: 10,
						minWidth: 150,
						renderCell: ({ value, row }) => {
							let url = generatePath(
								paths.entity4.objects.object.information.href,
								{
									objectType: getPluralName(row.objectType),
									objectId: row.id,
								},
							);

							if (row.parentObjectType === 'Account') {
								url = generatePath(
									paths.entity4.objects.object.subaccounts.subaccount.href,
									{
										objectType: getPluralName(row.parentObjectType),
										objectId: row.parentObjectId,
										subaccountId: row.id,
									},
								);
							}

							return (
								<T4Link color="secondary" to={url}>
									{value}
								</T4Link>
							);
						},
					},
					{
						field: 'entityType',
						headerName: 'Type',
						flex: 2,
						minWidth: 150,
						valueGetter: ({ row }) => row.objectType,
					},
					{
						...getDateColumnDefinition(),
						field: 'lastUpdated',
						headerName: 'Last Updated',
						flex: 2,
						minWidth: 150,
					},
				]}
			/>
		);
	},
);
