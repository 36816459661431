import { ExpandMore } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { stonlyData } from 'stonly/functions';
import { rem } from 'utilities/styles';
import { T4Solution } from '../../../shared/t4Solutions';
import { useSolutionPicker } from './useSolutionPicker';

interface SolutionPickerProps {
	activeSolution: T4Solution;
}

const SolutionPicker: React.FC<SolutionPickerProps> = observer(
	({ activeSolution }) => {
		const theme = useTheme();
		const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
		const open = Boolean(anchorEl);
		const { solutions, isPickerEnabled, getSolutionLogoSrc } =
			useSolutionPicker();

		const handleClick = (event: React.MouseEvent<HTMLElement>) => {
			setAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		return (
			<>
				<Button
					disabled={!isPickerEnabled}
					{...stonlyData({ id: 'solution-picker' })}
					onClick={handleClick}
					endIcon={
						isPickerEnabled ? <ExpandMore sx={{ color: '#fff' }} /> : undefined
					}
					sx={{
						padding: '0.5rem',
						'&:hover': {
							backgroundColor: 'rgba(255, 255, 255, 0.15)',
						},
					}}
				>
					<Box
						component="img"
						src={getSolutionLogoSrc(activeSolution)}
						alt={T4Solution[activeSolution]}
						sx={{
							display: 'block',
							height: rem(25),

							[theme.breakpoints.down('sm')]: {
								height: rem(25),
							},

							'&:hover': {
								cursor: 'pointer',
							},
						}}
					/>
				</Button>
				<Menu
					id="solution-picker"
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					sx={{
						paddingLeft: rem(24),
						'.MuiPaper-root': {
							borderRadius: 0,
						},
						'.MuiList-root': {
							background: theme.common?.charcoal?.main,
						},
					}}
				>
					{solutions.map((x) => (
						<MenuItem
							key={x.id}
							sx={{ color: '#fff', fontSize: rem(16), minWidth: 200 }}
							component={Link}
							to={x.url}
							onClick={handleClose}
						>
							{x.name}
						</MenuItem>
					))}
				</Menu>
			</>
		);
	},
);

export default SolutionPicker;
