import { observer } from 'mobx-react-lite';
import { VisualizationPreferencesReq } from 'modules/clients/customer-api/src/api/visualizations';
import { AccountMap } from 'modules/clients/customer-api/src/client';
import { useSnackbar } from 'notistack';
import {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';
import {
	useUserVisualizationPreference,
	UseUserVisualizationPreferenceProps,
} from '../../_hooks/useUserVisualizationPreference';

//#region Constants

const AccountMapVisualizationKey = 'accountMap';
const initialPreferences: VisualizationPreferencesReq = {
	options: [
		// Chart Options
		{
			optionId: 'displayStandaloneAccounts',
			hide: false,
		},
		{
			optionId: 'displaySubaccounts',
			hide: true,
		},
		{
			optionId: 'displayClosedAccounts',
			hide: false,
		},
		{
			optionId: 'displayLegend',
			hide: false,
		},
		{
			optionId: 'displayOverviewMap',
			hide: false,
		},

		// Card Options
		{
			optionId: 'cardOptionsColor',
			hide: false,
			value: 'accountPurpose',
		},
		{
			optionId: 'displayAccountType',
			hide: false,
		},
		{
			optionId: 'displayFlag',
			hide: false,
		},

		// Information Options
		{
			optionId: 'accountStatus',
			hide: false,
		},
		{
			optionId: 'openDate',
			hide: false,
		},
		{
			optionId: 'closeDate',
			hide: false,
		},
		{
			optionId: 'accountType',
			hide: false,
		},
		{
			optionId: 'purpose',
			hide: false,
		},
		{
			optionId: 'counterpartyBranch',
			hide: false,
		},
		{
			optionId: 'entityRegion',
			hide: false,
		},
		{
			optionId: 'entityErpCode',
			hide: false,
		},
		{
			optionId: 'generalLedgerAccountNumber',
			hide: false,
		},
	],
};

//#endregion

//#region Context

type AccountMapContextProps = {
	isLoading: boolean;
	error: string | undefined;
	accountMap: AccountMap | undefined;
	accountView: 'account' | 'entity';
	visualizationPreferences: UseUserVisualizationPreferenceProps['visualizationPreferences'];
	updateVisualizationPreferences: UseUserVisualizationPreferenceProps['updatePreferences'];
	toggleAccountView: () => void;
};

const AccountMapContext = createContext<AccountMapContextProps>({
	isLoading: false,
	error: undefined,
	accountMap: undefined,
	accountView: 'account',
	visualizationPreferences: {} as any,
	toggleAccountView: () => {},
	updateVisualizationPreferences: () => Promise.resolve(),
});

//#endregion

//#region Provider

export type AccountMapProviderProps = {
	children: ReactNode;
};

export const AccountMapProvider: FC<AccountMapProviderProps> = observer(
	({ children }) => {
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();
		const {
			isLoading: isLoadingPreferences,
			visualizationPreferences,
			updatePreferences: updateVisualizationPreferences,
		} = useUserVisualizationPreference(
			AccountMapVisualizationKey,
			initialPreferences,
		);

		const [isLoading, setIsLoading] = useState(true);
		const [error, setError] = useState<string>();
		const [accountMap, setAccountMap] = useState<AccountMap>();
		const [accountView, setAccountView] =
			useState<AccountMapContextProps['accountView']>('account');

		const toggleAccountView = useCallback<
			AccountMapContextProps['toggleAccountView']
		>(() => {
			setAccountView(accountView === 'account' ? 'entity' : 'account');
		}, [accountView]);

		const initalize = useCallback<() => Promise<void>>(async () => {
			try {
				setIsLoading(true);

				const response = await customerApiClient.getAccountMap();

				if (response && response.data && response.data.value) {
					setAccountMap(response.data.value);
				} else {
					throw new Error('Did not retrive Account Map data.');
				}
			} catch {
				const errorMessage = 'Failed to load the account map.';
				enqueueSnackbar(errorMessage, { variant: 'error' });
				setError(errorMessage);
			} finally {
				setIsLoading(false);
			}
		}, [customerApiClient, enqueueSnackbar]);

		useEffect(() => {
			initalize();
		}, [initalize]);

		return (
			<AccountMapContext.Provider
				value={{
					isLoading: isLoading || isLoadingPreferences,
					error: error,
					accountMap: accountMap,
					accountView: accountView,
					visualizationPreferences: visualizationPreferences,
					toggleAccountView: toggleAccountView,
					updateVisualizationPreferences: updateVisualizationPreferences,
				}}
			>
				{children}
			</AccountMapContext.Provider>
		);
	},
);

//#endregion

//#region Hook

export type UseAccountMap = AccountMapContextProps;

export function useAccountMap(): UseAccountMap {
	return useContext(AccountMapContext);
}

//#endregion
