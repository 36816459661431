import {
	AddLegalEntityGroupsResponse,
	RemoveLegalEntityGroupsResponse,
} from 'modules/clients/customer-api/src/entity4/entities';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';

export type UseLegalEntityGroupsSelectorProps = {
	isLoading: boolean;
	selectedLegalEntityGroupIds: string[];
	setSelectedLegalEntityGroupIds: (ids: string[]) => void;
	onBlur: (values: string[]) => void;
};

export function useLegalEntityGroupsSelector(
	legalEntityId: string,
): UseLegalEntityGroupsSelectorProps {
	const {
		isLoading: loading,
		legalEntityGroupCollections,
		refetch,
	} = useLegalEntityGroups();
	const { customerApiClient } = useClients();

	const [_isLoading, _setIsLoading] = useState(false);
	const [previousLegalEntityGroupIds, setPreviousLegalEntityGroupIds] =
		useState<string[]>([]);
	const [selectedLegalEntityGroupIds, setSelectedLegalEntityGroupIds] =
		useState<string[]>([]);

	const isLoading = useMemo(() => _isLoading || loading, [_isLoading, loading]);

	const onBlur = useCallback(
		async (values: string[]) => {
			const addedLegalEntityGroupIds = values.filter(
				(x) => !previousLegalEntityGroupIds.includes(x),
			);
			const removedLegalEntityGroupIds = previousLegalEntityGroupIds.filter(
				(x) => !values.includes(x),
			);

			let requests: [
				Promise<AddLegalEntityGroupsResponse>?,
				Promise<RemoveLegalEntityGroupsResponse>?,
			] = [];
			_setIsLoading(true);
			if (addedLegalEntityGroupIds.length > 0) {
				requests.push(
					customerApiClient.entity4.entities.addLegalEntityGroups({
						legalEntityId: legalEntityId,
						legalEntityGroupIds: addedLegalEntityGroupIds,
					}),
				);
			} else {
				requests.push(undefined);
			}

			if (removedLegalEntityGroupIds.length > 0) {
				requests.push(
					customerApiClient.entity4.entities.removeLegalEntityGroups({
						legalEntityId: legalEntityId,
						legalEntityGroupIds: removedLegalEntityGroupIds,
					}),
				);
			} else {
				requests.push(undefined);
			}
			const [addedResponse, removedResponse] = await Promise.all(requests);

			let success = true;
			let copyOfSelectedLegalEntityGroupIds = [...values];
			if (!(addedResponse === undefined || addedResponse?.data?.success)) {
				success = false;
				copyOfSelectedLegalEntityGroupIds =
					copyOfSelectedLegalEntityGroupIds.filter(
						(x) => !addedLegalEntityGroupIds.includes(x),
					);
			}

			if (!(removedResponse === undefined || removedResponse?.data?.success)) {
				success = false;
				copyOfSelectedLegalEntityGroupIds =
					copyOfSelectedLegalEntityGroupIds.concat(removedLegalEntityGroupIds);
			}

			if (success) {
				if (
					addedLegalEntityGroupIds.length !== 0 ||
					removedLegalEntityGroupIds.length !== 0
				) {
					refetch();
				}
			} else {
				setSelectedLegalEntityGroupIds(copyOfSelectedLegalEntityGroupIds);
			}
			_setIsLoading(false);
		},
		[
			customerApiClient.entity4.entities,
			legalEntityId,
			refetch,
			previousLegalEntityGroupIds,
		],
	);

	useEffect(() => {
		const legalEntityGroupIds = legalEntityGroupCollections.flatMap(
			(x) =>
				x.legalEntityGroups
					.filter((y) => y.legalEntities.some((z) => z.id === legalEntityId))
					.map((x) => x.id) ?? [],
		);

		setSelectedLegalEntityGroupIds(legalEntityGroupIds);
		setPreviousLegalEntityGroupIds(legalEntityGroupIds);
	}, [legalEntityGroupCollections, legalEntityId]);

	return {
		isLoading: isLoading,
		selectedLegalEntityGroupIds,
		setSelectedLegalEntityGroupIds: setSelectedLegalEntityGroupIds,
		onBlur: onBlur,
	};
}
