import { ObjectType } from 'modules/clients/customer-api/src/api/objects';
import {
	Field,
	FieldGroup as NewFieldGroup,
	Tab as NewTab,
} from '../../../../../modules/clients/customer-api/src/api/common';
import { Collection } from '../../object/models/collection';
import { T4Object } from '../../object/models/t4Object';
import { BooleanFieldView } from '../booleanFieldView';
import { CollectionView } from '../collectionView';
import { DateFieldView } from '../dateFieldView';
import { HeaderRef } from '../entityFieldRenderer';
import { EntityFieldView } from '../entityFieldView';
import { FieldGroupView } from '../fieldGroupView';
import { OptionFieldView } from '../optionFieldView';
import { PhoneNumberFieldView } from '../phoneNumberFieldView';
import { ReferenceFieldView } from '../referenceFieldView';
import { StringFieldView } from '../stringFieldView';

export const renderCollection = (
	object: T4Object,
	newTab: NewTab,
	newFieldGroup: NewFieldGroup,
	parentCollection: Collection | undefined,
	v2Refs: HeaderRef[] = [],
) => [
	newFieldGroup.sortOrder,
	<CollectionView
		key={`collection-${newFieldGroup.id}`}
		object={object}
		newTab={newTab}
		newFieldGroup={newFieldGroup}
		parentCollection={parentCollection}
		v2Refs={v2Refs}
	/>,
];

export const getFields = (
	object: T4Object,
	newFieldGroup: NewFieldGroup,
	entity4StaffPii: boolean,
	collection?: Collection,
): [Field, JSX.Element][] => [
	...newFieldGroup.fields
		.filter(
			(x) =>
				(x.fieldType === 'Text' && !x.referenceCollectionName) ||
				x.fieldType === 'LongText',
		)
		.filter(
			(x) =>
				entity4StaffPii || object.objectType !== ObjectType.staff || !x.isPII,
		)
		.map(
			(field) =>
				[
					field,
					<EntityFieldView
						key={`entity-field-view-${field.id}`}
						object={object}
						field={field}
						approvalField={
							<StringFieldView
								object={object}
								field={field}
								fieldViewType="approved"
								multiline={field.fieldType === 'LongText'}
								collection={collection}
							/>
						}
						pendingField={
							<StringFieldView
								object={object}
								field={field}
								fieldViewType="pending"
								multiline={field.fieldType === 'LongText'}
								collection={collection}
							/>
						}
					/>,
				] as [Field, JSX.Element],
		),
	...newFieldGroup.fields
		.filter((x) => x.fieldType === 'Boolean')
		.filter(
			(x) =>
				entity4StaffPii || object.objectType !== ObjectType.staff || !x.isPII,
		)
		.map(
			(field) =>
				[
					field,
					<EntityFieldView
						key={`entity-field-view-${field.id}`}
						object={object}
						field={field}
						approvalField={
							<BooleanFieldView
								object={object}
								field={field}
								fieldViewType="approved"
								collection={collection}
							/>
						}
						pendingField={
							<BooleanFieldView
								object={object}
								field={field}
								fieldViewType="pending"
								collection={collection}
							/>
						}
					/>,
				] as [Field, JSX.Element],
		),
	...newFieldGroup.fields
		.filter((x) => x.fieldType === 'Date')
		.filter(
			(x) =>
				entity4StaffPii || object.objectType !== ObjectType.staff || !x.isPII,
		)
		.map(
			(field) =>
				[
					field,
					<EntityFieldView
						key={`entity-field-view-${field.id}`}
						object={object}
						field={field}
						approvalField={
							<DateFieldView
								object={object}
								field={field}
								fieldViewType="approved"
								collection={collection}
							/>
						}
						pendingField={
							<DateFieldView
								object={object}
								field={field}
								fieldViewType="pending"
								collection={collection}
							/>
						}
					/>,
				] as [Field, JSX.Element],
		),
	...newFieldGroup.fields
		.filter((x) => x.fieldType === 'Option')
		.filter(
			(x) =>
				entity4StaffPii || object.objectType !== ObjectType.staff || !x.isPII,
		)
		.map(
			(field) =>
				[
					field,
					<EntityFieldView
						key={`entity-field-view-${field.id}`}
						object={object}
						field={field}
						approvalField={
							<OptionFieldView
								object={object}
								field={field}
								fieldViewType="approved"
								collection={collection}
							/>
						}
						pendingField={
							<OptionFieldView
								object={object}
								field={field}
								fieldViewType="pending"
								collection={collection}
							/>
						}
					/>,
				] as [Field, JSX.Element],
		),
	...newFieldGroup.fields
		.filter((x) => x.fieldType === 'PhoneNumber')
		.filter(
			(x) =>
				entity4StaffPii || object.objectType !== ObjectType.staff || !x.isPII,
		)
		.map(
			(field) =>
				[
					field,
					<EntityFieldView
						key={`entity-field-view-${field.id}`}
						object={object}
						field={field}
						approvalField={
							<PhoneNumberFieldView
								object={object}
								field={field}
								fieldViewType="pending"
								collection={collection}
							/>
						}
						pendingField={
							<PhoneNumberFieldView
								object={object}
								field={field}
								fieldViewType="pending"
								collection={collection}
							/>
						}
					/>,
				] as [Field, JSX.Element],
		),
	...newFieldGroup.fields
		.filter((x) => x.fieldType === 'Text' && x.referenceCollectionName)
		.filter(
			(x) =>
				entity4StaffPii || object.objectType !== ObjectType.staff || !x.isPII,
		)
		.map(
			(field) =>
				[
					field,
					<EntityFieldView
						key={`entity-field-view-${field.id}`}
						object={object}
						field={field}
						approvalField={
							<ReferenceFieldView
								object={object}
								field={field}
								fieldViewType="approved"
								collection={collection}
							/>
						}
						pendingField={
							<ReferenceFieldView
								object={object}
								field={field}
								fieldViewType="pending"
								collection={collection}
							/>
						}
					/>,
				] as [Field, JSX.Element],
		),
];

export const renderFields = (
	object: T4Object,
	newFieldGroup: NewFieldGroup,
	entity4StaffPii: boolean,
	collection?: Collection,
): [number, JSX.Element][] =>
	getFields(object, newFieldGroup, entity4StaffPii, collection).map(
		([field, component]) => [field.sortOrder, component],
	);

export const renderFieldGroup = (
	object: T4Object,
	newTab: NewTab,
	newFieldGroup: NewFieldGroup,
	v2Refs: HeaderRef[],
	entity4StaffPii: boolean,
	parentCollection: Collection | undefined,
	hideDivider: boolean = false,
) => {
	let element: JSX.Element | null = null;

	if (
		(newFieldGroup?.fields?.filter((x) => (entity4StaffPii ? true : !x.isPII))
			?.length ?? 0) > 0
	) {
		element = (
			<FieldGroupView
				key={`field-group-${newFieldGroup.id}`}
				object={object}
				newTab={newTab}
				newFieldGroup={newFieldGroup}
				v2Refs={v2Refs}
				collection={parentCollection}
				hideDivider={hideDivider}
			/>
		);
	}

	return [newFieldGroup.sortOrder, element];
};
