import { IRegistrationAuthorityApiData } from 'features/entity4/entities/entitiesApiTypes';
import {
	EntityType,
	entityTypeIdToEntityType,
} from 'features/entity4/entity4Constants';
import { JurisdictionRepository } from 'features/entity4/entityAspect/RegisteredAgentDetails/jurisdictionRepository';
import { Jurisdiction } from 'features/entity4/entityAspect/RegisteredAgentDetails/models/jurisdictionModel';
import {
	CommonCustomerFieldsRepository,
	CustomerFieldsRepository,
} from 'features/entity4/fields/customerFieldsRepository';
import { AdornmentType } from 'features/entity4/shared/components/molecules/inputAdornmentWithModal';
import CommonReferenceStore, {
	ReferenceStore,
} from 'features/entity4/shared/referenceStore';
import FrontendRepository from 'features/entity4/shared/repositories/frontendRepository';
import { History } from 'history';
import { flow, makeAutoObservable, runInAction } from 'mobx';
import { Moment } from 'moment';
import { generatePath } from 'react-router-dom';
import { paths } from 'shared/constants/paths';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import {
	extractValueFromRefJson,
	jsonToReferenceData,
	referenceDataToJson,
} from 'shared/utilities/referenceDataUtilities';
import { parseError } from 'utilities/errors/errorUtils';
import { capitalizeString } from 'utilities/stringUtils';
import { Registration } from '../../models/registration';
import { REGISTRATION_STATUS_OPTION_LIST_ID } from '../../registrationConstants';
import CommonRegistrationStore, {
	RegistrationStore,
} from '../../registrationStore';
import { IRegisteredAgentDto, IRegistrationDto } from '../../registrationTypes';

type updateDate = (date: Moment | null) => void;

const mismatchMessage = (childField: string) =>
	`The selected Country does not match the selected ${childField}.`;
export class RegistrationViewModel {
	public provinceError?: string;
	public authorityError?: string;
	public elfError?: string;
	private _error?: string | null;

	private _loadingRegistration = true;
	private _loadingCountries = false;
	private _loadingProvinces = false;
	private _loadingForms = false;
	private _loadingAuthorities = false;
	private _loadingAuthorityDetails = false;
	private _loadingStatuses = false;

	private _statuses: Option[] = [];
	private _countries: ReferenceDataValue[] = [];
	private _provinces: ReferenceDataValue[] = [];
	private _legalForms: ReferenceDataValue[] = [];
	private _authorities: ReferenceDataValue[] = [];

	private _registeredAgents: IRegisteredAgentDto[] = [];
	private _loadingRegisteredAgents = false;
	private _registeredAgentError = '';
	private _matchingJursidiction: Jurisdiction | null = null;
	private _loadingMatchingJurisdiction = false;
	private _matchingJurisdictionError = '';

	private _authorityDetails?: IRegistrationAuthorityApiData;

	private _registrationId: Registration['id'];
	private _history: History;
	private _updateLastAutoSave: (autoSaveDate?: Date) => void;
	private _registrationStore: RegistrationStore;
	private _referenceStore: ReferenceStore;
	private _customerFieldsRepository: CustomerFieldsRepository;
	private _registration?: Registration;

	public isHomeWarningShown = false;

	private _isMakeHomeRegistrationModalOpen: boolean = false;

	constructor(
		registrationId: Registration['id'],
		history: History,
		updateLastAutoSave: (autoSaveDate?: Date) => void,
		registrationStore: RegistrationStore = CommonRegistrationStore,
		referenceStore: ReferenceStore = CommonReferenceStore,
		customerFieldsRepository = CommonCustomerFieldsRepository,
		registration?: Registration,
	) {
		makeAutoObservable(this);

		this._registrationId = registrationId;
		this._history = history;
		this._updateLastAutoSave = updateLastAutoSave;
		this._registrationStore = registrationStore;
		this._referenceStore = referenceStore;
		this._customerFieldsRepository = customerFieldsRepository;
		this._registration = registration;
	}

	public load = flow(function* (this: RegistrationViewModel) {
		yield Promise.all([
			this.loadRegistration().catch((error) => {
				throw error;
			}),
			this.loadCountries(),
		]).catch((error) => {
			throw error;
		});
	});

	public loadRegistration = flow(function* (this: RegistrationViewModel) {
		try {
			this._loadingRegistration = true;
			this._registration = yield this._registrationStore.getRegById(
				this._registrationId,
			);

			if (this._registration) {
				this._updateLastAutoSave(this._registration?.updatedDate.toDate());
				yield this.loadProvinces();
				yield Promise.all([
					this.loadLegalForms(),
					this.loadAuthorities(),
					this.loadAuthorityDetails(),
					this.loadStatuses(),
					this.loadMatchingJurisdiction(),
				]);
			}
		} catch (error) {
			this._error = parseError(error);
		} finally {
			this._loadingRegistration = false;
			this.validateAll();
		}
	});

	public loadCountries = flow(function* (this: RegistrationViewModel) {
		try {
			this._loadingCountries = true;
			this._countries = yield this._referenceStore.getCountries();
		} catch (error) {
			this._error = error as string;
		} finally {
			this._loadingCountries = false;
		}
	});

	public loadProvinces = flow(function* (
		this: RegistrationViewModel,
		countryCode?: string | null,
	) {
		try {
			this._loadingProvinces = true;
			this.registration?.setIsWaiting('registrationState');
			if (!countryCode) {
				countryCode = extractValueFromRefJson(
					this._registration?.registrationCountry,
				);
			}
			this._provinces = countryCode
				? yield this._referenceStore.getStateByCountry(countryCode)
				: [];
		} catch (error) {
			this._error = error as string;
		} finally {
			this.registration?.setIsDirty('registrationState');
			this._loadingProvinces = false;
		}
	});

	async loadAuthorities(countryCode?: string | null) {
		this._loadingAuthorities = true;
		this.registration?.setIsWaiting('registrationState');
		try {
			if (!countryCode) {
				countryCode = extractValueFromRefJson(
					this._registration?.registrationCountry,
				);
			}
			const authorities = countryCode
				? await this._referenceStore.getRegistrationAuthorities(countryCode)
				: [];
			runInAction(() => {
				this._authorities = authorities;
			});
		} catch (error) {
			runInAction(() => {
				this._error = error as string;
			});
		} finally {
			runInAction(() => {
				this.registration?.setIsDirty('registrationState');
				this._loadingAuthorities = false;
			});
		}
	}

	async loadLegalForms(
		countryCode?: string | null,
		provinceCode?: string | null,
	) {
		this._loadingForms = true;
		this.registration?.setIsWaiting('registrationLegalForm');
		try {
			if (!countryCode) {
				countryCode = extractValueFromRefJson(
					this._registration?.registrationCountry,
				);
			}
			if (!provinceCode) {
				provinceCode = extractValueFromRefJson(
					this._registration?.registrationState,
				);
			}

			const province = jsonToReferenceData(
				this._registration?.registrationState,
			);

			if (
				!this._provinces?.find(
					(p) =>
						p.value === province?.value &&
						p.displayName === province?.displayName,
				)
			) {
				provinceCode = null;
			}

			const legalForms = countryCode
				? await this._referenceStore.getLegalForms(countryCode, provinceCode)
				: [];
			runInAction(() => {
				this._legalForms = legalForms.map((legalForm) => {
					const parentIdentifier =
						legalForm.parentIdentifier || countryCode || undefined;
					return {
						...legalForm,
						parentIdentifier,
					};
				});
			});
		} catch (error) {
			runInAction(() => {
				this._error = error as string;
			});
		} finally {
			runInAction(() => {
				this.registration?.setIsDirty('registrationLegalForm');
				this._loadingForms = false;
			});
		}
	}

	public async loadAuthorityDetails(authorityCode?: string | null) {
		this._loadingAuthorityDetails = true;
		try {
			if (!authorityCode) {
				authorityCode = extractValueFromRefJson(
					this._registration?.registrationAuthority,
				);
			}
			const details = authorityCode
				? await this._referenceStore.getRegistrationAuthorityByIdentifier(
						authorityCode,
				  )
				: undefined;
			runInAction(() => {
				this._authorityDetails = details;
			});
		} catch (error) {
			runInAction(() => {
				this._error = error as string;
			});
		} finally {
			runInAction(() => {
				this._loadingAuthorityDetails = false;
			});
		}
	}

	async loadStatuses() {
		this._loadingStatuses = true;
		try {
			const statuses =
				await this._customerFieldsRepository.getOptionsByOptionListSourceIdentifier(
					REGISTRATION_STATUS_OPTION_LIST_ID,
				);
			runInAction(() => {
				this._statuses = statuses;
			});
		} catch (error) {
			runInAction(() => {
				this._error = error as string;
			});
		} finally {
			runInAction(() => {
				this._loadingStatuses = false;
			});
		}
	}

	public get error() {
		return this._error;
	}

	public get loading() {
		// It's less code for now to combine these.
		// Later, behavior can be fine-tuned to each relevant component.
		return this._loadingRegistration || this._loadingCountries;
	}

	public get loadingCountries() {
		return this._loadingCountries;
	}

	public get loadingProvinces() {
		return this._loadingProvinces;
	}

	public get loadingForms() {
		return this._loadingForms;
	}

	public get loadingAuthorities() {
		return this._loadingAuthorities;
	}

	public get loadingAuthorityDetails() {
		return this._loadingAuthorityDetails;
	}

	public get loadingStatuses() {
		return this._loadingStatuses;
	}

	public get registration() {
		return this._registration;
	}

	// Views require a sync value
	public get countryOptions() {
		return this._countries;
	}

	public get provinceOptions() {
		return this._provinces;
	}

	public get legalFormOptions() {
		return this._legalForms;
	}

	public get authorityOptions() {
		return this._authorities;
	}

	public get statusOptions() {
		return this._statuses;
	}

	public get currentCountry(): ReferenceDataValue | null {
		return jsonToReferenceData(this._registration?.registrationCountry);
	}

	public get currentProvinceCode() {
		return this._registration?.registrationState!;
	}

	public get currentProvince(): ReferenceDataValue | null {
		return jsonToReferenceData(this._registration?.registrationState);
	}

	public get currentLegalFormCode() {
		return this._registration?.registrationLegalForm!;
	}

	public get currentLegalForm(): ReferenceDataValue | null {
		return jsonToReferenceData(this._registration?.registrationLegalForm);
	}

	public get currentAuthority(): ReferenceDataValue | null {
		return jsonToReferenceData(this._registration?.registrationAuthority);
	}

	public get currentStatus(): Option | null {
		const status =
			this._statuses.find((x) => x.id === this.registration?.statusId) ?? null;
		return status;
	}

	public get countryCode() {
		return this.registration?.registrationCountry;
	}

	public get isMakeHomeRegistrationModalOpen(): boolean {
		return this._isMakeHomeRegistrationModalOpen;
	}

	public openMakeHomeRegistrationModal(): void {
		this._isMakeHomeRegistrationModalOpen = true;
	}

	public closeMakeHomeRegistrationModal(): void {
		this._isMakeHomeRegistrationModalOpen = false;
	}

	public onMakeHomeRegistrationClick(isHomeRegisration: boolean) {
		if (
			isHomeRegisration &&
			this.registration?.asDto().homeRegistrationDefined
		) {
			this.openMakeHomeRegistrationModal();
		} else {
			this.registration
				?.update('isHomeRegistration', isHomeRegisration)
				.finally(() => this._updateLastAutoSave());
		}
	}

	public makeHomeRegistration = flow(function* (this: RegistrationViewModel) {
		yield this.registration?.update('isHomeRegistration', true);
		this._isMakeHomeRegistrationModalOpen = false;
	});

	public async changeCountry(option: ReferenceDataValue | null) {
		// Load provinces first - can maybe use to narrow down legal forms
		await this.registration
			?.update('registrationCountry', referenceDataToJson(option))
			.finally(() => this._updateLastAutoSave());
		await this.loadProvinces(option?.identifier);
		await Promise.all([
			this.loadLegalForms(option?.identifier),
			this.loadAuthorities(option?.identifier),
			this.loadMatchingJurisdiction(),
		]);
		this.validateAll();
	}

	public validateCountryAuthorityMatch(): void {
		if (this.registration?.registrationAuthority) {
			const authority = jsonToReferenceData(
				this._registration?.registrationAuthority,
			);
			const country = jsonToReferenceData(
				this._registration?.registrationCountry,
			);
			if (authority && authority.parentIdentifier !== country?.identifier) {
				this.authorityError = mismatchMessage('Registration Authority');
				return;
			}
		}
		this.authorityError = undefined;
	}

	public validateCountryProvinceMatch(): void {
		if (this.registration?.registrationState) {
			const state = jsonToReferenceData(this._registration?.registrationState);
			const country = jsonToReferenceData(
				this._registration?.registrationCountry,
			);
			if (state && state.parentIdentifier !== country?.identifier) {
				this.provinceError = mismatchMessage('State/Province');
				return;
			}
		}
		this.provinceError = undefined;
	}

	public validateCountryElfMatch(): void {
		if (this.registration?.registrationLegalForm) {
			const form = jsonToReferenceData(
				this._registration?.registrationLegalForm,
			);
			const country = jsonToReferenceData(
				this._registration?.registrationCountry,
			);
			if (form && form.parentIdentifier !== country?.identifier) {
				this.elfError = mismatchMessage('Legal Form');
				return;
			}
		}
		this.elfError = undefined;
	}

	public validateAll(): void {
		this.validateCountryAuthorityMatch();
		this.validateCountryProvinceMatch();
		this.validateCountryElfMatch();
	}

	public async changeProvince(option: ReferenceDataValue | null) {
		const countryCode = extractValueFromRefJson(
			this.registration?.registrationCountry,
		);

		await this.registration
			?.update('registrationState', referenceDataToJson(option))
			.finally(() => this._updateLastAutoSave());
		await this.loadLegalForms(countryCode, option?.identifier ?? null);
		await this.loadMatchingJurisdiction();
		this.validateCountryProvinceMatch();
	}

	public async changeLegalForm(option: ReferenceDataValue | null) {
		await this.registration
			?.update('registrationLegalForm', referenceDataToJson(option))
			.finally(() => this._updateLastAutoSave());
		this.validateCountryElfMatch();
	}

	public async changeAuthority(option: ReferenceDataValue | null) {
		let loadedDetails = option ? this.loadAuthorityDetails(option.value) : null;
		if (option === null) {
			this._authorityDetails = undefined;
		}

		await Promise.all([
			this.registration
				?.update('registrationAuthority', referenceDataToJson(option))
				.finally(() => this._updateLastAutoSave()),
			loadedDetails,
		]);
		this.validateCountryAuthorityMatch();
	}

	public async changeStatus(option: Option | null) {
		await this.registration
			?.update('statusId', option?.id)
			.finally(() => this._updateLastAutoSave());
	}

	public get authorityDetails() {
		return this._authorityDetails;
	}

	public makeUpdateDate = (
		fieldKey: 'expirationDate' | 'lastFiledDate' | 'registrationDate',
	): updateDate => {
		return async (date: Moment | null): Promise<void> => {
			this.registration?.setIsWaiting(fieldKey);
			if (this.registration) this.registration[fieldKey] = date!;
		};
	};

	// registered agent functions
	public get registeredAgentOptions(): IRegisteredAgentDto[] {
		if (this._registeredAgents.length === 0) {
			const agent = this.currentRegisteredAgent;
			return agent !== null ? [agent] : [];
		}

		return this._registeredAgents
			.slice()
			.sort((a, b) =>
				capitalizeString(entityTypeIdToEntityType.get(a.entityType)!)! <
				capitalizeString(entityTypeIdToEntityType.get(b.entityType)!)!
					? -1
					: 1,
			);
	}

	public get hasRegisteredAgentOptions(): boolean {
		return this._registeredAgents.length > 0;
	}

	public get loadingRegisteredAgents() {
		return this._loadingRegisteredAgents;
	}

	public get loadingMatchingJurisdiction() {
		return this._loadingMatchingJurisdiction;
	}

	public get registeredAgentError() {
		return this._registeredAgentError;
	}

	public get matchingJurisdictionError() {
		return this._matchingJurisdictionError;
	}

	public get currentRegisteredAgent() {
		const agent = this._registration?.registeredAgent;
		return agent ? agent : null;
	}

	public get matchingJurisdiction() {
		return this._matchingJursidiction;
	}

	public changeRegisteredAgent = async (option: IRegisteredAgentDto | null) => {
		runInAction(() => {
			this._matchingJurisdictionError = '';
		});

		await this._registration
			?.update('registeredAgent', option)
			.finally(() => this._updateLastAutoSave());
		await this.loadMatchingJurisdiction();
	};

	public navigateToRegisteredAgent = async () => {
		var registeredAgent = this._registration?.registeredAgent;
		if (!registeredAgent) return;

		const entityType = entityTypeIdToEntityType.get(
			registeredAgent!.entityType,
		);

		this._history.push(
			generatePath(paths.entity4.objects.object.information.href, {
				objectType: entityType,
				objectId: registeredAgent.id,
			}),
		);
	};

	public loadRegisteredAgents = async () => {
		try {
			runInAction(async () => {
				this._registeredAgentError = '';
				this._loadingRegisteredAgents = true;
				this._registeredAgents = await FrontendRepository.getRegisteredAgents(
					EntityType.Entity,
				);
			});
		} catch (error) {
			runInAction(() => {
				this._registeredAgentError = error as string;
			});
		} finally {
			runInAction(() => {
				this._loadingRegisteredAgents = false;
			});
		}
	};

	public loadMatchingJurisdiction = async () => {
		try {
			runInAction(() => {
				this._matchingJurisdictionError = '';
			});

			var registeredAgent = this._registration?.registeredAgent;
			if (!registeredAgent) {
				this._matchingJursidiction = null;
				return;
			}

			runInAction(() => {
				this._loadingMatchingJurisdiction = true;
			});

			const entityType = entityTypeIdToEntityType.get(
				registeredAgent!.entityType,
			);
			const match =
				await JurisdictionRepository.getMatchingRegisteredAgentJurisdiction(
					registeredAgent!.id,
					entityType!,
					this._registration!.id,
				);

			runInAction(() => {
				this._matchingJursidiction = new Jurisdiction(match, entityType!);
			});
		} catch (error) {
			runInAction(() => {
				this._matchingJursidiction = null;
				this._matchingJurisdictionError = error as string;
			});
		} finally {
			runInAction(() => {
				this._loadingMatchingJurisdiction = false;
			});
		}
	};

	public adornmentType<T extends keyof IRegistrationDto>(
		fieldKey: T,
	): AdornmentType {
		if (this.registration?.isWaiting(fieldKey)) {
			return 'loading';
		}
		if (this.registration?.isSaved(fieldKey)) {
			return 'success';
		}
		return 'info';
	}
}
