import { AxiosInstance } from 'axios';
import { T4CustomResponse } from '../../client';
import { Read, T4Response } from 'modules/clients/types';

export type Currency = {
	code: string | null;
	name: string | null;
};

export type CurrencyConfigurationResult = T4CustomResponse<{
	reportingCurrencyCode: Currency;
	activeCurrencies: Currency[];
}>;
export type CurrencyConfigurationResponse =
	T4Response<CurrencyConfigurationResult>;

export type ConfigurationsEndpoints = {
	getCurrencyConfiguration: Read<CurrencyConfigurationResult>;
};

export function configurations(
	axiosInstance: AxiosInstance,
): ConfigurationsEndpoints {
	return {
		getCurrencyConfiguration: async (
			_,
			config,
		): Promise<CurrencyConfigurationResponse> =>
			await axiosInstance.get('cash4/configurations/currency', {
				...config,
			}),
	};
}
