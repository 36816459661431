import { Container } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ObjectDocumentPathParams } from 'shared/constants/paths';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { useUser } from '../../../../../../shared/hooks/useUser';
import { useProfileView } from '../../../../entityProfile/providers/entityProfileContextProvider';
import { DocumentView } from '../../../../globalDocuments/components/documentView';
import { GlobalDocumentViewModel } from '../../../../globalDocuments/pages/models/globalDocumentViewModel';
import { GlobalDocumentsRepository } from '../../../../globalDocuments/shared/globalDocumentsRepository';
import { FieldViews } from '../../../../shared/fieldSets/fieldViews/fieldViewTypes';

export const ObjectDocumentPage: FC = observer(() => {
	const { documentId } = useParams<ObjectDocumentPathParams>();
	const { viewType } = useProfileView();
	const { isAuthor } = useUser();
	const { updateLastAutoSave, updateSubObjectName } = useHeader();
	const handleError = useErrorHandler();

	const repository = useMemo(() => new GlobalDocumentsRepository(), []);
	const [viewModel] = useState(
		new GlobalDocumentViewModel(documentId, repository, updateLastAutoSave),
	);

	useEffect(() => {
		updateSubObjectName('');
		viewModel
			.load()
			.catch((error) => handleError(error))
			.finally(() => {
				if (viewModel.document?.getName()) {
					updateSubObjectName(viewModel.document?.getName() || '');
				}
			});
	}, [handleError, viewModel]);

	return (
		<Container sx={{ paddingX: '2rem', paddingY: '1.5rem' }}>
			<DocumentView
				isReadonly={viewType !== FieldViews.edit || !isAuthor}
				viewModel={viewModel}
			/>
		</Container>
	);
});
