import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { ICollectionApiData, IEntityFieldApiData } from '../entitiesApiTypes';
import { EntityRepository } from '../entityRepository';
import { Entity } from './entity';
import { EntityBase } from './entityBase';
import { CollectionField } from './fields/collectionField';

export class EntityCollection implements EntityBase {
	private _data: ICollectionApiData;
	private _fields: CollectionField[];
	private _entity: Entity;

	private _entityRepository: EntityRepository;

	constructor(
		entity: Entity,
		data: ICollectionApiData,
		entityRepository?: EntityRepository
	) {
		makeAutoObservable(this);
		this._data = data;
		this._entity = entity;

		this._entityRepository =
			entityRepository ?? new EntityRepository(entity.entityType, entity.id);

		this._fields = data.fields.map(
			(x) => new CollectionField(x, this, undefined, this._entityRepository)
		);
	}

	get id(): string {
		return this._data.id || '';
	}

	get identifier(): string {
		return this._data.identifier;
	}

	public get parentId(): string | undefined {
		return this._data.parentId;
	}

	get entity() {
		return this._entity;
	}

	get createdDate() {
		return moment(this._data.createdDate);
	}

	getField(identifier: string) {
		return this._fields.find((x) => x.identifier === identifier);
	}

	addField(field: CollectionField) {
		this._fields.push(field);
	}

	addCollectionField(field: IEntityFieldApiData) {
		this.addField(
			new CollectionField(field, this, true, this._entityRepository)
		);
	}

	public async v2AddField(
		fieldIdentifier: string,
		value?: string | null,
		phoneNumberNumber?: string | null,
		extension?: string | null
	) {
		if (phoneNumberNumber !== undefined) {
			const field = await this._entityRepository.v2CreatePhoneNumberCollectionField(
				this._data.id!,
				fieldIdentifier,
				value ?? null,
				phoneNumberNumber ?? null,
				extension ?? null
			);
			this.addField(
				new CollectionField(field, this, true, this._entityRepository)
			);
		} else {
			const field = await this._entityRepository.v2CreateCollectionField(
				this._data.id!,
				fieldIdentifier,
				value ?? null
			);
			this.addField(
				new CollectionField(field, this, true, this._entityRepository)
			);
		}
	}
}
