import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import {
	T4Checkbox,
	T4CheckboxProps,
} from '../shared/components/atoms/t4Checkbox';

export type BooleanFieldProps = Pick<
	T4CheckboxProps,
	'id' | 'label' | 'required' | 'checked'
> & {
	onChange: (value: boolean) => void;
};

export const BooleanField: FC<BooleanFieldProps> = observer(
	({ id, label, checked, required, onChange }) => (
		<T4Checkbox
			id={id}
			label={label}
			required={required}
			checked={checked ?? false}
			onChange={(_, value) => onChange(value ?? false)}
		/>
	),
);
