import { Grid, ListItem, Typography } from '@mui/material';
import { FC } from 'react';

export interface AutocompleteOptionWithDescriptionProps {
	label: string;
	description: string;
}
export const AutoCompleteOptionWithDescription: FC<
	AutocompleteOptionWithDescriptionProps
> = ({ label, description, ...props }) => (
	<ListItem {...props}>
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="h6">{label}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="caption">{description}</Typography>
			</Grid>
		</Grid>
	</ListItem>
);
