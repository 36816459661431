import { Add } from '@mui/icons-material';
import {
	Alert,
	Box,
	CircularProgress,
	Collapse,
	DialogActions,
	Grid,
	Typography,
} from '@mui/material';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { UpdateField } from 'modules/clients/customer-api/src/api/objects';
import { FC, useCallback, useMemo, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { flattenErrors } from 'utilities/errors/errorUtils';
import {
	Field,
	FieldGroup,
	FieldGroup as NewFieldGroup,
	Tab as NewTab,
} from '../../../../modules/clients/customer-api/src/api/common';
import { T4Button } from '../../shared/components/atoms/t4Button';
import { T4Modal } from '../../shared/components/atoms/t4Modal';
import { IFieldUpdate } from '../entitiesApiTypes';
import { useCollectionView } from '../object/hooks/useCollectionView';
import { Collection } from '../object/models/collection';
import { T4Object as ObjectData } from '../object/models/t4Object';
import { getObjectInputType } from '../object/utilities';
import { Entity } from '../objects/entity';
import { EntityCollection } from '../objects/entityCollection';
import { BooleanFieldFormView } from './booleanFieldFormView';
import { CollectionItem } from './collectionItem';
import { DateFieldFormView } from './dateFIeldFormView';
import { HeaderRef } from './entityFieldRenderer';
import { FormFieldViewModel } from './models/formFieldViewModel';
import { OptionFieldFormView } from './optionFieldFormView';
import { ReferenceFieldFormView } from './referenceFieldFormView';
import { StringFieldFormView } from './stringFieldFormView';

export class CollectionViewModel {
	public collection: FieldGroup;
	public entity: Entity;
	public parentEntityCollection?: EntityCollection;
	public formFields: FormFieldViewModel<any>[] = [];
	public formError?: string;
	public isSaving: boolean = false;

	constructor(
		fieldGroup: FieldGroup,
		entity: Entity,
		entityCollection?: EntityCollection,
	) {
		makeAutoObservable(this, {
			formFields: false,
		});

		this.collection = fieldGroup;
		this.entity = entity;
		this.parentEntityCollection = entityCollection;
	}

	public addFormField(formField: FormFieldViewModel<any>) {
		const position = this.formFields.findIndex(
			(formFieldViewModel) =>
				formFieldViewModel.field.id === formField.field.id,
		);
		if (position !== -1) {
			this.formFields.splice(position, 1, formField);
		} else {
			this.formFields.push(formField);
		}
	}

	public clearForm() {
		this.formFields.forEach((formField) => formField.clearValue());
		this.formError = undefined;
	}

	public hasRequiredFields() {
		return this.formFields.some(
			(formField) => formField.field.isCreationRequirement,
		);
	}

	public filledRequiredFields() {
		return this.formFields
			.filter((formField) => formField.field.isCreationRequirement)
			.every((formField) => formField.value !== undefined);
	}

	public async save() {
		this.isSaving = true;

		if (this.filledRequiredFields()) {
			const fieldUpdates: IFieldUpdate[] = this.formFields
				.filter((formField) => formField.value !== undefined)
				.map((formField) => ({
					fieldIdentifier: formField.field.identifier,
					value: formField.value,
				}));
			if (this.parentEntityCollection) {
				await this.entity?.createCollection(
					this.collection.identifier,
					fieldUpdates,
					this.parentEntityCollection.id,
				);
			} else {
				await this.entity?.createCollection(
					this.collection.identifier,
					fieldUpdates,
				);
			}

			this.isSaving = false;

			return true;
		}

		this.formError = this.saveError;
		this.isSaving = false;

		return false;
	}

	public get saveError() {
		const unfilledFormFields = this.formFields.filter(
			(formField) =>
				formField.field.isCreationRequirement && formField.value === undefined,
		);
		return (
			'Please fill out the required fields:' +
			unfilledFormFields
				.map((formField, index) => {
					if (index === unfilledFormFields.length - 1) {
						return ' ' + formField.field.name + '.';
					} else {
						return ' ' + formField.field.name + ',';
					}
				})
				.join('')
		);
	}
}

export type CollectionViewProps = {
	object: ObjectData;
	newTab: NewTab;
	newFieldGroup: NewFieldGroup;
	parentCollection: Collection | undefined;
	v2Refs: HeaderRef[];
};

export const CollectionView: FC<CollectionViewProps> = observer(
	({ object, newTab, newFieldGroup, parentCollection, v2Refs = [] }) => {
		const { isReadOnly } = useCollectionView();
		const { customerApiClient } = useClients();

		const [isCreating, setIsCreating] = useState<boolean>(false);
		const [error, setError] = useState<string | undefined>();
		const [createModalOpen, setCreateModalOpen] = useState(false);
		const [newCollectionFields, setNewCollectionFields] = useState<
			UpdateField[]
		>([]);

		const newCollectionSaveable = useMemo(
			() =>
				newFieldGroup.fields
					.filter((x) => x.isCreationRequirement)
					.every((x) =>
						newCollectionFields.some((y) => y.fieldIdentifier === x.identifier),
					),
			[newFieldGroup.fields, newCollectionFields],
		);

		const setField = useCallback(
			(field: Field, value: any) => {
				if (value) {
					let collectionFields = newCollectionFields.slice();

					let newValue =
						field.fieldType === 'Option' ? value?.id ?? undefined : value;

					if (
						newCollectionFields.find(
							(x) => x.fieldIdentifier === field.identifier,
						)
					) {
						const index = collectionFields.findIndex(
							(x) => x.fieldIdentifier === field.identifier,
						);
						collectionFields[index].value = newValue;
					} else {
						collectionFields.push({
							fieldIdentifier: field.identifier,
							value: newValue,
						});
					}

					setNewCollectionFields(collectionFields);
				}
			},
			[newCollectionFields],
		);
		const createCollection = useCallback(async () => {
			if (object) {
				try {
					setIsCreating(true);
					setError(undefined);

					const response =
						await customerApiClient.api.objects.collections.create({
							objectId: object.id,
							objectType: getObjectInputType(object.objectType),
							collectionIdentifier: newFieldGroup.identifier,
							fieldUpdates: newCollectionFields,
							phoneNumberFieldUpdates: [],
							parentCollectionId: parentCollection?.id ?? null,
						});

					if (response && response.data && response.data.value) {
						object.collections.push(new Collection(response.data.value));
					}

					if (response && response.data && response.data.error) {
						setError(response.data.error);
					}
					if (
						response &&
						response.data &&
						response.data.errors &&
						(Object.values(response.data.errors)?.length ?? 0) > 0
					) {
						setError(
							error?.concat(flattenErrors(response.data.errors).join(', ')),
						);
					}
				} catch (error) {
					setError('Unable to create collection.');
				} finally {
					setIsCreating(false);
				}
			}
		}, [
			customerApiClient.api.objects.collections,
			error,
			newCollectionFields,
			newFieldGroup.identifier,
			object,
			parentCollection?.id,
		]);

		const renderFormFields = (newFieldGroup: NewFieldGroup) =>
			[
				...newFieldGroup.fields
					.filter((x) => x.fieldType === 'Text' && !x.referenceCollectionName)
					.filter((field) => field.isCreationRequirement)
					.map((field) => {
						return [
							field.sortOrder,
							<StringFieldFormView
								key={field.id.concat(field.identifier)}
								field={field}
								value={
									newCollectionFields.find(
										(x) => x.fieldIdentifier === field.identifier,
									)?.value
								}
								setValue={setField}
							/>,
						];
					}),
				...newFieldGroup.fields
					.filter((x) => x.fieldType === 'Boolean')
					.filter((field) => field.isCreationRequirement)
					.map((field) => {
						return [
							field.sortOrder,
							<BooleanFieldFormView
								key={field.id.concat(field.identifier)}
								field={field}
								value={
									newCollectionFields.find(
										(x) => x.fieldIdentifier === field.identifier,
									)?.value
								}
								setValue={setField}
							/>,
						];
					}),
				...newFieldGroup.fields
					.filter((x) => x.fieldType === 'Date')
					.filter((field) => field.isCreationRequirement)
					.map((field) => {
						return [
							field.sortOrder,
							<DateFieldFormView
								key={field.id.concat(field.identifier)}
								field={field}
								value={
									newCollectionFields.find(
										(x) => x.fieldIdentifier === field.identifier,
									)?.value
								}
								setValue={setField}
							/>,
						];
					}),
				...newFieldGroup.fields
					.filter((x) => x.fieldType === 'Option')
					.filter((field) => field.isCreationRequirement)
					.map((field) => {
						return [
							field.sortOrder,
							<OptionFieldFormView
								key={field.id.concat(field.identifier)}
								field={field}
								value={
									newCollectionFields.find(
										(x) => x.fieldIdentifier === field.identifier,
									)?.value
								}
								setValue={setField}
							/>,
						];
					}),
				...newFieldGroup.fields
					.filter((x) => x.fieldType === 'Text' && x.referenceCollectionName)
					.filter((field) => field.isCreationRequirement)
					.map((field) => {
						return [
							field.sortOrder,
							<ReferenceFieldFormView
								key={field.id.concat(field.identifier)}
								field={field}
								value={
									newCollectionFields.find(
										(x) => x.fieldIdentifier === field.identifier,
									)?.value
								}
								setValue={setField}
							/>,
						];
					}),
			].filter((x) => x[1] !== null);

		const collections = useMemo(
			() =>
				object?.collections.filter(
					(x) =>
						x.identifier === newFieldGroup.identifier &&
						x.parentCollectionId === parentCollection?.id,
				),

			// please leave this so we can calculate the correct values
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[
				newFieldGroup.identifier,
				object?.collections,
				parentCollection?.id,
				object?.collections?.length,
			],
		);

		return (
			<Box key={`collection-view-${newFieldGroup.id}`}>
				<Grid
					container
					columnSpacing={1}
					sx={{
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<Grid item>
						<Typography variant="h3">{newFieldGroup.name}</Typography>
					</Grid>
					{!isReadOnly && (
						<Grid
							item
							sx={{
								padding: '0.25rem',
							}}
						>
							<T4Button
								id={`collection-add-${newFieldGroup.id}`}
								variant="outlined"
								startIcon={<Add />}
								size="small"
								onClick={() => setCreateModalOpen(true)}
							>
								{`Add ${newFieldGroup.singularName}`}
							</T4Button>
							<T4Modal
								title={`Create ${newFieldGroup.singularName}`}
								open={createModalOpen}
								actions={
									<DialogActions
										sx={{
											paddingX: '1.5rem',
											paddingBottom: '1.5rem',
										}}
									>
										<Grid
											display="flex"
											justifyContent="space-between"
											width="100%"
										>
											{isCreating ? (
												<Box display="flex" alignItems="center">
													<Box marginRight={2}>
														<Typography noWrap={true}>Adding...</Typography>
													</Box>
													<CircularProgress size={25} />
												</Box>
											) : null}
											<Grid container spacing={1} justifyContent="flex-end">
												<Grid item>
													<T4Button
														id={`collection-creation-cancel-${newFieldGroup.id}`}
														color="secondary"
														onClick={() => setCreateModalOpen(false)}
													>
														Cancel
													</T4Button>
												</Grid>
												<Grid item>
													<T4Button
														id={`collection-creation-save-${newFieldGroup.id}`}
														variant="contained"
														disabled={isCreating || !newCollectionSaveable}
														onClick={() =>
															createCollection().then(() => {
																setCreateModalOpen(false);
																setNewCollectionFields([]);
															})
														}
													>
														Add
													</T4Button>
												</Grid>
											</Grid>
										</Grid>
									</DialogActions>
								}
								sx={{
									'& .MuiDialog-paper': {
										width: '100%',
									},
								}}
							>
								<Grid container rowSpacing={1}>
									<Grid item xs={12}>
										<Collapse in={Boolean(error)}>
											<Alert severity="error" variant="standard">
												{error}
											</Alert>
										</Collapse>
									</Grid>
									{[...renderFormFields(newFieldGroup)]
										.filter((x) => x[1] !== null)
										.sort((a, b) => (a[0] as number) - (b[0] as number))
										.map((x) => (
											<Grid key={x[0].toString()} item xs={12}>
												{x[1]}
											</Grid>
										))}
								</Grid>
							</T4Modal>
						</Grid>
					)}
				</Grid>
				{collections?.map((collection) => (
					<CollectionItem
						key={collection.id.concat(collection.identifier)}
						object={object}
						newTab={newTab}
						newFieldGroup={newFieldGroup}
						collection={collection}
						v2Refs={v2Refs}
					/>
				))}
			</Box>
		);
	},
);
