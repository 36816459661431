import { makeAutoObservable } from 'mobx';
import { Collection as CollectionData } from 'modules/clients/customer-api/src/api/objects';
import { ObjectField } from './objectField';

export class Collection {
	public id: string;
	public identifier: string;
	public parentCollectionId?: string;
	public createdDate: string;
	public fields: ObjectField[];

	constructor(collectionData: CollectionData) {
		makeAutoObservable(this);

		if (!collectionData) {
			throw new Error('Collection data is required.');
		}

		this.id = collectionData.id;
		this.identifier = collectionData.identifier;
		this.parentCollectionId = collectionData.parentId ?? undefined;
		this.createdDate = collectionData.createdDate;
		this.fields = collectionData.fields?.map((x) => new ObjectField(x)) ?? [];
	}
}
