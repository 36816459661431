import { Grid } from '@mui/material';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { useSigma } from 'features/shared/sigma/providers/sigmaProvider';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useEditFolderDetailsButtonProps } from 'shared/hooks/useEditFolderDetailsButton';

export type EditFolderDetailProps = {
	open: boolean;
	close: () => void;
};

export const EditFolderDetailsButton: FC<EditFolderDetailProps> = observer(
	({ open, close }) => {
		const { folder } = useSigma();
		const { isLoading, error, name, setName, resetForm, onSubmit } =
			useEditFolderDetailsButtonProps();

		const handleClose = useCallback(() => {
			resetForm();
			close();
		}, [resetForm, close]);

		const handleSubmit = useCallback(() => {
			onSubmit(handleClose);
		}, [handleClose, onSubmit]);

		return (
			<FormModal
				open={open}
				onClose={() => handleClose()}
				onSubmit={() => handleSubmit()}
				fullWidth
				title={'Edit Folder Details: ' + folder?.name}
				loading={isLoading}
				submitButtonLabel="Save"
				submitDisabled={name === '' || name === undefined || isLoading}
				loadingMessage="Saving"
			>
				<Grid container spacing={2} direction="column">
					<Grid item>
						<T4TextFieldV2
							id="folder-name"
							label="Name"
							placeholder={folder?.name}
							value={name}
							onChange={setName}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<T4AlertStack error={error} />
				</Grid>
			</FormModal>
		);
	},
);
