import { flow, makeAutoObservable } from 'mobx';
import { parseError } from 'utilities/errors/errorUtils';
import { FrontendGroupRepository, GroupMemberDto } from '../groupRepository';

export class GroupMembersListModel {
	private _groupId: string;
	private _frontendGroupRepository: FrontendGroupRepository;

	private _groupMembers: GroupMemberDto[] = [];

	private _loading: boolean;
	private _error: string;

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
	) {
		makeAutoObservable(this);
		this._groupId = groupId;
		this._frontendGroupRepository = frontendGroupRepository;

		this._loading = false;
		this._error = '';
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get groupMembers() {
		return this._groupMembers;
	}

	public load = flow(function* (this: GroupMembersListModel) {
		try {
			this._error = '';
			this._loading = true;

			var response = yield this._frontendGroupRepository.getGroupMembers(
				this._groupId,
			);
			this._groupMembers = response.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}
