import { action, computed, makeObservable, observable } from 'mobx';
import moment, { Moment } from 'moment';
import { Option, ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { formatDate } from 'shared/utilities/dateUtilities';
import { FieldDataType } from '../../../shared/fieldSets/fieldTypes';
import { IEntityFieldApiData } from '../../entitiesApiTypes';

export class EntityFieldBase {
	public loading: boolean = false;
	public loadingSuccess: boolean = false;
	public loadingErrorMessage: string = '';

	public data: IEntityFieldApiData;

	constructor(data: IEntityFieldApiData, created?: boolean) {
		this.data = data;
		this.loadingSuccess = created ?? false;
		makeObservable(this, {
			id: true,
			loadingState: true,
			identifier: true,
			dateApproved: true,
			dateSubmitted: true,
			approvedValue: true,
			pendingValue: true,
			pendingOptionSourceId: true,
			approvedOptionSourceId: true,
			isPendingApproval: true,
			initialValue: true,
			pendingOptionValue: true,
			approvedOptionValue: true,
			pendingReferenceValue: true,
			approvedReferenceValue: true,
			initialReferenceValue: true,
			isRejected: true,
			rejectionComment: true,
			approvedPhoneNumber: true,
			pendingPhoneNumber: true,
			getData: true,
			savePhoneNumber: true,
			setError: true,
			updatedBy: true,
			loading: observable,
			loadingSuccess: observable,
			loadingErrorMessage: observable,
			updateData: action,
			save: action,
			approve: action,
			unSubmit: action,
			reject: action,
			data: observable,
			isSubmitted: computed,
			initialDateValue: computed,
			displayValue: computed,
		});
	}

	public get id() {
		return this.data.id;
	}

	public get loadingState() {
		if (this.loadingErrorMessage) {
			return 'info';
		}
		return this.loading ? 'loading' : this.loadingSuccess ? 'success' : 'info';
	}

	public get displayValue() {
		return this.data.displayValue;
	}

	public get identifier(): string {
		return this.data.identifier;
	}

	public get dateApproved(): Moment | undefined {
		if (!this.data.approvedDate) return undefined;
		return moment(this.data.approvedDate);
	}

	public get dateSubmitted(): Moment | undefined {
		if (!this.data.submittedDate) return undefined;
		return moment(this.data.submittedDate);
	}

	public get approvedValue(): string | undefined {
		if (this.data.fieldType === FieldDataType.date) {
			return this.data.approvedValue
				? formatDate(moment(this.data.approvedValue))
				: undefined;
		}

		try {
			return (
				(JSON.parse(this.data.approvedValue) as ReferenceDataValue)
					.displayName ?? this.data.approvedValue
			);
		} catch (error) {
			return this.data.approvedValue;
		}
	}

	public get pendingValue(): string | undefined {
		if (this.data.fieldType === FieldDataType.date) {
			return this.data.pendingValue
				? formatDate(moment(this.data.pendingValue))
				: undefined;
		}

		try {
			const pendingValue = JSON.parse(
				this.data.pendingValue,
			) as ReferenceDataValue;

			return pendingValue.displayName ?? this.data.pendingValue;
		} catch (error) {
			return this.data.pendingValue;
		}
	}

	public get pendingOptionSourceId() {
		return this.data.pendingOptionSourceId;
	}

	public get approvedOptionSourceId() {
		return this.data.approvedOptionSourceId;
	}

	public get isPendingApproval(): boolean {
		return this.isSubmitted && !this.isRejected;
	}

	public get initialValue(): string | undefined {
		if (this.data.pendingValue === null || this.data.pendingValue === undefined)
			return this.data.approvedValue;
		return this.data.pendingValue;
	}

	public get pendingOptionValue(): Option | null {
		if (!this.data.pendingValue || !this.data.displayValue) return null;

		return {
			id: this.data.pendingValue,
			displayName: this.data.displayValue,
			code: '',
		};
	}

	public get approvedOptionValue(): Option | null {
		if (!this.data.approvedValue || !this.data.displayValue) return null;

		return {
			id: this.data.approvedValue,
			displayName: this.data.displayValue,
			code: '',
		};
	}

	public get pendingReferenceValue(): ReferenceDataValue | null {
		let value = this.data.pendingValue;
		if (!value) return null;

		try {
			return JSON.parse(value) as ReferenceDataValue;
		} catch (error) {
			return {
				value: value,
				displayName: value,
				identifier: value,
			};
		}
	}

	public get approvedReferenceValue(): ReferenceDataValue | null {
		let value = this.data.approvedValue;
		if (!value) return null;

		try {
			return JSON.parse(value) as ReferenceDataValue;
		} catch (error) {
			return {
				value: value,
				displayName: value,
				identifier: value,
			};
		}
	}

	public get initialReferenceValue(): ReferenceDataValue | null {
		let value = this.initialValue;
		if (!value) return null;

		try {
			return JSON.parse(value) as ReferenceDataValue;
		} catch (error) {
			return {
				value: value,
				displayName: value,
				identifier: value,
			};
		}
	}

	public get initialDateValue(): Moment | null {
		if (this.data.fieldType !== FieldDataType.date) {
			return null;
		}

		const value = this.data.pendingValue || this.data.approvedValue;

		return value ? moment(value) : null;
	}

	public get isSubmitted(): boolean {
		return Boolean(this.data.submittedDate) && !this.isRejected;
	}

	public get isRejected(): boolean {
		if (!this.pendingValue) {
			return false;
		}

		return Boolean(this.data.rejectedDate);
	}

	public get rejectionComment(): string | undefined {
		return this.data.rejectedComment;
	}

	public get approvedPhoneNumber() {
		return this.data.approvedPhoneNumber;
	}

	public get pendingPhoneNumber() {
		return this.data.pendingPhoneNumber;
	}

	public getData() {
		return this.data;
	}

	public updateData(data: IEntityFieldApiData) {
		this.data = data;
	}

	public approve() {
		throw new Error('not implemented');
	}

	public unSubmit() {
		throw new Error('not implemented');
	}

	public save(value: any): Promise<void> {
		throw new Error('not implemented');
	}

	public savePhoneNumber(
		_countryCode: string | null,
		_number: string | null,
		_extension: string | null,
	): Promise<void> {
		throw new Error('not implemented');
	}

	public reject(comment: string): Promise<void> {
		throw new Error('not implemented');
	}

	public setError(error: string) {
		this.loadingErrorMessage = error;
	}

	public updatedBy(): string | undefined {
		return this.data.updatedBy;
	}
}
