import { AxiosInstance } from 'axios';
import { Read, Write } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Field, ObjectInput } from '..';

//#region Request Models

export type FieldInput = ObjectInput & {
	fieldId: string;
};

export type RejectField = {
	comment: string;
};

export type ApproveObjectFieldInput = ObjectInput & {
	fieldId: string;
};

export type UpdateField = {
	fieldIdentifier: string;
	value: any;
};

export type PhoneNumberCreateField = {
	identifier: string;
	countryCode: string | null;
	number: string | null;
	extension: string | null;
};

export type PhoneNumberUpdateField = {
	countryCode: string | null;
	number: string | null;
	extension: string | null;
};

export type RejectObjectFieldInput = ObjectInput &
	RejectField & {
		fieldId: string;
	};

export type UpdateFieldInput = ObjectInput & UpdateField;

export type CreatePhoneNumberFieldInput = ObjectInput & PhoneNumberCreateField;

export type UpdatePhoneNumberFieldInput = ObjectInput &
	PhoneNumberUpdateField & {
		fieldId: string;
	};

//#endregion

export type ObjectFieldEndpoints = {
	get: Read<ApiResponse<Field>, FieldInput>;
	update: Write<ApiResponse<Field>, UpdateFieldInput, UpdateField>;
	phoneNumberCreate: Write<
		ApiResponse<Field>,
		CreatePhoneNumberFieldInput,
		PhoneNumberCreateField
	>;
	phoneNumberUpdate: Write<
		ApiResponse<Field>,
		UpdatePhoneNumberFieldInput,
		PhoneNumberUpdateField
	>;
	approve: Write<ApiResponse<Field>, ApproveObjectFieldInput>;
	reject: Write<ApiResponse<Field>, RejectObjectFieldInput, RejectField>;
};

export function objectFields(
	axiosInstance: AxiosInstance,
): ObjectFieldEndpoints {
	return {
		get: async (input, config = {}) =>
			await axiosInstance.get(
				`/${input!.objectType}/${input!.objectId}/fields/${input!.fieldId}`,
				{ ...config },
			),
		update: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}`,
				{
					fieldIdentifier: input.fieldIdentifier,
					value: input.value,
				},
				{
					...config,
				},
			),
		phoneNumberCreate: async (input, config = {}) =>
			await axiosInstance.post(
				`/${input!.objectType}/${input!.objectId}/fields/phonenumber`,
				{
					identifier: input.identifier,
					countryCode: input.countryCode ?? null,
					number: input.number ?? null,
					extension: input.extension ?? null,
				},
				{
					...config,
				},
			),
		phoneNumberUpdate: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/fields/${
					input!.fieldId
				}/phonenumber`,
				{
					countryCode: input.countryCode ?? null,
					number: input.number ?? null,
					extension: input.extension ?? null,
				},
				{
					...config,
				},
			),
		approve: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/fields/${
					input!.fieldId
				}/approve`,
				undefined,
				{
					...config,
				},
			),
		reject: async (input, config = {}) =>
			await axiosInstance.patch(
				`/${input!.objectType}/${input!.objectId}/fields/${
					input!.fieldId
				}/reject`,
				{
					comment: input.comment ?? null,
				},
				{
					...config,
				},
			),
	};
}
