import { Grid } from '@mui/material';
import React from 'react';
import RuleCategories from './RuleCategories';
import RuleConditions from './RuleConditions';
import RuleName from './RuleName';

export interface RuleFormProps {
	isEditing: boolean;
}

const RuleForm: React.FC<RuleFormProps> = ({ isEditing }) => {
	return (
		<Grid container rowSpacing={2}>
			<RuleName setPriorityToLast={!isEditing} />
			<RuleConditions />
			<RuleCategories />
		</Grid>
	);
};

export default RuleForm;
