import { Autocomplete, TextField } from '@mui/material';
import {
	GridFilterInputValueProps,
	GridSingleSelectColDef,
} from '@mui/x-data-grid-pro';
import { SyntheticEvent } from 'react';

export const SingleSelectFilterInput = (props: GridFilterInputValueProps) => {
	const { item, applyValue, apiRef } = props;
	const colDef = apiRef.current.getColumn(item.field) as GridSingleSelectColDef;
	const options = colDef.valueOptions as string[];

	const handleFilterChange = (_: SyntheticEvent, newValue: string | null) => {
		applyValue({ ...item, value: newValue });
	};

	return (
		<Autocomplete<string>
			autoComplete
			value={item.value ?? null}
			options={options}
			onChange={handleFilterChange}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Value"
					variant="standard"
					InputLabelProps={{ shrink: true }}
				/>
			)}
		/>
	);
};

export const MultiSelectFilterInput = (props: GridFilterInputValueProps) => {
	const { item, applyValue, apiRef } = props;
	const colDef = apiRef.current.getColumn(item.field) as GridSingleSelectColDef;
	const options = colDef.valueOptions as string[];

	const handleFilterChange = (_: SyntheticEvent, values: string[]) => {
		applyValue({ ...item, value: values });
	};

	// if the item.value is undefined then default to empty array
	// ensure value is always an array because MUI Typing for item.value of 'isAnyOf' operator can be: undefined | string | string[]
	let value: string[] = item.value ?? [];
	if (!Array.isArray(value)) value = [value];

	return (
		<Autocomplete<string, true, true, false>
			multiple
			autoComplete
			value={value}
			options={options}
			onChange={handleFilterChange}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Value"
					variant="standard"
					InputLabelProps={{ shrink: true }}
				/>
			)}
		/>
	);
};
