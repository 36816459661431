import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';

//#region Model(s)

export type Connector = {
	id: string;
	code: string;
	name: string;
	type: 'Api' | 'Sftp';
	triggerDateOffset: number;
	defaultCronExpression: string;
	defaultTriggerTimeZone: string;
	bankCode: string;
	bankName: string;
	parameters: ConnectorParameter[];
};

export type ConnectorParameter = {
	id: string;
	key: string;
	name: string;
	type: 'Value' | 'Secret';
	order: number;
	isValueOptional: boolean;
};

//#endregion

export type ConnectorsEndpoints = {
	connectors: Read<Connector[], undefined, ConnectorsResponse>;
};

export type ConnectorsResponse = T4Response<T4DataResponse<Connector[]>>;

export function connectors(axiosInstance: AxiosInstance): ConnectorsEndpoints {
	return {
		connectors: async (_, config = {}): Promise<ConnectorsResponse> => {
			return await axiosInstance.get('api/v2.0/cash4/connectors', {
				...config,
			});
		},
	};
}
