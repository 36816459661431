import { createContext } from 'react';

export interface ConfigurationsContextProps {
	auth0Client: string;
	auth0Token: string;
	auth0Domain: string;
	auth0Audience: string;
	featureFlagKey: string;
	customerApiUrl: string;
	apiGateway: {
		baseUrl: string;
		apiSubscriptionKey: string;
	};
	buildNumber: string;
	appInsights : {
		correlationDomains: string[];
		connectionString: string;
		key: string;
	}
}

export const ConfigurationsContext = createContext<ConfigurationsContextProps>({
	auth0Client: '',
	auth0Token: '',
	auth0Domain: '',
	auth0Audience: '',
	featureFlagKey: '',
	customerApiUrl: '',
	apiGateway: {
		baseUrl: '',
		apiSubscriptionKey: '',
	},
	buildNumber: '',
	appInsights : {
		correlationDomains: [],
		connectionString: '',
		key: ''
	}
});
