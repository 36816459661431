import { useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { parseErrors } from 'utilities/errors/errorUtils';

export type UseRemoveAccountIntegrationProps = {
	isLoading: boolean;
	isOpen: boolean;
	error: string | undefined;
	remove: (connectionId: string, accountIntegrationId: string) => void;
	cancel: () => void;
	confirm: () => Promise<void>;
};

export function useRemoveAccountIntegration(
	refetchConnections: () => Promise<void>,
) {
	const { customerApiClient } = useClients();

	const [isOpen, _setIsOpen] = useState(false);
	const [isLoading, _setIsLoading] = useState(false);
	const [error, _setError] = useState<string | undefined>();
	const [connectionId, setConnectionId] = useState<string | undefined>();
	const [accountIntegrationId, setAccountIntegrationId] = useState<
		string | undefined
	>();

	function _reset() {
		_setIsOpen(false);
		setConnectionId(undefined);
		setAccountIntegrationId(undefined);
	}

	return {
		isLoading,
		isOpen,
		error,
		remove: (connectionId: string, accountIntegrationId: string) => {
			if (connectionId && accountIntegrationId) {
				setConnectionId(connectionId);
				setAccountIntegrationId(accountIntegrationId);
				_setIsOpen(true);
			}
		},
		cancel: () => _reset(),
		confirm: async () => {
			if (connectionId && accountIntegrationId) {
				try {
					_setIsLoading(true);
					_setError(undefined);

					await customerApiClient.cash4.connections.deleteAccountIntegration({
						connectionId: connectionId,
						accountIntegrationId: accountIntegrationId,
					});

					_reset();
					refetchConnections();
				} catch (error) {
					parseErrors(error);
				} finally {
					_setIsLoading(false);
				}
			}
		},
	};
}
