import { ObjectRegistrationPage } from 'features/entity4/entities/object/registrations/pages/objectRegistrationPage';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { ObjectRegistrationsPage } from './pages/objectRegistrationsPage';

export const ObjectRegistrationsRoutes: FC = observer(() => {
	return (
		<Switch>
			<Route exact path={paths.entity4.objects.object.registrations.href}>
				<ObjectRegistrationsPage />
			</Route>
			<Route
				exact
				path={paths.entity4.objects.object.registrations.registration.href.concat(
					validIdRegex,
				)}
			>
				<ObjectRegistrationPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
});
