import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from '../../../../utilities/api';
import { EntityTypeId } from '../../entity4Constants';
import { FieldDataType, IFieldSetData } from './fieldTypes';

export interface TabDto {
	id: string;
	name: string;
	sortOrder: number;
}

export interface TabDataDto {
	tabId: string;
	fieldGroups: FieldGroupDto[];
}

export interface FieldGroupDto {
	id: string;
	identifier: string;
	name: string;
	singularName: string;
	isCollection: boolean;
	sortOrder: number;
	childFieldGroups: FieldGroupDto[];
	fields: FieldDto[];
}

export interface FieldDto {
	id: string;
	identifier: string;
	name: string;
	singularName: string;
	fieldType: FieldDataType;
	description?: string;
	parentFieldIdentifier?: string;
	requiresApproval: boolean;
	isCreationRequirement: boolean;
	isRecommendedCreationField?: boolean;
	requiresUniqueValue: boolean;
	isDefaultApprovalListColumn: boolean;
	isDefaultListColumn: boolean;
	referenceCollectionName?: string;
	optionList?: OptionListDto;
	validationCriterion?: ValidationCriterionDto;
	sortOrder: number;
	pendingPhoneNumber?: PhoneNumberDto;
	approvedPhoneNumber?: PhoneNumberDto;
}

export interface OptionListDto {
	id: string;
	name: string;
	options: OptionDto[];
}

export interface OptionDto {
	id: string;
	displayName: string;
	code: string;
	detail: string;
}

export interface ValidationCriterionDto {
	validationRegex: string;
	errorDescription: string;
}

export interface PhoneNumberDto {
	countryCode?: string;
	number?: string;
	extension?: string;
}

export class FieldRepository {
	public async getFields() {
		const response = await customerApi.get<ApiResponse<IFieldSetData[]>>(
			'/common/fields'
		);
		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async getTabs(entityType: EntityTypeId) {
		const response = await customerApi.get<ApiResponse<TabDto[]>>(
			'/common/tabs',
			{
				params: {
					entityType: entityType,
				},
			}
		);
		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async getAllTabData(entityType: EntityTypeId) {
		const response = await customerApi.get<ApiResponse<TabDataDto[]>>(
			'/common/tabs/data',
			{
				params: {
					entityType: entityType,
				},
			}
		);

		// eslint-disable-next-line no-throw-literal
		if (response.data == null) throw 'Could not find tab data.';
		if (response.data.success) return response.data.value;

		throw response.data.error;
	}

	public async getTabData(tabId: string) {
		const response = await customerApi.get<ApiResponse<TabDataDto | null>>(
			`/common/tabs/${tabId}`
		);
		// eslint-disable-next-line no-throw-literal
		if (response.data == null) throw 'Could not find tab data.';
		if (response.data.success) return response.data.value;

		throw response.data.error;
	}
}
