import {
	Cash4Configurations,
	UpdateCash4ConfigurationsReqeust,
} from 'modules/clients/customer-api/src/api/administration';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseCash4ConfigurationsProps = {
	loading: boolean;
	configurations: Cash4Configurations | undefined;
	update: (input: UpdateCash4ConfigurationsReqeust) => Promise<void>;
};

export function useCash4Configurations(): UseCash4ConfigurationsProps {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();

	const [loading, setLoading] = useState<boolean>(true);
	const [configurations, setConfigurations] = useState<Cash4Configurations>();

	const update = useCallback(
		async (input: UpdateCash4ConfigurationsReqeust) => {
			try {
				const response =
					await await customerApiClient.api.administration.updateCash4Configurations(
						input,
					);

				if (response && response.data && response.data.data) {
					setConfigurations(response.data.data);
				}
			} catch {
				enqueueSnackbar('Failed to update cash4 configurations.', {
					variant: 'error',
				});
			}
		},
		[customerApiClient.api.administration, enqueueSnackbar],
	);

	const initialize = useCallback<() => Promise<void>>(async () => {
		try {
			setLoading(true);

			const response =
				await customerApiClient.api.administration.cash4Configurations();

			if (response && response.data && response.data.data) {
				setConfigurations(response.data.data);
			}
		} catch {
			enqueueSnackbar('Failed to load cash4 configurations.', {
				variant: 'error',
			});
		} finally {
			setLoading(false);
		}
	}, [customerApiClient.api.administration, enqueueSnackbar]);

	useEffect(() => {
		initialize();
	}, [initialize]);

	return {
		loading: loading,
		configurations: configurations,
		update: update,
	};
}

export interface ICurrency {
	code: string;
	name: string;
}
