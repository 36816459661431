import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { FC, useMemo } from 'react';
import { T4TextFieldV2 } from '../../shared/components/atoms/t4TextField';
import { T4AlertStack } from '../../shared/components/molecules/t4AlertStack';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';
import { getValidationWarningFromValue } from '../object/utilities';

export type StringFieldFormViewProps = {
	field: Field;
	value: string | undefined;
	setValue: (field: Field, value: string | undefined) => void;
};

export const StringFieldFormView: FC<StringFieldFormViewProps> = observer(
	({ field, value, setValue }) => {
		const warning = useMemo(
			() => getValidationWarningFromValue(field, value),

			[field, value],
		);

		return (
			<Box>
				<T4TextFieldV2
					id={field.id}
					label={field.name}
					required={field.isCreationRequirement}
					value={value ?? ''}
					onChange={(value) => setValue(field, value ?? undefined)}
					startAdornment={
						<T4FieldAdornment
							id={field.id}
							t4AdornmentType="info"
							title={field.name}
							description={field.description}
						/>
					}
				/>
				<T4AlertStack warning={warning} />
			</Box>
		);
	},
);
