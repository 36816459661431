/* eslint-disable mobx/missing-observer */
import {
	Box,
	CircularProgress,
	Switch,
	SwitchProps,
	Theme,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useMemo } from 'react';

interface T4ToggleProps extends SwitchProps {
	label?: string;
	loading?: boolean;
}

const useIconStyles = makeStyles((theme: Theme) => ({
	circle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 20,
		height: 20,
		borderRadius: '50%',
		backgroundColor: theme.palette.background.default,
		boxShadow: theme.shadows[1],
	},
	active: {
		backgroundColor: theme.palette.primary.main,
	},
	disabled: {
		backgroundColor: theme.palette.charcoal[50],
	},
}));

export const T4Toggle: FC<T4ToggleProps> = ({ label, loading, ...rest }) => {
	const classes = useIconStyles();

	const Icon = useMemo(
		() => (
			<div
				className={`${classes.circle} ${rest.checked ? classes.active : ''} ${
					rest.disabled ? classes.disabled : ''
				}`}
			>
				{loading && (
					<CircularProgress
						size={15}
						sx={(theme) => ({
							color: rest.checked
								? theme.palette.primary.contrastText
								: theme.palette.charcoal[500],
						})}
					/>
				)}
			</div>
		),
		[classes, loading, rest.checked, rest.disabled],
	);

	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Switch {...rest} checkedIcon={Icon} icon={Icon} />
			{label && <Typography sx={{ display: 'inline' }}>{label}</Typography>}
		</Box>
	);
};
