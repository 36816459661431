import { observer } from 'mobx-react-lite';
import { createContext, FC, useContext, useState } from 'react';

//#region Context

export type VisualizationContextProps = {
	zoom: number;
	setZoom: (zoom: number) => void;
};

export const VisualizationContext = createContext({
	zoom: 1,
	setZoom: (zoom: number) => {},
});

//#endregion

//#region Provider

export const VisualizationProvider: FC = observer(({ children }) => {
	const [zoom, setZoom] = useState(1);

	return (
		<VisualizationContext.Provider value={{ zoom, setZoom }}>
			{children}
		</VisualizationContext.Provider>
	);
});

//#endregion

//#region Hook

export function useVisualizationContext(): VisualizationContextProps {
	return useContext(VisualizationContext);
}

//#endregion
