import moment, { Moment } from 'moment';

export const getMomentFromString = (
	value: Moment | string | null | undefined
): Moment | null => {
	return value ? moment(value) : null;
};

export const isDateEqual = (
	date1: Moment | string | null | undefined,
	date2: Moment | string | null | undefined
): boolean => {
	const parsedDate1 = getMomentFromString(date1);
	const parsedDate2 = getMomentFromString(date2);

	return (
		parsedDate1 === parsedDate2 ||
		(parsedDate1 !== null && parsedDate1.isSame(parsedDate2))
	);
};

export const canSaveDate = (date: Moment | null, maxDate?: Moment) =>
	date === null ||
	(maxDate && date.isValid() && !date.isAfter(maxDate)) ||
	(!maxDate && date.isValid());

export const getDateWarningMessage = (
	label: string,
	date: Moment | null,
	maxDate?: Moment
) => {
	if (date && !date.isValid()) {
		return `Selected ${label} is not valid.`;
	}

	if (date && maxDate && date.isAfter(maxDate)) {
		return `Selected ${label} is after the maximum date.`;
	}
};
