import {
	GraphObject,
	GridLayout,
	Group,
	Margin,
	Panel,
	Placeholder,
	Shape,
	Spot,
	TextBlock,
} from 'gojs';
import { textColor } from './constants';

const legendFond = 'Bold 34pt Roboto,Helvetica,Arial,sans-serif';
const legendMargin = new Margin(14, 12, 0, 24);
const legendButtonMargin = new Margin(0, 24, 0, 0);

export function legend() {
	return new Group(Panel.Auto, {
		layout: new GridLayout({
			wrappingColumn: 1,
		}),
		resizable: false,
		movable: true,
	}).add(
		new Shape('RoundedRectangle', { parameter1: 10, fill: 'white' }),
		new Panel(Panel.Vertical, {
			defaultAlignment: Spot.TopCenter,
		}).add(
			new Panel(Panel.Horizontal).add(
				new TextBlock({
					font: legendFond,
					stroke: textColor,
					text: 'Legend',
					margin: legendMargin,
					alignment: Spot.Left,
				}),
				GraphObject.make('SubGraphExpanderButton', {
					width: 25,
					margin: legendButtonMargin,
				}),
			),
			new Placeholder({
				padding: 8,
				background: 'white',
			}),
		),
	);
}
