import { Button, Container, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import T4Logo from '../../images/logos/Treasury4_GrayGreen_Logo.svg';
import { T4Solution } from '../t4Solutions';
import { Layout } from './layout';
import { Navbar } from './navbar';
import SolutionPicker from './solutionPicker/solutionPicker';

type VisualizationErrorProps = {
	headingText: string;
	bodyText: string;
	imageSrc: string;
	module?: T4Solution;
	buttonText?: string;
	buttonHref?: string;
	hideSideNavigation?: boolean;
};
export const CannotDisplay: FC<VisualizationErrorProps> = observer(
	({
		headingText,
		bodyText,
		imageSrc,
		module = T4Solution.Entity4,
		buttonText,
		buttonHref,
		hideSideNavigation = true,
	}) => {
		const cannotDisplay = (
			<Container
				sx={{
					height: '100%',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				<Grid
					container
					spacing={2}
					rowSpacing={2}
					width="100%"
					sx={{
						flexWrap: 'nowrap',
						alignItems: 'center',
					}}
				>
					<Grid item xs={8}>
						<Grid container spacing={2} justifyContent="center">
							<Grid item xs={6}>
								<img
									src={T4Logo}
									alt="Treasury4"
									style={{ maxWidth: '100%' }}
								/>
							</Grid>
							<Grid item container xs={12} justifyContent="center">
								<Typography fontSize="48px" fontWeight="bold" align="center">
									{headingText}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle1" align="center">
									{bodyText}
								</Typography>
							</Grid>
							<Grid
								item
								container
								xs={12}
								sx={{
									flexDirection: 'row',
									flexWrap: 'nowrap',
									justifyContent: 'center',
								}}
							>
								{buttonHref && buttonText && (
									<Button
										variant="contained"
										color="primary"
										href={buttonHref}
										sx={{
											flexDirection: 'row',
											flexWrap: 'nowrap',
											justifyContent: 'center',
										}}
									>
										{buttonText}
									</Button>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={16} width="100%">
						<img src={imageSrc} alt="Error" style={{ maxWidth: '100%' }} />
					</Grid>
				</Grid>
			</Container>
		);

		if (hideSideNavigation) {
			return cannotDisplay;
		}

		return (
			<Layout
				appBar={<Navbar logo={<SolutionPicker activeSolution={module} />} />}
				defaultOpen={false}
				toggleable={false}
			>
				{cannotDisplay}
			</Layout>
		);
	},
);
