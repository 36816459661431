import {
	T4Button,
	T4ButtonProps,
} from 'features/entity4/shared/components/atoms/t4Button';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type VisualizationButtonProps = Omit<T4ButtonProps, 'variant'>;

export const VisualizationButton: FC<VisualizationButtonProps> = observer(
	(props) => (
		<T4Button
			{...props}
			variant="outlined"
			sx={{
				...props.sx,
				background: 'white',
				'&:hover': {
					background: 'white',
				},
			}}
		/>
	),
);
