import { action, makeAutoObservable, runInAction } from 'mobx';

const defaultMakeCheckMatch = (itemToMatch: any) => {
	return (itemFromArr: any, index?: number, arr?: any[]): boolean => {
		return itemFromArr === itemToMatch;
	};
};

export class ListModel<T> {
	private _items: T[];

	private _itemName: string;
	private _makeCheckMatch: typeof defaultMakeCheckMatch;

	constructor(itemName: string, items: T[], find = defaultMakeCheckMatch) {
		this._itemName = itemName;
		this._makeCheckMatch = find;
		this._items = items;

		makeAutoObservable(this, {
			add: action,
			remove: action,
		});
	}

	public static make<TS>(
		name: string,
		items: TS[],
		find?: typeof defaultMakeCheckMatch
	) {
		return new ListModel<TS>(name, items, find);
	}

	get items() {
		return this._items;
	}

	public add(item: T) {
		this._items.push(item);
	}

	public remove(itemToRemove: T) {
		const checkMatch = this._makeCheckMatch(itemToRemove);
		const foundIndex = this._items.findIndex(checkMatch);

		if (foundIndex === -1) {
			throw new Error(`${this._itemName} not found`);
		}

		runInAction(() => {
			this._items = this._items.filter((_, index) => index !== foundIndex);
		});
	}

	public get hasItems() {
		return this._items.length > 0;
	}
}
