import { GridColDef } from '@mui/x-data-grid-pro';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	EntityTypeId,
	getEntityType,
	getEntityTypeKey,
} from '../entity4Constants';
import { IRelationshipDefinitionData } from './relationshipApiTypes';
import { BaseRelationship } from './relationshipsObjectTypes';

export function getRelationshipColumns<
	TRow extends BaseRelationship,
>(): GridColDef<TRow>[] {
	return [
		{
			...getDateColumnDefinition(),
			field: 'effectiveFrom',
			headerName: 'Effective From',
			description: 'Effective From',
			minWidth: 150,
		},
		{
			...getDateColumnDefinition(),
			field: 'effectiveTo',
			headerName: 'Effective To',
			description: 'Effective To',
			minWidth: 150,
		},
		{
			field: 'notes',
			headerName: 'Notes',
			description: 'Notes',
			minWidth: 150,
		},
	];
}

// filters what possible entity type connections can be made according to this.entityType
export function filterPossibleEntityTypeConnections(
	currentEntityType: EntityTypeId,
	relationships: IRelationshipDefinitionData[],
) {
	return relationships
		.flatMap((relationship) => {
			return relationship.types;
		})
		.map((type) =>
			type.primaryType === currentEntityType
				? {
						id: type.secondaryType,
						name: `${getEntityTypeKey(
							getEntityType(currentEntityType)!,
						)} to ${getEntityTypeKey(getEntityType(type.secondaryType)!)}`,
				  }
				: {
						id: type.primaryType,
						name: `${getEntityTypeKey(
							getEntityType(currentEntityType)!,
						)} to ${getEntityTypeKey(getEntityType(type.primaryType)!)}`,
				  },
		)
		.filter(
			(value, index, self) =>
				index === self.findIndex((x) => x.id === value.id),
		)
		.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});
}

// filters relationships to include definitions and types that are applicable to this.entityType
export function filterDefinitions(
	currentEntityType: EntityTypeId,
	relationships: IRelationshipDefinitionData[],
) {
	return relationships
		.filter((relationship) =>
			relationship.types.find(
				(type) =>
					type.primaryType === currentEntityType ||
					type.secondaryType === currentEntityType,
			),
		)
		.map((relationshipDefinition) => ({
			...relationshipDefinition,
			types: relationshipDefinition.types.filter(
				(type) =>
					type.primaryType === currentEntityType ||
					type.secondaryType === currentEntityType,
			),
		}));
}
