export interface BaseRelationship {
	id: string;
	relationshipTypeId: string;
	relationshipTypeName: string;
	definitionDescriptor: string;
	isPrimaryDirection: boolean;
	entityId: string;
	entityTypeId: number;
	effectiveFrom?: string | null;
	effectiveTo?: string | null;
	notes?: string | null;
}
export type PowerOfAttorneyRelationship = BaseRelationship & {
	powerOfAttorneyId?: string;
};

export enum RelationshipDirection {
	primary,
	secondary,
}
