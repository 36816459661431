import { Option } from 'modules/clients/customer-api/src/api/common';
import { ObjectType } from 'modules/clients/customer-api/src/api/objects';
import { useCallback, useMemo, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { getObjectInputType } from '../../object/utilities';
import {
	contactCollectionIdentifier,
	contactTypeFieldIdentifier,
} from '../contactsView';

export type UseCreateContactProps = {
	isCreating: boolean;
	error: string | undefined;
	createCollection: (contactType: Option) => Promise<boolean>;
};

export function useCreateContact(
	objectId: string,
	objectType: ObjectType,
): UseCreateContactProps {
	const { customerApiClient } = useClients();

	const [isCreating, setIsCreating] = useState(false);
	const [error, setError] = useState<string>();

	const _objectId = useMemo(() => objectId, [objectId]);
	const _objectType = useMemo(() => objectType, [objectType]);

	const createCollection = useCallback(
		async (contactType: Option): Promise<boolean> => {
			try {
				setIsCreating(true);
				setError(undefined);

				const response = await customerApiClient.api.objects.collections.create(
					{
						objectId: _objectId,
						objectType: getObjectInputType(_objectType),
						collectionIdentifier: contactCollectionIdentifier,
						fieldUpdates: [
							{
								fieldIdentifier: contactTypeFieldIdentifier,
								value: contactType?.id,
							},
						],
						parentCollectionId: null,
						phoneNumberFieldUpdates: [],
					},
				);

				if (response && response.data && response.data.error) {
					setError(response.data.error);
				}
				if (
					response &&
					response.data &&
					response.data.errors &&
					(Object.values(response.data.errors)?.length ?? 0) > 0
				) {
					setError(
						error?.concat(flattenErrors(response.data.errors).join(', ')),
					);
				}
			} catch (error) {
				setError('Unable to create collection.');
			} finally {
				setIsCreating(false);

				if (error) {
					return false;
				} else {
					return true;
				}
			}
		},
		[_objectId, _objectType, customerApiClient.api.objects.collections, error],
	);

	return {
		isCreating,
		error,
		createCollection,
	};
}
