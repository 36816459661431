import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { flow, makeAutoObservable } from 'mobx';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { UserEntitlementsListModel } from './userEntitlementsListModel';

export class UserEntitlementsListViewModel {
	private listModel: UserEntitlementsListModel;

	constructor() {
		makeAutoObservable(this);
		this.listModel = new UserEntitlementsListModel();
	}

	public getLoading = () => {
		return this.listModel.loading;
	};

	public getError = () => {
		return this.listModel.error !== '' ? this.listModel.error : undefined;
	};

	public getColumns = (): GridColDef[] => {
		return [
			{
				field: 'id',
				headerName: 'User',
				flex: 7,
				minWidth: 150,
			},
			{
				field: 'roles',
				headerName: 'Roles',
				flex: 10,
				minWidth: 300,
			},
			{
				field: 'securityGroups',
				headerName: 'Security Groups',
				flex: 5,
				minWidth: 100,
			},
			{
				...getDateColumnDefinition(),
				field: 'lastLogin',
				headerName: 'Last Login',
				flex: 5,
				minWidth: 100,
			},
		];
	};

	public getRows = (): GridRowModel[] => {
		return this.listModel.userEntitlements.map((userEntitlements) => {
			return {
				id: userEntitlements.user,
				roles: userEntitlements.roles.join(', '),
				securityGroups: userEntitlements.securityGroups.join(', '),
				lastLogin: userEntitlements.lastLogin,
			};
		});
	};

	public loadUserEntitlements = flow(function* (
		this: UserEntitlementsListViewModel,
	) {
		yield this.listModel.load();
	});
}
