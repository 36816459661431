import { FC } from 'react';
import { Field, FieldDataType } from './fieldTypes';
import FieldViewBoolean from './fieldViews/fieldViewBoolean';
import FieldViewDate from './fieldViews/fieldViewDate';
import { FieldViewOptions } from './fieldViews/fieldViewOptions';
import { FieldViewReference } from './fieldViews/fieldViewReference';
import { FieldViewString } from './fieldViews/fieldViewString';
import { FieldViewProps } from './fieldViews/fieldViewTypes';

export type FieldViewResolverProps = {
	resolve: (field: Field) => FC<FieldViewProps>;
};

const customViews = new Map<FieldDataType, FC<FieldViewProps>>([
	[FieldDataType.boolean, FieldViewBoolean],
	[FieldDataType.date, FieldViewDate],
	[FieldDataType.options, FieldViewOptions],
]);

export const getFieldView = (field: Field) => {
	if (field.hasPredefinedValues) {
		return FieldViewReference;
	}

	return customViews.get(field.fieldType) ?? FieldViewString;
};
