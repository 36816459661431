import { useCallback } from 'react';
import { useUser } from 'shared/hooks/useUser';

export type T4LocalStorageKeys = 'legalEntityGroupIds';

export type UseLocalStorageProps = {
	getItem: (key: T4LocalStorageKeys) => string | undefined;
	updateItem: (key: T4LocalStorageKeys, value: string) => void;
};

export function useLocalStorage(): UseLocalStorageProps {
    const user = useUser();
    
	const getItem = useCallback((key: T4LocalStorageKeys) => {
		return localStorage.getItem(`t4-${user.org}-${key}`) ?? undefined;
	}, [user.org]);

	const updateItem = useCallback(
		(key: T4LocalStorageKeys, value?: string) => {
			if (value) {
				localStorage.setItem(`t4-${user.org}-${key}`, value);
			} else {
				localStorage.removeItem(`t4-${user.org}-${key}`);
			}
		},
		[user.org],
	);

	return {
		getItem,
		updateItem,
	};
}