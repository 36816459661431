import { EntityType } from 'features/entity4/entity4Constants';
import { Tag } from 'modules/clients/customer-api/src/entity4/tags';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useState } from 'react';
import {
	TagsContext,
	TagsContextProps,
} from 'shared/providers/contexts/tagsContext';
import { parseErrorsV2 } from 'utilities/errors/errorUtils';
import { useClients } from './useClients';

export type UseTagsProps = TagsContextProps & {
	isAdding: boolean;
	addTag: (
		objectType: EntityType,
		objectId: string,
		name: string,
	) => Promise<Tag | undefined>;
};

export function useTags(): UseTagsProps {
	const { enqueueSnackbar } = useSnackbar();
	const { customerApiClient } = useClients();
	const context = useContext(TagsContext);

	const [isAdding, setIsAdding] = useState(false);

	const addTag = useCallback(
		async (objectType: EntityType, objectId: string, name: string) => {
			try {
				setIsAdding(true);

				let response = await customerApiClient.entity4.tags.add({
					data: { name: name },
					entityType: objectType,
					entityId: objectId,
				});

				if (response.status !== 200 || !response.data.success) {
					if (response.data.errors) {
						enqueueSnackbar(parseErrorsV2(response.data.errors), {
							variant: 'error',
						});
					} else {
						enqueueSnackbar('Something unexpected happened.', {
							variant: 'error',
						});
					}

					return;
				}

				context.refetchTags();

				return {
					tagId: response.data.value,
					name: name,
					lastUsedOn: new Date().toISOString(),
				} as Tag;
			} catch (err: any) {
				enqueueSnackbar(parseErrorsV2(err), {
					variant: 'error',
				});
			} finally {
				setIsAdding(false);
			}
		},
		[context, customerApiClient.entity4.tags, enqueueSnackbar],
	);

	return {
		...context,
		isAdding,
		addTag,
	};
}
