import {
	EntityTypeId,
	statusFieldByEntityTypeId,
	uniqueCodeFieldsByEntityTypeId,
} from '../../entity4Constants';
import {
	ICategorizedFieldSets,
	IFieldSetData,
	IFieldSetCategory,
	IFieldSetSubcategory,
	FieldSet,
	Field,
	IListFieldSet,
	FieldDataContract,
} from './fieldTypes';

export function groupFieldsByCategory(
	fieldSets: IFieldSetData[],
): ICategorizedFieldSets[] {
	var categoriesById: Map<string, IFieldSetCategory> = new Map();
	var subcategoriesByCategoryId: { [key: string]: IFieldSetSubcategory[] } = {};
	var fieldSetsBySubcategoryIdAndCategoryId: { [key: string]: FieldSet[] } = {};

	fieldSets!.forEach((fieldSet) => {
		// store all categories to categorize later
		if (!categoriesById.has(fieldSet.category.id)) {
			categoriesById.set(fieldSet.category.id, fieldSet.category);
		}

		// store all subcategories by category id
		subcategoriesByCategoryId[fieldSet.category.id] =
			subcategoriesByCategoryId[fieldSet.category.id] || [];
		if (
			!subcategoriesByCategoryId[fieldSet.category.id].find(
				(x) => x.id === fieldSet.subcategory.id,
			)
		) {
			subcategoriesByCategoryId[fieldSet.category.id].push(
				fieldSet.subcategory,
			);
		}

		// store fields by subcategory id
		let categorySubcategoryId = fieldSet.category.id + fieldSet.subcategory.id;
		fieldSetsBySubcategoryIdAndCategoryId[categorySubcategoryId] =
			fieldSetsBySubcategoryIdAndCategoryId[categorySubcategoryId] || [];
		fieldSetsBySubcategoryIdAndCategoryId[categorySubcategoryId].push(
			new FieldSet(fieldSet),
		);
	});

	return Array.from(categoriesById.entries())
		.map(([categoryId, category]) => {
			return {
				category: category,
				subCategories: subcategoriesByCategoryId[categoryId]
					.sort((a, b) => a.sortOrder - b.sortOrder)
					.map((subcategory) => {
						return {
							subcategory,
							fieldSets: fieldSetsBySubcategoryIdAndCategoryId[
								category.id + subcategory.id
							].sort((a, b) => a.sortOrder - b.sortOrder),
						};
					}),
			};
		})
		.sort((a, b) => a.category.sortOrder - b.category.sortOrder);
}

export function groupFieldsByListColumnSettings(
	fieldSets: IFieldSetData[],
): IListFieldSet[] {
	var staticColumnFieldSets: IFieldSetData[] = [];
	var columnFieldSets: IFieldSetData[] = [];

	fieldSets.forEach((fieldSet) => {
		if (isFieldSetInMap(fieldSet.identifier, uniqueCodeFieldsByEntityTypeId)) {
			staticColumnFieldSets.unshift(fieldSet);
		} else if (
			isFieldSetInMap(fieldSet.identifier, statusFieldByEntityTypeId)
		) {
			staticColumnFieldSets.push(fieldSet);
		} else {
			columnFieldSets.push(fieldSet);
		}
	});

	// sorting fields based on category -> subcategory -> field sort order
	// prepending uniqueCode and status to the front of the sorted columns
	columnFieldSets.sort((a, b) => a.sortOrder - b.sortOrder);
	columnFieldSets.sort(
		(a, b) => a.subcategory.sortOrder - b.subcategory.sortOrder,
	);
	columnFieldSets.sort((a, b) => a.category.sortOrder - b.category.sortOrder);
	fieldSets = staticColumnFieldSets.concat(columnFieldSets);

	return fieldSets.map((fieldSet) => {
		var fieldDataContract = new FieldDataContract(
			fieldSet.identifier,
			fieldSet.fields[0].identifier,
		);

		return {
			headerName: fieldSet.displayName,
			isListColumn: fieldSet.isDefaultListColumn,
			isApprovalListColumn: fieldSet.isDefaultApprovalListColumn,
			isCollectionField: fieldSet.allowMany || fieldSet.isFromToCollection,
			hasReferenceData: !!fieldSet.fields[0].metadata.referenceCollection,
			fieldType: fieldSet.fields[0].metadata.fieldType,
			showPending: isFieldSetInMap(
				fieldSet.identifier,
				uniqueCodeFieldsByEntityTypeId,
			),
			contract: fieldDataContract,
			requiresApproval: fieldSet.fields[0].metadata.requiresApproval,
			isCreationRequirement: fieldSet.fields[0].metadata.isCreationRequirement,
			referenceCollectionName: fieldSet.fields[0].metadata.referenceCollection,
			optionListId: fieldSet.fields[0].metadata.optionListId,
			parentFieldIdentifier: fieldSet.fields[0].parentFieldIdentifier,
		};
	});
}

export function getDefinitions(fieldSets: IFieldSetData[]) {
	const maps = {
		toDefinition: new Map<string, Field>(),
		toParentDefinition: new Map<string, Field>(),
	};

	const orphans = new Map<string, string>();

	fieldSets.forEach(({ fields }) => {
		fields.forEach((fieldData) => {
			const field = new Field(fieldData);
			maps.toDefinition.set(field.identifier, field);
			if (!field.parentFieldIdentifier) {
				return;
			}

			const parent = maps.toDefinition.get(field.parentFieldIdentifier);
			if (!parent) {
				orphans.set(field.identifier, field.parentFieldIdentifier);
			} else {
				maps.toParentDefinition.set(field.identifier, parent);
			}
		});
	});

	for (const [id, parentId] of orphans) {
		const parent = maps.toDefinition.get(parentId);
		maps.toParentDefinition.set(id, parent!);
	}

	return maps;
}

const isFieldSetInMap = (
	fieldSetIdentifier: string,
	map: Map<EntityTypeId, FieldDataContract>,
) => {
	return Array.from(map.values()).some(
		(value) => value.fieldSetIdentifier === fieldSetIdentifier,
	);
};
