import { AxiosInstance } from 'axios';
import { Write } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Collection, ObjectInput, UpdateField } from '..';
import { CollectionFieldEndpoints, collectionFields } from './fields';

//#region Request Models

export type CreatePhoneNumberCollectionField = {
	fieldIdentifier: string;
	countryCode: string | null;
	number: string | null;
	extension: string | null;
};

export type CreateCollection = {
	collectionIdentifier: string;
	parentCollectionId: string | null;
	fieldUpdates: UpdateField[];
	phoneNumberFieldUpdates: CreatePhoneNumberCollectionField[];
};

export type CreateCollectionInput = ObjectInput & CreateCollection;

export type DeleteCollectionInput = ObjectInput & {
	collectionId: string;
};

//#endregion

export type CollectionEndpoints = {
	create: Write<
		ApiResponse<Collection>,
		CreateCollectionInput,
		CreateCollection
	>;
	delete: Write<ApiResponse<string>, DeleteCollectionInput, undefined>;
	fields: CollectionFieldEndpoints;
};

export function collections(axiosInstance: AxiosInstance): CollectionEndpoints {
	return {
		fields: collectionFields(axiosInstance),
		create: async (input, config = {}) =>
			await axiosInstance.post(
				`/${input!.objectType}/${input!.objectId}/collections`,
				{
					collectionIdentifier: input.collectionIdentifier,
					parentCollectionId: input.parentCollectionId,
					fieldUpdates: input.fieldUpdates,
					phoneNumberFieldUpdates: input.phoneNumberFieldUpdates,
				},
				{
					...config,
				},
			),
		delete: async (input, config = {}) =>
			await axiosInstance.delete(
				`/${input!.objectType}/${input!.objectId}/collections/${
					input!.collectionId
				}`,
				{
					...config,
				},
			),
	};
}
