import { Moment } from 'moment';

export interface IDateRangeValidator {
	validate: (
		start: Moment | null,
		end: Moment | null,
		maxRange: number,
	) => boolean;
}

class DateRangeValidator implements IDateRangeValidator {
	validate(start: Moment | null, end: Moment | null, maxRange: number) {
		if (start == null || end == null) return false;
		return Math.abs(start!.diff(end, 'day', true)) < maxRange;
	}
}

export default DateRangeValidator;
