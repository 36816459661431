import { AxiosInstance } from 'axios';
import { T4DataResponse, T4Response } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Write } from '../../transactions';

//#region Models

export type Counterparty = T4DataResponse<{
	id: string;
	code: string;
}>;

//#endregion

//#region Responses

export type DeleteCounterpartyData = {
	id: string;
};
export type DeleteCounterpartyResponse = T4Response<
	ApiResponse<Counterparty>,
	DeleteCounterpartyData
>;

//#endregion

export type CounterpartyEndpoints = {
	delete: Write<ApiResponse<Counterparty>, DeleteCounterpartyData>;
};

export function counterparties(
	axiosInstance: AxiosInstance,
): CounterpartyEndpoints {
	return {
		delete: async (data, config = {}): Promise<DeleteCounterpartyResponse> =>
			await axiosInstance.delete(`counterparty/${data.id}`, {
				...config,
			}),
	};
}
