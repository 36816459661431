import { makeAutoObservable } from 'mobx';
import { PhoneNumber as PhoneNumberData } from 'modules/clients/customer-api/src/api/objects';

export class PhoneNumber {
	public id: string;
	public countryCode?: string;
	public number?: string;
	public extension?: string;

	constructor(phoneNumberData: PhoneNumberData) {
		makeAutoObservable(this);

		if (!phoneNumberData) {
			throw new Error('Phone number data is required.');
		}

		this.id = phoneNumberData.phoneNumberId;
		this.countryCode = phoneNumberData.countryCode ?? undefined;
		this.number = phoneNumberData.number ?? undefined;
		this.extension = phoneNumberData.extension ?? undefined;
	}
}
