import { AxiosResponse } from 'axios';
import { flow, makeAutoObservable, observable } from 'mobx';
import { ApiResponse } from '../../../../../utilities/api';
import { IFilter } from '../../../../../utilities/filter';
import {
	EntityConfig,
	EntityType,
	IEntityConfigDefinition,
} from '../../../entity4Constants';
import CommonFieldStore, {
	FieldStore,
	fieldFilterByEntityType,
} from '../../../shared/fieldSets/fieldStore';
import { IFieldSetData } from '../../../shared/fieldSets/fieldTypes';
import { IEntityApiData } from '../../entitiesApiTypes';
import { EntityRepository } from '../../entityRepository';
import { Entity } from '../../objects/entity';

export class EntityViewModel {
	public entity: Entity | undefined;
	public fieldFilter: IFilter<IFieldSetData>;
	public entityConfigDefinition: IEntityConfigDefinition;
	public fieldStore: FieldStore = CommonFieldStore;

	private _allowV1Rendering: boolean;
	private _loading: boolean;
	private _entityRepository: EntityRepository;
	private _onUpdate: () => void = () => {};

	constructor(
		entityId: string,
		entityType: EntityType,
		entityRepository?: EntityRepository,
		onUpdate?: () => void,
	) {
		makeAutoObservable<EntityViewModel, '_loading' | '_allowV1Rendering'>(
			this,
			{
				_loading: observable,
				_allowV1Rendering: observable,
			},
		);

		if (onUpdate) {
			this._onUpdate = onUpdate;
		}

		this._allowV1Rendering =
			[EntityType.Account, EntityType.Subaccount].indexOf(entityType) < 0;
		this.fieldFilter = fieldFilterByEntityType.get(entityType)!;
		this.entityConfigDefinition = EntityConfig.get(entityType)!;

		this._loading = true;

		this._entityRepository =
			entityRepository ?? new EntityRepository(entityType, entityId);
	}

	get loading(): boolean {
		return this._loading;
	}

	get allowsV1Rendering() {
		return this._allowV1Rendering;
	}

	get latestUpdatedDate(): Date | undefined {
		return this.entity?.latestUpdatedDate;
	}

	public load = flow(function* (this: EntityViewModel) {
		this._loading = true;

		try {
			yield this.fieldStore.getFields(this.fieldFilter);

			try {
				const entityResponse: AxiosResponse<ApiResponse<IEntityApiData>> =
					yield this._entityRepository.get();

				if (
					entityResponse &&
					entityResponse.data &&
					entityResponse.data.value
				) {
					this.entity = new Entity(
						entityResponse.data.value,
						undefined,
						this._onUpdate,
					);
				}
			} catch (error) {
				console.log(error);
			}
		} finally {
			this._loading = false;
		}
	});
}
