import { useQuery } from '@tanstack/react-query';
import { ApprovalRule } from 'modules/clients/apiGateway/payments4/approvalRules';
import { T4DataResponse2 } from 'modules/clients/types';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
export const useGetPaymentApprovalRule = (id: string | null) => {
	const { applicationApiClient } = useClients();

	const loadPaymentApprovalRule = useCallback(async () => {
		if (id === null) return;
		try {
			const response = await applicationApiClient.payments4.approvalRules.get(
				id,
			);
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<ApprovalRule>).data;
			else if (response.status === 404)
				throw new Error('Payment approval rule not found.');
			else throw new Error();
		} catch (error: any) {
			throw new Error(
				error?.message ??
					'Unable to load payment approval rule. Please try again later.',
			);
		}
	}, [id, applicationApiClient]);

	return useQuery<ApprovalRule | undefined, Error>(
		['payment-approval-rule', id],
		loadPaymentApprovalRule,
		{
			refetchOnWindowFocus: false,
			enabled: id !== null,
		},
	);
};
