import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { FormModal } from '../../../../shared/components/formModal';
import { IEntityFieldApiData } from '../../entities/entitiesApiTypes';
import { Entity } from '../../entities/objects/entity';
import { EntityFieldBase } from '../../entities/objects/fields/field';
import { FieldSet } from './fieldTypes';
import { getFieldView } from './fieldViewResolver';

export type CreateFieldSetButtonProps = {
	fieldSetDefinition: FieldSet;
	entity: Entity;
};

export class EntityFieldChange {
	public readonly identifier: string;
	public readonly value: any;

	constructor(identifier: string, value: any) {
		this.identifier = identifier;
		this.value = value;
	}
}

type ChangeMap = { [key: string]: EntityFieldChange };

class EntitySetPrerequisite extends EntityFieldBase {
	private _pendingChanges: ChangeMap;
	private _setPendingChanges: (map: ChangeMap) => void;
	private _changeKey: string;

	constructor(
		data: IEntityFieldApiData,
		pendingChanges: ChangeMap,
		setPendingChanges: (map: ChangeMap) => void,
		changeKey: string,
	) {
		super(data);
		this._pendingChanges = pendingChanges;
		this._setPendingChanges = setPendingChanges;
		this._changeKey = changeKey;
	}

	public async save(value: string | boolean) {
		this._setPendingChanges({
			...this._pendingChanges,
			[this._changeKey]: new EntityFieldChange(
				this._changeKey,
				typeof value === 'string' ? value : JSON.stringify(value),
			),
		});
	}
}

export const CreateFieldSetButton: FC<CreateFieldSetButtonProps> = observer(
	({ fieldSetDefinition, entity }) => {
		const [modalOpen, setModalOpen] = useState(false);
		const [loading, setLoading] = useState(false);
		const [errorMessage, setErrorMessage] = useState('');
		const [pendingChanges, setPendingChanges] = useState<{
			[key: string]: EntityFieldChange;
		}>({});

		const openModal = () => {
			setModalOpen(true);
		};

		const closeModal = () => {
			setModalOpen(false);
			resetChanges();
		};
		const onCreateClick = () => {
			entity.createCollection(fieldSetDefinition.name);
		};

		const resetChanges = () => {
			setPendingChanges({});
		};

		const onSubmit = () => {
			setLoading(true);
			setErrorMessage('');

			const fieldUpdates = Object.values(pendingChanges).map((change) => ({
				fieldIdentifier: change.identifier,
				value: change.value,
			}));

			return entity
				.createCollection(fieldSetDefinition.name, fieldUpdates)
				.catch(setErrorMessage)
				.finally(() => {
					setLoading(false);
					closeModal();
				});
		};

		if (fieldSetDefinition.creationFields.length === 0) {
			return (
				<Button
					size="small"
					onClick={onCreateClick}
					variant="contained"
					color="primary"
				>
					Add {fieldSetDefinition.displayName}
				</Button>
			);
		}

		const creationFields = fieldSetDefinition.creationFields.map(
			(fieldDefinition) => {
				const key = `createFieldSetButton ${fieldSetDefinition.name} ${fieldDefinition.id}`;

				const View = getFieldView(fieldDefinition);
				const fieldData = new EntitySetPrerequisite(
					{
						id: '',
						identifier: fieldDefinition.identifier,
						fieldType: fieldDefinition.fieldType,
					},
					pendingChanges,
					setPendingChanges,
					fieldDefinition.identifier, // not confident on this
				);

				return (
					<View
						key={key}
						fieldData={fieldData}
						fieldDefinition={fieldDefinition}
						fieldLayoutProps={{ excludeDivider: true }}
						createField={() => {}}
					/>
				);
			},
		);

		return (
			<>
				<Button
					size="small"
					onClick={openModal}
					variant="contained"
					color="primary"
					sx={{ marginY: '32px' }}
				>
					Add {fieldSetDefinition.displayName}
				</Button>
				<FormModal
					open={modalOpen}
					onClose={closeModal}
					title={`Add ${fieldSetDefinition.displayName}`}
					onSubmit={onSubmit}
					error={errorMessage}
					loading={loading}
					submitButtonLabel={`Add ${fieldSetDefinition.displayName}`}
					loadingMessage={`Adding ${fieldSetDefinition.displayName}...`}
					submitDisabled={Object.values(pendingChanges).length === 0}
				>
					{creationFields}
				</FormModal>
			</>
		);
	},
);
