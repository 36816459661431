import {
	TableCustomViewPreferences,
	TableCustomViewPreferencesReq,
	TablePreferences,
	TablePreferencesReq,
} from 'modules/clients/customer-api/src/userPreference';
import { useCallback, useEffect, useState } from 'react';
import { ApiResponse } from 'utilities/api';
import { useClients } from './useClients';
import { useUser } from './useUser';

export type UseUserTablePreferenceProps = {
	isInitializing: boolean;
	tablePreferences?: TablePreferences;
	updateTablePreferences: (
		tablePreferences: TablePreferencesReq,
	) => Promise<void>;
	createUserCustomViewPreference: (
		customView: TableCustomViewPreferencesReq,
	) => Promise<ApiResponse<TableCustomViewPreferences>>;
	deleteUserCustomViewPreference: (customViewId: string) => Promise<void>;
};

export function useUserTablePreference(
	tableCode: string,
): UseUserTablePreferenceProps {
	const { customerApiClient } = useClients();
	const user = useUser();

	const [isInitializing, setIsInitializing] = useState<boolean>(true);
	const [tablePreferences, setTablePreferences] = useState<TablePreferences>();

	const createUserCustomViewPreference = useCallback<
		(
			customView: TableCustomViewPreferencesReq,
		) => Promise<ApiResponse<TableCustomViewPreferences>>
	>(
		async (customView) => {
			const response =
				await customerApiClient.userPreference.createTableCustomView({
					userId: user.id,
					tableCode: tableCode,
					createCustomView: customView,
				});

			if (response.data.success) {
				const updatedUserTablePreference = {
					...tablePreferences,
				} as TablePreferences;
				updatedUserTablePreference.customViews?.push(response.data.value);
				setTablePreferences(updatedUserTablePreference);
			}

			return response.data;
		},
		[customerApiClient.userPreference, tableCode, tablePreferences, user.id],
	);

	const deleteUserCustomViewPreference = useCallback(
		async (customViewId: string) => {
			const updatedUserTablePreference = {
				...tablePreferences,
				customViews: tablePreferences?.customViews?.filter(
					(x) => x.id !== customViewId,
				),
			} as TablePreferences;
			setTablePreferences(updatedUserTablePreference);

			await customerApiClient.userPreference.deleteTableCustomView({
				userId: user.id,
				tableCode: tableCode,
				customViewId: customViewId,
			});
		},
		[customerApiClient.userPreference, tableCode, tablePreferences, user.id],
	);

	const updateTablePreferences = useCallback<
		(tablePreferencesReq: TablePreferencesReq) => Promise<void>
	>(
		async (tablePreferencesReq) => {
			setTablePreferences({
				...tablePreferences,
				selectedCustomViewId: tablePreferencesReq.selectedCustomViewId,
				columnPreferences: tablePreferencesReq.columnPreferences,
				tableFilter: tablePreferencesReq.tableFilter,
			} as TablePreferences);

			await customerApiClient.userPreference.updateTablePreferences({
				userId: user.id,
				tableCode: tableCode,
				tablePreferences: tablePreferencesReq,
			});
		},
		[customerApiClient.userPreference, tableCode, tablePreferences, user.id],
	);

	const loadPreferences = useCallback(async () => {
		try {
			setIsInitializing(true);
			const result = await customerApiClient.userPreference.getTablePreferences(
				{
					userId: user.id,
					tableCode: tableCode,
				},
			);
			setTablePreferences(result.data?.value ?? undefined);
		} catch {
		} finally {
			setIsInitializing(false);
		}
	}, [customerApiClient.userPreference, tableCode, user.id]);

	useEffect(() => {
		loadPreferences();

		return () => {
			setTablePreferences(undefined);
		};
	}, [loadPreferences]);

	return {
		isInitializing,
		tablePreferences,
		updateTablePreferences,
		createUserCustomViewPreference,
		deleteUserCustomViewPreference,
	};
}
