import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { observer } from 'mobx-react-lite';
import moment, { Moment } from 'moment';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDate } from 'shared/utilities/dateUtilities';
import { ObjectPathParams } from '../../../../../shared/constants/paths';
import {
	canSaveDate,
	getDateWarningMessage,
	isDateEqual,
} from '../../../entities/utilities/dateUtilities';
import { T4DateField } from '../../components/atoms/t4DateField';
import { InputAdornmentWithModal } from '../../components/molecules/inputAdornmentWithModal';
import { T4AlertStack } from '../../components/molecules/t4AlertStack';
import { FieldError } from './common/fieldError';
import { FieldViewFieldLayout } from './common/fieldLayout';
import { FieldViewProps, FieldViews } from './fieldViewTypes';

const FieldViewDate: FC<FieldViewProps> = observer(
	({ fieldDefinition, fieldData, customError, ...props }) => {
		const { viewType } = useProfileView();
		const { objectId } = useParams<ObjectPathParams>();
		const [date, setDate] = useState<Moment | null>(
			fieldData?.initialDateValue || null,
		);

		const save = (dateToSave: Moment | null) => {
			const formattedDate = formatDate(dateToSave) || '';
			if (!fieldData) {
				return props.createField(formattedDate);
			}

			if (isDateEqual(dateToSave, fieldData?.initialDateValue)) {
				fieldData?.setError('');
				return;
			}

			if (!canSaveDate(dateToSave)) {
				return;
			}

			// Normalize to saved value (input should be blurred now)
			fieldData.save(formattedDate).then(() => {
				if (dateToSave === null || dateToSave.isValid()) {
					setDate(fieldData.initialDateValue);
				}
			});
		};

		const onChange = (changedDate: Moment | null) => {
			setDate(changedDate);
		};

		const onAccept = (acceptedDate: Moment | null) => {
			setDate(acceptedDate);

			if (fieldData) {
				save(acceptedDate);
			} else {
				props.createField(formatDate(acceptedDate));
			}
		};

		const isReadOnly = useMemo(() => viewType !== FieldViews.edit, [viewType]);

		const approvalField = (
			<T4DateField
				id={fieldDefinition.id}
				label={`${
					viewType === FieldViews.default
						? fieldDefinition.name
						: 'Approved ' + fieldDefinition.name
				}`}
				onChange={onChange}
				value={moment(fieldData?.approvedValue) ?? null}
				onAccept={onAccept}
				readOnly
			/>
		);

		return (
			<FieldViewFieldLayout
				entityId={objectId}
				fieldDefinition={fieldDefinition}
				fieldData={fieldData}
				approvedValueToDisplay={fieldData?.approvedValue}
				value={date}
				approveFieldDisplay={approvalField}
				rejectionModalProps={{
					title: (
						<>
							Rejecting <strong>{date}</strong> as{' '}
							<strong>{fieldDefinition.name}</strong>
						</>
					),
				}}
				{...props.fieldLayoutProps}
			>
				<T4DateField
					id={fieldDefinition.id}
					label={fieldDefinition.name}
					value={date}
					onChange={onChange}
					onAccept={onAccept}
					onBlur={() => save(date)}
					readOnly={isReadOnly}
					slotProps={{
						field: {
							InputProps: {
								startAdornment: (
									<InputAdornmentWithModal
										title={fieldDefinition.name}
										description={fieldDefinition.description}
										adornmentType={fieldData?.loadingState}
										iconButtonProps={{
											...(viewType === FieldViews.default
												? {
														sx: {
															marginLeft: '-5px',
														},
												  }
												: {}),
										}}
									/>
								),
							},
						},
						textField: {
							variant:
								viewType === FieldViews.default ? 'standard' : 'outlined',
						},
					}}
				/>
				<T4AlertStack
					warning={getDateWarningMessage(fieldDefinition.name, date)}
				/>
				<FieldError
					error={fieldData?.loadingErrorMessage}
					customError={customError}
					onTryAgainClick={() => save(date)}
				/>
			</FieldViewFieldLayout>
		);
	},
);

export default FieldViewDate;
