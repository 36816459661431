import { Grid, Typography } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { Connector } from 'modules/clients/customer-api/src/cash4/connectors';
import { FC } from 'react';

export type FormConnectionParameter = {
	id?: string;
	key: string;
	value: string | null;
	isValueOptional: boolean;
};

export type ConnectionFormProps = {
	readOnly: boolean;
	errors: string[];
	connectors: Connector[];
	connector: Connector | undefined;
	connectionName: string;
	connectionParameters: FormConnectionParameter[];
	setConnector: (connector: Connector | null) => void;
	setConnectionName: (name: string | undefined) => void;
	setConnectionParameter: (key: string, value: string | undefined) => void;
};

export const ConnectionForm: FC<ConnectionFormProps> = ({
	readOnly,
	errors,
	connectors,
	connector,
	connectionName,
	connectionParameters,
	setConnector,
	setConnectionName,
	setConnectionParameter,
}) => (
	<Grid container sx={{ rowGap: 1 }}>
		<Grid item>
			<Typography>
				Provide the necessary parameters to create a connection
			</Typography>
		</Grid>

		<Grid item xs={12}>
			<T4Autocomplete<Connector>
				key="connector"
				label="Connector"
				options={connectors}
				getOptionLabel={(connector) => connector.name}
				isOptionEqualToValue={(connector, value) => connector.id === value.id}
				value={connector ?? null}
				onSelect={(connector) => setConnector(connector)}
				readOnly={
					(connectors.length === 1 && connectors[0] === connector) || readOnly
				}
				required
			/>
		</Grid>

		<Grid item xs={12}>
			<T4TextFieldV2
				key="connectionName"
				label="Connection Name"
				placeholder="Connector"
				value={connectionName}
				onChange={(name) => {
					setConnectionName(name);
				}}
				InputProps={{
					readOnly: readOnly,
				}}
				required
			/>
		</Grid>

		{connector && connector.parameters && connector.parameters.length !== 0 && (
			<Grid container item xs={12} sx={{ rowGap: 1 }}>
				{connector.parameters
					.sort((a, b) => a.order - b.order)
					.map((param) => (
						<Grid key={param.key} item xs={12}>
							<T4TextFieldV2
								id={param.key}
								label={param.name}
								value={
									param.type === 'Secret'
										? connectionParameters
												.find((x) => x.key === param.key)
												?.value?.replace(/\n/g, ' ') ?? ''
										: connectionParameters.find((x) => x.key === param.key)
												?.value ?? ''
								}
								onChange={(value) => setConnectionParameter(param.key, value)}
								InputProps={{
									readOnly: readOnly,
								}}
								isSecret={param.type === 'Secret'}
								required={!param.isValueOptional}
							/>
						</Grid>
					))}
			</Grid>
		)}

		{errors && errors.length !== 0 && (
			<Grid item xs={12}>
				<T4AlertStack errors={errors} />
			</Grid>
		)}
	</Grid>
);
