import { Typography } from '@mui/material';
import {
	GRID_CHECKBOX_SELECTION_COL_DEF,
	GridColumnHeaderParams,
	GridHeaderCheckbox,
	GridInputRowSelectionModel,
	GridRowId,
} from '@mui/x-data-grid-pro';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { Entity4Account } from 'models/apiModels';
import { CreateAccountIntegration } from 'modules/clients/customer-api/src/cash4/accountIntegrations';
import { ConnectionIndex } from 'modules/clients/customer-api/src/cash4/connections';
import { T4Response } from 'modules/clients/types';
import { FC, useEffect, useState } from 'react';
import { ConfirmFormModal } from 'shared/components/confirmFormModal';
import { useClients } from '../../../../../shared/hooks/useClients';
import { stonlyData } from '../../../../../stonly/functions';
import { connectionsListPagePrefix } from '../../../../../stonly/pagePrefixes';
import { T4AlertStack } from '../../../../entity4/shared/components/molecules/t4AlertStack';
import { InnerModalContent } from '../enums/addAccountIntegrationEnums';
import { AccountIdentifiersDataGrid } from './accountIdentifiersDataGrid';
import { AccountSelectionDataGrid } from './accountSelectionDataGrid';

export interface AddAccountIntegrationModalProps {
	isOpen: boolean;
	connection: ConnectionIndex;
	onClose: () => void;
	refetch: () => Promise<void>;
}

export const AddAccountsIntegrationModal: FC<
	AddAccountIntegrationModalProps
> = ({ isOpen, connection, onClose, refetch }) => {
	const { customerApiClient } = useClients();
	const [innerModalContent, setInnerModalContent] =
		useState<InnerModalContent | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [getAccountsError, setGetAccountsError] = useState('');
	const [accounts, setAccounts] = useState<Entity4Account[]>([]);
	const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
	const [accountIdentifiers, setAccountIdentifiers] = useState<
		Map<string, string>
	>(new Map<string, string>());
	const [addAccountErrors, setAddAccountErrors] = useState<string[]>([]);
	const [currentConnection, setCurrentConnection] =
		useState<ConnectionIndex | null>(null);

	useEffect(() => {
		if (isOpen) {
			setLoading(true);

			const fetchEntity4Accounts = async (): Promise<
				T4Response<Entity4Account[]>
			> => {
				return await customerApiClient.cash4.accounts.getAllEntity4Accounts();
			};

			fetchEntity4Accounts()
				.then((response: T4Response<Entity4Account[]>) => {
					if (response.data) {
						const existingAccounts = connection.accountIntegrations.map(
							(x) => x.entity4Account?.id,
						);
						setAccounts(
							response.data.filter(
								(account) =>
									!existingAccounts.includes(account.entity4AccountId),
							),
						);
						setInnerModalContent(InnerModalContent.AccountSelection);
					} else {
						setGetAccountsError('Unable to load accounts.');
					}
				})
				.catch(() => {
					setGetAccountsError('Unable to load accounts.');
				})
				.finally(() => setLoading(false));
		}
	}, [isOpen, connection, customerApiClient.cash4.accounts]);

	useEffect(() => {
		setCurrentConnection(connection);
	}, [connection]);

	const handleBackButtonClick = (): void => {
		setInnerModalContent(InnerModalContent.AccountSelection);
		setAddAccountErrors([]);
	};

	const handleDiscardEdits = (): void => {
		setSelectionModel([]);
		setAccountIdentifiers(new Map<string, string>());
		setGetAccountsError('');
		setAccounts([]);
		setAddAccountErrors([]);
		setInnerModalContent(null);
	};

	const onSelectionChange = (
		gridSelectionModel: GridInputRowSelectionModel,
	) => {
		let gridRowItems = gridSelectionModel as GridRowId[];

		setSelectionModel(gridRowItems);

		if (gridRowItems.length !== accountIdentifiers.size) {
			let identifiers = accountIdentifiers;
			accountIdentifiers.forEach((_, i) => {
				if (!gridRowItems.includes(i)) identifiers.delete(i);
			});

			setAccountIdentifiers(identifiers);
		}
	};

	const getIsDirty = (): boolean => {
		return selectionModel.length > 0 || accountIdentifiers.size > 0;
	};

	const getAccountIdentifier = (id: string): string => {
		return accountIdentifiers.get(id) || '';
	};

	const onAccountIdentifierChange = (key: string, value: string): void => {
		setAccountIdentifiers(
			new Map<string, string>(accountIdentifiers.set(key, value)),
		);
	};

	const getSelectedAccounts = (): Entity4Account[] => {
		return accounts.filter((x) => selectionModel.includes(x.entity4AccountId));
	};

	const handleOnSubmit = async (): Promise<void> => {
		if (currentConnection === null) return;

		try {
			setAddAccountErrors([]);
			setLoading(true);

			let createAccountIntegrations = [] as CreateAccountIntegration[];
			accountIdentifiers.forEach((value, key) => {
				createAccountIntegrations.push({
					connectionId: connection.id,
					accountIdentifier: value,
					entity4AccountId: key,
				} as CreateAccountIntegration);
			});

			const response =
				await customerApiClient.cash4.accountIntegrations.createAccountIntegrations(
					{
						accountIntegrations: createAccountIntegrations,
					},
				);

			if (response.data.data.errors.length) {
				setAddAccountErrors(response.data.data.errors);
			} else {
				onClose();
				handleDiscardEdits();
				await refetch();
			}
		} catch (error: any) {
			setAddAccountErrors([...error]);
		} finally {
			setLoading(false);
		}
	};

	return (
		<ConfirmFormModal
			loading={loading}
			isOpen={isOpen}
			isDirty={getIsDirty()}
			disableSubmit={
				selectionModel.length <= 0 ||
				(innerModalContent === InnerModalContent.AddAccountIdentifiers &&
					Array.from(accountIdentifiers.values()).filter((x) => x.trim() !== '')
						.length !== selectionModel.length)
			}
			modalTitle="Add Accounts"
			cancelModalTitle="Discard Account Integration?"
			cancelModalDescription="Are you sure you want to discard this Account Integration? Your changes will not be saved."
			onSubmit={async () => {
				if (innerModalContent === InnerModalContent.AccountSelection) {
					setInnerModalContent(InnerModalContent.AddAccountIdentifiers);
				} else if (
					accountIdentifiers.size === selectionModel.length &&
					innerModalContent === InnerModalContent.AddAccountIdentifiers
				) {
					await handleOnSubmit();
				}
			}}
			onClose={onClose}
			submitButtonLabel={
				innerModalContent === InnerModalContent.AccountSelection
					? 'Continue'
					: 'Add Accounts'
			}
			error={getAccountsError}
			showBackButton={
				innerModalContent === InnerModalContent.AddAccountIdentifiers
			}
			onBackButtonClick={handleBackButtonClick}
			backButtonDisabled={loading}
			discardEdits={handleDiscardEdits}
			maxWidth="lg"
		>
			<Typography sx={{ marginBottom: '1em' }}>
				Select the accounts to be added under this connection and then provide
				the account identifier for each.
			</Typography>

			{innerModalContent === InnerModalContent.AccountSelection && (
				<AccountSelectionDataGrid
					rows={accounts}
					columns={[
						{
							...GRID_CHECKBOX_SELECTION_COL_DEF,
							renderHeader: (params: GridColumnHeaderParams) => {
								return (
									<GridHeaderCheckbox
										{...params}
										{...stonlyData({
											id: `${connectionsListPagePrefix}-checkbox-selection-${connection.name
												.replaceAll(' ', '-')
												.toLocaleLowerCase()}`,
										})}
									/>
								);
							},
						},
						{
							field: 'displayName',
							headerName: 'Account Display Name',
							type: 'string',
							flex: 1,
						},
						{
							field: 'naturalAccountNumber',
							headerName: 'Natural Account Number',
							type: 'string',
							flex: 1,
						},
						{
							field: 'priorDayStatementsAccountNumber',
							headerName: 'Prior Day Statements Account Number',
							type: 'string',
							flex: 1,
						},
					]}
					rowSelectionModel={selectionModel}
					onSelectionChangeCallBack={onSelectionChange}
					getRowId={(row: Entity4Account) => row.entity4AccountId}
				/>
			)}

			{innerModalContent === InnerModalContent.AddAccountIdentifiers && (
				<AccountIdentifiersDataGrid
					rows={getSelectedAccounts()}
					columns={[
						{
							field: 'displayName',
							headerName: 'Account Display Name',
							type: 'string',
							flex: 1,
						},
						{
							field: 'naturalAccountNumber',
							headerName: 'Natural Account Number',
							type: 'string',
							flex: 1,
						},
						{
							field: 'priorDayStatementsAccountNumber',
							headerName: 'Prior Day Statements Account Number',
							type: 'string',
							flex: 1,
						},
						{
							field: 'accountIdentifier',
							headerName: 'Account Identifier',
							type: 'string',
							flex: 1,
							renderCell: (params: any) => {
								return (
									<T4TextFieldV2
										value={getAccountIdentifier(params.row.entity4AccountId)}
										onChange={(value) => {
											onAccountIdentifierChange(
												params.row.entity4AccountId,
												value,
											);
										}}
										sx={{ marginTop: '4px' }}
										placeholder="Account Identifier"
										{...stonlyData({
											id: `${connectionsListPagePrefix}-account-identifier-${params.row.entity4AccountId}`,
										})}
									/>
								);
							},
						},
					]}
					getRowId={(row: Entity4Account) => row.entity4AccountId}
				/>
			)}
			<T4AlertStack errors={addAccountErrors} />
		</ConfirmFormModal>
	);
};
