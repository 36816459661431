import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useDeleteFolderButton } from 'shared/hooks/useDeleteFolderButton';

export type DeleteFolderButtonProps = {
	open: boolean;
	close: () => void;
};

export const DeleteFolderButton: FC<DeleteFolderButtonProps> = observer(
	({ open, close }) => {
		const { isLoading, onSubmit } = useDeleteFolderButton();

		const handleClose = useCallback(() => {
			close();
		}, [close]);

		const handleSubmit = useCallback(() => {
			onSubmit(handleClose);
		}, [handleClose, onSubmit]);

		return (
			<FormModal
				open={open}
				onClose={() => handleClose()}
				onSubmit={() => handleSubmit()}
				fullWidth
				title={'Delete current folder and all reports in it?'}
				loading={isLoading}
				submitButtonLabel="Delete"
				loadingMessage="Deleting"
			/>
		);
	},
);
