import deepEqual from 'deep-eql';
import { useEffect, useState } from 'react';

export type UseT4ParameterProps<TProperty> = {
	property: TProperty;
};
export function useT4Parameter<TProperty>(
	property: TProperty,
): UseT4ParameterProps<TProperty> {
	const [propertyActual, setPropety] = useState(property);
	useEffect(() => {
		if (!deepEqual(property, propertyActual)) {
			setPropety(property);
		}
	}, [property, propertyActual]);

	return {
		property: propertyActual,
	};
}
