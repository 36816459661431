import { AxiosResponse } from 'axios';
import { Tag } from 'modules/clients/customer-api/src/entity4/tags';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from '../../../../utilities/api';
import {
	EntityConfig,
	EntityType,
	singularToPluralEntity,
} from '../../entity4Constants';
import { IRegisteredAgentDto } from '../../registrations/registrationTypes';
import { FieldDataType } from '../fieldSets/fieldTypes';

export interface IEntityStubDto {
	id: string;
	entityType: keyof typeof singularToPluralEntity;
	parentEntityId?: string;
	parentEntityType?: keyof typeof singularToPluralEntity;
	displayName: string;
	updatedDate: string;
}

export interface IEntityAspectDto {
	id: string;
	name: string;
	urlId: string;
	description: string;
}

export interface IAspectListDto {
	aspects: IEntityAspectDto[];
	availableAspects: IEntityAspectDto[];
}

export interface IEntityHeaderDto {
	name: string;
	tags: Tag[];
}

export interface IListPageDto {
	columns: IListColumnDto[];
	rows: IListRowDto[];
}

export interface IListFieldDto {
	id: string;
	fieldDefinitionId: string;
	identifier: string;
	displayValue?: string;
	approvedStringValue?: string;
	pendingStringValue?: string;
	approvedBooleanValue?: boolean;
	pendingBooleanValue?: boolean;
	approvedDateValue?: string;
	pendingDateValue?: string;
	approvedOptionId?: string;
	pendingOptionId?: string;
}

export interface IListTagsDto {
	tagId: string;
	name: string;
}

export interface IListTaxIdsDto {
	taxIdId: string;
	isPrimaryFederalIncomeTaxId: boolean;
	taxIdNumber: string;
}

export interface IListColumnDto {
	fieldDefinitionId: string;
	identifier: string;
	parentFieldIdentifier?: string;
	columnName: string;
	description: string;
	isDefaultListColumn: boolean;
	sortOrder: number;
	aspectUrlId?: string;
	fieldType: FieldDataType;
	requiresApproval: boolean;
	isRecommendedCreationField: boolean;
	isCreationRequirement: boolean;
	isAspectField: boolean;
	optionListId?: string;
	referenceCollectionName?: string;
}

export interface IListRowDto {
	id: string;
	hasChanges: boolean;
	hasRejections: boolean;
	fields: IListFieldDto[];
	tags: IListTagsDto[];
	taxIds: IListTaxIdsDto[];
}

const FrontendRepository = {
	getEntityHeader(
		entityType: EntityType,
		entityId: string,
	): Promise<AxiosResponse<ApiResponse<IEntityHeaderDto>>> {
		const { controllerRoute } = EntityConfig.get(entityType)!;

		return customerApi
			.get<ApiResponse<IEntityHeaderDto>>(
				`${controllerRoute}/frontend/${entityId}/header`,
			)
			.then((response) => {
				return response;
			});
	},

	getEntityAspects(
		entityId: string,
		entityType: EntityType,
	): Promise<IAspectListDto> {
		const { controllerRoute } = EntityConfig.get(entityType)!;

		return customerApi
			.get<ApiResponse<IAspectListDto>>(
				`${controllerRoute}/frontend/${entityId}/aspects`,
			)
			.then((response) => {
				return response.data.value;
			});
	},

	getRegisteredAgents(entityType: EntityType): Promise<IRegisteredAgentDto[]> {
		const { controllerRoute } = EntityConfig.get(entityType)!;

		return customerApi
			.get<ApiResponse<IRegisteredAgentDto[]>>(
				`${controllerRoute}/frontend/registeredAgents`,
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},

	getListPage(
		entityType: EntityType,
		parentEntityId?: string,
		legalEntityGroupIds?: string[],
	): Promise<IListPageDto> {
		let path = `${
			EntityConfig.get(entityType)!.controllerRoute
		}/frontend/listPage`;

		const queryParams = new URLSearchParams();
		(legalEntityGroupIds ?? []).forEach((id) =>
			queryParams.append('legalEntityGroupIds', id),
		);

		if (parentEntityId) {
			queryParams.append('parentEntityId', parentEntityId);
		}

		return customerApi
			.get<ApiResponse<IListPageDto>>(path, {
				params: queryParams,
			})
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	},
};

export default FrontendRepository;
