import { Chip } from '@mui/material';
import {
	GridRenderCellParams,
	GridTreeNodeWithRender,
	GridValidRowModel,
} from '@mui/x-data-grid-pro';
import moment, { Moment } from 'moment';
import { convertDate, formatReadDate } from 'shared/utilities/dateUtilities';

export function getExpirationDateCellColor(
	date: Moment | null,
): 'error' | 'warning' | undefined {
	return !date
		? undefined
		: date.isBefore(moment())
		? 'error'
		: date.isBefore(moment().add(5, 'days'))
		? 'warning'
		: undefined;
}

// eslint-disable-next-line mobx/missing-observer
export function ExpirationDateRegistrationCell<
	TRow extends GridValidRowModel,
	TValue extends string | Moment,
	TFormattedValue = TValue,
	TGridTreeNodeWithRender extends
		GridTreeNodeWithRender = GridTreeNodeWithRender,
>({
	value,
}: GridRenderCellParams<
	TRow,
	TValue,
	TFormattedValue,
	TGridTreeNodeWithRender
>) {
	const date = convertDate(value);
	const formatedDate = formatReadDate(date);
	const color = getExpirationDateCellColor(date);

	if (date === null) return null;

	return date && date.isAfter(moment().add(1, 'months')) ? (
		formatedDate
	) : (
		<Chip color={color} label={formatReadDate(date)} />
	);
}
