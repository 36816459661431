import { ObjectType } from 'modules/clients/customer-api/src/api/objects';
import { useCallback, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { getObjectInputType } from '../../object/utilities';

export type UseContactsViewProps = {
	loading: boolean;
	deleting: boolean;
	deleteModalOpen: boolean;
	deleteError: string;
	deletingType: string;
	setDeleteModalOpen: (isOpen: boolean) => void;
	deleteCollection: () => Promise<void>;
	setDeleteCollectionId: (id: string) => void;
	setDeletingType: (t: string) => void;
	refetch: () => Promise<void>;
};

export function useContactsView(
	objectId: string,
	objectType: ObjectType,
	refetchObject: () => Promise<void>,
): UseContactsViewProps {
	const { customerApiClient } = useClients();

	const [loading, setLoading] = useState<boolean>(false);

	const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
	const [deleting, setDeleting] = useState<boolean>(false);
	const [deleteError, setDeleteError] = useState<string>('');
	const [deleteCollectionId, setDeleteCollectionId] = useState<string>();
	const [deletingType, setDeletingType] = useState<string>('');

	const refetch = useCallback(async (): Promise<void> => {
		setLoading(true);

		try {
			await refetchObject();
		} finally {
			setLoading(false);
		}
	}, [setLoading, refetchObject]);

	const deleteCollection = useCallback(async (): Promise<void> => {
		if (deleteCollectionId) {
			setDeleting(true);
			const response = await customerApiClient.api.objects.collections.delete({
				objectId: objectId,
				objectType: getObjectInputType(objectType),
				collectionId: deleteCollectionId,
			});
			if (response && response.data && response.data.error) {
				setDeleteError(response.data.error);
			} else if (
				response &&
				response.data &&
				response.data.errors &&
				(Object.values(response.data.errors)?.length ?? 0) > 0
			) {
				setDeleteError(flattenErrors(response.data.errors).join(', '));
			} else {
				refetch().finally(() => {
					setDeleteModalOpen(false);
					setDeleting(false);
				});
			}
		} else {
			setDeleteModalOpen(false);
			setDeleting(false);
		}
	}, [
		deleteCollectionId,
		customerApiClient.api.objects.collections,
		objectId,
		objectType,
		refetch,
	]);

	return {
		loading,
		deleting,
		deleteModalOpen,
		deleteError,
		deletingType,
		setDeleteModalOpen,
		deleteCollection,
		setDeleteCollectionId,
		setDeletingType,
		refetch,
	};
}
