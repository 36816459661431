import { Add } from '@mui/icons-material';
import { Button, CircularProgress, Grid } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4FieldAdornment } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FormModal } from 'shared/components/formModal';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { stonlyData } from 'stonly/functions';
import { useProfileView } from '../../../entityProfile/providers/entityProfileContextProvider';
import { T4AlertStack } from '../../../shared/components/molecules/t4AlertStack';
import { AddJurisdictionViewModel } from '../models/addJurisdictionViewModel';
import { JURISDICTION_FIELD_DESCRIPTIONS } from '../models/jurisdictionModel';

export const addJurisdictionButtonStonlyId = 'jurisdiction-add-button';
export const addJurisdictionButtonTestId = addJurisdictionButtonStonlyId;

interface IAddRegisteredAgentJurisdictionButtonProps {
	viewModel: AddJurisdictionViewModel;
}
export const AddRegisteredAgentJurisdictionButton: React.FC<IAddRegisteredAgentJurisdictionButtonProps> =
	observer(({ viewModel }) => {
		const { setViewType } = useProfileView();
		const [loading, setLoading] = useState(false);
		const submitUploadModal = async () => {
			setLoading(true);
			await viewModel.addJurisdiction(setViewType);
			setLoading(false);
		};
		return (
			<div>
				<FormModal
					open={viewModel.open}
					onClose={viewModel.closeModal}
					title={'Add Jurisdiction'}
					onSubmit={submitUploadModal}
					description={''}
					error={''}
					fullWidth
					loading={loading}
					submitButtonLabel="Add"
					loadingMessage="Adding..."
					submitDisabled={viewModel.loading || viewModel.country === null}
				>
					<Grid container>
						<Grid item xs={12}>
							<T4Autocomplete<ReferenceDataValue, false, false, false>
								label="Country"
								value={viewModel.country}
								options={viewModel.location.countryOptions}
								onSelect={async (country) => {
									await viewModel.setCountry(country);
									viewModel.setState(null);
								}}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) =>
									option.value === value.value
								}
								onFocus={async () => {
									await viewModel.location.loadCountries();
								}}
								loading={viewModel.location.loadingCountries}
								startAdornment={
									<T4FieldAdornment
										description={JURISDICTION_FIELD_DESCRIPTIONS.country}
										t4AdornmentType={
											viewModel.location.loadingCountries ? 'loading' : 'info'
										}
									/>
								}
								required
							/>
							<T4AlertStack error={viewModel.countryError} />
						</Grid>
						<Grid item xs={12}>
							<T4Autocomplete
								label="State/Province"
								value={viewModel.state}
								options={viewModel.location.provinceOptions}
								onSelect={async (state) => {
									await viewModel.setState(state);
								}}
								getOptionLabel={(option) => option.displayName}
								isOptionEqualToValue={(option, value) =>
									option.value === value.value
								}
								loading={viewModel.location.loadingProvinces}
								startAdornment={
									<T4FieldAdornment
										description={JURISDICTION_FIELD_DESCRIPTIONS.state}
										t4AdornmentType={
											viewModel.location.loadingProvinces ? 'loading' : 'info'
										}
									/>
								}
							/>
							<T4AlertStack error={viewModel.location.provinceError} />
						</Grid>
					</Grid>

					<T4AlertStack error={viewModel.error} />
				</FormModal>

				<Button
					data-testid={addJurisdictionButtonTestId}
					{...stonlyData({ id: addJurisdictionButtonStonlyId })}
					variant="outlined"
					onClick={viewModel.openModal}
					startIcon={
						viewModel.loading ? <CircularProgress size={20} /> : <Add />
					}
				>
					Add Jurisdiction
				</Button>
			</div>
		);
	});
