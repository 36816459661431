import { Help, Notifications, Settings } from '@mui/icons-material';
import { Badge, Box, IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useT4Layout } from 'shared/hooks/useT4Layout';
import { useUser } from 'shared/hooks/useUser';
import { stonlyData } from 'stonly/functions';
import T4Logo from '../../images/logos/T4_White_Logo.svg';
import { paths } from '../constants/paths';
import DarkModeButton from './DarkModeButton';

export const stonlyIds = {
	darkModeToggle: 'dark-mode-toggle',
	stonlyLaunchpoint: 'stonly-launchpoint',
	adminMenuLaunchpoint: 'admin-menu-launchpoint',
	notificationsLaunchpoint: 'notifications-launchpoint',
};
export const dataTestIds = {
	stonlyLaunchpoint: 'stonly-launchpoint',
	adminMenuLaunchpoint: 'admin-menu-launchpoint',
};

export interface NavbarProps {
	logo: ReactNode;
}

export const Navbar: FC<NavbarProps> = observer(({ logo }) => {
	const history = useHistory();
	const { notificationsCount } = useT4Layout();
	const { t4DarkModeEnabled } = useT4FeatureFlags();
	const { isApprover, isAuthor } = useUser();

	return (
		<Box
			component="nav"
			sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			{logo}

			<Box display="flex" alignItems="center" justifyContent="between">
				<Box
					sx={{
						marginLeft: 'auto',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					{t4DarkModeEnabled && (
						<Box>
							<DarkModeButton />
						</Box>
					)}
					{(isAuthor || isApprover) && (
						<Tooltip title="Notifications" aria-label="Treasury4 Notifications">
							<IconButton
								{...stonlyData({ id: stonlyIds.notificationsLaunchpoint })}
								size="small"
								color="primary"
								onClick={() =>
									history.push(generatePath(paths.entity4.notifications.href))
								}
								sx={{
									marginRight: '0.25rem',
									'&:hover': {
										backgroundColor: 'rgba(255, 255, 255, 0.15)',
									},
								}}
							>
								<Badge
									badgeContent={notificationsCount}
									color="error"
									invisible={notificationsCount === 0}
								>
									<Notifications
										sx={{
											fill: '#fff',
											height: '1.5rem',
											width: '1.5rem',
										}}
									/>
								</Badge>
							</IconButton>
						</Tooltip>
					)}
					<Box>
						<Tooltip title="Help" aria-label="Stonly Launchpoint">
							<IconButton
								{...stonlyData({ id: stonlyIds.stonlyLaunchpoint })}
								data-testid={dataTestIds.stonlyLaunchpoint}
								size="small"
								color="primary"
								sx={{
									marginRight: '0.25rem',
									'&:hover': {
										backgroundColor: 'rgba(255, 255, 255, 0.15)',
									},
								}}
							>
								<Help
									sx={{
										fill: '#fff',
										height: '1.5rem',
										width: '1.5rem',
									}}
								/>
							</IconButton>
						</Tooltip>
					</Box>
					<Box>
						<Tooltip
							title="Administration"
							aria-label="Treasury4 Administration Dashboard"
						>
							<IconButton
								{...stonlyData({ id: stonlyIds.adminMenuLaunchpoint })}
								data-testid={dataTestIds.adminMenuLaunchpoint}
								size="small"
								color="primary"
								onClick={() =>
									history.push(generatePath(paths.administration.href))
								}
								sx={{
									marginRight: '0.25rem',
									'&:hover': {
										backgroundColor: 'rgba(255, 255, 255, 0.15)',
									},
								}}
							>
								<Settings
									sx={{
										fill: '#fff',
										height: '1.5rem',
										width: '1.5rem',
									}}
								/>
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
				<Tooltip
					title="Solutions Dashboard"
					aria-label="Treasury4 Solutions Dashboard"
				>
					<IconButton
						size="small"
						onClick={() => history.push('/')}
						sx={{
							'&:hover': {
								backgroundColor: 'rgba(255, 255, 255, 0.15)',
							},
						}}
					>
						<Box
							sx={{
								display: 'flex',
								height: '1.5rem',
								width: '1.5rem',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Box
								component="img"
								src={T4Logo}
								alt="Treasury 4 Solutions"
								sx={{
									fill: '#fff',
									alignSelf: 'center',
								}}
							/>
						</Box>
					</IconButton>
				</Tooltip>
			</Box>
		</Box>
	);
});
