import { CheckCircleOutline, InfoOutlined } from '@mui/icons-material';
import { CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { EntityFieldBase } from '../../../../entities/objects/fields/field';
import { Field } from '../../fieldTypes';

interface IInputAdornmentProps {
	onClick: () => void;
}

export const LoadingAdornment: React.FC<IInputAdornmentProps> = ({
	onClick,
}) => (
	<InputAdornment position="start">
		<IconButton onClick={onClick} size="small">
			<CircularProgress size={20} />
		</IconButton>
	</InputAdornment>
);

export const SuccessAdornment: React.FC<IInputAdornmentProps> = ({
	onClick,
}) => (
	<InputAdornment position="start">
		<IconButton onClick={onClick} size="small">
			<CheckCircleOutline color="primary" />
		</IconButton>
	</InputAdornment>
);

export const InfoAdornment: React.FC<IInputAdornmentProps> = ({ onClick }) => (
	<InputAdornment position="start">
		<IconButton onClick={onClick} size="small">
			<InfoOutlined />
		</IconButton>
	</InputAdornment>
);

interface IEntityFieldAdornmentProps {
	fieldDefinition: Field;
	field?: EntityFieldBase;
	onClick: () => void;
}
export const EntityFieldAdornment: React.FC<IEntityFieldAdornmentProps> = observer(
	({ fieldDefinition, field, onClick }) => {
		if (field?.loading) {
			return <LoadingAdornment onClick={onClick} />;
		} else if (field?.loadingSuccess) {
			return <SuccessAdornment onClick={onClick} />;
		} else if (fieldDefinition.description) {
			return <InfoAdornment onClick={onClick} />;
		}

		return null;
	}
);
