import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import {
	AdminViewModeContext,
	AdminViewModes,
} from './contexts/adminViewModeContext';

export const AdminProvider: FC = observer(({ children }) => {
	const [viewMode, setViewMode] = useState(AdminViewModes.default);

	return (
		<AdminViewModeContext.Provider
			value={{
				viewMode: viewMode,
				setViewMode: setViewMode,
			}}
		>
			{children}
		</AdminViewModeContext.Provider>
	);
});
