import {
	ClickAwayListener,
	Drawer,
	DrawerProps,
	Grid,
	Toolbar,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';

export type C4DrawerProps = Omit<DrawerProps, 'title'> & {
	title?: ReactNode;
	actions?: {
		start?: ReactNode[];
		end?: ReactNode[];
	};
	onClickAway?: () => void;
};

export const C4Drawer: FC<C4DrawerProps> = observer(
	({
		title,
		actions,
		children,
		variant = 'temporary',
		anchor = 'right',
		onClickAway,
		...rest
	}) => {
		return (
			<Drawer {...rest} anchor={anchor} variant={variant}>
				<Toolbar variant="dense" />
				<ClickAwayListener onClickAway={() => onClickAway?.()}>
					<Grid
						container
						sx={{
							height: '100%',
							gap: 2,
							padding: '1.5rem',
							paddingRight: 0,
							flexDirection: 'column',
							flexWrap: 'nowrap',
						}}
					>
						{title && (
							<Grid item xs="auto" sx={{ paddingRight: '1.5rem' }}>
								{title}
							</Grid>
						)}
						<Grid
							container
							item
							xs={true}
							sx={{ paddingRight: '1.5rem', overflowY: 'auto' }}
						>
							{children}
						</Grid>
						{actions &&
							((actions.start && actions.start.length > 0) ||
								(actions.end && actions.end.length > 0)) && (
								<Grid
									container
									item
									xs="auto"
									sx={{
										paddingRight: '1.5rem',
										gap: 1,
										justifyContent:
											((actions?.start?.length ?? 0) > 0 &&
												(actions?.end?.length ?? 0) > 0) ||
											(actions?.start?.length ?? 0)
												? 'space-between'
												: 'flex-end',
									}}
								>
									{actions.start && actions.start.length > 0 && (
										<Grid container item xs="auto" sx={{ gap: 1 }}>
											{actions.start.map((action, index) => (
												<Grid
													key={`drawer-start-action-${index}`}
													item
													xs="auto"
												>
													{action}
												</Grid>
											))}
										</Grid>
									)}
									{actions.end && actions.end.length > 0 && (
										<Grid container item xs="auto" sx={{ gap: 1 }}>
											{actions.end.map((action, index) => (
												<Grid key={`drawer-end-action-${index}`} item xs="auto">
													{action}
												</Grid>
											))}
										</Grid>
									)}
								</Grid>
							)}
					</Grid>
				</ClickAwayListener>
			</Drawer>
		);
	},
);
