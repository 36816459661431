import { OpenInNewOutlined } from '@mui/icons-material';
import {
	Divider,
	Grid,
	ListItemIcon,
	MenuItem,
	Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { DOCUMENT_STATUSES } from 'features/entity4/globalDocuments/shared/documentConstants';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { FC, useMemo, useRef } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { T4AddButton } from 'shared/components/buttons';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { ObjectPathParams, paths } from 'shared/constants/paths';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import {
	getDateColumnDefinition,
	getSingleSelectGridColDef,
} from 'shared/utilities/dataGrid/columnDefinitions';
import {
	USER_PREFERENCES_FIELD_OPTIONS,
	getOptionsMenuColDef,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { T4Link } from '../../../../shared/components/t4Link';
import { useUser } from '../../../../shared/hooks/useUser';
import { EntityType } from '../../entity4Constants';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { displayDocument } from '../documentUtils';
import { Document } from '../models/document';
import DocumentListViewModel from '../models/documentListViewModel';
import { DocumentViewModel } from '../models/documentViewModel';
import { DeleteDocumentModal } from './deleteDocumentModal';
import { UploadDocumentModal } from './uploadDocumentModal';

export interface DocumentListItem {
	menu: Document;
	id: string;
	name: string;
	type?: string;
	subObjectDisplayName?: string;
	status?: string;
	expirationDate?: Moment;
	uploadedByName?: string;
	uploadedDate?: Moment;
}

export type DocumentListViewProps = {
	viewModel: DocumentListViewModel;
	autoHeight?: true;
};

export const DocumentListView: FC<DocumentListViewProps> = observer(
	({ viewModel, autoHeight = false }) => {
		const { objectType, objectId } = useParams<ObjectPathParams>();
		const { isAuthor } = useUser();
		const { setViewType } = useProfileView();
		const handleError = useErrorHandler();

		const headerRef = useRef<HTMLDivElement>(null);
		const documentViewModel = useMemo(
			() =>
				new DocumentViewModel(
					handleError,
					viewModel.getObjectType(),
					viewModel.getObjectId(),
				),
			[handleError, viewModel],
		);

		documentViewModel.subObjectId = viewModel.getSubobjectId();
		documentViewModel.subObjectType = viewModel.getSubobjectType();

		const columns = (
			[
				{
					...getOptionsMenuColDef(
						(params: GridRenderCellParams<DocumentListItem, Document>) => {
							if (!params) {
								return null;
							}

							return (
								<OverflowMenu id={`document-overflow-menu-${params.row.id}`}>
									<MenuItem
										onClick={() => {
											setViewType(FieldViews.default);
											viewModel.navigateToDocument(params.row.id);
										}}
									>
										View
									</MenuItem>
									{isAuthor && (
										<MenuItem
											onClick={() => {
												setViewType(FieldViews.edit);
												viewModel.navigateToDocument(params.row.id);
											}}
										>
											Edit
										</MenuItem>
									)}
									{isAuthor && <Divider />}
									{isAuthor && (
										<MenuItem
											onClick={() => {
												displayDocument(params.row.menu, objectType, objectId);
											}}
										>
											<ListItemIcon>
												<OpenInNewOutlined />
											</ListItemIcon>{' '}
											View File
										</MenuItem>
									)}
									{isAuthor && <Divider />}
									{isAuthor && (
										<DeleteMenuItem
											onClick={() =>
												viewModel.deleteDocumentViewModel.open(
													params.row.menu,
													viewModel.getObjectType(),
													viewModel.getObjectId(),
												)
											}
										/>
									)}
								</OverflowMenu>
							);
						},
					),
				},
				{
					field: 'name',
					headerName: 'Name',
					flex: 50,
					minWidth: 150,
					sortable: true,
					renderCell: (params: GridRenderCellParams) => {
						const path = generatePath(
							paths.entity4.objects.object.documents.document.href,
							{
								objectType: objectType,
								objectId: objectId,
								documentId: params.id,
							},
						);

						return (
							<T4Link to={path} color="secondary">
								{params.formattedValue}
							</T4Link>
						);
					},
				},
				{
					...getSingleSelectGridColDef(viewModel.types),
					field: 'type',
					headerName: 'Type',
					flex: 50,
					minWidth: 150,
					sortable: true,
				},
				!viewModel.getSubobjectId() &&
					viewModel.getObjectType() !== EntityType.Subaccount && {
						field: 'subObjectDisplayName',
						headerName: 'Area',
						flex: 35,
						minWidth: 150,
						sortable: true,
					},
				{
					...getSingleSelectGridColDef(DOCUMENT_STATUSES),
					field: 'status',
					headerName: 'Status',
					flex: 20,
					minWidth: 150,
					sortable: true,
				},
				{
					...getDateColumnDefinition(),
					field: 'expirationDate',
					headerName: 'Expiration Date',
					flex: 20,
					minWidth: 150,
					sortable: true,
				},
				{
					field: 'uploadedByName',
					headerName: 'Uploaded By',
					flex: 20,
					minWidth: 150,
					sortable: true,
				},
				{
					...getDateColumnDefinition(),
					field: 'uploadedDate',
					headerName: 'Uploaded Date',
					flex: 20,
					minWidth: 150,
					sortable: true,
				},
			] as (GridColDef | boolean)[]
		).filter((x) => x !== false) as GridColDef[];

		return (
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ gap: '16px', height: '100%' }}
			>
				<Grid
					ref={headerRef}
					container
					item
					xs="auto"
					sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}
				>
					<Grid item xs="auto">
						<Typography variant={viewModel.getSubobjectType() ? 'h4' : 'h3'}>
							{viewModel.getTitle()}
						</Typography>
					</Grid>
					{isAuthor && (
						<Grid item xs="auto">
							<T4AddButton
								label="Add Document"
								onClick={() =>
									viewModel.uploadDocumentViewModel.open(
										viewModel.getObjectType(),
										viewModel.getObjectId(),
										viewModel.getSubobjectType(),
										viewModel.getSubobjectId(),
										setViewType,
									)
								}
							/>
						</Grid>
					)}
				</Grid>
				<Grid
					item
					xs
					sx={{
						height: `${
							autoHeight !== undefined
								? 'auto'
								: `calc(${(headerRef?.current?.clientHeight ?? 0) - 16}px)`
						}`,
						minHeight: '40vh',
					}}
				>
					<UserPreferencesDataGrid
						stonlyId="documents"
						tableKey={objectType.toString() + 'DocumentListView'}
						columns={columns}
						rows={viewModel.getDocuments()}
						loading={viewModel.isLoading()}
						emptyMessage="No Documents found"
						autoHeight={autoHeight}
						showToolbar
						initialState={{
							sorting: {
								sortModel: [
									{
										field: 'type',
										sort: 'asc',
									},
									{
										field: 'name',
										sort: 'asc',
									},
									{
										field: 'uploadedDate',
										sort: 'desc',
									},
								],
							},
							pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
						}}
					/>
				</Grid>
				<UploadDocumentModal viewModel={viewModel.uploadDocumentViewModel} />
				<DeleteDocumentModal viewModel={viewModel.deleteDocumentViewModel} />
			</Grid>
		);
	},
);
