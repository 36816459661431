import { IEntityFieldApiData } from '../../entitiesApiTypes';
import { EntityRepository } from '../../entityRepository';
import { EntityCollection } from '../entityCollection';
import { EntityFieldBase } from './field';

export class CollectionField extends EntityFieldBase {
	private _collection: EntityCollection;

	private _entityRepository: EntityRepository;

	constructor(
		data: IEntityFieldApiData,
		collection: EntityCollection,
		created?: boolean,
		entityRepository?: EntityRepository
	) {
		super(data, created);
		this._collection = collection;

		this._entityRepository =
			entityRepository ??
			new EntityRepository(collection.entity.entityType, collection.entity.id);
	}

	public save(value: any): Promise<void> {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		return this._entityRepository
			.v2UpdateCollectionField(this._collection.id, this.id, value)
			.then((collectionFieldData) => {
				this.updateData(collectionFieldData);
			})
			.catch((error) => (this.loadingErrorMessage = error))
			.finally(() => {
				this.loading = false;
				this.loadingSuccess = true;
			});
	}

	public savePhoneNumber(
		countryCode: string | null = null,
		number: string | null = null,
		extension: string | null = null
	): Promise<void> {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		return this._entityRepository
			.v2UpdatePhoneNumberCollectionField(
				this._collection.id,
				this.id,
				countryCode,
				number,
				extension
			)
			.then((value) => {
				this.updateData(value);
			})
			.catch((error) => (this.loadingErrorMessage = error))
			.finally(() => {
				this.loading = false;
				this.loadingSuccess = true;
			});
	}

	approve() {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		return this._entityRepository
			.approveField(this.id!)
			.then((data) => this.updateData(data))
			.catch((error) => (this.loadingErrorMessage = error))
			.finally(() => {
				this.loading = false;
				this.loadingSuccess = true;
			});
	}
	reject(comment: string) {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		return this._entityRepository
			.rejectField(this.id!, comment)
			.then((data) => this.updateData(data))
			.catch((error) => (this.loadingErrorMessage = error))
			.finally(() => {
				this.loading = false;
				this.loadingSuccess = true;
			});
	}
	unSubmit() {
		this.loading = true;
		this.loadingSuccess = false;
		this.loadingErrorMessage = '';
		return this._entityRepository
			.unsubmitField(this.id!)
			.then((data) => this.updateData(data))
			.catch((error) => (this.loadingErrorMessage = error))
			.finally(() => {
				this.loading = false;
			});
	}
}
