import {
	Connector,
	ConnectorsResponse,
} from 'modules/clients/customer-api/src/cash4/connectors';
import { useState } from 'react';
import { isValid } from 'shared/components/formModal';
import { useClients } from 'shared/hooks/useClients';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { FormConnectionParameter } from '../components/connectionForm';

export type UseCreateConnectionProps = {
	loading: boolean;
	error: string | undefined;
	errors: string[];
	connectors: Connector[];
	connector: Connector | undefined;
	connectionName: string;
	connectionParameters: FormConnectionParameter[];
	disableSubmit: boolean;
	isDirty: boolean;
	setConnector: (connector: Connector | null) => void;
	setConnectionName: (name: string | undefined) => void;
	setConnectionParameter: (key: string, value: string | undefined) => void;
	create: () => Promise<boolean>;
	open: () => void;
	clear: () => void;
};

export function useCreateConnection(): UseCreateConnectionProps {
	const { customerApiClient } = useClients();

	const [loading, _setLoading] = useState(false);
	const [error, _setError] = useState<string | undefined>();
	const [errors, _setErrors] = useState<string[]>([]);

	const [connectors, _setConnectors] = useState<Connector[]>([]);
	const [connector, _setConnector] = useState<Connector | undefined>();
	const [connectionName, _setConnectionName] = useState<string>('');
	const [parameters, _setParameters] = useState<FormConnectionParameter[]>([]);

	async function load(): Promise<void> {
		_setLoading(true);
		_setError(undefined);
		_setErrors([]);
		setConnector(null);

		try {
			const response: ConnectorsResponse =
				await customerApiClient.cash4.connectors.connectors();

			if (response.data.success) {
				_setConnectors(response.data.data ?? []);
				_setDefaultConnector();
			} else {
				throw new Error();
			}
		} catch (error: any) {
			_setError('Unable to load connectors.');
		} finally {
			_setLoading(false);
		}
	}

	async function create(): Promise<boolean> {
		if (connector !== undefined && connectionName !== '') {
			try {
				_setLoading(true);
				_setError(undefined);

				const response =
					await customerApiClient.cash4.connections.createConnection({
						connectorId: connector.id,
						name: connectionName,
						parameters: Object.assign(
							{},
							...parameters
								.slice()
								.map((x) => ({ [x.key]: x.value?.trim() ?? null })),
						),
					});

				if (response.data.success) {
					return true;
				} else if (response.status === 500) {
					_setError('An unexpected error occurred');
				} else if (response.data.error) {
					_setError(response.data.error);
				} else if (
					response.data.errors &&
					Object.entries(response.data.errors).length > 0
				) {
					_setErrors(flattenErrors(response.data.errors));
				} else if (response.data.success) {
					throw new Error();
				}
			} catch (error: any) {
				_setError('Unable to create connection.');
			} finally {
				_setLoading(false);
			}
		}

		return false;
	}

	function open(): void {
		load();
	}

	function clear(): void {
		_setDefaultConnector();
		_setParameters([]);
	}

	function setConnector(connector: Connector | null): void {
		_setConnector(connector ?? undefined);
		_setParameters([]);
		if (connector !== null) {
			_setParameters(
				connector.parameters.map((x) => ({
					key: x.key,
					value: null,
					isValueOptional: x.isValueOptional,
				})),
			);

			if (connectionName === '') {
				_setConnectionName(connector?.name ?? '');
			}
		} else {
			_setParameters([]);
		}
	}

	function setConnectionName(name: string | undefined): void {
		_setConnectionName(name ?? '');
	}

	function setConnectionParameter(
		key: string,
		value: string | undefined,
	): void {
		const index = parameters.findIndex((x) => x.key === key);
		if (index !== -1) {
			const newParameters = parameters.slice();
			newParameters[index].value = !isStringUndefinedOrNullOrWhitespace(value)
				? value!
				: null;
			_setParameters(newParameters);
		}
	}

	function isSubmitDisabled() {
		return (
			loading || !connector || !isValid(connectionName) || !areParametersValid()
		);
	}

	function isDirty() {
		return (
			connector !== undefined ||
			connectionName !== '' ||
			parameters.some((x) => x.value !== null)
		);
	}

	//#region Private Methods

	function areParametersValid() {
		return parameters
			.filter((x) => !x.isValueOptional)
			.every((x) => isValid(x.value));
	}

	function _setDefaultConnector(): void {
		if (connectors.length === 1) {
			setConnector(connectors[0]);
			setConnectionName(connectors[0].name);
		} else {
			setConnector(null);
			setConnectionName('');
		}
	}

	//#endregion

	return {
		loading,
		error,
		errors,
		connectors,
		connector,
		connectionName,
		connectionParameters: parameters.slice(),
		disableSubmit: isSubmitDisabled(),
		isDirty: isDirty(),
		setConnector,
		setConnectionName,
		setConnectionParameter,
		create,
		open,
		clear,
	};
}
