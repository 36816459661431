import { LocationModel } from 'features/entity4/shared/models/locationModel';
import CommonReferenceStore, {
	ReferenceStore,
} from 'features/entity4/shared/referenceStore';
import { flow, makeAutoObservable } from 'mobx';
import { parseError } from 'utilities/errors/errorUtils';
import { FrontendRegistrationRepository } from '../../frontendRegistrationRepository';

export class CreateRegistrationViewModel {
	private _entityId: string;
	public location: LocationModel;
	private _frontendRegistrationRepository: FrontendRegistrationRepository;

	private _error?: string;
	private _creating: boolean = false;
	private _isOpen: boolean = false;

	constructor(
		entityId: string,
		frontendRegistrationRepository: FrontendRegistrationRepository,
		referenceStore: ReferenceStore = CommonReferenceStore
	) {
		makeAutoObservable(this);

		this._entityId = entityId;
		this.location = new LocationModel(referenceStore);
		this._frontendRegistrationRepository = frontendRegistrationRepository;
	}

	public get entityId(): string {
		return this._entityId;
	}

	public get isOpen(): boolean {
		return this._isOpen;
	}

	public get creating(): boolean {
		return this._creating;
	}

	public get error(): string | undefined {
		return this._error;
	}

	public openModal() {
		this._isOpen = true;
	}

	public closeModal() {
		this._isOpen = false;
		this.location.clearSelections();
	}

	public isCreateButtonDisabled(): boolean {
		return this.location.currentCountry === undefined;
	}

	public create = flow<string | undefined, []>(function* (
		this: CreateRegistrationViewModel
	) {
		try {
			this._error = undefined;
			this._creating = true;
			const { currentCountry, currentProvince } = this.location;
			if (currentCountry) {
				var response = yield this._frontendRegistrationRepository.createRegistration(
					this._entityId,
					{
						country: currentCountry,
						stateProvince: currentProvince,
					}
				);
				return response.value;
			}
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._creating = false;
		}
	});
}
