import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from '../../../utilities/api';
import { EntityConfig, EntityType, SubObjectType } from '../entity4Constants';
import { IDocumentDto } from './documentTypes';

export class DocumentRepository {
	public async create(
		entityType: EntityType,
		entityId: string,
		item: Partial<Omit<IDocumentDto, 'documentId'>>,
		file?: File,
		subObjectId?: string,
		subObjectType?: SubObjectType
	) {
		const { controllerRoute } = EntityConfig.get(entityType)!;
		const formData = new FormData();
		if (file) {
			formData.append('file', file);
		}
		formData.append(
			'viewModel',
			JSON.stringify({
				...item,
			})
		);

		const response = subObjectId
			? await customerApi.post<ApiResponse<IDocumentDto>>(
					`${controllerRoute}/${entityId}/documents/${subObjectType}/${subObjectId}`,
					formData
			  )
			: await customerApi.post<ApiResponse<IDocumentDto>>(
					`${controllerRoute}/${entityId}/documents`,
					formData
			  );

		if (!response.data.success) {
			throw response.data.error;
		}
		return response.data.value;
	}

	public async update(
		entityType: EntityType,
		entityId: string,
		item: IDocumentDto
	) {
		const { controllerRoute } = EntityConfig.get(entityType)!;
		const response = await customerApi.patch<ApiResponse<IDocumentDto>>(
			`${controllerRoute}/${entityId}/documents/${item.documentId}`,
			item
		);

		if (!response.data.success) {
			throw response.data.error;
		}

		return response.data.value;
	}

	public async delete(
		entityType: EntityType,
		entityId: string,
		item: IDocumentDto
	) {
		const { controllerRoute } = EntityConfig.get(entityType)!;
		const response = await customerApi.delete<ApiResponse<boolean>>(
			`${controllerRoute}/${entityId}/documents/${item.documentId}`
		);
		if (!response.data.success) {
			throw response.data.error;
		}
	}

	public async getDocumentById(
		entityType: EntityType,
		entityId: string,
		documentId: string
	) {
		const { controllerRoute } = EntityConfig.get(entityType)!;
		const response = await customerApi.get<ApiResponse<IDocumentDto>>(
			`${controllerRoute}/${entityId}/documents/${documentId}`
		);

		if (!response.data.success) {
			throw response.data.error;
		}

		return response.data.value;
	}

	public async getDocumentsByEntityId(
		entityType: EntityType,
		entityId: string,
		subObjectId?: string,
		subObjectType?: SubObjectType
	) {
		const { controllerRoute } = EntityConfig.get(entityType)!;
		const response = subObjectId
			? await customerApi.get<ApiResponse<Array<IDocumentDto>>>(
					`${controllerRoute}/${entityId}/documents/${subObjectType}/${subObjectId}`
			  )
			: await customerApi.get<ApiResponse<Array<IDocumentDto>>>(
					`${controllerRoute}/${entityId}/documents`
			  );

		if (!response.data.success) {
			throw response.data.error;
		}

		return response.data.value;
	}

	public async download(
		entityType: EntityType,
		entityId: string,
		documentId: string
	) {
		const { controllerRoute } = EntityConfig.get(entityType)!;
		const response = await customerApi.get<BlobPart>(
			`${controllerRoute}/${entityId}/documents/${documentId}/download`,
			{
				responseType: 'blob',
			}
		);

		if (response.status !== 200) {
			throw response.statusText;
		}

		return response.data;
	}
}

export const CommonDocumentRepository = new DocumentRepository();
