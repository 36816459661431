import * as go from 'gojs';

//The below Layout is provided from GoJS support.
export class EntityViewLayout extends go.TreeLayout {
	// Decide whether a node belongs on the left side or on the right side of a bus alignment.
	isLeftSide(node: go.Node) {
		return node.data.paymentDirection === 'incoming';
	}

	assignTreeVertexValues(v: go.TreeVertex) {
		if (v.alignment === go.TreeLayout.AlignmentBus) {
			// Separate out the nodes that belong on the left side and those that belong on the right side.
			const left: Array<go.TreeVertex> = [];
			const right: Array<go.TreeVertex> = [];
			v.children.forEach((c: go.TreeVertex) => {
				if (!c.node) return;
				if (this.isLeftSide(c.node)) {
					left.push(c);
				} else {
					right.push(c);
				}
			});
			// The TreeVertex.children Array must hold child vertexes in alternating left-right order.
			// Add dummy vertexes on sides where they are unbalanced.
			const both: Array<go.TreeVertex> = [];
			while (left.length > 0 || right.length > 0) {
				if (left.length > 0) both.push(left.shift()!);
				else both.push(new go.TreeVertex(this.network!));
				if (right.length > 0) both.push(right.shift()!);
				else both.push(new go.TreeVertex(this.network!));
			}
			v.children = both;
		}
	}

	// Override setting fromSpot on TreeVertexes that have Bus alignment so that
	// the links to nodes on the left are separated slightly from links to nodes on the right.
	setPortSpots(v: go.TreeVertex) {
		if (v.alignment === go.TreeLayout.AlignmentBus) {
			v.children.forEach((c: go.TreeVertex) => {
				// this assumes that the effective this.treePath is from parent to child
				const e = c.sourceEdges.first();
				if (!e) return;
				const link = e.link;
				if (!link) return;
				// this assumes v.angle === 90
				if (this.isLeftSide(c.node as go.Node)) {
					link.fromSpot = new go.Spot(0.5, 1, -5, 0);
					link.toSpot = go.Spot.Right;
				} else {
					link.fromSpot = new go.Spot(0.5, 1, 5, 0);
					link.toSpot = go.Spot.Left;
				}
			});
		} else {
			//@ts-ignore
			super.setPortSpots(v);
		}
	}
}
