import { AxiosInstance } from 'axios';
import { Read, T4ApiResponse } from 'modules/clients/types';
import { Address, E4ObjectBase } from '..';
import { Account } from '../accounts';

// #region Models

export type LegalEntity = E4ObjectBase & {
	anglicizedLegalName: string | null;
	operatingAddress: Address | null;
};

// #endregion

export type LegalEntityEndpoints = {
	getAll: Read<T4ApiResponse<LegalEntity[]>>;
	getAccountsOwned: Read<T4ApiResponse<Account[]>, string>;
};

export function legalEntities(
	axiosInstance: AxiosInstance,
): LegalEntityEndpoints {
	return {
		getAll: async (_, config = {}) => {
			return await axiosInstance.get('entity4/legalentities', {
				...config,
			});
		},
		getAccountsOwned: async (id, config = {}) => {
			return await axiosInstance.get(`entity4/legalentities/${id}/accounts`, {
				...config,
			});
		},
	};
}
