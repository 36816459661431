import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from '../../../utilities/api';
import { EntityTypeId } from '../entity4Constants';
import { Option } from 'shared/types/referenceDataTypes';

export class CustomerFieldsRepository {
	public getOptionsByOptionListSourceIdentifier = (
		sourceIdentifier: string,
	): Promise<Option[]> => {
		return customerApi
			.get<ApiResponse<Option[]>>(`/Fields/optionlist/${sourceIdentifier}`)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw new Error('Unable to load options.');
			});
	};

	public getAddressTypesByEntityTypeId = (
		entityTypeId: EntityTypeId,
	): Promise<Option[]> => {
		return customerApi
			.get<ApiResponse<Option[]>>(
				`/Fields/optionlist/addresstype?entityTypeId=${entityTypeId}`,
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw new Error('Unable to load options.');
			});
	};
}

export const CommonCustomerFieldsRepository = new CustomerFieldsRepository();
