import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';

export type NotesFieldProps = {
	defaultValue?: string;
	disabled?: boolean;
};

export const NotesField: FC<NotesFieldProps> = observer(
	({ defaultValue, disabled }) => {
		const { notes, setNotes } = useReconciliationsContext();

		const [_notes, _setNotes] = useState<string | undefined>(
			defaultValue || notes,
		);

		return (
			<T4TextFieldV2
				label="Notes"
				disabled={disabled}
				minRows={3}
				maxRows={3}
				multiline
				value={_notes}
				onChange={(value) => _setNotes(value ?? undefined)}
				onBlur={() => setNotes(_notes)}
				helperText={`${_notes?.length ?? 0}/2048`}
			/>
		);
	},
);
