import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { GlobalDocumentPage } from './pages/globalDocumentPage';
import { GlobalDocumentsPage } from './pages/globalDocumentsPage';

export const GlobalDocumentRoutes: FC = observer(() => (
	<Switch>
		<Route exact path={paths.entity4.documents.href}>
			<GlobalDocumentsPage />
		</Route>
		<Route
			exact
			path={paths.entity4.documents.document.href.concat(validIdRegex)}
		>
			<GlobalDocumentPage />
		</Route>

		<Route>
			<CannotDisplay
				headingText={NOT_FOUND_MESSAGING.HEADING}
				bodyText={NOT_FOUND_MESSAGING.BODY}
				imageSrc={NOT_FOUND_MESSAGING.IMAGE}
				buttonText={RETURN_TO_HOME}
				buttonHref={paths.root.href}
			/>
		</Route>
	</Switch>
));
