import { useContext } from 'react';
import {
	ApplicationConfigurationContext,
	ApplicationConfigurationContextProps,
} from 'shared/providers/contexts/applicationConfigurationContext';

export function useApplicationConfiguration(): ApplicationConfigurationContextProps {
	const configuraiton = useContext(ApplicationConfigurationContext);

	return {
		...configuraiton,
	};
}
