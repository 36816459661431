import { AxiosInstance } from 'axios';
import { Read, T4Response } from 'modules/clients/types';

export type FieldChange = {
	objectId: string;
	objectType: string;
	parentObjectId?: string;
	parentObjectType?: string;
	objectName: string;
	fieldName: string;
	dateChanged: string;
	changedBy: string;
};

export type RejectedChange = FieldChange & {
	rejectedBy: string;
};

export type NotificationsResult = {
	pendingApprovals: FieldChange[];
	rejections: RejectedChange[];
};
export type NotificationsResponse = T4Response<NotificationsResult>;

export type FrontendEndpoints = {
	notifications: Read<NotificationsResult>;
};

export function frontend(axiosInstance: AxiosInstance): FrontendEndpoints {
	return {
		notifications: async (_, config): Promise<NotificationsResponse> =>
			await axiosInstance.get('api/frontend/notifications', {
				...config,
			}),
	};
}
