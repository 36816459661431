import { Box } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { FC, useEffect, useMemo, useState } from 'react';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { useProfileView } from '../../entityProfile/providers/entityProfileContextProvider';
import { T4FieldAdornment } from '../../shared/components/molecules/t4FieldAdornment';
import { FieldViews } from '../../shared/fieldSets/fieldViews/fieldViewTypes';
import CommonReferenceRepository from '../../shared/repositories/referenceRepository';
import { useFieldView } from '../object/hooks/useFieldView';
import { Collection } from '../object/models/collection';
import { ObjectField } from '../object/models/objectField';
import { T4Object } from '../object/models/t4Object';
import { ViewType, getReferenceValue } from '../object/utilities';
import { VisibilityOffIcon } from './visibilityOffIcon';
import { useHeader } from 'shared/providers/contexts/headerContext';

export type ReferenceFieldViewProps = {
	object: T4Object;
	field: Field;
	fieldViewType: ViewType;
	collection: Collection | undefined;
};

export const ReferenceFieldView: FC<ReferenceFieldViewProps> = observer(
	({ object, field, fieldViewType, collection }) => {
		const { viewType, objectAspects } = useProfileView();
		const { updateLastAutoSave } = useHeader();
		const {
			isReadOnly,
			hasPermission,
			showVisibilityIcon,
			error,
			warning,
			label,
			adornmentState,
			value,
			update,
		} = useFieldView<ReferenceDataValue>(
			object,
			field,
			fieldViewType,
			updateLastAutoSave,
			collection,
		);

		const [isLoading, setIsLoading] = useState<boolean>(false);
		const [optionsError, setOptionsError] = useState<string | undefined>();
		const [options, setOptions] = useState<ReferenceDataValue[]>([]);
		const parentField = useMemo<ObjectField | undefined>(() => {
			return object?.fields.find(
				(x) => x.identifier === (field.parentFieldIdentifier ?? ''),
			);
		}, [field.parentFieldIdentifier, object?.fields]);
		const parentFieldError = useMemo<string | undefined>(() => {
			if (parentField) {
				if (
					options?.find((x) => x.identifier === value?.identifier) === undefined
				) {
					const parentFieldDefition = objectAspects
						.map((x) => x.aspect)
						.flatMap((x) => x.tabs)
						.flatMap((x) => x.fieldGroups)
						.flatMap((x) => x.fields)
						.find((x) => x.identifier === parentField.identifier);

					if (value) {
						return `${field.name} does not match the selected ${parentFieldDefition?.name}. Please update.`;
					}
				}
			}

			return undefined;
		}, [field.name, objectAspects, options, parentField, value]);

		useEffect(() => {
			async function getOptions() {
				let options: ReferenceDataValue[] = [];

				setIsLoading(true);
				if (field.referenceCollectionName) {
					try {
						options = await CommonReferenceRepository.getCollection(
							field.referenceCollectionName,
						);

						if (parentField && parentField.approvedValue) {
							const approvedValue = getReferenceValue(
								parentField.approvedValue,
							);
							if (approvedValue) {
								options = options.filter(
									(x) => x.parentIdentifier === approvedValue.identifier,
								);
							}
						}
					} catch {
						setOptionsError('Failed to load options.');
					} finally {
						setOptions(options ?? []);
						setIsLoading(false);
					}
				}
			}
			getOptions();
		}, [
			field.referenceCollectionName,
			parentField,
			parentField?.approvedValue,
		]);

		return (
			<Box>
				<T4Autocomplete<ReferenceDataValue, false, boolean, false>
					id={field.id}
					label={label}
					options={options}
					getOptionLabel={(option) => option.displayName}
					isOptionEqualToValue={(a, b) => a?.identifier === b?.identifier}
					value={value ?? null}
					onChange={(_, option) => update(option ?? undefined)}
					disabled={!hasPermission}
					startAdornment={
						<T4FieldAdornment
							id={field.id}
							hasPermission={hasPermission}
							t4AdornmentType={
								(optionsError?.length ?? 0) > 0
									? 'error'
									: isLoading
									? 'loading'
									: adornmentState
							}
							title={field.name}
							description={field.description}
							iconButtonProps={{
								...(viewType === FieldViews.default
									? {
											sx: {
												marginLeft: '-5px',
											},
									  }
									: {}),
							}}
						/>
					}
					textFieldProps={{
						variant: viewType === FieldViews.default ? 'standard' : 'outlined',
						InputProps: {
							sx: {
								...(viewType === FieldViews.default
									? {
											padding: '0rem !important',
									  }
									: {}),
								paddingRight:
									viewType === FieldViews.default
										? '0px !important'
										: '14px !important',
							},
							endAdornment: showVisibilityIcon ? (
								<VisibilityOffIcon />
							) : undefined,
							...(viewType === FieldViews.default
								? {
										disableUnderline: true,
								  }
								: {}),
						},
					}}
					required={field.isCreationRequirement}
					disableClearable={field.isCreationRequirement}
					readOnly={isReadOnly}
				/>
				<T4AlertStack error={error || parentFieldError} warning={warning} />
			</Box>
		);
	},
);
