import { Tag } from 'modules/clients/customer-api/src/entity4/tags';
import { useSnackbar } from 'notistack';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { TagsContext } from './contexts/tagsContext';

export type TagsProviderProps = {
	children: ReactNode;
};

export const TagsProvider: FC<TagsProviderProps> = ({ children }) => {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const [initializing, setInitializing] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(true);
	const [tags, setTags] = useState<Tag[]>([]);
	const refetchTags = useCallback(async () => {
		try {
			setLoading(true);

			let response = await customerApiClient.entity4.tags.getAll();

			if (!response.data.success) {
				enqueueSnackbar('Something unexpected happened.', {
					variant: 'error',
				});

				return;
			}

			setTags(response.data.data);
		} catch (err: any) {
			enqueueSnackbar('Tags were not retrieved.', {
				variant: 'error',
			});
		} finally {
			setInitializing(false);
			setLoading(false);
		}
	}, [customerApiClient.entity4.tags, enqueueSnackbar]);

	useEffect(() => {
		refetchTags();
	}, [refetchTags]);

	return (
		<TagsContext.Provider
			value={{
				initializing: initializing,
				loading: loading,
				tags: tags,
				refetchTags: refetchTags,
			}}
		>
			{children}
		</TagsContext.Provider>
	);
};
