import {
	Checkbox,
	CheckboxProps,
	FormControlLabel,
	FormControlLabelProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export interface T4CheckboxProps
	extends Pick<
		CheckboxProps,
		| 'color'
		| 'checked'
		| 'disabled'
		| 'id'
		| 'onChange'
		| 'required'
		| 'size'
		| 'readOnly'
		| 'sx'
	> {
	/**
	 * @see {@link FormControlLabelProps.label}
	 */
	label: FormControlLabelProps['label'];

	/**
	 * @see {@link FormControlLabelprops.value}
	 */
	value?: FormControlLabelProps['value'];
}
export const T4Checkbox: FC<T4CheckboxProps> = observer(
	({ id, label, value, disabled, ...props }) => {
		const idPartial = `${id ? `-${id}` : ''}`;

		return (
			<FormControlLabel
				id={`t4-checkbox-label${idPartial}`}
				data-test-id="t4-checkbox-label"
				label={label ?? ''}
				value={value ?? ''}
				disabled={disabled}
				control={
					<Checkbox
						id={`t4-checkbox${idPartial}`}
						data-testid="t4-checkbox"
						{...props}
					/>
				}
			/>
		);
	},
);
