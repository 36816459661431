import {
	CashFlowClass,
	CashFlowSubtype,
	CashFlowType,
} from 'features/cash4/categories/categoriesViewModel';
import { GLCode } from 'features/cash4/rules/models';
import { observer } from 'mobx-react-lite';
import React, {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useState,
} from 'react';

//#region Context

type ManualCategorizationContextProps = {
	classes: CashFlowClass[];
	setClasses: React.Dispatch<React.SetStateAction<CashFlowClass[]>>;
	thisClass: CashFlowClass | null;
	setThisClass: React.Dispatch<React.SetStateAction<CashFlowClass | null>>;
	types: (classId: string) => CashFlowType[];
	thisType: CashFlowType | null;
	setThisType: React.Dispatch<React.SetStateAction<CashFlowType | null>>;
	subtypes: (typeId: string) => CashFlowSubtype[];
	thisSubtype: CashFlowSubtype | null;
	setThisSubtype: React.Dispatch<React.SetStateAction<CashFlowSubtype | null>>;
	glCodes: GLCode[];
	setGlCodes: React.Dispatch<React.SetStateAction<GLCode[]>>;
	thisGlCode: GLCode | null;
	setThisGlCode: React.Dispatch<React.SetStateAction<GLCode | null>>;
	clearRule: () => void;
	handleCategoriesChange: (
		categories: CashFlowClass[],
		cashFlowClassCode: CashFlowClass['code'],
		cashFlowTypeCode: CashFlowType['code'],
		cashFlowSubtypeCode: CashFlowSubtype['code'],
	) => void;
	handleGlCodesChange: (glCodes: GLCode[], glCode: string) => void;
};

const ManualCategorizationContext =
	createContext<ManualCategorizationContextProps>({
		classes: [],
		setClasses: () => {},
		thisClass: null,
		setThisClass: () => {},
		types: () => [],
		thisType: null,
		setThisType: () => {},
		subtypes: () => [],
		thisSubtype: null,
		setThisSubtype: () => {},
		glCodes: [],
		setGlCodes: () => {},
		thisGlCode: null,
		setThisGlCode: () => {},
		clearRule: () => {},
		handleCategoriesChange: () => {},
		handleGlCodesChange: () => {},
	});

//#endregion

//#region Provider

export type ManualCategorizationProviderProps = {
	children: ReactNode;
};

export const ManualCategorizationProvider: FC<ManualCategorizationProviderProps> =
	observer(({ children }) => {
		const [classes, setClasses] = useState<CashFlowClass[]>([]);
		const [thisClass, setThisClass] = useState<CashFlowClass | null>(null);
		const [thisType, setThisType] = useState<CashFlowType | null>(null);
		const [thisSubtype, setThisSubtype] = useState<CashFlowSubtype | null>(
			null,
		);
		const [glCodes, setGlCodes] = useState<GLCode[]>([]);
		const [thisGlCode, setThisGlCode] = useState<GLCode | null>(null);

		const handleCategoriesChange = useCallback(
			(
				categories: CashFlowClass[],
				cashFlowClassCode: CashFlowClass['code'],
				cashFlowTypeCode: CashFlowType['code'],
				cashFlowSubtypeCode: CashFlowSubtype['code'],
			) => {
				setClasses(categories);
				const foundClass =
					categories.find((x) => x.code === cashFlowClassCode) ?? null;
				setThisClass(foundClass);

				const foundType = foundClass
					? foundClass.types.find((x) => x.code === cashFlowTypeCode) ?? null
					: null;
				setThisType(foundType);

				const foundSubtype = foundType
					? foundType.subtypes.find((x) => x.code === cashFlowSubtypeCode) ??
					  null
					: null;
				setThisSubtype(foundSubtype);
			},
			[],
		);

		const handleGlCodesChange = useCallback(
			(codes: GLCode[], glCode: string) => {
				setGlCodes(codes);
				const foundGlCode = codes.find((x) => x.code === glCode) ?? null;
				setThisGlCode(foundGlCode);
			},
			[],
		);

		const clearRule = useCallback(() => {
			setThisClass(null);
			setThisType(null);
			setThisSubtype(null);
			setThisGlCode(null);
		}, []);

		const types = useCallback(
			(classId: string) => classes.find((x) => x.id === classId)?.types ?? [],
			[classes],
		);

		const subtypes = useCallback(
			(typeId: string) =>
				classes.flatMap((x) => x.types).find((x) => x.id === typeId)
					?.subtypes ?? [],
			[classes],
		);

		return (
			<ManualCategorizationContext.Provider
				value={{
					classes,
					setClasses,
					thisClass,
					setThisClass,
					types,
					thisType,
					setThisType,
					subtypes,
					thisSubtype,
					setThisSubtype,
					glCodes,
					setGlCodes,
					thisGlCode,
					setThisGlCode,
					clearRule,
					handleCategoriesChange,
					handleGlCodesChange,
				}}
			>
				{children}
			</ManualCategorizationContext.Provider>
		);
	});

//#endregion

//#region Hook

export type UseManualCategorizationProps = ManualCategorizationContextProps;

export function useManualCategorization(): UseManualCategorizationProps {
	const context = useContext(ManualCategorizationContext);
	if (context === undefined) {
		throw new Error(
			'useManualCategorization must be used within a ManualCategorizationProvider',
		);
	}
	return context;
}

//#endregion
