import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EntityTypeId, getEntityType } from 'features/entity4/entity4Constants';
import { flow, makeAutoObservable } from 'mobx';
import { PowerOfAttorneyType } from 'modules/clients/customer-api/src/api/referenceData';
import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { ObjectPathParams, paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import {
	getDateColumnDefinition,
	getMultiSelectGridColDef,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { getOptionsMenuColDef } from 'shared/utilities/dataGrid/dataGridUtils';
import { T4Link } from '../../../../shared/components/t4Link';
import { CreateRelationshipDrawerViewModel } from '../components/createRelationshipDrawer/createRelationshipDrawerViewModel';
import { DeleteRelationshipViewModel } from '../components/deleteRelationshipViewModel';
import {
	EditRelationshipDrawerViewModel,
	rowToRelationshipData,
} from '../components/editRelationshipDrawer/editRelationshipDrawerViewModel';
import { RelationshipOptionsCell } from '../components/relationshipOptionCell';
import { usePowerOfAttorneys } from '../providers/powerOfAttorneysProvider';
import { getRelationshipColumns } from '../relationshipUtilities';
import { BaseRelationship } from '../relationshipsObjectTypes';
import {
	StaffPowerOfAttorneyRelationship,
	StaffRelationshipsListModel,
} from './staffRelationshipsListModel';

export type AllowedTab =
	| 'Account Signatory'
	| 'Roles'
	| 'Ownership'
	| 'Power of Attorney';

export class StaffRelationshipsViewModel {
	private readonly _entityId: string;
	private _isAuthor: boolean;

	private listDataModel: StaffRelationshipsListModel;

	public _createRelationshipViewModel: CreateRelationshipDrawerViewModel;
	public readonly editRelationshipDrawerViewModel: EditRelationshipDrawerViewModel;
	public readonly deleteRelationshipViewModel: DeleteRelationshipViewModel;

	constructor(
		entityId: string,
		isAuthor: boolean,
		powerOfAttorneys: PowerOfAttorneyType[],
	) {
		makeAutoObservable(this);

		this._entityId = entityId;
		this._isAuthor = isAuthor;

		this.listDataModel = new StaffRelationshipsListModel(entityId);
		this._createRelationshipViewModel = new CreateRelationshipDrawerViewModel(
			EntityTypeId.Staff,
			this._entityId,
			() => this.load(),
			powerOfAttorneys,
		);
		this.editRelationshipDrawerViewModel = new EditRelationshipDrawerViewModel(
			EntityTypeId.Staff,
			this._entityId,
			() => this.load(),
			powerOfAttorneys,
		);
		this.deleteRelationshipViewModel = new DeleteRelationshipViewModel(
			EntityTypeId.Staff,
			this._entityId,
			() => this.load(),
		);
	}

	public getCreateViewModel = () => this._createRelationshipViewModel;

	public loading = () => this.listDataModel.loading;
	public error = () => this.listDataModel.error;
	public getEntityId = () => this._entityId;
	public isAuthor = () => this._isAuthor;
	public setIsAuthor = (isAuthor: boolean) => (this._isAuthor = isAuthor);

	// tab functions
	public getTabList = (): AllowedTab[] => [
		'Account Signatory',
		'Roles',
		'Ownership',
		'Power of Attorney',
	];

	public getEntityName = () => this.listDataModel.data?.entityName;

	// account table
	public getAccountColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'accountName',
				headerName: 'Account Name',
				description: 'Account Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'signatoryTitle',
				headerName: 'Signatory Title',
				description: 'Signatory Title',
				flex: 1,
			},
			{
				field: 'accountStatus',
				headerName: 'Account Status',
				description: 'Account Status',
				flex: 1,
			},
			{
				field: 'counterpartyName',
				headerName: 'Counterparty Name',
				description: 'Counterparty Name',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getAccountRows = () =>
		this.listDataModel.data?.accountSignatories ?? [];

	public getRoleColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'entityName',
				headerName: 'Entity Name',
				description: 'Entity Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'formOfOrganization',
				headerName: 'Form of Organization',
				description: 'Form of Organization',
				flex: 1,
			},
			{
				field: 'title',
				headerName: 'Title',
				description: 'Title',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getRoleRows = () => this.listDataModel.data?.roles ?? [];

	public getOwnershipColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'entityName',
				headerName: 'Entity Name',
				description: 'Entity Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'formOfOrganization',
				headerName: 'Form of Organization',
				description: 'Form of Organization',
				flex: 1,
			},
			{
				field: 'ownershipLevel',
				headerName: 'Ownership Level',
				description: 'Ownership Level',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getOwnershipRows = () => this.listDataModel.data?.ownership ?? [];

	public getPowerOfAttorneyRelationshipColumns =
		(): GridColDef<StaffPowerOfAttorneyRelationship>[] => {
			const columns: GridColDef<StaffPowerOfAttorneyRelationship>[] = [
				{
					field: 'legalEntityDisplayName',
					headerName: 'Legal Entity Name',
					description: 'Legal Entity Name',
					flex: 1,
					renderCell: (params: GridRenderCellParams) => {
						const path = generatePath(
							paths.entity4.objects.object.information.href,
							{
								objectType: getEntityType(params.row.entityTypeId)!,
								objectId: params.row.entityId,
							},
						);

						return (
							<T4Link to={path} color="secondary">
								{params.formattedValue}
							</T4Link>
						);
					},
				},
				{
					...getMultiSelectGridColDef(
						this.listDataModel.powerOfAttorneys.map((x) => x.description),
					),
					field: 'typeOfPowerOfAttorneyId',
					headerName: 'Power of Attorney',
					description: 'Power of Attorney',
					valueGetter: ({ value }) =>
						this.listDataModel.powerOfAttorneys.find((x) => x.id === value)
							?.description ?? '',
				},
				...getRelationshipColumns<StaffPowerOfAttorneyRelationship>(),
			];

			if (this.isAuthor()) {
				columns.unshift(
					getOptionsMenuColDef<StaffPowerOfAttorneyRelationship>(
						(params: GridRenderCellParams<BaseRelationship, string>) => (
							<RelationshipOptionsCell
								editRelationshipDrawerViewModel={
									this.editRelationshipDrawerViewModel
								}
								deleteRelationshipViewModel={this.deleteRelationshipViewModel}
								relationship={rowToRelationshipData(
									EntityTypeId.Staff,
									params.row,
								)}
							/>
						),
					),
				);
			}

			return columns;
		};
	public getPowerOfAttoryneyRows = () =>
		this.listDataModel.data?.powerOfAttorneys ?? [];

	private addOptionsColumn = (cols: GridColDef[]) => {
		if (this._isAuthor) {
			cols.unshift({
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<BaseRelationship, string>) => (
						<RelationshipOptionsCell
							editRelationshipDrawerViewModel={
								this.editRelationshipDrawerViewModel
							}
							deleteRelationshipViewModel={this.deleteRelationshipViewModel}
							relationship={rowToRelationshipData(
								EntityTypeId.Staff,
								params.row,
							)}
						/>
					),
				),
			});
		}

		return cols;
	};

	public load = flow(function* (this: StaffRelationshipsViewModel) {
		yield this.listDataModel.load();
		this._createRelationshipViewModel.initialize(
			this.getEntityName(),
			this.listDataModel.flatData,
		);
		this.editRelationshipDrawerViewModel.setEntityName(this.getEntityName());
	});
}

export const useStaffRelationshipsViewModel = () => {
	const { powerOfAttorneys } = usePowerOfAttorneys();
	const { objectId } = useParams<ObjectPathParams>();
	const { isAuthor } = useUser();
	const [viewModel] = useState(
		new StaffRelationshipsViewModel(objectId, isAuthor, powerOfAttorneys),
	);

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	useEffect(() => {
		viewModel.setIsAuthor(isAuthor);
	}, [isAuthor, viewModel]);

	return viewModel;
};
