import { makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { customerApi } from 'shared/providers/customerApi';
import { toUtc } from 'shared/utilities/dateUtilities';
import { ApiResponse } from 'utilities/api';
import DateRangeValidator, { IDateRangeValidator } from './dateRangeValidator';

export interface IAuditDto {
	date: string;
	user: string;
	action: string;
	area: string;
	areaName: string;
	record?: string;
	recordName?: string;
	fieldChanged?: string;
	oldValue?: string;
	newValue?: string;
}

export const auditLogGenericError = 'Unable to retrieve audit log';
export const dateRangeInvalidError =
	'Please select a date range of 100 days or less.';
class AuditModel {
	public loading: boolean = false;
	private audits: IAuditDto[] = [];
	private error?: string;
	private startDate: Moment | null = moment().add().days(-28); // shows 30 days because it includes the start and end date inclusively
	private endDate: Moment | null = moment();
	private rangeValidator: IDateRangeValidator;

	constructor() {
		makeAutoObservable(this);
		this.rangeValidator = new DateRangeValidator();
	}

	public getLoading() {
		return this.loading;
	}

	public setLoading(loading: boolean) {
		this.loading = loading;
	}

	public setError(error: string) {
		this.error = error;
	}

	public getError() {
		return this.error;
	}

	public setStartDate(date: Moment | null) {
		this.startDate = date;
	}
	public getStartDate() {
		return this.startDate;
	}
	public setEndDate(date: Moment | null) {
		this.endDate = date;
	}
	public getEndDate() {
		return this.endDate;
	}

	public load() {
		this.error = undefined;
		this.setAudits([]);
		this.setLoading(true);

		if (!this.dateRangeIsValid()) {
			this.error = dateRangeInvalidError;
			return;
		}

		return customerApi
			.get<ApiResponse<IAuditDto[]>>('/audit', {
				params: {
					startDate: toUtc(this.startDate),
					endDate: toUtc(this.endDate),
				},
			})
			.then((response) => {
				if (response.data.success) {
					this.setAudits(response.data.value);
				} else if (!response.data.error) {
					this.setError(auditLogGenericError);
				} else {
					this.setError(response.data.error);
				}
			})
			.catch((error) => this.setError(auditLogGenericError))
			.finally(() => this.setLoading(false));
	}

	setAudits(value: IAuditDto[]) {
		this.audits = value;
	}

	public getAudits() {
		return this.audits;
	}

	private dateRangeIsValid(): boolean {
		return this.rangeValidator.validate(this.startDate, this.endDate, 100);
	}
}

export default AuditModel;
