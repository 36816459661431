import { Download } from '@mui/icons-material';
import {
	Button,
	Checkbox,
	CircularProgress,
	Menu,
	MenuItem,
} from '@mui/material';
import { Template } from 'modules/clients/customer-api/src/entity4/dataImports';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type DownloadTemplatesButtonProps = {
	templates: Template[];
};

export const DownloadTemplatesButton: FC<DownloadTemplatesButtonProps> = ({
	templates,
}) => {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>();
	const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
	const [isDownloading, setIsDownloading] = useState<boolean>(false);

	const closeModal = () => {
		setAnchorElement(undefined);
		setSelectedTemplates([]);
	};

	const selectTemplate = (template: Template) => {
		const id = template.id;
		if (selectedTemplates.includes(id)) {
			setSelectedTemplates(
				selectedTemplates.filter((template) => template !== id),
			);
		} else {
			setSelectedTemplates([...selectedTemplates, id]);
		}
	};

	const downloadTemplates = async () => {
		try {
			setIsDownloading(true);
			let failedTemplateNames = [];
			for (const templateId of selectedTemplates) {
				let response =
					await customerApiClient.entity4.dataImports.downloadTemplate(
						templateId,
					);

				if (response.status === 200 && response.data) {
					const template = templates.find((x) => x.id === templateId);

					const fileName = template?.fileName ?? 'Template.xlsx';
					const contentType = response.headers['content-type'];
					const file = new File([response.data], fileName, {
						type: contentType,
					});

					const link = document.createElement('a');
					link.href = URL.createObjectURL(file);
					link.setAttribute('target', '_blank');
					link.setAttribute('type', contentType);
					link.setAttribute('download', fileName);

					document.body.appendChild(link);
					link.click();
					globalThis.document.body.removeChild(link);
				} else {
					failedTemplateNames.push(
						templates.find((x) => x.id === templateId)?.name,
					);
				}
			}

			if (failedTemplateNames.length > 0) {
				if (failedTemplateNames.length < selectedTemplates.length)
					enqueueSnackbar(
						`The following templates could not be downloaded: ${failedTemplateNames.join(
							', ',
						)}.`,
						{ variant: 'error' },
					);
				else
					enqueueSnackbar('System failed to download templates.', {
						variant: 'error',
					});
			}

			closeModal();
		} catch (error: any) {
			enqueueSnackbar('System failed to download templates.', {
				variant: 'error',
			});
		} finally {
			setIsDownloading(false);
		}
	};

	return (
		<>
			<Button
				variant="outlined"
				startIcon={<Download />}
				onClick={(event) => setAnchorElement(event.currentTarget)}
				disabled={templates.length === 0}
			>
				Download Templates
			</Button>
			<Menu
				open={Boolean(anchorElement)}
				anchorEl={anchorElement}
				onClose={closeModal}
			>
				{templates.map((template, index) => (
					<MenuItem key={index} onClick={() => selectTemplate(template)}>
						<Checkbox
							key={template.id}
							checked={selectedTemplates.includes(template.id)}
							onClick={() => selectTemplate(template)}
							sx={{ paddingLeft: 0 }}
						/>
						{template.name}
					</MenuItem>
				))}

				<MenuItem>
					<Button
						variant="contained"
						disabled={selectedTemplates.length === 0 || isDownloading}
						onClick={async () => await downloadTemplates()}
						sx={{ width: '125px' }}
					>
						{isDownloading ? <CircularProgress size={24} /> : 'Download'}
					</Button>
				</MenuItem>
			</Menu>
		</>
	);
};
