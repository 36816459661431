import { Option } from 'modules/clients/customer-api/src/api/common';
import { useCallback, useMemo, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { Collection } from '../../object/models/collection';
import { T4Object } from '../../object/models/t4Object';
import { getObjectInputType } from '../../object/utilities';
import {
	phoneNumberCollectionIdentifier,
	phoneNumberTypeIdentifier,
} from '../contactsView';
export type UseCreatePhoneNumberProps = {
	isCreating: boolean;
	error: string | undefined;
	createCollection: (phoneNumberType: Option) => Promise<boolean>;
};

export function useCreatePhoneNumber(
	object: T4Object,
	parentCollectionId: string | undefined,
): UseCreatePhoneNumberProps {
	const { customerApiClient } = useClients();

	const [isCreating, setIsCreating] = useState(false);
	const [error, setError] = useState<string>();

	const _objectId = useMemo(() => object.id, [object.id]);
	const _objectType = useMemo(() => object.objectType, [object.objectType]);
	const _parentCollectionId = useMemo(
		() => parentCollectionId,
		[parentCollectionId],
	);

	const createCollection = useCallback(
		async (phoneNumberType: Option): Promise<boolean> => {
			try {
				setIsCreating(true);
				setError(undefined);

				const response = await customerApiClient.api.objects.collections.create(
					{
						objectId: _objectId,
						objectType: getObjectInputType(_objectType),
						collectionIdentifier: phoneNumberCollectionIdentifier,
						fieldUpdates: [
							{
								fieldIdentifier: phoneNumberTypeIdentifier,
								value: phoneNumberType?.id,
							},
						],
						parentCollectionId: _parentCollectionId ?? null,
						phoneNumberFieldUpdates: [],
					},
				);

				if (response && response.data && response.data.value) {
					object.collections.push(new Collection(response.data.value));
				}

				if (response && response.data && response.data.error) {
					setError(response.data.error);
				}
				if (
					response &&
					response.data &&
					response.data.errors &&
					(Object.values(response.data.errors)?.length ?? 0) > 0
				) {
					setError(
						error?.concat(flattenErrors(response.data.errors).join(', ')),
					);
				}
			} catch (error) {
				setError('Unable to create collection.');
			} finally {
				setIsCreating(false);

				if (error) {
					return false;
				} else {
					return true;
				}
			}
		},
		[
			_objectId,
			_objectType,
			_parentCollectionId,
			customerApiClient.api.objects.collections,
			error,
			object.collections,
		],
	);

	return {
		isCreating,
		error,
		createCollection,
	};
}
