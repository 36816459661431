import { action, flow, makeObservable, observable, override } from 'mobx';
import { parseError } from 'utilities/errors/errorUtils';
import {
	GroupEntityDto,
	GroupMemberDto,
	GroupRepository,
	GroupRequestDto,
} from '../groupRepository';

export const AdditionRequestModalDescription = 'Approve or reject adding ';
export const RemovalRequestModalDescription = 'Approve or reject removing ';

export abstract class SingleGroupRequestApprovalModel {
	protected readonly _groupId: string;
	protected readonly _groupRepository: GroupRepository;

	public readonly request: GroupRequestDto;

	public loading: boolean;
	public error: string;

	public isApproved: boolean | null = null;
	public rejectionComment: string | null = null;

	constructor(
		groupId: string,
		request: GroupRequestDto,
		groupRepository: GroupRepository,
	) {
		makeObservable(this, {
			loading: observable,
			error: observable,
			isApproved: observable,
			rejectionComment: observable,
			getDescription: action,
			submit: flow,
		});

		this._groupId = groupId;
		this._groupRepository = groupRepository;
		this.request = request;
		this.loading = false;
		this.error = '';
	}

	public getDescription(): string {
		return '';
	}
	public submit(): any {}
}

export class SingleGroupMemberRequestApprovalModel extends SingleGroupRequestApprovalModel {
	private readonly _member: GroupMemberDto;

	constructor(
		groupId: string,
		member: GroupMemberDto,
		groupRepository: GroupRepository,
	) {
		super(groupId, member.request!, groupRepository);
		makeObservable(this, {
			getDescription: override,
			submit: override,
		});
		this._member = member;
	}

	public override getDescription(): string {
		if (this.request.type === 'Addition')
			return AdditionRequestModalDescription + `"${this._member.email}".`;
		else return RemovalRequestModalDescription + `"${this._member.email}".`;
	}

	public override submit = flow(function* (
		this: SingleGroupMemberRequestApprovalModel,
	) {
		try {
			this.loading = true;
			this.error = '';

			yield this._groupRepository.manageGroupMemberApprovals(this._groupId, {
				reviews: {
					[this.request.id]: {
						isApproved: this.isApproved!,
						rejectionComment: this.rejectionComment,
					},
				},
			});
		} catch (error: any) {
			this.error = parseError(error);
		} finally {
			this.loading = false;
		}
	});
}

export class SingleGroupEntityRequestApprovalModel extends SingleGroupRequestApprovalModel {
	private readonly _entity: GroupEntityDto;

	constructor(
		groupId: string,
		entity: GroupEntityDto,
		groupRepository: GroupRepository,
	) {
		super(groupId, entity.request!, groupRepository);
		makeObservable(this, {
			getDescription: override,
			submit: override,
		});
		this._entity = entity;
	}

	public override getDescription(): string {
		if (this.request.type === 'Addition')
			return AdditionRequestModalDescription + `"${this._entity.name}".`;
		else return RemovalRequestModalDescription + `"${this._entity.name}".`;
	}

	public override submit = flow(function* (
		this: SingleGroupEntityRequestApprovalModel,
	) {
		try {
			this.loading = true;
			this.error = '';

			yield this._groupRepository.manageGroupEntityApprovals(this._groupId, {
				reviews: {
					[this.request.id]: {
						isApproved: this.isApproved!,
						rejectionComment: this.rejectionComment,
					},
				},
			});
		} catch (error: any) {
			this.error = parseError(error);
		} finally {
			this.loading = false;
		}
	});
}
