import { Tag } from 'modules/clients/customer-api/src/entity4/tags';
import { createContext } from 'react';

export type TagsContextProps = {
	initializing: boolean;
	loading: boolean;
	tags: Tag[];
	refetchTags: () => Promise<void>;
};

export const TagsContext = createContext<TagsContextProps>({
	initializing: true,
	loading: true,
	tags: [],
	refetchTags: () => Promise.resolve(),
});
