import { TabPanel, TabPanelProps } from '@mui/lab';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';

export type VisualizationTabPanelProps = TabPanelProps & {
	children: ReactNode;
};

export const VisualizationTabPanel: FC<VisualizationTabPanelProps> = observer(
	({ children, ...rest }) => (
		<TabPanel
			{...rest}
			sx={{
				...rest?.sx,
				paddingY: 0,
				width: '100%',
			}}
		>
			{children}
		</TabPanel>
	),
);
