import { AxiosResponse } from 'axios';
import { getDateWarningMessage } from 'features/entity4/entities/utilities/dateUtilities';
import {
	EntityConfig,
	EntityTypeId,
	getEntityType,
	getEntityTypeKey,
} from 'features/entity4/entity4Constants';
import { makeAutoObservable } from 'mobx';
import { CancellablePromise } from 'mobx/dist/internal';
import { Moment } from 'moment';
import { customerApi } from 'shared/providers/customerApi';
import {
	areDatesEqual,
	convertDate,
	formatDate,
} from 'shared/utilities/dateUtilities';
import { ApiResponse } from 'utilities/api';
import { flattenErrors, parseError } from 'utilities/errors/errorUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import {
	IEditRelationshipData,
	IEditRelationshipForm,
	getEntityTypeName,
} from '../components/editRelationshipDrawer/editRelationshipDrawerViewModel';
import { GeneralAccountRelationship } from './accountRelationshipsListModel';

interface IEditAccountRelationshipForm extends IEditRelationshipForm {
	fundingDirection: string | null;
	cashFlowMovement: string | null;
	fundingFrequency: string | null;
}

export interface IEditAccountRelationshipData extends IEditRelationshipData {
	fundingDirection: string | null;
	cashFlowMovement: string | null;
	fundingFrequency: string | null;
}

export const rowToRelationshipData = (row: GeneralAccountRelationship) => {
	return {
		id: row.id,
		typeId: row.relationshipTypeId,
		entityTypeId: row.entityTypeId,
		entityTypeDisplayName: `${getEntityTypeKey(
			getEntityType(EntityTypeId.Account)!,
		)} to ${getEntityTypeKey(getEntityType(row.entityTypeId)!)}`,
		relationshipDisplayName: row.relationshipTypeName,
		definitionDescriptor: row.definitionDescriptor,
		entityDisplayName: getEntityTypeName(row),
		effectiveFrom: row.effectiveFrom ? convertDate(row.effectiveFrom) : null,
		effectiveTo: row.effectiveTo ? convertDate(row.effectiveTo) : null,
		notes: row.notes ? row.notes : null,
		fundingDirection: row.fundingDirection,
		cashFlowMovement: row.cashFlowMovement,
		fundingFrequency: row.fundingFrequency,
	} as IEditAccountRelationshipData;
};

const initialForm: IEditAccountRelationshipForm = {
	effectiveFrom: null,
	effectiveTo: null,
	notes: null,
	fundingDirection: null,
	cashFlowMovement: null,
	fundingFrequency: null,
	selectedPowerOfAttorneyType: null,
};

export class EditAccountRelationshipDrawerViewModel {
	public readonly entityType: EntityTypeId;
	public readonly entityId: string;

	private _entityName: string | undefined;
	private _fundingDirections: string[] = [];
	private _cashFlowMovements: string[] = [];
	private _fundingFrequencies: string[] = [];
	private _drawerOpen: boolean = false;

	private _updating: boolean = false;
	private _updateError: string | undefined = undefined;
	private _updateErrors: string[] = [];

	private _refetch: () => CancellablePromise<void>;

	private _form: IEditAccountRelationshipForm = initialForm;
	private _relationshipData: IEditAccountRelationshipData =
		{} as IEditAccountRelationshipData;

	constructor(entityId: string, refetch: () => CancellablePromise<void>) {
		makeAutoObservable(this);
		this.entityType = EntityTypeId.Account;
		this.entityId = entityId;
		this._refetch = () => refetch();
	}

	public initialize(
		entityName: string | undefined,
		fundingDirections: string[],
		cashflowMovements: string[],
		fundingFrequencies: string[],
	) {
		this._entityName = entityName;
		this._fundingDirections = fundingDirections;
		this._cashFlowMovements = cashflowMovements;
		this._fundingFrequencies = fundingFrequencies;
	}

	public setEntityName(value: string | undefined) {
		this._entityName = value;
	}

	public isUpdating() {
		return this._updating;
	}
	public getUpdateError() {
		return this._updateError;
	}
	public getUpdateErrors() {
		return this._updateErrors;
	}

	public getTypeDisplayName() {
		return this._relationshipData.entityTypeDisplayName;
	}
	public getRelationshipDisplayName() {
		return this._relationshipData.relationshipDisplayName;
	}

	public getRelatedEntityLabel() {
		return getEntityTypeKey(
			getEntityType(this._relationshipData.entityTypeId)!,
		);
	}
	public getFundingDirectionList() {
		return this._fundingDirections;
	}
	public getCashFlowMovementList() {
		return this._cashFlowMovements;
	}
	public getFundingFrequencyList() {
		return this._fundingFrequencies;
	}

	// relationship directionality
	public getEntityName() {
		return this._entityName;
	}

	public getRelatedEntityName() {
		return this._relationshipData?.entityDisplayName;
	}

	public getDirectionDescription() {
		return this._relationshipData?.definitionDescriptor?.toLowerCase();
	}

	public isOpen() {
		return this._drawerOpen;
	}

	public isDirty() {
		return (
			!areDatesEqual(
				this._form.effectiveFrom,
				this._relationshipData.effectiveFrom,
			) ||
			!areDatesEqual(
				this._form.effectiveTo,
				this._relationshipData.effectiveTo,
			) ||
			this._form.fundingDirection !== this._relationshipData.fundingDirection ||
			this._form.cashFlowMovement !== this._relationshipData.cashFlowMovement ||
			this._form.fundingFrequency !== this._relationshipData.fundingFrequency ||
			this._form.notes !== this._relationshipData.notes
		);
	}

	public async openDrawer(relationship: IEditAccountRelationshipData) {
		this._drawerOpen = true;
		this._relationshipData = relationship;
		this.setEffectiveFrom(
			this._relationshipData.effectiveFrom?.isValid()
				? this._relationshipData.effectiveFrom
				: null,
		);
		this.setEffectiveTo(
			this._relationshipData.effectiveTo?.isValid()
				? this._relationshipData.effectiveTo
				: null,
		);
		this.setNotes(this._relationshipData.notes);
		this._form.fundingDirection = this._relationshipData.fundingDirection;
		this._form.cashFlowMovement = this._relationshipData.cashFlowMovement;
		this._form.fundingFrequency = this._relationshipData.fundingFrequency;
	}

	public closeDrawer() {
		this._drawerOpen = false;
		this.resetForm();
	}
	private resetForm() {
		this.setEffectiveFrom(null);
		this.setEffectiveTo(null);
		this.setNotes(null);
		this._updateError = undefined;
		this._updateErrors = [];
	}

	public isSubmitDisabled() {
		return this._updating || !this.areDatesValid() || !this.isDirty();
	}

	public getEffectiveFrom() {
		if (this._form.effectiveFrom) return this._form.effectiveFrom;

		return null;
	}
	public setEffectiveFrom(value: Moment | null) {
		this._form.effectiveFrom = value;
	}

	public getEffectiveTo() {
		if (this._form.effectiveTo) return this._form.effectiveTo;

		return null;
	}
	public setEffectiveTo(value: Moment | null) {
		this._form.effectiveTo = value;
	}

	public getNotes() {
		return this._form.notes;
	}
	public setNotes(value: string | null) {
		if (isStringUndefinedOrNullOrWhitespace(value)) this._form.notes = null
		else if (value && value.length <=2048) this._form.notes = value
	}

	public areDatesValid() {
		const effectiveFromValid =
			this._form.effectiveTo !== null
				? !Boolean(
						getDateWarningMessage(
							'EffectiveFrom',
							this._form.effectiveFrom,
							this._form.effectiveTo,
						),
				  )
				: !Boolean(
						getDateWarningMessage('EffectiveFrom', this._form.effectiveFrom),
				  );

		return (
			effectiveFromValid &&
			!Boolean(getDateWarningMessage('EffectiveTo', this._form.effectiveTo))
		);
	}

	public *updateRelationship() {
		if (this.isSubmitDisabled()) return;
		const route = EntityConfig.get(
			getEntityType(this.entityType)!,
		)!.controllerRoute;

		try {
			this._updating = true;
			this._updateError = undefined;

			const response: AxiosResponse<ApiResponse<string>> =
				yield customerApi.put<ApiResponse<string>>(
					`${route}/${this.entityId}/relationships/${this._relationshipData.id}`,
					{
						effectiveFrom: formatDate(this._form.effectiveFrom) ?? null,
						effectiveTo: formatDate(this._form.effectiveTo) ?? null,
						notes: this._form.notes,
						fundingDirection: this._form.fundingDirection,
						cashFlowMovement: this._form.cashFlowMovement,
						fundingFrequency: this._form.fundingFrequency,
					},
				);

			if (response.data.error) this._updateError = response.data.error;
			else if (response.data.errors)
				this._updateErrors = flattenErrors(response.data.errors);
			else {
				this.closeDrawer();
				yield this._refetch();
			}
		} catch (err: any) {
			this._updateError = parseError(err);
		} finally {
			this._updating = false;
		}
	}

	// account to account specific functions
	public getFundingDirection() {
		return this._form.fundingDirection || '';
	}
	public setFundingDirection(value: string | null) {
		if (value !== null) {
			this._form.fundingDirection = value;
		}
	}

	public getCashFlowMovement() {
		return this._form.cashFlowMovement;
	}
	public setCashFlowMovement(value: string | null) {
		this._form.cashFlowMovement = value;
	}

	public getFundingFrequency() {
		return this._form.fundingFrequency;
	}
	public setFundingFrequency(value: string | null) {
		this._form.fundingFrequency = value;
	}

	public isAccountToAccountRelationship() {
		if (this._relationshipData === null) return false;
		return this._relationshipData.entityTypeId === EntityTypeId.Account;
	}
}
