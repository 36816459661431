import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { USER_PREFERENCES_FIELD_OPTIONS } from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { DeleteRelationshipModal } from '../components/deleteRelationshipModal';
import { RelationshipsLayout } from '../components/relationshipsLayout';
import { IRelationshipDefinitionData } from '../relationshipApiTypes';
import { useAccountRelationshipsViewModel } from './accountRelationshipsViewModels';
import { CreateAccountRelationshipDrawer } from './createAccountRelationshipDrawer';
import { EditAccountRelationshipDrawer } from './editAccountRelationshipDrawer';

export const AccountRelationships: FC = observer(() => {
	const viewModel = useAccountRelationshipsViewModel();
	const history = useHistory();

	const navigateToTab = useCallback(
		(definition: IRelationshipDefinitionData | null) => {
			if (definition) {
				let newTab = viewModel.getTabList()[0];

				if (definition.displayName === 'Signatory') {
					newTab = 'Signatories';
				} else if (
					definition.displayName === 'Account Owner' ||
					definition.displayName === 'Account Holder'
				) {
					newTab = 'Holder and Owners';
				} else if (
					definition.subcategory.displayName === 'Account To Account'
				) {
					newTab = 'Account to Account';
				}

				history.push(history.location.pathname + `?tab=${newTab}`);
			}
		},
		[history, viewModel],
	);

	return (
		<RelationshipsLayout
			createRelationshipButton={
				<CreateAccountRelationshipDrawer
					viewModel={viewModel.getCreateViewModel()!}
					readOnly={viewModel.loading()}
					navigateToTab={navigateToTab}
				/>
			}
			tabDetails={[
				{
					tab: 'Signatories',
					component: (
						<UserPreferencesDataGrid
							stonlyId="signatories"
							tableKey="accountRelationshipsSignatories"
							columns={viewModel.getSignatoryColumns()}
							rows={viewModel.getSignatoryRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Signatory Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
				{
					tab: 'Holder and Owners',
					component: (
						<Box sx={{ paddingBottom: '1.25rem' }}>
							<UserPreferencesDataGrid
								stonlyId="account-holders"
								tableKey="accountRelationshipsAccountHolders"
								columns={viewModel.getHolderColumns()}
								rows={viewModel.getHolderRows()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Account Holder Relationships"
								initialState={{
									pagination: { paginationModel: { pageSize: 5 } },
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
								hideFooter={viewModel.getHolderRows().length <= 5}
								pagination={viewModel.getHolderRows().length > 5}
								showToolbar
								showCustomViewButton
								autoHeight
								sx={{ height: '40vh' }}
							/>
							<UserPreferencesDataGrid
								stonlyId="account-owners"
								tableKey="accountRelationshipsAccountOwners"
								columns={viewModel.getOwnerColumns()}
								rows={viewModel.getOwnerRows()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Account Owner Relationships"
								showToolbar
								showCustomViewButton
								initialState={{
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
								sx={{
									marginTop: '1rem',
									height: '40vh',
								}}
							/>
						</Box>
					),
				},
				{
					tab: 'Account to Account',
					component: (
						<Box sx={{ paddingBottom: '1.25rem' }}>
							<UserPreferencesDataGrid
								stonlyId="source-accounts"
								tableKey="accountRelationshipsSourceAccounts"
								columns={viewModel.getAccountToAccountColumns()}
								rows={viewModel.getSourceAccountRows()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Source Account Relationships"
								showToolbar
								showCustomViewButton
								calculateColumnWidths
								initialState={{
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
								sx={{
									height: '40vh',
								}}
							/>
							<UserPreferencesDataGrid
								stonlyId="target-accounts"
								tableKey="accountRelationshipsTargetAccounts"
								columns={viewModel.getAccountToAccountColumns()}
								rows={viewModel.getTargetAccountRows()}
								loading={viewModel.loading()}
								errorMessage={viewModel.error()}
								emptyMessage="No Destination Account Relationships"
								showToolbar
								showCustomViewButton
								calculateColumnWidths
								initialState={{
									pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
								}}
								sx={{
									marginTop: '1rem',
									height: '40vh',
								}}
							/>
						</Box>
					),
				},
			]}
		>
			<EditAccountRelationshipDrawer
				viewModel={viewModel.editAccountRelationshipDrawerViewModel}
			/>
			<DeleteRelationshipModal
				viewModel={viewModel.deleteRelationshipViewModel}
			/>
		</RelationshipsLayout>
	);
});
