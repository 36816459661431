import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { flow, makeAutoObservable } from 'mobx';
import { parseError } from 'utilities/errors/errorUtils';
import {
	AvailableGroupEntityDto,
	FrontendGroupRepository,
	GroupRepository,
} from '../groupRepository';

export class ManageGroupEntitiesModel {
	private _groupId: string;
	private _frontendGroupRepository: FrontendGroupRepository;
	private _groupRepository: GroupRepository;

	private _loading: boolean;
	private _error: string;
	private _availableEntitiesLoading: boolean;
	private _availableEntitiesError: string;

	private _availableEntities: AvailableGroupEntityDto[] = [];
	private _selectionModel: GridRowId[] = [];

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
		groupRepository: GroupRepository,
	) {
		makeAutoObservable(this);
		this._groupId = groupId;
		this._frontendGroupRepository = frontendGroupRepository;
		this._groupRepository = groupRepository;

		this._loading = false;
		this._availableEntitiesLoading = false;
		this._error = '';
		this._availableEntitiesError = '';
	}

	public get loading() {
		return this._loading;
	}

	public get error() {
		return this._error;
	}

	public get availableEntitiesLoading() {
		return this._availableEntitiesLoading;
	}

	public get availableEntitiesError() {
		return this._availableEntitiesError;
	}

	public get availableEntities() {
		return this._availableEntities;
	}

	public clear = () => {
		this._error = '';
		this._availableEntitiesError = '';
		this._selectionModel = [];
		this._availableEntities = [];
	};

	public get selectionModel() {
		return this._selectionModel;
	}

	public onSelectionModelChange = (selectionModel: GridRowSelectionModel) => {
		this._selectionModel = selectionModel;
	};

	public setSelectionModel = () => {
		this._selectionModel = this._availableEntities
			.filter((entity) => entity.isInGroup)
			.map((entity) => entity.id);
	};

	public updateGroupEntities = flow(function* (this: ManageGroupEntitiesModel) {
		try {
			this._loading = true;
			this._error = '';

			const response = yield this._groupRepository.updateGroupEntities(
				this._groupId,
				{
					entityIds: this._selectionModel as string[],
				},
			);

			return response.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});

	public loadAvailableEntities = flow(function* (
		this: ManageGroupEntitiesModel,
	) {
		try {
			this._availableEntitiesLoading = true;
			this._availableEntitiesError = '';

			const response =
				yield this._frontendGroupRepository.getAvailableGroupEntities(
					this._groupId,
				);
			this._availableEntities = response.value;
		} catch (error: any) {
			this._availableEntitiesError = parseError(error);
		} finally {
			this._availableEntitiesLoading = false;
		}
	});
}
