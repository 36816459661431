import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { paths } from 'shared/constants/paths';
import { RestrictedAccessError } from 'utilities/errors/restrictedAccessError';
import { UnexpectedError } from 'utilities/errors/unexpectedError';

export type UseErrorHandlerProps = (error: Error) => void;

export const useErrorHandler = (): UseErrorHandlerProps => {
	const history = useHistory();

	return useCallback(
		(error: Error) => {
			if (error === RestrictedAccessError) {
				history.push(generatePath(paths.accessDenied.href));
			} else if (error === UnexpectedError) {
				history.push(generatePath(paths.error.href));
			}
		},
		[history],
	);
};
