import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { AdminViewModes } from 'features/administration/providers/contexts/adminViewModeContext';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { FormModal } from 'shared/components/formModal';
import { stonlyData } from 'stonly/functions';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { useGroupNavigation } from '../useGroupNavigation';
import { useAddGroupButton } from './hooks/useAddGroupButton';

export const dataTestIds = {
	addButton: 'add-group-button',
	nameField: 'group-name-field',
	descriptionField: 'description-name-field',
};

export const stonlyIds = {
	addButton: 'add-group-button',
};

export const AddGroupButton: FC = observer(() => {
	const groupNavigation = useGroupNavigation();
	const {
		isLoading,
		name,
		description,
		setName,
		setDescription,
		getFieldErrors,
		resetForm,
		submit,
	} = useAddGroupButton();

	const [isOpen, setIsOpen] = useState<boolean>(false);

	return (
		<>
			<Button
				{...stonlyData({ id: stonlyIds.addButton })}
				data-testid={dataTestIds.addButton}
				startIcon={<Add />}
				type="button"
				variant="outlined"
				color="primary"
				onClick={() => setIsOpen(true)}
				sx={{ height: 'fitcontent' }}
			>
				Create Security Group
			</Button>

			<FormModal
				open={isOpen}
				onClose={() => {
					resetForm();
					setIsOpen(false);
				}}
				onSubmit={async () => {
					const groupId = await submit();
					if (groupId) groupNavigation(groupId, AdminViewModes.edit);
				}}
				fullWidth
				title="Create Security Group"
				description="Create a group for managing Entities and Users."
				loading={isLoading}
				submitButtonLabel="Create"
				loadingMessage="Creating..."
				submitDisabled={isStringUndefinedOrNullOrWhitespace(name) || isLoading}
			>
				<Grid container spacing={2} direction="column">
					<Grid item>
						<T4TextFieldV2
							data-testid={dataTestIds.nameField}
							id="group-name"
							label="Name"
							value={name ?? ''}
							onChange={setName}
							required
							error={getFieldErrors('name').length > 0}
						/>
						<T4AlertStack errors={getFieldErrors('name')} />
					</Grid>
					<Grid item>
						<T4TextFieldV2
							data-testid={dataTestIds.descriptionField}
							id="group-description"
							label="Description"
							value={description ?? ''}
							onChange={setDescription}
							multiline
							minRows={3}
							maxRows={3}
							error={getFieldErrors('description').length > 0}
						/>
						<T4AlertStack errors={getFieldErrors('description')} />
					</Grid>
				</Grid>
			</FormModal>
		</>
	);
});
