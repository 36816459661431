import {
	ApprovalRule,
	ApprovalTier,
} from 'modules/clients/apiGateway/payments4/approvalRules';

export const isLowestTierAmount = (
	amount: number,
	rule: ApprovalRule,
	currentTier?: ApprovalTier,
): boolean =>
	rule.approvalTiers
		.filter(
			(existingTier) =>
				(currentTier ? existingTier.id !== currentTier?.id : true) &&
				existingTier.isUnlimitedAmount === false,
		)
		.every((existingTier) => amount < existingTier.amount);

export const isAnotherTierUnlimitedAmount = (
	rule: ApprovalRule,
	currentTier?: ApprovalTier,
): boolean =>
	rule.approvalTiers.some(
		(existingTier) =>
			(currentTier ? existingTier.id !== currentTier?.id : true) &&
			existingTier.isUnlimitedAmount,
	);

export const isAnotherTierAutoApprove = (
	rule: ApprovalRule,
	currentTier?: ApprovalTier,
): boolean =>
	rule.approvalTiers.some(
		(existingTier) =>
			(currentTier ? existingTier.id !== currentTier?.id : true) &&
			existingTier.isAutoApprove,
	);
