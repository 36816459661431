import { Alert, CircularProgress, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Option } from 'shared/types/referenceDataTypes';
import { ObjectPathParams } from '../../../../../shared/constants/paths';
import { entityTypeToEntityTypeId } from '../../../entity4Constants';
import { AddressListView } from '../../../fields/components/addressListView';
import { CommonCustomerFieldsRepository } from '../../../fields/customerFieldsRepository';
import {
	AddressFields,
	AddressList,
} from '../../../fields/objects/addressList';
import { FieldSetViewProps } from '../fieldSetResolver';

export const AddressFieldSetView: FC<FieldSetViewProps> = observer(
	({ fieldSetDefinition, entity }) => {
		const { objectType } = useParams<ObjectPathParams>();
		const entityTypeId = entityTypeToEntityTypeId.get(objectType);
		const [loading, setLoading] = useState(true);
		const [loadingError, setLoadingError] = useState<string | undefined>(
			undefined,
		);
		const [addressTypes, setAddressTypes] = useState<Option[]>([]);

		const sortAddressTypes = (addressTypes: Option[]): Option[] => {
			var sortedList: Option[] = [];
			var map = new Map(
				addressTypes.map((x) => {
					return [x.code, x];
				}),
			);

			['RESI', 'OPER', 'REGIS', 'BILL'].forEach((option) => {
				if (map.has(option)) {
					sortedList.push(map.get(option)!);
					map.delete(option);
				}
			});

			return [...sortedList, ...map.values()];
		};

		useEffect(() => {
			setLoadingError(undefined);
			setLoading(true);
			CommonCustomerFieldsRepository.getAddressTypesByEntityTypeId(
				entityTypeId!,
			)
				.then((response) => {
					var sortedAddresses = sortAddressTypes(response);
					setAddressTypes(sortedAddresses);
				})
				.catch(setLoadingError)
				.finally(() => setLoading(false));
		}, [entityTypeId]);

		if (loading) {
			return (
				<Grid container alignItems="center" spacing={1} justifyItems="center">
					<Grid item>
						<CircularProgress size={25} />
					</Grid>
					<Grid item>
						<Typography variant="h5">Loading Addresses</Typography>
					</Grid>
				</Grid>
			);
		}

		if (loadingError) {
			return <Alert severity="error">{loadingError}</Alert>;
		}

		return (
			<>
				{addressTypes.map((addressType) => {
					const addressEntries = entity.getCollectionEntries(
						fieldSetDefinition.name,
					);
					const addressList = new AddressList(
						entity,
						fieldSetDefinition,
						addressType,
					);
					addressEntries
						.filter(
							(x) =>
								x.getField(AddressFields.type)?.displayValue ===
								addressType.displayName,
						)
						.forEach((x) => addressList.addItemFromCollection(x));
					return (
						<AddressListView
							key={entity.id + addressType.code}
							addressList={addressList}
						/>
					);
				})}
			</>
		);
	},
);
