/* eslint-disable mobx/missing-observer */
import {
	AutocompleteFreeSoloValueMapping,
	AutocompleteValue,
	Box,
	Checkbox,
	Chip,
	Typography,
} from '@mui/material';
import {
	T4Autocomplete,
	T4AutocompleteProps,
} from 'features/entity4/shared/components/atoms/t4Autocomplete';
import {
	ForwardedRef,
	HTMLAttributes,
	forwardRef,
	useImperativeHandle,
	useRef,
} from 'react';
import { brandColors } from 'shared/theme/brandColors';

interface T4MultiSelectAutocompleteProps<
	TOption,
	TDisableClearable extends boolean = false,
	TFreeSolo extends boolean = false,
> extends T4AutocompleteProps<TOption, true, TDisableClearable, TFreeSolo> {
	/**
	 * @see {@link AutoCompleteProps.value}
	 */
	value: AutocompleteValue<TOption, true, TDisableClearable, TFreeSolo>;

	/**
	 * @see {@link AutoCompleteProps.getOptionLabel}
	 */
	getOptionLabel: (
		option: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>,
	) => string;

	/**
	 * @see {@link AutoCompleteProps.isOptionEqualToValue}
	 */
	isOptionEqualToValue: (
		option: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>,
		value: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>,
	) => boolean;

	/**
	 * Get key for each item in list rendering
	 */
	getOptionKey: (
		option: TOption | AutocompleteFreeSoloValueMapping<TFreeSolo>,
	) => string;

	/**
	 * Helper text displayed at bottom of open list
	 */
	listHelperText?: string;
}

export const T4MultiSelectAutocompleteInner =
	function T4MultiSelectAutocompleteInner<
		TOption,
		TDisableClearable extends boolean = false,
		TFreeSolo extends boolean = false,
	>(
		{
			getOptionKey,
			getOptionLabel,
			isOptionEqualToValue,
			listHelperText,
			...rest
		}: T4MultiSelectAutocompleteProps<TOption, TDisableClearable, TFreeSolo>,
		ref?: ForwardedRef<any>,
	) {
		// take forwared ref and combine it with input ref needed for text field
		const inputRef = useRef<HTMLInputElement | null>(null);
		useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, []);

		return (
			<T4Autocomplete<TOption, true, TDisableClearable, TFreeSolo>
				ref={inputRef}
				filterSelectedOptions={false}
				disableCloseOnSelect={true}
				blurOnSelect={false}
				{...rest}
				multiple
				getOptionLabel={getOptionLabel}
				isOptionEqualToValue={isOptionEqualToValue}
				ListboxComponent={ListBox}
				ListboxProps={{
					defaultValue: listHelperText,
				}}
				renderTags={(valuesChips, getTagProps, _) => {
					return (
						<Box
							sx={{
								height: '100%',
								display: 'inline-flex',
								overflowX: 'auto',
								overflowY: 'hidden',
								scrollbarWidth: 'thin',
								alignItems: 'center',
								paddingY: '4px',
							}}
						>
							{valuesChips.map((option, index) => {
								const tagProps = getTagProps({ index });
								return (
									<Chip
										{...tagProps}
										size="small"
										key={index}
										label={getOptionLabel(option)}
										onDelete={
											rest.disabled || rest.readOnly
												? undefined
												: (event) => {
														inputRef?.current?.focus();
														tagProps.onDelete(event);
												  }
										}
									/>
								);
							})}
						</Box>
					);
				}}
				renderOption={(props, option) => (
					<li
						{...props}
						key={getOptionKey(option)}
						style={{
							padding: '0.5rem',
							paddingTop: '0.1rem',
							paddingBottom: '0.1rem',
						}}
					>
						<Checkbox
							checked={rest.value.some((value) =>
								isOptionEqualToValue(value, option),
							)}
							size="small"
						/>
						<Typography>{getOptionLabel(option)}</Typography>
					</li>
				)}
			/>
		);
	};

const ListBox = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(
	function ListBox({ children, defaultValue, ...rest }, ref) {
		return (
			<ul {...rest} ref={ref} role="listbox">
				{children}
				{defaultValue && (
					<li
						style={{
							paddingTop: '8px',
							paddingBottom: '4px',
							paddingLeft: '16px',
							paddingRight: '16px',
							borderTop: '1px solid',
							borderColor: brandColors.charcoal[50],
						}}
						key="helperText"
					>
						<Typography variant="caption" sx={{ fontStyle: 'italic' }}>
							{defaultValue}
						</Typography>
					</li>
				)}
			</ul>
		);
	},
);

export const T4MultiSelectAutocomplete = forwardRef(
	T4MultiSelectAutocompleteInner,
) as <
	TOption,
	TDisableClearable extends boolean = false,
	TFreeSolo extends boolean = false,
>(
	props: T4MultiSelectAutocompleteProps<
		TOption,
		TDisableClearable,
		TFreeSolo
	> & { ref?: ForwardedRef<any> },
) => ReturnType<typeof T4MultiSelectAutocompleteInner>;
