/* eslint-disable mobx/missing-observer */
import { Box, Drawer, Toolbar } from '@mui/material';
import { stonlyData } from 'stonly/functions';

interface T4DrawerProps {
	children?: React.ReactNode;
	open: boolean;
	onClose: () => void;
	stonlyPrefix?: string;
}

const T4Drawer: React.FC<T4DrawerProps> = ({
	children,
	open,
	onClose,
	stonlyPrefix,
}) => {
	const stonlyId = stonlyPrefix
		? stonlyData({
				id: `${stonlyPrefix}-drawer`,
		  })
		: {};
	return (
		<Drawer anchor={'right'} open={open} onClose={onClose} {...stonlyId}>
			<Toolbar
				sx={{
					minHeight: '41px !important',
					height: '41px',
					maxHeight: '41px',
				}}
			/>
			<Box
				sx={{
					minWidth: 500,
					maxWidth: 500,
					p: 2,
				}}
			>
				{children}
			</Box>
		</Drawer>
	);
};

export default T4Drawer;
