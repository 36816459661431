import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useReferenceDataFetcher } from 'shared/hooks/useReferenceDataFetcher';
import { ObjectPathParams } from '../../../../../../shared/constants/paths';
import { DocumentListView } from '../../../../documents/components/documentListView';
import DocumentListViewModel from '../../../../documents/models/documentListViewModel';
import { useProfileView } from '../../../../entityProfile/providers/entityProfileContextProvider';
import { FieldViews } from '../../../../shared/fieldSets/fieldViews/fieldViewTypes';

export const ObjectDocumentsPage: FC = observer(() => {
	const { objectId, objectType } = useParams<ObjectPathParams>();
	const { fetch } = useReferenceDataFetcher();
	const history = useHistory();

	const viewModel = useMemo(
		() => new DocumentListViewModel(objectType, objectId, history, fetch),
		[objectType, objectId, history, fetch],
	);
	const handleError = useErrorHandler();
	const { setViewType } = useProfileView();

	useEffect(() => {
		setViewType(FieldViews.default);
	}, [setViewType]);

	useEffect(() => {
		viewModel.load().catch((error) => handleError(error));
	}, [viewModel, handleError]);

	return (
		<Box
			sx={{
				paddingX: '2rem',
				paddingY: '1.5rem',
				height: '100%',
			}}
		>
			<DocumentListView viewModel={viewModel} />
		</Box>
	);
});
