import { Edit } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader, PageHeaderProps } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { ObjectDocumentPathParams } from 'shared/constants/paths';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { useUser } from 'shared/hooks/useUser';
import { DocumentView } from '../components/documentView';
import { DocumentViewModes, useDocumentView } from '../hooks/useDocumentView';
import { GlobalDocumentsRepository } from '../shared/globalDocumentsRepository';
import { GlobalDocumentViewModel } from './models/globalDocumentViewModel';
import { useHeader } from 'shared/providers/contexts/headerContext';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';

export const GlobalDocumentPage: FC = observer(() => {
	const handleError = useErrorHandler();
	const { documentId } = useParams<ObjectDocumentPathParams>();
	const { viewMode, setViewMode } = useDocumentView();
	const { isAuthor } = useUser();
	const { updateLastAutoSave, breadcrumbs, updateObjectName } = useHeader();

	const repository = useMemo(() => new GlobalDocumentsRepository(), []);
	const [viewModel] = useState(
		new GlobalDocumentViewModel(documentId, repository, updateLastAutoSave),
	);

	useEffect(() => {
		updateObjectName('');
		viewModel
			.load()
			.catch((error) => handleError(error))
			.finally(() => {
				if (viewModel.document?.getName()) {
					updateObjectName(viewModel.document?.getName() || '');
				}
			});
	}, [handleError, viewModel, updateObjectName]);

	const actions = useMemo(() => {
		let availibleActions: PageHeaderProps['actions'] = [
			{
				stonlyId: 'header-view-button',
				testId: 'header-view-button',
				label: 'View',
				action: () => setViewMode(DocumentViewModes.default),
			},
		];

		if (isAuthor) {
			availibleActions.push({
				stonlyId: 'header-edit-button',
				testId: 'header-edit-button',
				label: 'Edit',
				startIcon: <Edit />,
				action: () => setViewMode(DocumentViewModes.edit),
			});
		}

		return availibleActions;
	}, [isAuthor, setViewMode]);

	return (
		<T4View
			loading={viewModel.isLoading()}
			header={
				<PageHeader
					id={viewModel.getTitle() ?? 'documentsview'}
					title={viewModel.getTitle()}
					isEditMode={viewMode === DocumentViewModes.edit}
					showDoneButton={viewMode !== DocumentViewModes.default}
					actions={actions}
					breadcrumbs={<BreadcrumbList breadcrumbs={breadcrumbs} />}
				/>
			}
		>
			<DocumentView
				isReadonly={viewMode === DocumentViewModes.default}
				viewModel={viewModel}
				sx={{
					paddingBottom: '2rem',
				}}
			/>
		</T4View>
	);
});
