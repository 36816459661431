import Add from '@mui/icons-material/Add';
import {
	Box,
	Divider,
	Grid,
	Link,
	MenuItem,
	Typography,
	useTheme,
} from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { observer } from 'mobx-react-lite';
import {
	Option,
	OptionList,
	Tab,
} from 'modules/clients/customer-api/src/api/common';
import { FC, useCallback, useMemo, useState } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { FormModal } from 'shared/components/formModal';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { useUser } from 'shared/hooks/useUser';
import { getOptionsMenuColDef } from 'shared/utilities/dataGrid/dataGridUtils';
import { Collection } from '../object/models/collection';
import { PhoneNumber } from '../object/models/phoneNumber';
import { T4Object } from '../object/models/t4Object';
import { ContactDrawer } from './contactDrawer';
import { CreateContactModal } from './createContactModal';
import { useContactsView } from './hooks/useContactsView';

//#region Constants

export const contactCollectionIdentifier = 't4_fieldset_contact';
export const contactTypeFieldIdentifier = 't4_field_contact_type';
export const phoneNumberCollectionIdentifier =
	't4_fieldset_contact_phone_number';
export const phoneNumberTypeIdentifier = 't4_field_contact_phone_number_type';

//#endregion

//#region Models

export type ContactPhoneNumber = {
	type: Option | undefined;
	phoneNumber: PhoneNumber | undefined;
};

export type Contact = {
	id: string;
	type: Option | undefined;
	name: string;
	title: string;
	primaryEmail: string;
	secondaryEmail: string;
	phoneNumbers: ContactPhoneNumber[];
};

//#endregion

//#region Functions

export function formatPhoneNumber(
	phoneNumber: PhoneNumber | undefined,
): string {
	let formattedPhoneNumber = '';

	if (phoneNumber) {
		const addValue = (value: string) => {
			if (value) {
				if (formattedPhoneNumber) {
					formattedPhoneNumber += ' ';
				}

				formattedPhoneNumber += value;
			}
		};

		if (phoneNumber.countryCode) {
			addValue(phoneNumber.countryCode);
		}

		if (phoneNumber.number) {
			addValue(phoneNumber.number);
		}

		if (phoneNumber.extension) {
			addValue(`x${phoneNumber.extension}`);
		}
	}

	return formattedPhoneNumber;
}

//#endregion

export type ContactsViewProps = {
	tabId: string;
	object: T4Object;
	tab: Tab;
	hidden: boolean;
	refetchObject: () => Promise<void>;
};

export const ContactsView: FC<ContactsViewProps> = observer(
	({ tabId, object, tab, hidden, refetchObject }) => {
		const theme = useTheme();
		const { isAuthor } = useUser();
		const { viewType, setViewType } = useProfileView();
		const {
			loading,
			deleting,
			deleteModalOpen,
			deleteError,
			deletingType,
			setDeleteModalOpen,
			deleteCollection,
			setDeleteCollectionId,
			setDeletingType,
			refetch,
		} = useContactsView(object.id, object.objectType, refetchObject);

		const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
		const [isContactDrawerOpen, setIsContactDrawerOpen] =
			useState<boolean>(false);
		const [collectionId, setCollectionId] = useState<string>();

		const contactTypeList = useMemo<OptionList | undefined>(
			() =>
				tab.fieldGroups
					.filter((x) => x.identifier === contactCollectionIdentifier)?.[0]
					?.fields.find((x) => x.identifier === contactTypeFieldIdentifier)
					?.optionList,
			[tab.fieldGroups],
		);
		const contacts: Contact[] = useMemo(
			() =>
				object.collections
					.filter((x) => x.identifier === contactCollectionIdentifier)
					.map((collection) => {
						const typeId = collection.fields.find(
							(x) => x.identifier === 't4_field_contact_type',
						)?.approvedOptionSourceId;

						const contactTypeOptions = (
							tab?.fieldGroups[0].fields.find(
								(x) => x.identifier === 't4_field_contact_type',
							)?.optionList?.options || []
						).find((x) => x.id === typeId);

						const getField = (
							identifier: string,
							currentCollection?: Collection,
						) => {
							const col = currentCollection ? currentCollection : collection;
							return col.fields.find((x) => x.identifier === identifier);
						};
						const phoneNumbers: ContactPhoneNumber[] = object.collections
							.filter(
								(x) =>
									x.parentCollectionId === collection.id &&
									x.identifier === 't4_fieldset_contact_phone_number',
							)
							.sort((a, b) => {
								const getPhoneNumberType = (collection: Collection): string =>
									(collection.fields.find(
										(x) =>
											x.identifier === 't4_field_contact_phone_number_type',
									)?.approvedValue as string) ?? '';

								return getPhoneNumberType(a).localeCompare(
									getPhoneNumberType(b),
								);
							})
							.map((x) => {
								const typeField = x.fields.find(
									(x) => x.identifier === 't4_field_contact_phone_number_type',
								);
								const type = tab.fieldGroups[0].childFieldGroups[0].fields
									.find(
										(x) =>
											x.identifier === 't4_field_contact_phone_number_type',
									)
									?.optionList?.options.find(
										(x) => x.id === typeField?.approvedOptionSourceId,
									);

								return {
									type: type,
									phoneNumber: getField('t4_field_contact_phone_number', x)
										?.approvedPhoneNumber,
								};
							});

						return {
							id: collection.id,
							type: contactTypeOptions,
							name: getField('t4_field_contact_name')?.approvedValue,
							title: getField('t4_field_contact_title_description')
								?.approvedValue,
							primaryEmail: getField('t4_field_contact_primary_email')
								?.approvedValue,
							secondaryEmail: getField('t4_field_contact_secondary_email')
								?.approvedValue,
							phoneNumbers: phoneNumbers,
						};
					}),
			[object, tab],
		);

		const handleContactCreated = useCallback(async (): Promise<void> => {
			await refetch();
		}, [refetch]);

		const handleContactDrawerOpen = useCallback(
			(collectionId: string, mode: FieldViews = FieldViews.default) => {
				setCollectionId(collectionId);
				setViewType(mode);
				setIsContactDrawerOpen(true);
			},
			[setViewType],
		);

		const handleContactDrawerClose = useCallback(() => {
			if (viewType === FieldViews.edit) {
				refetch();
			}
			setIsContactDrawerOpen(false);
			setCollectionId(undefined);
			setViewType(FieldViews.default);
		}, [refetch, viewType, setViewType]);

		return (
			<Box id={tabId} hidden={hidden} sx={{ height: '100%' }}>
				<Grid container flexDirection="column" sx={{ height: '100%', gap: 2 }}>
					<Grid
						container
						item
						xs="auto"
						sx={{
							justifyContent: 'space-between',
						}}
					>
						<Grid item xs="auto" sx={{ alignContent: 'end' }}>
							<Typography variant="h3">Contacts</Typography>
						</Grid>
						{isAuthor && (
							<Grid item xs="auto">
								<T4Button
									startIcon={<Add />}
									variant="outlined"
									color="primary"
									onClick={() => setIsCreateModalOpen(true)}
								>
									Add Contact
								</T4Button>
							</Grid>
						)}
					</Grid>
					<Grid item xs={true}>
						<UserPreferencesDataGrid
							tableKey="contactsList"
							stonlyId="contacts"
							rows={contacts}
							calculateColumnWidths={true}
							hideFooter={false}
							loading={loading}
							pagination
							showToolbar
							columnVisibilityModel={{
								type: true,
								name: true,
								title: false,
								primaryEmail: true,
								secondaryEmail: false,
								phoneNumbers: true,
								phoneNumberTypes: true,
							}}
							columns={[
								{
									...getOptionsMenuColDef<Contact>((params) => {
										return (
											<OverflowMenu id={`contact-overflow-menu-${params.id}`}>
												<MenuItem
													onClick={() => handleContactDrawerOpen(params.row.id)}
												>
													View
												</MenuItem>
												{isAuthor && (
													<MenuItem
														onClick={() =>
															handleContactDrawerOpen(
																params.row.id,
																FieldViews.edit,
															)
														}
													>
														Edit
													</MenuItem>
												)}
												{isAuthor && <Divider />}
												{isAuthor && (
													<DeleteMenuItem
														onClick={() => {
															setDeletingType('contact');
															setDeleteCollectionId(params.row.id);
															setDeleteModalOpen(true);
														}}
													/>
												)}
											</OverflowMenu>
										);
									}),
								},
								{
									flex: 1,
									field: 'type',
									headerName: 'Contact Type',
									valueGetter: (params) => params.row.type?.displayName,
								},
								{
									flex: 1,
									field: 'name',
									headerName: 'Name',
								},
								{
									flex: 1,
									field: 'title',
									headerName: 'Title/Description',
								},
								{
									flex: 1,
									field: 'primaryEmail',
									headerName: 'Primary Email',
								},
								{
									flex: 1,
									field: 'secondaryEmail',
									headerName: 'Secondary Email',
								},
								{
									flex: 1,
									field: 'phoneNumbers',
									headerName: 'Phone Numbers',
									valueFormatter: (value) =>
										value.value
											.filter((x: ContactPhoneNumber) => x.phoneNumber?.number)
											.map(
												(x: ContactPhoneNumber) =>
													`${x.type?.displayName}: ${formatPhoneNumber(
														x.phoneNumber,
													)}`,
											)
											.join(', '),
									renderCell: (params) => {
										const numberCount = params.row?.phoneNumbers?.length ?? 0;

										return numberCount > 1 ? (
											<Link
												variant="body2"
												color={theme.palette.denim[500]}
												onClick={() => handleContactDrawerOpen(params.row.id)}
												sx={{ cursor: 'pointer' }}
											>
												{numberCount} Number{numberCount > 1 ? 's' : ''}
											</Link>
										) : numberCount === 1 ? (
											formatPhoneNumber(params.row.phoneNumbers[0].phoneNumber)
										) : (
											''
										);
									},
								},
								{
									flex: 1,
									field: 'phoneNumberTypes',
									headerName: 'Phone Type',
									disableExport: true,
									renderCell: (params) => {
										return [
											...new Set(
												params.row.phoneNumbers.map((x) => x.type?.displayName),
											),
										].join(', ');
									},
								},
							]}
							pageSizeOptions={[10, 25, 50, 100]}
							initialState={{
								pagination: { paginationModel: { pageSize: 10 } },
							}}
							sx={{ height: '100%' }}
						/>
					</Grid>
				</Grid>
				<CreateContactModal
					open={isCreateModalOpen}
					onClose={() => setIsCreateModalOpen(false)}
					onAdd={async () => await handleContactCreated()}
					objectId={object.id}
					objectType={object.objectType}
					contactTypeList={contactTypeList}
				/>
				<ContactDrawer
					object={object}
					tab={tab}
					collectionId={collectionId}
					open={isContactDrawerOpen}
					onClose={() => handleContactDrawerClose()}
					deleteCollection={deleteCollection}
					setDeleteCollectionId={setDeleteCollectionId}
					handleContactDrawerOpen={handleContactDrawerOpen}
				/>
				<FormModal
					open={deleteModalOpen}
					onClose={() => {
						if (!loading) {
							setDeleteModalOpen(false);
						}
					}}
					title="Confirm Delete"
					loading={deleting}
					onSubmit={deleteCollection}
					description={`Are you sure you want to delete this ${deletingType}?`}
					error={deleteError}
					submitButtonLabel="Delete"
					loadingMessage="Deleting..."
					submitButtonColor="error"
					submitDisabled={deleting}
					cancelDisabled={deleting}
				/>
			</Box>
		);
	},
);
