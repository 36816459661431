import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { FC } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { T4DataGridProps } from '../../../../../shared/components/dataGrid/dataGrid';

type AddAccountIdentifiersProps<TData extends GridValidRowModel> = Pick<
	T4DataGridProps<TData>,
	'rows' | 'columns' | 'getRowId'
>;

export const AccountIdentifiersDataGrid: FC<
	AddAccountIdentifiersProps<any>
> = ({ rows, columns, getRowId }) => {
	return (
		<UserPreferencesDataGrid
			tableKey="connectAddIdAccountList"
			stonlyId="connect-add-id-account"
			columns={columns}
			rows={rows}
			rowHeight={90}
			calculateColumnWidths
			autoHeight
			emptyMessage="No accounts were selected."
			sx={{
				'& .Mui-selected': {
					background: '#fff!important',
				},
			}}
			getRowId={getRowId}
		/>
	);
};
