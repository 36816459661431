import {
	Box,
	CircularProgress,
	Grid,
	SxProps,
	Theme,
	Typography,
} from '@mui/material';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, useEffect } from 'react';
import { useT4Layout } from 'shared/hooks/useT4Layout';

export const DrawerWidth = 540;

export const useDrawerStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		width: DrawerWidth,
		flexShrink: 0,
		padding: 20,
	},
	drawerPaper: {
		height: '100%',
		width: DrawerWidth,
		maxWidth: '100%',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(3),
	},
	formControl: {
		marginBottom: theme.spacing(2),
	},
}));

export const drawerBaseTestIds = {
	drawerBase: 'drawer-base',
	drawerTitle: 'drawer-base-title',
	drawerContent: 'drawer-base-content',
	drawerActions: 'drawer-base-actions',
	closeButton: 'drawer-base-button',
};

export interface T4DrawerBaseProps
	extends Omit<DrawerProps, 'title' | 'onClose'> {
	// title of drawer
	title?: ReactNode | string | undefined;

	// function to close the drawer
	onClose: () => void;

	// intializing state for the drawer before any content renders
	initializing?: boolean;

	// loading state for any actions that may be loading
	loading?: boolean;

	// actions displayed at the top of the drawer
	utilityActions?: ReactNode[];

	// tabs component to display
	tabs?: ReactNode;

	// submit actions at the bottom of the drawer
	actions?: ReactNode[];

	// disables T4 layout side nav collapse when true
	disableNavigationCollapse?: boolean;
}

export const T4DrawerBase: FC<T4DrawerBaseProps> = observer(
	({
		open,
		title,
		onClose,
		initializing,
		loading,
		utilityActions,
		tabs,
		actions,
		children,
		disableNavigationCollapse,
	}) => {
		const classes = useDrawerStyles();
		const { sideMenuOpen, setSideMenuOpen } = useT4Layout();

		useEffect(() => {
			if (open && !disableNavigationCollapse) {
				setSideMenuOpen(false);
			}
		}, [open, setSideMenuOpen, sideMenuOpen, disableNavigationCollapse]);

		const gridItemWidthAndPaddingSxBase: SxProps<Theme> = {
			width: '100%!important',
			paddingX: '1.5rem',
		};

		return (
			<Drawer
				className={classes.drawer}
				variant="temporary"
				anchor="right"
				open={open}
				onClose={onClose}
				classes={{
					paper: classes.drawerPaper,
				}}
				sx={{
					'& .MuiPaper-root': {
						marginTop: '48px',
						height: 'calc(100% - 48px)',
					},
				}}
			>
				{initializing ? (
					<Box
						sx={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress size={25} />
					</Box>
				) : (
					<Grid
						container
						direction="column"
						rowGap={1}
						sx={{
							height: '100%',
							paddingY: '0.75rem',
							justifyContent: 'center',
							alignItems: 'center',
							overflowY: 'hidden',
							flexWrap: 'nowrap',
						}}
					>
						<Grid
							container
							item
							xs="auto"
							sx={{
								...gridItemWidthAndPaddingSxBase,
								alignItems: 'center',
							}}
						>
							<Grid item xs={10}>
								<Typography variant="h4" fontWeight={500}>
									{title}
								</Typography>
							</Grid>
							<Grid
								container
								item
								xs
								sx={{ display: 'flex', justifyContent: 'flex-end' }}
							>
								{utilityActions &&
									utilityActions.length > 0 &&
									utilityActions.map((action, index) => (
										<Grid item xs="auto" key={index}>
											{action}
										</Grid>
									))}
							</Grid>
						</Grid>

						<Grid item xs="auto" sx={gridItemWidthAndPaddingSxBase}>
							{tabs}
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								...gridItemWidthAndPaddingSxBase,
								paddingY: '0.75rem',
								overflowY: 'auto',
								scrollbarWidth: 'thin',
							}}
						>
							{children}
						</Grid>

						<Grid container item xs sx={gridItemWidthAndPaddingSxBase}>
							<Grid
								container
								item
								columnSpacing={1}
								xs={12}
								sx={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
							>
								{loading && (
									<Grid
										item
										xs="auto"
										sx={{
											display: 'flex',
											alignItems: 'center',
											paddingBottom: '0.3rem',
										}}
									>
										<Typography noWrap={true} sx={{ marginRight: '0.25rem' }}>
											Loading...
										</Typography>
										<CircularProgress size={25} />
									</Grid>
								)}

								{actions &&
									actions.length > 0 &&
									actions.map((action, index) => (
										<Grid item xs="auto" key={index}>
											{action}
										</Grid>
									))}
							</Grid>
						</Grid>
					</Grid>
				)}
			</Drawer>
		);
	},
);
