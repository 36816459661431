import { AxiosInstance } from 'axios';
import { ObjectType, Read, T4DataResponse } from '../../../types';
import { AccountsEndpoints, accounts } from './accounts';
import { CounterpartyEndpoints, counterparties } from './counterparties';
import { DataImportEndpoints, dataImports } from './dataImports';
import { EntityEndpoints, entities } from './entities';
import {
	LegalEntityGroupEndpoints,
	legalEntityGroups,
} from './legalEntityGroups';
import { PartnerEndpoints, partners } from './partners';
import { StaffEndpoints, staff } from './staff';
import { TagEndpoints, tags } from './tags';

//#region Models

export type Dashboard = {
	counts: DashboardCounts;
	expiringRegistrations: ExpiringRegistration[];
	recentlyUpdated: RecentlyUpdated[];
};

export type DashboardCounts = {
	entities: number;
	partners: number;
	counterparties: number;
	accounts: number;
	staff: number;
	documents: number;
};

export type ExpiringRegistration = {
	legalEntityId: string;
	id: string;
	entityCode: string;
	registrationNumber?: string;
	registrationCountry?: string;
	expirationDate: string;
};

export type RecentlyUpdated = {
	parentObjectId?: string;
	parentObjectType?: ObjectType;
	id: string;
	objectType: ObjectType;
	displayName: string;
	lastUpdated: string;
};

//#endregion

//#region Requests

export type GetDashboardResponse = T4DataResponse<Dashboard>;

//#endregion

export type Entity4Endpoints = {
	entities: EntityEndpoints;
	partners: PartnerEndpoints;
	counterparties: CounterpartyEndpoints;
	accounts: AccountsEndpoints;
	staff: StaffEndpoints;
	dataImports: DataImportEndpoints;
	tags: TagEndpoints;
	legalEntityGroups: LegalEntityGroupEndpoints;
	dashboard: Read<GetDashboardResponse>;
};

export function entity4(axiosInstance: AxiosInstance): Entity4Endpoints {
	return {
		entities: entities(axiosInstance),
		partners: partners(axiosInstance),
		counterparties: counterparties(axiosInstance),
		accounts: accounts(axiosInstance),
		staff: staff(axiosInstance),
		dataImports: dataImports(axiosInstance),
		tags: tags(axiosInstance),
		legalEntityGroups: legalEntityGroups(axiosInstance),
		dashboard: async (_, config = {}) =>
			await axiosInstance.get('api/entity4/dashboard', {
				...config,
			}),
	};
}
