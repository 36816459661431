import { AxiosInstance } from 'axios';
import { FrontendEndpoints, frontend } from './frontend';

export type V1Endpoints = {
	frontend: FrontendEndpoints;
};

export function v1(axiosInstance: AxiosInstance): V1Endpoints {
	return {
		frontend: frontend(axiosInstance),
	};
}
