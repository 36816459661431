import { useState } from 'react';
import { useApplicationConfiguration } from 'shared/hooks/useApplicationConfigurations';
import { useClients } from 'shared/hooks/useClients';
import { ApprovalConfiguration } from './contractObjects';

export type UseApprovalConfigurationProps = {
	loading: boolean;
	open: boolean;
	error: string | undefined;
	configuration: ApprovalConfiguration;
	onToggleApprovals: () => Promise<void>;
	onConfirmDialogClose: () => Promise<void>;
	updateConfiguration: () => Promise<void>;
};

export function useApprovalConfigurations() {
	const { customerApiClient } = useClients();
	const { approvalConfiguration, setApprovalConfiguration } =
		useApplicationConfiguration();

	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState<string | undefined>();

	const toggleApprovals = async () => {
		try {
			setLoading(true);
			setError(undefined);

			const response =
				await customerApiClient.api.configuration.fieldApprovals.toggle({
					approvalTypeId: approvalConfiguration.type.id ?? 1, // brittle,
				});

			setApprovalConfiguration(response.data.value);
		} catch (error: any) {
			setError(error?.message ?? error ?? 'Unknown error');
		} finally {
			setLoading(false);
		}
	};

	const onToggleApprovals = async () => {
		if (!open && approvalConfiguration.isActive) {
			setOpen(true);
		} else {
			await toggleApprovals();
			setOpen(false);
		}
	};

	const updateConfiguration = async (
		approvalsRequired: number,
		rejectionsRequired?: number,
	) => {
		try {
			setLoading(true);
			setError(undefined);

			const response =
				await customerApiClient.api.configuration.fieldApprovals.update({
					configurationId: approvalConfiguration.id,
					approvalsRequired: approvalsRequired,
					rejectionsRequired: rejectionsRequired ?? 1,
				});

			setApprovalConfiguration(response.data.value);
		} catch (error: any) {
			setError(error?.message ?? error ?? 'Unknown error');
		} finally {
			setLoading(false);
		}
	};

	/* Unused currently, however it works and will be used in future */
	/*
	public updateConfiguration = async () => {
		await this.configDataModel.updateConfiguration();
	};

	public getApprovalsError = () =>
		this.configDataModel.updateErrors['approvalsRequired'] ?? undefined;

	public setApprovals = (event: ChangeEvent<any>) => {
		const value = event.target.value;
		const regex = /^[0-9\b]+$/;

		if (value !== '' || regex.test(value)) {
			this.configDataModel.setData(
				value as number,
				this.configDataModel!.configuration!.rejectionsRequired
			);
		}
	};
	*/

	return {
		loading,
		open,
		error,
		configuration: approvalConfiguration,
		onToggleApprovals,
		onConfirmDialogClose: () => setOpen(false),
		updateConfiguration,
	};
}
