import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';
import { Write } from '../../transactions';
import { AccountIntegration } from '../accountIntegrations';
import { Connector } from '../connectors';

//#region Model(s)

export type ConnectionCheck = {
	id: string;
	success: boolean;
	type: string;
	status: string;
	response: string;
	errorMessage: string;
};

//#region Connection

export type ConnectionView = Pick<Connection, 'id'>;

export type ConnectionIndex = Omit<Connection, 'parameters'>;

export type Connection = {
	id: string;
	name: string;
	connector: Connector;
	parameters: ConnectionParameter[];
	accountIntegrations: AccountIntegration[];
	credentialVerification: CredentialVerification;
};

export type ConnectionParameter = {
	id: string;
	key: string;
	value: string | null;
};

export type CredentialVerification = {
	errorMessage: string;
	success: boolean;
};

//#endregion

//#endregion

//#region Request(s)

//#region Get Connections

export type GetConnectionsResponse = T4Response<
	T4DataResponse<ConnectionIndex[]>
>;

//#endregion

//#region Get Connection

export type GetConnectionInput = {
	connectionId: string;
};

export type GetConnectionResponse = T4Response<
	T4DataResponse<Connection>,
	GetConnectionInput
>;

//#endregion

//#region Create Connection

export type CreateConnectionInput = {
	connectorId: string;
	name: string;
	parameters: { [key: string]: string };
};

export type CreateConnectionResponse = T4Response<
	T4DataResponse<ConnectionView>,
	CreateConnectionInput
>;

//#endregion

//#region Update Connection

export type UpdateConnectionInput = {
	connectionId: string;
	name: string;
	parameters: { [key: string]: string };
};

export type UpdateConnectionResponse = T4Response<
	T4DataResponse<ConnectionView>,
	UpdateConnectionInput
>;

//#endregion

//#region Delete Connection

export type DeleteConnectionInput = {
	connectionId: string;
	accountIntegrationId: string;
};

export type DeleteConnectionReponse = T4Response<
	T4DataResponse<AccountIntegration>,
	DeleteConnectionInput
>;

//#endregion

//#endregion

export type ConnectionsEndpoints = {
	getConnections: Read<T4DataResponse<ConnectionIndex[]>>;
	getConnection: Read<T4DataResponse<Connection>, GetConnectionInput>;
	createConnection: Write<
		T4DataResponse<ConnectionView>,
		CreateConnectionInput
	>;
	updateConnection: Write<
		T4DataResponse<ConnectionView>,
		UpdateConnectionInput
	>;
	deleteAccountIntegration: Write<
		T4DataResponse<AccountIntegration>,
		DeleteConnectionInput
	>;
};

export function connections(
	axiosInstance: AxiosInstance,
): ConnectionsEndpoints {
	return {
		getConnections: async (_, config = {}): Promise<GetConnectionsResponse> => {
			return await axiosInstance.get('api/v1.0/cash4/connections', {
				...config,
			});
		},
		getConnection: async (
			data,
			config = {},
		): Promise<GetConnectionResponse> => {
			if (!data?.connectionId) {
				throw new Error("Connection id can't be null.");
			}
			return await axiosInstance.get(
				`api/v1.0/cash4/connections/${data.connectionId}`,
				{
					...config,
				},
			);
		},
		createConnection: async (
			data,
			config = {},
		): Promise<CreateConnectionResponse> => {
			return await axiosInstance.post('api/v1.0/cash4/connections', data, {
				...config,
			});
		},
		updateConnection: async (
			data,
			config = {},
		): Promise<UpdateConnectionResponse> => {
			return await axiosInstance.put('api/v1.0/cash4/connections', data, {
				...config,
			});
		},
		deleteAccountIntegration: async (
			data,
			config = {},
		): Promise<DeleteConnectionReponse> => {
			return await axiosInstance.delete(
				`api/v1.0/cash4/connections/${data.connectionId}/accountintegrations/${data.accountIntegrationId}`,
				{ ...config },
			);
		},
	};
}
