import {
	Checkbox,
	CheckboxProps,
	FormControlLabel,
	Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type VisualizationCheckboxProps = CheckboxProps & {
	label?: string;
};

export const VisualizationCheckbox: FC<VisualizationCheckboxProps> = observer(
	({ children, label, ...rest }) => (
		<FormControlLabel
			key={rest?.key}
			control={
				<Checkbox
					{...rest}
					sx={{
						...rest?.sx,
						padding: '0.25rem',
					}}
				/>
			}
			label={
				label ? (
					<Typography sx={{ fontSize: '0.875rem' }}>{label}</Typography>
				) : undefined
			}
			sx={{
				margin: 0,
			}}
		/>
	),
);
