import { Grid } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4Checkbox } from 'features/entity4/shared/components/atoms/t4Checkbox';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { useSigma } from 'features/shared/sigma/providers/sigmaProvider';
import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useShareReportButton } from 'shared/hooks/useShareReportButton';

export type ShareReportButtonProps = {
	open: boolean;
	close: () => void;
};

export const ShareReportButton: FC<ShareReportButtonProps> = observer(
	({ open, close }) => {
		const { workbook } = useSigma();
		const {
			isLoading,
			error,
			isSubmitDisabled,
			copy,
			name,
			permission,
			setCopy,
			setName,
			setPermission,
			onSubmit,
			resetForm,
		} = useShareReportButton();

		const handleClose = useCallback(() => {
			resetForm();
			close();
		}, [resetForm, close]);

		const handleSubmit = useCallback(() => {
			onSubmit(handleClose);
		}, [handleClose, onSubmit]);

		return (
			<FormModal
				open={open}
				onClose={() => handleClose()}
				onSubmit={() => handleSubmit()}
				fullWidth
				title={'Share ' + workbook?.name + ' with your organization.'}
				description="Allow all users in your organization to view, explore, or edit your report."
				loading={isLoading}
				submitDisabled={isSubmitDisabled}
				submitButtonLabel="Share"
				loadingMessage="Sharing"
			>
				<Grid container spacing={2} direction="column">
					<Grid item>
						<T4Checkbox
							id="report-name"
							label="Send a copy"
							value={copy}
							onChange={(_, x) => setCopy(x)}
						/>
					</Grid>
					{copy ? (
						<Grid item>
							<T4TextFieldV2
								id="report-name"
								label="Share as"
								value={name ?? workbook?.name}
								onChange={setName}
							/>
						</Grid>
					) : (
						<>
							Your report will be moved into your shared folder; however, you
							will still have ownership of it
						</>
					)}
					<Grid item>
						<T4Autocomplete
							id="report-permissions"
							label="Permission"
							value={permission ?? ''}
							onChange={(_, x) => {
								if (x) {
									setPermission(x);
								}
							}}
							required={true}
							options={['Edit', 'Explore']}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<T4AlertStack error={error} />
				</Grid>
			</FormModal>
		);
	},
);
