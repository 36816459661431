import { makeAutoObservable } from 'mobx';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';

export interface IReportEmbedDto {
	reportId: string;
	reportName: string;
	embedUrl: string;
	dataSetId: string;
}
export interface IWorkspaceEmbedDto {
	token: string;
	reports: IReportEmbedDto[];
}

class DashboardPageModel {
	private dataAccessor: () => Promise<IWorkspaceEmbedDto>;
	private _token: string = '';
	private _reports: IReportEmbedDto[] = [];
	private _selectedReport: string = '';

	constructor(accessor?: () => Promise<IWorkspaceEmbedDto>) {
		makeAutoObservable(this);
		if (accessor) {
			this.dataAccessor = accessor;
		} else {
			this.dataAccessor = () =>
				customerApi
					.get<ApiResponse<IWorkspaceEmbedDto>>('/cash4/analyticsStudio')
					.then((response) => response.data.value);
		}
	}

	public init() {
		this.dataAccessor().then(this.handleResponse);
	}

	public getToken() {
		return this._token;
	}

	public getReports() {
		return this._reports;
	}

	public getSelectedReport() {
		return this._reports.find((x) => x.reportId === this._selectedReport);
	}

	public setSelectedReport(reportId: string) {
		this._selectedReport = reportId;
	}

	private handleResponse = (response: IWorkspaceEmbedDto) => {
		const { token, reports } = response;
		this._token = token;
		this._reports = reports;

		if (this._reports && this._reports.length) {
			this.setSelectedReport(this._reports[0].reportId);
		}
	};
}

export default DashboardPageModel;
