import { DialogActions, Grid } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4Modal } from 'features/entity4/shared/components/atoms/t4Modal';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import {
	Option,
	OptionList,
} from 'modules/clients/customer-api/src/api/common';
import { ObjectType } from 'modules/clients/customer-api/src/api/objects';
import { FC, useCallback, useMemo, useState } from 'react';
import { useCreateContact } from './hooks/useCreateContact';

export type CreateContactsModalProps = {
	objectId: string;
	objectType: ObjectType;
	contactTypeList: OptionList | undefined;
	open: boolean;
	onClose?: () => void;
	onAdd: () => Promise<void>;
};

export const CreateContactModal: FC<CreateContactsModalProps> = observer(
	({ open, onClose, onAdd, objectId, objectType, contactTypeList }) => {
		const { isCreating, error, createCollection } = useCreateContact(
			objectId,
			objectType,
		);

		const [contactType, setContactType] = useState<Option>();

		const canSave = useMemo(
			() => contactType !== undefined && !isCreating,
			[contactType, isCreating],
		);

		const handleClose = useCallback(() => {
			onClose?.();
			setContactType(undefined);
		}, [onClose]);

		const handleSelect = useCallback((option: Option | null) => {
			setContactType(option ?? undefined);
		}, []);

		const handleAdd = useCallback(async (): Promise<void> => {
			if (contactType) {
				const result = await createCollection(contactType);

				if (result) {
					onAdd();
					handleClose();
				}
			}
		}, [contactType, createCollection, handleClose, onAdd]);

		return (
			<T4Modal
				open={open}
				title="Create Contact"
				fullWidth
				actions={
					<DialogActions
						sx={{
							padding: '1.5rem',
							paddingTop: '0.5rem',
						}}
					>
						<Grid
							container
							wrap="nowrap"
							sx={{
								justifyContent: 'flex-end',
								gap: 1,
							}}
						>
							<Grid item xs="auto">
								<T4Button color="secondary" onClick={() => handleClose()}>
									Cancel
								</T4Button>
							</Grid>
							<Grid item xs="auto">
								<T4Button
									variant="contained"
									disabled={!canSave}
									onClick={() => handleAdd()}
								>
									Add
								</T4Button>
							</Grid>
						</Grid>
					</DialogActions>
				}
			>
				<Grid container sx={{ gap: 2 }}>
					{error && (
						<Grid item xs={12}>
							<T4AlertStack error={error} />
						</Grid>
					)}
					<Grid item xs={12}>
						<T4Autocomplete<Option>
							label="Contact Type"
							options={contactTypeList?.options ?? []}
							getOptionLabel={(option) => option?.displayName}
							value={contactType ?? null}
							onChange={(_, value) => handleSelect(value)}
						/>
					</Grid>
				</Grid>
			</T4Modal>
		);
	},
);
