import { Grid } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { useSigma } from 'features/shared/sigma/providers/sigmaProvider';
import { observer } from 'mobx-react-lite';
import { SigmaFolder } from 'modules/clients/customer-api/src/sigmaEmbed';
import { FC, useCallback } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useEditReportDetailsButton } from 'shared/hooks/useEditReportDetailsButton';

export type EditReportDetailProps = {
	open: boolean;
	close: () => void;
};

export const EditReportDetailsButton: FC<EditReportDetailProps> = observer(
	({ open, close }) => {
		const { folders, folder, workbook } = useSigma();
		const {
			isLoading,
			error,
			selectedFolder,
			name,
			description,
			setFolder,
			setName,
			setDescription,
			resetForm,
			onSubmit,
		} = useEditReportDetailsButton();

		const handleClose = useCallback(() => {
			resetForm();
			close();
		}, [resetForm, close]);

		const handleSubmit = useCallback(() => {
			onSubmit(handleClose);
		}, [handleClose, onSubmit]);

		return (
			<FormModal
				open={open}
				onClose={() => handleClose()}
				onSubmit={() => handleSubmit()}
				fullWidth
				title={'Edit Report Details: ' + workbook?.name}
				loading={isLoading}
				submitDisabled={isLoading}
				submitButtonLabel="Save"
				loadingMessage="Saving"
			>
				<Grid container spacing={2} direction="column">
					<Grid item>
						<T4TextFieldV2
							id="report-name"
							label="Name"
							placeholder={workbook?.name}
							value={name}
							onChange={setName}
						/>
					</Grid>
					<Grid item>
						<T4Autocomplete<SigmaFolder>
							label="Folder"
							options={folders.filter(
								(x) => x.permission === 'edit' || x.permission === 'contribute',
							)}
							getOptionLabel={(option) => option.name}
							value={selectedFolder ?? folder ?? null}
							onChange={(_, x) => {
								if (x !== null) {
									setFolder(x);
								}
							}}
							required
						/>
					</Grid>
					<Grid item>
						<T4TextFieldV2
							id="report-description"
							label="Description"
							placeholder={workbook?.description}
							value={description}
							onChange={setDescription}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<T4AlertStack error={error} />
				</Grid>
			</FormModal>
		);
	},
);
