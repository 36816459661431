import { GroupRequestDto } from '../groupRepository';
import { brandColors } from '../../../../shared/theme/brandColors';

enum TooltipApprovalText {
	IsModifierPendingAddition = 'You cannot approve your own changes',
	IsModifierPendingRemoval = 'You cannot approve your own changes',
	IsApproverPendingAddition = 'Pending Addition',
	IsApproverPendingRemoval = 'Pending Removal',
}

export const getTooltipText = (
	isModifier: boolean,
	request: GroupRequestDto,
): TooltipApprovalText | undefined => {
	if (isModifier) {
		if (request.type === 'Addition') {
			return TooltipApprovalText.IsModifierPendingAddition;
		}

		if (request.type === 'Removal') {
			return TooltipApprovalText.IsModifierPendingRemoval;
		}
	}

	if (request.type === 'Addition') {
		return TooltipApprovalText.IsApproverPendingAddition;
	}

	if (request.type === 'Removal') {
		return TooltipApprovalText.IsApproverPendingRemoval;
	}
};

export const isModifier = (
	userId: string,
	request: GroupRequestDto,
): boolean => {
	return request.createdBy === userId;
};

export const getIconApprovalColor = (
	isModifier: boolean,
	request: GroupRequestDto,
): string | undefined => {
	if (isModifier) {
		if (request.type === 'Addition') {
			return brandColors.charcoal.light;
		}

		if (request.type === 'Removal') {
			return brandColors.charcoal.light;
		}
	}

	if (request.type === 'Addition') {
		return brandColors.equityGreen['500'];
	}

	if (request.type === 'Removal') {
		return brandColors.errorRed.A100;
	}
};
