import { Search } from '@mui/icons-material';
import { TextField, TextFieldProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type SearchTextFieldProps = TextFieldProps;

export const SearchTextField: FC<SearchTextFieldProps> = observer(
	({ label, value, ...rest }) => (
		<TextField
			{...rest}
			label={label ?? 'Search'}
			value={value ?? ''}
			InputLabelProps={{
				...rest?.InputLabelProps,
				sx: {
					...rest?.InputLabelProps?.sx,
					top: '-7.5px',
					'&.MuiInputLabel-shrink': {
						...(rest?.InputLabelProps?.sx as any)?.['&.MuiInputLabel-shrink'],
						top: 0,
					},
				},
			}}
			InputProps={{
				...rest?.InputProps,
				endAdornment: (
					<>
						{rest?.InputProps?.endAdornment && rest?.InputProps?.endAdornment}
						<Search />
					</>
				),
			}}
			inputProps={{
				...rest?.inputProps,
				style: {
					...rest?.inputProps?.style,
					padding: '8.5px 16px',
				},
			}}
			sx={(theme) => ({
				...(rest?.sx as any),
				alignSelf: 'center',
				margin: 0,
				backgroundColor: 'white',
				'& .MuiInputBase-input': {
					...(rest?.sx as any)?.['& .MuiInputBase-input'],
					color: theme.palette.text.primary,
				},
				'& .MuiFormControlLabel-label': {
					...(rest?.sx as any)?.['& .MuiFormControlLabel-label'],
					color: theme.palette.text.primary,
				},
			})}
		/>
	),
);
