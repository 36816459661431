import { observer } from 'mobx-react-lite';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { USER_PREFERENCES_FIELD_OPTIONS } from '../../../../shared/utilities/dataGrid/dataGridUtils';
import { CreateRelationshipDrawer } from '../components/createRelationshipDrawer/createRelationshipDrawer';
import { DeleteRelationshipModal } from '../components/deleteRelationshipModal';
import { EditRelationshipDrawer } from '../components/editRelationshipDrawer/editRelationshipDrawer';
import { RelationshipsLayout } from '../components/relationshipsLayout';
import { IRelationshipDefinitionData } from '../relationshipApiTypes';
import { StaffPowerOfAttorneyRelationship } from './staffRelationshipsListModel';
import { useStaffRelationshipsViewModel } from './staffRelationshipsViewModel';

export const StaffRelationships: FC = observer(() => {
	const viewModel = useStaffRelationshipsViewModel();
	const history = useHistory();

	const navigateToTab = useCallback(
		(definition: IRelationshipDefinitionData | null) => {
			if (definition) {
				let newTab = viewModel.getTabList()[0];

				if (definition.displayName === 'Signatory') {
					newTab = 'Account Signatory';
				} else if (definition.displayName === 'Power of Attorney') {
					newTab = 'Power of Attorney';
				} else if (definition.category.displayName === 'Staff') {
					newTab = 'Roles';
				} else if (definition.category.displayName === 'Ownership') {
					newTab = 'Ownership';
				}

				history.push(history.location.pathname + `?tab=${newTab}`);
			}
		},
		[history, viewModel],
	);

	return (
		<RelationshipsLayout
			createRelationshipButton={
				<CreateRelationshipDrawer
					viewModel={viewModel.getCreateViewModel()!}
					readOnly={viewModel.loading()}
					navigateToTab={navigateToTab}
				/>
			}
			tabDetails={[
				{
					tab: 'Account Signatory',
					component: (
						<UserPreferencesDataGrid
							stonlyId="account-signatory"
							tableKey="staffRelationshipsAccountSignatory"
							columns={viewModel.getAccountColumns()}
							rows={viewModel.getAccountRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Account Signatory Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
				{
					tab: 'Roles',
					component: (
						<UserPreferencesDataGrid
							stonlyId="roles"
							tableKey="staffRelationshipsRoles"
							columns={viewModel.getRoleColumns()}
							rows={viewModel.getRoleRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Role Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
				{
					tab: 'Ownership',
					component: (
						<UserPreferencesDataGrid
							stonlyId="onwership"
							tableKey="staffRelationshipsOwnership"
							columns={viewModel.getOwnershipColumns()}
							rows={viewModel.getOwnershipRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Ownership Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
				{
					tab: 'Power of Attorney',
					component: (
						<UserPreferencesDataGrid<StaffPowerOfAttorneyRelationship>
							stonlyId="staff-power-of-attorney-relationships"
							tableKey="staff-power-of-attorney-relationships"
							columns={viewModel.getPowerOfAttorneyRelationshipColumns()}
							rows={viewModel.getPowerOfAttoryneyRows()}
							loading={viewModel.loading()}
							errorMessage={viewModel.error()}
							emptyMessage="No Ownership Relationships"
							showToolbar
							showCustomViewButton
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
						/>
					),
				},
			]}
		>
			<EditRelationshipDrawer
				viewModel={viewModel.editRelationshipDrawerViewModel}
			/>
			<DeleteRelationshipModal
				viewModel={viewModel.deleteRelationshipViewModel}
			/>
		</RelationshipsLayout>
	);
});
