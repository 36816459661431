import { AxiosResponse } from 'axios';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from '../../../utilities/api';
import { EntityType } from '../entity4Constants';
import {
	IAspect,
	IAspectApiData,
	ICollectionApiData,
	IEntityApiData,
	IEntityFieldApiData,
	IEntityIdentifierApiData,
	IFieldUpdate,
} from './entitiesApiTypes';
import { Entity } from './objects/entity';
import { EntityCollection } from './objects/entityCollection';

export const getEntityTypeRoute = (entityType: EntityType): string => {
	switch (entityType) {
		case EntityType.Entity:
			return 'entity';
		case EntityType.Partner:
			return 'partner';
		case EntityType.Counterparty:
			return 'counterparty';
		case EntityType.Account:
			return 'account';
		case EntityType.Staff:
			return 'staff';
		case EntityType.Subaccount:
			return 'subaccount';
		default:
			throw new Error(`Route not supported for type: ${entityType}.`);
	}
};

export const getRoute = (
	entityType: EntityType,
	parentEntityType?: EntityType,
	parentEntityId?: string,
): string => {
	if (
		parentEntityType === EntityType.Subaccount ||
		(entityType === EntityType.Subaccount && parentEntityType === undefined)
	)
		throw new Error('Sub Accounts can only be a child entity type.');

	if (parentEntityType && parentEntityId) {
		return `/${getEntityTypeRoute(
			parentEntityType,
		)}/${parentEntityId}/${getEntityTypeRoute(entityType)}`;
	}

	return `/${getEntityTypeRoute(entityType)}`;
};

export class EntityRepository {
	private _route: string;
	private _entityId?: string;

	constructor(
		entityType: EntityType,
		entityId?: string,
		parentEntityType?: EntityType,
		parentEntityId?: string,
	) {
		this._entityId = entityId;

		this._route = getRoute(entityType, parentEntityType, parentEntityId);
	}

	public create(creationFields: IFieldUpdate[]): Promise<string> {
		return customerApi
			.post<ApiResponse<string>>(`${this._route}`, {
				creationFields: creationFields,
			})
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public get(): Promise<AxiosResponse<ApiResponse<IEntityApiData>>> {
		return customerApi
			.get<AxiosResponse<ApiResponse<IEntityApiData>>, any>(
				`${this._route}/${this._entityId}`,
			)
			.then((response) => {
				return response;
			});
	}

	public getIdentifiers(): Promise<IEntityIdentifierApiData[]> {
		return customerApi
			.get<ApiResponse<IEntityIdentifierApiData[]>>(
				`${this._route}/identifiers`,
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public getAll(): Promise<Entity[]> {
		return customerApi
			.get<ApiResponse<IEntityApiData[]>>(`${this._route}`)
			.then((response) => {
				if (response.data.success) {
					return response.data.value.map((x) => new Entity(x));
				}

				throw response.data.error;
			});
	}

	public updateField(fieldUpdate: IFieldUpdate): Promise<IEntityFieldApiData> {
		let entityId = this._entityId;
		if (fieldUpdate.entityId !== undefined) {
			entityId = fieldUpdate.entityId;
		}
		return customerApi
			.patch<ApiResponse<IEntityFieldApiData>>(`${this._route}/${entityId}`, {
				fieldIdentifier: fieldUpdate.fieldIdentifier,
				value: fieldUpdate.value ?? '',
			})
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public createPhoneNumberField(
		fieldIdentifier: string,
		countryCode: string | null,
		number: string | null,
		extension: string | null,
	): Promise<IEntityFieldApiData> {
		return customerApi
			.post<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/fields/phonenumber`,
				{
					identifier: fieldIdentifier,
					countryCode: countryCode,
					number: number,
					extension: extension,
				},
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public updatePhoneNumberField(
		fieldId: string,
		countryCode: string | null,
		number: string | null,
		extension: string | null,
	): Promise<IEntityFieldApiData> {
		return customerApi
			.patch<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/fields/${fieldId}/phonenumber`,
				{
					countryCode: countryCode,
					number: number,
					extension: extension,
				},
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public addCollection(
		entity: Entity,
		collectionIdentifier: string,
		fieldUpdates: IFieldUpdate[],
		parentCollectionId?: string,
	): Promise<EntityCollection> {
		return customerApi
			.post<ApiResponse<ICollectionApiData>>(
				`${this._route}/${this._entityId}/collections`,
				{
					collectionIdentifier,
					parentCollectionId,
					fieldUpdates,
				},
			)
			.then((response) => {
				if (response.data.success) {
					return new EntityCollection(entity, response.data.value);
				}

				throw response.data.error;
			});
	}

	public updateCollectionField(
		entity: Entity,
		collectionId: string,
		collectionIdentifier: string,
		fieldUpdates: IFieldUpdate[],
	): Promise<EntityCollection> {
		return customerApi
			.patch<ApiResponse<ICollectionApiData>>(
				`${this._route}/${this._entityId}/collections/${collectionId}`,
				{
					collectionIdentifier,
					fieldUpdates,
				},
			)
			.then((response) => {
				if (response.data.success) {
					// TODO move to location that needs the mobx model
					// instead return the dto
					return new EntityCollection(entity, response.data.value);
				}

				throw response.data.error;
			});
	}

	public v2UpdateCollectionField(
		collectionId: string,
		fieldId: string,
		value: string,
	): Promise<IEntityFieldApiData> {
		return customerApi
			.patch<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/collections/${collectionId}/fields/${fieldId}`,
				{
					value: value,
				},
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public v2CreateCollectionField(
		collectionId: string,
		fieldIdentifier: string,
		value: string | null,
	): Promise<IEntityFieldApiData> {
		return customerApi
			.post<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/collections/${collectionId}/fields`,
				{
					fieldIdentifier: fieldIdentifier,
					value: value,
				},
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public v2CreatePhoneNumberCollectionField(
		collectionId: string,
		fieldIdentifier: string,
		countryCode: string | null = null,
		number: string | null = null,
		extension: string | null = null,
	): Promise<IEntityFieldApiData> {
		return customerApi
			.post<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/collections/${collectionId}/fields/phonenumber`,
				{
					fieldIdentifier: fieldIdentifier,
					countryCode: countryCode,
					number: number,
					extension: extension,
				},
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public v2UpdatePhoneNumberCollectionField(
		collectionId: string,
		fieldId: string,
		countryCode: string | null,
		number: string | null,
		extension: string | null,
	) {
		return customerApi
			.patch<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/collections/${collectionId}/fields/${fieldId}/phonenumber`,
				{
					countryCode: countryCode,
					number: number,
					extension: extension,
				},
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public deleteCollectionField(collectionId: string): Promise<any> {
		return customerApi
			.delete<ApiResponse<ICollectionApiData>>(
				`${this._route}/${this._entityId}/collections/${collectionId}`,
			)
			.then((response) => {
				if (response.data.success) {
					return true;
				}

				throw response.data.error;
			});
	}

	public unsubmitField(fieldId: string): Promise<IEntityFieldApiData> {
		return customerApi
			.patch<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/fields/${fieldId}/unsubmit`,
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public approveField(fieldId: string): Promise<IEntityFieldApiData> {
		return customerApi
			.patch<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/fields/${fieldId}/approve`,
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public rejectField(
		fieldId: string,
		comment: string,
	): Promise<IEntityFieldApiData> {
		return customerApi
			.patch<ApiResponse<IEntityFieldApiData>>(
				`${this._route}/${this._entityId}/fields/${fieldId}/reject`,
				{
					comment,
				},
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	// #region Aspects Requests
	public addAspect(aspectId: string): Promise<void> {
		return customerApi
			.post<ApiResponse<void>>(`${this._route}/${this._entityId}/aspects`, {
				aspectId,
			})
			.then((response) => {
				if (response.data.success) {
					return response.data.value;
				}

				throw response.data.error;
			});
	}

	public getAspects(): Promise<IAspect[]> {
		return customerApi
			.get<ApiResponse<IAspectApiData>>(
				`${this._route}/${this._entityId}/aspects`,
			)
			.then((response) => {
				if (response.data.success) {
					return response.data.value.aspects;
				}

				throw response.data.error;
			});
	}
	// #endregion
}
