import { History } from 'history';
import { makeAutoObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { jsonToReferenceData } from 'shared/utilities/referenceDataUtilities';
import { paths } from '../../../../../shared/constants/paths';
import { EntityType } from '../../../entity4Constants';
import { FieldViews } from '../../../shared/fieldSets/fieldViews/fieldViewTypes';
import { LocationModel } from '../../../shared/models/locationModel';
import { JurisdictionRepository } from '../jurisdictionRepository';

export class AddJurisdictionViewModel {
	public entityId: string;
	public entityType: EntityType;
	public aspectId: string;
	private _history: History;

	private _open: boolean;
	private _loading: boolean;
	private _error: string;

	private _country: string | null;
	private _countryError: string;
	private _state: string | null;

	private _location: LocationModel;

	constructor(
		entityId: string,
		entityType: EntityType,
		aspectId: string,
		history: History,
	) {
		makeAutoObservable(this);
		this.entityId = entityId;
		this.entityType = entityType;
		this.aspectId = aspectId;
		this._history = history;

		this._open = false;
		this._loading = false;
		this._error = '';

		this._country = null;
		this._countryError = '';
		this._state = null;

		this._location = new LocationModel();
	}

	get open(): boolean {
		return this._open;
	}

	get loading(): boolean {
		return this._loading;
	}

	get error(): string {
		return this._error;
	}

	get country(): ReferenceDataValue | null {
		return jsonToReferenceData(this._country);
	}

	get countryError(): string {
		return this._countryError;
	}

	get state(): ReferenceDataValue | null {
		return jsonToReferenceData(this._state);
	}

	get location(): LocationModel {
		return this._location;
	}

	public openModal = () => {
		this._open = true;
	};

	public closeModal = () => {
		this.setError('');
		this._country = null;
		this._countryError = '';
		this._state = null;
		this._open = false;
		this._location = new LocationModel();
	};

	public setCountry = async (option: ReferenceDataValue | null) => {
		this.setError('');
		await this._location.changeCountry(option);
		this._country = this._location.currentCountry ?? null;
	};

	public setState = async (option: ReferenceDataValue | null) => {
		this.setError('');
		await this._location.changeProvince(option);
		this._state = this._location.currentProvince ?? null;
	};

	public addJurisdiction = async (
		setViewType: (update: FieldViews) => void,
	) => {
		try {
			this._loading = true;
			if (!this.validateCountryExists()) return;

			this.setError('');
			const jurisdictionId =
				await JurisdictionRepository.addRegisteredAgentJurisdiction(
					this.entityId,
					this.entityType,
					{
						country: this._country,
						state: this._state ? this._state : null,
					},
				);

			setViewType(FieldViews.edit);
			this._history.push(
				generatePath(
					paths.entity4.objects.object.registeredAgentDetails
						.registeredAgentDetail.href,
					{
						objectType: this.entityType,
						objectId: this.entityId,
						registeredAgentDetailId: jurisdictionId,
					},
				),
			);
		} catch (error) {
			this.setError(error);
		} finally {
			this._loading = false;
		}
	};

	private validateCountryExists(): boolean {
		if (this.country == null) {
			this._countryError = 'Please select a Country';
			return false;
		}
		this._countryError = '';
		return true;
	}

	private setError = (error: any) => {
		if (error instanceof Error) this._error = error.message;
		else if (typeof error === 'string') this._error = error;
		else throw new Error(`Unhandled error type.`);
	};
}
