/* eslint-disable mobx/missing-observer */
import { Box, Container, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import Logo from '../../images/logos/Treasury4_GrayGreen_Logo.svg';

export const loadingScreenTestIds = {
	loading: 'loading-screen',
};

const LoadingScreen: React.FC = () => (
	<Box
		sx={{
			width: '100vw',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',

			// added to account for <body> padding
			position: 'fixed',
			top: 0,
			left: 0,
		}}
	>
		<Container maxWidth="sm">
			<Box
				marginBottom={3}
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<img
					src={Logo}
					alt="Treasury4"
					data-testid={loadingScreenTestIds.loading}
					height={100}
					width={500}
				/>
			</Box>
			<LinearProgress />
			<Typography textAlign="center" variant="h3" sx={{ marginTop: '8px' }}>
				Making progress...
			</Typography>
		</Container>
	</Box>
);

export default LoadingScreen;
