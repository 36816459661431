import { Grid, Switch, TextField, Typography } from '@mui/material';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { FormModal } from 'shared/components/formModal';
import { stonlyData } from 'stonly/functions';
import { useApprovalConfigurations } from './useApprovalConfigurations';

export const dataTestIds = {
	approvalsToggle: 'entity4-fieldapproval-configuration-toggle',
};

export const stonlyIds = {
	approvalsToggle: 'entity4-fieldapproval-configuration-toggle',
	approvalsInput: 'entity4-fieldapproval-configuration-input',
};

export const FieldApprovalConfiguration: FC = observer(() => {
	const approvalConfigurations = useApprovalConfigurations();

	return (
		<Grid container rowGap={2}>
			<Grid container item rowGap={2} xs={12} sx={{ rowGap: '8px' }}>
				<Grid item xs={12}>
					<Typography variant="h3">Entity4 Field Approvals</Typography>
				</Grid>
				<Grid container item>
					<Grid item xs={true}>
						<Typography>
							When this option is turned on, changes to any field that requires
							approval will require a secondary approver to confirm any field
							changes before they are applied. When turned off, changes are
							automatically applied to those fields.
						</Typography>
					</Grid>
					<Grid item xs={2} />
					<Grid
						item
						xs="auto"
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Switch
							data-testid={dataTestIds.approvalsToggle}
							name="Field Approval Configuration Toggle"
							disabled={approvalConfigurations.loading}
							checked={approvalConfigurations.configuration.isActive ?? false}
							value={approvalConfigurations.configuration.isActive ?? false}
							onChange={() => approvalConfigurations.onToggleApprovals()}
							{...stonlyData({
								id: stonlyIds.approvalsToggle,
							})}
						/>
					</Grid>
				</Grid>

				{approvalConfigurations.configuration.isActive && (
					<Grid container item xs={12} rowGap={2}>
						<Grid
							container
							item
							columnGap={2}
							xs={12}
							sx={{ paddingLeft: '1rem' }}
						>
							<Grid container item direction="column" sm={true}>
								<Typography variant="subtitle2">
									Number of approvals required
								</Typography>
								<Typography>
									Changes to fields that require approval will not be made in
									the system until the defined number of approvals has been
									reached.
								</Typography>
							</Grid>
							<Grid item xs={2} />
							<Grid item xs="auto">
								<TextField
									value={approvalConfigurations.configuration.approvalsRequired}
									type="number"
									size="small"
									{...stonlyData({
										id: stonlyIds.approvalsInput,
									})}
									InputProps={{
										readOnly: true,
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}

				<Grid item xs={12}>
					<T4AlertStack error={approvalConfigurations.error} />
				</Grid>
			</Grid>

			<FormModal
				title="Are you sure you want to turn Approvals off?"
				description="Any pending changes will automatically be applied and cannot be undone."
				submitButtonLabel="Turn Off"
				open={approvalConfigurations.open}
				loading={approvalConfigurations.loading}
				submitDisabled={approvalConfigurations.loading}
				onClose={() => approvalConfigurations.onConfirmDialogClose()}
				onSubmit={() => approvalConfigurations.onToggleApprovals()}
			/>
		</Grid>
	);
});
