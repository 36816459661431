import { observer } from 'mobx-react-lite';
import { Field } from 'modules/clients/customer-api/src/api/common';
import { Moment } from 'moment';
import { FC } from 'react';
import { T4DateField } from '../../shared/components/atoms/t4DateField';

export type DateFieldFormViewProps = {
	field: Field;
	value: Moment | undefined;
	setValue: (field: Field, value: Moment | undefined) => void;
};

export const DateFieldFormView: FC<DateFieldFormViewProps> = observer(
	({ field, value, setValue }) => (
		<T4DateField<Moment>
			id={field.id}
			label={field.name}
			value={value ?? null}
			onChange={(value) => setValue(field, value ?? undefined)}
			required={field.isCreationRequirement}
		/>
	),
);
