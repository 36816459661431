import { Box, Grid, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { GroupPathParams } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { FrontendGroupRepository, GroupRepository } from '../groupRepository';
import { SingleGroupRequestApprovalModal } from '../shared/singleGroupRequestApprovalModal';
import { GroupEntitiesListViewModel } from './groupEntitiesListViewModel';
import { ManageGroupEntitiesButton } from './manageGroupEntitiesButton';

export const GroupEntitiesListRoute: FC = observer(() => {
	const { groupId } = useParams<GroupPathParams>();
	const frontendGroupsRepository = useMemo(
		() => new FrontendGroupRepository(),
		[],
	);
	const groupRepository = useMemo(() => new GroupRepository(), []);
	const history = useHistory();
	const { id } = useUser();

	const [viewModel] = useState(
		new GroupEntitiesListViewModel(
			groupId!,
			frontendGroupsRepository,
			groupRepository,
			history,
			id,
		),
	);

	return <GroupEntitiesList viewModel={viewModel} />;
});

interface IGroupEntitiesListProps {
	viewModel: GroupEntitiesListViewModel;
}

const stonlyIds = {
	groupEntitiesList: 'group-entities-list',
};

export const GroupEntitiesList: FC<IGroupEntitiesListProps> = observer(
	({ viewModel }) => {
		const theme = useTheme();

		useEffect(() => {
			viewModel.loadEntities();
		}, [viewModel]);

		return (
			<Grid container sx={{ rowGap: 1.5 }}>
				<Grid
					container
					item
					xs={12}
					sx={{
						justifyContent: 'space-between',
					}}
				>
					<Grid item xs="auto" />
					<Grid item xs="auto">
						<ManageGroupEntitiesButton
							viewModel={viewModel.manageGroupEntitiesViewModel}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Box
						sx={{
							height: '65vh',
							'& .entity-pending-request': {
								color: theme.palette.text.disabled,
							},
						}}
					>
						<UserPreferencesDataGrid
							tableKey="groupEntitiesList"
							stonlyId={stonlyIds.groupEntitiesList}
							columns={viewModel.getEntityListColumns()}
							rows={viewModel.getEntityListRows()}
							errorMessage={viewModel.getEntityListError()}
							onCellClick={viewModel.onCellClick}
							getRowClassName={viewModel.isRowPending}
							loading={viewModel.getEntityListLoading()}
						/>
					</Box>
				</Grid>
				<SingleGroupRequestApprovalModal
					viewModel={viewModel.manageRequestViewModel}
				/>
			</Grid>
		);
	},
);
