import { useQuery as useTanStackUseQuery } from '@tanstack/react-query';
import { AspectManager } from 'features/entity4/entities/hooks/aspectManager';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { useQuery } from 'features/entity4/shared/hooks/userQuery';
import { observer } from 'mobx-react-lite';
import { ObjectAspect } from 'modules/clients/customer-api/src/api/common';
import { ObjectType } from 'modules/clients/types';
import {
	FC,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ObjectSubaccountPathParams, paths } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import { T4Object as ObjectData } from '../../entities/object/models/t4Object';

export type EntityProfileContextProps = {
	isLoading: boolean;
	loadingObject: boolean;
	viewType: FieldViews;
	setViewType: (update: FieldViews) => void;
	aspectManager: AspectManager;
	objectAspects: ObjectAspect[];
	refetchObject: () => Promise<void>;
	object?: ObjectData;
	subObject?: ObjectData;
	fetchObject: (subaccountId?: string) => void;
};

export const EntityProfileContext = createContext<EntityProfileContextProps>(
	{} as EntityProfileContextProps,
);

export const EntityProfileProvider: FC = observer(({ children }) => {
	const history = useHistory();
	const query = useQuery();
	const { isAuthor, isApprover } = useUser();
	const props = useParams<ObjectSubaccountPathParams>();
	const { objectType, objectId } = props;
	const { customerApiClient } = useClients();
	const {
		data,
		error,
		isLoading: objectAspectsLoading,
		refetch,
	} = useTanStackUseQuery({
		queryKey: ['object-aspects-subaccount'],
		refetchOnWindowFocus: false,
		queryFn: () => {
			let objectTypeName: ObjectType = 'Entity';
			switch (objectType) {
				case 'entities': {
					objectTypeName = 'Entity';
					break;
				}
				case 'partners': {
					objectTypeName = 'Partner';
					break;
				}
				case 'counterparties': {
					objectTypeName = 'Counterparty';
					break;
				}
				case 'staff': {
					objectTypeName = 'Staff';
					break;
				}
				case 'accounts': {
					objectTypeName = 'Account';
					break;
				}
				case 'subaccounts': {
					objectTypeName = 'Subaccount';
					break;
				}
				default: {
					objectTypeName = 'Entity';
					break;
				}
			}

			return customerApiClient.api.common.objectAspects({
				objectType: objectTypeName,
			});
		},
	});

	const [loadingObject, setLoadingObject] = useState<boolean>(true);
	const [object, setObject] = useState<ObjectData | undefined>();
	const [subObject, setSubObject] = useState<ObjectData | undefined>();
	const [fieldView, setFieldView] = useState(FieldViews.default);
	const aspectManager = useMemo(() => {
		return new AspectManager(objectId!, objectType, history);
	}, [objectId, objectType, history]);
	const objectAspects = useMemo<ObjectAspect[]>(
		() => data?.data?.data ?? [],
		[data?.data?.data],
	);

	useEffect(() => {
		const viewParam = query.get('view');
		if (isAuthor && viewParam === 'edit') {
			setFieldView(FieldViews.edit);
		} else if (isApprover && viewParam === 'review') {
			setFieldView(FieldViews.review);
		} else {
			setFieldView(FieldViews.default);
		}
	}, [query, isAuthor, isApprover]);

	useEffect(() => {
		return () => {
			setFieldView(FieldViews.default);
		};
	}, [objectType]);

	const fetchObject = useCallback(
		async (subaccountId?: string) => {
			setLoadingObject(true);

			const objectTypeParam =
				objectType === 'entities'
					? 'entity'
					: objectType === 'partners'
					? 'partner'
					: objectType === 'counterparties'
					? 'counterparty'
					: objectType === 'accounts'
					? 'account'
					: objectType === 'staff'
					? 'staff'
					: 'account';

			const response = await customerApiClient.api.objects.get({
				objectType: objectTypeParam,
				objectId: objectId,
			});

			if (response && response.data && response.data.value) {
				setObject(new ObjectData(response.data.value));
			}

			if (subaccountId) {
				const response = await customerApiClient.api.objects.subaccounts.get({
					objectType: objectTypeParam,
					objectId: objectId,
					subaccountId: subaccountId,
				});

				if (response && response.data && response.data.value) {
					setSubObject(new ObjectData(response.data.value));
				}
			} else {
				setSubObject(undefined);
			}
			setLoadingObject(false);
		},
		[customerApiClient.api.objects, objectId, objectType],
	);

	useEffect(() => {
		fetchObject();
	}, [fetchObject]);

	useEffect(() => {
		refetch();
	}, [refetch, objectType]);

	const refetchObject = useCallback(async () => {
		await fetchObject();
	}, [fetchObject]);

	const setViewType = useCallback(
		(view: FieldViews) => {
			if (isAuthor && view === FieldViews.edit) {
				setFieldView(view);
			} else if (isApprover && view === FieldViews.review) {
				setFieldView(view);
			} else {
				setFieldView(FieldViews.default);
			}
		},
		[isAuthor, isApprover],
	);

	if (
		error ||
		(data && data.data && data.data.error) ||
		(data &&
			data.data &&
			data.data.error &&
			Object.values(data.data.errors).length > 0)
	) {
		history.push(generatePath(paths.error.href));
	}

	return (
		<EntityProfileContext.Provider
			value={{
				isLoading: objectAspectsLoading,
				loadingObject: loadingObject,
				viewType: fieldView,
				aspectManager: aspectManager,
				setViewType: setViewType,
				objectAspects: objectAspects,
				object: object,
				subObject: subObject,
				fetchObject: fetchObject,
				refetchObject: refetchObject,
			}}
		>
			{children}
		</EntityProfileContext.Provider>
	);
});

export const useProfileView = (): EntityProfileContextProps => {
	return useContext(EntityProfileContext);
};
