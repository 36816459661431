import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';
import { ApiResponse } from 'utilities/api';
import { Write } from '../../transactions';
import { SubaccountEndpoints, subaccounts } from './subaccounts';

//#region Models

export type AccountSummary = {
	id: string;
	displayName: string | null;
	naturalAccountNumber: string | null;
	priorDayStatementsAccountNumber: string | null;
	status: string | null;
};

export type Account = T4DataResponse<{
	id: string;
	code: string;
}>;

//#endregion

//#region Requests

export type DeleteAccountRequest = {
	id: string;
};
export type DeleteAccountResponse = T4Response<
	ApiResponse<Account>,
	DeleteAccountRequest
>;

export type AccountsResponse = T4Response<ApiResponse<AccountSummary[]>>;

//#endregion

export type AccountsEndpoints = {
	getAllAccountSummaries: Read<ApiResponse<AccountSummary[]>>;
	sync: Read<void>;
	delete: Write<ApiResponse<Account>, DeleteAccountRequest>;
	subaccounts: SubaccountEndpoints;
};

export function accounts(axiosInstance: AxiosInstance): AccountsEndpoints {
	return {
		getAllAccountSummaries: async (
			_,
			config = {},
		): Promise<AccountsResponse> => {
			return await axiosInstance.get('account/summary', {
				...config,
			});
		},
		sync: async (_, config) => {
			return await axiosInstance.get('account/sync', {
				...config,
			});
		},
		delete: async (data, config = {}): Promise<DeleteAccountResponse> =>
			await axiosInstance.delete(`account/${data.id}`, {
				...config,
			}),
		subaccounts: subaccounts(axiosInstance),
	};
}
