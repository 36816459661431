import { List, ListItemText, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { NavigationItem, NavigationItemProps } from './navigationItem';

export type NavigationSectionProps = {
	title?: string;
	navigationItems: NavigationItemProps[];
};

export const NavigationSection: FC<NavigationSectionProps> = observer(
	({ title, navigationItems }) => (
		<>
			{title && (
				<ListItemText
					sx={{
						padding: '1rem',
						paddingTop: '1.25rem',
						paddingBottom: '0.25rem',
						margin: 0,
					}}
				>
					<Typography
						variant="subtitle2"
						sx={(theme) => ({
							fontWeight: 500,
							color: theme.palette.grey[500],
						})}
					>
						{title}
					</Typography>
				</ListItemText>
			)}
			<List disablePadding={true}>
				{navigationItems.map((navigationItem) => (
					<NavigationItem
						key={`navigation-item-${navigationItem.id}`}
						id={navigationItem.id}
						to={navigationItem.to}
						label={navigationItem.label}
						icon={navigationItem.icon}
						selected={navigationItem.selected}
						disabled={navigationItem.disabled}
						children={navigationItem.children}
					/>
				))}
			</List>
		</>
	),
);
