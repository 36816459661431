import { useSigma } from 'features/shared/sigma/providers/sigmaProvider';
import { useCallback, useEffect, useState } from 'react';
import { useClients } from './useClients';

interface UseEditFolderDetailsButtonProps {
	isLoading: boolean;
	error: string;
	name: string;
	setName: (value: string) => void;
	resetForm: () => void;
	onSubmit: (onSuccess: () => void) => Promise<void>;
}

export function useEditFolderDetailsButtonProps(): UseEditFolderDetailsButtonProps {
	const { folders, folder, updateFolder } = useSigma();
	const { customerApiClient } = useClients();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [error, setError] = useState<string>('');

	const resetForm = useCallback(() => {
		setIsLoading(false);
		setName('');
		setError('');
	}, []);

	const onSubmit = useCallback(
		async (onSuccess: () => void) => {
			try {
				if (folder) {
					const isNameUnqiue = !folders.some((x) => x.name === name);
					if (isNameUnqiue) {
						setIsLoading(true);
						const response = await customerApiClient.sigmaEmbed.editFolder({
							fileId: folder.id,
							name: name,
						});
						if (response && response.data) {
							updateFolder({
								...folder,
								name: name,
							});
							onSuccess();
						} else {
							setError('Error editing folder details.');
						}
					} else {
						setError('Folder with this name already exists.');
					}
				} else {
					setError('No folder selected.');
				}
			} catch {
				setError('Unable to edit folder details.');
			}
		},
		[customerApiClient.sigmaEmbed, folder, folders, name, updateFolder],
	);

	useEffect(() => {
		return () => resetForm();
	}, [resetForm]);

	return {
		isLoading,
		error,
		name,
		setName,
		resetForm,
		onSubmit: onSubmit,
	};
}
