import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { EntityTypeId, getEntityType } from 'features/entity4/entity4Constants';
import { flow, makeAutoObservable } from 'mobx';
import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { ObjectPathParams, paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import { getOptionsMenuColDef } from 'shared/utilities/dataGrid/dataGridUtils';
import { T4Link } from '../../../../shared/components/t4Link';
import { CreateRelationshipDrawerViewModel } from '../components/createRelationshipDrawer/createRelationshipDrawerViewModel';
import { DeleteRelationshipViewModel } from '../components/deleteRelationshipViewModel';
import {
	EditRelationshipDrawerViewModel,
	rowToRelationshipData,
} from '../components/editRelationshipDrawer/editRelationshipDrawerViewModel';
import { RelationshipOptionsCell } from '../components/relationshipOptionCell';
import { BaseRelationship } from '../relationshipsObjectTypes';
import { PartnerRelationshipsListModel } from './partnerRelationshipsListModel';

export type AllowedTab = 'Accounts Owned';

export class PartnerRelationshipsViewModel {
	private readonly _entityId: string;
	private _isAuthor: boolean;

	private listDataModel: PartnerRelationshipsListModel;

	public _createRelationshipViewModel: CreateRelationshipDrawerViewModel;
	public readonly editRelationshipDrawerViewModel: EditRelationshipDrawerViewModel;
	public readonly deleteRelationshipViewModel: DeleteRelationshipViewModel;

	constructor(entityId: string, isAuthor: boolean) {
		makeAutoObservable(this);

		this._entityId = entityId;
		this._isAuthor = isAuthor;

		this.listDataModel = new PartnerRelationshipsListModel(entityId);
		this._createRelationshipViewModel = new CreateRelationshipDrawerViewModel(
			EntityTypeId.Partner,
			this._entityId,
			() => this.load(),
		);
		this.editRelationshipDrawerViewModel = new EditRelationshipDrawerViewModel(
			EntityTypeId.Partner,
			this._entityId,
			() => this.load(),
		);
		this.deleteRelationshipViewModel = new DeleteRelationshipViewModel(
			EntityTypeId.Partner,
			this._entityId,
			() => this.load(),
		);
	}

	public getCreateViewModel = () => this._createRelationshipViewModel;

	public loading = () => this.listDataModel.loading;
	public error = () => this.listDataModel.error;
	public getEntityId = () => this._entityId;
	public isAuthor = () => this._isAuthor;
	public setIsAuthor = (isAuthor: boolean) => (this._isAuthor = isAuthor);

	// tab functions
	public getTabList = (): AllowedTab[] => ['Accounts Owned'];

	public getEntityName = () => this.listDataModel.data?.entityName;

	// account table
	public getAccountColumns = (): GridColDef[] => {
		const cols: GridColDef[] = [
			{
				field: 'accountName',
				headerName: 'Account Name',
				description: 'Account Name',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => {
					const path = generatePath(
						paths.entity4.objects.object.information.href,
						{
							objectType: getEntityType(params.row.entityTypeId)!,
							objectId: params.row.entityId,
						},
					);

					return (
						<T4Link to={path} color="secondary">
							{params.formattedValue}
						</T4Link>
					);
				},
			},
			{
				field: 'accountStatus',
				headerName: 'Account Status',
				description: 'Account Status',
				flex: 1,
			},
			{
				field: 'counterpartyName',
				headerName: 'Counterparty Name',
				description: 'Counterparty Name',
				flex: 1,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveFrom',
				headerName: 'Effective From',
				description: 'Effective From',
				minWidth: 150,
			},
			{
				...getDateColumnDefinition(),
				field: 'effectiveTo',
				headerName: 'Effective To',
				description: 'Effective To',
				minWidth: 150,
			},
		];
		return this.addOptionsColumn(cols);
	};
	public getAccountRows = () => this.listDataModel.data?.accounts ?? [];

	private addOptionsColumn = (cols: GridColDef[]) => {
		if (this._isAuthor) {
			cols.unshift({
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<BaseRelationship, string>) => (
						<RelationshipOptionsCell
							editRelationshipDrawerViewModel={
								this.editRelationshipDrawerViewModel
							}
							deleteRelationshipViewModel={this.deleteRelationshipViewModel}
							relationship={rowToRelationshipData(
								EntityTypeId.Partner,
								params.row,
							)}
						/>
					),
				),
			});
		}

		return cols;
	};

	public load = flow(function* (this: PartnerRelationshipsViewModel) {
		yield this.listDataModel.load();
		this._createRelationshipViewModel.initialize(
			this.getEntityName(),
			this.listDataModel.flatData,
		);
		this.editRelationshipDrawerViewModel.setEntityName(this.getEntityName());
	});
}

export const usePartnerRelationshipsViewModel = () => {
	const { objectId } = useParams<ObjectPathParams>();
	const { isAuthor } = useUser();
	const [viewModel] = useState(
		new PartnerRelationshipsViewModel(objectId, isAuthor),
	);

	useEffect(() => {
		viewModel.load();
	}, [viewModel]);

	useEffect(() => {
		viewModel.setIsAuthor(isAuthor);
	}, [isAuthor, viewModel]);

	return viewModel;
};
