/* eslint-disable mobx/missing-observer */
import { DataGridProProps, gridClasses } from '@mui/x-data-grid-pro';
import { Cash4Account } from 'models/apiModels';
import React, { FC } from 'react';
import { T4DataGrid } from 'shared/components/dataGrid/dataGrid';
import {
	CollapseIcon,
	ExpandIcon,
	columnGroupingModel,
	columns,
} from '../utilities';
import './AccountsList.css';
import DetailPanelContent from './DetailContentPanel';
import { getColumnDefs } from 'features/cash4/shared/gridColDef/GridColDefFactory';

interface AccountsListProps {
	accounts: Cash4Account[];
	disableVirtualization?: boolean;
	stonlyIds: { [key: string]: string };
}

export const AccountsList: FC<AccountsListProps> = ({
	accounts,
	disableVirtualization = false,
	stonlyIds,
}) => {
	const getDetailPanelContent = React.useCallback<
		NonNullable<DataGridProProps['getDetailPanelContent']>
	>(
		({ row }) => <DetailPanelContent row={row} stonlyIds={stonlyIds} />,
		[stonlyIds],
	);

	const getDetailPanelHeight = React.useCallback(() => 470, []);

	return (
		<T4DataGrid
			columns={getColumnDefs(columns)}
			rows={accounts ?? []}
			experimentalFeatures={{ columnGrouping: true }}
			columnGroupingModel={columnGroupingModel}
			getDetailPanelHeight={getDetailPanelHeight}
			getDetailPanelContent={getDetailPanelContent}
			slots={{
				detailPanelExpandIcon: (params) => <ExpandIcon />,
				detailPanelCollapseIcon: (params) => <CollapseIcon />,
			}}
			getRowClassName={(params) => {
				return params.row.isLinked ? 'linked-row' : 'unlinked-row';
			}}
			disableRowSelectionOnClick
			sx={{
				[`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
					outline: 'transparent',
				},
				[`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]:
					{
						outline: 'none',
					},
			}}
			getRowId={(row) => row.id}
			disableVirtualization={disableVirtualization}
			hideFooter={false}
			density="compact"
			stonlyId={stonlyIds.accountsGrid}
		/>
	);
};
