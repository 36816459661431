import { AxiosInstance } from 'axios';
import { Read, T4DataResponse, T4Response } from 'modules/clients/types';
import {
	OptionList,
	ReferenceDataValue,
} from 'shared/types/referenceDataTypes';

export type PowerOfAttorneyType = {
	id: string;
	code: string;
	description: string;
	detail?: string;
	isDeprecated: boolean;
};

export type DataLists = {
	referenceLists: { [key: string]: ReferenceDataValue[] };
	optionLists: { [key: string]: OptionList };
};

export type GetDataListsRequest = {
	objectTypeId?: number;
	optionListIds?: string[];
	referenceListNames?: string[];
};

export type ReferenceDataEndpoints = {
	getPowerOfAttorneys: Read<T4DataResponse<PowerOfAttorneyType[]>>;
	getLists: Read<T4DataResponse<DataLists>, GetDataListsRequest>;
};

export function referenceData(
	axiosInstance: AxiosInstance,
): ReferenceDataEndpoints {
	return {
		getPowerOfAttorneys: async (_, config) =>
			await axiosInstance.get('referencedata/powerofattorneys', { ...config }),
		getLists: async (
			data,
			config,
		): Promise<T4Response<T4DataResponse<DataLists>, GetDataListsRequest>> =>
			await axiosInstance.post('referencedata/getlists', data, config),
	};
}
