import { Collapse, Box } from '@mui/material';
import { EntityFieldBase } from '../../../../entities/objects/fields/field';
import T4Chip from '../../../chips/t4Chip';
import { Field } from '../../fieldTypes';
interface IFieldViewStatusChipProps {
	fieldData?: EntityFieldBase;
	fieldDefinition: Field;
}
export const FieldViewStatusChip: React.FC<IFieldViewStatusChipProps> = ({
	fieldData,
	fieldDefinition,
}) => {
	const getStatusChip = () => {
		if (fieldData?.isRejected) {
			return <T4Chip label="Rejected Approval" chipColor="red" />;
		} else if (fieldData?.isPendingApproval) {
			return <T4Chip label="Pending Approval" chipColor="gold" />;
		} else {
			return <T4Chip label="Approval Field" />;
		}
	};

	return (
		<Collapse in={fieldDefinition.requiresApproval}>
			<Box marginBottom={2}>{getStatusChip()}</Box>
		</Collapse>
	);
};
