import Entity4Logo from 'images/logos/entity4/Entity4_White_Logo.svg';
import Treasury4Logo from 'images/logos/T4_White_Logo.svg';
import Cash4Logo from 'images/logos/cash4/Cash4_White_Logo.svg';
import Payments4Logo from 'images/logos/payments4/Payments4_White_Logo.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';
import { FeatureFlag } from 'shared/constants/featureFlagConstants';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { T4Solution } from '../../t4Solutions';

interface T4SolutionDetails {
	id: T4Solution;
	url: string;
	name: string;
	logo: string;
}

const solutionDetails: Map<T4Solution, T4SolutionDetails> = new Map([
	[
		T4Solution.Administration,
		{
			id: T4Solution.Administration,
			url: paths.administration.href,
			name: 'Administration',
			logo: Treasury4Logo,
		},
	],
	[
		T4Solution.Entity4,
		{
			id: T4Solution.Entity4,
			url: paths.entity4.href,
			name: 'Entity4',
			logo: Entity4Logo,
		},
	],
	[
		T4Solution.Cash4,
		{
			id: T4Solution.Cash4,
			url: paths.cash4.href,
			name: 'Cash4',
			logo: Cash4Logo,
		},
	],
	[
		T4Solution.Payments4,
		{
			id: T4Solution.Payments4,
			url: paths.payments4.paymentStatus.href,
			name: 'Payments4',
			logo: Payments4Logo,
		},
	],
]);

interface UseSolutionPickerProps {
	isPickerEnabled: boolean;
	solutions: T4SolutionDetails[];
	getSolutionLogoSrc: (solution: T4Solution) => string | undefined;
}

export const useSolutionPicker = (): UseSolutionPickerProps => {
	const user = useUser();
	const flags = useFlags();

	const getSolutionLogoSrc = useCallback((solution: T4Solution) => {
		return solutionDetails.get(solution)?.logo;
	}, []);

	const solutions = useMemo(() => {
		const output = [solutionDetails.get(T4Solution.Entity4)!];

		if (flags && flags[FeatureFlag.cash4CategorizationMvp]) {
			output.push(solutionDetails.get(T4Solution.Cash4)!);
		}

		if (flags && flags[FeatureFlag.payments4Module]) {
			output.push(solutionDetails.get(T4Solution.Payments4)!);
		}

		if (user.isAdmin) {
			output.push(solutionDetails.get(T4Solution.Administration)!);
		}

		return output;
	}, [user, flags]);

	return {
		isPickerEnabled: solutions.length > 1,
		solutions,
		getSolutionLogoSrc,
	};
};
