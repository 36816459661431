import { Link } from '@mui/material';
import { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { flow, makeAutoObservable } from 'mobx';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { ReferenceDataValue } from 'shared/types/referenceDataTypes';
import { paths } from '../../../../../shared/constants/paths';
import { EntityType } from '../../../entity4Constants';
import { AdornmentType } from '../../../shared/components/molecules/inputAdornmentWithModal';
import { LocationModel } from '../../../shared/models/locationModel';
import { IAssociatedRegistrationsDto } from '../jurisdictionApiTypes';
import { JurisdictionRepository } from '../jurisdictionRepository';
import { Jurisdiction } from './jurisdictionModel';
import { removeIdentifierFromReferenceDisplayName } from 'shared/utilities/referenceDataUtilities';

export class RegisteredAgentJurisdictionViewModel {
	public entityId: string;
	public entityType: EntityType;

	public jurisdictionId: string;
	public jurisdiction: Jurisdiction | null = null;

	private _loading: boolean;
	private _error: string;

	private _location: LocationModel;

	private _associatedRegistrations: IAssociatedRegistrationsDto[] = [];
	private _loadingAssociatedRegistrations = false;
	private _associatedRegistrationsError = '';

	private _updateLastAutoSave: (autoSaveDate?: Date) => void;

	constructor(
		entityId: string,
		entityType: EntityType,
		jurisdictionId: string,
		updateLastAutoSave: (autoSaveDate?: Date) => void,
	) {
		makeAutoObservable(this);
		this.entityId = entityId;
		this.entityType = entityType;
		this.jurisdictionId = jurisdictionId;

		this._loading = false;
		this._error = '';
		this._location = new LocationModel();

		this._updateLastAutoSave = updateLastAutoSave;
	}

	// getters
	get loading(): boolean {
		return this._loading;
	}

	get error(): string {
		return this._error;
	}

	get location(): LocationModel {
		return this._location;
	}

	get formattedLocation(): string {
		if (this.jurisdiction?.country) {
			const country = JSON.parse(this.jurisdiction?.country);
			const parsedCountry = removeIdentifierFromReferenceDisplayName(country);
			if (this.jurisdiction?.state) {
				const state = JSON.parse(this.jurisdiction.state);
				const parsedState = removeIdentifierFromReferenceDisplayName(state);
				return `${parsedCountry}, ${parsedState}`;
			}
			return parsedCountry;
		}
		return '';
	}

	get countryOptions(): ReferenceDataValue[] {
		const countries = this._location.countryOptions;
		if (countries.length) {
			return countries;
		}
		return [this.jurisdiction!.countryOption!];
	}

	get countryAdornment(): AdornmentType {
		if (this._location.loadingCountries) return 'loading';
		return this.getAdornmentType('country');
	}

	get stateAdornment(): AdornmentType {
		if (this._location.loadingCountries) return 'loading';
		return this.getAdornmentType('state');
	}

	private getAdornmentType = (key: keyof Jurisdiction): AdornmentType => {
		if (this.jurisdiction!.isWaiting(key)) return 'loading';
		else if (this.jurisdiction!.isSaved(key)) return 'success';
		return 'info';
	};

	get provinceOptions(): ReferenceDataValue[] {
		const provinces = this._location.provinceOptions;
		if (provinces.length) {
			return provinces;
		}
		return this.jurisdiction!.stateOption
			? [this.jurisdiction!.stateOption!]
			: [];
	}

	// setters
	public setCountry = async (option: ReferenceDataValue | null) => {
		await this._location.changeCountry(option);
		await this.jurisdiction
			?.setCountry(this._location.currentCountry ?? null)
			.finally(() => this._updateLastAutoSave());
		await this.loadAssociatedRegistrations();
	};

	public setState = async (option: ReferenceDataValue | null) => {
		await this._location.changeProvince(option);
		await this.jurisdiction
			?.setState(this._location.currentProvince ?? null)
			.finally(() => this._updateLastAutoSave());
		await this.loadAssociatedRegistrations();
	};

	private setError = (error: any) => {
		if (error instanceof Error) this._error = error.message;
		else if (typeof error === 'string') this._error = error;
		else throw new Error(`Unhandled error type.`);
	};

	// loading functions
	public load = flow(function* (this: RegisteredAgentJurisdictionViewModel) {
		try {
			this._loading = true;
			this.setError('');

			const jurisdiction =
				yield JurisdictionRepository.getRegisteredAgentJurisdiction(
					this.entityId,
					this.entityType,
					this.jurisdictionId,
				).catch((error) => this.setError(error));

			this.jurisdiction = new Jurisdiction(jurisdiction, this.entityType);
			this._updateLastAutoSave(this.jurisdiction.updatedDate);
			this._location.initializeValues(
				this.jurisdiction.country!,
				this.jurisdiction.state ?? undefined,
			);
		} finally {
			this._loading = false;
		}

		yield this.loadAssociatedRegistrations();
	});

	// associated registrations table functions
	public get associatedRegistrationColumns(): any {
		return [
			{
				field: 'entityName',
				headerName: 'Entity',
				flex: 3,
				renderCell: (params: GridRenderCellParams) => (
					<Link
						component={RouterLink}
						to={this.getPath(params, 'entity')}
						color="secondary"
					>
						{params.value}
					</Link>
				),
			},
			{
				field: 'registrationNumber',
				headerName: 'Registration',
				flex: 1,
				renderCell: (params: GridRenderCellParams) => (
					<Link
						component={RouterLink}
						to={this.getPath(params, 'registration')}
						color="secondary"
					>
						{params.value}
					</Link>
				),
			},
			{
				field: 'registrationStatus',
				headerName: 'Status',
				flex: 1,
			},
		];
	}

	public get associatedRegistrationRows(): any {
		return this._associatedRegistrations.map((x) => {
			return {
				id: x.registrationId,
				entityName: x.entityName,
				registrationNumber: x.registrationNumber,
				registrationStatus: x.registrationStatus,
			};
		});
	}

	public get loadingAssociatedRegistrations(): boolean {
		return this._loadingAssociatedRegistrations;
	}

	public get associatedRegistrationsError(): string | undefined {
		return this._associatedRegistrationsError !== ''
			? this._associatedRegistrationsError
			: undefined;
	}

	public getPath = (params: GridCellParams, to: 'entity' | 'registration') => {
		const selected = this._associatedRegistrations.find((x) => {
			return x.registrationId === params.id;
		});
		if (!selected) return '';

		if (to === 'entity') {
			return generatePath(paths.entity4.objects.object.information.href, {
				objectType: EntityType.Entity,
				objectId: selected.entityId,
			});
		}

		return generatePath(
			paths.entity4.objects.object.registrations.registration.href,
			{
				objectType: EntityType.Entity,
				objectId: selected.entityId,
				registrationId: selected.registrationId,
			},
		);
	};

	public loadAssociatedRegistrations = async () => {
		try {
			this._loadingAssociatedRegistrations = true;
			this._associatedRegistrationsError = '';

			this._associatedRegistrations =
				await JurisdictionRepository.getAssociatedRegistrations(
					this.entityId,
					this.entityType,
					this.jurisdictionId,
				);
		} catch (error) {
			this._associatedRegistrationsError = error as string;
		} finally {
			this._loadingAssociatedRegistrations = false;
		}
	};
}
