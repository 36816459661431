import { Grid } from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import EntityFieldRenderer from '../../components/entityFieldRenderer';

export const ObjectLegalPage = observer(() => {
	const { loadingObject, object, objectAspects } = useProfileView();

	const legalAspect = useMemo(
		() => objectAspects.find((x) => x.aspect.name === 'Legal')?.aspect,
		[objectAspects],
	);

	return (
		<Grid container sx={{ padding: 0 }}>
			<EntityFieldRenderer
				loading={loadingObject}
				object={object}
				aspectOverride={legalAspect}
				disableV1Rendering
			/>
		</Grid>
	);
});
