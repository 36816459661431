import { Add, SwapHoriz } from '@mui/icons-material';
import {
	Box,
	Button,
	Collapse,
	Grid,
	IconButton,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { IEntityIdentifierApiData } from 'features/entity4/entities/entitiesApiTypes';
import { EntityTypeId } from 'features/entity4/entity4Constants';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { Moment } from 'moment';
import { FC } from 'react';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { FormModal } from 'shared/components/formModal';
import { getCorrectArticle } from 'utilities/stringUtils';
import { stonlyData } from '../../../../stonly/functions';
import { GeneralRelationshipFields } from '../components/generalRelationshipsFields';
import { IRelationshipDefinitionData } from '../relationshipApiTypes';
import { RelationshipModalVerbiage } from '../relationshipConstants';
import { CreateAccountRelationshipDrawerViewModel } from './createAccountRelationshipDrawerViewModel';

interface ICreateDrawerProps {
	viewModel: CreateAccountRelationshipDrawerViewModel;
	readOnly: boolean;
	navigateToTab: (definition: IRelationshipDefinitionData | null) => void;
}

export const stonlyIds = {
	addButton: 'relationship-add-button',
	createButton: 'submit-button',
	cancelButton: 'cancel-button',
};

export const testIds = {
	addButton: 'add-button',
	createButton: 'create-button',
	cancelButton: 'cancel-button',
};

export const CreateAccountRelationshipDrawer: FC<ICreateDrawerProps> = observer(
	({ viewModel, readOnly, navigateToTab }) => {
		const theme = useTheme();

		return (
			<>
				<Button
					data-testid={testIds.addButton}
					{...stonlyData({ id: stonlyIds.addButton })}
					onClick={() => viewModel.openDrawer()}
					startIcon={<Add />}
					variant="outlined"
					color="primary"
					disabled={readOnly}
				>
					Add Relationship
				</Button>

				<T4DrawerBase
					open={viewModel.isOpen()}
					onClose={() => viewModel.checkCloseDrawer()}
					title={RelationshipModalVerbiage.create.title}
					loading={viewModel.isCreateLoading()}
					actions={[
						<DrawerCancelButton
							stonlyId={stonlyIds.cancelButton}
							label={RelationshipModalVerbiage.create.cancelTitle}
							onCancel={() => viewModel.checkCloseDrawer()}
						/>,
						<DrawerSubmitButton
							stonlyId={stonlyIds.createButton}
							label={RelationshipModalVerbiage.create.submitTitle}
							onSubmit={async () => {
								const definition: any =
									await viewModel.checkCreateRelationship();
								navigateToTab(definition);
							}}
							disabled={viewModel.isSubmitDisabled()}
						/>,
					]}
				>
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="h4" sx={{ marginY: '12px' }}>
								{viewModel.getEntityName()}
							</Typography>
						</Grid>

						<Grid container>
							<Grid item xs={12} sx={{ marginY: '16px' }}>
								<T4Autocomplete<
									{ id: EntityTypeId; name: string },
									false,
									false,
									false
								>
									label="Relationship Type"
									options={viewModel.getPossibleEntityTypes()}
									getOptionLabel={(entityType) => entityType?.name ?? ''}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									value={viewModel.getRelatedObjectEntityType()}
									onSelect={async (
										value: { id: EntityTypeId; name: string } | null,
									) => await viewModel.setRelatedObjectEntityType(value)}
									readOnly={viewModel.isCreateLoading()}
									placeholder={'Select a relationship type'}
									required
								/>
							</Grid>

							<Collapse in={Boolean(viewModel.getRelatedObjectEntityType())}>
								<Grid item xs={12} sx={{ marginBottom: '16px' }}>
									<T4Autocomplete<
										IRelationshipDefinitionData,
										false,
										false,
										false
									>
										label="Relationship"
										options={viewModel.getDefinitions()}
										getOptionLabel={(entityType) =>
											entityType?.displayName ?? ''
										}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										value={viewModel.getSelectedDefinition()}
										onSelect={(value: IRelationshipDefinitionData | null) =>
											viewModel.setSelectedDefinition(value)
										}
										readOnly={viewModel.isCreateLoading()}
										placeholder={'Select a relationship'}
										required
									/>
								</Grid>

								<Grid item xs={12} sx={{ marginBottom: '16px' }}>
									<T4Autocomplete<IEntityIdentifierApiData, false, false, false>
										label={viewModel.getRelatedObjectsLabel()}
										options={viewModel.getRelatedObjects()}
										getOptionLabel={(entity) => entity?.displayName ?? ''}
										isOptionEqualToValue={(option, value) =>
											option.id === value.id
										}
										value={viewModel.getRelatedObject()}
										onSelect={(value: IEntityIdentifierApiData | null) =>
											viewModel.setRelatedObject(value)
										}
										readOnly={
											viewModel.isCreateLoading() ||
											viewModel.isRelatedEntitiesLoading()
										}
										placeholder={`Select ${getCorrectArticle(
											viewModel.getRelatedObjectsLabel(),
										)} ${viewModel.getRelatedObjectsLabel()}`}
										required
									/>
								</Grid>

								<Grid
									container
									item
									sx={{
										marginTop: '12px',
										marginBottom: '12px',
									}}
								>
									<Grid
										item
										xs={viewModel.canToggleRelationshipDirection() ? 11 : 12}
										sx={{
											display: 'flex',
											padding: '16px',
											backgroundColor: theme.palette.lightBackground?.main,
											borderRadius: '2px',
										}}
									>
										<Typography variant="body1">
											<Box component="span" sx={{ fontWeight: 'bold' }}>
												{viewModel.getEntityName()}
											</Box>{' '}
											{viewModel.getDirectionDescription()}{' '}
											<Box component="span" sx={{ fontWeight: 'bold' }}>
												{viewModel.getRelatedEntityName()}
											</Box>
											.
										</Typography>
									</Grid>
									{viewModel.canToggleRelationshipDirection() && (
										<Grid
											item
											xs={1}
											sx={{ paddingLeft: '4px', alignSelf: 'center' }}
										>
											<Tooltip title="Switch Relationship Direction">
												<IconButton
													onClick={() =>
														viewModel.toggleRelationshipDirection()
													}
													aria-controls="swap-relationship-direction"
													aria-haspopup="true"
													aria-label="Switch Relationship Direction"
												>
													<SwapHoriz />
												</IconButton>
											</Tooltip>
										</Grid>
									)}
								</Grid>

								<Collapse
									in={Boolean(viewModel.isAccountToAccountRelationship())}
								>
									<Grid
										container
										item
										columnSpacing={1}
										sx={{ marginBottom: '16px' }}
									>
										<Grid item xs={6}>
											<T4Autocomplete<string, false, false, false>
												label="Funding Direction"
												options={viewModel.getFundingDirectionList()}
												value={viewModel.getFundingDirection()}
												onSelect={(value: string | null) =>
													viewModel.setFundingDirection(value)
												}
												readOnly={viewModel.isCreateLoading()}
												required
											/>
										</Grid>

										<Grid item xs={6}>
											<T4Autocomplete<string, false, false, false>
												label="Cash Flow Movement"
												options={viewModel.getCashFlowMovementList()}
												value={viewModel.getCashFlowMovement()}
												onSelect={(value: string | null) =>
													viewModel.setCashFlowMovement(value)
												}
												readOnly={viewModel.isCreateLoading()}
											/>
										</Grid>
									</Grid>

									<Grid item xs={12} sx={{ marginBottom: '16px' }}>
										<T4Autocomplete<string, false, false, false>
											label="Funding Frequency"
											options={viewModel.getFundingFrequencyList()}
											value={viewModel.getFundingFrequency()}
											onSelect={(value: string | null) =>
												viewModel.setFundingFrequency(value)
											}
											readOnly={viewModel.isCreateLoading()}
											placeholder={'Select a funding frequency'}
										/>
									</Grid>
								</Collapse>

								<GeneralRelationshipFields
									effectiveFrom={viewModel.getEffectiveFrom()}
									effectiveFromOnChange={(value: Moment | null) =>
										viewModel.setEffectiveFrom(value)
									}
									effectiveTo={viewModel.getEffectiveTo()}
									effectiveToOnChange={(value: Moment | null) =>
										viewModel.setEffectiveTo(value)
									}
									notes={viewModel.getNotes() ?? ''}
									notesOnChange={(value: string | null) =>
										viewModel.setNotes(value)
									}
									inputDisabled={viewModel.isCreateLoading()}
								/>
							</Collapse>
						</Grid>
					</Grid>

					<Collapse in={Boolean(viewModel.getRelatedEntitiesError())}>
						<Box sx={{ marginY: '8px' }}>
							<T4AlertStack error={viewModel.getRelatedEntitiesError()} />
						</Box>
					</Collapse>

					<Collapse
						in={
							Boolean(viewModel.getCreateError()) ||
							Boolean(viewModel.getCreateErrors().length > 0)
						}
					>
						<Box sx={{ marginY: '8px' }}>
							<T4AlertStack
								error={viewModel.getCreateError()}
								errors={viewModel.getCreateErrors()}
							/>
						</Box>
					</Collapse>

					<FormModal
						open={viewModel.isCancelConfirmationOpen()}
						onClose={() => viewModel.closeCancelConfirmation()}
						onSubmit={async () => viewModel.closeDrawer()}
						submitButtonLabel={
							RelationshipModalVerbiage.cancelConfirmation.submitButtonLabel
						}
						cancelButtonLabel={
							RelationshipModalVerbiage.cancelConfirmation.cancelButtonLabel
						}
						title={RelationshipModalVerbiage.cancelConfirmation.title}
						description={
							RelationshipModalVerbiage.cancelConfirmation.description
						}
						loading={false}
					/>

					<FormModal
						open={viewModel.isDuplicateConfirmationOpen()}
						onClose={() => viewModel.closeDuplicateConfirmation()}
						onSubmit={async () => {
							viewModel.closeDuplicateConfirmation();
							const definition: any = await viewModel.createRelationship();
							navigateToTab(definition);
						}}
						submitButtonLabel={
							RelationshipModalVerbiage.duplicateRelationshipConfirmation
								.submitButtonLabel
						}
						title={
							RelationshipModalVerbiage.duplicateRelationshipConfirmation.title
						}
						description={
							RelationshipModalVerbiage.duplicateRelationshipConfirmation
								.description
						}
						loading={false}
					/>
				</T4DrawerBase>
			</>
		);
	},
);
