import { History } from 'history';
import { flow, makeAutoObservable } from 'mobx';
import { generatePath } from 'react-router-dom';
import { parseError } from 'utilities/errors/errorUtils';
import {
	DataLists,
	GetDataListsRequest,
} from '../../../../../modules/clients/customer-api/src/api/referenceData';
import { paths } from '../../../../../shared/constants/paths';
import { Option } from '../../../../../shared/types/referenceDataTypes';
import { displayDocument } from '../../../documents/documentUtils';
import { Document } from '../../../documents/models/document';
import { getEntityType } from '../../../entity4Constants';
import { DOCUMENT_STATUSES } from '../../shared/documentConstants';
import {
	GlobalDocumentListItem,
	GlobalDocumentsRepository,
} from '../../shared/globalDocumentsRepository';

const DOCUMENT_TYPE_LIST_ID = '8b0e5955-5744-491a-a019-1c7be1284ef4';

export default class DocumentGlobalListViewModel {
	private _error?: string = undefined;
	private _loading = true;
	private _documents: GlobalDocumentListItem[] = [];
	private _globalDocumentsRepository: GlobalDocumentsRepository;
	private _fetchReferenceData: (
		request: GetDataListsRequest,
	) => Promise<DataLists | undefined>;
	private _documentTypes: Option[] = [];
	private _documentStatuses: string[] = DOCUMENT_STATUSES;
	private _history: History;

	constructor(
		globalDocumentsRepository: GlobalDocumentsRepository,
		fetchReferenceData: (
			request: GetDataListsRequest,
		) => Promise<DataLists | undefined>,
		history: History,
	) {
		makeAutoObservable(this);

		this._globalDocumentsRepository = globalDocumentsRepository;
		this._fetchReferenceData = fetchReferenceData;
		this._history = history;
	}

	public load = flow(function* (this: DocumentGlobalListViewModel) {
		this._loading = true;
		this._error = undefined;

		try {
			this._documents =
				yield this._globalDocumentsRepository.getAllDocumentListItems();

			const referenceResponse = yield this._fetchReferenceData({
				referenceListNames: [],
				optionListIds: [DOCUMENT_TYPE_LIST_ID],
			});

			if (referenceResponse) {
				this._documentTypes =
					referenceResponse.optionLists[DOCUMENT_TYPE_LIST_ID]?.options ?? [];
			}
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});

	public isLoading(): boolean {
		return this._loading;
	}

	public getError(): string | undefined {
		return this._error;
	}

	public getDocuments() {
		return this._documents.slice();
	}

	public get types(): string[] {
		return [...new Set(this._documentTypes.map((x) => x.displayName))];
	}

	public get statuses(): string[] {
		return this._documentStatuses;
	}

	public navigateToDocument = (
		documentId: string,
		mode: 'view' | 'edit' = 'view',
	): void => {
		const document = this._documents.find(({ id }) => id === documentId);

		if (document) {
			this._history.push(
				generatePath(paths.entity4.objects.object.documents.document.href, {
					objectType: getEntityType(parseInt(document.entityTypeId)),
					objectId: document.entityId,
					documentId: documentId,
				}) + (mode === 'edit' ? `?view=${mode}` : ''),
			);
		}
	};

	public openDocumentInNewTabOrDownload = async (
		documentId: string,
		documentItem: Document,
	): Promise<void> => {
		const document = this._documents.find(({ id }) => id === documentId);

		if (document) {
			const entityType = getEntityType(parseInt(document.entityTypeId));

			if (entityType) {
				await displayDocument(documentItem, entityType, document.entityId);
			}
		}
	};
}
