const E4Logo = ({ fillColor = '#414042', size = 24 }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		viewBox="0 0 98 63"
		width={size}
		height={size * (63 / 98)}
	>
		<path
			d="M10,34.7h0v20h0c0,0,0,0,0,0h31.7v8H.8V.5h40.9v8H5.2h.1c0,.1,4.6,4.7,4.6,4.7v13.3h0c0,0,0,0,0,0h24.8v8H10ZM41.8,62.8H.7V.4h41v8.2H5.4l4.6,4.6v13.3h24.9v8.2H10v19.9h31.7v8.2ZM.9,62.7h40.7v-7.9H9.9v-20.2h24.9v-7.9H9.9v-13.4l-4.9-4.9h36.6V.6H.9v62.1Z"
			fill={fillColor}
		/>
		<path
			d="M88.5,40.1V.6h-10.4l-31.6,39.5v8.2h32.8v14.6h9.3v-10l-4.6-4.6h13.9v-8.2h-9.3ZM79.2,40.1h-22.5l22.5-28v28Z"
			fill={fillColor}
		/>
	</svg>
);

export default E4Logo;
