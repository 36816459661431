import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from '../../../utilities/api';
import { IRegistrationDto } from './registrationTypes';

export class RegistrationRepository {
	public async getByEntityId(entityId: string): Promise<IRegistrationDto[]> {
		const response = await customerApi.get<ApiResponse<IRegistrationDto[]>>(
			`/registration?entityId=${entityId}`
		);

		if (!response.data.success) {
			throw response.data.error;
		}

		return response.data.value;
	}

	public async getByRegistrationId(
		registrationId: string
	): Promise<IRegistrationDto> {
		const response = await customerApi.get<ApiResponse<IRegistrationDto>>(
			`/registration/${registrationId}`
		);

		if (!response.data.success) {
			throw response.data.error;
		}

		return response.data.value;
	}

	public async getExpiringRegistrations() {
		const response = await customerApi.get<ApiResponse<IRegistrationDto[]>>(
			'/registration/expiring'
		);

		if (!response.data.success) {
			throw response.data.error;
		}

		return response.data.value;
	}

	public async create(item: Partial<Omit<IRegistrationDto, 'registrationId'>>) {
		const response = await customerApi.post<ApiResponse<IRegistrationDto>>(
			`/registration`,
			item
		);
		if (!response.data.success) {
			throw response.data.error;
		}

		return response.data.value;
	}

	public async update(item: IRegistrationDto): Promise<IRegistrationDto> {
		const response = await customerApi.patch<ApiResponse<IRegistrationDto>>(
			`/registration/${item.registrationId}`,
			item
		);

		if (!response.data.success) {
			throw response.data.error;
		}

		return response.data.value;
	}

	public async delete(item: IRegistrationDto): Promise<void> {
		const response = await customerApi.delete<ApiResponse<string>>(
			`/registration/${item.registrationId}`
		);
		if (!response.data.success) {
			throw response.data.error;
		}
	}
}

const CommonRegistrationRepository = new RegistrationRepository();
export default CommonRegistrationRepository;
