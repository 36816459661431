import { Edit } from '@mui/icons-material';
import { Alert, Box, Button, Snackbar } from '@mui/material';
import { GridPaginationModel, GridRowParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import { FormModal } from 'shared/components/formModal';
import { stonlyData } from 'stonly/functions';
import { ManageGroupMembersViewModel } from './manageGroupMembersButtonViewModel';

interface IManageGroupMembersButtonProps {
	viewModel: ManageGroupMembersViewModel;
}
export const dataTestIds = {
	manageGroupMembersButton: 'manage-group-members-button',
};

export const stonlyIds = {
	manageGroupMembersButton: 'manage-group-members-button',
};

export const ManageGroupMembersButton: FC<IManageGroupMembersButtonProps> =
	observer(({ viewModel }) => {
		const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
			{
				page: 0,
				pageSize: 25,
			},
		);

		return (
			<>
				<Button
					{...stonlyData({ id: stonlyIds.manageGroupMembersButton })}
					data-testid={dataTestIds.manageGroupMembersButton}
					startIcon={<Edit />}
					type="button"
					variant="outlined"
					color="primary"
					onClick={viewModel.openModal}
				>
					Manage
				</Button>

				<FormModal
					open={viewModel.isModalOpen()}
					onClose={viewModel.closeModal}
					onSubmit={async () => await viewModel.manageMembersSubmit()}
					title="Manage Members"
					description="Add and remove members who can access this group by checking or unchecking the boxes."
					error={viewModel.getManageMembersError()}
					loading={viewModel.getManageMembersLoading()}
					fullWidth
					maxWidth="md"
					submitButtonLabel="Save Changes"
					loadingMessage="Loading..."
					submitDisabled={!viewModel.canSubmitUpdates()}
				>
					<Box sx={{ height: '55vh' }}>
						<UserPreferencesDataGrid
							tableKey="manageGroupMembersButton"
							stonlyId="manage-group-members"
							columns={viewModel.getAvailableMembersListColumns()}
							rows={viewModel.getAvailableMembersListRows()}
							loading={viewModel.getAvailableMembersLoading()}
							errorMessage={viewModel.getAvailableMembersError()}
							checkboxSelection
							rowSelectionModel={viewModel.getSelectionModel()}
							onRowSelectionModelChange={viewModel.onSelectionChange}
							showToolbar
							pagination
							paginationModel={paginationModel}
							onPaginationModelChange={(model) => setPaginationModel(model)}
							pageSizeOptions={[25]}
							hideFooter={false}
							isRowSelectable={(params: GridRowParams) =>
								!params.row.hasOpenRequest
							}
						/>
					</Box>
				</FormModal>

				<Snackbar
					open={viewModel.isSuccessSnackbarOpen()}
					autoHideDuration={6000}
					onClose={viewModel.closeSuccessSnackbar}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				>
					<Alert
						onClose={viewModel.closeSuccessSnackbar}
						severity="success"
						sx={{ width: '100%' }}
					>
						Successfully updated group members.
					</Alert>
				</Snackbar>
			</>
		);
	});
