import { EntityFieldBase } from '../../../entities/objects/fields/field';
import { Field } from '../fieldTypes';
import { FieldViewFieldLayoutProps } from './common/fieldLayout';

export type FieldViewProps = {
	fieldData?: EntityFieldBase;
	fieldDefinition: Field;
	parentField?: EntityFieldBase;
	parentFieldDefinition?: Field;
	fieldLayoutProps?: Partial<FieldViewFieldLayoutProps>;
	customError?: string;
	createField: (value?: string) => void;
};

export enum FieldViews {
	review,
	edit,
	default,
}

export enum ValueStatus {
	Dirty,
	Waiting,
	Saved,
	Errored,
}
