import { EntityType, SubObjectType } from '../entity4Constants';
import {
	CommonDocumentRepository,
	DocumentRepository,
} from './documentRepository';
import { IDocumentDto } from './documentTypes';
import { Document } from './models/document';

export class DocumentStore {
	private _documentRepo: DocumentRepository;

	constructor(documentRepository: DocumentRepository) {
		this._documentRepo = documentRepository;
	}

	public async getDocumentById(
		entityType: EntityType,
		entityId: string,
		documentId: string
	) {
		const dto = await this._documentRepo.getDocumentById(
			entityType,
			entityId,
			documentId
		);
		const fetchedItem = Document.make(dto);

		return fetchedItem;
	}

	public async getDocumentsByEntityId(
		entityType: EntityType,
		entityId: string,
		subObjectId?: string,
		subObjectType?: SubObjectType
	) {
		const dto = await this._documentRepo.getDocumentsByEntityId(
			entityType,
			entityId,
			subObjectId,
			subObjectType
		);
		const fetchedItems = dto.map((docDto) => Document.make(docDto));

		return fetchedItems;
	}

	public async create(
		entityType: EntityType,
		entityId: string,
		partial: Partial<Omit<IDocumentDto, 'documentId'>>,
		file?: File,
		subObjectId?: string,
		subObjectType?: SubObjectType
	) {
		const dto = await this._documentRepo.create(
			entityType,
			entityId,
			partial,
			file,
			subObjectId,
			subObjectType
		);
		const newItem = Document.make(dto);

		return newItem;
	}

	public async update<T extends keyof IDocumentDto>(
		entityType: EntityType,
		entityId: string,
		item: Document,
		key: T,
		value: IDocumentDto[T]
	): Promise<Document> {
		const { documentId, ...safeDto } = item.asDto();

		let modifiedDto: IDocumentDto = {
			...safeDto,
			documentId: documentId,
		};
		if (key === 'name') {
			modifiedDto.name = (value as string)?.trim();
		} else {
			modifiedDto[key] = value;
		}

		const updatedDto = await this._documentRepo.update(
			entityType,
			entityId,
			modifiedDto
		);
		item.sync(updatedDto);

		return item;
	}

	public async remove(
		entityType: EntityType,
		entityId: string,
		item: Document
	) {
		await this._documentRepo.delete(entityType, entityId, item.asDto());
	}

	public async download(
		entityType: EntityType,
		entityId: string,
		documentId: string
	) {
		const documentData = await this._documentRepo.download(
			entityType,
			entityId,
			documentId
		);

		return documentData;
	}
}

export const CommonDocumentStore = new DocumentStore(CommonDocumentRepository);
