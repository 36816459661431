import { Circle } from '@mui/icons-material';
import { Box, Tooltip, useTheme } from '@mui/material';
import { FC, useRef, useState } from 'react';

export const DataImportStatusCell: FC<{
	erroredRecordCount: number;
	status: string | undefined;
}> = ({ erroredRecordCount, status }) => {
	const theme = useTheme();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const valueRef = useRef<Element>(null);

	const onMouseEnter = () => {
		if (
			(valueRef.current?.scrollWidth ?? 0) >
			(valueRef.current?.clientWidth ?? 0)
		)
			setIsOpen(true);
	};

	const onMouseLeave = () => {
		setIsOpen(false);
	};

	const getColor = () => {
		if (status === 'Submitted') {
			return theme.common?.denim?.main;
		} else if (status === 'Processing') {
			return theme.common?.maize?.main;
		} else if (status === 'Complete' && erroredRecordCount === 0) {
			return theme.common?.equityGreen?.main;
		} else if (status === 'Failure') {
			return theme.common?.debtRed?.main;
		} else if (erroredRecordCount > 0) {
			return theme.common?.jasper?.main;
		}
	};

	const formattedStatus =
		erroredRecordCount > 0
			? status === 'Failure'
				? status
				: erroredRecordCount + ' Errors'
			: status;

	return (
		<Box sx={{ display: 'flex', overflow: 'hidden' }}>
			<Circle
				fontSize="small"
				htmlColor={getColor() ?? theme.common?.charcoal?.main}
			/>
			<Tooltip
				open={isOpen}
				title={formattedStatus}
				onOpen={onMouseEnter}
				onClose={onMouseLeave}
				disableFocusListener
				disableTouchListener
			>
				<Box
					ref={valueRef}
					sx={{
						marginLeft: '4px',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{formattedStatus}
				</Box>
			</Tooltip>
		</Box>
	);
};
