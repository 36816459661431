import { AxiosInstance } from 'axios';
import { Read, T4ApiResponse } from 'modules/clients/types';
import { Address, E4ObjectBase } from '..';
import { Account } from '../accounts';

// #region Models

export type Partner = E4ObjectBase & {
	anglicizedLegalName: string | null;
	operatingAddress: Address | null;
};

// #endregion

export type PartnerEndpoints = {
	getAll: Read<T4ApiResponse<Partner[]>>;
	getAccountsOwned: Read<T4ApiResponse<Account[]>, string>;
};

export function partners(axiosInstance: AxiosInstance): PartnerEndpoints {
	return {
		getAll: async (_, config = {}) => {
			return await axiosInstance.get('entity4/partners', {
				...config,
			});
		},
		getAccountsOwned: async (id, config = {}) => {
			return await axiosInstance.get(`entity4/partners/${id}/accounts`, {
				...config,
			});
		},
	};
}
