import { useQuery } from '@tanstack/react-query';
import { PaymentTemplate } from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { T4DataResponse2 } from 'modules/clients/types';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export const PaymentTemplateQueryKeys = {
	getAllPaymentTemplates: 'get-all-payment-templates',
	getPaymentTemplate: 'get-payment-template',
};

export const useGetPaymentTemplate = (templateId: string | null) => {
	const { applicationApiClient } = useClients();

	const load = useCallback(async () => {
		try {
			if (templateId == null) return;
			const response =
				await applicationApiClient.payments4.paymentTemplates.get(templateId);
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<PaymentTemplate>).data;
			else throw new Error();
		} catch {
			throw new Error(
				'Unable to load payment template. Please try again later.',
			);
		}
	}, [templateId, applicationApiClient]);

	return useQuery<PaymentTemplate | undefined, Error>(
		[PaymentTemplateQueryKeys.getPaymentTemplate],
		load,
		{
			refetchOnWindowFocus: false,
			enabled: templateId !== null,
		},
	);
};

export const useGetAllPaymentTemplates = (isEnabled?: boolean) => {
	const { applicationApiClient } = useClients();

	const load = useCallback(async () => {
		try {
			const response =
				await applicationApiClient.payments4.paymentTemplates.getAll();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<PaymentTemplate[]>).data;
			else throw new Error();
		} catch {
			throw new Error(
				'Unable to load payment templates. Please try again later.',
			);
		}
	}, [applicationApiClient]);

	return useQuery<PaymentTemplate[], Error>(
		[PaymentTemplateQueryKeys.getAllPaymentTemplates],
		load,
		{
			refetchOnWindowFocus: false,
			enabled: isEnabled,
		},
	);
};
