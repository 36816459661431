import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
	Box,
	CircularProgress,
	Collapse,
	Grid,
	Tab,
	Typography,
} from '@mui/material';
import { DocumentListView } from 'features/entity4/documents/components/documentListView';
import { HeaderRef } from 'features/entity4/entities/components/entityFieldRenderer';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { observer } from 'mobx-react-lite';
import {
	ObjectAspect,
	Tab as TabData,
} from 'modules/clients/customer-api/src/api/common';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { stonlyData } from '../../../../stonly/functions';
import {
	renderCollection,
	renderFieldGroup,
} from '../../entities/components/functions/v2EntityFieldRenderer';
import { SubAccountViewModel } from './models/subAccountViewModel';

export type SubAccountViewProps = {
	isLoading: boolean;
	subAccount: SubAccountViewModel;
	objectAspects: ObjectAspect[];
};

export const SubAccountView: FC<SubAccountViewProps> = observer(
	({ isLoading, subAccount, objectAspects }) => {
		const { entity4StaffPii } = useT4FeatureFlags();
		const { subObject } = useProfileView();

		useEffect(() => {
			subAccount.documentList.load();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		const informationAspect = useMemo(
			() => objectAspects.find((x) => x.isDefault)?.aspect,
			[objectAspects],
		);
		const [currentTab, setCurrentTab] = useState<string>(
			objectAspects.find((x) => x.isDefault)?.aspect?.tabs?.[0]?.name ?? '',
		);

		const v2Refs = useMemo<HeaderRef[]>(
			() =>
				informationAspect?.tabs.flatMap((tab) => {
					return tab.fieldGroups.map((fieldGroup) => ({
						categoryName: tab.name,
						subcategoryName: fieldGroup.name,
						ref: React.createRef(),
					}));
				}) ?? [],
			[informationAspect?.tabs],
		);

		const CustomTab: FC<{ currentTab: string; tab: TabData }> = observer(
			({ currentTab, tab }) => (
				<Collapse in={currentTab === tab.name}>
					<Box sx={{ paddingX: '1.5rem', paddingY: '0.25rem' }}>
						{tab.fieldGroups
							.slice()
							.sort((a, b) => a.sortOrder - b.sortOrder)
							.map((fieldGroup) => (
								<Typography
									key={`subcategory-${fieldGroup.id}`}
									onClick={() => {
										const ref = v2Refs.find(
											(x) =>
												x.categoryName === tab.name &&
												x.subcategoryName === fieldGroup.name,
										)?.ref;

										if (ref && ref.current) {
											ref.current.scrollIntoView({
												behavior: 'smooth',
											});
										}
									}}
									sx={() => ({
										paddingY: '0.5rem',
										cursor: 'pointer',
										textWrap: 'wrap',
									})}
								>
									{fieldGroup.name}
								</Typography>
							))}
					</Box>
				</Collapse>
			),
		);

		useEffect(() => {
			setCurrentTab(
				objectAspects.find((x) => x.isDefault)?.aspect?.tabs?.[0]?.name ?? '',
			);
		}, [objectAspects]);

		if (subAccount.loading || !subAccount.entity || isLoading || !subObject) {
			return (
				<Box width="100%" my={5} py={2} display="flex" justifyContent="center">
					<CircularProgress />
				</Box>
			);
		}

		return (
			<TabContext value={currentTab}>
				<Grid container sx={{ height: '100%' }}>
					<Grid
						item
						xs={1.5}
						sx={(theme) => ({
							paddingTop: '1.25rem',
							borderRight: '1px solid',
							borderColor: theme.palette.charcoal[50],
						})}
					>
						<TabList
							onChange={(_, value) => setCurrentTab(value)}
							orientation="vertical"
							color="secondary"
							sx={{
								position: 'sticky',
								top: '1.25rem',
								justifySelf: 'flex-start',
							}}
						>
							{informationAspect?.tabs
								.map((tab) => {
									let elements = [
										<Tab
											key={`entry-tab-${tab.name}`}
											data-testid={`${tab.name.toLowerCase()}-tab`}
											{...stonlyData({ id: `${tab.name.toLowerCase()}-tab` })}
											label={tab.name}
											value={tab.name}
											sx={(theme) => ({
												alignItems: 'flex-start',
												textAlign: 'left',
												...(currentTab === tab.name
													? {
															backgroundColor: theme.palette.denim[50],
															color: theme.palette.denim[500],
													  }
													: {}),
											})}
										/>,
									];

									if (tab.fieldGroups.length > 1) {
										elements.push(
											<CustomTab currentTab={currentTab} tab={tab} />,
										);
									}
									return elements;
								})
								.map((x) => x)}
							<Tab
								data-testid={'documents-tab'}
								{...stonlyData({ id: 'documents-tab' })}
								label="Documents"
								value="Documents"
								sx={(theme) => ({
									alignItems: 'flex-start',
									...(currentTab === 'Documents'
										? {
												backgroundColor: theme.palette.denim[50],
												color: theme.palette.denim[500],
										  }
										: {}),
								})}
							/>
						</TabList>
					</Grid>
					<Grid item xs={10.5}>
						{informationAspect?.tabs.map((tab) => (
							<TabPanel
								key={`entry-tab-panel-${tab.name}`}
								value={tab.name}
								sx={{
									padding: '2rem',
								}}
							>
								<Grid item container sx={{ gap: 2 }}>
									{[
										...tab.fieldGroups
											.filter((x) => x.isCollection === false)
											.sort((a, b) => a.sortOrder - b.sortOrder)
											.map((fieldGroup, index, array) =>
												renderFieldGroup(
													subObject,
													tab,
													fieldGroup,
													v2Refs,
													entity4StaffPii,
													undefined,
													array.length - 1 === index,
												),
											),
										...tab.fieldGroups
											.filter((x) => x.isCollection === true)
											.map((fieldGroup) =>
												renderCollection(
													subObject,
													tab,
													fieldGroup,
													undefined,
													v2Refs,
												),
											),
									]
										.sort((a, b) => (a[0] as number) - (b[0] as number))
										.map((x, index) => (
											<Grid key={`field-group-${index}`} item xs={12}>
												{x[1]}
											</Grid>
										))}
								</Grid>
							</TabPanel>
						))}
						<TabPanel
							value={'Documents'}
							style={{ width: '100%', height: '100%' }}
						>
							<Grid container sx={{ height: '100%', flexWrap: 'nowrap' }}>
								<DocumentListView viewModel={subAccount.documentList} />
							</Grid>
						</TabPanel>
					</Grid>
				</Grid>
			</TabContext>
		);
	},
);
