import { Divider, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { DeleteMenuItem } from '../../../../../../shared/components/deleteMenuItem';
import { OverflowMenu } from '../../../../../../shared/components/overflowMenu';
import { useUser } from '../../../../../../shared/hooks/useUser';

export interface TaxIdOverflowMenuProps {
	taxIdId: string | undefined;
	viewTaxId: () => void;
	editTaxId: () => void;
	deleteTaxId: () => void;
}
export const TaxIdOverflowMenu: FC<TaxIdOverflowMenuProps> = observer(
	({ taxIdId, viewTaxId, editTaxId, deleteTaxId }) => {
		const { isAuthor } = useUser();

		return (
			<OverflowMenu id={`taxId-more-menu-${taxIdId}`}>
				<MenuItem onClick={viewTaxId}>View Details</MenuItem>
				{isAuthor && <MenuItem onClick={editTaxId}>Edit Details</MenuItem>}
				{isAuthor && <Divider />}
				{isAuthor && <DeleteMenuItem onClick={deleteTaxId} />}
			</OverflowMenu>
		);
	},
);
