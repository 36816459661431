import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { useMemo } from 'react';
import { useUser } from 'shared/hooks/useUser';

export type UseCollectionViewProps = {
	isReadOnly: boolean;
};

export function useCollectionView(): UseCollectionViewProps {
	const { isAuthor } = useUser();
	const { viewType } = useProfileView();

	const isReadOnly = useMemo(
		() => !isAuthor || viewType !== FieldViews.edit,
		[isAuthor, viewType],
	);

	return {
		isReadOnly,
	};
}
