import { makeAutoObservable } from 'mobx';
import { extractDisplayNameFromRefJson } from 'shared/utilities/referenceDataUtilities';
import { IAddressDto } from '../jurisdictionApiTypes';
import { Jurisdiction } from '../models/jurisdictionModel';
import { AddressFormModel } from './addressFormModel';

export class Address {
	private jurisdiction: Jurisdiction;
	private _state: string | null;
	public id: string | null;
	public buildingNumber: string | null;
	public streetName: string | null;
	public poBox: string | null;
	public address1: string | null;
	public address2: string | null;
	public city: string | null;
	public postalCode: string | null;

	public addressForm: AddressFormModel;

	constructor(jurisdiction: Jurisdiction, dto?: IAddressDto) {
		makeAutoObservable(this);
		this.jurisdiction = jurisdiction;
		this._state = extractDisplayNameFromRefJson(jurisdiction.state);
		if (dto) {
			this.id = dto.id;
			this.buildingNumber = dto.buildingNumber;
			this.streetName = dto.streetName;
			this.poBox = dto.poBox;
			this.address1 = dto.address1;
			this.address2 = dto.address2;
			this.city = dto.city;
			this.postalCode = dto.postalCode;
		} else {
			this.id = null;
			this.buildingNumber = null;
			this.streetName = null;
			this.poBox = null;
			this.address1 = null;
			this.address2 = null;
			this.city = null;
			this.postalCode = null;
		}
		this.addressForm = new AddressFormModel(this);
	}

	public get state(): string | null {
		return this._state;
	}

	public set state(state: string | null) {
		this._state = state;
	}

	public get location(): string {
		let location = this.city ?? '';

		if (this.city && this.state) location += `, ${this.state}`;
		else if (this.state) location += this.state;

		if (this.postalCode) location += ` ${this.postalCode}`;
		return location;
	}

	public updateState = () => {
		const value = extractDisplayNameFromRefJson(this.jurisdiction.state);
		this._state = value;
		this.addressForm.setValue('state', value);
	};

	public update = async <T extends keyof Address>(
		key: T,
		value: string,
	): Promise<void> => {
		const origValue = (this as Address)[key];
		try {
			(this as Address)[key] =
				value !== null
					? value.trim()
						? (value.trim() as Address[T])
						: (null as any)
					: null;
			this.addressForm.setIsWaiting(key as keyof AddressFormModel);

			await this.jurisdiction.update();

			this.addressForm.setIsSaved(key as keyof AddressFormModel);
		} catch (err) {
			(this as Address)[key] = origValue;
			this.addressForm.setValue(
				key as keyof AddressFormModel,
				origValue as string,
			);
			this.addressForm.setIsErrored(key as keyof AddressFormModel);
			throw err;
		}
	};

	public shouldDisplayFieldError = (key: keyof Address): string => {
		const initialValue = this[key];
		if (initialValue === null) {
			return '';
		}
		return this.addressForm.getFieldError(key as keyof AddressFormModel);
	};

	public clear = (): void => {
		this.id = null;
		this.buildingNumber = null;
		this.streetName = null;
		this.poBox = null;
		this.address1 = null;
		this.address2 = null;
		this.city = null;
		this.postalCode = null;
		this.addressForm = new AddressFormModel(this);
	};

	public isEmpty = (): boolean => {
		if (
			!this.buildingNumber &&
			!this.streetName &&
			!this.poBox &&
			!this.address1 &&
			!this.address2 &&
			!this.city &&
			!this.postalCode
		) {
			return true;
		}
		return false;
	};

	public toDto = (): IAddressDto | null => {
		if (this.isEmpty()) return null;
		return {
			id: this.id,
			buildingNumber: this.buildingNumber,
			streetName: this.streetName,
			poBox: this.poBox,
			address1: this.address1,
			address2: this.address2,
			city: this.city,
			postalCode: this.postalCode,
		};
	};
}
