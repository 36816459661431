/* eslint-disable mobx/missing-observer */
import { TrendingUp } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-pro';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useQuery } from '@tanstack/react-query';
import {
	ExtendedGridColDef,
	getColumnDefs,
	getColumnVisibilityModel,
} from 'features/cash4/shared/gridColDef/GridColDefFactory';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { CustomNoRowsOverlay } from 'shared/components/dataGrid/customNoRowsOverlay';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { useUser } from 'shared/hooks/useUser';
import { brandColors } from 'shared/theme/brandColors';
import { isValidDateRange } from 'shared/utilities/dateUtilities';
import { stonlyData } from 'stonly/functions';
import RowActionButton from '../components/RowActionButton';
import { fetchHoldings } from '../services';
import { stonlyIds } from '../stonlyIds';

interface HoldingsRouteProps {}

const HoldingsRoute: React.FC<HoldingsRouteProps> = () => {
	return (
		<T4View
			header={
				<PageHeader
					id={pageHeaderStonlyIds.holdingsPage}
					title="Investment Holdings"
				/>
			}
		>
			<HoldingsPage />
		</T4View>
	);
};

export default HoldingsRoute;

interface HoldingsPageProps {}

const HoldingsPage: React.FC<HoldingsPageProps> = () => {
	const statementDateColumnName = 'statementDate';
	const defaultDateRange: DateRange<Moment> = [
		moment().subtract(7, 'days').startOf('day'),
		moment().subtract(0, 'days').endOf('day'),
	];

	const [dateRange, setDateRange] =
		useState<DateRange<Moment>>(defaultDateRange);

	const [sortModel, setSortModel] = useState<GridSortModel>([
		{
			field: statementDateColumnName,
			sort: 'desc',
		},
	]);

	const { cash4 } = useUser();

	const { data: holdings, isLoading: holdingsLoading } = useQuery(
		['holdings', dateRange],
		() => fetchHoldings(dateRange),
		{
			initialData: [],
		},
	);

	useEffect(() => {
		setSortModel([
			{
				field: statementDateColumnName,
				sort: 'desc',
			},
		]);
	}, [holdings]);

	const handleDateRangeChange = (dateRange: DateRange<Moment>) => {
		if (isValidDateRange(dateRange)) {
			setDateRange(dateRange);
		}
	};

	const getRowId = (row: any) => row.investmentHoldingId;

	const customNoRowOverlay = () => (
		<CustomNoRowsOverlay
			icon={
				<div
					style={{
						backgroundColor: brandColors.cornflower[50],
						borderRadius: '50%',
						width: '80px',
						height: '80px',
						display: 'flex',
						margin: 'auto',
					}}
				>
					<TrendingUp
						sx={{
							fontSize: '50px',
							color: brandColors.denim[500],
							margin: 'auto',
						}}
					/>
				</div>
			}
			heading={<Typography variant="h6">No Holdings Found</Typography>}
			body={
				<Typography variant="body1">
					Looks like there are no investment holdings. Please adjust the date
					range or contact Customer Service at{' '}
					<a href="mailto:support@treasury4.com">support@treasury4.com</a> to
					add investment holding data.
				</Typography>
			}
		/>
	);

	let columns: ExtendedGridColDef[] = [];

	// Add the first column action button per row, if user has permission
	if (cash4.isAuthor) {
		columns.push({
			field: 'actions',
			headerName: '',
			width: 25,
			renderCell: (params) => {
				return <RowActionButton row={params.row} />;
			},
			resizable: false,
			sortable: false,
			disableColumnMenu: true,
			filterable: false,
			disableExport: true,
			hideable: false,
			disableReorder: true,
		} as GridColDef);
	}

	// Set default columns in this list of GridColDefs
	[
		{
			field: 'cusip',
			headerName: 'CUSIP',
		},
		{
			field: 'isin',
			headerName: 'ISIN',
		},
		{
			field: statementDateColumnName,
			headerName: 'Statement Date',
			type: 't4Date',
		},
		{
			field: 'e4AccountName',
			headerName: 'Account Name',
			width: 300,
		},
		{
			field: 'e4AccountNumber',
			headerName: 'Account Number',
		},
		{
			field: 'bankCode',
			headerName: 'Bank Code',
		},
		{
			field: 'currencyCode',
			headerName: 'Currency',
		},
		{
			field: 'shares',
			headerName: 'Shares',
			type: 't4Decimal',
		},
		{
			field: 'accruedInterest',
			headerName: 'Accrued Interest',
			type: 't4Money',
		},
		{
			field: 'marketValue',
			headerName: 'Market Value',
			type: 't4Money',
		},
		{
			field: 'bookValue',
			headerName: 'Book Value',
			type: 't4Money',
		},
		{
			field: 'accountNumber',
			headerName: 'C4 Account Number',
			defaultVisibility: false,
		},
		{
			field: 'federalTaxCost',
			headerName: 'Federal Tax Cost',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'unrealizedGainLossCost',
			headerName: 'Unrealized Gain/Loss Cost',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'openingBalanceAmount',
			headerName: 'Opening Balance Amount',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'openingBalanceUnits',
			headerName: 'Opening Balance Units',
			type: 't4Decimal',
			defaultVisibility: false,
		},
		{
			field: 'openingBalancePrice',
			headerName: 'Opening Balance Price',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'closingBalancePrice',
			headerName: 'Closing Balance Price',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'mtdEarnings',
			headerName: 'MTD Earnings',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'baseSettledMarketValue',
			headerName: 'Base Settled Market Value',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'localSettledMarketValue',
			headerName: 'Local Settled Market Value',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'localTradedMarketValue',
			headerName: 'Local Traded Market Value',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'currentYield',
			headerName: 'Current Yield',
			type: 't4Decimal',
			defaultVisibility: false,
		},
		{
			field: 'priceDate',
			headerName: 'Price Date',
			type: 't4Date',
			defaultVisibility: false,
		},
		{
			field: 'firstIncomePaymentDate',
			headerName: 'Next Two Income Payments 1',
			type: 't4Date',
			defaultVisibility: false,
		},
		{
			field: 'secondIncomePaymentDate',
			headerName: 'Next Two Income Payments 2',
			type: 't4Date',
			defaultVisibility: false,
		},
		{
			field: 'monthToDateEarningsRate',
			headerName: 'MTD Earnings Rate',
			type: 't4Decimal',
			defaultVisibility: false,
		},
		{
			field: 'monthToDateFees',
			headerName: 'MTD Fees',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'monthToDateFeesRate',
			headerName: 'MTD Fees Rate',
			type: 't4Decimal',
			defaultVisibility: false,
		},
		{
			field: 'sourceAccountName',
			headerName: 'Source Account Name',
			defaultVisibility: false,
		},
		{
			field: 'portfolioId',
			headerName: 'Portfolio ID',
			defaultVisibility: false,
		},
		{
			field: 'portfolioTypeCode',
			headerName: 'Portfolio Type Code',
			defaultVisibility: false,
		},
		{
			field: 'portfolioTypeCodeDescription',
			headerName: 'Portfolio Type Code Description',
			defaultVisibility: false,
		},
		{
			field: 'portfolioName',
			headerName: 'Portfolio Name',
			defaultVisibility: false,
		},
		{
			field: 'custodianId',
			headerName: 'Custodian ID',
			defaultVisibility: false,
		},
		{
			field: 'custodianName',
			headerName: 'Custodian Name',
			defaultVisibility: false,
		},
		{
			field: 'subCustodianId',
			headerName: 'Sub-custodian ID',
			defaultVisibility: false,
		},
		{
			field: 'subCustodianName',
			headerName: 'Sub-custodian Name',
			defaultVisibility: false,
		},
		{
			field: 'baseMarketValuePlusAccrued',
			headerName: 'Base Market Value Plus Accrued',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'localAccruedInterest',
			headerName: 'Local Accrued Interest',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'tradeDate',
			headerName: 'Trade Date',
			type: 't4Date',
			defaultVisibility: false,
		},
		{
			field: 'settlementDate',
			headerName: 'Settlement Date',
			type: 't4Date',
			defaultVisibility: false,
		},
		{
			field: 'originalLotId',
			headerName: 'Original Lot Id',
			defaultVisibility: false,
		},
		{
			field: 'dividendAmount',
			headerName: 'Dividend Amount',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'expectedDividendDate',
			headerName: 'Ex Dividend Date',
			type: 't4Date',
			defaultVisibility: false,
		},
		{
			field: 'recordDate',
			headerName: 'Record Date',
			type: 't4Date',
			defaultVisibility: false,
		},
		{
			field: 'paymentDate',
			headerName: 'Payment Date',
			type: 't4Date',
			defaultVisibility: false,
		},
		{
			field: 'estimatedAnnualIncome',
			headerName: 'Estimated Annual Income',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'price',
			headerName: 'Price',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'bookPrice',
			headerName: 'Book Price',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'bookYield',
			headerName: 'Book Yield',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'purchaseYield',
			headerName: 'Purchase Yield',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'originalPrice',
			headerName: 'Original Price',
			type: 't4Money',
			defaultVisibility: false,
		},
		{
			field: 'duration',
			headerName: 'Duration',
			type: 't4Decimal',
			defaultVisibility: false,
		},
		{
			field: 'durationGroup',
			headerName: 'Duration Group',
			defaultVisibility: false,
		},
		{
			field: 'balanceSheetClassification',
			headerName: 'Balance Sheet Classification',
			defaultVisibility: false,
		},
		{
			field: 'lotId',
			headerName: 'Lot ID',
			defaultVisibility: false,
		},
	].forEach(function (item) {
		columns.push(item as ExtendedGridColDef);
	});

	return (
		<>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				rowGap={1}
				sx={{ height: '100%' }}
			>
				<Grid container item xs={1} columnGap={3}>
					<Grid item>
						<T4DateRangePicker
							value={dateRange}
							onChange={(newValue) => {
								handleDateRangeChange(newValue);
							}}
							disableFuture
							showShortcuts
							shortcutResetDefaults={defaultDateRange}
							id={stonlyIds.investmentsHoldingsRoot}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ height: '65vh' }}>
					<UserPreferencesDataGrid
						stonlyId={stonlyIds.investmentsHoldingsGrid}
						getRowId={getRowId}
						tableKey="holdingsPage"
						columns={getColumnDefs(columns)}
						rows={holdings}
						loading={holdingsLoading}
						calculateColumnWidths
						columnVisibilityModel={getColumnVisibilityModel(columns, false)}
						sortModel={sortModel}
						onSortModelChange={(newSortModel) => {
							setSortModel(newSortModel);
						}}
						initialState={{
							pinnedColumns: { left: ['actions'] },
						}}
						showToolbar
						showCustomViewButton
						slotProps={{
							toolbar: {
								quickFilterProps: {
									inputProps: {
										...stonlyData({
											id: stonlyIds.investmentsHoldingsGridFilter,
										}),
									},
								},
							},
						}}
						noRowsOverlay={customNoRowOverlay}
					/>
				</Grid>
			</Grid>
		</>
	);
};
