import { isNullOrUndefined } from '@microsoft/applicationinsights-core-js';

export const stonlyIdAttribute: string = 'data-stonlyid';

export interface StonlyData {
	'data-stonlyid': string;
}
export interface StonlyDataProps {
	id: string;
}
export const stonlyData = ({ id }: StonlyDataProps): StonlyData => {
	if (isNullOrUndefined(id)) {
		throw Error('Stonly id was not provided.');
	}

	return {
		'data-stonlyid': id,
	};
};
