import {
	Card,
	CardContent,
	CardContentProps,
	CardHeader,
	CardHeaderProps,
	CardProps,
	CircularProgress,
	CircularProgressProps,
	Grid,
	Tooltip,
	Typography,
	TypographyProps,
	useTheme,
} from '@mui/material';
import { FC, ReactNode } from 'react';

export type DashboardCardProps = {
	icon?: ReactNode;
	loading: boolean;
	value: any;
	label: string;
	dashboardCardProps?: Partial<CardProps> & {
		cardHeaderProps?: Partial<CardHeaderProps>;
		cardContentProps?: Partial<CardContentProps>;
		valueTypographyProps?: Partial<TypographyProps>;
		circularProgressProps?: Partial<CircularProgressProps>;
	};
};

export const DashboardCard: FC<DashboardCardProps> = ({
	icon,
	loading,
	value,
	label,
	dashboardCardProps = {},
}) => {
	const theme = useTheme();
	const {
		sx: cardSx = {},
		cardHeaderProps = {},
		cardContentProps = {},
		valueTypographyProps = {},
		circularProgressProps = {},
		...cardProps
	} = dashboardCardProps;

	return (
		<Card
			{...cardProps}
			sx={{
				backgroundColor: theme.common?.denim?.main,
				'& .MuiTypography-root': {
					color: '#FFFFFF',
				},
				'& .MuiCardHeader-root': {
					paddingTop: '12px',
					'& .MuiCardHeader-content': {
						'& .MuiTypography-root': {
							fontSize: '16px',
							fontWeight: 400,
						},
					},
				},
				'& .MuiCardContent-root': {
					'&:last-child': {
						paddingBottom: '16px',
					},
					paddingTop: 0,
					fontWeight: 300,
				},
				'& .MuiCircularProgress-circle': {
					stroke: '#FFFFFF',
				},
				...(loading && {
					height: '101px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}),
				...cardSx,
			}}
		>
			{loading ? (
				<CircularProgress {...circularProgressProps} />
			) : (
				<>
					<CardHeader
						{...cardHeaderProps}
						title={label}
						avatar={icon}
						sx={{
							flexDirection: 'row-reverse',
							...cardHeaderProps.sx,
							'& .MuiCardHeader-avatar': {
								...((cardHeaderProps?.sx as any)?.[
									'& .MuiCardHeader-avatar'
								] as any),
								margin: 0,
							},
						}}
					/>
					<CardContent {...cardContentProps}>
						<Grid container>
							<Grid item sx={{ maxWidth: '100%' }}>
								<Tooltip title={value}>
									<Typography {...valueTypographyProps} variant="h1" noWrap>
										{value}
									</Typography>
								</Tooltip>
							</Grid>
						</Grid>
					</CardContent>
				</>
			)}
		</Card>
	);
};
