import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

export const CollectionMenu: FC = observer(({ children }) => {
	const theme = useTheme();

	const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

	return (
		<>
			<IconButton
				onClick={(event) => setAnchorElement(event.currentTarget)}
				size="small"
				sx={{
					borderStyle: 'solid',
					borderWidth: '1px',
					backgroundColor: 'white',
					color: theme.palette.text.primary,
					marginRight: '-2.5rem',
				}}
			>
				<MoreVert fontSize="small" />
			</IconButton>
			<Menu
				anchorEl={anchorElement}
				open={Boolean(anchorElement)}
				onClick={() => setAnchorElement(null)}
				onClose={() => setAnchorElement(null)}
			>
				{children}
			</Menu>
		</>
	);
});
