import { flow, makeAutoObservable } from 'mobx';
import { parseError } from 'utilities/errors/errorUtils';
import { FrontendGroupRepository, GroupEntityDto } from '../groupRepository';

export class GroupEntitiesListModel {
	private _groupId: string;
	private _frontendGroupRepository: FrontendGroupRepository;

	private _groupEntities: GroupEntityDto[] = [];

	private _loading: boolean;
	private _error: string;

	constructor(
		groupId: string,
		frontendGroupRepository: FrontendGroupRepository,
	) {
		makeAutoObservable(this);
		this._groupId = groupId;
		this._frontendGroupRepository = frontendGroupRepository;

		this._loading = false;
		this._error = '';
	}

	public get loading() {
		return this._loading;
	}
	public get error() {
		return this._error;
	}
	public get groupEntities() {
		return this._groupEntities;
	}

	public load = flow(function* (this: GroupEntitiesListModel) {
		try {
			this._loading = true;
			this._error = '';

			var response = yield this._frontendGroupRepository.getGroupEntities(
				this._groupId,
			);
			this._groupEntities = response.value;
		} catch (error: any) {
			this._error = parseError(error);
		} finally {
			this._loading = false;
		}
	});
}
