import { Box, Button, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { rem } from '../../utilities/styles';

export type SolutionsCardProps = {
	name: string;
	isDisabled: boolean;
	urlPath: string;
	text?: string;
	image?: string;
};

const SolutionCard: FC<SolutionsCardProps> = observer((props) => {
	const theme = useTheme();
	const history = useHistory();

	const goToModuleDashboard = () => {
		if (props.urlPath && !props.isDisabled) {
			history.push(props.urlPath);
		}
	};

	return (
		<Button
			disabled={props.isDisabled}
			onClick={goToModuleDashboard}
			sx={{
				width: '100%',
				height: rem(200),
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#404041',
				borderRadius: rem(12),
				textDecoration: 'none',
				appearance: 'none',
				border: 0,
				transition: '.3s ease-in-out',

				[theme.breakpoints.down('sm')]: {
					height: rem(150),
				},

				'&:disabled': {
					opacity: '.75',
					cursor: 'default',

					'&:hover': {
						backgroundColor: '#404041',
					},
				},

				'&:hover': {
					cursor: 'pointer',
					backgroundColor: '#343334',
				},
			}}
		>
			<Box
				component="img"
				src={props.image}
				alt={props.name}
				sx={{
					height: rem(50),
					marginBottom: rem(10),
					width: '100%',
					paddingX: '16px',

					[theme.breakpoints.down('sm')]: {
						height: rem(40),
					},
				}}
			/>
			<Typography
				sx={{
					color: '#FFFFFF',
				}}
			>
				{props.text}
			</Typography>
		</Button>
	);
});

export default SolutionCard;
